import uniqid from "uniqid";
import UIkit from "uikit";
import $ from "jquery";
import { API_HOST } from "../../api_utils/index";

const Quill = require("quill");

//   quill.on("text-change", () => {
// 	const delta = quill.getContents();

// 	// localStorage.setItem(
// 	//   `redslingRichText_${id}`,
// 	//   JSON.stringify(delta)
// 	// );
//   });

//   let richTextContent = localStorage.getItem(
// 	`redslingRichText_${id}`
//   );
//   if (richTextContent) {
// 	quill.setContents(JSON.parse(richTextContent));
//   }

const CustomTablePluginNew = (editor, options) => {
	console.log("CustomTablePluginNew options", options);

	editor.Blocks.add("custom-table-container", {
		label: "Custom Table (v2)",
		category: "Data-Table",
		media: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"> <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/> </svg>`,
		select: true,
		content: {
			type: "custom-table-container",
		},
		draggable: true,
	});

	// blocks only reserved for custom-table - button for edit/delete modals in table rows
	editor.Blocks.add("custom-table-action-button", {
		label: "Custom Table Action Button",
		category: "Data-Table",
		select: true,
		media: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/><path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/></svg>`,
		content: {type: "custom-table-action-button", },
	});

	// blocks only reserved for custom-table - multi-row selectiong checkbox where all row checkbox inputs get logged in local storage
	editor.Blocks.add("custom-table-row-selection-checkbox-container", {
		label: "Custom Table Row Selection",
		category: "Data-Table",
		select: true,
		media: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16"><path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/><path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/></svg>`,
		content: {type: "custom-table-row-selection-checkbox-container", },
	});

	// blocks only reserved for custom-table - toggle that gets replicated also and stores boolean values in local storage
	editor.Blocks.add("custom-table-toggle-container", {
		label: "Custom Table Toggle",
		category: "Data-Table",
		select: true,
		media: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16"><path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/></svg>`,
		content: {type: "custom-table-toggle-container", },
	});

	// // blocks only reserved for custom-table - up-arrow icon to sort in ascending order
	// editor.Blocks.add("custom-table-sort-ascending", {
	// 	label: "Custom Table Ascending-Sort",
	// 	category: "Data-Table",
	// 	select: true,
	// 	media: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/></svg>`,
	// 	content: {type: "custom-table-sort-ascending", },
	// });

	// // blocks only reserved for custom-table - down-arrow icon to sort in descending order
	// editor.Blocks.add("custom-table-sort-descending", {
	// 	label: "Custom Table Descending-Sort",
	// 	category: "Data-Table",
	// 	select: true,
	// 	media: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/></svg>`,
	// 	content: {type: "custom-table-sort-descending", },
	// });

	// blocks only reserved for custom-table - container for holding sorting-arrows
	editor.Blocks.add("custom-table-sort-container", {
		label: "Custom Table Sort Container",
		category: "Data-Table",
		select: true,
		media: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"/></svg>`,
		content: {type: "custom-table-sort-container", },
	});

	if (editor !== null && editor !== undefined) {
		editor.DomComponents.addType("custom-table-action-button", {
			model: {
				defaults: {
					tagName: "button",
					type: "custom-table-action-button",
					attributes: {
						class: "custom-table-action-button",
					},

					"style-default": {
						width: "max-content",
						padding: "0.5em",
						"border-radius": "10px",
						"background-color": "purple",
						border: "none",
						color: "white",
						cursor: "pointer",
						"pointer-events": "all",
					},

					style: {
						width: "max-content",
						padding: "0.5em",
						"border-radius": "10px",
						"background-color": "purple",
						border: "none",
						color: "white",
						cursor: "pointer",
						"pointer-events": "all",
					},

					components: [
						{
							tagName: "p",
							type: "text",
							editable: true,

							"style-default": {
								"font-size": "10px",
							},

							style: {
								"font-size": "10px",
							},

							components: {
								type: "textnode",
								content: "Action",
							},
						},
					],

					// traits
					tableActions: "",
					buttonLabel: "Action",

					traits: [
						{
							type: "select",
							label: "Table Actions",
							name: "tableActions",
							options: [
								{
									id: "edit",
									value: "edit",
									name: "Edit",
								},
								{
									id: "delete",
									value: "delete",
									name: "Delete",
								},
							],
							changeProp: true,
						}, {
							type: "text",
							label: "Button Label",
							name: "buttonLabel",
							changeProp: true,
						},
					],

					"script-props": ["tableActions", "buttonLabel"],

					changeProp: true,
				},

				init() {
					this.on("change:tableActions", this.handlePropChange);
					this.on("change:buttonLabel", this.buttonLabelHandler);
				},

				handlePropChange() {
					let tableActions = this.get("tableActions");
					console.log("tableActions", tableActions);

					// to add html attribute to this component depending on the user's choice
					this.setAttributes({
						"data-table-action": tableActions,
					});
				},

				buttonLabelHandler() {
		            let buttonLabel = this.get("buttonLabel");

		            if (buttonLabel) {
		                const textComponent = this.components().find(component => component.get('type') === 'text');
		                if (textComponent) {
		                    const textNode = textComponent.components().find(node => node.get('type') === 'textnode');
		                    if (textNode) {
		                        textNode.set('content', buttonLabel);
		                    } else {
		                        textComponent.append({
		                            type: 'textnode',
		                            content: buttonLabel
		                        });
		                    }
		                }
		                console.log('Updated button label: ', buttonLabel);
		                this.view.render();
		            }
		        },
			},
		});

		editor.DomComponents.addType("custom-table-row-selection-checkbox-container", {
			model: {
				defaults: {
					tagName: "div",
					attributes: {
						class: "custom-table-row-selection-checkbox-container",
					},

					"style-default": {
						width: "max-content",
					},

					style: {
						width: "max-content",
					},

					components: [
						{
							tagName: "input",
							attributes: {
								type: "checkbox",
								class: "custom-table-row-selection-checkbox",
								name: "custom-table-row-selection-checkbox",
								value: "selected",
							},
						},
					],
				},
			},
		});

		editor.DomComponents.addType("custom-table-toggle-container", {
			model: {
				defaults: {
					tagName: "div",
					type: "custom-table-toggle-container",
					attributes: {
						class: "custom-table-toggle-container",
					},

					style: {
						display: "flex",
						"justify-content": "flex-start",
						"align-items": "center",
						padding: '0.5%',
					},

					"style-default": {
						display: "flex",
						"justify-content": "flex-start",
						"align-items": "center",
						padding: '0.5%',
					},

					leftLabel: "Off",
					rightLabel: "On",
					isOnColor: "lightgreen",
					isOffColor: "lightgray",

					traits: [
						{
							type: "text",
							label: "Left Label",
							name: "leftLabel",
							changeProp: true,
						}, 
						{
							type: "text",
							label: "Right Label",
							name: "rightLabel",
							changeProp: true,
						},
						{
							type: "color",
							label: "Is_On Color",
							name: "isOnColor",
							changeProp: true,
						},
						{
							type: "color",
							label: "Is_Off Color",
							name: "isOffColor",
							changeProp: true,
						},
					],

					"script-props": ["leftLabel", "rightLabel", "isOnColor", "isOffColor"],
					changeProp: true,

					components: [
						{
							tagName: "div",
							type: "custom-table-inner-toggle-container",
							attributes: {
								class: "custom-table-inner-toggle-container",
								"data-isOnColor": "lightgreen",
								"data-isOffColor": "lightgray",
							},

							style: {
								display: "flex",
								"justify-content": "flex-start",
								"align-items": "center",
								padding: '0.5%',
								width: "max-content",
							},

							"style-default": {
								display: "flex",
								"justify-content": "flex-start",
								"align-items": "center",
								padding: '0.5%',
								width: "max-content",
							},

							components: [
								// left label
								{
					                tagName: "p",
					                type: "text",
					                attributes: {
					                	class: "left-label",
					                },
					  
					                components: {
					                    type: "textnode",
					                    content: "Off",
					                },
					  
					                "style-default": {
					                    margin: "auto",
					                    "text-align": "left",
					                    "font-weight": "normal",
					                },
					  
					                style: {
					                    margin: "auto",
					                    "text-align": "left",
					                    "font-weight": "normal",
					                },
				                },
				                // toggle element
								{
									tagName: "div",
									type: "custom-table-toggle",
									attributes: {
										class:  "custom-table-toggle",
									},

									style: {
										position: "relative",
							            width: "50px",
							            height: "25px",
							            "border-radius": "25px",
							            "background-color": "lightgray",
							            "margin-left": "5%",
							            "margin-right": "5%",
							            cursor: "pointer",
							            transition: "background-color 0.3s",
									},

									"style-default": {
										position: "relative",
							            width: "50px",
							            height: "25px",
							            "border-radius": "25px",
							            "background-color": "lightgray",
							            "margin-left": "5%",
							            "margin-right": "5%",
							            cursor: "pointer",
							            transition: "background-color 0.3s",
									},

									components: [
										{
											tagName: "div",
											type: "custom-table-toggle-knob",
											attributes: {
												class:  "custom-table-toggle-knob",
											},

											style: {
												position: "absolute",
									            width: "23px",
									            height: "23px",
									            "border-radius": "50%",
									            "background-color": "white",
									            top: "1px",
									            left: "1px",
									            transition: "left 0.3s",
											},

											"style-default": {
												position: "absolute",
									            width: "23px",
									            height: "23px",
									            "border-radius": "50%",
									            "background-color": "white",
									            top: "1px",
									            left: "1px",
									            transition: "left 0.3s",
											},
										},
									],
								},
								// right label
								{
					                tagName: "p",
					                type: "text",
					                attributes: {
					                	class: "right-label",
					                },
					  
					                components: {
					                    type: "textnode",
					                    content: "On",
					                },
					  
					                "style-default": {
					                    margin: "auto",
					                    "text-align": "left",
					                    "font-weight": "normal",
					                },
					  
					                style: {
					                    margin: "auto",
					                    "text-align": "left",
					                    "font-weight": "normal",
					                },
				                },
							],
						},
					],
				},

				init() {
					this.on("change:leftLabel", this.leftLabelHandler);
					this.on("change:rightLabel", this.rightLabelHandler);
					this.on("change:isOnColor", this.toggleColorHandler);
					this.on("change:isOffColor", this.toggleColorHandler);
				},

				leftLabelHandler() {
		            let leftLabel = this.get('leftLabel');

		            let container = this;
		            let leftLabelText = container.find('.left-label')[0];
		            
		            if (leftLabel && leftLabelText) {
		                leftLabelText.components().models[0].set("content", leftLabel);
		                console.log('Updated left label: ', leftLabel);
		                this.view.render();
		            }
		        },

				rightLabelHandler() {
		            let rightLabel = this.get('rightLabel');

		            let container = this;
		            let rightLabelText = container.find('.right-label')[0];

		            if (rightLabel && rightLabelText) {
		                rightLabelText.components().models[0].set("content", rightLabel);
		                console.log('Updated right label: ', rightLabel);
		                this.view.render();
		            }
		        },

				toggleColorHandler() {
		            let isOnColor = this.get('isOnColor');
		            let isOffColor = this.get('isOffColor');

		            let container = this;
		            let innerContainer = container.find('.custom-table-inner-toggle-container')[0];

		            if (isOnColor && innerContainer) {
		                innerContainer.addAttributes({ 'data-isOnColor': `${isOnColor}` });
		            }
		            if (isOffColor && innerContainer) {
		                innerContainer.addAttributes({ 'data-isOffColor': `${isOffColor}` });
		            }
		            
		            this.view.render();
		        },
			},
		});

		editor.DomComponents.addType("custom-table-sort-ascending", {
			model: {
				defaults: {
					tagName: "span",
					type: "custom-table-sort-ascending",
					attributes: {
						class: "custom-table-sort-ascending",
					},

					style: {
						color: "white",
					},

					"style-default": {
						color: "white",
					},

					components: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"/></svg>`,
				}
			}
		});

		editor.DomComponents.addType("custom-table-sort-descending", {
			model: {
				defaults: {
					tagName: "span",
					type: "custom-table-sort-descending",
					attributes: {
						class: "custom-table-sort-descending",
					},

					style: {
						color: "white",
					},

					"style-default": {
						color: "white",
					},

					components: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4"/></svg>`,
				}
			}
		});

		editor.DomComponents.addType("custom-table-sort-container", {
			model: {
				defaults: {
					tagName: "div",
					type: "custom-table-sort-container",
					attributes: {
						class: "custom-table-sort-container",
					},

					// traits
					Align: "vertically",

					traits: [
						{
							type: "select",
							name: "Align",
							label: "Align",
							options: [
				                { id: "vertically", value: "vertically", name: "Vertically" },
				                { id: "horizontally", value: "horizontally", name: "Horizontally" },
			                ],
							changeProp: true,
						},
					],

					changeProp: true,
					"script-props": ["Align"],

					style: {
						width: "max-content",
						display: "flex",
						"flex-direction": "column",
						"align-items": "center",
						"justify-content": "center",
						"row-gap": "1%",
					},

					"style-default": {
						width: "max-content",
						display: "flex",
						"flex-direction": "column",
						"align-items": "center",
						"justify-content": "center",
						"row-gap": "1%",
					},

					components: [
						{type: "custom-table-sort-ascending"},
						{type: "custom-table-sort-descending"},
					],
				},

				init() {
					this.on("change:Align", this.alignmentHandler);
				},

				alignmentHandler() {
					let {Align} = this.props();
					let container = this;

					if (Align === 'horizontally') {
						container.setStyle({
							width: "max-content",
							display: "flex",
							"flex-direction": "row",
							"align-items": "center",
							"justify-content": "center",
							"row-gap": "1%",
						});
					} else {
						container.setStyle({
							width: "max-content",
							display: "flex",
							"flex-direction": "column",
							"align-items": "center",
							"justify-content": "center",
							"row-gap": "1%",
						});
					}
				},
			},
		});

		// downloadable script for pagination
		const paginationScript = function (props) {
			console.log("$props", props, this);

			// For LB download manipulation
			// checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
			let idEl = this.id;
			const url = window.top.location.href;
			const isSubstringPresent = (url.indexOf("/editor/") !== -1 && url.indexOf("?projectId=") !== -1) || url.indexOf("/large_preview/") !== -1 || url.indexOf("/tab_preview/") !== -1 || url.indexOf("/mobile_preview/") !== -1 || url.indexOf("/fragment_editor/") !== -1;
			// if (!isSubstringPresent) {
			// 	setInterval(function () {
			// 		Object.keys(props).forEach(function (key) {
			// 			if (window[`${key}${idEl}`]) {
			// 				props[key] = window[`${key}${idEl}`];
			// 			}
			// 		});
			// 	}, 500);
			// }

			const customTableContainer = this;
			const customTable = customTableContainer.getElementsByClassName("custom-table")[0];
			const customTableThead = customTable.getElementsByClassName("custom-table-thead")[0];
			const customTableTbody = customTable.getElementsByClassName("custom-table-tbody")[0];
			const customTableTfoot = customTable.getElementsByClassName("custom-table-tfoot")[0];

			let table_selected_column;

			// Global variables to store the current page or index
			let currentPageIndex = 1;
			let currentPageIndexForPlotPaginatedTableWithoutBatches = 0;

			// variable to hold the current sorting order status of the table (Ascending/Descending)
			let colName, currentSortingOrder;

			const initLib = function ($, Quill, props, id) {
				console.log("Quill::::", Quill);
				console.log("id::::", id);
				console.log("props id-> ", id, props);

				// base url bearer token taken directly from local storage after getting downloaded
				let baseUrlData, dynamicBaseUrlBearerToken, baseUrl1BearerToken, baseUrl2BearerToken, baseUrl3BearerToken, baseUrl4BearerToken, baseUrl5BearerToken;
				// getting baseUrl data from local storage for run-time access
				let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
				if (baseUrlDataFromStorage) {
					baseUrlData = JSON.parse(baseUrlDataFromStorage);
				}
				// extracting token values from base URLs from baseUrlData
				if (baseUrlData) {
					try {
						if (`baseUrl1_${props.projectId}` in baseUrlData) {
							baseUrl1BearerToken = baseUrlData[`baseUrl1_${props.projectId}`].token;
						}
						if (`baseUrl2_${props.projectId}` in baseUrlData) {
							baseUrl2BearerToken = baseUrlData[`baseUrl2_${props.projectId}`].token;
						}
						if (`baseUrl3_${props.projectId}` in baseUrlData) {
							baseUrl3BearerToken = baseUrlData[`baseUrl3_${props.projectId}`].token;
						}
						if (`baseUrl4_${props.projectId}` in baseUrlData) {
							baseUrl4BearerToken = baseUrlData[`baseUrl4_${props.projectId}`].token;
						}
						if (`baseUrl5_${props.projectId}` in baseUrlData) {
							baseUrl5BearerToken = baseUrlData[`baseUrl5_${props.projectId}`].token;
						}
					} catch (e) {
						// statements
						console.log(e);
					}
				}				

				// let updatedProps = props;
				let updatedProps;

				// storing HeaderFields_i properties in sessionStorage if present in updatedProps
				let ssData = JSON.parse(sessionStorage.getItem(`updatedProps_${id}`));
				console.log("ssData", ssData);
				if (ssData) {
					// checking if the keys of existing sessionStorage object are same as the new object entered in this script
					if (JSON.stringify(Object.keys(ssData).sort()) === JSON.stringify(Object.keys(props).sort())) {		
   						let keys = Object.keys(ssData);
					    let allValuesMatch = true;
					 
					    for (let key of keys) {
					 
					      console.log('ssData[key]::::::',ssData[key])
					      console.log('props[key]::::::',props[key])
					     
					        if (ssData[key] !== props[key]) {
					            allValuesMatch = false;
					            break;
					        }
					    }
					 
					    if (allValuesMatch) {
					        console.log('ssData:::::::::', ssData);
					        updatedProps = ssData;
					    } else {
					        console.log('Values of some keys do not match');
					        updatedProps = props
					        sessionStorage.setItem(`updatedProps_${id}`, JSON.stringify(props));
					    }

					} else {
						// if (('HeaderField_0' in props) || ('HeaderField_0' in props.HeaderFields)) {
						if (Object.keys(props).some((key) => key.includes("HeaderField_")) || Object.keys(props.HeaderFields).some((key) => key.includes("HeaderField_"))) {
							updatedProps = props;
							sessionStorage.setItem(`updatedProps_${id}`, JSON.stringify(props));
							console.log("updatedProps saved in sessionStorage", updatedProps);

							// if HeaderField_i are present in props but not its props.HeaderFields then add those to props.HeaderFields as well, (just so its present in download)
							// if (('HeaderField_0' in props) && !('HeaderField_0' in props.HeaderFields)) {
							if (Object.keys(props).some((key) => key.includes("HeaderField_")) && !Object.keys(props.HeaderFields).some((key) => key.includes("HeaderField_"))) {
								let fields = Object.keys(props);
								fields.forEach((key) => {
									if (key.includes("HeaderField_")) {
										props.HeaderFields[key] = props[key];
									}
									sessionStorage.setItem(`updatedProps_${id}`, JSON.stringify(props));
								});
								console.log("updated props.HeaderFields");
							}
						} else {
							// if HeaderFields_i are there in ssData and also customUrlValue or baseUrlValue, then use that object
							if (((Object.keys(ssData).some((key) => key.includes("HeaderField_")) || Object.keys(ssData.HeaderFields).some((key) => key.includes("HeaderField_"))) && ssData.hasOwnProperty("customUrlValue") && ssData["customUrlValue"] !== "") || (ssData.hasOwnProperty("baseUrlValue") && ssData["baseUrlValue"] !== "")) {
								let keys = Object.keys(ssData);
				                let allValuesMatch = true;
				                for (let key of keys) {
				                  console.log('ssData[key]1::::::', ssData[key]);
				                  console.log('props[key]1::::::', props[key]);
				                  if (ssData[key] !== props[key]) {
				                    allValuesMatch = false;
				                    break;
				                  }
				                }
				                if (allValuesMatch) {
				                  console.log('ssData1:::::::::', ssData);
				                  updatedProps = ssData;
				                  console.log("updatedProps saved in sessionStorage (3)", updatedProps);
				                } else {
				                  console.log('Values of some keys do not match');
				                  updatedProps = props;
				                  sessionStorage.setItem(`updatedProps_${id}`, JSON.stringify(updatedProps));
				                }
							} else {
								updatedProps = props;
								console.log("updatedProps saved in sessionStorage (1)", updatedProps);
								sessionStorage.setItem(`updatedProps_${id}`, JSON.stringify(updatedProps));
							}
						}
					}
				} else {
					updatedProps = props;
					sessionStorage.setItem(`updatedProps_${id}`, JSON.stringify(updatedProps));
					console.log("updatedProps saved in sessionStorage (2)", updatedProps);
				}

				console.log("initLib updatedProps", updatedProps);

				// Function to show the alert everytime currentPageIndex is updated
				// const showAlert = function(updatedProps) {
				const showAlert = function (updatedProps) {
					// alert('You have selected page: ' + currentPageIndex);

					// on click/pagination event, this will manually get the recent object having HeaderField_i props somewhere

					// if HeaderField_i are in any of the levels in the updatedProps object, send that into the plotPaginatedTable function for plotting
					// if (('HeaderField_0' in updatedProps) || ('HeaderField_0' in updatedProps.HeaderFields) || (customTableContainer.hasAttribute(`data-headerfield_0`))) {
					let hasHeaderFieldAttribute = false;
					// Iterate through the attributes of customTableContainer
					for (let i = 0; i < customTableContainer.attributes.length; i++) {
						let attribute = customTableContainer.attributes[i];

						// Check if the attribute name includes the substring "data-headerfield_"
						if (attribute.name.includes("data-headerfield_")) {
							hasHeaderFieldAttribute = true;
							break; // Exit the loop if found
						}
					}
					// Now, you can use the boolean variable in your condition
					// if HeaderField_i are in any of the levels in the updatedProps object, send that into the plotPaginatedTable function for plotting
					if (Object.keys(updatedProps).some((key) => key.includes("HeaderField_")) || Object.keys(updatedProps.HeaderFields).some((key) => key.includes("HeaderField_")) || hasHeaderFieldAttribute) {
						// paginated api calls will be done only if pagination is required
						if (updatedProps.paginationRequired) {
							console.log("x");
							console.log("x updatedProps", updatedProps);
							
							// if the user clicks on the sorting buttons, then keep the api plotting in the table sorted by managing the sorting state by a global variable - currentSortingOrder
							if (currentSortingOrder) {
								if (!updatedProps.useTablePlottingApiForPagination)
									plotPaginatedTable(currentPageIndex, updatedProps, currentSortingOrder, colName);
								else
									plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps, currentSortingOrder, colName);
							} else {
								if (!updatedProps.useTablePlottingApiForPagination)
									plotPaginatedTable(currentPageIndex, updatedProps);
								else
									plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps);
							}

							// attaching an event listener to up-array/down-arrow for sorting of table -
							// NOTE - if you move/drag/reposition the arrow, click on the 'tick' icon to reapply the event listener
							let upArrows = customTableThead.getElementsByTagName("tr")[0].querySelectorAll(".custom-table-sort-ascending");
							let downArrows = customTableThead.getElementsByTagName("tr")[0].querySelectorAll(".custom-table-sort-descending");
							console.log("upArrows", upArrows);
							if (upArrows.length > 0) {
								Array.from(upArrows).forEach((btn) => {
									btn.style.cursor = "pointer";

									btn.onclick = function(event) {
										event.preventDefault();

										console.log("upArrows btn", btn);

										// Get the parent cell (td) of the clicked button
								        const parentCell = btn.parentElement.parentElement;
								        
								        // Get the parent row (tr) of the parent cell
								        const parentRow = parentCell.parentElement;
								        
								        // Get all cells in the parent row
								        const cells = Array.from(parentRow.children);
								        
								        // Find the index of the parent cell
								        const cellIndex = cells.indexOf(parentCell);

								        // get the column name from header props
								        if (`HeaderField_${cellIndex}` in updatedProps) colName = updatedProps[`HeaderField_${cellIndex}`];
								        else if (`HeaderField_${cellIndex}` in updatedProps.HeaderFields) colName = updatedProps.HeaderFields[`HeaderField_${cellIndex}`];
								        else if (customTableContainer.hasAttribute(`data-headerfield_${cellIndex}`)) colName = customTableContainer.getAttribute(`data-headerfield_${cellIndex}`);

								        console.log('upArrow cell index, colName:', cellIndex, colName);

								        if (colName) {
								        	if (!updatedProps.useTablePlottingApiForPagination)
								        		plotPaginatedTable(currentPageIndex, updatedProps, "ascending", colName);
								        	else
								        		plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps, "ascending", colName);
								        }
									
								        // update the current sorting order variable
								        currentSortingOrder = 'ascending';
									};
								});
							}

							if (downArrows.length > 0) {
								downArrows.forEach((btn) => {
									btn.style.cursor = "pointer";

									btn.onclick = function(event) {
										event.preventDefault();

										// Get the parent cell (td) of the clicked button
								        const parentCell = btn.parentElement.parentElement;
								        
								        // Get the parent row (tr) of the parent cell
								        const parentRow = parentCell.parentElement;
								        
								        // Get all cells in the parent row
								        const cells = Array.from(parentRow.children);
								        
								        // Find the index of the parent cell
								        const cellIndex = cells.indexOf(parentCell);

								        // get the column name from header props
								        if (`HeaderField_${cellIndex}` in updatedProps) colName = updatedProps[`HeaderField_${cellIndex}`];
								        else if (`HeaderField_${cellIndex}` in updatedProps.HeaderFields) colName = updatedProps.HeaderFields[`HeaderField_${cellIndex}`];
								        else if (customTableContainer.hasAttribute(`data-headerfield_${cellIndex}`)) colName = customTableContainer.getAttribute(`data-headerfield_${cellIndex}`);

								        console.log('UpArrow cell index, colName:', cellIndex, colName);

								        if (colName){
								        	if (!updatedProps.useTablePlottingApiForPagination)
								        	 	plotPaginatedTable(currentPageIndex, updatedProps, "descending", colName);
								        	else
								        		plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps, "descending", colName);
								     }
									
								        // update the current sorting order variable
								        currentSortingOrder = 'descending';
									};
								});
							}
						}
					}

					// // run this everytime api call is done due to user click event
					// // find stellar-button component in header column
					// let headerStellarButtons;
					// if (table_selected_column) {
					// 	headerStellarButtons = customTableThead.getElementsByTagName('tr')[0].getElementsByTagName('th')[Number(table_selected_column)].getElementsByClassName('stellar-button-container');
					// }
					// console.log('headerStellarButtons:', headerStellarButtons);

					// let customTableTbodyTrs = customTableTbody.getElementsByTagName('tr');
					// console.log('customTableTbodyTrs', customTableTbodyTrs);
					// if (headerStellarButtons && customTableTbodyTrs) {
					// 	for (let i = 0; i < customTableTbodyTrs.length; i++) {
					// 		let row = customTableTbodyTrs[i];

					// 		console.log('row', row);

					// 		for (let j = 0; j < headerStellarButtons.length; j++) {
					// 			// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
					// 			let clonedButton = headerStellarButtons[j].cloneNode(true);
					// 			row.getElementsByTagName('td')[Number(table_selected_column)].appendChild(clonedButton);
					// 		}

					// 		// // if loop reaches the end, clear the table_selected_column key from sessionStorage so that it doesn't plot everytime script run, only when the 'tick' button is clicked
					// 		// if (i === customTableTbodyTrs.length - 1) {
					// 		// 	// getting the table_id_selected_column index value from sessionStorage
					// 	    // 	for (let i = 0; i < sessionStorage.length; i++) {
					// 		//         const key = sessionStorage.key(i);

					// 		//         // Check if the key contains the substring '_selected_column'
					// 		//         if (key && key.includes('_selected_column')) {
					// 		//             // Get the value associated with the key
					// 		//             const value = sessionStorage.removeItem(key);
					// 		//         }
					// 		//     }
					// 		//     console.log('table_selected_column key removed from sessionStorage');
					// 		// }
					// 	}
					// }
				};

				// else make normal api calls (and run outside of showAlert so that the plotting function is not tide down to event triggering)
				if (!props.paginationRequired) {
					console.log("!x");					
					
					// if the user clicks on the sorting buttons, then keep the api plotting in the table sorted by managing the sorting state by a global variable - currentSortingOrder
					if (currentSortingOrder) {
						plotTable(currentPageIndex, updatedProps, currentSortingOrder, colName);
					} else {
						plotTable(currentPageIndex, updatedProps);
					}

					// attaching an event listener to up-array/down-arrow for sorting of table -
					// NOTE - if you move/drag/reposition the arrow, click on the 'tick' icon to reapply the event listener
					let upArrows = customTableThead.getElementsByTagName("tr")[0].querySelectorAll(".custom-table-sort-ascending");
					let downArrows = customTableThead.getElementsByTagName("tr")[0].querySelectorAll(".custom-table-sort-descending");
					if (upArrows.length > 0) {
						upArrows.forEach((btn) => {
							btn.style.cursor = "pointer";

							btn.onclick = function(event) {
								event.preventDefault();

								// Get the parent cell (td) of the clicked button
						        const parentCell = btn.parentElement;
						        
						        // Get the parent row (tr) of the parent cell
						        const parentRow = parentCell.parentElement;
						        
						        // Get all cells in the parent row
						        const cells = Array.from(parentRow.children);
						        
						        // Find the index of the parent cell
						        const cellIndex = cells.indexOf(parentCell);

						        // get the column name from header props
						        if (`HeaderField_${cellIndex}` in updatedProps) colName = updatedProps[`HeaderField_${cellIndex}`];
						        else if (`HeaderField_${cellIndex}` in updatedProps.HeaderFields) colName = updatedProps.HeaderFields[`HeaderField_${cellIndex}`];
						        else if (customTableContainer.hasAttribute(`data-headerfield_${cellIndex}`)) colName = customTableContainer.getAttribute(`data-headerfield_${cellIndex}`);

						        console.log('UpArrow cell index, colName:', cellIndex, colName);

						        if (colName) plotTable(currentPageIndex, updatedProps, "ascending", colName);
									
						        // update the current sorting order variable
						        currentSortingOrder = 'ascending';
							};
						});
					}

					if (downArrows.length > 0) {
						downArrows.forEach((btn) => {
							btn.style.cursor = "pointer";

							btn.onclick = function(event) {
								event.preventDefault();

								// Get the parent cell (td) of the clicked button
						        const parentCell = btn.parentElement;
						        
						        // Get the parent row (tr) of the parent cell
						        const parentRow = parentCell.parentElement;
						        
						        // Get all cells in the parent row
						        const cells = Array.from(parentRow.children);
						        
						        // Find the index of the parent cell
						        const cellIndex = cells.indexOf(parentCell);

						        // get the column name from header props
						        if (`HeaderField_${cellIndex}` in updatedProps) colName = updatedProps[`HeaderField_${cellIndex}`];
						        else if (`HeaderField_${cellIndex}` in updatedProps.HeaderFields) colName = updatedProps.HeaderFields[`HeaderField_${cellIndex}`];
						        else if (customTableContainer.hasAttribute(`data-headerfield_${cellIndex}`)) colName = customTableContainer.getAttribute(`data-headerfield_${cellIndex}`);

						        console.log('UpArrow cell index, colName:', cellIndex, colName);

						        if (colName) plotTable(currentPageIndex, updatedProps, "descending", colName);
									
						        // update the current sorting order variable
						        currentSortingOrder = 'descending';
							};
						});
					}
				}

				// also run/plot automatically -
				// - once the component is loaded
				// - if any of the traits are updated
				showAlert(updatedProps);

				// helper function to check if a variable is an array-of-objects or not
				function isArrayofObjects(variable) {
					// Check if the variable is an array
					if (!Array.isArray(variable)) {
						return false;
					}

					// Check if all elements in the array are objects
					for (const element of variable) {
						if (typeof element !== "object" || Array.isArray(element) || element === null) {
							return false;
						}
					}
					return true;
				}

				// helper function to get value of a key in nested object
				function findKeyValue(obj, key) {
					if (obj.hasOwnProperty(key)) {
						// If the current object has the key, return its value
						return obj[key];
					}

					for (var prop in obj) {
						if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
							// If the current property is an object, recursively search within it
							var result = findKeyValue(obj[prop], key);
							if (result !== undefined) {
								return result;
							}
						}
					}
					// Key not found in the object
					return undefined;
				}

				// helper function to check if there are any nested array-of-objects in the objects of the main array-of-objects
				function hasArrayOfObjectsWithinArray(dataStructure) {
					if (!Array.isArray(dataStructure)) {
						return false; // Not an array
					}

					for (const obj of dataStructure) {
						if (typeof obj === "object" && obj !== null) {
							for (const value of Object.values(obj)) {
								if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object" && value[0] !== null) {
									return true; // Found an array of objects with nested array
								}
							}
						}
					}

					return false; // Not found
				}

				// helper function to generate randomId
				function generateRandomId(prefix) {
					const alphabet = "abcdefghijklmnopqrstuvwxyz";
					const randomAlphabet = alphabet[Math.floor(Math.random() * alphabet.length)];
					const randomNumber = Math.floor(Math.random() * 10000);
					return `${prefix}_${randomAlphabet}${randomNumber}`;
				}

				// Helper function to copy styles from source element to target element and its children
				function copyStyles(source, target) {
					// Copy styles from the source element to the target element
					let computedStyles = window.getComputedStyle(source);
					for (let k = 0; k < computedStyles.length; k++) {
						// Check if the source element has the class name 'custom-table-action-button'
						if ((source.classList.contains("custom-table-action-button") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") ||
							(source.classList.contains("gjs-row") && source.getAttribute("data-gjs-type") === "div" && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") ||
							(source.classList.contains("custom-table-toggle-container") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none")) {
							// Skip copying the 'display: none' property for elements with class 'custom-table-action-button'
							continue;
						}
						// Copy other styles
						target.style[computedStyles[k]] = computedStyles.getPropertyValue(computedStyles[k]);
						target.style["display"] = "flex";
					}

					// Recursively copy styles for each child element
					for (let childIndex = 0; childIndex < source.children.length; childIndex++) {
						copyStyles(source.children[childIndex], target.children[childIndex]);
					}
				}

				// Helper function to copy styles from source element to target element and its children
				function copyStyles1(source, target) {
					// Copy styles from the source element to the target element
					let computedStyles = window.getComputedStyle(source);

					// Set display property of the target element to 'block'
					target.style.display = "block";

					// Copy other styles
					for (let k = 0; k < computedStyles.length; k++) {
						// Check if the source element has the class name 'custom-table-action-button'
						if (
							(source.classList.contains("custom-table-action-button") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") ||
							(source.classList.contains("radio_regular_form") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") ||
							(source.classList.contains("checkbox_regular_form") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") ||
							(source.classList.contains("input_select_container") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") ||
							(source.getAttribute("data-gjs-type") === "form_text_field" && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") ||
							(source.getAttribute("data-gjs-type") === "form_number_field" && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") ||
							(source.classList.contains("custom-table-row-selection-checkbox-container") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") ||
							(source.getAttribute("data-gjs-type") === "dateTimeInputNew" && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none")						
						) {
							// Skip copying the 'display: none' property for elements with class 'custom-table-action-button' or 'radio_regular_form'
							continue;
						}
						// Copy other styles
						target.style[computedStyles[k]] = computedStyles.getPropertyValue(computedStyles[k]);
						target.style["display"] = "block";
					}

					// Recursively copy styles for each child element
					for (let childIndex = 0; childIndex < source.children.length; childIndex++) {
						// Set display property of the child element to 'inline'
						target.children[childIndex].style.display = "inline";
						copyStyles(source.children[childIndex], target.children[childIndex]);
					}
				}

				// Helper function to copy styles from source element to target element and its children
				function copyStyles2(source, target) {
					// Copy styles from the source element to the target element
					let computedStyles = window.getComputedStyle(source);

					// Set display property of the target element to 'block'
					target.style.display = "flex";

					// Copy other styles
					for (let k = 0; k < computedStyles.length; k++) {
						// Skip copying the 'display: none' property for elements with class 'radio_regular_form'
						if ((source.classList.contains("radio_regular_form") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") || (source.classList.contains("checkbox_regular_form") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none") || (source.classList.contains("checkbox_regular_form_new") && computedStyles[k] === "display" && computedStyles.getPropertyValue(computedStyles[k]) === "none")) {
							continue;
						}
						// Copy other styles
						target.style[computedStyles[k]] = computedStyles.getPropertyValue(computedStyles[k]);
						target.style["display"] = "flex";
					}

					// Recursively copy styles for each child element
					for (let childIndex = 0; childIndex < source.children.length; childIndex++) {
						// Set display property of the child element to 'inline'
						copyStyles2(source.children[childIndex], target.children[childIndex]); // Corrected recursive call
					}
				}

				// helper function to extract table names from redSling datamodels
				// const extractTableName = (url) => {
				//     const regex = /\/(data_grid|table_data|nested_data|strict_filter_nested)\/([^?\/]+)/;
				//     const match = url.match(regex);
				//     return match ? match[2] : null;
				// };

				function extractTableName(url) {
					// Match the table name after the last forward slash (/)
					const regex = /\/([^\/?]+)(\?|$)/;
					const match = url.match(regex);
					return match ? match[1] : null;
				};

				// helper function to extract reference keynames from attributes
				const extractReferenceKeynameAttributes = (referencingAttributes) => {
					// Initialize an object to store unique words and their associated values
					const uniqueWords = {};

					try {
						// Iterate over the values of referencingAttributes
						for (const value of Object.values(referencingAttributes)) {
							// Split the value by ":"
							const parts = value.split(":");

							// Get the first part (trimmed)
							const firstPart = parts[0].trim();

							// Store the first part as a key in the uniqueWords object if it's not already present
							if (!(firstPart in uniqueWords)) {
								uniqueWords[firstPart] = value;
							}
						}
					} catch (e) {
						// statements
						console.log(e);
					}

					return uniqueWords;
				};

				// helper function to extract reference attributes for labels
				const extractReferenceAttributesForEditModalLabels = (exampleString) => {
					// Split the string by semi-colon
					const parts = exampleString.split(";");
					console.log("exampleString, parts", exampleString, parts);

					const extractedValues = {};

					try {
						// Iterate over the parts
						for (const part of parts) {
							console.log("part", part);
							// Split each part by colon ":"
							const [key, valuesString] = part.trim().split(":");
							console.log("valuesString", valuesString);

							// Split the valuesString by comma ","
							const values = valuesString.trim().split(",");

							// Store the key-value pair in the extractedValues object
							extractedValues[key.trim()] = values.map((value) => value.trim());
						}
					} catch (e) {
						// statements
						console.log(e);
					}

					return extractedValues;
				};

				// helper function for Number Format Conversion
				function convertNumberFormat(input, formatType) {
				    let number;

				    // Convert the input to a number if it's a string
				    if (typeof input === "string") {
				        number = parseFloat(input.replace(/,/g, ""));
				        if (isNaN(number)) {
				            console.error("Invalid number format");
				            return null;
				        }
				    } else if (typeof input === "number") {
				        number = input;
				    } else {
				        console.error("Input must be a number or a string representing a number");
				        return null;
				    }

				    let formattedNumber;

				    // Function to add grouping separators
				    function addSeparator(number, separator, groupSize) {
				        const parts = number.toString().split(".");
				        const integerPart = parts[0];
				        const decimalPart = parts[1] ? `.${parts[1]}` : "";

				        const regex = new RegExp(`\\B(?=(\\d{${groupSize}})+(?!\\d))`, "g");
				        return integerPart.replace(regex, separator) + decimalPart;
				    }

				    // Function to handle decimal places
				    function formatDecimal(number, decimalPlaces) {
				        return number.toFixed(decimalPlaces);
				    }

				    switch (formatType) {
				        // No separator
				        case "no_sep":
				            formattedNumber = number.toString().replace(/,/g, "");
				            break;

				        // Apostrophe separator
				        case "aps_sep":
				            formattedNumber = addSeparator(number, "'", 3);
				            break;

				        // Space separator
				        case "spc_sep":
				            formattedNumber = addSeparator(number, " ", 3);
				            break;

				        // Space underscore separator
				        case "spc_sep_underscore":
				            formattedNumber = addSeparator(number, "_", 3);
				            break;

				        // Western international format
				        case "wes_int_frt":
				            formattedNumber = addSeparator(number, ",", 3);
				            break;

				        // European format
				        case "eu_frt":
				            formattedNumber = addSeparator(number, ".", 3).replace(/\./g, ",").replace(/,/g, ".");
				            break;

				        // Indian numbering system
				        case "ind_num_sys":
				            const integerPart = number.toString().split(".")[0];
				            const lastThreeDigits = integerPart.slice(-3);
				            const otherDigits = integerPart.slice(0, -3).replace(/\B(?=(\d{2})+(?!\d))/g, ",");
				            formattedNumber = otherDigits + "," + lastThreeDigits;
				            break;

				        // East Asian format
				        case "est_asn_frt":
				            formattedNumber = addSeparator(number, ",", 4);
				            break;

				        // Two decimal places
				        case "two_dec":
				            formattedNumber = formatDecimal(number, 2);
				            break;

				        // Four decimal places
				        case "four_dec":
				            formattedNumber = formatDecimal(number, 4);
				            break;

				        // Six decimal places
				        case "six_dec":
				            formattedNumber = formatDecimal(number, 6);
				            break;

				        // Eight decimal places
				        case "eight_dec":
				            formattedNumber = formatDecimal(number, 8);
				            break;

				        // Add more cases for combinations of separators and decimal places if needed
				        case "aps_sep_two_dec":
				            formattedNumber = formatDecimal(number, 2);
				            formattedNumber = addSeparator(formattedNumber, "'", 3);
				            break;

				        case "spc_sep_two_dec":
				            formattedNumber = formatDecimal(number, 2);
				            formattedNumber = addSeparator(formattedNumber, " ", 3);
				            break;

				        case "spc_sep_underscore_two_dec":
				            formattedNumber = formatDecimal(number, 2);
				            formattedNumber = addSeparator(formattedNumber, "_", 3);
				            break;

				        case "wes_int_frt_two_dec":
				            formattedNumber = formatDecimal(number, 2);
				            formattedNumber = addSeparator(formattedNumber, ",", 3);
				            break;

				        case "eu_frt_two_dec":
				            formattedNumber = formatDecimal(number, 2);
				            formattedNumber = addSeparator(formattedNumber, ".", 3).replace(/\./g, ",").replace(/,/g, ".");
				            break;

				        case "ind_num_sys_two_dec":
				            formattedNumber = formatDecimal(number, 2);
				            const integerPartTwoDec = formattedNumber.split(".")[0];
				            const lastThreeDigitsTwoDec = integerPartTwoDec.slice(-3);
				            const otherDigitsTwoDec = integerPartTwoDec.slice(0, -3).replace(/\B(?=(\d{2})+(?!\d))/g, ",");
				            formattedNumber = otherDigitsTwoDec + "," + lastThreeDigitsTwoDec + "." + formattedNumber.split(".")[1];
				            break;

				        case "est_asn_frt_two_dec":
				            formattedNumber = formatDecimal(number, 2);
				            formattedNumber = addSeparator(formattedNumber, ",", 4);
				            break;

				        // Four decimal places with separator
				        case "aps_sep_four_dec":
				            formattedNumber = formatDecimal(number, 4);
				            formattedNumber = addSeparator(formattedNumber, "'", 3);
				            break;

				        case "spc_sep_four_dec":
				            formattedNumber = formatDecimal(number, 4);
				            formattedNumber = addSeparator(formattedNumber, " ", 3);
				            break;

				        case "spc_sep_underscore_four_dec":
				            formattedNumber = formatDecimal(number, 4);
				            formattedNumber = addSeparator(formattedNumber, "_", 3);
				            break;

				        case "wes_int_frt_four_dec":
				            formattedNumber = formatDecimal(number, 4);
				            formattedNumber = addSeparator(formattedNumber, ",", 3);
				            break;

				        case "eu_frt_four_dec":
				            formattedNumber = formatDecimal(number, 4);
				            formattedNumber = addSeparator(formattedNumber, ".", 3).replace(/\./g, ",").replace(/,/g, ".");
				            break;

				        case "ind_num_sys_four_dec":
				            formattedNumber = formatDecimal(number, 4);
				            const integerPartFourDec = formattedNumber.split(".")[0];
				            const lastThreeDigitsFourDec = integerPartFourDec.slice(-3);
				            const otherDigitsFourDec = integerPartFourDec.slice(0, -3).replace(/\B(?=(\d{2})+(?!\d))/g, ",");
				            formattedNumber = otherDigitsFourDec + "," + lastThreeDigitsFourDec + "." + formattedNumber.split(".")[1];
				            break;

				        case "est_asn_frt_four_dec":
				            formattedNumber = formatDecimal(number, 4);
				            formattedNumber = addSeparator(formattedNumber, ",", 4);
				            break;

				        // Six decimal places with separator
				        case "aps_sep_six_dec":
				            formattedNumber = formatDecimal(number, 6);
				            formattedNumber = addSeparator(formattedNumber, "'", 3);
				            break;

				        case "spc_sep_six_dec":
				            formattedNumber = formatDecimal(number, 6);
				            formattedNumber = addSeparator(formattedNumber, " ", 3);
				            break;

				        case "spc_sep_underscore_six_dec":
				            formattedNumber = formatDecimal(number, 6);
				            formattedNumber = addSeparator(formattedNumber, "_", 3);
				            break;

				        case "wes_int_frt_six_dec":
				            formattedNumber = formatDecimal(number, 6);
				            formattedNumber = addSeparator(formattedNumber, ",", 3);
				            break;

				        case "eu_frt_six_dec":
				            formattedNumber = formatDecimal(number, 6);
				            formattedNumber = addSeparator(formattedNumber, ".", 3).replace(/\./g, ",").replace(/,/g, ".");
				            break;

				        case "ind_num_sys_six_dec":
				            formattedNumber = formatDecimal(number, 6);
				            const integerPartSixDec = formattedNumber.split(".")[0];
				            const lastThreeDigitsSixDec = integerPartSixDec.slice(-3);
				            const otherDigitsSixDec = integerPartSixDec.slice(0, -3).replace(/\B(?=(\d{2})+(?!\d))/g, ",");
				            formattedNumber = otherDigitsSixDec + "," + lastThreeDigitsSixDec + "." + formattedNumber.split(".")[1];
				            break;

				        case "est_asn_frt_six_dec":
				            formattedNumber = formatDecimal(number, 6);
				            formattedNumber = addSeparator(formattedNumber, ",", 4);
				            break;

				        // Eight decimal places with separator
				        case "aps_sep_eight_dec":
				            formattedNumber = formatDecimal(number, 8);
				            formattedNumber = addSeparator(formattedNumber, "'", 3);
				            break;

				        case "spc_sep_eight_dec":
				            formattedNumber = formatDecimal(number, 8);
				            formattedNumber = addSeparator(formattedNumber, " ", 3);
				            break;

				        case "spc_sep_underscore_eight_dec":
				            formattedNumber = formatDecimal(number, 8);
				            formattedNumber = addSeparator(formattedNumber, "_", 3);
				            break;

				        case "wes_int_frt_eight_dec":
				            formattedNumber = formatDecimal(number, 8);
				            formattedNumber = addSeparator(formattedNumber, ",", 3);
				            break;

				        case "eu_frt_eight_dec":
				            formattedNumber = formatDecimal(number, 8);
				            formattedNumber = addSeparator(formattedNumber, ".", 3).replace(/\./g, ",").replace(/,/g, ".");
				            break;

				        case "ind_num_sys_eight_dec":
				            formattedNumber = formatDecimal(number, 8);
				            const integerPartEightDec = formattedNumber.split(".")[0];
				            const lastThreeDigitsEightDec = integerPartEightDec.slice(-3);
				            const otherDigitsEightDec = integerPartEightDec.slice(0, -3).replace(/\B(?=(\d{2})+(?!\d))/g, ",");
				            formattedNumber = otherDigitsEightDec + "," + lastThreeDigitsEightDec + "." + formattedNumber.split(".")[1];
				            break;

				        case "est_asn_frt_eight_dec":
				            formattedNumber = formatDecimal(number, 8);
				            formattedNumber = addSeparator(formattedNumber, ",", 4);
				            break;

				        default:
				            console.error("Invalid format type");
				            return null;
				    }

				    return formattedNumber;
				}

				// helper function for timezone Conversion
				function convertToTimeZone(dateStr, targetTimeZone) {
				    // Handle empty dateStr cases
				    if (!dateStr) {
				        return dateStr;
				    }

				    try {
				    	// Trim the dateStr to remove any leading or trailing whitespace
				        dateStr = dateStr.trim();

				        let date;

				        // Check if the dateStr ends with "Z" indicating UTC and contains "T"
				        if (dateStr.endsWith("Z") && dateStr.includes("T")) {
				            // Create a Date object from the ISO 8601 string
				            date = new Date(dateStr);
				        } else {
				            // Fallback to existing logic: create a Date object from the date string
				            date = new Date(dateStr);
				        }

				        // Check if the date is valid
				        if (isNaN(date.getTime())) {
				            throw new Error('Invalid date');
				        }

				        // Define the time zones mapping
				        const timeZones = {
				            "GMT": "Etc/GMT",
				            "CET": "Europe/Berlin",
				            "EST": "America/New_York",
				            "PST": "America/Los_Angeles",
				            "IST": "Asia/Kolkata",
				            "CST": "Asia/Shanghai",
				            "JST": "Asia/Tokyo",
				            "ICT": "Asia/Bangkok", // Indochina Time UTC +7
				            "AEST": "Australia/Sydney",
				            "ACST": "Australia/Adelaide",
				            "AWST": "Australia/Perth",
				            "EDT": "America/New_York",
				            "PDT": "America/Los_Angeles",
				            "CEST": "Europe/Berlin",
				            "BST": "Europe/London",
				            "MSK": "Europe/Moscow",
				            "AKST": "America/Anchorage",
				            "HST": "Pacific/Honolulu"
				        };

				        // Validate if the target time zone exists
				        if (!timeZones[targetTimeZone]) {
				            throw new Error('Invalid target time zone');
				        }

				        // Use Intl.DateTimeFormat to convert the time zone
				        const options = {
				            timeZone: timeZones[targetTimeZone],
				            year: 'numeric',
				            month: '2-digit',
				            day: '2-digit',
				            hour: '2-digit',
				            minute: '2-digit',
				            second: '2-digit',
				            hour12: false
				        };

				        const formatter = new Intl.DateTimeFormat('en-US', options);
				        const formattedDate = formatter.format(date);

				        return formattedDate;

				    } catch (error) {
				        console.error("Error:", error.message);
				        return null;
				    }
				}

				// helper function to convert date formats
				function convertDateFormat(dateString, targetFormat) {
					// Handle empty dateStr cases
				    if (!dateString) {
				        return dateString;
				    }
				    
				    let date;
				    if (dateString.includes("/")) {
				        // Parse format '20/04/2024, 14:23:59'
				        const parts = dateString.split(", ")[0].split("/");
				        const datePart = parts[0];
				        const monthPart = parts[1];
				        const yearPart = parts[2];
				        const timePart = dateString.split(", ")[1];
				        const timeParts = timePart.split(":");
				        date = new Date(Date.UTC(yearPart, monthPart - 1, datePart, timeParts[0], timeParts[1], timeParts[2]));
				    } else if (dateString.includes("T")) {
				        // Parse format '2024-04-20T08:53:47.975Z'
				        date = new Date(dateString);
				    } else {
				        // Parse other formats
				        date = new Date(dateString);
				    }

				    if (isNaN(date.getTime())) {
				        console.error("Invalid date format");
				        return null;
				    }

				    const day = date.getUTCDate().toString().padStart(2, "0");
				    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
				    const year = date.getUTCFullYear();
				    const hours = date.getUTCHours();
				    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
				    const amPm = hours >= 12 ? "pm" : "am";
				    const displayHours = hours % 12 || 12; // Convert to 12-hour format

				    switch (targetFormat) {
				        case "DD MMM YYYY":
				            return `${day} ${getMonthName(date.getUTCMonth())} ${year}`;
				        case "DD MMMM YYYY":
				            return `${day} ${getFullMonthName(date.getUTCMonth())} ${year}`;
				        case "DD/MM/YYYY":
				            return `${day}/${month}/${year}`;
				        case "MM/DD/YYYY":
				            return `${month}/${day}/${year}`;
				        case "DD-MM-YYYY":
				            return `${day}-${month}-${year}`;
				        case "DD MMM YYYY HH:mm am/pm":
				            return `${day} ${getMonthName(date.getUTCMonth())} ${year} ${displayHours}:${minutes} ${amPm}`;
				        case "DD MMMM YYYY HH:mm am/pm":
				            return `${day} ${getFullMonthName(date.getUTCMonth())} ${year} ${displayHours}:${minutes} ${amPm}`;
				        case "DD/MM/YYYY HH:mm am/pm":
				            return `${day}/${month}/${year} ${displayHours}:${minutes} ${amPm}`;
				        case "MM/DD/YYYY HH:mm am/pm":
				            return `${month}/${day}/${year} ${displayHours}:${minutes} ${amPm}`;
				        case "DD-MM-YYYY HH:mm am/pm":
				            return `${day}-${month}-${year} ${displayHours}:${minutes} ${amPm}`;
				        case "DD MMM YYYY 24:00":
				            return `${day} ${getMonthName(date.getUTCMonth())} ${year} ${hours}:${minutes}`;
				        case "DD MMMM YYYY 24:00":
				            return `${day} ${getFullMonthName(date.getUTCMonth())} ${year} ${hours}:${minutes}`;
				        case "DD/MM/YYYY 24:00":
				            return `${day}/${month}/${year} ${hours}:${minutes}`;
				        case "MM/DD/YYYY 24:00":
				            return `${month}/${day}/${year} ${hours}:${minutes}`;
				        case "DD-MM-YYYY 24:00":
				            return `${day}-${month}-${year} ${hours}:${minutes}`;
				        default:
				            console.error("Invalid target date format");
				            return null;
				    }
				}

				function getMonthName(monthIndex) {
				    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
				    return months[monthIndex];
				}

				function getFullMonthName(monthIndex) {
				    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				    return months[monthIndex];
				}

				function getAmPm(hours) {
					return hours >= 12 ? "pm" : "am";
				}

				// // a function triggered by a trait on a specific column index that calculates difference between provided date and current date and returns result in years
				// function calcDiffBtwCurrDateNApiRespValFunc(date) {
				// 	if (!date) {
				// 		console.warn("No date provided");
				// 		return null;
				// 	}
					
				// 	// Convert the input date to a Date object
				// 	const inputDate = new Date(date);
				// 	if (isNaN(inputDate)) {
				// 		console.error("Invalid date format provided");
				// 		return null;
				// 	}
					
				// 	// Get the current date
				// 	const currentDate = new Date();
					
				// 	// Calculate the difference in years
				// 	const diffInMilliseconds = currentDate - inputDate;
				// 	const diffInYears = diffInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
					
				// 	return Math.floor(diffInYears);
				// }

				// helper function to create a popup notification for email
				function showNotification(isSuccess, message) {
					// Create a notification element
					const notification = document.createElement("div");

					// Apply styles directly
					notification.style.display = "block";
					notification.style.position = "fixed";
					notification.style.top = "20px";
					notification.style.right = "20px";
					notification.style.padding = "20px";
					notification.style.backgroundColor = "white";
					notification.style.border = "3px solid";
					notification.style.borderRadius = "10px";
					notification.style.boxShadow = "0 0 10px rgba(0,0,0,0.1)";
					notification.style.zIndex = "1000";
					notification.style.maxWidth = "300px";
					notification.style.textAlign = "center";

					// Set the message
					notification.textContent = message;

					// Apply border color based on success or failure
					notification.style.borderColor = isSuccess ? "#4CAF50" : "#F44336";

					// Append the notification to the document body
					document.body.appendChild(notification);

					// Hide and remove the notification after 3 seconds
					setTimeout(() => {
						document.body.removeChild(notification);
					}, 4000);
				}

				// helper function to update the toggle state
		        function updateToggle(toggleInstance, toggleState, isOnColor, isOffColor) {
		            const toggleKnobInstance = toggleInstance.querySelector('.custom-table-toggle-knob');
		            if (toggleState) {
		                // toggleInstance.style.backgroundColor = 'lightgreen';
		                toggleInstance.style.backgroundColor = isOnColor;
		                toggleKnobInstance.style.left = '26px';
		            } else {
		                // toggleInstance.style.backgroundColor = 'lightgray';
		                toggleInstance.style.backgroundColor = isOffColor;
		                toggleKnobInstance.style.left = '1px';
		            }
		        }

		    	// helper function to make a PUT call on behalf of the toggle component
		       async function updateTable(cellValue, data_db_id, updatingCustomUrlValue, updatingBaseUrlValue, updatingApiPathValue, updatingApiQueryParamValue, updatingApiTableAttributeValue, updatingApiMethodValue, updatingApiUsernameValue, updatingApiPasswordValue, updatingApiBearerTokenValue, updatingApiMoreHeadersValue) {
		        	console.log('cellValue, data_db_id', cellValue, data_db_id, updatingCustomUrlValue, updatingBaseUrlValue, updatingApiPathValue, updatingApiQueryParamValue, updatingApiTableAttributeValue, updatingApiMethodValue, updatingApiUsernameValue, updatingApiPasswordValue, updatingApiBearerTokenValue, updatingApiMoreHeadersValue);

		        	// fetching API data
					let apiData;
					let bu, bubt; // base url bearer token, temp var

					// if only custom url is provided and its a PUT call
					if (updatingCustomUrlValue && (!updatingBaseUrlValue || updatingBaseUrlValue === "null") && !updatingApiPathValue && updatingApiMethodValue === "PUT") {
						// if nothing is provided, for authentication
						if (!updatingApiUsernameValue && !updatingApiPasswordValue && !updatingApiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "PUT",
								body: JSON.stringify({
									to_insert: {
										[updatingApiTableAttributeValue]: cellValue,
									},
								}),
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatingApiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatingApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatingCustomUrlValue}` + `?${updatingApiQueryParamValue}=${data_db_id}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatingApiUsernameValue && !updatingApiPasswordValue && updatingApiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "PUT",
								body: JSON.stringify({
									to_insert: {
										[updatingApiTableAttributeValue]: cellValue,
									},
								}),
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${updatingApiBearerTokenValue}`,
								},
							};

							if (updatingApiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatingApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatingCustomUrlValue}` + `?${updatingApiQueryParamValue}=${data_db_id}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatingApiUsernameValue && updatingApiPasswordValue && !updatingApiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "PUT",
								body: JSON.stringify({
									to_insert: {
										[updatingApiTableAttributeValue]: cellValue,
									},
								}),
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatingApiUsernameValue + ":" + updatingApiPasswordValue),
								},
							};

							if (updatingApiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatingApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatingCustomUrlValue}` + `?${updatingApiQueryParamValue}=${data_db_id}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a PUT call
					else if (!updatingCustomUrlValue && (updatingBaseUrlValue || !updatingBaseUrlValue === "null") && updatingApiPathValue && updatingApiMethodValue === "PUT") {
						// concatenating base url and its remaining part
						let url, dynamicBaseUrlBearerToken;
						let forDownloadUrl;

						if (updatingBaseUrlValue === "baseUrl1") {
							url = updatedProps.options.baseUrl1 + updatingApiPathValue;
							forDownloadUrl = "{{API_URL1}}" + updatingApiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl1BearerToken;
							dynamicBaseUrlBearerToken = baseUrl1BearerToken;
						} else if (updatingBaseUrlValue === "baseUrl2") {
							url = updatedProps.options.baseUrl2 + updatingApiPathValue;
							forDownloadUrl = "{{API_URL2}}" + updatingApiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl2BearerToken;
							dynamicBaseUrlBearerToken = baseUrl2BearerToken;
						} else if (updatingBaseUrlValue === "baseUrl3") {
							url = updatedProps.options.baseUrl3 + updatingApiPathValue;
							forDownloadUrl = "{{API_URL3}}" + updatingApiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl3BearerToken;
							dynamicBaseUrlBearerToken = baseUrl3BearerToken;
						} else if (updatingBaseUrlValue === "baseUrl4") {
							url = updatedProps.options.baseUrl4 + updatingApiPathValue;
							forDownloadUrl = "{{API_URL4}}" + updatingApiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl4BearerToken;
							dynamicBaseUrlBearerToken = baseUrl4BearerToken;
						} else if (updatingBaseUrlValue === "baseUrl5") {
							url = updatedProps.options.baseUrl5 + updatingApiPathValue;
							forDownloadUrl = "{{API_URL5}}" + updatingApiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl5BearerToken;
							dynamicBaseUrlBearerToken = baseUrl5BearerToken;
						}

						const urlDownload1 = window.top.location.href;
						const isSubstringPresent1 = (urlDownload1.indexOf("/editor/") !== -1 && urlDownload1.indexOf("?projectId=") !== -1) || urlDownload1.indexOf("/large_preview/") !== -1 || urlDownload1.indexOf("/tab_preview/") !== -1 || urlDownload1.indexOf("/mobile_preview/") !== -1 || urlDownload1.indexOf("/fragment_editor/") !== -1;
       			 		if (!isSubstringPresent1) {
       			 			// running in download
       			 			url = forDownloadUrl;
       			 		}

						bu = url;
						bubt = dynamicBaseUrlBearerToken;
						console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!updatingApiUsernameValue && !updatingApiPasswordValue && !updatingApiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "PUT",
								body: JSON.stringify({
									to_insert: {
										[updatingApiTableAttributeValue]: cellValue,
									},
								}),
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatingApiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatingApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}` + `?${updatingApiQueryParamValue}=${data_db_id}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatingApiUsernameValue && !updatingApiPasswordValue && !updatingApiBearerTokenValue && dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "PUT",
								body: JSON.stringify({
									to_insert: {
										[updatingApiTableAttributeValue]: cellValue,
									},
								}),
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
								},
							};

							if (updatingApiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatingApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}` + `?${updatingApiQueryParamValue}=${data_db_id}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatingApiUsernameValue && updatingApiPasswordValue && !updatingApiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "PUT",
								body: JSON.stringify({
									to_insert: {
										[updatingApiTableAttributeValue]: cellValue,
									},
								}),
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatingApiUsernameValue + ":" + updatingApiPasswordValue),
								},
							};

							if (updatingApiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatingApiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}` + `?${updatingApiQueryParamValue}=${data_db_id}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					console.log("apiData:", apiData);
		       }

		       // helper function to return the api response for a table in the sorted order (Asc/Desc)
		       async function returnSortedApiData(page, updatedProps, sortingOrder, colName) {
		       		let apiData;
		       		let bu, bubt; // base url bearer token, temp var
		       		let sortingPayload = {
				        filterData: {},
				        sort: {}
				    };

				    if (sortingOrder === 'ascending') {
				        sortingPayload.sort[colName] = 1;
				    } else if (sortingOrder === 'descending') {
				        sortingPayload.sort[colName] = -1;
				    }

				    // if page prop is provided, it means call plotPaginated table for api query parameters, else make a simple api call
				    if (page) {
					    // if only custom url is provided and its a POST call
						if (updatedProps.customUrlValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && (!updatedProps.nonAPICustomData || !updatedProps.nonAPICustomData.length)) {
							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only base url is provided and its a POST call
						else if (!updatedProps.customUrlValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && (!updatedProps.nonAPICustomData || !updatedProps.nonAPICustomData.length)) {
							// concatenating base url and its remaining part
							let url, dynamicBaseUrlBearerToken;
							let forDownloadUrl;
							
							if (updatedProps.baseUrlValue === "baseUrl1") {
								url = updatedProps.options.baseUrl1 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL1}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl1BearerToken;
								dynamicBaseUrlBearerToken = baseUrl1BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl2") {
								url = updatedProps.options.baseUrl2 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL2}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl2BearerToken;
								dynamicBaseUrlBearerToken = baseUrl2BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl3") {
								url = updatedProps.options.baseUrl3 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL3}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl3BearerToken;
								dynamicBaseUrlBearerToken = baseUrl3BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl4") {
								url = updatedProps.options.baseUrl4 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL4}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl4BearerToken;
								dynamicBaseUrlBearerToken = baseUrl4BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl5") {
								url = updatedProps.options.baseUrl5 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL5}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl5BearerToken;
								dynamicBaseUrlBearerToken = baseUrl5BearerToken;
							}

							const urlDownload1 = window.top.location.href;
							const isSubstringPresent1 = (urlDownload1.indexOf("/editor/") !== -1 && urlDownload1.indexOf("?projectId=") !== -1) || urlDownload1.indexOf("/large_preview/") !== -1 || urlDownload1.indexOf("/tab_preview/") !== -1 || urlDownload1.indexOf("/mobile_preview/") !== -1 || urlDownload1.indexOf("/fragment_editor/") !== -1;
	       			 		if (!isSubstringPresent1) {
	       			 			// running in download
	       			 			url = forDownloadUrl;
	       			 		}

							bu = url;
							bubt = dynamicBaseUrlBearerToken;
							console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if custom data is provided (usually from LB) without using API, plot that (only for without paginated tables)
						else if (updatedProps.nonAPICustomData.length) {
							// Sort based on the sortingOrder
							const sortedData = updatedProps.nonAPICustomData.toSorted((a, b) => {
								if (sortingOrder === 'ascending') {
								return a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0;
								} else if (sortingOrder === 'descending') {
								return a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0;
								}
							});
						
							// Assign sorted data to apiData
							const apiData = sortedData; // Already sorted in the correct order
							console.log('Sorted apiData:', apiData);
							return apiData;
						}
				    } else {
				    	// if only custom url is provided and its a POST call
						if (updatedProps.customUrlValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && (!updatedProps.nonAPICustomData || !updatedProps.nonAPICustomData.length)) {
							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only base url is provided and its a POST call
						else if (!updatedProps.customUrlValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && (!updatedProps.nonAPICustomData || !updatedProps.nonAPICustomData.length)) {
							// concatenating base url and its remaining part
							let url, dynamicBaseUrlBearerToken;
							let forDownloadUrl;

							if (updatedProps.baseUrlValue === "baseUrl1") {
								url = updatedProps.options.baseUrl1 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL1}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl1BearerToken;
								dynamicBaseUrlBearerToken = baseUrl1BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl2") {
								url = updatedProps.options.baseUrl2 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL2}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl2BearerToken;
								dynamicBaseUrlBearerToken = baseUrl2BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl3") {
								url = updatedProps.options.baseUrl3 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL3}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl3BearerToken;
								dynamicBaseUrlBearerToken = baseUrl3BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl4") {
								url = updatedProps.options.baseUrl4 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL4}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl4BearerToken;
								dynamicBaseUrlBearerToken = baseUrl4BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl5") {
								url = updatedProps.options.baseUrl5 + updatedProps.apiPathValue;
								forDownloadUrl = "{{API_URL5}}" + updatedProps.apiPathValue;
								// baseUrlBearerToken = updatedProps.options.baseUrl5BearerToken;
								dynamicBaseUrlBearerToken = baseUrl5BearerToken;
							}

							const urlDownload4 = window.top.location.href;
							const isSubstringPresent4 = (urlDownload4.indexOf("/editor/") !== -1 && urlDownload4.indexOf("?projectId=") !== -1) || urlDownload4.indexOf("/large_preview/") !== -1 || urlDownload4.indexOf("/tab_preview/") !== -1 || urlDownload4.indexOf("/mobile_preview/") !== -1 || urlDownload4.indexOf("/fragment_editor/") !== -1;
	       			 		if (!isSubstringPresent4) {
	       			 			// running in download
	       			 			url = forDownloadUrl;
	       			 		}

							bu = url;
							bubt = dynamicBaseUrlBearerToken;
							console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: JSON.stringify(sortingPayload),
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if custom data is provided (usually from LB) without using API, plot that (only for without paginated tables)
						else if (updatedProps.nonAPICustomData.length) {
							// Sort based on the sortingOrder
							const sortedData = updatedProps.nonAPICustomData.toSorted((a, b) => {
								if (sortingOrder === 'ascending') {
								return a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0;
								} else if (sortingOrder === 'descending') {
								return a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0;
								}
							});
						
							// Assign sorted data to apiData
							const apiData = sortedData; // Already sorted in the correct order
							console.log('Sorted apiData:', apiData);
							return apiData;
						}
				    }

					return apiData;
		       }

				// Find the .uk-pagination element within custom-table-tfoot
				const ukPagination = $(customTableTfoot).find(".uk-pagination");

				console.log("ukPagination", ukPagination, this);

				// Attach the event to the found element
				ukPagination.on("select.uk.pagination", function (e, pageIndex) {
					console.log("ukPagination (2)", ukPagination, pageIndex);
					// Update the global variable with the selected page/index
					currentPageIndex = pageIndex + 1;
					currentPageIndexForPlotPaginatedTableWithoutBatches = pageIndex;

					// Remove the uk-active class from all li elements
					ukPagination.find("li").removeClass("uk-active");
					// Add the uk-active class to the selected li element
					ukPagination.find("li:contains(" + currentPageIndex + ")").addClass("uk-active");
					ukPagination.find("li:contains(" + currentPageIndex + ")").children().first().css({"background": "#009dd8", "color": "white"});

					ukPagination.find("li:contains(" + (currentPageIndex - 1) + ")").children().first().css({"background": "#f7f7f7", "color": "black"});

					// run this function everytime currentPageIndex is updated
					showAlert(updatedProps);
				});

				// Attach click event for the "Previous" button
				$(customTableTfoot)
					.find(".uk-pagination-previous")
					.on("click", function (e) {
						e.preventDefault();
						if (currentPageIndex > 1) {
							// Update the global variable with the previous page/index
							currentPageIndex--;
							currentPageIndexForPlotPaginatedTableWithoutBatches--;

							// Remove the uk-active class from all li elements
							ukPagination.find("li").removeClass("uk-active");
							// Add the uk-active class to the selected li element
							ukPagination.find("li:contains(" + currentPageIndex + ")").addClass("uk-active");
							ukPagination.find("li:contains(" + currentPageIndex + ")").children().first().css({"background": "#009dd8", "color": "white"});
							
							ukPagination.find("li:contains(" + (currentPageIndex + 1) + ")").children().first().css({"background": "#f7f7f7", "color": "black"});
							// Trigger the uk-pagination to go to the previous page
							ukPagination.find("li:contains(" + currentPageIndex + ")").trigger("click");

							// run this function everytime currentPageIndex is updated
							showAlert(updatedProps);
						}
					});

				// Attach click event for the "Next" button
				$(customTableTfoot)
					.find(".uk-pagination-next")
					.on("click", function (e) {
						e.preventDefault();
						// Update the global variable with the next page/index
						currentPageIndex++;
						currentPageIndexForPlotPaginatedTableWithoutBatches++;

						// Remove the uk-active class from all li elements
						ukPagination.find("li").removeClass("uk-active");
						// Add the uk-active class to the selected li element
						ukPagination.find("li:contains(" + currentPageIndex + ")").addClass("uk-active");
						ukPagination.find("li:contains(" + currentPageIndex + ")").children().first().css({"background": "#009dd8", "color": "white"});
						
						ukPagination.find("li:contains(" + (currentPageIndex - 1) + ")").children().first().css({"background": "#f7f7f7", "color": "black"});
						// Trigger the uk-pagination to go to the next page
						ukPagination.find("li:contains(" + currentPageIndex + ")").trigger("click");

						// run this function everytime currentPageIndex is updated
						showAlert(updatedProps);
					});

				// plotting with paginated api calls
				async function plotPaginatedTable(page, updatedProps, sortingOrder, colName) {
					console.log("page, size, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, updatedProps", page, updatedProps.numRowsPerPage, updatedProps.customUrlValue, updatedProps.baseUrlValue, updatedProps.apiPathValue, updatedProps.apiMethodValue, updatedProps.apiUsernameValue, updatedProps.apiPasswordValue, updatedProps.apiBearerTokenValue, updatedProps.apiMoreHeadersValue, updatedProps.apiBodyValue, updatedProps);

					// fetching API data
					let apiData, tableData;
					let bu, bubt; // base url bearer token, temp var
					// preparing `getByTableNameUrl` var for toggle's field_type boolean requirement; Will pass baseUrl to this var if provided to make it dynamic in download so that it doesn't give errors
					let getByTableNameUrl = updatedProps.API_HOST;

					// if only custom url is provided and its a GET call
					if (updatedProps.customUrlValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a GET call
					else if (!updatedProps.customUrlValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
						// concatenating base url and its remaining part
						let url, dynamicBaseUrlBearerToken;
						let forDownloadUrl;

						if (updatedProps.baseUrlValue === "baseUrl1") {
							url = updatedProps.options.baseUrl1 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL1}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl1;
							// baseUrlBearerToken = updatedProps.options.baseUrl1BearerToken;
							dynamicBaseUrlBearerToken = baseUrl1BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl2") {
							url = updatedProps.options.baseUrl2 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL2}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl2;
							// baseUrlBearerToken = updatedProps.options.baseUrl2BearerToken;
							dynamicBaseUrlBearerToken = baseUrl2BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl3") {
							url = updatedProps.options.baseUrl3 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL3}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl3;
							// baseUrlBearerToken = updatedProps.options.baseUrl3BearerToken;
							dynamicBaseUrlBearerToken = baseUrl3BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl4") {
							url = updatedProps.options.baseUrl4 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL4}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl4;
							// baseUrlBearerToken = updatedProps.options.baseUrl4BearerToken;
							dynamicBaseUrlBearerToken = baseUrl4BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl5") {
							url = updatedProps.options.baseUrl5 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL5}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl5;
							// baseUrlBearerToken = updatedProps.options.baseUrl5BearerToken;
							dynamicBaseUrlBearerToken = baseUrl5BearerToken;
						}

						const urlDownload1 = window.top.location.href;
						const isSubstringPresent1 = (urlDownload1.indexOf("/editor/") !== -1 && urlDownload1.indexOf("?projectId=") !== -1) || urlDownload1.indexOf("/large_preview/") !== -1 || urlDownload1.indexOf("/tab_preview/") !== -1 || urlDownload1.indexOf("/mobile_preview/") !== -1 || urlDownload1.indexOf("/fragment_editor/") !== -1;
       			 		if (!isSubstringPresent1) {
       			 			// running in download
       			 			url = forDownloadUrl;
       			 		}

						bu = url;
						bubt = dynamicBaseUrlBearerToken;

						console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only custom url is provided and its a POST call
					else if (updatedProps.customUrlValue && updatedProps.apiBodyValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a POST call
					else if (!updatedProps.customUrlValue && updatedProps.apiBodyValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
						// concatenating base url and its remaining part
						let url, dynamicBaseUrlBearerToken;
						let forDownloadUrl;
						
						if (updatedProps.baseUrlValue === "baseUrl1") {
							url = updatedProps.options.baseUrl1 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL1}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl1;
							// baseUrlBearerToken = updatedProps.options.baseUrl1BearerToken;
							dynamicBaseUrlBearerToken = baseUrl1BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl2") {
							url = updatedProps.options.baseUrl2 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL2}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl2;
							// baseUrlBearerToken = updatedProps.options.baseUrl2BearerToken;
							dynamicBaseUrlBearerToken = baseUrl2BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl3") {
							url = updatedProps.options.baseUrl3 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL3}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl3;
							// baseUrlBearerToken = updatedProps.options.baseUrl3BearerToken;
							dynamicBaseUrlBearerToken = baseUrl3BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl4") {
							url = updatedProps.options.baseUrl4 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL4}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl4;
							// baseUrlBearerToken = updatedProps.options.baseUrl4BearerToken;
							dynamicBaseUrlBearerToken = baseUrl4BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl5") {
							url = updatedProps.options.baseUrl5 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL5}}" + updatedProps.apiPathValue;
							getByTableNameUrl = updatedProps.options.baseUrl5
							// baseUrlBearerToken = updatedProps.options.baseUrl5BearerToken;
							dynamicBaseUrlBearerToken = baseUrl5BearerToken;
						}

						const urlDownload1 = window.top.location.href;
						const isSubstringPresent1 = (urlDownload1.indexOf("/editor/") !== -1 && urlDownload1.indexOf("?projectId=") !== -1) || urlDownload1.indexOf("/large_preview/") !== -1 || urlDownload1.indexOf("/tab_preview/") !== -1 || urlDownload1.indexOf("/mobile_preview/") !== -1 || urlDownload1.indexOf("/fragment_editor/") !== -1;
       			 		if (!isSubstringPresent1) {
       			 			// running in download
       			 			url = forDownloadUrl;
       			 		}

						bu = url;
						bubt = dynamicBaseUrlBearerToken;
						console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}` + `?page=${page}&size=${Number(updatedProps.numRowsPerPageValue)}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if sortingOrder is provided (either ascending or descending), get the sorted apiData
					if (sortingOrder) apiData = await returnSortedApiData(page, updatedProps, sortingOrder, colName);

					console.log("apiData:", apiData);

					try {	
					  // an API call to get the field names & field types so that the toggle buttons can be initialized accordingly as on/off
					  let tableNameResponse = await fetch(`${getByTableNameUrl}customroutes/getByTableName`, {
					    method: "POST",
					    headers: {
					      "Content-Type": "application/json",
					      Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`
					    },
					    // body: JSON.stringify({projectId : "64df604132bcdbec0c024776"}),
					    body: JSON.stringify({
					      tableName: extractTableName(updatedProps.customUrlValue ? updatedProps.customUrlValue : bu + updatedProps.apiPathValue)
					    })
					  });

					  // Check if the response is ok (status is in the range 200-299)
					  if (!tableNameResponse.ok) {
					    throw new Error(`HTTP error! status: ${tableNameResponse.status}`);
					  }

					  let tableNameResponseData = await tableNameResponse.json();
					  tableData = tableNameResponseData;
					} catch (error) {
					  console.error("Error fetching table name data:", error);
					  // Handle the error, maybe set tableData to a default value or show a message to the user
					  tableData = null; // or any default value you prefer
					}

					// clearing tbody every time before plotting - for view change
					if (customTableTbody) {
						customTableTbody.innerHTML = "";
					}					

					// CHECKING & PLOTTING DATASTRUCTURES
					// if the response is: an array-of-objects
					if (isArrayofObjects(apiData) && !hasArrayOfObjectsWithinArray(apiData)) {
						console.log("#");

						// loop over apiData array as a table's row
						apiData.forEach((obj, idx) => {
							if (idx < updatedProps.numRowsPerPageValue) {
								let tr = document.createElement("tr");
								tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
								tr.setAttribute("class", "custom-table-tbody-row");
								// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
								tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
								tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
								tr.style.width = "100%";
								tr.style.height = "auto";
								if (idx % 2 !== 0) {
									tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
									// hover effect
									if (!updatedProps.tBodyTrSelectColorHighlight) {
										tr.addEventListener("mouseover", function () {
											tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
										});
										tr.addEventListener("mouseleave", function () {
											tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyOddTrBackgroundColor} ${updatedProps.tBodyOddTrBackgroundColor}`;
										});
									}									
								} else {
									tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
									// hover effect
									if (!updatedProps.tBodyTrSelectColorHighlight) {
										tr.addEventListener("mouseover", function () {
											tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
										});
										tr.addEventListener("mouseleave", function () {
											tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyEvenTrBackgroundColor} ${updatedProps.tBodyEvenTrBackgroundColor}`;
										});
									}									
								}

								// get data and add it as table's column
								for (let i = 0; i < updatedProps.numColsInputValue; i++) {
									let content, convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
									// field name and type are for toggle replication
									let fieldName;
            						
									// using updatedProps.HeaderFields because only these are present in the downloading code
									// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
									// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
									// }

									try {
										if (`HeaderField_${i}` in updatedProps) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else {
												// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";

													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 0", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else {
													content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
											// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
											fieldName = updatedProps[`HeaderField_${i}`];
										} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else {
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													targetFormat = targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 1", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else {
													content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												}
												// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
											// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
											fieldName = updatedProps.HeaderFields[`HeaderField_${i}`];
										} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
											// Get the value of the attribute from 'this'
											console.log("customTableContainer", customTableContainer);
											const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (headerFieldAttribute.includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object is not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
											} else {
												// content = obj[headerFieldAttribute];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[headerFieldAttribute];
													targetFormat = targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[headerFieldAttribute];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 2", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
													content = obj[headerFieldAttribute];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[headerFieldAttribute]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("headerFieldAttribute", headerFieldAttribute, content);
											// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
											fieldName = headerFieldAttribute;
										}
									} catch (e) {
										// statements
										console.error(e);
									}

									// field name and type are for toggle replication
									let fieldType;
									let tableDataFields;
                  					if (tableData && ("fields" in tableData)) tableDataFields = tableData.fields;
									if (tableDataFields) fieldType = tableDataFields.filter((x) => x.field_name === fieldName)[0]?.field_type;
									
            						// console.log("+fieldType+", fieldType);

									// console.log("content, i", content, i);

									let td = document.createElement("td");
									td.setAttribute("data-gjs-type", "cell");
									// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
									td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
									td.style.padding = "8px";

									td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
									td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
									td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
									td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
									td.style.borderCollapse = "collapse";
									td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
									td.style.color = `${updatedProps.tBodyTdFontColor}`;
									td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
									td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
									td.innerHTML = content !== undefined ? content : "";

									// for replicating single column component
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerSingleColumnComponents;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerSingleColumnComponents = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="custom-table-thead-row"][class*="gjs-row"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerSingleColumnComponents) {
											if (headerSingleColumnComponents.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerSingleColumnComponents.length; j++) {
												// row.getElementsByTagName('td')[table_selected_column].append(headerSingleColumnComponents[j]);
												let clonedSingleColumnComponent = headerSingleColumnComponents[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerSingleColumnComponents[j], clonedSingleColumnComponent);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedSingleColumnComponent.setAttribute("id", `single_column_component_table${id}_row${idx}_column${i}_${j}`);

												// for replicating ACTION BUTTON inside single column components
												let innerClonedActionButtons = clonedSingleColumnComponent.getElementsByClassName("custom-table-action-button");
												if (innerClonedActionButtons.length > 0) {
													Array.from(innerClonedActionButtons).forEach((innerClonedActionButton, j_btn) => {
														if (innerClonedActionButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedActionButton.setAttribute("id", `custom_table_action${innerClonedActionButton.getAttribute("data-table-action")}_button_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedActionButton.getElementsByTagName("p")[0]) {
																innerClonedActionButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedActionButton.addEventListener("click", async function (event) {
																console.log("action-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
																localStorage.setItem(
																	`custom_table_action_button_table${id}_${innerClonedActionButton.getAttribute("data-table-action")}_column${i}_${j}_element${j_btn}_clicked`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"data-table-action": `${innerClonedActionButton.getAttribute("data-table-action")}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedActionButton.getAttribute("id")}`,
																	}),
																);

																// Style for the modals
																let modalStyle = {
																	padding: "2%",
																	backgroundColor: "white",
																	borderRadius: "5px",
																	boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																	maxWidth: "50vw",
																	width: "30vw",
																	maxHeight: "40vh",
																	margin: "auto",
																	overflow: "auto",
																	"-ms-overflow-style": "none",
																	"scrollbar-width": "none",
																};

																// Style for the input fields
																let inputFieldStyle = {
																	display: "flex",
																	flexDirection: "column",
																	marginBottom: "15px",
																};

																// Style for the buttons
																let buttonStyle = {
																	width: "100%",
																	padding: "10px",
																	backgroundColor: "#007bff",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let submitButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let closeButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																// Edit modal
																if (innerClonedActionButton.getAttribute("data-table-action") === "edit") {
																	// Create the dialog element
																	let editModal = document.createElement("dialog");
																	editModal.setAttribute("id", "custom-modal");

																	// Apply styles to the modal
																	Object.assign(editModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Edit`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// variable only for the reference keyname user input
																	let selectedOptions = [];
																	// Check if updatedProps.EditableFields is not empty
																	if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																		// Iterate over EditableFields to create form elements
																		for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																			// to handle non-Reference field keynames
																			if (!fieldName.includes("-")) {
																				let fieldLabel = document.createElement("label");
																				fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																				fieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(fieldLabel);

																				let inputField = document.createElement("input");
																				inputField.setAttribute("name", fieldName);
																				inputField.value = obj[fieldName]; // to initialize input fields with present values
																				Object.assign(inputField.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// Set input field type based on field type
																				switch (fieldType.toLowerCase()) {
																					case "string":
																						inputField.setAttribute("type", "text");
																						break;
																					case "boolean":
																						inputField.setAttribute("type", "radio");
																						break;
																					case "number":
																						inputField.setAttribute("type", "number");
																						break;
																					case "date-time":
																						inputField.setAttribute("type", "datetime-local");
																						break;
																					case "time":
																						inputField.setAttribute("type", "time");
																						break;
																					case "file":
																						inputField.setAttribute("type", "file");
																						break;
																					default:
																						inputField.setAttribute("type", "text");
																						break;
																				}
																				modalContent.appendChild(inputField);
																			}

																			// to handle Reference field keynames for example - WebProject1_Address-Sector
																			else if (fieldName.includes("-")) {
																				// variables to be used later in this if-condition
																				let [table, column] = fieldName.split("-");
																				// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																				// create its label
																				let refFieldLabel = document.createElement("label");
																				refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																				refFieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(refFieldLabel);

																				// create a drodown for these keynames > its options will be all the values from response from that table
																				let referenceKeynameDropdown = document.createElement("select");
																				referenceKeynameDropdown.setAttribute("name", fieldName);
																				referenceKeynameDropdown.setAttribute("id", fieldName);
																				// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																				Object.assign(referenceKeynameDropdown.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// populate it with options using the response from its data-model API
																				let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																					headers: {
																						Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																					},
																				});
																				let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																				if (referenceKeynameValueOptionsData) {
																					// setting default option --select--
																					let defaultOption = document.createElement("option");
																					// defaultOption.setAttribute('disabled', 'disabled');
																					defaultOption.innerHTML = "--select--";
																					referenceKeynameDropdown.appendChild(defaultOption);

																					// loop over the response to fill the options of its reference table
																					referenceKeynameValueOptionsData.forEach((optObj) => {
																						let option = document.createElement("option");
																						option.value = optObj[`${column}`];
																						option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																						option.innerHTML = optObj[`${column}`];
																						// to initialize input fields with present values
																						if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																							option.setAttribute("selected", true);
																						}
																						referenceKeynameDropdown.appendChild(option);
																					});
																				}

																				referenceKeynameDropdown.addEventListener("change", function (e) {
																					let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																					// console.log('Selected value:', selectedOptionFromDropdown.value);
																					// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																					// get the reference ID of the table row from main obj to update
																					let refTableId;
																					let tableName = table.split("_")[1];
																					if (obj[tableName] !== null) {
																						refTableId = obj[tableName];
																					}

																					selectedOptions.push({
																						table,
																						column,
																						refTableId,
																						value: selectedOptionFromDropdown.value,
																						"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																					});
																				});

																				modalContent.appendChild(referenceKeynameDropdown);
																			}
																		}
																	}

																	console.log("+bu, bubt+", bu, bubt);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";
																	buttonContainer.style.marginTop = "5%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-submit-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
																	submitButton.innerHTML = "Submit";

																	// Apply styles to the button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", function (e) {
																		e.preventDefault(); // Prevent default form action
																		// Handle form submission here
																		console.log("Form submitted!", obj);

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Initialize an empty FormData object to get the form's input fields values
																		let formData = new FormData(modalContent);
																		let formValues = {};
																		for (const [key, value] of formData.entries()) {
																			formValues[key] = value;
																		}

																		// Create the to_insert object with the keynames from updatedProps.EditableFields
																		// and their corresponding values from formValues
																		let to_insert = {};
																		Object.keys(updatedProps.EditableFields).forEach((key) => {
																			if (formValues[key] !== undefined && !key.includes("-")) {
																				// Check if the form has the field
																				to_insert[key] = formValues[key];
																			}

																			// re-attach the reference id to PUT call else it will set the references to null
																			if (formValues[key] !== undefined && key.includes("-")) {
																				let x = key.split("-");
																				let table = x[0].split("_")[1];
																				if (obj[table] !== null) {
																					to_insert[table] = obj[table];
																				}
																			}
																		});

																		console.log("+to_insert+", to_insert);
																		console.log("+selectedOptions+", selectedOptions);

																		// logic to update all table cells the edit button is editing without refreshing
																		for (const key in to_insert) {
																			let columns = [];
																			if (updatedProps[`HeaderField_${i}`]) {
																				Object.keys(updatedProps).map((hF_i) => {
																					if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			} else {
																				Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																					if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			}

																			// console.log('+columns+', columns);

																			columns.forEach(idx => {
																				let td = tr.getElementsByTagName('td')[idx];

																				let valueToUpdateCellWith = to_insert[key];
																				// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																				// Find the first text node
																				const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																				if (textNode) {
																					textNode.nodeValue = valueToUpdateCellWith;
																				} else {
																					// If no text node exists, create one
																					td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																				}
																			});
																		}

																		// Fetch call (to non-reference table) can be made here using the input field values
																		// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																		fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "PUT",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																			body: JSON.stringify({
																				to_insert: to_insert,
																			}),
																		})
																			.then((response) => response.json())
																			.then((data) => console.log(data));

																		// Fetch call (for reference table)
																		if (selectedOptions.length > 0) {
																			selectedOptions.forEach((data) => {
																				// make api calls per object
																				let requestBody = {};
																				requestBody[data.column] = data.value; // Assign dynamic key-value pair
																				fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																					method: "PUT",
																					headers: {
																						"Content-Type": "application/json",
																						Authorization: `Bearer ${values.BearerToken}`,
																					},
																					body: JSON.stringify({
																						to_insert: requestBody,
																					}),
																				})
																					.then((response) => response.json())
																					.then((data) => console.log(data));
																			});
																		}

																		// Close the modal when the form is submitted
																		editModal.close();

																		// clear the selectedOptions for next turn
																		selectedOptions = [];

																		// // giving a delay in reload so that it doesn't change the url in the broswer tab
																		// setTimeout(function () {
																		// 	window.top.location.reload();
																		// }, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-close-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
																	closeButton.innerHTML = "Close";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (event) {
																		event.preventDefault(); // Prevent default behavior of the click event
																		editModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	editModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(editModal);

																	// Show the modal
																	editModal.showModal();
																}
																// Delete modal
																else if (innerClonedActionButton.getAttribute("data-table-action") === "delete") {
																	// Create the dialog element
																	let deleteModal = document.createElement("dialog");
																	deleteModal.setAttribute("id", "delete-modal");

																	// Apply styles to the modal
																	Object.assign(deleteModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Delete`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// // Create an input field for data model with a default value
																	// let dataModelInputField = document.createElement("input");
																	// dataModelInputField.setAttribute('type', 'text');
																	// dataModelInputField.setAttribute('name', 'DataModel');
																	// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
																	// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(dataModelInputField.style, inputFieldStyle);

																	// modalContent.appendChild(dataModelInputField);

																	// // Create an input field for bearer token with a default value
																	// let bearerTokenInputField = document.createElement("input");
																	// bearerTokenInputField.setAttribute('type', 'text');
																	// bearerTokenInputField.setAttribute('name', 'BearerToken');
																	// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
																	// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(bearerTokenInputField.style, inputFieldStyle);

																	// modalContent.appendChild(bearerTokenInputField);

																	// Create a paragraph tag for confirmation message
																	let confirmationMessage = document.createElement("p");
																	confirmationMessage.style.marginBottom = "5%";
																	confirmationMessage.style.fontSize = "1vw";
																	confirmationMessage.innerHTML = "Are you sure?";

																	// Append confirmation message to the modal content
																	modalContent.appendChild(confirmationMessage);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-delete-yes-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
																	submitButton.innerHTML = "Yes";

																	// Apply styles to the "Yes" button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", async function (e) {
																		e.preventDefault();
																		// Handle form submission here
																		console.log("Form submitted!");

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Use the values in the fetch call or other logic
																		console.log("Input field values:", values);

																		// Make the fetch call to delete the item
																		let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "DELETE",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																		});
																		// .then(response => {
																		//     if (response.ok) {
																		//         console.log('Item deleted successfully');
																		//     } else {
																		//         console.error('Failed to delete item');
																		//     }
																		// });

																		let responseData = response.json();
																		if (responseData.ok) {
																			console.log("Item deleted successfully");
																		} else {
																			console.error("Failed to delete item");
																		}

																		// Close the modal when the form is submitted
																		deleteModal.close();

																		// giving a delay in reload so that it doesn't change the url in the broswer tab
																		setTimeout(function () {
																			window.top.location.reload();
																		}, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-delete-no-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
																	closeButton.innerHTML = "No";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (e) {
																		e.preventDefault();
																		deleteModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	deleteModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(deleteModal);

																	// Show the modal
																	deleteModal.showModal();
																}
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedActionButton);
														}
													});
												}

												// for replicating STELLAR BUTTON inside single column components
												let innerClonedStellarButtons = clonedSingleColumnComponent.getElementsByClassName("stellar-button-container");
												if (innerClonedStellarButtons.length > 0) {
													Array.from(innerClonedStellarButtons).forEach((innerClonedStellarButton, j_btn) => {
														if (innerClonedStellarButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
																innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedStellarButton.addEventListener("click", function (event) {
																console.log("stellar-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

																// this registers all stellar button clicks on LS
																// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
																// this registers only by the individuals in the column header
																localStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);

																sessionStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedStellarButton);
														}
													});
												}

												td.appendChild(clonedSingleColumnComponent);

												// to hide the header buttons after they have been cloned
												headerSingleColumnComponents[j].style.display = "none";
											}
										}
										// }
										console.log("headerSingleColumnComponents:", headerSingleColumnComponents);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating stellar buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerStellarButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										console.log("headerStellarButtons1:", headerStellarButtons);
										if (headerStellarButtons) {
											if (headerStellarButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerStellarButtons.length; j++) {
												console.log("headerStellarButtons[j]", headerStellarButtons[j]);
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerStellarButtons[j], clonedStellarButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
												clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
												if (clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
													clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header stellar button was not in single column component, only then append it to td
												if (!headerStellarButtons[j].parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedStellarButton);
													console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedStellarButton.addEventListener("click", function (event) {
													console.log("stellar-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													sessionStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													// highlight the table row that the stellar button has selected
													if (updatedProps.tBodyTrSelectColorHighlight)
														clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";

													customTable.onclick = function(event) {														
														// Check if the click is inside the custom table but outside the clicked row
														// if (
														// 	customTable.contains(event.target) && // Click is inside the customTable
														// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
														// ) {
														// 	console.log('@deselecting row', idx);
														// 	// Determine original color based on row index (even/odd)
														// 	const originalColor = idx % 2 === 0
														// 		? updatedProps.tBodyEvenTrBackgroundColor
														// 		: updatedProps.tBodyOddTrBackgroundColor;

														// 	// Reset the row's background color
														// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
														// }

														// Iterate over all rows in the table
														const rows = customTable.querySelectorAll("tr");
														rows.forEach((row, rowIndex) => {
															// Skip the currently selected row
															if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																return;
															}

															// Reset the background color based on even/odd index
															const originalColor = rowIndex % 2 === 0
																? updatedProps.tBodyEvenTrBackgroundColor
																: updatedProps.tBodyOddTrBackgroundColor;

															row.style.backgroundColor = originalColor;
														});
													};
												});

												// to hide the header buttons after they have been cloned
												headerStellarButtons[j].style.display = "none";
											}
										}
										// }
										console.log("headerStellarButtons:", headerStellarButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating action buttons
									try {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find action-button component in header column
										let headerActionButtons;
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										headerActionButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-action-button");

										if (headerActionButtons.length > 0) {
											if (headerActionButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(headerActionButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												await copyStyles(j, clonedButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedButton.setAttribute("id", `custom_table_action_button_table${id}_row${idx}_column${i}_${j}`);
												if (clonedButton.getElementsByTagName("p")[0]) {
													clonedButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header action button was not in single column component, only then append it to td
												console.log("j.parentNode.classList", j.parentNode.classList);
												if (!j.parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedButton);
													console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedButton.addEventListener("click", async function (event) {
													console.log("action-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
													localStorage.setItem(
														`custom_table_action_button_table${id}_clicked`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															"data-table-action": `${clonedButton.getAttribute("data-table-action")}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedButton.getAttribute("id")}`,
														}),
													);

													// Style for the modals
													let modalStyle = {
														padding: "2%",
														backgroundColor: "white",
														borderRadius: "5px",
														boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
														maxWidth: "50vw",
														width: "30vw",
														maxHeight: "40vh",
														margin: "auto",
														overflow: "auto",
														"-ms-overflow-style": "none",
														"scrollbar-width": "none",
													};

													// Style for the input fields
													let inputFieldStyle = {
														display: "flex",
														flexDirection: "column",
														marginBottom: "15px",
													};

													// Style for the buttons
													let buttonStyle = {
														width: "100%",
														padding: "10px",
														backgroundColor: "#007bff",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let submitButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let closeButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													// Edit modal
													if (clonedButton.getAttribute("data-table-action") === "edit") {
														// Create the dialog element
														let editModal = document.createElement("dialog");
														editModal.setAttribute("id", "custom-modal");

														// Apply styles to the modal
														Object.assign(editModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Edit`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// variable only for the reference keyname user input
														let selectedOptions = [];
														// Check if updatedProps.EditableFields is not empty
														if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
															// Iterate over EditableFields to create form elements
															for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																// to handle non-Reference field keynames
																if (!fieldName.includes("-")) {
																	let fieldLabel = document.createElement("label");
																	fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																	fieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(fieldLabel);

																	let inputField = document.createElement("input");
																	inputField.setAttribute("name", fieldName);
																	inputField.value = obj[fieldName]; // to initialize input fields with present values
																	Object.assign(inputField.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// Set input field type based on field type
																	switch (fieldType.toLowerCase()) {
																		case "string":
																			inputField.setAttribute("type", "text");
																			break;
																		case "boolean":
																			inputField.setAttribute("type", "radio");
																			break;
																		case "number":
																			inputField.setAttribute("type", "number");
																			break;
																		case "date-time":
																			inputField.setAttribute("type", "datetime-local");
																			break;
																		case "time":
																			inputField.setAttribute("type", "time");
																			break;
																		case "file":
																			inputField.setAttribute("type", "file");
																			break;
																		default:
																			inputField.setAttribute("type", "text");
																			break;
																	}
																	modalContent.appendChild(inputField);
																}

																// to handle Reference field keynames for example - WebProject1_Address-Sector
																else if (fieldName.includes("-")) {
																	// variables to be used later in this if-condition
																	let [table, column] = fieldName.split("-");
																	// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																	// create its label
																	let refFieldLabel = document.createElement("label");
																	refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																	refFieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(refFieldLabel);

																	// create a drodown for these keynames > its options will be all the values from response from that table
																	let referenceKeynameDropdown = document.createElement("select");
																	referenceKeynameDropdown.setAttribute("name", fieldName);
																	referenceKeynameDropdown.setAttribute("id", fieldName);
																	// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																	Object.assign(referenceKeynameDropdown.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// populate it with options using the response from its data-model API
																	let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																		headers: {
																			Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																		},
																	});
																	let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																	if (referenceKeynameValueOptionsData) {
																		// setting default option --select--
																		let defaultOption = document.createElement("option");
																		// defaultOption.setAttribute('disabled', 'disabled');
																		defaultOption.innerHTML = "--select--";
																		referenceKeynameDropdown.appendChild(defaultOption);

																		// loop over the response to fill the options of its reference table
																		referenceKeynameValueOptionsData.forEach((optObj) => {
																			let option = document.createElement("option");
																			option.value = optObj[`${column}`];
																			option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																			option.innerHTML = optObj[`${column}`];
																			// to initialize input fields with present values
																			if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																				option.setAttribute("selected", true);
																			}
																			referenceKeynameDropdown.appendChild(option);
																		});
																	}

																	referenceKeynameDropdown.addEventListener("change", function (e) {
																		let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																		// console.log('Selected value:', selectedOptionFromDropdown.value);
																		// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																		// get the reference ID of the table row from main obj to update
																		let refTableId;
																		let tableName = table.split("_")[1];
																		if (obj[tableName] !== null) {
																			refTableId = obj[tableName];
																		}

																		selectedOptions.push({
																			table,
																			column,
																			refTableId,
																			value: selectedOptionFromDropdown.value,
																			"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																		});
																	});

																	modalContent.appendChild(referenceKeynameDropdown);
																}
															}
														}

														console.log("+bu, bubt+", bu, bubt);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";
														buttonContainer.style.marginTop = "5%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-submit-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
														submitButton.innerHTML = "Submit";

														// Apply styles to the button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", function (e) {
															e.preventDefault(); // Prevent default form action
															// Handle form submission here
															console.log("Form submitted!@@", obj);

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Initialize an empty FormData object to get the form's input fields values
															let formData = new FormData(modalContent);
															let formValues = {};
															for (const [key, value] of formData.entries()) {
																formValues[key] = value;
															}

															// Create the to_insert object with the keynames from updatedProps.EditableFields
															// and their corresponding values from formValues
															let to_insert = {};
															Object.keys(updatedProps.EditableFields).forEach((key) => {
																if (formValues[key] !== undefined && !key.includes("-")) {
																	// Check if the form has the field
																	to_insert[key] = formValues[key];
																}

																// re-attach the reference id to PUT call else it will set the references to null
																if (formValues[key] !== undefined && key.includes("-")) {
																	let x = key.split("-");
																	let table = x[0].split("_")[1];
																	if (obj[table] !== null) {
																		to_insert[table] = obj[table];
																	}
																}
															});

															console.log("+to_insert+", to_insert);
															console.log("+selectedOptions+", selectedOptions);

															// logic to update all table cells the edit button is editing without refreshing
															for (const key in to_insert) {
																let columns = [];
																if (updatedProps[`HeaderField_${i}`]) {
																	Object.keys(updatedProps).map((hF_i) => {
																		if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																} else {
																	Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																		if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																}

																// console.log('+columns+', columns);

																columns.forEach(idx => {
																	let td = tr.getElementsByTagName('td')[idx];

																	let valueToUpdateCellWith = to_insert[key];
																	// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																	// Find the first text node
																	const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																	if (textNode) {
																		textNode.nodeValue = valueToUpdateCellWith;
																	} else {
																		// If no text node exists, create one
																		td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																	}
																});
															}

															// Fetch call (to non-reference table) can be made here using the input field values
															// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
															fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "PUT",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
																body: JSON.stringify({
																	to_insert: to_insert,
																}),
															})
																.then((response) => response.json())
																.then((data) => console.log(data));

															// Fetch call (for reference table)
															if (selectedOptions.length > 0) {
																selectedOptions.forEach((data) => {
																	// make api calls per object
																	let requestBody = {};
																	requestBody[data.column] = data.value; // Assign dynamic key-value pair
																	fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																		method: "PUT",
																		headers: {
																			"Content-Type": "application/json",
																			Authorization: `Bearer ${values.BearerToken}`,
																		},
																		body: JSON.stringify({
																			to_insert: requestBody,
																		}),
																	})
																		.then((response) => response.json())
																		.then((data) => console.log(data));
																});
															}

															// Close the modal when the form is submitted
															editModal.close();

															// clear the selectedOptions for next turn
															selectedOptions = [];

															// // giving a delay in reload so that it doesn't change the url in the broswer tab
															// setTimeout(function () {
															// 	window.top.location.reload();
															// }, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-close-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
														closeButton.innerHTML = "Close";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (event) {
															event.preventDefault(); // Prevent default behavior of the click event
															editModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														editModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(editModal);

														// Show the modal
														editModal.showModal();
													}
													// Delete modal
													else if (clonedButton.getAttribute("data-table-action") === "delete") {
														// Create the dialog element
														let deleteModal = document.createElement("dialog");
														deleteModal.setAttribute("id", "delete-modal");

														// Apply styles to the modal
														Object.assign(deleteModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Delete`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// // Create an input field for data model with a default value
														// let dataModelInputField = document.createElement("input");
														// dataModelInputField.setAttribute('type', 'text');
														// dataModelInputField.setAttribute('name', 'DataModel');
														// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
														// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

														// // Apply styles to the input fields
														// Object.assign(dataModelInputField.style, inputFieldStyle);

														// modalContent.appendChild(dataModelInputField);

														// // Create an input field for bearer token with a default value
														// let bearerTokenInputField = document.createElement("input");
														// bearerTokenInputField.setAttribute('type', 'text');
														// bearerTokenInputField.setAttribute('name', 'BearerToken');
														// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
														// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

														// // Apply styles to the input fields
														// Object.assign(bearerTokenInputField.style, inputFieldStyle);

														// modalContent.appendChild(bearerTokenInputField);

														// Create a paragraph tag for confirmation message
														let confirmationMessage = document.createElement("p");
														confirmationMessage.style.marginBottom = "5%";
														confirmationMessage.style.fontSize = "1vw";
														confirmationMessage.innerHTML = "Are you sure?";

														// Append confirmation message to the modal content
														modalContent.appendChild(confirmationMessage);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-delete-yes-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
														submitButton.innerHTML = "Yes";

														// Apply styles to the "Yes" button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", async function (e) {
															e.preventDefault();
															// Handle form submission here
															console.log("Form submitted!");

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Use the values in the fetch call or other logic
															console.log("Input field values:", values);

															// Make the fetch call to delete the item
															let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "DELETE",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
															});
															// .then(response => {
															//     if (response.ok) {
															//         console.log('Item deleted successfully');
															//     } else {
															//         console.error('Failed to delete item');
															//     }
															// });

															let responseData = response.json();
															if (responseData.ok) {
																console.log("Item deleted successfully");
															} else {
																console.error("Failed to delete item");
															}

															// Close the modal when the form is submitted
															deleteModal.close();

															// giving a delay in reload so that it doesn't change the url in the broswer tab
															setTimeout(function () {
																window.top.location.reload();
															}, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-delete-no-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
														closeButton.innerHTML = "No";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (e) {
															e.preventDefault();
															deleteModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														deleteModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(deleteModal);

														// Show the modal
														deleteModal.showModal();
													}
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										console.log("headerActionButtons:", headerActionButtons);
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_file buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerInputFileButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerInputFileButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_file_container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerInputFileButtons) {
											if (headerInputFileButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											// for (let j = 0; j < headerInputFileButtons.length; j++) {
											// 	// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
											// 	let clonedInputFileButton = headerInputFileButtons[j].cloneNode(true);

											// 	// Copy the styles from the original button to the cloned button and its inner elements
											// 	copyStyles(headerInputFileButtons[j], clonedInputFileButton);

											// 	// changing the clone's IDs so that they are present and available individually in LB and in download
											// 	clonedInputFileButton.setAttribute('id', `input_file_container_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].setAttribute('id', `input_file_label_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].getElementsByTagName('p')[0].setAttribute('id', `input_file_label_p_table${id}_row${idx}_column${i}_${j}`);

											// 	td.appendChild(clonedInputFileButton);

											// 	let headerInputFileClickHandler = function(event) {
											// 		console.log('input_file_label clicked', 'saving data in session storage');
											// 		// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

											// 		// this registers all stellar button clicks on LS
											// 		// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
											// 		// this registers only by the individuals in the column header
											// 		localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, JSON.stringify({
											// 			'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
											// 			tableId: `${id}`,
											// 			rowId: `custom_table_tbody_row${idx}_table${id}`,
											// 			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
											// 			buttonId: `${clonedInputFileButton.getAttribute('id')}`,
											// 		}));

											// 		console.log('clonedInputFileButton++', clonedInputFileButton);

											// 		let inputFile = document.createElement('input');
											//         inputFile.type = 'file';
											//         inputFile.style.display = 'none';

											//         inputFile.addEventListener('change', async function (event) {
											//               let file = inputFile.files[0];
											//               console.log('this++', this);
											//               console.log('file is:', file);

											//               let formData = new FormData();
											//               formData.append('file', file);

											//               try {
											//                   const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
											//                       method: 'POST',
											//                       headers: {
											//                           // 'Content-Type': 'multipart/form-data',
											//                       },
											//                       body: formData,
											//                   });

											//                   if (response.ok) {
											//                       const data = await response.json();
											//                       console.log('File uploaded:', data);
											//                       clonedInputFileButton.setAttribute('data_file_url', data["fileLocation"]);

											//                     // Retrieve the object from local storage
											// 			        let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j}`)) || {};

											// 			        // Add the data_file_url and its value to the object
											// 			        clickedFileData.data_file_url = data["fileLocation"];

											// 			        // Convert the modified object back to a JSON string
											// 			        let updatedClickedFileData = JSON.stringify(clickedFileData);

											// 			        // Update the local storage with the modified JSON string
											// 			        localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, updatedClickedFileData);
											//                   } else {
											//                       console.error('Error uploading file:', response.statusText);
											//                   }

											//                   clonedInputFileButton.onclick = null;
											//               } catch (error) {
											//                   console.error('Error uploading file:', error);
											//               } finally {
											//               	console.log('after 1')
											//                   // Remove the inputFile element after API call completes
											//                   if (inputFile && inputFile.parentNode) {
											//                   	console.log('after 2')
											//                       inputFile.parentNode.removeChild(inputFile);
											//                   }
											//               }
											//         });

											//         clonedInputFileButton.appendChild(inputFile);
											//         inputFile.click();
											//         console.log('clicked', clonedInputFileButton);
											// 	};

											// 	// adding click event on stellar-button from the table components
											// 	// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
											// 	clonedInputFileButton.onclick = headerInputFileClickHandler;

											// 	// to hide the header buttons after they have been cloned
											// 	headerInputFileButtons[j].style.display = "none";
											// }

											Array.from(headerInputFileButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
												let clonedInputFileButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedInputFileButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedInputFileButton.setAttribute("id", `input_file_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].setAttribute("id", `input_file_label_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0]) {
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0].setAttribute("id", `input_file_label_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedInputFileButton);

												let headerInputFileClickHandler = function (event) {
													console.log("input_file_label clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_input_file_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedInputFileButton.getAttribute("id")}`,
														}),
													);

													// console.log('clonedInputFileButton++', clonedInputFileButton);

													let inputFile = document.createElement("input");
													inputFile.type = "file";
													inputFile.style.display = "none";

													inputFile.addEventListener("change", async function (event) {
														let file = inputFile.files[0];
														console.log("this++", this);
														console.log("file is:", file);

														let formData = new FormData();
														formData.append("file", file);

														try {
															const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
																method: "POST",
																headers: {
																	// 'Content-Type': 'multipart/form-data',
																},
																body: formData,
															});

															if (response.ok) {
																const data = await response.json();
																console.log("File uploaded:", data);
																td.setAttribute("data_file_url", data["fileLocation"]);

																// Retrieve the object from local storage
																let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`)) || {};

																// Add the data_file_url and its value to the object
																clickedFileData.data_file_url = data["fileLocation"];

																// Convert the modified object back to a JSON string
																let updatedClickedFileData = JSON.stringify(clickedFileData);

																// Update the local storage with the modified JSON string
																localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`, updatedClickedFileData);
															} else {
																console.error("Error uploading file:", response.statusText);
															}
														} catch (error) {
															console.error("Error uploading file:", error);
														} finally {
															// Remove the inputFile element after API call completes
															if (inputFile && inputFile.parentNode) {
																inputFile.parentNode.removeChild(inputFile);
															}
														}
													});

													clonedInputFileButton.appendChild(inputFile);
													inputFile.click();

													if (inputFile && inputFile.parentNode) {
														inputFile.parentNode.removeChild(inputFile);
													}
												};

												// adding click event on stellar-button from the table components
												// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].onclick = headerInputFileClickHandler;

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										console.log("headerInputFileButtons:", headerInputFileButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating radio_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let radioButtonGroups;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										radioButtonGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("radio_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (radioButtonGroups) {
											if (radioButtonGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(radioButtonGroups).forEach((radioButtonGroup, j) => {
												let clonedRadioButtonGroup = radioButtonGroup.cloneNode(true);
												// Copy styles from the original to the cloned button
												copyStyles2(radioButtonGroup, clonedRadioButtonGroup);

												// Change IDs and append cloned radio button groups to the container
												clonedRadioButtonGroup.setAttribute("id", `radio_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each radio button in the group
												clonedRadioButtonGroup.querySelectorAll('input[type="radio"]').forEach((radioButton) => {
													radioButton.addEventListener("click", (event) => {
														// Get the selected radio button's value and id
														const selectedValue = event.target.value;
														const selectedId = event.target.id;
														const selectedDataDbId = event.target.getAttribute("data_db_id") ? event.target.getAttribute("data_db_id") : "";

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_radio_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																radioButtonId: `${selectedId}`,
																selectedValue: `${selectedValue}`,
																selectedDataDbId: `${selectedDataDbId}`,
															}),
														);

														// You can also perform other actions based on the selected radio button
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedRadioButtonGroup);
											});
										}

										// Hide the original radio button groups in the header column
										Array.from(radioButtonGroups).forEach((radioButtonGroup) => {
											radioButtonGroup.style.display = "none";
										});
										// }
										console.log("radioButtonGroups:", radioButtonGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroups;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroups) {
											if (checkboxGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroups).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroups).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										console.log("checkboxGroups:", checkboxGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form_new
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroupsV2;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroupsV2 = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form_new");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroupsV2) {
											if (checkboxGroupsV2.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroupsV2).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_v2_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_v2_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroupsV2).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										console.log("checkboxGroupsV2:", checkboxGroupsV2);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_select
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dropdownContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dropdownContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_select_container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dropdownContainers) {
											if (dropdownContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(dropdownContainers).forEach((dropdownContainer, j) => {
												let dropdown = dropdownContainer.getElementsByTagName("select")[0];
												let dropdownClone = dropdown.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dropdown, dropdownClone);

												// Change IDs and append cloned dropdowns to the container
												dropdownClone.setAttribute("id", `input_select_table${id}_row${idx}_column${i}_${j}`);
												dropdownClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const selectedValue = event.target.value;
													const selectedOption = event.target.options[event.target.selectedIndex];
													const selectedId = selectedOption.id;

													// Get the data-db-id attribute value of the selected option
													const selectedDataDbId = selectedOption.getAttribute("data_db_id") ? selectedOption.getAttribute("data_db_id") : "";

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_input_select_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															selectedValue: `${selectedValue}`,
															selectedId: `${selectedId}`,
															selectedDataDbId: `${selectedDataDbId}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dropdownClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dropdownContainers).forEach((dropdownContainer) => {
												dropdownContainer.style.display = "none";
											});
										}
										// }
										console.log("dropdownContainers:", dropdownContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_text_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputTextContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputTextContainers) {
											if (inputTextContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
												let inputText = inputTextContainer.getElementsByTagName("input")[0];
												let inputTextClone = inputText.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputText, inputTextClone);

												// Change IDs and append cloned dropdowns to the container
												inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
												inputTextClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_text_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputTextClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputTextContainers).forEach((inputTextContainer) => {
												inputTextContainer.style.display = "none";
											});
										}
										// }
										console.log("inputTextContainers:", inputTextContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_number_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputNumberContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputNumberContainers) {
											if (inputNumberContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
												let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
												let inputNumberClone = inputNumber.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputNumber, inputNumberClone);

												// Change IDs and append cloned dropdowns to the container
												inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
												inputNumberClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_number_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputNumberClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
												inputNumberContainer.style.display = "none";
											});
										}
										// }
										console.log("inputNumberContainers:", inputNumberContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating send email buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let sendEmailButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										sendEmailButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("send-email-button-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (sendEmailButtons) {
											if (sendEmailButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(sendEmailButtons).forEach((j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedEmailButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedEmailButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedEmailButton.setAttribute("id", `send_email_button_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedEmailButton.getElementsByClassName("send-email-button")[0].setAttribute("id", `send_email_btn_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0]) {
													clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0].setAttribute("id", `send_email_btn_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedEmailButton);

												const sendEmailHandler = async function (sendEmailButtonContainer) {
													let selectedFile;
													// reason - because this script was getting called multiple times, the dialog element was getting added multiple times so this code will be removing all the previously appended ones and then create a new one on-the-go
													if (sendEmailButtonContainer) {
														let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
														modalAlreadyExists.forEach((modal) => {
															console.log("removing modal 2", modal);
															modal.remove();
														});

														// // fetch filter call for content
														// let filteredResponse = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${extractTableName(updatedProps.customUrlValue)}`, {
														// 	method: "POST",
														// 	headers: {
														// 		'Content-Type': "application/json",
														// 		Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
														// 	},
														// 	body: JSON.stringify({
														// 	    filterData: {
														// 	        _id: `${obj[updatedProps.DataModelObjIdKeyname]}`,
														// 	    }
														// 	}),
														// });

														// let filteredResponseData = await filteredResponse.json();

														// Get the keys from updatedProps.HeaderFields object and from HTML attributes
														const headerFieldKeys = Object.keys(updatedProps.HeaderFields);
														const htmlAttributeKeys = Array.from(customTableContainer.attributes)
															.filter((attr) => attr.name.includes("headerfield_"))
															.map((attr) => attr.value);

														console.log("htmlAttributeKeys", htmlAttributeKeys);

														// Combine the keys from both sources
														const allKeys = [...headerFieldKeys, ...htmlAttributeKeys];

														// Accumulate all key-value pairs
														const keyValuePairs = [];

														// Get the values corresponding to the keys from the obj object
														htmlAttributeKeys.forEach((key) => {
															let value;
															if (/:/.test(key)) {
																// If the key contains a colon (indicating nested properties), split it
																const [mainKey, subKey] = key.split(/\s*:\s*/);
																// Check if the main key exists in obj
																if (obj[`${mainKey}_data`]) {
																	// Check if the main key points to an object
																	if (typeof obj[`${mainKey}_data`] === "object" && obj[`${mainKey}_data`][subKey]) {
																		value = obj[`${mainKey}_data`][subKey];
																		keyValuePairs.push(`${subKey}: ${value}`);
																	}
																}
															} else {
																value = obj[key];
																keyValuePairs.push(`${key}: ${value}`);
															}
															// if (value) {
															//     keyValuePairs.push(`${key}: ${value}`);
															// }
														});

														console.log("allKeys, keyValuePairs", allKeys, keyValuePairs);

														// Join all key-value pairs into a single string
														const filteredKeyValuePairs = keyValuePairs.join("\n");

														console.log("allKeys, filteredKeyValuePairs", allKeys, filteredKeyValuePairs);

														// Create a dialog modal with styling
														const dynamicEmailSendingModal = document.createElement("dialog");
														dynamicEmailSendingModal.setAttribute("class", `custom-email-modal`);
														dynamicEmailSendingModal.setAttribute("id", `custom-email-modal-${sendEmailButtonContainer.id}`);
														Object.assign(dynamicEmailSendingModal.style, {
															margin: "auto",
															width: "35vw",
															overflow: "auto",
															backgroundColor: "white",
															boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
															border: "1px solid #ccc",
															borderRadius: "20px",
															zIndex: "1000",
															padding: "0 0 1% 0",
														});

														// modal UI header & footer
														let modalHeader = document.createElement("div");
														Object.assign(modalHeader.style, {
															// backgroundColor: "#1991E7",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															color: "white",
															fontSize: "1vw",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});
														modalHeader.innerHTML = "Compose Message";

														const modalFooter = document.createElement("div");
														Object.assign(modalFooter.style, {
															backgroundColor: "transparent",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});

														// Create a form element
														const emailForm = document.createElement("form");
														Object.assign(emailForm.style, {
															padding: "3% 6%",
															display: "flex",
															flexDirection: "column",
														});

														// Check if EmailFromDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailFromDynamicCheckbox) {
														const emailFromDiv = document.createElement("div");
														Object.assign(emailFromDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailFromLabel = document.createElement("label");
														emailFromLabel.innerHTML = "From";
														// Create an input email field for the sender's email address with styling
														const emailFromField = document.createElement("input");
														Object.assign(emailFromField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailFromField, {
															type: "email",
															id: `emailFrom-${sendEmailButtonContainer.id}`,
															name: "emailFrom",
															placeholder: "Sender's Email Address",
														});
														// visiblity will be hidden because this field is not actually required and it needs to occupy same space for UI alignment purposes
														const emailFromRequiredFieldLabel = document.createElement("span");
														emailFromRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailFromRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
															visibility: "hidden",
														});

														// Append the email input field to the form
														emailFromDiv.appendChild(emailFromLabel);
														emailFromDiv.appendChild(emailFromField);
														emailFromDiv.appendChild(emailFromRequiredFieldLabel);
														emailForm.appendChild(emailFromDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailToDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailToDynamicCheckbox) {
														const emailToDiv = document.createElement("div");
														Object.assign(emailToDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailToLabel = document.createElement("label");
														emailToLabel.innerHTML = "To";
														// Create an input email field for the receiver's email address with styling
														const emailToField = document.createElement("input");
														Object.assign(emailToField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailToField, {
															type: "text",
															id: `emailTo-${sendEmailButtonContainer.id}`,
															name: "emailTo",
															placeholder: "Receiver's Email Addresses (comma separated)",
														});
														const emailToRequiredFieldLabel = document.createElement("span");
														emailToRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailToRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailToDiv.appendChild(emailToLabel);
														emailToDiv.appendChild(emailToField);
														emailToDiv.appendChild(emailToRequiredFieldLabel);
														emailForm.appendChild(emailToDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailSubjectDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailSubjectDynamicCheckbox) {
														const emailSubjectDiv = document.createElement("div");
														Object.assign(emailSubjectDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailSubjectLabel = document.createElement("label");
														emailSubjectLabel.innerHTML = "Subject";
														// Create an input email field for the receiver's email address with styling
														const emailSubjectField = document.createElement("input");
														Object.assign(emailSubjectField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailSubjectField, {
															type: "text",
															id: `emailSubject-${sendEmailButtonContainer.id}`,
															name: "emailSubject",
															placeholder: "Email's subject",
														});
														const emailSubjectRequiredFieldLabel = document.createElement("span");
														emailSubjectRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailSubjectRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailSubjectDiv.appendChild(emailSubjectLabel);
														emailSubjectDiv.appendChild(emailSubjectField);
														emailSubjectDiv.appendChild(emailSubjectRequiredFieldLabel);
														emailForm.appendChild(emailSubjectDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailBodyDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailBodyDynamicCheckbox) {
														const emailBodyDiv = document.createElement("div");
														Object.assign(emailBodyDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailBodyLabel = document.createElement("label");
														emailBodyLabel.innerHTML = "Body";
														// Create an input email field for the receiver's email address with styling
														const emailBodyField = document.createElement("textarea");
														Object.assign(emailBodyField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailBodyField, {
															rows: "5",
															cols: "10",
															id: `emailBody-${sendEmailButtonContainer.id}`,
															name: "emailBody",
															placeholder: "Email's body",
														});
														const emailBodyRequiredFieldLabel = document.createElement("span");
														emailBodyRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailBodyRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														emailBodyField.value = filteredKeyValuePairs;

														// Append the email input field to the form
														emailBodyDiv.appendChild(emailBodyLabel);
														emailBodyDiv.appendChild(emailBodyField);
														emailBodyDiv.appendChild(emailBodyRequiredFieldLabel);
														emailForm.appendChild(emailBodyDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if FileAttachmentDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.FileAttachmentDynamicCheckbox) {
														// Create an input email field for the receiver's email address with styling
														const fileAttachmentField = document.createElement("input");
														Object.assign(fileAttachmentField.style, {
															// marginBottom: "10px",
															// padding: "5px",
															// width: "80%",
															// borderRadius: "5px",
															// backgroundColor: "transparent",
															// boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
															display: "none",
														});
														Object.assign(fileAttachmentField, {
															type: "file",
															id: `fileAttachment-${sendEmailButtonContainer.id}`,
															name: "fileAttachment",
														});

														// Add event listener for the file input field change event
														fileAttachmentField.addEventListener("change", (event) => {
															selectedFile = event.target.files[0];
															console.log("Selected file:", selectedFile);
															// You can do further processing with the selected file here
														});

														// Create a label for the file input button
														const fileAttachmentLabel = document.createElement("label");
														fileAttachmentLabel.setAttribute("for", "fileAttachmentInput"); // Set the 'for' attribute to match the input field's id
														fileAttachmentLabel.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16"><path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/></svg>';
														Object.assign(fileAttachmentLabel.style, {
															cursor: "pointer",
														});

														// Attach click event to the label
														fileAttachmentLabel.addEventListener("click", () => {
															fileAttachmentField.click();
														});

														// Append the email input field to the form
														modalFooter.appendChild(fileAttachmentLabel);
														modalFooter.appendChild(fileAttachmentField);
														// }
														// // else take directly from props
														// else {}

														// Create a submit button
														const submitButton = document.createElement("button");
														Object.assign(submitButton.style, {
															// marginBottom: "3%",
															// marginRight: "4%",
															alignSelf: "flex-end",
															padding: "1.5% 3%",
															border: "none",
															borderRadius: "25px",
															// backgroundColor: "#007bff",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															color: "white",
															cursor: "pointer",
														});
														submitButton.textContent = "Send";
														submitButton.type = "submit";

														// Add event listener for form submission
														submitButton.addEventListener("click", async (event) => {
															event.preventDefault(); // Prevent form submission

															// Get the entered email values
															const emailFromValue = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`).value : "";
															let emailToValue = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`).value : "";
															emailToValue = emailToValue.includes(",") ? emailToValue.split(/\s*,\s*/) : emailToValue;
															const emailSubjectValue = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`).value : "";
															const emailBodyValue = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`).value : "";
															const fileAttachmentValue = emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`).value : "";

															// to preserve line breaks in email body
															const formattedEmailBodyValue = emailBodyValue.replace(/\n/g, "<br>");

															console.log("form values", emailFromValue, emailToValue, emailSubjectValue, emailBodyValue, formattedEmailBodyValue, fileAttachmentValue);
															console.log("selectedFile", selectedFile);

															// Create a FormData object
															const formData = new FormData();

															// Append form data to FormData object
															formData.append("emailFrom", emailFromValue);
															formData.append("emailTo", emailToValue);
															formData.append("emailSubject", emailSubjectValue);
															formData.append("emailBody", formattedEmailBodyValue);
															formData.append("fileAttachment", selectedFile);

															// Make a fetch call to the API with the entered email address
															try {
																// send email if email - to, subject, body are provided
																if (emailToValue !== "" && emailSubjectValue !== "" && emailBodyValue !== "") {
																	const response = await fetch(`${updatedProps.API_HOST}user/custom_email`, {
																		method: "POST",
																		body: formData,
																	});

																	if (response.ok) {
																		console.log("Email sent successfully!");
																		showNotification(true, "Email sent successfully!");
																	} else {
																		console.error("Failed to send email.");
																		showNotification(false, "Failed to send email. Please check the provided values.");
																	}
																}
															} catch (error) {
																console.error("Error sending email:", error);
																showNotification(false, "Error sending email. Please check the provided values.");
															}

															// Close the modal after form submission
															dynamicEmailSendingModal.close();
														});

														// Create a close button
														const closeButton = document.createElement("button");
														closeButton.setAttribute("class", "custom-email-modal-close");
														closeButton.setAttribute("id", `custom-email-modal-close-${sendEmailButtonContainer.id}`);
														Object.assign(closeButton.style, {
															border: "none",
															borderRadius: "3px",
															backgroundColor: "transparent",
															color: "white",
															cursor: "pointer",
														});
														closeButton.textContent = "X";
														closeButton.type = "button";

														// Add event listener for closing the modal
														closeButton.addEventListener("click", () => {
															dynamicEmailSendingModal.close();
														});

														// Append the form and close button to the modal
														dynamicEmailSendingModal.appendChild(modalHeader);
														dynamicEmailSendingModal.appendChild(emailForm);
														modalHeader.appendChild(closeButton);
														modalFooter.appendChild(submitButton);
														dynamicEmailSendingModal.appendChild(modalFooter);

														// Append the modal to the document body
														document.body.appendChild(dynamicEmailSendingModal);

														// Show the modal
														dynamicEmailSendingModal.showModal();
													}
												};

												// adding click event on stellar-button from the table components
												clonedEmailButton.getElementsByClassName("send-email-button")[0].addEventListener("click", function (event) {
													console.log("send-email-button clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_send_email_button_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedEmailButton.getAttribute("id")}`,
														}),
													);

													sendEmailHandler(clonedEmailButton);
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										console.log("sendEmailButtons:", sendEmailButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form text_area
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let normalTextAreaContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										normalTextAreaContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("normal_text_area_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (normalTextAreaContainers) {
											if (normalTextAreaContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer, j) => {
												// let inputText = inputTextContainer.getElementsByTagName('input')[0];
												let normalTextAreaContainerClone = normalTextAreaContainer.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(normalTextAreaContainer, normalTextAreaContainerClone);

												// Change IDs and append cloned dropdowns to the container
												normalTextAreaContainerClone.setAttribute("id", `normal_text_area_field_table${id}_row${idx}_column${i}_${j}`);

												// Event listener for input event
												normalTextAreaContainerClone.addEventListener("input", (event) => {
													const enteredValue = event.target.value;
													localStorage.setItem(
														`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);
												});

												// Event listener for keypress event with Enter key
												normalTextAreaContainerClone.addEventListener("keypress", (event) => {
													if (event.key === "Enter") {
														const enteredValue = event.target.value;
														localStorage.setItem(
															`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
													}
												});

												// Append the dropdown to the table cell
												td.appendChild(normalTextAreaContainerClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer) => {
												normalTextAreaContainer.style.display = "none";
											});
										}
										// }
										console.log("normalTextAreaContainers:", normalTextAreaContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating custom-table-row-selection-checkbox-container
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let tableRowSelectionCheckboxContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (tableRowSelectionCheckboxContainers) {
											// if (tableRowSelectionCheckboxContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// }

											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
												let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
												let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

												// to store row info accross page change
												const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

												// Change IDs and append cloned dropdowns to the container
												tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
												
												// Retrieve stored data from session storage
									            let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

									            // Check if this checkbox should be checked
									            const existingData = storedData.find(
									                (item) =>
									                    item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
									                    item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
									                    item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
									                    item.paginationIndex === currentPageIndex
									            );
									            if (existingData) {
									                tableRowSelectionCheckboxClone.checked = true;
									            }
												
												tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
													const enteredValue = event.target.value;
													const isChecked = event.target.checked; // Check if the checkbox is checked

													// to store row info accross page change
													const checkboxData = {
												        "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												        tableId: `${id}`,
												        rowId: `custom_table_tbody_row${idx}_table${id}`,
												        cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												        inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
												        enteredValue: `${enteredValue}`,
												        paginationIndex: currentPageIndex, // Include the current page index
												    };

												    // Retrieve existing data
												    let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

													if (isChecked) {
														// Checkbox is checked, add object to local storage
														localStorage.setItem(
															`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
														// console.log("currentPageIndex", currentPageIndex);

														// If checked, check if the entry already exists
									                    const existingIndex = storedData.findIndex(
									                        (item) =>
									                            item.rowId === checkboxData.rowId &&
									                            item.cellId === checkboxData.cellId &&
									                            item.paginationIndex === checkboxData.paginationIndex &&
									                            item.inputId === checkboxData.inputId
									                    );

									                    if (existingIndex !== -1) {
									                        // Update existing entry
									                        storedData[existingIndex] = checkboxData;
									                    } else {
									                        // Add new entry
									                        storedData.push(checkboxData);
									                    }
													} else {
														// Checkbox is unchecked, remove object from local storage
														localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
														
														// If unchecked, remove the entry
									                    storedData = storedData.filter(
									                        (item) =>
									                            !(item.rowId === checkboxData.rowId &&
									                              item.cellId === checkboxData.cellId &&
									                              item.paginationIndex === checkboxData.paginationIndex &&
									                              item.inputId === checkboxData.inputId)
									                    );
													}

													// You can also perform other actions based on the checkbox state
													// For example, update UI, make API calls, etc.
													
													// Update session storage with the modified array
													sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
												});

												// Append the dropdown to the table cell
												td.appendChild(tableRowSelectionCheckboxClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
												tableRowSelectionCheckboxContainer.style.display = "none";
											});
										}
										// }
										console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_date_time field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dateTimeInputContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dateTimeInputContainers) {
											// if (dateTimeInputContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// 	td.style.scrollbarColor = `transparent transparent`;
											// }

											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
												let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
												let dateTimeInputClone = dateTimeInput.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dateTimeInput, dateTimeInputClone);

												// Change IDs and append cloned dropdowns to the container
												dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
												dateTimeInputClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_date_time_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dateTimeInputClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
												dateTimeInputContainer.style.display = "none";
											});
										}
										// }
										console.log("dateTimeInputContainers:", dateTimeInputContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating toggle
									try {
										// plot only if the field_type of header attribute is boolean
										if (fieldType === 'Boolean') {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let toggleContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											toggleContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('.custom-table-toggle-container');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (toggleContainers) {
												// if there are toggle elements being replicated then don't show the data model content in their table cells along with the toggle replicated components
												td.textContent = "";

												// if (toggleContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(toggleContainers).forEach((toggleContainer, j) => {
												    let toggleInnerContainer = toggleContainer.getElementsByClassName("custom-table-inner-toggle-container")[0];
												    let toggleClone = toggleInnerContainer.cloneNode(true);
												    // Copy styles from the original to the cloned dropdown
												    copyStyles(toggleInnerContainer, toggleClone);

												    // Initial state of the toggle
												    let isOn = false;

												    // Change IDs and append cloned dropdowns to the container
												    toggleClone.setAttribute("id", `custom_table_inner_toggle_container_table${id}_row${idx}_column${i}_${j}`);
												    if (toggleClone.getElementsByClassName("custom-table-toggle")[0]) {
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].setAttribute("id", `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`);

												        // update toggle once before/outside click event also so that it initializes according to the value coming from backend. If `content` value is true, toggle should be on, else off.
												        if (content) {
												        	isOn = true;
												        }
												        updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));

												        // now attach event to make the toggle function changing color on on/off actions and sending PUT api calls
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].addEventListener("click", (event) => {
												            isOn = !isOn;

												            // if the cell value is false, change it to true before sending it in PUT call payload, else send it as it it. When toggle is on, 'true' value should be send in the backend.
												            let cellValue = content ? false : true;

												            // a PUT api call will be made to update that particular record of the given table
												            updateTable(cellValue, `${obj[updatedProps.DataModelObjIdKeyname]}`, updatedProps.updatingCustomUrlValue, updatedProps.updatingBaseUrlValue, updatedProps.updatingApiPathValue, updatedProps.updatingApiQueryParamValue, updatedProps.updatingApiTableAttributeValue, updatedProps.updatingApiMethodValue, updatedProps.updatingApiUsernameValue, updatedProps.updatingApiPasswordValue, updatedProps.updatingApiBearerTokenValue, updatedProps.updatingApiMoreHeadersValue);

												            updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));
												            console.log(`Toggle is now: ${isOn ? 'ON' : 'OFF'}`, content);

												            // Construct the localStorage key
												            let toggleLocalStorageKey = `clicked_custom_table_toggle_table${id}_column${i}`;

												            // Retrieve existing data from local storage
												            let togglesData = JSON.parse(localStorage.getItem(toggleLocalStorageKey)) || [];

												            // Create the new toggle data object
												            let newToggleData = {
												                "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												                tableId: `${id}`,
												                rowId: `custom_table_tbody_row${idx}_table${id}`,
												                cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												                toggleEleId: `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`,
												                toggleStatus: isOn,
												            };

												            // Check if the toggle already exists
												            let existingToggleIndex = togglesData.findIndex(toggle => toggle.toggleEleId === newToggleData.toggleEleId);

												            if (existingToggleIndex !== -1) {
												                // Update the existing toggle data
												                togglesData[existingToggleIndex] = newToggleData;
												            } else {
												                // Add the new toggle data
												                togglesData.push(newToggleData);
												            }

												            // Update the local storage with the new/updated toggle data, column-wise
												            localStorage.setItem(toggleLocalStorageKey, JSON.stringify(togglesData));
												        });
												    }

												    // Append the dropdown to the table cell
												    td.appendChild(toggleClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(toggleContainers).forEach((toggleContainer) => {
													toggleContainer.style.display = "none";
												});
											}
											// }
											console.log("toggleContainers:", toggleContainers);
											// }
										}										
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating rich text input
                                    try {
                                        console.log("i", i);

                                        let inputTextContainers;

                                        console.log("customTableThead", customTableThead);
                                        console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

                                        let richTextIndexesData = updatedProps.richTextIndexes;
                                        console.log("richTextIndexesData::::::::::", richTextIndexesData );

                                        if (richTextIndexesData.length > 0) {
                                            richTextIndexesData.forEach((x) => {
                                                console.log("richTextIndexesData x::::::::::", x);
                                                console.log("richTextIndexesData::::::", i);
                                                console.log("richTextIndexesData::::::", i);

                                                if (x === i) {
                                                    td.innerHTML = "";
                                                    let wrapperDiv = document.createElement("div");
                                                    wrapperDiv.setAttribute(
                                                        "id",
                                                        `rich_text_container_${idx}_${id}`
                                                    );
                                                    wrapperDiv.setAttribute(
                                                        "class",
                                                        "rich_text_table_container"
                                                    );

                                                    wrapperDiv.style.backgroundColor = "white";

                                                    td.appendChild(wrapperDiv);

                                                    let quill = new Quill(wrapperDiv, {
                                                        modules: {
                                                            toolbar: updatedProps.richTextOptions.toolbar ? [
                                                                [{
                                                                    header: [1, 2, 3, 4, 5, 6, false]
                                                                }],
                                                                ["bold", "italic", "underline", "strike"],
                                                                [{
                                                                    list: "bullet"
                                                                }, {
                                                                    list: "ordered"
                                                                }],
                                                                ["link"],
                                                                [{
                                                                    align: []
                                                                }],
                                                                [{
                                                                    color: []
                                                                }, {
                                                                    background: []
                                                                }],
                                                            ] : false,
                                                        },
                                                        placeholder: "",
                                                        theme: "snow",
                                                    });    

                                                    updatedProps.richTextOptions.readOnly ? quill.enable(false) : quill.enable(true);

                                                    if (content) {
                                                        console.log('contentRich:::::::',content)
                                                        let jsonContent = JSON.parse(content);
                                                        console.log('jsonContent:::::::::',jsonContent)
                                                        console.log('type jsonContent:::::::::',typeof jsonContent)
                                                        if (jsonContent ) {
                                                            console.log('inside jsonContent')
                                                            quill.setContents(jsonContent);
                                                        }
                                                    } else {
                                                        let richTextContent;
                                                        richTextContent = sessionStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );
                                                        if (!richTextContent) richTextContent = localStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );

                                                        let parsedData = JSON.parse(richTextContent);
                                                        if (parsedData && parsedData.delta) {
                                                            quill.setContents(parsedData.delta);
                                                        }                                                           
                                                   	}

                                                    quill.on("text-change", () => {
                                                        const delta = quill.getContents();

                                                        let deltaRef = {
                                                            "data-db-id": `${
                                                                obj[updatedProps.DataModelObjIdKeyname]
                                                                }`,
                                                            delta:delta
                                                        }
                                                        
                                                        localStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );

                                                        sessionStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );
                                                    });
                                                }
                                            });
                                        }

                                        // }
                                        console.log("inputTextContainers:", inputTextContainers);
                                        // }
                                    } catch (e) {
										// statements
										console.log(e);
									}

									tr.append(td);
								}

								if (customTableTbody) {
									customTableTbody.append(tr);
								}								
								console.log("+idx+", idx);
							}
						});
					}

					// if the response is a nested array-of-objects
					else if (typeof apiData === "object" && !isArrayofObjects(apiData) && !hasArrayOfObjectsWithinArray(apiData) && !Object.keys(apiData).includes("message")) {
						let nestedArrOfObj = findKeyValue(apiData, updatedProps.nestedArrOfObjKeynameValue);
						console.log("api nestedArrOfObj", nestedArrOfObj);

						if (isArrayofObjects(nestedArrOfObj)) {
							console.log("#");

							// loop over nestedArrOfObj array as a table's row
							nestedArrOfObj.forEach((obj, idx) => {
								if (idx < updatedProps.numRowsPerPageValue) {
									let tr = document.createElement("tr");
									tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
									tr.setAttribute("class", "custom-table-tbody-row");
									// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
									tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
									tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
									tr.style.width = "100%";
									if (idx % 2 !== 0) {
										tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
										// hover effect
										tr.addEventListener("mouseover", function () {
											tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
										});
										tr.addEventListener("mouseleave", function () {
											tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
										});
									} else {
										tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
										// hover effect
										tr.addEventListener("mouseover", function () {
											tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
										});
										tr.addEventListener("mouseleave", function () {
											tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
										});
									}

									// get data and add it as table's column
									for (let i = 0; i < updatedProps.numColsInputValue; i++) {
										let content, convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
										// using updatedProps.HeaderFields because only these are present in the downloading code
										// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
										// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
										// }
										// field name and type are for toggle replication
										let fieldName;

										try {
											if (`HeaderField_${i}` in updatedProps) {
												// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
												if (updatedProps[`HeaderField_${i}`].includes(":")) {
													// Replace spaces around colon with an empty string
													const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
													// Split the modified string by colon
													const referenceArray = modifiedStr.split(":");

													const referenceArrayObjName = `${referenceArray[0]}_data`;
													const referenceArrayKeyName = referenceArray[1];

													console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
													console.log(obj[referenceArrayObjName]);
													// if the reference object in not undefined, then find the further keyname
													if (obj[referenceArrayObjName]) {
														content = obj[referenceArrayObjName][referenceArrayKeyName];
													}
													// content = obj[referenceArrayObjName][referenceArrayKeyName];
												} else {
													// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
													// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
													if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
														// let convertedDate;
														dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
														targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
														switch (targetFormat) {
															case "":
																convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
																break;

															case "date_mon_year":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
																break;
															case "date_month_year":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
																break;
															case "dd/mm/yyyy":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
																break;
															case "mm/dd/yyyy":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
																break;
															case "dd-mm-yyyy":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
																break;

															case "date_mon_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
																break;
															case "date_month_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
																break;
															case "dd/mm/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
																break;
															case "mm/dd/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
																break;
															case "dd-mm-yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
																break;

															case "date_mon_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
																break;
															case "date_month_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
																break;
															case "dd/mm/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
																break;
															case "mm/dd/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
																break;
															case "dd-mm-yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
																break;

															default:
																console.error("Invalid target date format");
														}

														content = convertedDate;
														console.log("converted content 0", dateString, content);
													} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
														numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
														console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

														content = convertedNumber;
														console.log("converted content 0", convertedNumber, content);
													} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
														timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
														console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

														content = convertedNumber;
														console.log("converted content 0", convertedNumber, content);
													} else {
														content = obj[`${updatedProps[`HeaderField_${i}`]}`];
													}

													// applying function from session storage on specific column (by index)
													if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
														try {
															// Retrieve the function string from sessionStorage
															const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);
	
															if (storedFuncString) {
																// Recreate the function using the Function constructor
																const recreatedFunction = new Function(`return (${storedFuncString})`)();
																console.log('Function recreated:', recreatedFunction);
	
																// Use the recreated function
																content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
																console.log('+recreatedFunction content+', content);
															}
														} catch (error) {
															console.error(error);
														}
													}
												}
												console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
											} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
												// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
												if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
													// Replace spaces around colon with an empty string
													const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
													// Split the modified string by colon
													const referenceArray = modifiedStr.split(":");

													const referenceArrayObjName = `${referenceArray[0]}_data`;
													const referenceArrayKeyName = referenceArray[1];

													console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
													console.log(obj[referenceArrayObjName]);
													// if the reference object in not undefined, then find the further keyname
													if (obj[referenceArrayObjName]) {
														content = obj[referenceArrayObjName][referenceArrayKeyName];
													}
													// content = obj[referenceArrayObjName][referenceArrayKeyName];
												} else {
													// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
													if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
														// let convertedDate;
														dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
														switch (targetFormat) {
															case "":
																convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
																break;

															case "date_mon_year":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
																break;
															case "date_month_year":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
																break;
															case "dd/mm/yyyy":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
																break;
															case "mm/dd/yyyy":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
																break;
															case "dd-mm-yyyy":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
																break;

															case "date_mon_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
																break;
															case "date_month_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
																break;
															case "dd/mm/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
																break;
															case "mm/dd/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
																break;
															case "dd-mm-yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
																break;

															case "date_mon_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
																break;
															case "date_month_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
																break;
															case "dd/mm/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
																break;
															case "mm/dd/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
																break;
															case "dd-mm-yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
																break;

															default:
																console.error("Invalid target date format");
														}

														content = convertedDate;
														console.log("converted content 1", dateString, content);
													} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
													} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
														timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
														console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

														content = convertedNumber;
														console.log("converted content 1", convertedNumber, content);
													} else {
															content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													}

													// applying function from session storage on specific column (by index)
													if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
														try {
															// Retrieve the function string from sessionStorage
															const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);
	
															if (storedFuncString) {
																// Recreate the function using the Function constructor
																const recreatedFunction = new Function(`return (${storedFuncString})`)();
																console.log('Function recreated:', recreatedFunction);
	
																// Use the recreated function
																content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
																console.log('+recreatedFunction content+', content);
															}
														} catch (error) {
															console.error(error);
														}
													}
													// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												}
												console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
											} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
												// Get the value of the attribute from 'this'
												console.log("customTableContainer", customTableContainer);
												const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

												// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
												if (headerFieldAttribute.includes(":")) {
													// Replace spaces around colon with an empty string
													const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
													// Split the modified string by colon
													const referenceArray = modifiedStr.split(":");

													const referenceArrayObjName = `${referenceArray[0]}_data`;
													const referenceArrayKeyName = referenceArray[1];

													console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
													console.log(obj[referenceArrayObjName]);
													// if the reference object is not undefined, then find the further keyname
													if (obj[referenceArrayObjName]) {
														content = obj[referenceArrayObjName][referenceArrayKeyName];
													}
												} else {
													// content = obj[headerFieldAttribute];
													// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
													if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
														// let convertedDate;
														dateString = obj[headerFieldAttribute];
														targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
														switch (targetFormat) {
															case "":
																convertedDate = obj[headerFieldAttribute];
																break;

															case "date_mon_year":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
																break;
															case "date_month_year":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
																break;
															case "dd/mm/yyyy":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
																break;
															case "mm/dd/yyyy":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
																break;
															case "dd-mm-yyyy":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
																break;

															case "date_mon_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
																break;
															case "date_month_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
																break;
															case "dd/mm/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
																break;
															case "mm/dd/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
																break;
															case "dd-mm-yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
																break;

															case "date_mon_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
																break;
															case "date_month_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
																break;
															case "dd/mm/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
																break;
															case "mm/dd/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
																break;
															case "dd-mm-yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
																break;

															default:
																console.error("Invalid target date format");
														}

														content = convertedDate;
														console.log("converted content 2", dateString, content);
													} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
														numberString = obj[headerFieldAttribute];
														let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
														console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

														content = convertedNumber;
														console.log("converted content 2", convertedNumber, content);
													} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
														timezoneString = obj[headerFieldAttribute];
														let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
														console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

														content = convertedNumber;
														console.log("converted content 2", convertedNumber, content);
													} else {
														content = obj[headerFieldAttribute];
													}

													// applying function from session storage on specific column (by index)
													if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
														try {
															// Retrieve the function string from sessionStorage
															const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);
	
															if (storedFuncString) {
																// Recreate the function using the Function constructor
																const recreatedFunction = new Function(`return (${storedFuncString})`)();
																console.log('Function recreated:', recreatedFunction);
	
																// Use the recreated function
																content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
																console.log('+recreatedFunction content+', content);
															}
														} catch (error) {
															console.error(error);
														}
													}
												}
												console.log("headerFieldAttribute", headerFieldAttribute, content);
											}
										} catch (e) {
											// statements
											console.error(e);
										}

										// field name and type are for toggle replication
										let fieldType;
										let tableDataFields;
	                  					if (tableData && ("fields" in tableData)) tableDataFields = tableData.fields;
										if (tableDataFields) fieldType = tableDataFields.filter((x) => x.field_name === fieldName)[0]?.field_type;
										
	            						// console.log("+fieldType+", fieldType);

										// console.log("content, i", content, i);

										let td = document.createElement("td");
										td.setAttribute("data-gjs-type", "cell");
										// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
										td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
										td.style.padding = "8px";
										td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
										td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
										td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
										td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
										td.style.borderCollapse = "collapse";
										td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
										td.style.color = `${updatedProps.tBodyTdFontColor}`;
										td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
										td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
										td.innerHTML = content !== undefined ? content : "";

										// for replicating single column component
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerSingleColumnComponents;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerSingleColumnComponents = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('div[data-gjs-type="custom-table-thead-row"][class*="gjs-row"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerSingleColumnComponents) {
											if (headerSingleColumnComponents.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerSingleColumnComponents.length; j++) {
												// row.getElementsByTagName('td')[table_selected_column].append(headerSingleColumnComponents[j]);
												let clonedSingleColumnComponent = headerSingleColumnComponents[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerSingleColumnComponents[j], clonedSingleColumnComponent);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedSingleColumnComponent.setAttribute("id", `single_column_component_table${id}_row${idx}_column${i}_${j}`);

												// for replicating ACTION BUTTON inside single column components
												let innerClonedActionButtons = clonedSingleColumnComponent.getElementsByClassName("custom-table-action-button");
												if (innerClonedActionButtons.length > 0) {
													Array.from(innerClonedActionButtons).forEach((innerClonedActionButton, j_btn) => {
														if (innerClonedActionButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedActionButton.setAttribute("id", `custom_table_action${innerClonedActionButton.getAttribute("data-table-action")}_button_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedActionButton.getElementsByTagName("p")[0]) {
																innerClonedActionButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedActionButton.addEventListener("click", async function (event) {
																console.log("action-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
																localStorage.setItem(
																	`custom_table_action_button_table${id}_${innerClonedActionButton.getAttribute("data-table-action")}_column${i}_${j}_element${j_btn}_clicked`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"data-table-action": `${innerClonedActionButton.getAttribute("data-table-action")}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedActionButton.getAttribute("id")}`,
																	}),
																);

																// Style for the modals
																let modalStyle = {
																	padding: "2%",
																	backgroundColor: "white",
																	borderRadius: "5px",
																	boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																	maxWidth: "50vw",
																	width: "30vw",
																	maxHeight: "40vh",
																	margin: "auto",
																	overflow: "auto",
																	"-ms-overflow-style": "none",
																	"scrollbar-width": "none",
																};

																// Style for the input fields
																let inputFieldStyle = {
																	display: "flex",
																	flexDirection: "column",
																	marginBottom: "15px",
																};

																// Style for the buttons
																let buttonStyle = {
																	width: "100%",
																	padding: "10px",
																	backgroundColor: "#007bff",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let submitButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let closeButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																// Edit modal
																if (innerClonedActionButton.getAttribute("data-table-action") === "edit") {
																	// Create the dialog element
																	let editModal = document.createElement("dialog");
																	editModal.setAttribute("id", "custom-modal");

																	// Apply styles to the modal
																	Object.assign(editModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Edit`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// variable only for the reference keyname user input
																	let selectedOptions = [];
																	// Check if updatedProps.EditableFields is not empty
																	if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																		// Iterate over EditableFields to create form elements
																		for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																			// to handle non-Reference field keynames
																			if (!fieldName.includes("-")) {
																				let fieldLabel = document.createElement("label");
																				fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																				fieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(fieldLabel);

																				let inputField = document.createElement("input");
																				inputField.setAttribute("name", fieldName);
																				inputField.value = obj[fieldName]; // to initialize input fields with present values
																				Object.assign(inputField.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// Set input field type based on field type
																				switch (fieldType.toLowerCase()) {
																					case "string":
																						inputField.setAttribute("type", "text");
																						break;
																					case "boolean":
																						inputField.setAttribute("type", "radio");
																						break;
																					case "number":
																						inputField.setAttribute("type", "number");
																						break;
																					case "date-time":
																						inputField.setAttribute("type", "datetime-local");
																						break;
																					case "time":
																						inputField.setAttribute("type", "time");
																						break;
																					case "file":
																						inputField.setAttribute("type", "file");
																						break;
																					default:
																						inputField.setAttribute("type", "text");
																						break;
																				}
																				modalContent.appendChild(inputField);
																			}

																			// to handle Reference field keynames for example - WebProject1_Address-Sector
																			else if (fieldName.includes("-")) {
																				// variables to be used later in this if-condition
																				let [table, column] = fieldName.split("-");
																				// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																				// create its label
																				let refFieldLabel = document.createElement("label");
																				refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																				refFieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(refFieldLabel);

																				// create a drodown for these keynames > its options will be all the values from response from that table
																				let referenceKeynameDropdown = document.createElement("select");
																				referenceKeynameDropdown.setAttribute("name", fieldName);
																				referenceKeynameDropdown.setAttribute("id", fieldName);
																				// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																				Object.assign(referenceKeynameDropdown.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// populate it with options using the response from its data-model API
																				let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																					headers: {
																						Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																					},
																				});
																				let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																				if (referenceKeynameValueOptionsData) {
																					// setting default option --select--
																					let defaultOption = document.createElement("option");
																					// defaultOption.setAttribute('disabled', 'disabled');
																					defaultOption.innerHTML = "--select--";
																					referenceKeynameDropdown.appendChild(defaultOption);

																					// loop over the response to fill the options of its reference table
																					referenceKeynameValueOptionsData.forEach((optObj) => {
																						let option = document.createElement("option");
																						option.value = optObj[`${column}`];
																						option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																						option.innerHTML = optObj[`${column}`];
																						// to initialize input fields with present values
																						if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																							option.setAttribute("selected", true);
																						}
																						referenceKeynameDropdown.appendChild(option);
																					});
																				}

																				referenceKeynameDropdown.addEventListener("change", function (e) {
																					let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																					// console.log('Selected value:', selectedOptionFromDropdown.value);
																					// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																					// get the reference ID of the table row from main obj to update
																					let refTableId;
																					let tableName = table.split("_")[1];
																					if (obj[tableName] !== null) {
																						refTableId = obj[tableName];
																					}

																					selectedOptions.push({
																						table,
																						column,
																						refTableId,
																						value: selectedOptionFromDropdown.value,
																						"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																					});
																				});

																				modalContent.appendChild(referenceKeynameDropdown);
																			}
																		}
																	}

																	console.log("+bu, bubt+", bu, bubt);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";
																	buttonContainer.style.marginTop = "5%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-submit-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
																	submitButton.innerHTML = "Submit";

																	// Apply styles to the button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", function (e) {
																		e.preventDefault(); // Prevent default form action
																		// Handle form submission here
																		console.log("Form submitted!", obj);

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Initialize an empty FormData object to get the form's input fields values
																		let formData = new FormData(modalContent);
																		let formValues = {};
																		for (const [key, value] of formData.entries()) {
																			formValues[key] = value;
																		}

																		// Create the to_insert object with the keynames from updatedProps.EditableFields
																		// and their corresponding values from formValues
																		let to_insert = {};
																		Object.keys(updatedProps.EditableFields).forEach((key) => {
																			if (formValues[key] !== undefined && !key.includes("-")) {
																				// Check if the form has the field
																				to_insert[key] = formValues[key];
																			}

																			// re-attach the reference id to PUT call else it will set the references to null
																			if (formValues[key] !== undefined && key.includes("-")) {
																				let x = key.split("-");
																				let table = x[0].split("_")[1];
																				if (obj[table] !== null) {
																					to_insert[table] = obj[table];
																				}
																			}
																		});

																		console.log("+to_insert+", to_insert);
																		console.log("+selectedOptions+", selectedOptions);

																		// logic to update all table cells the edit button is editing without refreshing
																		for (const key in to_insert) {
																			let columns = [];
																			if (updatedProps[`HeaderField_${i}`]) {
																				Object.keys(updatedProps).map((hF_i) => {
																					if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			} else {
																				Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																					if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			}

																			// console.log('+columns+', columns);

																			columns.forEach(idx => {
																				let td = tr.getElementsByTagName('td')[idx];

																				let valueToUpdateCellWith = to_insert[key];
																				// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																				// Find the first text node
																				const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																				if (textNode) {
																					textNode.nodeValue = valueToUpdateCellWith;
																				} else {
																					// If no text node exists, create one
																					td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																				}
																			});
																		}

																		// Fetch call (to non-reference table) can be made here using the input field values
																		// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																		fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "PUT",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																			body: JSON.stringify({
																				to_insert: to_insert,
																			}),
																		})
																			.then((response) => response.json())
																			.then((data) => console.log(data));

																		// Fetch call (for reference table)
																		if (selectedOptions.length > 0) {
																			selectedOptions.forEach((data) => {
																				// make api calls per object
																				let requestBody = {};
																				requestBody[data.column] = data.value; // Assign dynamic key-value pair
																				fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																					method: "PUT",
																					headers: {
																						"Content-Type": "application/json",
																						Authorization: `Bearer ${values.BearerToken}`,
																					},
																					body: JSON.stringify({
																						to_insert: requestBody,
																					}),
																				})
																					.then((response) => response.json())
																					.then((data) => console.log(data));
																			});
																		}

																		// Close the modal when the form is submitted
																		editModal.close();

																		// clear the selectedOptions for next turn
																		selectedOptions = [];

																		// // giving a delay in reload so that it doesn't change the url in the broswer tab
																		// setTimeout(function () {
																		// 	window.top.location.reload();
																		// }, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-close-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
																	closeButton.innerHTML = "Close";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (event) {
																		event.preventDefault(); // Prevent default behavior of the click event
																		editModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	editModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(editModal);

																	// Show the modal
																	editModal.showModal();
																}
																// Delete modal
																else if (innerClonedActionButton.getAttribute("data-table-action") === "delete") {
																	// Create the dialog element
																	let deleteModal = document.createElement("dialog");
																	deleteModal.setAttribute("id", "delete-modal");

																	// Apply styles to the modal
																	Object.assign(deleteModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Delete`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// // Create an input field for data model with a default value
																	// let dataModelInputField = document.createElement("input");
																	// dataModelInputField.setAttribute('type', 'text');
																	// dataModelInputField.setAttribute('name', 'DataModel');
																	// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
																	// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(dataModelInputField.style, inputFieldStyle);

																	// modalContent.appendChild(dataModelInputField);

																	// // Create an input field for bearer token with a default value
																	// let bearerTokenInputField = document.createElement("input");
																	// bearerTokenInputField.setAttribute('type', 'text');
																	// bearerTokenInputField.setAttribute('name', 'BearerToken');
																	// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
																	// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(bearerTokenInputField.style, inputFieldStyle);

																	// modalContent.appendChild(bearerTokenInputField);

																	// Create a paragraph tag for confirmation message
																	let confirmationMessage = document.createElement("p");
																	confirmationMessage.style.marginBottom = "5%";
																	confirmationMessage.style.fontSize = "1vw";
																	confirmationMessage.innerHTML = "Are you sure?";

																	// Append confirmation message to the modal content
																	modalContent.appendChild(confirmationMessage);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-delete-yes-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
																	submitButton.innerHTML = "Yes";

																	// Apply styles to the "Yes" button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", async function (e) {
																		e.preventDefault();
																		// Handle form submission here
																		console.log("Form submitted!");

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Use the values in the fetch call or other logic
																		console.log("Input field values:", values);

																		// Make the fetch call to delete the item
																		let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "DELETE",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																		});
																		// .then(response => {
																		//     if (response.ok) {
																		//         console.log('Item deleted successfully');
																		//     } else {
																		//         console.error('Failed to delete item');
																		//     }
																		// });

																		let responseData = response.json();
																		if (responseData.ok) {
																			console.log("Item deleted successfully");
																		} else {
																			console.error("Failed to delete item");
																		}

																		// Close the modal when the form is submitted
																		deleteModal.close();

																		// giving a delay in reload so that it doesn't change the url in the broswer tab
																		setTimeout(function () {
																			window.top.location.reload();
																		}, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-delete-no-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
																	closeButton.innerHTML = "No";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (e) {
																		e.preventDefault();
																		deleteModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	deleteModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(deleteModal);

																	// Show the modal
																	deleteModal.showModal();
																}
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedActionButton);
														}
													});
												}

												// for replicating STELLAR BUTTON inside single column components
												let innerClonedStellarButtons = clonedSingleColumnComponent.getElementsByClassName("stellar-button-container");
												if (innerClonedStellarButtons.length > 0) {
													Array.from(innerClonedStellarButtons).forEach((innerClonedStellarButton, j_btn) => {
														if (innerClonedStellarButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
																innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedStellarButton.addEventListener("click", function (event) {
																console.log("stellar-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

																// this registers all stellar button clicks on LS
																// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
																// this registers only by the individuals in the column header
																localStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);

																sessionStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedStellarButton);
														}
													});
												}

												td.appendChild(clonedSingleColumnComponent);

												// to hide the header buttons after they have been cloned
												headerSingleColumnComponents[j].style.display = "none";
											}
										}
										// }
										console.log("headerSingleColumnComponents:", headerSingleColumnComponents);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating stellar buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerStellarButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										console.log("headerStellarButtons1:", headerStellarButtons);
										if (headerStellarButtons) {
											if (headerStellarButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerStellarButtons.length; j++) {
												console.log("headerStellarButtons[j]", headerStellarButtons[j]);
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerStellarButtons[j], clonedStellarButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
												clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
												if (clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
													clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header stellar button was not in single column component, only then append it to td
												if (!headerStellarButtons[j].parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedStellarButton);
													console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedStellarButton.addEventListener("click", function (event) {
													console.log("stellar-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													sessionStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													// highlight the table row that the stellar button has selected
													if (updatedProps.tBodyTrSelectColorHighlight)
														clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";

													customTable.onclick = function(event) {														
														// Check if the click is inside the custom table but outside the clicked row
														// if (
														// 	customTable.contains(event.target) && // Click is inside the customTable
														// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
														// ) {
														// 	console.log('@deselecting row', idx);
														// 	// Determine original color based on row index (even/odd)
														// 	const originalColor = idx % 2 === 0
														// 		? updatedProps.tBodyEvenTrBackgroundColor
														// 		: updatedProps.tBodyOddTrBackgroundColor;

														// 	// Reset the row's background color
														// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
														// }

														// Iterate over all rows in the table
														const rows = customTable.querySelectorAll("tr");
														rows.forEach((row, rowIndex) => {
															// Skip the currently selected row
															if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																return;
															}

															// Reset the background color based on even/odd index
															const originalColor = rowIndex % 2 === 0
																? updatedProps.tBodyEvenTrBackgroundColor
																: updatedProps.tBodyOddTrBackgroundColor;

															row.style.backgroundColor = originalColor;
														});
													};
												});

												// to hide the header buttons after they have been cloned
												headerStellarButtons[j].style.display = "none";
											}
										}
										// }
										console.log("headerStellarButtons:", headerStellarButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating action buttons
									try {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find action-button component in header column
										let headerActionButtons;
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										headerActionButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-action-button");

										if (headerActionButtons.length > 0) {
											if (headerActionButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(headerActionButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												await copyStyles(j, clonedButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedButton.setAttribute("id", `custom_table_action_button_table${id}_row${idx}_column${i}_${j}`);
												if (clonedButton.getElementsByTagName("p")[0]) {
													clonedButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header action button was not in single column component, only then append it to td
												console.log("j.parentNode.classList", j.parentNode.classList);
												if (!j.parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedButton);
													console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedButton.addEventListener("click", async function (event) {
													console.log("action-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
													localStorage.setItem(
														`custom_table_action_button_table${id}_clicked`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															"data-table-action": `${clonedButton.getAttribute("data-table-action")}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedButton.getAttribute("id")}`,
														}),
													);

													// Style for the modals
													let modalStyle = {
														padding: "2%",
														backgroundColor: "white",
														borderRadius: "5px",
														boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
														maxWidth: "50vw",
														width: "30vw",
														maxHeight: "40vh",
														margin: "auto",
														overflow: "auto",
														"-ms-overflow-style": "none",
														"scrollbar-width": "none",
													};

													// Style for the input fields
													let inputFieldStyle = {
														display: "flex",
														flexDirection: "column",
														marginBottom: "15px",
													};

													// Style for the buttons
													let buttonStyle = {
														width: "100%",
														padding: "10px",
														backgroundColor: "#007bff",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let submitButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let closeButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													// Edit modal
													if (clonedButton.getAttribute("data-table-action") === "edit") {
														// Create the dialog element
														let editModal = document.createElement("dialog");
														editModal.setAttribute("id", "custom-modal");

														// Apply styles to the modal
														Object.assign(editModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Edit`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// variable only for the reference keyname user input
														let selectedOptions = [];
														// Check if updatedProps.EditableFields is not empty
														if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
															// Iterate over EditableFields to create form elements
															for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																// to handle non-Reference field keynames
																if (!fieldName.includes("-")) {
																	let fieldLabel = document.createElement("label");
																	fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																	fieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(fieldLabel);

																	let inputField = document.createElement("input");
																	inputField.setAttribute("name", fieldName);
																	inputField.value = obj[fieldName]; // to initialize input fields with present values
																	Object.assign(inputField.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// Set input field type based on field type
																	switch (fieldType.toLowerCase()) {
																		case "string":
																			inputField.setAttribute("type", "text");
																			break;
																		case "boolean":
																			inputField.setAttribute("type", "radio");
																			break;
																		case "number":
																			inputField.setAttribute("type", "number");
																			break;
																		case "date-time":
																			inputField.setAttribute("type", "datetime-local");
																			break;
																		case "time":
																			inputField.setAttribute("type", "time");
																			break;
																		case "file":
																			inputField.setAttribute("type", "file");
																			break;
																		default:
																			inputField.setAttribute("type", "text");
																			break;
																	}
																	modalContent.appendChild(inputField);
																}

																// to handle Reference field keynames for example - WebProject1_Address-Sector
																else if (fieldName.includes("-")) {
																	// variables to be used later in this if-condition
																	let [table, column] = fieldName.split("-");
																	// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																	// create its label
																	let refFieldLabel = document.createElement("label");
																	refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																	refFieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(refFieldLabel);

																	// create a drodown for these keynames > its options will be all the values from response from that table
																	let referenceKeynameDropdown = document.createElement("select");
																	referenceKeynameDropdown.setAttribute("name", fieldName);
																	referenceKeynameDropdown.setAttribute("id", fieldName);
																	// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																	Object.assign(referenceKeynameDropdown.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// populate it with options using the response from its data-model API
																	let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																		headers: {
																			Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																		},
																	});
																	let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																	if (referenceKeynameValueOptionsData) {
																		// setting default option --select--
																		let defaultOption = document.createElement("option");
																		// defaultOption.setAttribute('disabled', 'disabled');
																		defaultOption.innerHTML = "--select--";
																		referenceKeynameDropdown.appendChild(defaultOption);

																		// loop over the response to fill the options of its reference table
																		referenceKeynameValueOptionsData.forEach((optObj) => {
																			let option = document.createElement("option");
																			option.value = optObj[`${column}`];
																			option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																			option.innerHTML = optObj[`${column}`];
																			// to initialize input fields with present values
																			if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																				option.setAttribute("selected", true);
																			}
																			referenceKeynameDropdown.appendChild(option);
																		});
																	}

																	referenceKeynameDropdown.addEventListener("change", function (e) {
																		let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																		// console.log('Selected value:', selectedOptionFromDropdown.value);
																		// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																		// get the reference ID of the table row from main obj to update
																		let refTableId;
																		let tableName = table.split("_")[1];
																		if (obj[tableName] !== null) {
																			refTableId = obj[tableName];
																		}

																		selectedOptions.push({
																			table,
																			column,
																			refTableId,
																			value: selectedOptionFromDropdown.value,
																			"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																		});
																	});

																	modalContent.appendChild(referenceKeynameDropdown);
																}
															}
														}

														console.log("+bu, bubt+", bu, bubt);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";
														buttonContainer.style.marginTop = "5%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-submit-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
														submitButton.innerHTML = "Submit";

														// Apply styles to the button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", function (e) {
															e.preventDefault(); // Prevent default form action
															// Handle form submission here
															console.log("Form submitted!", obj);

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Initialize an empty FormData object to get the form's input fields values
															let formData = new FormData(modalContent);
															let formValues = {};
															for (const [key, value] of formData.entries()) {
																formValues[key] = value;
															}

															// Create the to_insert object with the keynames from updatedProps.EditableFields
															// and their corresponding values from formValues
															let to_insert = {};
															Object.keys(updatedProps.EditableFields).forEach((key) => {
																if (formValues[key] !== undefined && !key.includes("-")) {
																	// Check if the form has the field
																	to_insert[key] = formValues[key];
																}

																// re-attach the reference id to PUT call else it will set the references to null
																if (formValues[key] !== undefined && key.includes("-")) {
																	let x = key.split("-");
																	let table = x[0].split("_")[1];
																	if (obj[table] !== null) {
																		to_insert[table] = obj[table];
																	}
																}
															});

															console.log("+to_insert+", to_insert);
															console.log("+selectedOptions+", selectedOptions);

															// logic to update all table cells the edit button is editing without refreshing
															for (const key in to_insert) {
																let columns = [];
																if (updatedProps[`HeaderField_${i}`]) {
																	Object.keys(updatedProps).map((hF_i) => {
																		if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																} else {
																	Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																		if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																}

																// console.log('+columns+', columns);

																columns.forEach(idx => {
																	let td = tr.getElementsByTagName('td')[idx];

																	let valueToUpdateCellWith = to_insert[key];
																	// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																	// Find the first text node
																	const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																	if (textNode) {
																		textNode.nodeValue = valueToUpdateCellWith;
																	} else {
																		// If no text node exists, create one
																		td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																	}
																});
															}

															// Fetch call (to non-reference table) can be made here using the input field values
															// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
															fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "PUT",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
																body: JSON.stringify({
																	to_insert: to_insert,
																}),
															})
																.then((response) => response.json())
																.then((data) => console.log(data));

															// Fetch call (for reference table)
															if (selectedOptions.length > 0) {
																selectedOptions.forEach((data) => {
																	// make api calls per object
																	let requestBody = {};
																	requestBody[data.column] = data.value; // Assign dynamic key-value pair
																	fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																		method: "PUT",
																		headers: {
																			"Content-Type": "application/json",
																			Authorization: `Bearer ${values.BearerToken}`,
																		},
																		body: JSON.stringify({
																			to_insert: requestBody,
																		}),
																	})
																		.then((response) => response.json())
																		.then((data) => console.log(data));
																});
															}

															// Close the modal when the form is submitted
															editModal.close();

															// clear the selectedOptions for next turn
															selectedOptions = [];

															// // giving a delay in reload so that it doesn't change the url in the broswer tab
															// setTimeout(function () {
															// 	window.top.location.reload();
															// }, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-close-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
														closeButton.innerHTML = "Close";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (event) {
															event.preventDefault(); // Prevent default behavior of the click event
															editModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														editModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(editModal);

														// Show the modal
														editModal.showModal();
													}
													// Delete modal
													else if (clonedButton.getAttribute("data-table-action") === "delete") {
														// Create the dialog element
														let deleteModal = document.createElement("dialog");
														deleteModal.setAttribute("id", "delete-modal");

														// Apply styles to the modal
														Object.assign(deleteModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Delete`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// // Create an input field for data model with a default value
														// let dataModelInputField = document.createElement("input");
														// dataModelInputField.setAttribute('type', 'text');
														// dataModelInputField.setAttribute('name', 'DataModel');
														// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
														// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

														// // Apply styles to the input fields
														// Object.assign(dataModelInputField.style, inputFieldStyle);

														// modalContent.appendChild(dataModelInputField);

														// // Create an input field for bearer token with a default value
														// let bearerTokenInputField = document.createElement("input");
														// bearerTokenInputField.setAttribute('type', 'text');
														// bearerTokenInputField.setAttribute('name', 'BearerToken');
														// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
														// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

														// // Apply styles to the input fields
														// Object.assign(bearerTokenInputField.style, inputFieldStyle);

														// modalContent.appendChild(bearerTokenInputField);

														// Create a paragraph tag for confirmation message
														let confirmationMessage = document.createElement("p");
														confirmationMessage.style.marginBottom = "5%";
														confirmationMessage.style.fontSize = "1vw";
														confirmationMessage.innerHTML = "Are you sure?";

														// Append confirmation message to the modal content
														modalContent.appendChild(confirmationMessage);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-delete-yes-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
														submitButton.innerHTML = "Yes";

														// Apply styles to the "Yes" button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", async function (e) {
															e.preventDefault();
															// Handle form submission here
															console.log("Form submitted!");

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Use the values in the fetch call or other logic
															console.log("Input field values:", values);

															// Make the fetch call to delete the item
															let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "DELETE",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
															});
															// .then(response => {
															//     if (response.ok) {
															//         console.log('Item deleted successfully');
															//     } else {
															//         console.error('Failed to delete item');
															//     }
															// });

															let responseData = response.json();
															if (responseData.ok) {
																console.log("Item deleted successfully");
															} else {
																console.error("Failed to delete item");
															}

															// Close the modal when the form is submitted
															deleteModal.close();

															// giving a delay in reload so that it doesn't change the url in the broswer tab
															setTimeout(function () {
																window.top.location.reload();
															}, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-delete-no-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
														closeButton.innerHTML = "No";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (e) {
															e.preventDefault();
															deleteModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														deleteModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(deleteModal);

														// Show the modal
														deleteModal.showModal();
													}
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										console.log("headerActionButtons:", headerActionButtons);
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_file buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerInputFileButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerInputFileButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_file_container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerInputFileButtons) {
											if (headerInputFileButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											// for (let j = 0; j < headerInputFileButtons.length; j++) {
											// 	// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
											// 	let clonedInputFileButton = headerInputFileButtons[j].cloneNode(true);

											// 	// Copy the styles from the original button to the cloned button and its inner elements
											// 	copyStyles(headerInputFileButtons[j], clonedInputFileButton);

											// 	// changing the clone's IDs so that they are present and available individually in LB and in download
											// 	clonedInputFileButton.setAttribute('id', `input_file_container_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].setAttribute('id', `input_file_label_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].getElementsByTagName('p')[0].setAttribute('id', `input_file_label_p_table${id}_row${idx}_column${i}_${j}`);

											// 	td.appendChild(clonedInputFileButton);

											// 	let headerInputFileClickHandler = function(event) {
											// 		console.log('input_file_label clicked', 'saving data in session storage');
											// 		// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

											// 		// this registers all stellar button clicks on LS
											// 		// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
											// 		// this registers only by the individuals in the column header
											// 		localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, JSON.stringify({
											// 			'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
											// 			tableId: `${id}`,
											// 			rowId: `custom_table_tbody_row${idx}_table${id}`,
											// 			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
											// 			buttonId: `${clonedInputFileButton.getAttribute('id')}`,
											// 		}));

											// 		console.log('clonedInputFileButton++', clonedInputFileButton);

											// 		let inputFile = document.createElement('input');
											//         inputFile.type = 'file';
											//         inputFile.style.display = 'none';

											//         inputFile.addEventListener('change', async function (event) {
											//               let file = inputFile.files[0];
											//               console.log('this++', this);
											//               console.log('file is:', file);

											//               let formData = new FormData();
											//               formData.append('file', file);

											//               try {
											//                   const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
											//                       method: 'POST',
											//                       headers: {
											//                           // 'Content-Type': 'multipart/form-data',
											//                       },
											//                       body: formData,
											//                   });

											//                   if (response.ok) {
											//                       const data = await response.json();
											//                       console.log('File uploaded:', data);
											//                       clonedInputFileButton.setAttribute('data_file_url', data["fileLocation"]);

											//                     // Retrieve the object from local storage
											// 			        let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j}`)) || {};

											// 			        // Add the data_file_url and its value to the object
											// 			        clickedFileData.data_file_url = data["fileLocation"];

											// 			        // Convert the modified object back to a JSON string
											// 			        let updatedClickedFileData = JSON.stringify(clickedFileData);

											// 			        // Update the local storage with the modified JSON string
											// 			        localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, updatedClickedFileData);
											//                   } else {
											//                       console.error('Error uploading file:', response.statusText);
											//                   }

											//                   clonedInputFileButton.onclick = null;
											//               } catch (error) {
											//                   console.error('Error uploading file:', error);
											//               } finally {
											//               	console.log('after 1')
											//                   // Remove the inputFile element after API call completes
											//                   if (inputFile && inputFile.parentNode) {
											//                   	console.log('after 2')
											//                       inputFile.parentNode.removeChild(inputFile);
											//                   }
											//               }
											//         });

											//         clonedInputFileButton.appendChild(inputFile);
											//         inputFile.click();
											//         console.log('clicked', clonedInputFileButton);
											// 	};

											// 	// adding click event on stellar-button from the table components
											// 	// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
											// 	clonedInputFileButton.onclick = headerInputFileClickHandler;

											// 	// to hide the header buttons after they have been cloned
											// 	headerInputFileButtons[j].style.display = "none";
											// }

											Array.from(headerInputFileButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
												let clonedInputFileButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedInputFileButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedInputFileButton.setAttribute("id", `input_file_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].setAttribute("id", `input_file_label_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0]) {
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0].setAttribute("id", `input_file_label_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedInputFileButton);

												let headerInputFileClickHandler = function (event) {
													console.log("input_file_label clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_input_file_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedInputFileButton.getAttribute("id")}`,
														}),
													);

													// console.log('clonedInputFileButton++', clonedInputFileButton);

													let inputFile = document.createElement("input");
													inputFile.type = "file";
													inputFile.style.display = "none";

													inputFile.addEventListener("change", async function (event) {
														let file = inputFile.files[0];
														console.log("this++", this);
														console.log("file is:", file);

														let formData = new FormData();
														formData.append("file", file);

														try {
															const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
																method: "POST",
																headers: {
																	// 'Content-Type': 'multipart/form-data',
																},
																body: formData,
															});

															if (response.ok) {
																const data = await response.json();
																console.log("File uploaded:", data);
																td.setAttribute("data_file_url", data["fileLocation"]);

																// Retrieve the object from local storage
																let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`)) || {};

																// Add the data_file_url and its value to the object
																clickedFileData.data_file_url = data["fileLocation"];

																// Convert the modified object back to a JSON string
																let updatedClickedFileData = JSON.stringify(clickedFileData);

																// Update the local storage with the modified JSON string
																localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`, updatedClickedFileData);
															} else {
																console.error("Error uploading file:", response.statusText);
															}
														} catch (error) {
															console.error("Error uploading file:", error);
														} finally {
															// Remove the inputFile element after API call completes
															if (inputFile && inputFile.parentNode) {
																inputFile.parentNode.removeChild(inputFile);
															}
														}
													});

													clonedInputFileButton.appendChild(inputFile);
													inputFile.click();

													if (inputFile && inputFile.parentNode) {
														inputFile.parentNode.removeChild(inputFile);
													}
												};

												// adding click event on stellar-button from the table components
												// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].onclick = headerInputFileClickHandler;

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										console.log("headerInputFileButtons:", headerInputFileButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating radio_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let radioButtonGroups;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										radioButtonGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("radio_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (radioButtonGroups) {
											if (radioButtonGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(radioButtonGroups).forEach((radioButtonGroup, j) => {
												let clonedRadioButtonGroup = radioButtonGroup.cloneNode(true);
												// Copy styles from the original to the cloned button
												copyStyles2(radioButtonGroup, clonedRadioButtonGroup);

												// Change IDs and append cloned radio button groups to the container
												clonedRadioButtonGroup.setAttribute("id", `radio_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each radio button in the group
												clonedRadioButtonGroup.querySelectorAll('input[type="radio"]').forEach((radioButton) => {
													radioButton.addEventListener("click", (event) => {
														// Get the selected radio button's value and id
														const selectedValue = event.target.value;
														const selectedId = event.target.id;
														const selectedDataDbId = event.target.getAttribute("data_db_id") ? event.target.getAttribute("data_db_id") : "";

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_radio_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																radioButtonId: `${selectedId}`,
																selectedValue: `${selectedValue}`,
																selectedDataDbId: `${selectedDataDbId}`,
															}),
														);

														// You can also perform other actions based on the selected radio button
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedRadioButtonGroup);
											});
										}

										// Hide the original radio button groups in the header column
										Array.from(radioButtonGroups).forEach((radioButtonGroup) => {
											radioButtonGroup.style.display = "none";
										});
										// }
										console.log("radioButtonGroups:", radioButtonGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroups;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroups) {
											if (checkboxGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroups).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroups).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										console.log("checkboxGroups:", checkboxGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form_new
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroupsV2;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroupsV2 = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form_new");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroupsV2) {
											if (checkboxGroupsV2.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroupsV2).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_v2_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_v2_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroupsV2).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										console.log("checkboxGroupsV2:", checkboxGroupsV2);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_select
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dropdownContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dropdownContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_select_container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dropdownContainers) {
											if (dropdownContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(dropdownContainers).forEach((dropdownContainer, j) => {
												let dropdown = dropdownContainer.getElementsByTagName("select")[0];
												let dropdownClone = dropdown.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dropdown, dropdownClone);

												// Change IDs and append cloned dropdowns to the container
												dropdownClone.setAttribute("id", `input_select_table${id}_row${idx}_column${i}_${j}`);
												dropdownClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const selectedValue = event.target.value;
													const selectedOption = event.target.options[event.target.selectedIndex];
													const selectedId = selectedOption.id;

													// Get the data-db-id attribute value of the selected option
													const selectedDataDbId = selectedOption.getAttribute("data_db_id") ? selectedOption.getAttribute("data_db_id") : "";

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_input_select_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															selectedValue: `${selectedValue}`,
															selectedId: `${selectedId}`,
															selectedDataDbId: `${selectedDataDbId}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dropdownClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dropdownContainers).forEach((dropdownContainer) => {
												dropdownContainer.style.display = "none";
											});
										}
										// }
										console.log("dropdownContainers:", dropdownContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_text_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputTextContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputTextContainers) {
											if (inputTextContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
												let inputText = inputTextContainer.getElementsByTagName("input")[0];
												let inputTextClone = inputText.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputText, inputTextClone);

												// Change IDs and append cloned dropdowns to the container
												inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
												inputTextClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_text_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputTextClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputTextContainers).forEach((inputTextContainer) => {
												inputTextContainer.style.display = "none";
											});
										}
										// }
										console.log("inputTextContainers:", inputTextContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_number_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputNumberContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputNumberContainers) {
											if (inputNumberContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
												let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
												let inputNumberClone = inputNumber.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputNumber, inputNumberClone);

												// Change IDs and append cloned dropdowns to the container
												inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
												inputNumberClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_number_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputNumberClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
												inputNumberContainer.style.display = "none";
											});
										}
										// }
										console.log("inputNumberContainers:", inputNumberContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating send email buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let sendEmailButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										sendEmailButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("send-email-button-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (sendEmailButtons) {
											if (sendEmailButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(sendEmailButtons).forEach((j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedEmailButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedEmailButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedEmailButton.setAttribute("id", `send_email_button_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedEmailButton.getElementsByClassName("send-email-button")[0].setAttribute("id", `send_email_btn_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0]) {
													clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0].setAttribute("id", `send_email_btn_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedEmailButton);

												const sendEmailHandler = async function (sendEmailButtonContainer) {
													let selectedFile;
													// reason - because this script was getting called multiple times, the dialog element was getting added multiple times so this code will be removing all the previously appended ones and then create a new one on-the-go
													if (sendEmailButtonContainer) {
														let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
														modalAlreadyExists.forEach((modal) => {
															console.log("removing modal 2", modal);
															modal.remove();
														});

														// // fetch filter call for content
														// let filteredResponse = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${extractTableName(updatedProps.customUrlValue)}`, {
														// 	method: "POST",
														// 	headers: {
														// 		'Content-Type': "application/json",
														// 		Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
														// 	},
														// 	body: JSON.stringify({
														// 	    filterData: {
														// 	        _id: `${obj[updatedProps.DataModelObjIdKeyname]}`,
														// 	    }
														// 	}),
														// });

														// let filteredResponseData = await filteredResponse.json();

														// Get the keys from updatedProps.HeaderFields object and from HTML attributes
														const headerFieldKeys = Object.keys(updatedProps.HeaderFields);
														const htmlAttributeKeys = Array.from(customTableContainer.attributes)
															.filter((attr) => attr.name.includes("headerfield_"))
															.map((attr) => attr.value);

														console.log("htmlAttributeKeys", htmlAttributeKeys);

														// Combine the keys from both sources
														const allKeys = [...headerFieldKeys, ...htmlAttributeKeys];

														// Accumulate all key-value pairs
														const keyValuePairs = [];

														// Get the values corresponding to the keys from the obj object
														htmlAttributeKeys.forEach((key) => {
															let value;
															if (/:/.test(key)) {
																// If the key contains a colon (indicating nested properties), split it
																const [mainKey, subKey] = key.split(/\s*:\s*/);
																// Check if the main key exists in obj
																if (obj[`${mainKey}_data`]) {
																	// Check if the main key points to an object
																	if (typeof obj[`${mainKey}_data`] === "object" && obj[`${mainKey}_data`][subKey]) {
																		value = obj[`${mainKey}_data`][subKey];
																		keyValuePairs.push(`${subKey}: ${value}`);
																	}
																}
															} else {
																value = obj[key];
																keyValuePairs.push(`${key}: ${value}`);
															}
															// if (value) {
															//     keyValuePairs.push(`${key}: ${value}`);
															// }
														});

														console.log("allKeys, keyValuePairs", allKeys, keyValuePairs);

														// Join all key-value pairs into a single string
														const filteredKeyValuePairs = keyValuePairs.join("\n");

														console.log("allKeys, filteredKeyValuePairs", allKeys, filteredKeyValuePairs);

														// Create a dialog modal with styling
														const dynamicEmailSendingModal = document.createElement("dialog");
														dynamicEmailSendingModal.setAttribute("class", `custom-email-modal`);
														dynamicEmailSendingModal.setAttribute("id", `custom-email-modal-${sendEmailButtonContainer.id}`);
														Object.assign(dynamicEmailSendingModal.style, {
															margin: "auto",
															width: "35vw",
															overflow: "auto",
															backgroundColor: "white",
															boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
															border: "1px solid #ccc",
															borderRadius: "20px",
															zIndex: "1000",
															padding: "0 0 1% 0",
														});

														// modal UI header & footer
														let modalHeader = document.createElement("div");
														Object.assign(modalHeader.style, {
															// backgroundColor: "#1991E7",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															color: "white",
															fontSize: "1vw",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});
														modalHeader.innerHTML = "Compose Message";

														const modalFooter = document.createElement("div");
														Object.assign(modalFooter.style, {
															backgroundColor: "transparent",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});

														// Create a form element
														const emailForm = document.createElement("form");
														Object.assign(emailForm.style, {
															padding: "3% 6%",
															display: "flex",
															flexDirection: "column",
														});

														// Check if EmailFromDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailFromDynamicCheckbox) {
														const emailFromDiv = document.createElement("div");
														Object.assign(emailFromDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailFromLabel = document.createElement("label");
														emailFromLabel.innerHTML = "From";
														// Create an input email field for the sender's email address with styling
														const emailFromField = document.createElement("input");
														Object.assign(emailFromField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailFromField, {
															type: "email",
															id: `emailFrom-${sendEmailButtonContainer.id}`,
															name: "emailFrom",
															placeholder: "Sender's Email Address",
														});
														// visiblity will be hidden because this field is not actually required and it needs to occupy same space for UI alignment purposes
														const emailFromRequiredFieldLabel = document.createElement("span");
														emailFromRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailFromRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
															visibility: "hidden",
														});

														// Append the email input field to the form
														emailFromDiv.appendChild(emailFromLabel);
														emailFromDiv.appendChild(emailFromField);
														emailFromDiv.appendChild(emailFromRequiredFieldLabel);
														emailForm.appendChild(emailFromDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailToDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailToDynamicCheckbox) {
														const emailToDiv = document.createElement("div");
														Object.assign(emailToDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailToLabel = document.createElement("label");
														emailToLabel.innerHTML = "To";
														// Create an input email field for the receiver's email address with styling
														const emailToField = document.createElement("input");
														Object.assign(emailToField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailToField, {
															type: "text",
															id: `emailTo-${sendEmailButtonContainer.id}`,
															name: "emailTo",
															placeholder: "Receiver's Email Addresses (comma separated)",
														});
														const emailToRequiredFieldLabel = document.createElement("span");
														emailToRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailToRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailToDiv.appendChild(emailToLabel);
														emailToDiv.appendChild(emailToField);
														emailToDiv.appendChild(emailToRequiredFieldLabel);
														emailForm.appendChild(emailToDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailSubjectDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailSubjectDynamicCheckbox) {
														const emailSubjectDiv = document.createElement("div");
														Object.assign(emailSubjectDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailSubjectLabel = document.createElement("label");
														emailSubjectLabel.innerHTML = "Subject";
														// Create an input email field for the receiver's email address with styling
														const emailSubjectField = document.createElement("input");
														Object.assign(emailSubjectField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailSubjectField, {
															type: "text",
															id: `emailSubject-${sendEmailButtonContainer.id}`,
															name: "emailSubject",
															placeholder: "Email's subject",
														});
														const emailSubjectRequiredFieldLabel = document.createElement("span");
														emailSubjectRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailSubjectRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailSubjectDiv.appendChild(emailSubjectLabel);
														emailSubjectDiv.appendChild(emailSubjectField);
														emailSubjectDiv.appendChild(emailSubjectRequiredFieldLabel);
														emailForm.appendChild(emailSubjectDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailBodyDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailBodyDynamicCheckbox) {
														const emailBodyDiv = document.createElement("div");
														Object.assign(emailBodyDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailBodyLabel = document.createElement("label");
														emailBodyLabel.innerHTML = "Body";
														// Create an input email field for the receiver's email address with styling
														const emailBodyField = document.createElement("textarea");
														Object.assign(emailBodyField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailBodyField, {
															rows: "5",
															cols: "10",
															id: `emailBody-${sendEmailButtonContainer.id}`,
															name: "emailBody",
															placeholder: "Email's body",
														});
														const emailBodyRequiredFieldLabel = document.createElement("span");
														emailBodyRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailBodyRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														emailBodyField.value = filteredKeyValuePairs;

														// Append the email input field to the form
														emailBodyDiv.appendChild(emailBodyLabel);
														emailBodyDiv.appendChild(emailBodyField);
														emailBodyDiv.appendChild(emailBodyRequiredFieldLabel);
														emailForm.appendChild(emailBodyDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if FileAttachmentDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.FileAttachmentDynamicCheckbox) {
														// Create an input email field for the receiver's email address with styling
														const fileAttachmentField = document.createElement("input");
														Object.assign(fileAttachmentField.style, {
															// marginBottom: "10px",
															// padding: "5px",
															// width: "80%",
															// borderRadius: "5px",
															// backgroundColor: "transparent",
															// boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
															display: "none",
														});
														Object.assign(fileAttachmentField, {
															type: "file",
															id: `fileAttachment-${sendEmailButtonContainer.id}`,
															name: "fileAttachment",
														});

														// Add event listener for the file input field change event
														fileAttachmentField.addEventListener("change", (event) => {
															selectedFile = event.target.files[0];
															console.log("Selected file:", selectedFile);
															// You can do further processing with the selected file here
														});

														// Create a label for the file input button
														const fileAttachmentLabel = document.createElement("label");
														fileAttachmentLabel.setAttribute("for", "fileAttachmentInput"); // Set the 'for' attribute to match the input field's id
														fileAttachmentLabel.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16"><path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/></svg>';
														Object.assign(fileAttachmentLabel.style, {
															cursor: "pointer",
														});

														// Attach click event to the label
														fileAttachmentLabel.addEventListener("click", () => {
															fileAttachmentField.click();
														});

														// Append the email input field to the form
														modalFooter.appendChild(fileAttachmentLabel);
														modalFooter.appendChild(fileAttachmentField);
														// }
														// // else take directly from props
														// else {}

														// Create a submit button
														const submitButton = document.createElement("button");
														Object.assign(submitButton.style, {
															// marginBottom: "3%",
															// marginRight: "4%",
															alignSelf: "flex-end",
															padding: "1.5% 3%",
															border: "none",
															borderRadius: "25px",
															// backgroundColor: "#007bff",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															color: "white",
															cursor: "pointer",
														});
														submitButton.textContent = "Send";
														submitButton.type = "submit";

														// Add event listener for form submission
														submitButton.addEventListener("click", async (event) => {
															event.preventDefault(); // Prevent form submission

															// Get the entered email values
															const emailFromValue = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`).value : "";
															let emailToValue = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`).value : "";
															emailToValue = emailToValue.includes(",") ? emailToValue.split(/\s*,\s*/) : emailToValue;
															const emailSubjectValue = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`).value : "";
															const emailBodyValue = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`).value : "";
															const fileAttachmentValue = emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`).value : "";

															// to preserve line breaks in email body
															const formattedEmailBodyValue = emailBodyValue.replace(/\n/g, "<br>");

															console.log("form values", emailFromValue, emailToValue, emailSubjectValue, emailBodyValue, formattedEmailBodyValue, fileAttachmentValue);
															console.log("selectedFile", selectedFile);

															// Create a FormData object
															const formData = new FormData();

															// Append form data to FormData object
															formData.append("emailFrom", emailFromValue);
															formData.append("emailTo", emailToValue);
															formData.append("emailSubject", emailSubjectValue);
															formData.append("emailBody", formattedEmailBodyValue);
															formData.append("fileAttachment", selectedFile);

															// Make a fetch call to the API with the entered email address
															try {
																// send email if email - to, subject, body are provided
																if (emailToValue !== "" && emailSubjectValue !== "" && emailBodyValue !== "") {
																	const response = await fetch(`${updatedProps.API_HOST}user/custom_email`, {
																		method: "POST",
																		body: formData,
																	});

																	if (response.ok) {
																		console.log("Email sent successfully!");
																		showNotification(true, "Email sent successfully!");
																	} else {
																		console.error("Failed to send email.");
																		showNotification(false, "Failed to send email. Please check the provided values.");
																	}
																}
															} catch (error) {
																console.error("Error sending email:", error);
																showNotification(false, "Error sending email. Please check the provided values.");
															}

															// Close the modal after form submission
															dynamicEmailSendingModal.close();
														});

														// Create a close button
														const closeButton = document.createElement("button");
														closeButton.setAttribute("class", "custom-email-modal-close");
														closeButton.setAttribute("id", `custom-email-modal-close-${sendEmailButtonContainer.id}`);
														Object.assign(closeButton.style, {
															border: "none",
															borderRadius: "3px",
															backgroundColor: "transparent",
															color: "white",
															cursor: "pointer",
														});
														closeButton.textContent = "X";
														closeButton.type = "button";

														// Add event listener for closing the modal
														closeButton.addEventListener("click", () => {
															dynamicEmailSendingModal.close();
														});

														// Append the form and close button to the modal
														dynamicEmailSendingModal.appendChild(modalHeader);
														dynamicEmailSendingModal.appendChild(emailForm);
														modalHeader.appendChild(closeButton);
														modalFooter.appendChild(submitButton);
														dynamicEmailSendingModal.appendChild(modalFooter);

														// Append the modal to the document body
														document.body.appendChild(dynamicEmailSendingModal);

														// Show the modal
														dynamicEmailSendingModal.showModal();
													}
												};

												// adding click event on stellar-button from the table components
												clonedEmailButton.getElementsByClassName("send-email-button")[0].addEventListener("click", function (event) {
													console.log("send-email-button clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_send_email_button_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedEmailButton.getAttribute("id")}`,
														}),
													);

													sendEmailHandler(clonedEmailButton);
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										console.log("sendEmailButtons:", sendEmailButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form text_area
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let normalTextAreaContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										normalTextAreaContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("normal_text_area_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (normalTextAreaContainers) {
											if (normalTextAreaContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer, j) => {
												// let inputText = inputTextContainer.getElementsByTagName('input')[0];
												let normalTextAreaContainerClone = normalTextAreaContainer.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(normalTextAreaContainer, normalTextAreaContainerClone);

												// Change IDs and append cloned dropdowns to the container
												normalTextAreaContainerClone.setAttribute("id", `normal_text_area_field_table${id}_row${idx}_column${i}_${j}`);

												// Event listener for input event
												normalTextAreaContainerClone.addEventListener("input", (event) => {
													const enteredValue = event.target.value;
													localStorage.setItem(
														`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);
												});

												// Event listener for keypress event with Enter key
												normalTextAreaContainerClone.addEventListener("keypress", (event) => {
													if (event.key === "Enter") {
														const enteredValue = event.target.value;
														localStorage.setItem(
															`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
													}
												});

												// Append the dropdown to the table cell
												td.appendChild(normalTextAreaContainerClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer) => {
												normalTextAreaContainer.style.display = "none";
											});
										}
										// }
										console.log("normalTextAreaContainers:", normalTextAreaContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating custom-table-row-selection-checkbox-container
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let tableRowSelectionCheckboxContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (tableRowSelectionCheckboxContainers) {
											// if (tableRowSelectionCheckboxContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// }

											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
												let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
												let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

												// to store row info accross page change
												const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

												// Change IDs and append cloned dropdowns to the container
												tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
												
												// Retrieve stored data from session storage
									            let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

									            // Check if this checkbox should be checked
									            const existingData = storedData.find(
									                (item) =>
									                    item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
									                    item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
									                    item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
									                    item.paginationIndex === currentPageIndex
									            );
									            if (existingData) {
									                tableRowSelectionCheckboxClone.checked = true;
									            }
												
												tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
													const enteredValue = event.target.value;
													const isChecked = event.target.checked; // Check if the checkbox is checked

													// to store row info accross page change
													const checkboxData = {
												        "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												        tableId: `${id}`,
												        rowId: `custom_table_tbody_row${idx}_table${id}`,
												        cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												        inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
												        enteredValue: `${enteredValue}`,
												        paginationIndex: currentPageIndex, // Include the current page index
												    };

												    // Retrieve existing data
												    let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

													if (isChecked) {
														// Checkbox is checked, add object to local storage
														localStorage.setItem(
															`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
														// console.log("currentPageIndex", currentPageIndex);

														// If checked, check if the entry already exists
									                    const existingIndex = storedData.findIndex(
									                        (item) =>
									                            item.rowId === checkboxData.rowId &&
									                            item.cellId === checkboxData.cellId &&
									                            item.paginationIndex === checkboxData.paginationIndex &&
									                            item.inputId === checkboxData.inputId
									                    );

									                    if (existingIndex !== -1) {
									                        // Update existing entry
									                        storedData[existingIndex] = checkboxData;
									                    } else {
									                        // Add new entry
									                        storedData.push(checkboxData);
									                    }
													} else {
														// Checkbox is unchecked, remove object from local storage
														localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
														
														// If unchecked, remove the entry
									                    storedData = storedData.filter(
									                        (item) =>
									                            !(item.rowId === checkboxData.rowId &&
									                              item.cellId === checkboxData.cellId &&
									                              item.paginationIndex === checkboxData.paginationIndex &&
									                              item.inputId === checkboxData.inputId)
									                    );
													}

													// You can also perform other actions based on the checkbox state
													// For example, update UI, make API calls, etc.
													
													// Update session storage with the modified array
													sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
												});

												// Append the dropdown to the table cell
												td.appendChild(tableRowSelectionCheckboxClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
												tableRowSelectionCheckboxContainer.style.display = "none";
											});
										}
										// }
										console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_date_time field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dateTimeInputContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dateTimeInputContainers) {
											// if (dateTimeInputContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// 	td.style.scrollbarColor = `transparent transparent`;
											// }

											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
												let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
												let dateTimeInputClone = dateTimeInput.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dateTimeInput, dateTimeInputClone);

												// Change IDs and append cloned dropdowns to the container
												dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
												dateTimeInputClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_date_time_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dateTimeInputClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
												dateTimeInputContainer.style.display = "none";
											});
										}
										// }
										console.log("dateTimeInputContainers:", dateTimeInputContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating toggle
									try {
										// plot only if the field_type of header attribute is boolean
										if (fieldType === 'Boolean') {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let toggleContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											toggleContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('.custom-table-toggle-container');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (toggleContainers) {
												// if there are toggle elements being replicated then don't show the data model content in their table cells along with the toggle replicated components
												td.textContent = "";

												// if (toggleContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(toggleContainers).forEach((toggleContainer, j) => {
												    let toggleInnerContainer = toggleContainer.getElementsByClassName("custom-table-inner-toggle-container")[0];
												    let toggleClone = toggleInnerContainer.cloneNode(true);
												    // Copy styles from the original to the cloned dropdown
												    copyStyles(toggleInnerContainer, toggleClone);

												    // Initial state of the toggle
												    let isOn = false;

												    // Change IDs and append cloned dropdowns to the container
												    toggleClone.setAttribute("id", `custom_table_inner_toggle_container_table${id}_row${idx}_column${i}_${j}`);
												    if (toggleClone.getElementsByClassName("custom-table-toggle")[0]) {
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].setAttribute("id", `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`);

												        // update toggle once before/outside click event also so that it initializes according to the value coming from backend. If `content` value is true, toggle should be on, else off.
												        if (content) {
												        	isOn = true;
												        }
												        updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));

												        // now attach event to make the toggle function changing color on on/off actions and sending PUT api calls
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].addEventListener("click", (event) => {
												            isOn = !isOn;

												            // if the cell value is false, change it to true before sending it in PUT call payload, else send it as it it. When toggle is on, 'true' value should be send in the backend.
												            let cellValue = content ? false : true;

												            // a PUT api call will be made to update that particular record of the given table
												            updateTable(cellValue, `${obj[updatedProps.DataModelObjIdKeyname]}`, updatedProps.updatingCustomUrlValue, updatedProps.updatingBaseUrlValue, updatedProps.updatingApiPathValue, updatedProps.updatingApiQueryParamValue, updatedProps.updatingApiTableAttributeValue, updatedProps.updatingApiMethodValue, updatedProps.updatingApiUsernameValue, updatedProps.updatingApiPasswordValue, updatedProps.updatingApiBearerTokenValue, updatedProps.updatingApiMoreHeadersValue);

												            updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));
												            console.log(`Toggle is now: ${isOn ? 'ON' : 'OFF'}`, content);

												            // Construct the localStorage key
												            let toggleLocalStorageKey = `clicked_custom_table_toggle_table${id}_column${i}`;

												            // Retrieve existing data from local storage
												            let togglesData = JSON.parse(localStorage.getItem(toggleLocalStorageKey)) || [];

												            // Create the new toggle data object
												            let newToggleData = {
												                "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												                tableId: `${id}`,
												                rowId: `custom_table_tbody_row${idx}_table${id}`,
												                cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												                toggleEleId: `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`,
												                toggleStatus: isOn,
												            };

												            // Check if the toggle already exists
												            let existingToggleIndex = togglesData.findIndex(toggle => toggle.toggleEleId === newToggleData.toggleEleId);

												            if (existingToggleIndex !== -1) {
												                // Update the existing toggle data
												                togglesData[existingToggleIndex] = newToggleData;
												            } else {
												                // Add the new toggle data
												                togglesData.push(newToggleData);
												            }

												            // Update the local storage with the new/updated toggle data, column-wise
												            localStorage.setItem(toggleLocalStorageKey, JSON.stringify(togglesData));
												        });
												    }

												    // Append the dropdown to the table cell
												    td.appendChild(toggleClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(toggleContainers).forEach((toggleContainer) => {
													toggleContainer.style.display = "none";
												});
											}
											// }
											console.log("toggleContainers:", toggleContainers);
											// }
										}										
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating rich text input
                                    try {
                                        console.log("i", i);

                                        let inputTextContainers;

                                        console.log("customTableThead", customTableThead);

                                        console.log(
                                            "thead th i",
                                            customTableThead
                                            .getElementsByTagName("tr")[0]
                                            .querySelectorAll("th")[i]
                                        );

                                        let richTextIndexesData = updatedProps.richTextIndexes;
                                        console.log(
                                            "richTextIndexesData::::::::::",
                                            richTextIndexesData
                                        );

                                        if (richTextIndexesData.length > 0) {
                                            richTextIndexesData.forEach((x) => {
                                                console.log("richTextIndexesData x::::::::::", x);
                                                console.log("richTextIndexesData::::::", i);
                                                console.log("richTextIndexesData::::::", i);
                                                if (x === i) {
                                                    td.innerHTML = "";
                                                    let wrapperDiv = document.createElement("div");
                                                    wrapperDiv.setAttribute(
                                                        "id",
                                                        `rich_text_container_${idx}_${id}`
                                                    );
                                                    wrapperDiv.setAttribute(
                                                        "class",
                                                        "rich_text_table_container"
                                                    );

                                                    wrapperDiv.style.backgroundColor = "white";

                                                    td.appendChild(wrapperDiv);

                                                    let quill = new Quill(wrapperDiv, {
                                                        modules: {
                                                            toolbar: updatedProps.richTextOptions.toolbar ? [
                                                                [{
                                                                    header: [1, 2, 3, 4, 5, 6, false]
                                                                }],
                                                                ["bold", "italic", "underline", "strike"],
                                                                [{
                                                                    list: "bullet"
                                                                }, {
                                                                    list: "ordered"
                                                                }],
                                                                ["link"],
                                                                [{
                                                                    align: []
                                                                }],
                                                                [{
                                                                    color: []
                                                                }, {
                                                                    background: []
                                                                }],
                                                            ] : false,
                                                        },
                                                        placeholder: "",
                                                        theme: "snow",
                                                    });

                                                    updatedProps.richTextOptions.readOnly ? quill.enable(false) : quill.enable(true);

                                                    if (content) {
                                                        console.log('contentRich:::::::',content)
                                                        let jsonContent = JSON.parse(content);
                                                        console.log('jsonContent:::::::::',jsonContent)
                                                        console.log('type jsonContent:::::::::',typeof jsonContent)
                                                        if (jsonContent ) {
                                                            console.log('inside jsonContent')
                                                            quill.setContents(jsonContent);
                                                        }
                                                    } else {
                                                        let richTextContent;
                                                        richTextContent = sessionStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );
                                                        if (!richTextContent) richTextContent = localStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );
                                                        
                                                        let parsedData = JSON.parse(richTextContent);
                                                        if (parsedData && parsedData.delta) {
                                                            quill.setContents(parsedData.delta);
                                                        }                                                           
                                                   }

                                                    quill.on("text-change", () => {
                                                        const delta = quill.getContents();

                                                        let deltaRef = {
                                                            "data-db-id": `${
                                                                obj[updatedProps.DataModelObjIdKeyname]
                                                                }`,
                                                            delta:delta
                                                        }
                                                        
                                                        localStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );
                                                        
                                                        sessionStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );
                                                    });                                                   
                                                }
                                            });
                                        }

                                        // }
                                        console.log("inputTextContainers:", inputTextContainers);
                                        // }
                                    } catch (e) {
										// statements
										console.log(e);
									}

										tr.append(td);
									}

									if (customTableTbody) {
										customTableTbody.append(tr);
									}									
									console.log("+idx+", idx);
								}
							});
						}
					}

					// if the response has a nested array-of-objects within the objects of main array-of-objects
					else if (hasArrayOfObjectsWithinArray(apiData)) {
						console.log("###");

						apiData.forEach((obj, idx) => {
							if (idx < updatedProps.numRowsPerPageValue) {
								let tr = document.createElement("tr");
								tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
								tr.setAttribute("class", "custom-table-tbody-row");
								// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
								tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
								tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
								tr.style.width = "100%";
								tr.style.height = "auto";
								if (idx % 2 !== 0) {
									tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
									// hover effect
									tr.addEventListener("mouseover", function () {
										tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
										// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
									});
									tr.addEventListener("mouseleave", function () {
										tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
										// tr.style.scrollbarColor = `${updatedProps.tBodyOddTrBackgroundColor} ${updatedProps.tBodyOddTrBackgroundColor}`;
									});
								} else {
									tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
									// hover effect
									tr.addEventListener("mouseover", function () {
										tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
										// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
									});
									tr.addEventListener("mouseleave", function () {
										tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
										// tr.style.scrollbarColor = `${updatedProps.tBodyEvenTrBackgroundColor} ${updatedProps.tBodyEvenTrBackgroundColor}`;
									});
								}

								// get data and add it as table's column
								for (let i = 0; i < updatedProps.numColsInputValue; i++) {
									let content, nestedContent = [], convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
									let nestedColumn; // variable to check if that column is going to be nested or not

									// field name and type are for toggle replication
									let fieldName;

									// using updatedProps.HeaderFields because only these are present in the downloading code
									// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
									// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
									// }

									// to get the values of the header keynames entered in header input fields for plotting
									try {
										if (`HeaderField_${i}` in updatedProps) {
											// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
											if (updatedProps[`HeaderField_${i}`].includes("-")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*-\s*/g, "-");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split("-");

												const referenceArrayObjName = `${referenceArray[0]}`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj 0", referenceArrayObjName, referenceArrayKeyName);
												console.log("referenceArrayObjName", obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (isArrayofObjects(obj[referenceArrayObjName])) {
													obj[referenceArrayObjName].forEach((nesObj) => {
														nestedContent.push(nesObj[referenceArrayKeyName]);
													});
													content = nestedContent;
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
												// console.log("+content+ 1", content);
											} else if (!updatedProps[`HeaderField_${i}`].includes("-") && !updatedProps[`HeaderField_${i}`].includes(":")) {
												// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 0", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else {
													content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												}
											}
											console.log("+updatedProps+", `${updatedProps[`HeaderField_${i}`]}`, content);
											// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
											fieldName = updatedProps[`HeaderField_${i}`];
										} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
											// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
											if (updatedProps.HeaderFields[`HeaderField_${i}`].includes("-")) {
												console.log("updatedProps.HeaderFields[`HeaderField_${i}`] 2", updatedProps.HeaderFields[`HeaderField_${i}`]);
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*-\s*/g, "-");
												// Split the modified string by colon
												const referenceArray = modifiedStr.trim().split("-");

												const referenceArrayObjName = `${referenceArray[0]}`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj 1", referenceArrayObjName, referenceArrayKeyName);
												console.log("referenceArrayObjName", obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (isArrayofObjects(obj[referenceArrayObjName])) {
													obj[referenceArrayObjName].forEach((nesObj) => {
														nestedContent.push(nesObj[referenceArrayKeyName]);
													});
													content = nestedContent;
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
												console.log("+content+ 2", content);
											} else if (!updatedProps.HeaderFields[`HeaderField_${i}`].includes("-") && !updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
												console.log("updatedProps.HeaderFields[`HeaderField_${i}`] 3", updatedProps.HeaderFields[`HeaderField_${i}`]);
												console.log("+content+ 3", content);
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 1", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													// console.log("converted content 1", convertedNumber, content);
												} else {
													// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													// console.log("+content++ 3", content, nestedContent);
												}
												// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												// console.log("+content++ 2", content, nestedContent);
											}
											console.log("updatedProps.HeaderFields content+", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
											// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
											fieldName = updatedProps.HeaderFields[`HeaderField_${i}`];
										} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
											// Get the value of the attribute from 'this'
											console.log("customTableContainer", customTableContainer);
											const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

											// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
											if (headerFieldAttribute.includes("-")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = headerFieldAttribute.replace(/\s*-\s*/g, "-");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split("-");

												const referenceArrayObjName = `${referenceArray[0]}`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object is not undefined, then find the further keyname
												if (isArrayofObjects(obj[referenceArrayObjName])) {
													obj[referenceArrayObjName].forEach((nesObj) => {
														nestedContent.push(nesObj[referenceArrayKeyName]);
													});
													content = nestedContent;
												}
												// console.log("+content+ 3", content);
											} else if (!headerFieldAttribute.includes("-") && !headerFieldAttribute.includes(":")) {
												// content = obj[headerFieldAttribute];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[headerFieldAttribute];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[headerFieldAttribute];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 2", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
													content = obj[headerFieldAttribute];
												}
											}
											console.log("headerFieldAttribute", headerFieldAttribute, content);
											// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
											fieldName = headerFieldAttribute;
										}
									} catch (e) {
										// statements
										console.error(e);
									}

									try {
										if (`HeaderField_${i}` in updatedProps) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj 2", referenceArrayObjName, referenceArrayKeyName);
												console.log("referenceArrayObjName", obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else if (!updatedProps[`HeaderField_${i}`].includes(":") && !updatedProps[`HeaderField_${i}`].includes("-")) {
												// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 0", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else {
													content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
											// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
											fieldName = updatedProps[`HeaderField_${i}`];
										} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else if (!updatedProps.HeaderFields[`HeaderField_${i}`].includes(":") && !updatedProps.HeaderFields[`HeaderField_${i}`].includes("-")) {
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 1", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else {
													content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
												// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
											}
											console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
											// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
											fieldName = updatedProps.HeaderFields[`HeaderField_${i}`];
										} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
											// Get the value of the attribute from 'this'
											console.log("customTableContainer", customTableContainer);
											const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (headerFieldAttribute.includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object is not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
											} else if (!headerFieldAttribute.includes(":") && !headerFieldAttribute.includes("-")) {
												// content = obj[headerFieldAttribute];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[headerFieldAttribute];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[headerFieldAttribute];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 2", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
													content = obj[headerFieldAttribute];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("headerFieldAttribute", headerFieldAttribute, content);
											// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
											fieldName = headerFieldAttribute;
										}
									} catch (e) {
										// statements
										console.error(e);
									}

									// to replicate components based on number of rows in nested table - but in an independent column
									// if there is a reserved keyword "nested-column" in the headerField_i then using the structure of 'obj' append stellar buttons and checkboxes those many times in an indepedent column
									try {
										if (`HeaderField_${i}` in updatedProps) {
											// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
											if (updatedProps[`HeaderField_${i}`].includes("nested-column")) {
												nestedColumn = true;
											} else {
												nestedColumn = false;
											}
											console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
										} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
											// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
											if (updatedProps.HeaderFields[`HeaderField_${i}`].includes("nested-column")) {
												nestedColumn = true;
											} else {
												nestedColumn = false;
											}
											console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
										} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
											// Get the value of the attribute from 'this'
											console.log("customTableContainer", customTableContainer);
											const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

											// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
											if (headerFieldAttribute.includes("nested-column")) {
												nestedColumn = true;
											} else {
												nestedColumn = false;
											}
											console.log("headerFieldAttribute", headerFieldAttribute, content);
										}
									} catch (e) {
										// statements
										console.error(e);
									}

									console.log("content, i", content, i);

									// field name and type are for toggle replication
									let fieldType;
									let tableDataFields;
                  					if (tableData && ("fields" in tableData)) tableDataFields = tableData.fields;
									if (tableDataFields) fieldType = tableDataFields.filter((x) => x.field_name === fieldName)[0]?.field_type;

									let td = document.createElement("td");
									td.setAttribute("data-gjs-type", "cell");
									// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
									td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
									td.style.padding = "0";

									td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
									td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
									td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
									td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
									td.style.borderCollapse = "collapse";
									td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
									td.style.color = `${updatedProps.tBodyTdFontColor}`;
									td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
									td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
									// plot content directly if its not an array
									td.innerHTML = content !== undefined && !Array.isArray(content) ? content : "";

									// if the content of the cell is not an array, still hande attaching of special components in the cells
									if (!Array.isArray(content)) {
										console.log("i here 1", i);
										// for replicating single column component
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let headerSingleColumnComponents;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											headerSingleColumnComponents = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('div[data-gjs-type="custom-table-thead-row"][class*="gjs-row"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (headerSingleColumnComponents) {
												if (headerSingleColumnComponents.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												for (let j = 0; j < headerSingleColumnComponents.length; j++) {
													// row.getElementsByTagName('td')[table_selected_column].append(headerSingleColumnComponents[j]);
													let clonedSingleColumnComponent = headerSingleColumnComponents[j].cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(headerSingleColumnComponents[j], clonedSingleColumnComponent);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedSingleColumnComponent.setAttribute("id", `single_column_component_table${id}_row${idx}_column${i}_${j}`);

													// for replicating ACTION BUTTON inside single column components
													let innerClonedActionButtons = clonedSingleColumnComponent.getElementsByClassName("custom-table-action-button");
													if (innerClonedActionButtons.length > 0) {
														Array.from(innerClonedActionButtons).forEach((innerClonedActionButton, j_btn) => {
															if (innerClonedActionButton) {
																// changing the clone's IDs so that they are present and available individually in LB and in download
																innerClonedActionButton.setAttribute("id", `custom_table_action${innerClonedActionButton.getAttribute("data-table-action")}_button_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																if (innerClonedActionButton.getElementsByTagName("p")[0]) {
																	innerClonedActionButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																}

																// adding click event on stellar-button from the table components
																innerClonedActionButton.addEventListener("click", async function (event) {
																	console.log("action-button clicked", "saving data in session storage");
																	event.preventDefault();
																	// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
																	localStorage.setItem(
																		`custom_table_action_button_table${id}_${innerClonedActionButton.getAttribute("data-table-action")}_column${i}_${j}_element${j_btn}_clicked`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			"data-table-action": `${innerClonedActionButton.getAttribute("data-table-action")}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			buttonId: `${innerClonedActionButton.getAttribute("id")}`,
																		}),
																	);

																	// Style for the modals
																	let modalStyle = {
																		padding: "2%",
																		backgroundColor: "white",
																		borderRadius: "5px",
																		boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																		maxWidth: "50vw",
																		width: "30vw",
																		maxHeight: "40vh",
																		margin: "auto",
																		overflow: "auto",
																		"-ms-overflow-style": "none",
																		"scrollbar-width": "none",
																	};

																	// Style for the input fields
																	let inputFieldStyle = {
																		display: "flex",
																		flexDirection: "column",
																		marginBottom: "15px",
																	};

																	// Style for the buttons
																	let buttonStyle = {
																		width: "100%",
																		padding: "10px",
																		backgroundColor: "#007bff",
																		color: "white",
																		border: "none",
																		borderRadius: "5px",
																		cursor: "pointer",
																		// marginRight: 'auto',
																		// marginLeft: 'auto',
																		marginBottom: "5%",
																	};

																	let submitButtonStyle = {
																		width: "50%",
																		padding: "10px",
																		backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																		color: "white",
																		border: "none",
																		borderRadius: "5px",
																		cursor: "pointer",
																		// marginRight: 'auto',
																		// marginLeft: 'auto',
																		marginBottom: "5%",
																	};

																	let closeButtonStyle = {
																		width: "50%",
																		padding: "10px",
																		backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
																		color: "white",
																		border: "none",
																		borderRadius: "5px",
																		cursor: "pointer",
																		// marginRight: 'auto',
																		// marginLeft: 'auto',
																		marginBottom: "5%",
																	};

																	// Edit modal
																	if (innerClonedActionButton.getAttribute("data-table-action") === "edit") {
																		// Create the dialog element
																		let editModal = document.createElement("dialog");
																		editModal.setAttribute("id", "custom-modal");

																		// Apply styles to the modal
																		Object.assign(editModal.style, modalStyle);

																		// Create content for the modal
																		let modalContent = document.createElement("form");

																		// Apply styles to the modal content
																		Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																		// Create a label for the data model input field
																		let dataModelLabel = document.createElement("label");
																		dataModelLabel.innerHTML = `Edit`;
																		dataModelLabel.style.width = "100%";
																		dataModelLabel.style.borderBottom = "1px solid gray";
																		dataModelLabel.style.lineHeight = "2";
																		dataModelLabel.style.fontSize = "1.4vw";

																		// Apply styles to the label
																		Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																		// Append label to the modal content
																		modalContent.appendChild(dataModelLabel);

																		// variable only for the reference keyname user input
																		let selectedOptions = [];
																		// Check if updatedProps.EditableFields is not empty
																		if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																			// Iterate over EditableFields to create form elements
																			for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																				// to handle non-Reference field keynames
																				if (!fieldName.includes("-")) {
																					let fieldLabel = document.createElement("label");
																					fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																					fieldLabel.style.textTransform = "capitalize";
																					modalContent.appendChild(fieldLabel);

																					let inputField = document.createElement("input");
																					inputField.setAttribute("name", fieldName);
																					inputField.value = obj[fieldName]; // to initialize input fields with present values
																					Object.assign(inputField.style, {
																						borderRadius: "5px",
																						border: "1px solid gray",
																						marginBottom: "5%",
																						padding: "1%",
																					});

																					// Set input field type based on field type
																					switch (fieldType.toLowerCase()) {
																						case "string":
																							inputField.setAttribute("type", "text");
																							break;
																						case "boolean":
																							inputField.setAttribute("type", "radio");
																							break;
																						case "number":
																							inputField.setAttribute("type", "number");
																							break;
																						case "date-time":
																							inputField.setAttribute("type", "datetime-local");
																							break;
																						case "time":
																							inputField.setAttribute("type", "time");
																							break;
																						case "file":
																							inputField.setAttribute("type", "file");
																							break;
																						default:
																							inputField.setAttribute("type", "text");
																							break;
																					}
																					modalContent.appendChild(inputField);
																				}

																				// to handle Reference field keynames for example - WebProject1_Address-Sector
																				else if (fieldName.includes("-")) {
																					// variables to be used later in this if-condition
																					let [table, column] = fieldName.split("-");
																					// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																					// create its label
																					let refFieldLabel = document.createElement("label");
																					refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																					refFieldLabel.style.textTransform = "capitalize";
																					modalContent.appendChild(refFieldLabel);

																					// create a drodown for these keynames > its options will be all the values from response from that table
																					let referenceKeynameDropdown = document.createElement("select");
																					referenceKeynameDropdown.setAttribute("name", fieldName);
																					referenceKeynameDropdown.setAttribute("id", fieldName);
																					// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																					Object.assign(referenceKeynameDropdown.style, {
																						borderRadius: "5px",
																						border: "1px solid gray",
																						marginBottom: "5%",
																						padding: "1%",
																					});

																					// populate it with options using the response from its data-model API
																					let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																						headers: {
																							Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																						},
																					});
																					let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																					if (referenceKeynameValueOptionsData) {
																						// setting default option --select--
																						let defaultOption = document.createElement("option");
																						// defaultOption.setAttribute('disabled', 'disabled');
																						defaultOption.innerHTML = "--select--";
																						referenceKeynameDropdown.appendChild(defaultOption);

																						// loop over the response to fill the options of its reference table
																						referenceKeynameValueOptionsData.forEach((optObj) => {
																							let option = document.createElement("option");
																							option.value = optObj[`${column}`];
																							option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																							option.innerHTML = optObj[`${column}`];
																							// to initialize input fields with present values
																							if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																								option.setAttribute("selected", true);
																							}
																							referenceKeynameDropdown.appendChild(option);
																						});
																					}

																					referenceKeynameDropdown.addEventListener("change", function (e) {
																						let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																						// console.log('Selected value:', selectedOptionFromDropdown.value);
																						// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																						// get the reference ID of the table row from main obj to update
																						let refTableId;
																						let tableName = table.split("_")[1];
																						if (obj[tableName] !== null) {
																							refTableId = obj[tableName];
																						}

																						selectedOptions.push({
																							table,
																							column,
																							refTableId,
																							value: selectedOptionFromDropdown.value,
																							"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																						});
																					});

																					modalContent.appendChild(referenceKeynameDropdown);
																				}
																			}
																		}

																		console.log("+bu, bubt+", bu, bubt);

																		let buttonContainer = document.createElement("div");
																		buttonContainer.setAttribute("class", "modal-button-container");
																		buttonContainer.style.display = "flex";
																		buttonContainer.style.alignItems = "center";
																		buttonContainer.style.justifyContent = "space-between";
																		buttonContainer.style.columnGap = "5%";
																		buttonContainer.style.width = "100%";
																		buttonContainer.style.marginTop = "5%";

																		// Create a submit button for the form
																		let submitButton = document.createElement("button");
																		submitButton.setAttribute("class", "table-submit-button");
																		submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
																		submitButton.innerHTML = "Submit";

																		// Apply styles to the button
																		Object.assign(submitButton.style, submitButtonStyle);

																		submitButton.addEventListener("click", function (e) {
																			e.preventDefault(); // Prevent default form action
																			// Handle form submission here
																			console.log("Form submitted!", obj);

																			// Create an object to store the values of input fields
																			let values = {};

																			// // Add default input field values to the values object
																			// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																			// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																		    function replaceSubstringWithTableData(url) {
																		        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																		    }

																		    // Add default input field values to the values object
																		    values["DataModel"] = updatedProps.customUrlValue 
																		        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																		        : replaceSubstringWithTableData(bu);
																		        
																		    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Initialize an empty FormData object to get the form's input fields values
																			let formData = new FormData(modalContent);
																			let formValues = {};
																			for (const [key, value] of formData.entries()) {
																				formValues[key] = value;
																			}

																			// Create the to_insert object with the keynames from updatedProps.EditableFields
																			// and their corresponding values from formValues
																			let to_insert = {};
																			Object.keys(updatedProps.EditableFields).forEach((key) => {
																				if (formValues[key] !== undefined && !key.includes("-")) {
																					// Check if the form has the field
																					to_insert[key] = formValues[key];
																				}

																				// re-attach the reference id to PUT call else it will set the references to null
																				if (formValues[key] !== undefined && key.includes("-")) {
																					let x = key.split("-");
																					let table = x[0].split("_")[1];
																					if (obj[table] !== null) {
																						to_insert[table] = obj[table];
																					}
																				}
																			});

																			console.log("+to_insert+", to_insert);
																			console.log("+selectedOptions+", selectedOptions);

																			// logic to update all table cells the edit button is editing without refreshing
																			for (const key in to_insert) {
																				let columns = [];
																				if (updatedProps[`HeaderField_${i}`]) {
																					Object.keys(updatedProps).map((hF_i) => {
																						if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																					});
																				} else {
																					Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																						if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																					});
																				}

																				// console.log('+columns+', columns);

																				columns.forEach(idx => {
																					let td = tr.getElementsByTagName('td')[idx];

																					let valueToUpdateCellWith = to_insert[key];
																					// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																					// Find the first text node
																					const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																					if (textNode) {
																						textNode.nodeValue = valueToUpdateCellWith;
																					} else {
																						// If no text node exists, create one
																						td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																					}
																				});
																			}

																			// Fetch call (to non-reference table) can be made here using the input field values
																			// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																			fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																				method: "PUT",
																				headers: {
																					"Content-Type": "application/json",
																					Authorization: `Bearer ${values.BearerToken}`,
																				},
																				body: JSON.stringify({
																					to_insert: to_insert,
																				}),
																			})
																				.then((response) => response.json())
																				.then((data) => console.log(data));

																			// Fetch call (for reference table)
																			if (selectedOptions.length > 0) {
																				selectedOptions.forEach((data) => {
																					// make api calls per object
																					let requestBody = {};
																					requestBody[data.column] = data.value; // Assign dynamic key-value pair
																					fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																						method: "PUT",
																						headers: {
																							"Content-Type": "application/json",
																							Authorization: `Bearer ${values.BearerToken}`,
																						},
																						body: JSON.stringify({
																							to_insert: requestBody,
																						}),
																					})
																						.then((response) => response.json())
																						.then((data) => console.log(data));
																				});
																			}

																			// Close the modal when the form is submitted
																			editModal.close();

																			// clear the selectedOptions for next turn
																			selectedOptions = [];

																			// // giving a delay in reload so that it doesn't change the url in the broswer tab
																			// setTimeout(function () {
																			// 	window.top.location.reload();
																			// }, 2000);
																		});

																		// Create a close button for the modal
																		let closeButton = document.createElement("button");
																		closeButton.setAttribute("class", "table-close-button");
																		closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
																		closeButton.innerHTML = "Close";

																		// Apply styles to the button
																		Object.assign(closeButton.style, closeButtonStyle);

																		closeButton.addEventListener("click", function (event) {
																			event.preventDefault(); // Prevent default behavior of the click event
																			editModal.close(); // Close the modal when the close button is clicked
																		});

																		// Append content, submit button, and close button to the modal
																		buttonContainer.appendChild(submitButton);
																		buttonContainer.appendChild(closeButton);
																		modalContent.appendChild(buttonContainer);
																		editModal.appendChild(modalContent);

																		// Append the modal to the document
																		document.body.appendChild(editModal);

																		// Show the modal
																		editModal.showModal();
																	}
																	// Delete modal
																	else if (innerClonedActionButton.getAttribute("data-table-action") === "delete") {
																		// Create the dialog element
																		let deleteModal = document.createElement("dialog");
																		deleteModal.setAttribute("id", "delete-modal");

																		// Apply styles to the modal
																		Object.assign(deleteModal.style, modalStyle);

																		// Create content for the modal
																		let modalContent = document.createElement("form");

																		// Apply styles to the modal content
																		Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																		// Create a label for the data model input field
																		let dataModelLabel = document.createElement("label");
																		dataModelLabel.innerHTML = `Delete`;
																		dataModelLabel.style.width = "100%";
																		dataModelLabel.style.borderBottom = "1px solid gray";
																		dataModelLabel.style.lineHeight = "2";
																		dataModelLabel.style.fontSize = "1.4vw";

																		// Apply styles to the label
																		Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																		// Append label to the modal content
																		modalContent.appendChild(dataModelLabel);

																		// // Create an input field for data model with a default value
																		// let dataModelInputField = document.createElement("input");
																		// dataModelInputField.setAttribute('type', 'text');
																		// dataModelInputField.setAttribute('name', 'DataModel');
																		// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
																		// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

																		// // Apply styles to the input fields
																		// Object.assign(dataModelInputField.style, inputFieldStyle);

																		// modalContent.appendChild(dataModelInputField);

																		// // Create an input field for bearer token with a default value
																		// let bearerTokenInputField = document.createElement("input");
																		// bearerTokenInputField.setAttribute('type', 'text');
																		// bearerTokenInputField.setAttribute('name', 'BearerToken');
																		// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
																		// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

																		// // Apply styles to the input fields
																		// Object.assign(bearerTokenInputField.style, inputFieldStyle);

																		// modalContent.appendChild(bearerTokenInputField);

																		// Create a paragraph tag for confirmation message
																		let confirmationMessage = document.createElement("p");
																		confirmationMessage.style.marginBottom = "5%";
																		confirmationMessage.style.fontSize = "1vw";
																		confirmationMessage.innerHTML = "Are you sure?";

																		// Append confirmation message to the modal content
																		modalContent.appendChild(confirmationMessage);

																		let buttonContainer = document.createElement("div");
																		buttonContainer.setAttribute("class", "modal-button-container");
																		buttonContainer.style.display = "flex";
																		buttonContainer.style.alignItems = "center";
																		buttonContainer.style.justifyContent = "space-between";
																		buttonContainer.style.columnGap = "5%";
																		buttonContainer.style.width = "100%";

																		// Create a submit button for the form
																		let submitButton = document.createElement("button");
																		submitButton.setAttribute("class", "table-delete-yes-button");
																		submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
																		submitButton.innerHTML = "Yes";

																		// Apply styles to the "Yes" button
																		Object.assign(submitButton.style, submitButtonStyle);

																		submitButton.addEventListener("click", async function (e) {
																			e.preventDefault();
																			// Handle form submission here
																			console.log("Form submitted!");

																			// Create an object to store the values of input fields
																			let values = {};

																			// // Add default input field values to the values object
																			// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																			// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																		    function replaceSubstringWithTableData(url) {
																		        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																		    }

																		    // Add default input field values to the values object
																		    values["DataModel"] = updatedProps.customUrlValue 
																		        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																		        : replaceSubstringWithTableData(bu);
																		        
																		    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Use the values in the fetch call or other logic
																			console.log("Input field values:", values);

																			// Make the fetch call to delete the item
																			let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																				method: "DELETE",
																				headers: {
																					"Content-Type": "application/json",
																					Authorization: `Bearer ${values.BearerToken}`,
																				},
																			});
																			// .then(response => {
																			//     if (response.ok) {
																			//         console.log('Item deleted successfully');
																			//     } else {
																			//         console.error('Failed to delete item');
																			//     }
																			// });

																			let responseData = response.json();
																			if (responseData.ok) {
																				console.log("Item deleted successfully");
																			} else {
																				console.error("Failed to delete item");
																			}

																			// Close the modal when the form is submitted
																			deleteModal.close();

																			// giving a delay in reload so that it doesn't change the url in the broswer tab
																			setTimeout(function () {
																				window.top.location.reload();
																			}, 2000);
																		});

																		// Create a close button for the modal
																		let closeButton = document.createElement("button");
																		closeButton.setAttribute("class", "table-delete-no-button");
																		closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
																		closeButton.innerHTML = "No";

																		// Apply styles to the button
																		Object.assign(closeButton.style, closeButtonStyle);

																		closeButton.addEventListener("click", function (e) {
																			e.preventDefault();
																			deleteModal.close(); // Close the modal when the close button is clicked
																		});

																		// Append content, submit button, and close button to the modal
																		buttonContainer.appendChild(submitButton);
																		buttonContainer.appendChild(closeButton);
																		modalContent.appendChild(buttonContainer);
																		deleteModal.appendChild(modalContent);

																		// Append the modal to the document
																		document.body.appendChild(deleteModal);

																		// Show the modal
																		deleteModal.showModal();
																	}
																});

																clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedActionButton);
															}
														});
													}

													// for replicating STELLAR BUTTON inside single column components
													let innerClonedStellarButtons = clonedSingleColumnComponent.getElementsByClassName("stellar-button-container");
													if (innerClonedStellarButtons.length > 0) {
														Array.from(innerClonedStellarButtons).forEach((innerClonedStellarButton, j_btn) => {
															if (innerClonedStellarButton) {
																// changing the clone's IDs so that they are present and available individually in LB and in download
																innerClonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																if (innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
																	innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																}

																// adding click event on stellar-button from the table components
																innerClonedStellarButton.addEventListener("click", function (event) {
																	console.log("stellar-button clicked", "saving data in session storage");
																	event.preventDefault();
																	// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

																	// this registers all stellar button clicks on LS
																	// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
																	// this registers only by the individuals in the column header
																	localStorage.setItem(
																		`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																		}),
																	);

																	sessionStorage.setItem(
																		`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																		}),
																	);
																});

																clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedStellarButton);
															}
														});
													}

													td.appendChild(clonedSingleColumnComponent);

													// to hide the header buttons after they have been cloned
													headerSingleColumnComponents[j].style.display = "none";
												}
											}
											// }
											console.log("headerSingleColumnComponents:", headerSingleColumnComponents);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating stellar buttons
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let headerStellarButtons;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
											console.log("headerStellarButtons1:", headerStellarButtons);
											if (headerStellarButtons) {
												if (headerStellarButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												for (let j = 0; j < headerStellarButtons.length; j++) {
													console.log("headerStellarButtons[j]", headerStellarButtons[j]);
													// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
													let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(headerStellarButtons[j], clonedStellarButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
													clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
													if (clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
														clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);
													}

													// if the header stellar button was not in single column component, only then append it to td
													if (!headerStellarButtons[j].parentNode.classList.contains("gjs-cell")) {
														td.appendChild(clonedStellarButton);
														console.log("appended");
													}

													// adding click event on stellar-button from the table components
													clonedStellarButton.addEventListener("click", function (event) {
														console.log("stellar-button clicked", "saving data in session storage");
														event.preventDefault();
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

														// this registers all stellar button clicks on LS
														// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
														// this registers only by the individuals in the column header
														localStorage.setItem(
															`clicked_stellar_button_container_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedStellarButton.getAttribute("id")}`,
															}),
														);

														sessionStorage.setItem(
															`clicked_stellar_button_container_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedStellarButton.getAttribute("id")}`,
															}),
														);

														// highlight the table row that the stellar button has selected
														if (updatedProps.tBodyTrSelectColorHighlight)
															clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";
	
														customTable.onclick = function(event) {														
															// Check if the click is inside the custom table but outside the clicked row
															// if (
															// 	customTable.contains(event.target) && // Click is inside the customTable
															// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
															// ) {
															// 	console.log('@deselecting row', idx);
															// 	// Determine original color based on row index (even/odd)
															// 	const originalColor = idx % 2 === 0
															// 		? updatedProps.tBodyEvenTrBackgroundColor
															// 		: updatedProps.tBodyOddTrBackgroundColor;
	
															// 	// Reset the row's background color
															// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
															// }
	
															// Iterate over all rows in the table
															const rows = customTable.querySelectorAll("tr");
															rows.forEach((row, rowIndex) => {
																// Skip the currently selected row
																if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																	return;
																}
	
																// Reset the background color based on even/odd index
																const originalColor = rowIndex % 2 === 0
																	? updatedProps.tBodyEvenTrBackgroundColor
																	: updatedProps.tBodyOddTrBackgroundColor;
	
																row.style.backgroundColor = originalColor;
															});
														};
													});

													// to hide the header buttons after they have been cloned
													headerStellarButtons[j].style.display = "none";
												}
											}
											// }
											console.log("headerStellarButtons:", headerStellarButtons);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating action buttons
										try {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find action-button component in header column
											let headerActionButtons;
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
											headerActionButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-action-button");

											if (headerActionButtons.length > 0) {
												if (headerActionButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(headerActionButtons).forEach(async (j, j_idx) => {
													// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
													let clonedButton = j.cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													await copyStyles(j, clonedButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedButton.setAttribute("id", `custom_table_action_button_table${id}_row${idx}_column${i}_${j}`);
													if (clonedButton.getElementsByTagName("p")[0]) {
														clonedButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}`);
													}

													// if the header action button was not in single column component, only then append it to td
													console.log("j.parentNode.classList", j.parentNode.classList);
													if (!j.parentNode.classList.contains("gjs-cell")) {
														td.appendChild(clonedButton);
														console.log("appended");
													}

													// adding click event on stellar-button from the table components
													clonedButton.addEventListener("click", async function (event) {
														console.log("action-button clicked", "saving data in session storage");
														event.preventDefault();
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
														localStorage.setItem(
															`custom_table_action_button_table${id}_clicked`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																"data-table-action": `${clonedButton.getAttribute("data-table-action")}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedButton.getAttribute("id")}`,
															}),
														);

														// Style for the modals
														let modalStyle = {
															padding: "2%",
															backgroundColor: "white",
															borderRadius: "5px",
															boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
															maxWidth: "50vw",
															width: "30vw",
															maxHeight: "40vh",
															margin: "auto",
															overflow: "auto",
															"-ms-overflow-style": "none",
															"scrollbar-width": "none",
														};

														// Style for the input fields
														let inputFieldStyle = {
															display: "flex",
															flexDirection: "column",
															marginBottom: "15px",
														};

														// Style for the buttons
														let buttonStyle = {
															width: "100%",
															padding: "10px",
															backgroundColor: "#007bff",
															color: "white",
															border: "none",
															borderRadius: "5px",
															cursor: "pointer",
															// marginRight: 'auto',
															// marginLeft: 'auto',
															marginBottom: "5%",
														};

														let submitButtonStyle = {
															width: "50%",
															padding: "10px",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															color: "white",
															border: "none",
															borderRadius: "5px",
															cursor: "pointer",
															// marginRight: 'auto',
															// marginLeft: 'auto',
															marginBottom: "5%",
														};

														let closeButtonStyle = {
															width: "50%",
															padding: "10px",
															backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
															color: "white",
															border: "none",
															borderRadius: "5px",
															cursor: "pointer",
															// marginRight: 'auto',
															// marginLeft: 'auto',
															marginBottom: "5%",
														};

														// Edit modal
														if (clonedButton.getAttribute("data-table-action") === "edit") {
															// Create the dialog element
															let editModal = document.createElement("dialog");
															editModal.setAttribute("id", "custom-modal");

															// Apply styles to the modal
															Object.assign(editModal.style, modalStyle);

															// Create content for the modal
															let modalContent = document.createElement("form");

															// Apply styles to the modal content
															Object.assign(modalContent.style, {
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
															});

															// Create a label for the data model input field
															let dataModelLabel = document.createElement("label");
															dataModelLabel.innerHTML = `Edit`;
															dataModelLabel.style.width = "100%";
															dataModelLabel.style.borderBottom = "1px solid gray";
															dataModelLabel.style.lineHeight = "2";
															dataModelLabel.style.fontSize = "1.4vw";

															// Apply styles to the label
															Object.assign(dataModelLabel.style, {
																marginBottom: "10px",
															});

															// Append label to the modal content
															modalContent.appendChild(dataModelLabel);

															// variable only for the reference keyname user input
															let selectedOptions = [];
															// Check if updatedProps.EditableFields is not empty
															if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																// Iterate over EditableFields to create form elements
																for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																	// to handle non-Reference field keynames
																	if (!fieldName.includes("-")) {
																		let fieldLabel = document.createElement("label");
																		fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																		fieldLabel.style.textTransform = "capitalize";
																		modalContent.appendChild(fieldLabel);

																		let inputField = document.createElement("input");
																		inputField.setAttribute("name", fieldName);
																		inputField.value = obj[fieldName]; // to initialize input fields with present values
																		Object.assign(inputField.style, {
																			borderRadius: "5px",
																			border: "1px solid gray",
																			marginBottom: "5%",
																			padding: "1%",
																		});

																		// Set input field type based on field type
																		switch (fieldType.toLowerCase()) {
																			case "string":
																				inputField.setAttribute("type", "text");
																				break;
																			case "boolean":
																				inputField.setAttribute("type", "radio");
																				break;
																			case "number":
																				inputField.setAttribute("type", "number");
																				break;
																			case "date-time":
																				inputField.setAttribute("type", "datetime-local");
																				break;
																			case "time":
																				inputField.setAttribute("type", "time");
																				break;
																			case "file":
																				inputField.setAttribute("type", "file");
																				break;
																			default:
																				inputField.setAttribute("type", "text");
																				break;
																		}
																		modalContent.appendChild(inputField);
																	}

																	// to handle Reference field keynames for example - WebProject1_Address-Sector
																	else if (fieldName.includes("-")) {
																		// variables to be used later in this if-condition
																		let [table, column] = fieldName.split("-");
																		// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																		// create its label
																		let refFieldLabel = document.createElement("label");
																		refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																		refFieldLabel.style.textTransform = "capitalize";
																		modalContent.appendChild(refFieldLabel);

																		// create a drodown for these keynames > its options will be all the values from response from that table
																		let referenceKeynameDropdown = document.createElement("select");
																		referenceKeynameDropdown.setAttribute("name", fieldName);
																		referenceKeynameDropdown.setAttribute("id", fieldName);
																		// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																		Object.assign(referenceKeynameDropdown.style, {
																			borderRadius: "5px",
																			border: "1px solid gray",
																			marginBottom: "5%",
																			padding: "1%",
																		});

																		// populate it with options using the response from its data-model API
																		let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																			headers: {
																				Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																			},
																		});
																		let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																		if (referenceKeynameValueOptionsData) {
																			// setting default option --select--
																			let defaultOption = document.createElement("option");
																			// defaultOption.setAttribute('disabled', 'disabled');
																			defaultOption.innerHTML = "--select--";
																			referenceKeynameDropdown.appendChild(defaultOption);

																			// loop over the response to fill the options of its reference table
																			referenceKeynameValueOptionsData.forEach((optObj) => {
																				let option = document.createElement("option");
																				option.value = optObj[`${column}`];
																				option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																				option.innerHTML = optObj[`${column}`];
																				// to initialize input fields with present values
																				if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																					option.setAttribute("selected", true);
																				}
																				referenceKeynameDropdown.appendChild(option);
																			});
																		}

																		referenceKeynameDropdown.addEventListener("change", function (e) {
																			let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																			// console.log('Selected value:', selectedOptionFromDropdown.value);
																			// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																			// get the reference ID of the table row from main obj to update
																			let refTableId;
																			let tableName = table.split("_")[1];
																			if (obj[tableName] !== null) {
																				refTableId = obj[tableName];
																			}

																			selectedOptions.push({
																				table,
																				column,
																				refTableId,
																				value: selectedOptionFromDropdown.value,
																				"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																			});
																		});

																		modalContent.appendChild(referenceKeynameDropdown);
																	}
																}
															}

															console.log("+bu, bubt+", bu, bubt);

															let buttonContainer = document.createElement("div");
															buttonContainer.setAttribute("class", "modal-button-container");
															buttonContainer.style.display = "flex";
															buttonContainer.style.alignItems = "center";
															buttonContainer.style.justifyContent = "space-between";
															buttonContainer.style.columnGap = "5%";
															buttonContainer.style.width = "100%";
															buttonContainer.style.marginTop = "5%";

															// Create a submit button for the form
															let submitButton = document.createElement("button");
															submitButton.setAttribute("class", "table-submit-button");
															submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
															submitButton.innerHTML = "Submit";

															// Apply styles to the button
															Object.assign(submitButton.style, submitButtonStyle);

															submitButton.addEventListener("click", function (e) {
																e.preventDefault(); // Prevent default form action
																// Handle form submission here
																console.log("Form submitted!", obj);

																// Create an object to store the values of input fields
																let values = {};

																// // Add default input field values to the values object
																// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Function to replace the substring between "/customroutes/" and the table name with "table_data"
															    function replaceSubstringWithTableData(url) {
															        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
															    }

															    // Add default input field values to the values object
															    values["DataModel"] = updatedProps.customUrlValue 
															        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
															        : replaceSubstringWithTableData(bu);
															        
															    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Initialize an empty FormData object to get the form's input fields values
																let formData = new FormData(modalContent);
																let formValues = {};
																for (const [key, value] of formData.entries()) {
																	formValues[key] = value;
																}

																// Create the to_insert object with the keynames from updatedProps.EditableFields
																// and their corresponding values from formValues
																let to_insert = {};
																Object.keys(updatedProps.EditableFields).forEach((key) => {
																	if (formValues[key] !== undefined && !key.includes("-")) {
																		// Check if the form has the field
																		to_insert[key] = formValues[key];
																	}

																	// re-attach the reference id to PUT call else it will set the references to null
																	if (formValues[key] !== undefined && key.includes("-")) {
																		let x = key.split("-");
																		let table = x[0].split("_")[1];
																		if (obj[table] !== null) {
																			to_insert[table] = obj[table];
																		}
																	}
																});

																console.log("+to_insert+", to_insert);
																console.log("+selectedOptions+", selectedOptions);

																// logic to update all table cells the edit button is editing without refreshing
																for (const key in to_insert) {
																	let columns = [];
																	if (updatedProps[`HeaderField_${i}`]) {
																		Object.keys(updatedProps).map((hF_i) => {
																			if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																		});
																	} else {
																		Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																			if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																		});
																	}

																	// console.log('+columns+', columns);

																	columns.forEach(idx => {
																		let td = tr.getElementsByTagName('td')[idx];

																		let valueToUpdateCellWith = to_insert[key];
																		// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																		// Find the first text node
																		const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																		if (textNode) {
																			textNode.nodeValue = valueToUpdateCellWith;
																		} else {
																			// If no text node exists, create one
																			td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																		}
																	});
																}

																// Fetch call (to non-reference table) can be made here using the input field values
																// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																	method: "PUT",
																	headers: {
																		"Content-Type": "application/json",
																		Authorization: `Bearer ${values.BearerToken}`,
																	},
																	body: JSON.stringify({
																		to_insert: to_insert,
																	}),
																})
																	.then((response) => response.json())
																	.then((data) => console.log(data));

																// Fetch call (for reference table)
																if (selectedOptions.length > 0) {
																	selectedOptions.forEach((data) => {
																		// make api calls per object
																		let requestBody = {};
																		requestBody[data.column] = data.value; // Assign dynamic key-value pair
																		fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																			method: "PUT",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																			body: JSON.stringify({
																				to_insert: requestBody,
																			}),
																		})
																			.then((response) => response.json())
																			.then((data) => console.log(data));
																	});
																}

																// Close the modal when the form is submitted
																editModal.close();

																// clear the selectedOptions for next turn
																selectedOptions = [];

																// // giving a delay in reload so that it doesn't change the url in the broswer tab
																// setTimeout(function () {
																// 	window.top.location.reload();
																// }, 2000);
															});

															// Create a close button for the modal
															let closeButton = document.createElement("button");
															closeButton.setAttribute("class", "table-close-button");
															closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
															closeButton.innerHTML = "Close";

															// Apply styles to the button
															Object.assign(closeButton.style, closeButtonStyle);

															closeButton.addEventListener("click", function (event) {
																event.preventDefault(); // Prevent default behavior of the click event
																editModal.close(); // Close the modal when the close button is clicked
															});

															// Append content, submit button, and close button to the modal
															buttonContainer.appendChild(submitButton);
															buttonContainer.appendChild(closeButton);
															modalContent.appendChild(buttonContainer);
															editModal.appendChild(modalContent);

															// Append the modal to the document
															document.body.appendChild(editModal);

															// Show the modal
															editModal.showModal();
														}
														// Delete modal
														else if (clonedButton.getAttribute("data-table-action") === "delete") {
															// Create the dialog element
															let deleteModal = document.createElement("dialog");
															deleteModal.setAttribute("id", "delete-modal");

															// Apply styles to the modal
															Object.assign(deleteModal.style, modalStyle);

															// Create content for the modal
															let modalContent = document.createElement("form");

															// Apply styles to the modal content
															Object.assign(modalContent.style, {
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
															});

															// Create a label for the data model input field
															let dataModelLabel = document.createElement("label");
															dataModelLabel.innerHTML = `Delete`;
															dataModelLabel.style.width = "100%";
															dataModelLabel.style.borderBottom = "1px solid gray";
															dataModelLabel.style.lineHeight = "2";
															dataModelLabel.style.fontSize = "1.4vw";

															// Apply styles to the label
															Object.assign(dataModelLabel.style, {
																marginBottom: "10px",
															});

															// Append label to the modal content
															modalContent.appendChild(dataModelLabel);

															// // Create an input field for data model with a default value
															// let dataModelInputField = document.createElement("input");
															// dataModelInputField.setAttribute('type', 'text');
															// dataModelInputField.setAttribute('name', 'DataModel');
															// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
															// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

															// // Apply styles to the input fields
															// Object.assign(dataModelInputField.style, inputFieldStyle);

															// modalContent.appendChild(dataModelInputField);

															// // Create an input field for bearer token with a default value
															// let bearerTokenInputField = document.createElement("input");
															// bearerTokenInputField.setAttribute('type', 'text');
															// bearerTokenInputField.setAttribute('name', 'BearerToken');
															// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
															// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

															// // Apply styles to the input fields
															// Object.assign(bearerTokenInputField.style, inputFieldStyle);

															// modalContent.appendChild(bearerTokenInputField);

															// Create a paragraph tag for confirmation message
															let confirmationMessage = document.createElement("p");
															confirmationMessage.style.marginBottom = "5%";
															confirmationMessage.style.fontSize = "1vw";
															confirmationMessage.innerHTML = "Are you sure?";

															// Append confirmation message to the modal content
															modalContent.appendChild(confirmationMessage);

															let buttonContainer = document.createElement("div");
															buttonContainer.setAttribute("class", "modal-button-container");
															buttonContainer.style.display = "flex";
															buttonContainer.style.alignItems = "center";
															buttonContainer.style.justifyContent = "space-between";
															buttonContainer.style.columnGap = "5%";
															buttonContainer.style.width = "100%";

															// Create a submit button for the form
															let submitButton = document.createElement("button");
															submitButton.setAttribute("class", "table-delete-yes-button");
															submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
															submitButton.innerHTML = "Yes";

															// Apply styles to the "Yes" button
															Object.assign(submitButton.style, submitButtonStyle);

															submitButton.addEventListener("click", async function (e) {
																e.preventDefault();
																// Handle form submission here
																console.log("Form submitted!");

																// Create an object to store the values of input fields
																let values = {};

																// // Add default input field values to the values object
																// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Function to replace the substring between "/customroutes/" and the table name with "table_data"
															    function replaceSubstringWithTableData(url) {
															        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
															    }

															    // Add default input field values to the values object
															    values["DataModel"] = updatedProps.customUrlValue 
															        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
															        : replaceSubstringWithTableData(bu);
															        
															    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Use the values in the fetch call or other logic
																console.log("Input field values:", values);

																// Make the fetch call to delete the item
																let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																	method: "DELETE",
																	headers: {
																		"Content-Type": "application/json",
																		Authorization: `Bearer ${values.BearerToken}`,
																	},
																});
																// .then(response => {
																//     if (response.ok) {
																//         console.log('Item deleted successfully');
																//     } else {
																//         console.error('Failed to delete item');
																//     }
																// });

																let responseData = response.json();
																if (responseData.ok) {
																	console.log("Item deleted successfully");
																} else {
																	console.error("Failed to delete item");
																}

																// Close the modal when the form is submitted
																deleteModal.close();

																// giving a delay in reload so that it doesn't change the url in the broswer tab
																setTimeout(function () {
																	window.top.location.reload();
																}, 2000);
															});

															// Create a close button for the modal
															let closeButton = document.createElement("button");
															closeButton.setAttribute("class", "table-delete-no-button");
															closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
															closeButton.innerHTML = "No";

															// Apply styles to the button
															Object.assign(closeButton.style, closeButtonStyle);

															closeButton.addEventListener("click", function (e) {
																e.preventDefault();
																deleteModal.close(); // Close the modal when the close button is clicked
															});

															// Append content, submit button, and close button to the modal
															buttonContainer.appendChild(submitButton);
															buttonContainer.appendChild(closeButton);
															modalContent.appendChild(buttonContainer);
															deleteModal.appendChild(modalContent);

															// Append the modal to the document
															document.body.appendChild(deleteModal);

															// Show the modal
															deleteModal.showModal();
														}
													});

													// to hide the header buttons after they have been cloned
													j.style.display = "none";
												});
											}
											console.log("headerActionButtons:", headerActionButtons);
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating input_file buttons
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let headerInputFileButtons;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											headerInputFileButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_file_container");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (headerInputFileButtons) {
												if (headerInputFileButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												// for (let j = 0; j < headerInputFileButtons.length; j++) {
												// 	// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
												// 	let clonedInputFileButton = headerInputFileButtons[j].cloneNode(true);

												// 	// Copy the styles from the original button to the cloned button and its inner elements
												// 	copyStyles(headerInputFileButtons[j], clonedInputFileButton);

												// 	// changing the clone's IDs so that they are present and available individually in LB and in download
												// 	clonedInputFileButton.setAttribute('id', `input_file_container_table${id}_row${idx}_column${i}_${j}`);
												// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].setAttribute('id', `input_file_label_table${id}_row${idx}_column${i}_${j}`);
												// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].getElementsByTagName('p')[0].setAttribute('id', `input_file_label_p_table${id}_row${idx}_column${i}_${j}`);

												// 	td.appendChild(clonedInputFileButton);

												// 	let headerInputFileClickHandler = function(event) {
												// 		console.log('input_file_label clicked', 'saving data in session storage');
												// 		// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

												// 		// this registers all stellar button clicks on LS
												// 		// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
												// 		// this registers only by the individuals in the column header
												// 		localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, JSON.stringify({
												// 			'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
												// 			tableId: `${id}`,
												// 			rowId: `custom_table_tbody_row${idx}_table${id}`,
												// 			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												// 			buttonId: `${clonedInputFileButton.getAttribute('id')}`,
												// 		}));

												// 		console.log('clonedInputFileButton++', clonedInputFileButton);

												// 		let inputFile = document.createElement('input');
												//         inputFile.type = 'file';
												//         inputFile.style.display = 'none';

												//         inputFile.addEventListener('change', async function (event) {
												//               let file = inputFile.files[0];
												//               console.log('this++', this);
												//               console.log('file is:', file);

												//               let formData = new FormData();
												//               formData.append('file', file);

												//               try {
												//                   const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
												//                       method: 'POST',
												//                       headers: {
												//                           // 'Content-Type': 'multipart/form-data',
												//                       },
												//                       body: formData,
												//                   });

												//                   if (response.ok) {
												//                       const data = await response.json();
												//                       console.log('File uploaded:', data);
												//                       clonedInputFileButton.setAttribute('data_file_url', data["fileLocation"]);

												//                     // Retrieve the object from local storage
												// 			        let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j}`)) || {};

												// 			        // Add the data_file_url and its value to the object
												// 			        clickedFileData.data_file_url = data["fileLocation"];

												// 			        // Convert the modified object back to a JSON string
												// 			        let updatedClickedFileData = JSON.stringify(clickedFileData);

												// 			        // Update the local storage with the modified JSON string
												// 			        localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, updatedClickedFileData);
												//                   } else {
												//                       console.error('Error uploading file:', response.statusText);
												//                   }

												//                   clonedInputFileButton.onclick = null;
												//               } catch (error) {
												//                   console.error('Error uploading file:', error);
												//               } finally {
												//               	console.log('after 1')
												//                   // Remove the inputFile element after API call completes
												//                   if (inputFile && inputFile.parentNode) {
												//                   	console.log('after 2')
												//                       inputFile.parentNode.removeChild(inputFile);
												//                   }
												//               }
												//         });

												//         clonedInputFileButton.appendChild(inputFile);
												//         inputFile.click();
												//         console.log('clicked', clonedInputFileButton);
												// 	};

												// 	// adding click event on stellar-button from the table components
												// 	// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
												// 	clonedInputFileButton.onclick = headerInputFileClickHandler;

												// 	// to hide the header buttons after they have been cloned
												// 	headerInputFileButtons[j].style.display = "none";
												// }

												Array.from(headerInputFileButtons).forEach(async (j, j_idx) => {
													// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
													let clonedInputFileButton = j.cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(j, clonedInputFileButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedInputFileButton.setAttribute("id", `input_file_container_table${id}_row${idx}_column${i}_${j_idx}`);
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].setAttribute("id", `input_file_label_table${id}_row${idx}_column${i}_${j_idx}`);
													if (clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0]) {
														clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0].setAttribute("id", `input_file_label_p_table${id}_row${idx}_column${i}_${j_idx}`);
													}

													td.appendChild(clonedInputFileButton);

													let headerInputFileClickHandler = function (event) {
														console.log("input_file_label clicked", "saving data in session storage");
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

														// this registers all stellar button clicks on LS
														// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
														// this registers only by the individuals in the column header
														localStorage.setItem(
															`clicked_input_file_container_table${id}_column${i}_${j_idx}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedInputFileButton.getAttribute("id")}`,
															}),
														);

														// console.log('clonedInputFileButton++', clonedInputFileButton);

														let inputFile = document.createElement("input");
														inputFile.type = "file";
														inputFile.style.display = "none";

														inputFile.addEventListener("change", async function (event) {
															let file = inputFile.files[0];
															console.log("this++", this);
															console.log("file is:", file);

															let formData = new FormData();
															formData.append("file", file);

															try {
																const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
																	method: "POST",
																	headers: {
																		// 'Content-Type': 'multipart/form-data',
																	},
																	body: formData,
																});

																if (response.ok) {
																	const data = await response.json();
																	console.log("File uploaded:", data);
																	td.setAttribute("data_file_url", data["fileLocation"]);

																	// Retrieve the object from local storage
																	let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`)) || {};

																	// Add the data_file_url and its value to the object
																	clickedFileData.data_file_url = data["fileLocation"];

																	// Convert the modified object back to a JSON string
																	let updatedClickedFileData = JSON.stringify(clickedFileData);

																	// Update the local storage with the modified JSON string
																	localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`, updatedClickedFileData);
																} else {
																	console.error("Error uploading file:", response.statusText);
																}
															} catch (error) {
																console.error("Error uploading file:", error);
															} finally {
																// Remove the inputFile element after API call completes
																if (inputFile && inputFile.parentNode) {
																	inputFile.parentNode.removeChild(inputFile);
																}
															}
														});

														clonedInputFileButton.appendChild(inputFile);
														inputFile.click();

														if (inputFile && inputFile.parentNode) {
															inputFile.parentNode.removeChild(inputFile);
														}
													};

													// adding click event on stellar-button from the table components
													// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].onclick = headerInputFileClickHandler;

													// to hide the header buttons after they have been cloned
													j.style.display = "none";
												});
											}
											// }
											console.log("headerInputFileButtons:", headerInputFileButtons);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating radio_regular_form
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let radioButtonGroups;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											radioButtonGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("radio_regular_form");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (radioButtonGroups) {
												if (radioButtonGroups.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(radioButtonGroups).forEach((radioButtonGroup, j) => {
													let clonedRadioButtonGroup = radioButtonGroup.cloneNode(true);
													// Copy styles from the original to the cloned button
													copyStyles2(radioButtonGroup, clonedRadioButtonGroup);

													// Change IDs and append cloned radio button groups to the container
													clonedRadioButtonGroup.setAttribute("id", `radio_regular_form_table${id}_row${idx}_column${i}_${j}`);

													// Add event listener to each radio button in the group
													clonedRadioButtonGroup.querySelectorAll('input[type="radio"]').forEach((radioButton) => {
														radioButton.addEventListener("click", (event) => {
															// Get the selected radio button's value and id
															const selectedValue = event.target.value;
															const selectedId = event.target.id;
															const selectedDataDbId = event.target.getAttribute("data_db_id") ? event.target.getAttribute("data_db_id") : "";

															// Store the selected value, id, and other relevant data in local storage
															localStorage.setItem(
																`clicked_radio_regular_form_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	radioButtonId: `${selectedId}`,
																	selectedValue: `${selectedValue}`,
																	selectedDataDbId: `${selectedDataDbId}`,
																}),
															);

															// You can also perform other actions based on the selected radio button
															// For example, update UI, make API calls, etc.
														});
													});

													// Append the container to the table cell
													td.appendChild(clonedRadioButtonGroup);
												});
											}

											// Hide the original radio button groups in the header column
											Array.from(radioButtonGroups).forEach((radioButtonGroup) => {
												radioButtonGroup.style.display = "none";
											});
											// }
											console.log("radioButtonGroups:", radioButtonGroups);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating checkbox_regular_form
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let checkboxGroups;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											checkboxGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (checkboxGroups) {
												if (checkboxGroups.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(checkboxGroups).forEach((checkboxGroup, j) => {
													let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
													// Copy styles from the original to the cloned checkbox group
													copyStyles2(checkboxGroup, clonedCheckboxGroup);

													// Change IDs and append cloned checkbox groups to the container
													clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_table${id}_row${idx}_column${i}_${j}`);

													// Add event listener to each checkbox in the group
													clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
														checkbox.addEventListener("change", (event) => {
															// Initialize an array to hold the values of checked checkboxes
															let selectedValues = [];
															let selectedIds = [];
															let selectedDataDbIds = [];

															// Iterate over all checkboxes to find the checked ones
															clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
																// If a checkbox is checked, add its value and id to the respective arrays
																if (checkbox.checked) {
																	selectedValues.push(checkbox.value);
																	selectedIds.push(checkbox.id);
																	selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
																}
															});

															// Store the array of selected values and ids in local storage
															localStorage.setItem(
																`clicked_checkbox_regular_form_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	selectedValues: selectedValues,
																	selectedIds: selectedIds,
																	selectedDataDbIds: selectedDataDbIds,
																}),
															);

															// You can also perform other actions based on the selected checkboxes
															// For example, update UI, make API calls, etc.
														});
													});

													// Append the container to the table cell
													td.appendChild(clonedCheckboxGroup);
												});

												// Hide the original radio button groups in the header column
												Array.from(checkboxGroups).forEach((checkboxGroup) => {
													checkboxGroup.style.display = "none";
												});
											}
											// }
											console.log("checkboxGroups:", checkboxGroups);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating checkbox_regular_form_new
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let checkboxGroupsV2;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											checkboxGroupsV2 = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form_new");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (checkboxGroupsV2) {
												if (checkboxGroupsV2.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(checkboxGroupsV2).forEach((checkboxGroup, j) => {
													let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
													// Copy styles from the original to the cloned checkbox group
													copyStyles2(checkboxGroup, clonedCheckboxGroup);

													// Change IDs and append cloned checkbox groups to the container
													clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_v2_table${id}_row${idx}_column${i}_${j}`);

													// Add event listener to each checkbox in the group
													clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
														checkbox.addEventListener("change", (event) => {
															// Initialize an array to hold the values of checked checkboxes
															let selectedValues = [];
															let selectedIds = [];
															let selectedDataDbIds = [];

															// Iterate over all checkboxes to find the checked ones
															clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
																// If a checkbox is checked, add its value and id to the respective arrays
																if (checkbox.checked) {
																	selectedValues.push(checkbox.value);
																	selectedIds.push(checkbox.id);
																	selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
																}
															});

															// Store the array of selected values and ids in local storage
															localStorage.setItem(
																`clicked_checkbox_regular_form_v2_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	selectedValues: selectedValues,
																	selectedIds: selectedIds,
																	selectedDataDbIds: selectedDataDbIds,
																}),
															);

															// You can also perform other actions based on the selected checkboxes
															// For example, update UI, make API calls, etc.
														});
													});

													// Append the container to the table cell
													td.appendChild(clonedCheckboxGroup);
												});

												// Hide the original radio button groups in the header column
												Array.from(checkboxGroupsV2).forEach((checkboxGroup) => {
													checkboxGroup.style.display = "none";
												});
											}
											// }
											console.log("checkboxGroupsV2:", checkboxGroupsV2);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating input_select
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let dropdownContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											dropdownContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_select_container");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (dropdownContainers) {
												if (dropdownContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(dropdownContainers).forEach((dropdownContainer, j) => {
													let dropdown = dropdownContainer.getElementsByTagName("select")[0];
													let dropdownClone = dropdown.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(dropdown, dropdownClone);

													// Change IDs and append cloned dropdowns to the container
													dropdownClone.setAttribute("id", `input_select_table${id}_row${idx}_column${i}_${j}`);
													dropdownClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const selectedValue = event.target.value;
														const selectedOption = event.target.options[event.target.selectedIndex];
														const selectedId = selectedOption.id;

														// Get the data-db-id attribute value of the selected option
														const selectedDataDbId = selectedOption.getAttribute("data_db_id") ? selectedOption.getAttribute("data_db_id") : "";

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_input_select_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValue: `${selectedValue}`,
																selectedId: `${selectedId}`,
																selectedDataDbId: `${selectedDataDbId}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(dropdownClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(dropdownContainers).forEach((dropdownContainer) => {
													dropdownContainer.style.display = "none";
												});
											}
											// }
											console.log("dropdownContainers:", dropdownContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_text_field
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputTextContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (inputTextContainers) {
												if (inputTextContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
													let inputText = inputTextContainer.getElementsByTagName("input")[0];
													let inputTextClone = inputText.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputText, inputTextClone);

													// Change IDs and append cloned dropdowns to the container
													inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
													inputTextClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_form_text_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(inputTextClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputTextContainers).forEach((inputTextContainer) => {
													inputTextContainer.style.display = "none";
												});
											}
											// }
											console.log("inputTextContainers:", inputTextContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_number_field
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputNumberContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (inputNumberContainers) {
												if (inputNumberContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
													let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
													let inputNumberClone = inputNumber.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputNumber, inputNumberClone);

													// Change IDs and append cloned dropdowns to the container
													inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
													inputNumberClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_form_number_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(inputNumberClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
													inputNumberContainer.style.display = "none";
												});
											}
											// }
											console.log("inputNumberContainers:", inputNumberContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating send email buttons
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let sendEmailButtons;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											sendEmailButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("send-email-button-container");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (sendEmailButtons) {
												if (sendEmailButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(sendEmailButtons).forEach((j, j_idx) => {
													// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
													let clonedEmailButton = j.cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(j, clonedEmailButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedEmailButton.setAttribute("id", `send_email_button_container_table${id}_row${idx}_column${i}_${j_idx}`);
													clonedEmailButton.getElementsByClassName("send-email-button")[0].setAttribute("id", `send_email_btn_table${id}_row${idx}_column${i}_${j_idx}`);
													if (clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0]) {
														clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0].setAttribute("id", `send_email_btn_p_table${id}_row${idx}_column${i}_${j_idx}`);
													}

													td.appendChild(clonedEmailButton);

													const sendEmailHandler = async function (sendEmailButtonContainer) {
														let selectedFile;
														// reason - because this script was getting called multiple times, the dialog element was getting added multiple times so this code will be removing all the previously appended ones and then create a new one on-the-go
														if (sendEmailButtonContainer) {
															let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
															modalAlreadyExists.forEach((modal) => {
																console.log("removing modal 2", modal);
																modal.remove();
															});

															// // fetch filter call for content
															// let filteredResponse = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${extractTableName(updatedProps.customUrlValue)}`, {
															// 	method: "POST",
															// 	headers: {
															// 		'Content-Type': "application/json",
															// 		Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
															// 	},
															// 	body: JSON.stringify({
															// 	    filterData: {
															// 	        _id: `${obj[updatedProps.DataModelObjIdKeyname]}`,
															// 	    }
															// 	}),
															// });

															// let filteredResponseData = await filteredResponse.json();

															// Get the keys from updatedProps.HeaderFields object and from HTML attributes
															const headerFieldKeys = Object.keys(updatedProps.HeaderFields);
															const htmlAttributeKeys = Array.from(customTableContainer.attributes)
																.filter((attr) => attr.name.includes("headerfield_"))
																.map((attr) => attr.value);

															console.log("htmlAttributeKeys", htmlAttributeKeys);

															// Combine the keys from both sources
															const allKeys = [...headerFieldKeys, ...htmlAttributeKeys];

															// Accumulate all key-value pairs
															const keyValuePairs = [];

															// Get the values corresponding to the keys from the obj object
															htmlAttributeKeys.forEach((key) => {
																let value;
																if (/:/.test(key)) {
																	// If the key contains a colon (indicating nested properties), split it
																	const [mainKey, subKey] = key.split(/\s*:\s*/);
																	// Check if the main key exists in obj
																	if (obj[`${mainKey}_data`]) {
																		// Check if the main key points to an object
																		if (typeof obj[`${mainKey}_data`] === "object" && obj[`${mainKey}_data`][subKey]) {
																			value = obj[`${mainKey}_data`][subKey];
																			keyValuePairs.push(`${subKey}: ${value}`);
																		}
																	}
																} else {
																	value = obj[key];
																	keyValuePairs.push(`${key}: ${value}`);
																}
																// if (value) {
																//     keyValuePairs.push(`${key}: ${value}`);
																// }
															});

															console.log("allKeys, keyValuePairs", allKeys, keyValuePairs);

															// Join all key-value pairs into a single string
															const filteredKeyValuePairs = keyValuePairs.join("\n");

															console.log("allKeys, filteredKeyValuePairs", allKeys, filteredKeyValuePairs);

															// Create a dialog modal with styling
															const dynamicEmailSendingModal = document.createElement("dialog");
															dynamicEmailSendingModal.setAttribute("class", `custom-email-modal`);
															dynamicEmailSendingModal.setAttribute("id", `custom-email-modal-${sendEmailButtonContainer.id}`);
															Object.assign(dynamicEmailSendingModal.style, {
																margin: "auto",
																width: "35vw",
																overflow: "auto",
																backgroundColor: "white",
																boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																border: "1px solid #ccc",
																borderRadius: "20px",
																zIndex: "1000",
																padding: "0 0 1% 0",
															});

															// modal UI header & footer
															let modalHeader = document.createElement("div");
															Object.assign(modalHeader.style, {
																// backgroundColor: "#1991E7",
																backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																paddingTop: "0.8%",
																paddingBottom: "0.8%",
																paddingLeft: "5%",
																paddingRight: "5%",
																color: "white",
																fontSize: "1vw",
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
															});
															modalHeader.innerHTML = "Compose Message";

															const modalFooter = document.createElement("div");
															Object.assign(modalFooter.style, {
																backgroundColor: "transparent",
																paddingTop: "0.8%",
																paddingBottom: "0.8%",
																paddingLeft: "5%",
																paddingRight: "5%",
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
															});

															// Create a form element
															const emailForm = document.createElement("form");
															Object.assign(emailForm.style, {
																padding: "3% 6%",
																display: "flex",
																flexDirection: "column",
															});

															// Check if EmailFromDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailFromDynamicCheckbox) {
															const emailFromDiv = document.createElement("div");
															Object.assign(emailFromDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailFromLabel = document.createElement("label");
															emailFromLabel.innerHTML = "From";
															// Create an input email field for the sender's email address with styling
															const emailFromField = document.createElement("input");
															Object.assign(emailFromField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailFromField, {
																type: "email",
																id: `emailFrom-${sendEmailButtonContainer.id}`,
																name: "emailFrom",
																placeholder: "Sender's Email Address",
															});
															// visiblity will be hidden because this field is not actually required and it needs to occupy same space for UI alignment purposes
															const emailFromRequiredFieldLabel = document.createElement("span");
															emailFromRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailFromRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
																visibility: "hidden",
															});

															// Append the email input field to the form
															emailFromDiv.appendChild(emailFromLabel);
															emailFromDiv.appendChild(emailFromField);
															emailFromDiv.appendChild(emailFromRequiredFieldLabel);
															emailForm.appendChild(emailFromDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if EmailToDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailToDynamicCheckbox) {
															const emailToDiv = document.createElement("div");
															Object.assign(emailToDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailToLabel = document.createElement("label");
															emailToLabel.innerHTML = "To";
															// Create an input email field for the receiver's email address with styling
															const emailToField = document.createElement("input");
															Object.assign(emailToField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailToField, {
																type: "text",
																id: `emailTo-${sendEmailButtonContainer.id}`,
																name: "emailTo",
																placeholder: "Receiver's Email Addresses (comma separated)",
															});
															const emailToRequiredFieldLabel = document.createElement("span");
															emailToRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailToRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
															});

															// Append the email input field to the form
															emailToDiv.appendChild(emailToLabel);
															emailToDiv.appendChild(emailToField);
															emailToDiv.appendChild(emailToRequiredFieldLabel);
															emailForm.appendChild(emailToDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if EmailSubjectDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailSubjectDynamicCheckbox) {
															const emailSubjectDiv = document.createElement("div");
															Object.assign(emailSubjectDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailSubjectLabel = document.createElement("label");
															emailSubjectLabel.innerHTML = "Subject";
															// Create an input email field for the receiver's email address with styling
															const emailSubjectField = document.createElement("input");
															Object.assign(emailSubjectField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailSubjectField, {
																type: "text",
																id: `emailSubject-${sendEmailButtonContainer.id}`,
																name: "emailSubject",
																placeholder: "Email's subject",
															});
															const emailSubjectRequiredFieldLabel = document.createElement("span");
															emailSubjectRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailSubjectRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
															});

															// Append the email input field to the form
															emailSubjectDiv.appendChild(emailSubjectLabel);
															emailSubjectDiv.appendChild(emailSubjectField);
															emailSubjectDiv.appendChild(emailSubjectRequiredFieldLabel);
															emailForm.appendChild(emailSubjectDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if EmailBodyDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailBodyDynamicCheckbox) {
															const emailBodyDiv = document.createElement("div");
															Object.assign(emailBodyDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailBodyLabel = document.createElement("label");
															emailBodyLabel.innerHTML = "Body";
															// Create an input email field for the receiver's email address with styling
															const emailBodyField = document.createElement("textarea");
															Object.assign(emailBodyField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailBodyField, {
																rows: "5",
																cols: "10",
																id: `emailBody-${sendEmailButtonContainer.id}`,
																name: "emailBody",
																placeholder: "Email's body",
															});
															const emailBodyRequiredFieldLabel = document.createElement("span");
															emailBodyRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailBodyRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
															});

															emailBodyField.value = filteredKeyValuePairs;

															// Append the email input field to the form
															emailBodyDiv.appendChild(emailBodyLabel);
															emailBodyDiv.appendChild(emailBodyField);
															emailBodyDiv.appendChild(emailBodyRequiredFieldLabel);
															emailForm.appendChild(emailBodyDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if FileAttachmentDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.FileAttachmentDynamicCheckbox) {
															// Create an input email field for the receiver's email address with styling
															const fileAttachmentField = document.createElement("input");
															Object.assign(fileAttachmentField.style, {
																// marginBottom: "10px",
																// padding: "5px",
																// width: "80%",
																// borderRadius: "5px",
																// backgroundColor: "transparent",
																// boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
																display: "none",
															});
															Object.assign(fileAttachmentField, {
																type: "file",
																id: `fileAttachment-${sendEmailButtonContainer.id}`,
																name: "fileAttachment",
															});

															// Add event listener for the file input field change event
															fileAttachmentField.addEventListener("change", (event) => {
																selectedFile = event.target.files[0];
																console.log("Selected file:", selectedFile);
																// You can do further processing with the selected file here
															});

															// Create a label for the file input button
															const fileAttachmentLabel = document.createElement("label");
															fileAttachmentLabel.setAttribute("for", "fileAttachmentInput"); // Set the 'for' attribute to match the input field's id
															fileAttachmentLabel.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16"><path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/></svg>';
															Object.assign(fileAttachmentLabel.style, {
																cursor: "pointer",
															});

															// Attach click event to the label
															fileAttachmentLabel.addEventListener("click", () => {
																fileAttachmentField.click();
															});

															// Append the email input field to the form
															modalFooter.appendChild(fileAttachmentLabel);
															modalFooter.appendChild(fileAttachmentField);
															// }
															// // else take directly from props
															// else {}

															// Create a submit button
															const submitButton = document.createElement("button");
															Object.assign(submitButton.style, {
																// marginBottom: "3%",
																// marginRight: "4%",
																alignSelf: "flex-end",
																padding: "1.5% 3%",
																border: "none",
																borderRadius: "25px",
																// backgroundColor: "#007bff",
																backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																color: "white",
																cursor: "pointer",
															});
															submitButton.textContent = "Send";
															submitButton.type = "submit";

															// Add event listener for form submission
															submitButton.addEventListener("click", async (event) => {
																event.preventDefault(); // Prevent form submission

																// Get the entered email values
																const emailFromValue = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`).value : "";
																let emailToValue = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`).value : "";
																emailToValue = emailToValue.includes(",") ? emailToValue.split(/\s*,\s*/) : emailToValue;
																const emailSubjectValue = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`).value : "";
																const emailBodyValue = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`).value : "";
																const fileAttachmentValue = emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`).value : "";

																// to preserve line breaks in email body
																const formattedEmailBodyValue = emailBodyValue.replace(/\n/g, "<br>");

																console.log("form values", emailFromValue, emailToValue, emailSubjectValue, emailBodyValue, formattedEmailBodyValue, fileAttachmentValue);
																console.log("selectedFile", selectedFile);

																// Create a FormData object
																const formData = new FormData();

																// Append form data to FormData object
																formData.append("emailFrom", emailFromValue);
																formData.append("emailTo", emailToValue);
																formData.append("emailSubject", emailSubjectValue);
																formData.append("emailBody", formattedEmailBodyValue);
																formData.append("fileAttachment", selectedFile);

																// Make a fetch call to the API with the entered email address
																try {
																	// send email if email - to, subject, body are provided
																	if (emailToValue !== "" && emailSubjectValue !== "" && emailBodyValue !== "") {
																		const response = await fetch(`${updatedProps.API_HOST}user/custom_email`, {
																			method: "POST",
																			body: formData,
																		});

																		if (response.ok) {
																			console.log("Email sent successfully!");
																			showNotification(true, "Email sent successfully!");
																		} else {
																			console.error("Failed to send email.");
																			showNotification(false, "Failed to send email. Please check the provided values.");
																		}
																	}
																} catch (error) {
																	console.error("Error sending email:", error);
																	showNotification(false, "Error sending email. Please check the provided values.");
																}

																// Close the modal after form submission
																dynamicEmailSendingModal.close();
															});

															// Create a close button
															const closeButton = document.createElement("button");
															closeButton.setAttribute("class", "custom-email-modal-close");
															closeButton.setAttribute("id", `custom-email-modal-close-${sendEmailButtonContainer.id}`);
															Object.assign(closeButton.style, {
																border: "none",
																borderRadius: "3px",
																backgroundColor: "transparent",
																color: "white",
																cursor: "pointer",
															});
															closeButton.textContent = "X";
															closeButton.type = "button";

															// Add event listener for closing the modal
															closeButton.addEventListener("click", () => {
																dynamicEmailSendingModal.close();
															});

															// Append the form and close button to the modal
															dynamicEmailSendingModal.appendChild(modalHeader);
															dynamicEmailSendingModal.appendChild(emailForm);
															modalHeader.appendChild(closeButton);
															modalFooter.appendChild(submitButton);
															dynamicEmailSendingModal.appendChild(modalFooter);

															// Append the modal to the document body
															document.body.appendChild(dynamicEmailSendingModal);

															// Show the modal
															dynamicEmailSendingModal.showModal();
														}
													};

													// adding click event on stellar-button from the table components
													clonedEmailButton.getElementsByClassName("send-email-button")[0].addEventListener("click", function (event) {
														console.log("send-email-button clicked", "saving data in session storage");
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

														// this registers all stellar button clicks on LS
														// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
														// this registers only by the individuals in the column header
														localStorage.setItem(
															`clicked_send_email_button_container_table${id}_column${i}_${j_idx}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedEmailButton.getAttribute("id")}`,
															}),
														);

														sendEmailHandler(clonedEmailButton);
													});

													// to hide the header buttons after they have been cloned
													j.style.display = "none";
												});
											}
											// }
											console.log("sendEmailButtons:", sendEmailButtons);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form text_area
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let normalTextAreaContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											normalTextAreaContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("normal_text_area_regular_form");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (normalTextAreaContainers) {
												if (normalTextAreaContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer, j) => {
													// let inputText = inputTextContainer.getElementsByTagName('input')[0];
													let normalTextAreaContainerClone = normalTextAreaContainer.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(normalTextAreaContainer, normalTextAreaContainerClone);

													// Change IDs and append cloned dropdowns to the container
													normalTextAreaContainerClone.setAttribute("id", `normal_text_area_field_table${id}_row${idx}_column${i}_${j}`);

													// Event listener for input event
													normalTextAreaContainerClone.addEventListener("input", (event) => {
														const enteredValue = event.target.value;
														localStorage.setItem(
															`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
													});

													// Event listener for keypress event with Enter key
													normalTextAreaContainerClone.addEventListener("keypress", (event) => {
														if (event.key === "Enter") {
															const enteredValue = event.target.value;
															localStorage.setItem(
																`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														}
													});

													// Append the dropdown to the table cell
													td.appendChild(normalTextAreaContainerClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer) => {
													normalTextAreaContainer.style.display = "none";
												});
											}
											// }
											console.log("normalTextAreaContainers:", normalTextAreaContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating custom-table-row-selection-checkbox-container
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let tableRowSelectionCheckboxContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (tableRowSelectionCheckboxContainers) {
												// if (tableRowSelectionCheckboxContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// }

												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
													let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
													let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

													// to store row info accross page change
													const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

													// Change IDs and append cloned dropdowns to the container
													tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
													
													// Retrieve stored data from session storage
										            let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

										            // Check if this checkbox should be checked
										            const existingData = storedData.find(
										                (item) =>
										                    item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
										                    item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
										                    item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
										                    item.paginationIndex === currentPageIndex
										            );
										            if (existingData) {
										                tableRowSelectionCheckboxClone.checked = true;
										            }
													
													tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
														const enteredValue = event.target.value;
														const isChecked = event.target.checked; // Check if the checkbox is checked

														// to store row info accross page change
														const checkboxData = {
													        "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
													        tableId: `${id}`,
													        rowId: `custom_table_tbody_row${idx}_table${id}`,
													        cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
													        inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
													        enteredValue: `${enteredValue}`,
													        paginationIndex: currentPageIndex, // Include the current page index
													    };

													    // Retrieve existing data
													    let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

														if (isChecked) {
															// Checkbox is checked, add object to local storage
															localStorage.setItem(
																`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
															// console.log("currentPageIndex", currentPageIndex);

															// If checked, check if the entry already exists
										                    const existingIndex = storedData.findIndex(
										                        (item) =>
										                            item.rowId === checkboxData.rowId &&
										                            item.cellId === checkboxData.cellId &&
										                            item.paginationIndex === checkboxData.paginationIndex &&
										                            item.inputId === checkboxData.inputId
										                    );

										                    if (existingIndex !== -1) {
										                        // Update existing entry
										                        storedData[existingIndex] = checkboxData;
										                    } else {
										                        // Add new entry
										                        storedData.push(checkboxData);
										                    }
														} else {
															// Checkbox is unchecked, remove object from local storage
															localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
															
															// If unchecked, remove the entry
										                    storedData = storedData.filter(
										                        (item) =>
										                            !(item.rowId === checkboxData.rowId &&
										                              item.cellId === checkboxData.cellId &&
										                              item.paginationIndex === checkboxData.paginationIndex &&
										                              item.inputId === checkboxData.inputId)
										                    );
														}

														// You can also perform other actions based on the checkbox state
														// For example, update UI, make API calls, etc.
														
														// Update session storage with the modified array
														sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
													});

													// Append the dropdown to the table cell
													td.appendChild(tableRowSelectionCheckboxClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
													tableRowSelectionCheckboxContainer.style.display = "none";
												});
											}
											// }
											console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_date_time field
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let dateTimeInputContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (dateTimeInputContainers) {
												// if (dateTimeInputContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
													let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
													let dateTimeInputClone = dateTimeInput.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(dateTimeInput, dateTimeInputClone);

													// Change IDs and append cloned dropdowns to the container
													dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
													dateTimeInputClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_form_date_time_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(dateTimeInputClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
													dateTimeInputContainer.style.display = "none";
												});
											}
											// }
											console.log("dateTimeInputContainers:", dateTimeInputContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating toggle
										try {
											// plot only if the field_type of header attribute is boolean
											if (fieldType === 'Boolean') {
												// if (i === Number(table_selected_column)) {
												console.log("i", i);
												// run this everytime api call is done due to user click event
												// find stellar-button component in header column
												let toggleContainers;
												// if (table_selected_column) {
												console.log("customTableThead", customTableThead);

												// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
												// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

												console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

												toggleContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('.custom-table-toggle-container');
												console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

												if (toggleContainers) {
													// if there are toggle elements being replicated then don't show the data model content in their table cells along with the toggle replicated components
													td.textContent = "";

													// if (toggleContainers.length > 0) {
													// 	td.style.height = 'auto';
													// 	td.style.overflow = 'scroll';
													// 	td.style.scrollbarColor = `transparent transparent`;
													// }

													Array.from(toggleContainers).forEach((toggleContainer, j) => {
													    let toggleInnerContainer = toggleContainer.getElementsByClassName("custom-table-inner-toggle-container")[0];
													    let toggleClone = toggleInnerContainer.cloneNode(true);
													    // Copy styles from the original to the cloned dropdown
													    copyStyles(toggleInnerContainer, toggleClone);

													    // Initial state of the toggle
													    let isOn = false;

													    // Change IDs and append cloned dropdowns to the container
													    toggleClone.setAttribute("id", `custom_table_inner_toggle_container_table${id}_row${idx}_column${i}_${j}`);
													    if (toggleClone.getElementsByClassName("custom-table-toggle")[0]) {
													        toggleClone.getElementsByClassName("custom-table-toggle")[0].setAttribute("id", `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`);

													        // update toggle once before/outside click event also so that it initializes according to the value coming from backend. If `content` value is true, toggle should be on, else off.
													        if (content) {
													        	isOn = true;
													        }
													        updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));

													        // now attach event to make the toggle function changing color on on/off actions and sending PUT api calls
													        toggleClone.getElementsByClassName("custom-table-toggle")[0].addEventListener("click", (event) => {
													            isOn = !isOn;

													            // if the cell value is false, change it to true before sending it in PUT call payload, else send it as it it. When toggle is on, 'true' value should be send in the backend.
													            let cellValue = content ? false : true;

													            // a PUT api call will be made to update that particular record of the given table
													            updateTable(cellValue, `${obj[updatedProps.DataModelObjIdKeyname]}`, updatedProps.updatingCustomUrlValue, updatedProps.updatingBaseUrlValue, updatedProps.updatingApiPathValue, updatedProps.updatingApiQueryParamValue, updatedProps.updatingApiTableAttributeValue, updatedProps.updatingApiMethodValue, updatedProps.updatingApiUsernameValue, updatedProps.updatingApiPasswordValue, updatedProps.updatingApiBearerTokenValue, updatedProps.updatingApiMoreHeadersValue);

													            updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));
													            console.log(`Toggle is now: ${isOn ? 'ON' : 'OFF'}`, content);

													            // Construct the localStorage key
													            let toggleLocalStorageKey = `clicked_custom_table_toggle_table${id}_column${i}`;

													            // Retrieve existing data from local storage
													            let togglesData = JSON.parse(localStorage.getItem(toggleLocalStorageKey)) || [];

													            // Create the new toggle data object
													            let newToggleData = {
													                "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
													                tableId: `${id}`,
													                rowId: `custom_table_tbody_row${idx}_table${id}`,
													                cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
													                toggleEleId: `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`,
													                toggleStatus: isOn,
													            };

													            // Check if the toggle already exists
													            let existingToggleIndex = togglesData.findIndex(toggle => toggle.toggleEleId === newToggleData.toggleEleId);

													            if (existingToggleIndex !== -1) {
													                // Update the existing toggle data
													                togglesData[existingToggleIndex] = newToggleData;
													            } else {
													                // Add the new toggle data
													                togglesData.push(newToggleData);
													            }

													            // Update the local storage with the new/updated toggle data, column-wise
													            localStorage.setItem(toggleLocalStorageKey, JSON.stringify(togglesData));
													        });
													    }

													    // Append the dropdown to the table cell
													    td.appendChild(toggleClone);
													});

													// Hide the original dropdowns in the header column
													Array.from(toggleContainers).forEach((toggleContainer) => {
														toggleContainer.style.display = "none";
													});
												}
												// }
												console.log("toggleContainers:", toggleContainers);
												// }
											}										
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating rich text input
	                                    try {
	                                        console.log("i", i);

	                                        let inputTextContainers;

	                                        console.log("customTableThead", customTableThead);

	                                        console.log(
	                                            "thead th i",
	                                            customTableThead
	                                            .getElementsByTagName("tr")[0]
	                                            .querySelectorAll("th")[i]
	                                        );

	                                        let richTextIndexesData = updatedProps.richTextIndexes;
	                                        console.log(
	                                            "richTextIndexesData::::::::::",
	                                            richTextIndexesData
	                                        );

	                                        if (richTextIndexesData.length > 0) {
	                                            richTextIndexesData.forEach((x) => {
	                                                console.log("richTextIndexesData x::::::::::", x);
	                                                console.log("richTextIndexesData::::::", i);
	                                                console.log("richTextIndexesData::::::", i);
	                                                if (x === i) {
	                                                    td.innerHTML = "";
	                                                    let wrapperDiv = document.createElement("div");
	                                                    wrapperDiv.setAttribute(
	                                                        "id",
	                                                        `rich_text_container_${idx}_${id}`
	                                                    );
	                                                    wrapperDiv.setAttribute(
	                                                        "class",
	                                                        "rich_text_table_container"
	                                                    );

	                                                    wrapperDiv.style.backgroundColor = "white";

	                                                    td.appendChild(wrapperDiv);

	                                                    let quill = new Quill(wrapperDiv, {
	                                                        modules: {
	                                                            toolbar: updatedProps.richTextOptions.toolbar ? [
	                                                                [{
	                                                                    header: [1, 2, 3, 4, 5, 6, false]
	                                                                }],
	                                                                ["bold", "italic", "underline", "strike"],
	                                                                [{
	                                                                    list: "bullet"
	                                                                }, {
	                                                                    list: "ordered"
	                                                                }],
	                                                                ["link"],
	                                                                [{
	                                                                    align: []
	                                                                }],
	                                                                [{
	                                                                    color: []
	                                                                }, {
	                                                                    background: []
	                                                                }],
	                                                            ] : false,
	                                                        },
	                                                        placeholder: "",
	                                                        theme: "snow",
	                                                    });

	                                                    updatedProps.richTextOptions.readOnly ? quill.enable(false) : quill.enable(true);

	                                                    if (content) {
	                                                        console.log('contentRich:::::::',content)
	                                                        let jsonContent = JSON.parse(content);
	                                                        console.log('jsonContent:::::::::',jsonContent)
	                                                        console.log('type jsonContent:::::::::',typeof jsonContent)
	                                                        if (jsonContent ) {
	                                                            console.log('inside jsonContent')
	                                                            quill.setContents(jsonContent);
	                                                        }
	                                                    } else {
	                                                        let richTextContent = localStorage.getItem(
	                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`
	                                                        );
	                                                        
	                                                        let parsedData = JSON.parse(richTextContent);
	                                                        if (parsedData && parsedData.delta) {
	                                                            quill.setContents(parsedData.delta);
	                                                        }                                                           
	                                                   }

	                                                    quill.on("text-change", () => {
	                                                        const delta = quill.getContents();

	                                                        let deltaRef = {
	                                                            "data-db-id": `${
	                                                                obj[updatedProps.DataModelObjIdKeyname]
	                                                                }`,
	                                                            delta:delta
	                                                        }
	                                                        
	                                                        localStorage.setItem(
	                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
	                                                            JSON.stringify(deltaRef)
	                                                        );

	                                                        sessionStorage.setItem(
	                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
	                                                            JSON.stringify(deltaRef)
	                                                        );
	                                                    });	                                                   
	                                                }
	                                            });
	                                        }

	                                        // }
	                                        console.log("inputTextContainers:", inputTextContainers);
	                                        // }
	                                    } catch (e) {
											// statements
											console.log(e);
										}
									}

									// if content is an array, then create a table with nested rows and also attach special components in the nested cells of the nested rows
									if (Array.isArray(content)) {
										console.log("i here 3", i);
										let nestedTable = document.createElement("table");

										nestedTable.style.width = "none"; // Override width
										nestedTable.style.marginTop = "0"; // Override margin-top
										nestedTable.style.marginLeft = "0"; // Override margin-left

										content.forEach((row, row_idx) => {
											let nestedTr = document.createElement("tr");

											let nestedTd = document.createElement("td");
											nestedTd.setAttribute("data-gjs-type", "cell");
											// nestedTd.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
											nestedTd.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
											nestedTd.style.padding = "8px";

											nestedTd.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
											nestedTd.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
											nestedTd.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
											nestedTd.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
											nestedTd.style.borderCollapse = "collapse";
											nestedTd.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
											nestedTd.style.color = `${updatedProps.tBodyTdFontColor}`;
											nestedTd.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
											nestedTd.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;

											nestedTd.innerHTML = `${row}`;

											// // getting the nested table data
											// let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
											// // if that table name's key exists in obj and if it is not empty
											// if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {}

											// for replicating custom-table-row-selection-checkbox-container (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
											try {
												// if (i === Number(table_selected_column)) {
												console.log("i", i);
												// run this everytime api call is done due to user click event
												// find stellar-button component in header column
												let tableRowSelectionCheckboxContainers;
												// if (table_selected_column) {
												console.log("customTableThead", customTableThead);

												// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
												// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

												console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

												tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
												console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

												if (tableRowSelectionCheckboxContainers) {
													// if (tableRowSelectionCheckboxContainers.length > 0) {
													// 	td.style.height = 'auto';
													// 	td.style.overflow = 'scroll';
													// }

													Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
														let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
														let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
														// Copy styles from the original to the cloned dropdown
														copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

														// Change IDs and append cloned dropdowns to the container
														tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
														tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
															const enteredValue = event.target.value;
															const isChecked = event.target.checked; // Check if the checkbox is checked

															if (isChecked) {
																// Checkbox is checked, add object to local storage

																// getting the nested table data
																let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
																// if that table name's key exists in obj and if it is not empty
																if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
																	localStorage.setItem(
																		`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			enteredValue: `${enteredValue}`,
																		}),
																	);
																} else {
																	localStorage.setItem(
																		`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			enteredValue: `${enteredValue}`,
																		}),
																	);
																}

																// localStorage.setItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`, JSON.stringify({
																//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
																//     tableId: `${id}`,
																//     rowId: `custom_table_tbody_row${idx}_table${id}`,
																//     nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																//     enteredValue: `${enteredValue}`,
																// }));
															} else {
																// Checkbox is unchecked, remove object from local storage
																localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
															}

															// You can also perform other actions based on the checkbox state
															// For example, update UI, make API calls, etc.
														});

														// Append the dropdown to the table cell
														nestedTd.appendChild(tableRowSelectionCheckboxClone);
													});

													// Hide the original dropdowns in the header column
													Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
														tableRowSelectionCheckboxContainer.style.display = "none";
													});
												}
												// }
												console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
												// }
											} catch (e) {
												// statements
												console.log(e);
											}

											// for replicating form_date_time field (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
											try {
												// if (i === Number(table_selected_column)) {
												console.log("i", i);
												// run this everytime api call is done due to user click event
												// find stellar-button component in header column
												let dateTimeInputContainers;
												// if (table_selected_column) {
												console.log("customTableThead", customTableThead);

												// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
												// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

												console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

												dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
												console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

												if (dateTimeInputContainers) {
													// if (dateTimeInputContainers.length > 0) {
													// 	td.style.height = 'auto';
													// 	td.style.overflow = 'scroll';
													// 	td.style.scrollbarColor = `transparent transparent`;
													// }

													Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
														let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
														let dateTimeInputClone = dateTimeInput.cloneNode(true);
														// Copy styles from the original to the cloned dropdown
														copyStyles1(dateTimeInput, dateTimeInputClone);

														// Change IDs and append cloned dropdowns to the container
														dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
														dateTimeInputClone.addEventListener("change", (event) => {
															// Get the selected option's value and id
															const enteredValue = event.target.value;

															// Store the selected value, id, and other relevant data in local storage
															// getting the nested table data
															let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
															// if that table name's key exists in obj and if it is not empty
															if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
																localStorage.setItem(
																	`clicked_form_date_time_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															} else {
																localStorage.setItem(
																	`clicked_form_date_time_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															}

															// localStorage.setItem(`clicked_form_date_time_field_table${id}_column${i}_nestedtable_row_${j}`, JSON.stringify({
															//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
															//     tableId: `${id}`,
															//     rowId: `custom_table_tbody_row${idx}_table${id}`,
															//     nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
															//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															//     enteredValue: `${enteredValue}`,
															// }));

															// You can also perform other actions based on the selected dropdown option
															// For example, update UI, make API calls, etc.
														});

														// Append the dropdown to the table cell
														nestedTd.appendChild(dateTimeInputClone);
													});

													// Hide the original dropdowns in the header column
													Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
														dateTimeInputContainer.style.display = "none";
													});
												}
												// }
												console.log("dateTimeInputContainers:", dateTimeInputContainers);
												// }
											} catch (e) {
												// statements
												console.log(e);
											}

											// for replicating form_text_field (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
											try {
												// if (i === Number(table_selected_column)) {
												console.log("i here 4", i);
												// run this everytime api call is done due to user click event
												// find stellar-button component in header column
												let inputTextContainers;
												// if (table_selected_column) {
												console.log("customTableThead", customTableThead);

												// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
												// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

												console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

												inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
												console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

												if (i === 8) {
													console.log("inputTextContainers i here:", inputTextContainers);
												}

												if (inputTextContainers) {
													if (inputTextContainers.length > 0) {
														td.style.height = "auto";
														td.style.overflow = "scroll";
														td.style.scrollbarColor = `transparent transparent`;
													}

													Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
														let inputText = inputTextContainer.getElementsByTagName("input")[0];
														let inputTextClone = inputText.cloneNode(true);
														// Copy styles from the original to the cloned dropdown
														copyStyles1(inputText, inputTextClone);

														// Change IDs and append cloned dropdowns to the container
														inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
														inputTextClone.addEventListener("change", (event) => {
															// Get the selected option's value and id
															const enteredValue = event.target.value;

															// Store the selected value, id, and other relevant data in local storage
															// getting the nested table data
															let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
															// if that table name's key exists in obj and if it is not empty
															if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
																localStorage.setItem(
																	`clicked_form_text_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															} else {
																localStorage.setItem(
																	`clicked_form_text_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															}

															// localStorage.setItem(`clicked_form_text_field_table${id}_column${i}_${j}`, JSON.stringify({
															//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
															//     tableId: `${id}`,
															//     rowId: `custom_table_tbody_row${idx}_table${id}`,
															//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															//     enteredValue: `${enteredValue}`,
															// }));

															// You can also perform other actions based on the selected dropdown option
															// For example, update UI, make API calls, etc.
														});

														// Append the dropdown to the table cell
														nestedTd.appendChild(inputTextClone);
													});

													// Hide the original dropdowns in the header column
													Array.from(inputTextContainers).forEach((inputTextContainer) => {
														inputTextContainer.style.display = "none";
													});
												}
												// }
												console.log("inputTextContainers:", inputTextContainers);
												// }
											} catch (e) {
												// statements
												console.log(e);
											}

											// for replicating form_number_field (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
											try {
												// if (i === Number(table_selected_column)) {
												console.log("i", i);
												// run this everytime api call is done due to user click event
												// find stellar-button component in header column
												let inputNumberContainers;
												// if (table_selected_column) {
												console.log("customTableThead", customTableThead);

												// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
												// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

												console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

												inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
												console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

												if (inputNumberContainers) {
													if (inputNumberContainers.length > 0) {
														td.style.height = "auto";
														td.style.overflow = "scroll";
														td.style.scrollbarColor = `transparent transparent`;
													}

													Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
														let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
														let inputNumberClone = inputNumber.cloneNode(true);
														// Copy styles from the original to the cloned dropdown
														copyStyles1(inputNumber, inputNumberClone);

														// Change IDs and append cloned dropdowns to the container
														inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
														inputNumberClone.addEventListener("change", (event) => {
															// Get the selected option's value and id
															const enteredValue = event.target.value;

															// Store the selected value, id, and other relevant data in local storage
															// getting the nested table data
															let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
															// if that table name's key exists in obj and if it is not empty
															if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
																localStorage.setItem(
																	`clicked_form_number_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															} else {
																localStorage.setItem(
																	`clicked_form_number_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															}

															// localStorage.setItem(`clicked_form_number_field_table${id}_column${i}_${j}`, JSON.stringify({
															//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
															//     tableId: `${id}`,
															//     rowId: `custom_table_tbody_row${idx}_table${id}`,
															//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															//     enteredValue: `${enteredValue}`,
															// }));

															// You can also perform other actions based on the selected dropdown option
															// For example, update UI, make API calls, etc.
														});

														// Append the dropdown to the table cell
														nestedTd.appendChild(inputNumberClone);
													});

													// Hide the original dropdowns in the header column
													Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
														inputNumberContainer.style.display = "none";
													});
												}
												// }
												console.log("inputNumberContainers:", inputNumberContainers);
												// }
											} catch (e) {
												// statements
												console.log(e);
											}

											nestedTr.appendChild(nestedTd);
											nestedTable.appendChild(nestedTr);
										});

										td.appendChild(nestedTable);
									}

									console.log("obj ++", obj);
									// if the column is nested then add as many amounts of stellar button in each td cell as there are objects
									if (nestedColumn) {
										console.log("adding nested stellar buttons");

										// find the nested table data in the obj and then loop over its elements which will create as many stellar buttons as required
										let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
										console.log("tableApi", tableApi, extractTableName(tableApi));
										
										// if that table name's key exists in obj and if it is not empty
										if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
											// loop over each object in the array-of-objects in obj so that you can add those many exact number of stellar buttons in the cells and also get the _id reference
											obj[extractTableName(tableApi)].forEach((nestedObj, nestedObj_idx) => {
												// for replicating stellar buttons
												try {
													// if (i === Number(table_selected_column)) {
													console.log("i", i);
													// run this everytime api call is done due to user click event
													// find stellar-button component in header column
													let headerStellarButtons;
													// if (table_selected_column) {
													console.log("customTableThead", customTableThead);

													// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
													// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

													console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

													headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
													console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

													if (headerStellarButtons) {
														if (headerStellarButtons.length > 0) {
															td.style.height = "auto";
															td.style.overflow = "scroll";
															td.style.scrollbarColor = `transparent transparent`;
														}

														for (let j = 0; j < headerStellarButtons.length; j++) {
															// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
															let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

															// Copy the styles from the original button to the cloned button and its inner elements
															copyStyles(headerStellarButtons[j], clonedStellarButton);

															// changing the clone's IDs so that they are present and available individually in LB and in download
															clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
															clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
															clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);

															td.appendChild(clonedStellarButton);

															// adding click event on stellar-button from the table components
															clonedStellarButton.addEventListener("click", function (event) {
																console.log("stellar-button clicked", "saving data in local storage");
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

																// this registers all stellar button clicks on LS
																// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
																// this registers only by the individuals in the column header
																localStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		// 'nested-data-db-id': `${obj[extractTableName(tableApi)][nestedObj_idx][updatedProps.DataModelObjIdKeyname]}`,
																		"nested-data-db-id": `${nestedObj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${nestedObj_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${clonedStellarButton.getAttribute("id")}`,
																	}),
																);

																// highlight the table row that the stellar button has selected
																if (updatedProps.tBodyTrSelectColorHighlight)
																	clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";
			
																customTable.onclick = function(event) {														
																	// Check if the click is inside the custom table but outside the clicked row
																	// if (
																	// 	customTable.contains(event.target) && // Click is inside the customTable
																	// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
																	// ) {
																	// 	console.log('@deselecting row', idx);
																	// 	// Determine original color based on row index (even/odd)
																	// 	const originalColor = idx % 2 === 0
																	// 		? updatedProps.tBodyEvenTrBackgroundColor
																	// 		: updatedProps.tBodyOddTrBackgroundColor;
			
																	// 	// Reset the row's background color
																	// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
																	// }
			
																	// Iterate over all rows in the table
																	const rows = customTable.querySelectorAll("tr");
																	rows.forEach((row, rowIndex) => {
																		// Skip the currently selected row
																		if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																			return;
																		}
			
																		// Reset the background color based on even/odd index
																		const originalColor = rowIndex % 2 === 0
																			? updatedProps.tBodyEvenTrBackgroundColor
																			: updatedProps.tBodyOddTrBackgroundColor;
			
																		row.style.backgroundColor = originalColor;
																	});
																};
															});

															// to hide the header buttons after they have been cloned
															headerStellarButtons[j].style.display = "none";
														}
													}
													// }
													console.log("headerStellarButtons:", headerStellarButtons);
													// }
												} catch (e) {
													// statements
													console.log(e);
												}
											});
										}
									}

									tr.append(td);
								}

								if (customTableTbody) {
									customTableTbody.append(tr);
								}								
								console.log("+idx+", idx);
							}
						});
					}

					// if there no data found in the api and response is {"message":"no data available for the Given table"}
					else if (typeof apiData === "object" && !Array.isArray(apiData) && Object.keys(apiData).includes("message")) {
						let tr = document.createElement("tr");
						tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
						tr.setAttribute("class", "custom-table-tbody-row");
						// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
						tr.setAttribute("id", `custom_table_tbody_rowNULL_table${id}`);
						tr.setAttribute("data-db-id", `null`);
						tr.style.width = "100%";
						tr.style.height = "auto";	
						tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
						// hover effect
						tr.addEventListener("mouseover", function () {
							tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
							// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
						});
						tr.addEventListener("mouseleave", function () {
							tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
							// tr.style.scrollbarColor = `${updatedProps.tBodyOddTrBackgroundColor} ${updatedProps.tBodyOddTrBackgroundColor}`;
						});

						let td = document.createElement("td");
						td.setAttribute("data-gjs-type", "cell");
						// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
						td.setAttribute("id", `custom_table_tbody_row_cell_NULL_table${id}_columnNULL`);
						td.setAttribute("colspan", `${updatedProps.numColsInputValue}`);
						td.style.padding = "8px";

						td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
						td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
						td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
						td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
						td.style.borderCollapse = "collapse";
						td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
						td.style.color = `${updatedProps.tBodyTdFontColor}`;
						td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
						td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
						td.innerHTML = "No records found.";

						tr.append(td);

						if (customTableTbody) {
							customTableTbody.append(tr);
						}
					}
				}

				// plotting without paginated api calls
				async function plotTable(page, updatedProps, sortingOrder, colName) {
					console.log("page1, updatedProps", page, updatedProps);

					// fetching API data
					let apiData, tableData;
					let bu, bubt; // base url bearer token, temp var

					// if only custom url is provided and its a GET call
					if (updatedProps.customUrlValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET" && !updatedProps.nonAPICustomDataCacheKeyname) {
						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a GET call
					else if (!updatedProps.customUrlValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET" && !updatedProps.nonAPICustomDataCacheKeyname) {
						// concatenating base url and its remaining part
						let url, dynamicBaseUrlBearerToken;
						let forDownloadUrl;

						if (updatedProps.baseUrlValue === "baseUrl1") {
							url = updatedProps.options.baseUrl1 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL1}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl1BearerToken;
							dynamicBaseUrlBearerToken = baseUrl1BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl2") {
							url = updatedProps.options.baseUrl2 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL2}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl2BearerToken;
							dynamicBaseUrlBearerToken = baseUrl2BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl3") {
							url = updatedProps.options.baseUrl3 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL3}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl3BearerToken;
							dynamicBaseUrlBearerToken = baseUrl3BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl4") {
							url = updatedProps.options.baseUrl4 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL4}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl4BearerToken;
							dynamicBaseUrlBearerToken = baseUrl4BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl5") {
							url = updatedProps.options.baseUrl5 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL5}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl5BearerToken;
							dynamicBaseUrlBearerToken = baseUrl5BearerToken;
						}

						const urlDownload3 = window.top.location.href;
						const isSubstringPresent3 = (urlDownload3.indexOf("/editor/") !== -1 && urlDownload3.indexOf("?projectId=") !== -1) || urlDownload3.indexOf("/large_preview/") !== -1 || urlDownload3.indexOf("/tab_preview/") !== -1 || urlDownload3.indexOf("/mobile_preview/") !== -1 || urlDownload3.indexOf("/fragment_editor/") !== -1;
       			 		if (!isSubstringPresent3) {
       			 			// running in download
       			 			url = forDownloadUrl;
       			 		}

						bubt = dynamicBaseUrlBearerToken;
						console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only custom url is provided and its a POST call
					else if (updatedProps.customUrlValue && updatedProps.apiBodyValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST" && !updatedProps.nonAPICustomDataCacheKeyname) {
						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a POST call
					else if (!updatedProps.customUrlValue && updatedProps.apiBodyValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST" && !updatedProps.nonAPICustomDataCacheKeyname) {
						// concatenating base url and its remaining part
						let url, dynamicBaseUrlBearerToken;
						let forDownloadUrl;

						if (updatedProps.baseUrlValue === "baseUrl1") {
							url = updatedProps.options.baseUrl1 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL1}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl1BearerToken;
							dynamicBaseUrlBearerToken = baseUrl1BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl2") {
							url = updatedProps.options.baseUrl2 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL2}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl2BearerToken;
							dynamicBaseUrlBearerToken = baseUrl2BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl3") {
							url = updatedProps.options.baseUrl3 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL3}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl3BearerToken;
							dynamicBaseUrlBearerToken = baseUrl3BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl4") {
							url = updatedProps.options.baseUrl4 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL4}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl4BearerToken;
							dynamicBaseUrlBearerToken = baseUrl4BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl5") {
							url = updatedProps.options.baseUrl5 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL5}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl5BearerToken;
							dynamicBaseUrlBearerToken = baseUrl5BearerToken;
						}

						const urlDownload4 = window.top.location.href;
						const isSubstringPresent4 = (urlDownload4.indexOf("/editor/") !== -1 && urlDownload4.indexOf("?projectId=") !== -1) || urlDownload4.indexOf("/large_preview/") !== -1 || urlDownload4.indexOf("/tab_preview/") !== -1 || urlDownload4.indexOf("/mobile_preview/") !== -1 || urlDownload4.indexOf("/fragment_editor/") !== -1;
       			 		if (!isSubstringPresent4) {
       			 			// running in download
       			 			url = forDownloadUrl;
       			 		}

						bu = url;
						bubt = dynamicBaseUrlBearerToken;
						console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if custom data is provided (usually from LB) without using API but using cache, plot that (only for without paginated tables)
					else if (updatedProps.nonAPICustomDataCacheKeyname) {
						if ("caches" in window) {
							try {
								let cache = await caches.open(`${updatedProps.nonAPICustomDataCacheKeyname}`);
								let response = await cache.match(`/${updatedProps.nonAPICustomDataCacheKeyname}`);
								if (response) {
									apiData = await response.json();
									apiData = JSON.parse(apiData);
									console.log("Retrieved JSON from cache:");
								} else {
									console.log("No matching JSON data found in cache.");
								}
							} catch (error) {
								console.error("Error accessing cache:", error);
							}
						} else {
							console.error("Cache API not supported in this browser.");
						}
					}

					// if sortingOrder is provided (either ascending or descending), get the sorted apiData
					if (sortingOrder) apiData = await returnSortedApiData(page, updatedProps, sortingOrder, colName);

					console.log("apiData:", apiData, '\nplotting');

					// clearing tbody every time before plotting - for view change
					if (customTableTbody) {
						customTableTbody.innerHTML = "";
					}					

					// CHECKING & PLOTTING DATASTRUCTURES
					// if the response is: an array-of-objects
					if (isArrayofObjects(apiData)) {
						console.log("###");

						// if length of apiData is less than 5000 rows, then plot table normally in a loop, else render rows in chucks
						if (apiData.length <= 5000) {
							// loop over apiData array as a table's row
							apiData.forEach((obj, idx) => {
									let tr = document.createElement("tr");
									tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
									tr.setAttribute("class", "custom-table-tbody-row");
									// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
									tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
									tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
									tr.style.width = "100%";
									tr.style.height = "auto";
									if (idx % 2 !== 0) {
										tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
										// hover effect
										tr.addEventListener("mouseover", function () {
											tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
										});
										tr.addEventListener("mouseleave", function () {
											tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyOddTrBackgroundColor} ${updatedProps.tBodyOddTrBackgroundColor}`;
										});
									} else {
										tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
										// hover effect
										tr.addEventListener("mouseover", function () {
											tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
										});
										tr.addEventListener("mouseleave", function () {
											tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyEvenTrBackgroundColor} ${updatedProps.tBodyEvenTrBackgroundColor}`;
										});
									}

									// get data and add it as table's column
									for (let i = 0; i < updatedProps.numColsInputValue; i++) {
										let content, convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
										// using updatedProps.HeaderFields because only these are present in the downloading code
										// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
										// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
										// }
										// field name and type are for toggle replication
										let fieldName;

										try {
											if (`HeaderField_${i}` in updatedProps) {
												// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
												if (updatedProps[`HeaderField_${i}`].includes(":")) {
													// Replace spaces around colon with an empty string
													const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
													// Split the modified string by colon
													const referenceArray = modifiedStr.split(":");

													const referenceArrayObjName = `${referenceArray[0]}_data`;
													const referenceArrayKeyName = referenceArray[1];

													// console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
													// console.log(obj[referenceArrayObjName]);
													// if the reference object in not undefined, then find the further keyname
													if (obj[referenceArrayObjName]) {
														content = obj[referenceArrayObjName][referenceArrayKeyName];
													}
													// content = obj[referenceArrayObjName][referenceArrayKeyName];
												} else {
													// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
													// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
													if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
														// let convertedDate;
														dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
														targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
														switch (targetFormat) {
															case "":
																convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
																break;

															case "date_mon_year":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
																break;
															case "date_month_year":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
																break;
															case "dd/mm/yyyy":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
																break;
															case "mm/dd/yyyy":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
																break;
															case "dd-mm-yyyy":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
																break;

															case "date_mon_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
																break;
															case "date_month_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
																break;
															case "dd/mm/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
																break;
															case "mm/dd/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
																break;
															case "dd-mm-yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
																break;

															case "date_mon_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
																break;
															case "date_month_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
																break;
															case "dd/mm/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
																break;
															case "mm/dd/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
																break;
															case "dd-mm-yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
																break;

															default:
																console.error("Invalid target date format");
														}

														content = convertedDate;
														// console.log("converted content 0", dateString, content);
													} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
														numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
														// console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 0", convertedNumber, content);
													} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
														timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
														// console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 0", convertedNumber, content);
													} else {
														content = obj[`${updatedProps[`HeaderField_${i}`]}`];
													}

													// applying function from session storage on specific column (by index)
													if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
														try {
															// Retrieve the function string from sessionStorage
															const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

															if (storedFuncString) {
																// Recreate the function using the Function constructor
																const recreatedFunction = new Function(`return (${storedFuncString})`)();
																// console.log('Function recreated:', recreatedFunction);

																// Use the recreated function
																content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
																// console.log('+recreatedFunction content+', content);
															}
														} catch (error) {
															console.error(error);
														}
													}
												}
												// console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
											} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
												// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
												if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
													// Replace spaces around colon with an empty string
													const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
													// Split the modified string by colon
													const referenceArray = modifiedStr.split(":");

													const referenceArrayObjName = `${referenceArray[0]}_data`;
													const referenceArrayKeyName = referenceArray[1];

													// console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
													// console.log(obj[referenceArrayObjName]);
													// if the reference object in not undefined, then find the further keyname
													if (obj[referenceArrayObjName]) {
														content = obj[referenceArrayObjName][referenceArrayKeyName];
													}
													// content = obj[referenceArrayObjName][referenceArrayKeyName];
												} else {
													// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
													if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
														// let convertedDate;
														dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
														switch (targetFormat) {
															case "":
																convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
																break;

															case "date_mon_year":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
																break;
															case "date_month_year":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
																break;
															case "dd/mm/yyyy":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
																break;
															case "mm/dd/yyyy":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
																break;
															case "dd-mm-yyyy":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
																break;

															case "date_mon_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
																break;
															case "date_month_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
																break;
															case "dd/mm/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
																break;
															case "mm/dd/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
																break;
															case "dd-mm-yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
																break;

															case "date_mon_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
																break;
															case "date_month_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
																break;
															case "dd/mm/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
																break;
															case "mm/dd/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
																break;
															case "dd-mm-yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
																break;

															default:
																console.error("Invalid target date format");
														}

														content = convertedDate;
														// console.log("converted content 1", dateString, content);
													} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
														numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
														// console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 1", convertedNumber, content);
													} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
														timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
														// console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 1", convertedNumber, content);
													} else {
														content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													}

													// applying function from session storage on specific column (by index)
													if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
														try {
															// Retrieve the function string from sessionStorage
															const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

															if (storedFuncString) {
																// Recreate the function using the Function constructor
																const recreatedFunction = new Function(`return (${storedFuncString})`)();
																// console.log('Function recreated:', recreatedFunction);

																// Use the recreated function
																content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
																// console.log('+recreatedFunction content+', content);
															}
														} catch (error) {
															console.error(error);
														}
													}
													// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												}
												// console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
											} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
												// Get the value of the attribute from 'this'
												// console.log("customTableContainer", customTableContainer);
												const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

												// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
												if (headerFieldAttribute.includes(":")) {
													// Replace spaces around colon with an empty string
													const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
													// Split the modified string by colon
													const referenceArray = modifiedStr.split(":");

													const referenceArrayObjName = `${referenceArray[0]}_data`;
													const referenceArrayKeyName = referenceArray[1];

													// console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
													// console.log(obj[referenceArrayObjName]);
													// if the reference object is not undefined, then find the further keyname
													if (obj[referenceArrayObjName]) {
														content = obj[referenceArrayObjName][referenceArrayKeyName];
													}
												} else {
													// content = obj[headerFieldAttribute];
													// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
													if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
														// let convertedDate;
														dateString = obj[headerFieldAttribute];
														targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
														switch (targetFormat) {
															case "":
																convertedDate = obj[headerFieldAttribute];
																break;

															case "date_mon_year":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
																break;
															case "date_month_year":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
																break;
															case "dd/mm/yyyy":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
																break;
															case "mm/dd/yyyy":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
																break;
															case "dd-mm-yyyy":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
																break;

															case "date_mon_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
																break;
															case "date_month_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
																break;
															case "dd/mm/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
																break;
															case "mm/dd/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
																break;
															case "dd-mm-yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
																break;

															case "date_mon_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
																break;
															case "date_month_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
																break;
															case "dd/mm/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
																break;
															case "mm/dd/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
																break;
															case "dd-mm-yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
																break;

															default:
																console.error("Invalid target date format");
														}

														content = convertedDate;
														// console.log("converted content 2", dateString, content);
													} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
														timezoneString = obj[headerFieldAttribute];
														let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
														// console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 2", convertedNumber, content);
													} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
														numberString = obj[headerFieldAttribute];
														let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
														// console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 2", convertedNumber, content);
													} else {
														content = obj[headerFieldAttribute];
													}

													// applying function from session storage on specific column (by index)
													if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
														try {
															// Retrieve the function string from sessionStorage
															const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

															if (storedFuncString) {
																// Recreate the function using the Function constructor
																const recreatedFunction = new Function(`return (${storedFuncString})`)();
																// console.log('Function recreated:', recreatedFunction);

																// Use the recreated function
																content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
																// console.log('+recreatedFunction content+', content);
															}
														} catch (error) {
															console.error(error);
														}
													}
												}
												// console.log("headerFieldAttribute", headerFieldAttribute, content);
											}
										} catch (e) {
											// statements
											console.error(e);
										}

										// field name and type are for toggle replication
										let fieldType;
										let tableDataFields;
										if (tableData && ("fields" in tableData)) tableDataFields = tableData.fields;
										if (tableDataFields) fieldType = tableDataFields.filter((x) => x.field_name === fieldName)[0]?.field_type;
										
										// // console.log("+fieldType+", fieldType);

										// // console.log("content, i", content, i);

										let td = document.createElement("td");
										td.setAttribute("data-gjs-type", "cell");
										// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
										td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
										td.style.padding = "8px";

										td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
										td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
										td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
										td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
										td.style.borderCollapse = "collapse";
										td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
										td.style.color = `${updatedProps.tBodyTdFontColor}`;
										td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
										td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
										td.innerHTML = content !== undefined ? content : "";

										// for replicating single column component
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let headerSingleColumnComponents;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											headerSingleColumnComponents = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('div[data-gjs-type="custom-table-thead-row"][class*="gjs-row"]');
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (headerSingleColumnComponents) {
												if (headerSingleColumnComponents.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												for (let j = 0; j < headerSingleColumnComponents.length; j++) {
													// row.getElementsByTagName('td')[table_selected_column].append(headerSingleColumnComponents[j]);
													let clonedSingleColumnComponent = headerSingleColumnComponents[j].cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(headerSingleColumnComponents[j], clonedSingleColumnComponent);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedSingleColumnComponent.setAttribute("id", `single_column_component_table${id}_row${idx}_column${i}_${j}`);

													// for replicating ACTION BUTTON inside single column components
													let innerClonedActionButtons = clonedSingleColumnComponent.getElementsByClassName("custom-table-action-button");
													if (innerClonedActionButtons.length > 0) {
														Array.from(innerClonedActionButtons).forEach((innerClonedActionButton, j_btn) => {
															if (innerClonedActionButton) {
																// changing the clone's IDs so that they are present and available individually in LB and in download
																innerClonedActionButton.setAttribute("id", `custom_table_action${innerClonedActionButton.getAttribute("data-table-action")}_button_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																if (innerClonedActionButton.getElementsByTagName("p")[0]) {
																	innerClonedActionButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																}

																// adding click event on stellar-button from the table components
																innerClonedActionButton.addEventListener("click", async function (event) {
																	console.log("action-button clicked", "saving data in session storage");
																	event.preventDefault();
																	// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
																	localStorage.setItem(
																		`custom_table_action_button_table${id}_${innerClonedActionButton.getAttribute("data-table-action")}_column${i}_${j}_element${j_btn}_clicked`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			"data-table-action": `${innerClonedActionButton.getAttribute("data-table-action")}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			buttonId: `${innerClonedActionButton.getAttribute("id")}`,
																		}),
																	);

																	// Style for the modals
																	let modalStyle = {
																		padding: "2%",
																		backgroundColor: "white",
																		borderRadius: "5px",
																		boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																		maxWidth: "50vw",
																		width: "30vw",
																		maxHeight: "40vh",
																		margin: "auto",
																		overflow: "auto",
																		"-ms-overflow-style": "none",
																		"scrollbar-width": "none",
																	};

																	// Style for the input fields
																	let inputFieldStyle = {
																		display: "flex",
																		flexDirection: "column",
																		marginBottom: "15px",
																	};

																	// Style for the buttons
																	let buttonStyle = {
																		width: "100%",
																		padding: "10px",
																		backgroundColor: "#007bff",
																		color: "white",
																		border: "none",
																		borderRadius: "5px",
																		cursor: "pointer",
																		// marginRight: 'auto',
																		// marginLeft: 'auto',
																		marginBottom: "5%",
																	};

																	let submitButtonStyle = {
																		width: "50%",
																		padding: "10px",
																		backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																		color: "white",
																		border: "none",
																		borderRadius: "5px",
																		cursor: "pointer",
																		// marginRight: 'auto',
																		// marginLeft: 'auto',
																		marginBottom: "5%",
																	};

																	let closeButtonStyle = {
																		width: "50%",
																		padding: "10px",
																		backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
																		color: "white",
																		border: "none",
																		borderRadius: "5px",
																		cursor: "pointer",
																		// marginRight: 'auto',
																		// marginLeft: 'auto',
																		marginBottom: "5%",
																	};

																	// Edit modal
																	if (innerClonedActionButton.getAttribute("data-table-action") === "edit") {
																		// Create the dialog element
																		let editModal = document.createElement("dialog");
																		editModal.setAttribute("id", "custom-modal");

																		// Apply styles to the modal
																		Object.assign(editModal.style, modalStyle);

																		// Create content for the modal
																		let modalContent = document.createElement("form");

																		// Apply styles to the modal content
																		Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																		// Create a label for the data model input field
																		let dataModelLabel = document.createElement("label");
																		dataModelLabel.innerHTML = `Edit`;
																		dataModelLabel.style.width = "100%";
																		dataModelLabel.style.borderBottom = "1px solid gray";
																		dataModelLabel.style.lineHeight = "2";
																		dataModelLabel.style.fontSize = "1.4vw";

																		// Apply styles to the label
																		Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																		// Append label to the modal content
																		modalContent.appendChild(dataModelLabel);

																		// variable only for the reference keyname user input
																		let selectedOptions = [];
																		// Check if updatedProps.EditableFields is not empty
																		if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																			// Iterate over EditableFields to create form elements
																			for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																				// to handle non-Reference field keynames
																				if (!fieldName.includes("-")) {
																					let fieldLabel = document.createElement("label");
																					fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																					fieldLabel.style.textTransform = "capitalize";
																					modalContent.appendChild(fieldLabel);

																					let inputField = document.createElement("input");
																					inputField.setAttribute("name", fieldName);
																					inputField.value = obj[fieldName]; // to initialize input fields with present values
																					Object.assign(inputField.style, {
																						borderRadius: "5px",
																						border: "1px solid gray",
																						marginBottom: "5%",
																						padding: "1%",
																					});

																					// Set input field type based on field type
																					switch (fieldType.toLowerCase()) {
																						case "string":
																							inputField.setAttribute("type", "text");
																							break;
																						case "boolean":
																							inputField.setAttribute("type", "radio");
																							break;
																						case "number":
																							inputField.setAttribute("type", "number");
																							break;
																						case "date-time":
																							inputField.setAttribute("type", "datetime-local");
																							break;
																						case "time":
																							inputField.setAttribute("type", "time");
																							break;
																						case "file":
																							inputField.setAttribute("type", "file");
																							break;
																						default:
																							inputField.setAttribute("type", "text");
																							break;
																					}
																					modalContent.appendChild(inputField);
																				}

																				// to handle Reference field keynames for example - WebProject1_Address-Sector
																				else if (fieldName.includes("-")) {
																					// variables to be used later in this if-condition
																					let [table, column] = fieldName.split("-");
																					// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																					// create its label
																					let refFieldLabel = document.createElement("label");
																					refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																					refFieldLabel.style.textTransform = "capitalize";
																					modalContent.appendChild(refFieldLabel);

																					// create a drodown for these keynames > its options will be all the values from response from that table
																					let referenceKeynameDropdown = document.createElement("select");
																					referenceKeynameDropdown.setAttribute("name", fieldName);
																					referenceKeynameDropdown.setAttribute("id", fieldName);
																					// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																					Object.assign(referenceKeynameDropdown.style, {
																						borderRadius: "5px",
																						border: "1px solid gray",
																						marginBottom: "5%",
																						padding: "1%",
																					});

																					// populate it with options using the response from its data-model API
																					let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																						headers: {
																							Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																						},
																					});
																					let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																					if (referenceKeynameValueOptionsData) {
																						// setting default option --select--
																						let defaultOption = document.createElement("option");
																						// defaultOption.setAttribute('disabled', 'disabled');
																						defaultOption.innerHTML = "--select--";
																						referenceKeynameDropdown.appendChild(defaultOption);

																						// loop over the response to fill the options of its reference table
																						referenceKeynameValueOptionsData.forEach((optObj) => {
																							let option = document.createElement("option");
																							option.value = optObj[`${column}`];
																							option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																							option.innerHTML = optObj[`${column}`];
																							// to initialize input fields with present values
																							if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																								option.setAttribute("selected", true);
																							}
																							referenceKeynameDropdown.appendChild(option);
																						});
																					}

																					referenceKeynameDropdown.addEventListener("change", function (e) {
																						let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																						// console.log('Selected value:', selectedOptionFromDropdown.value);
																						// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																						// get the reference ID of the table row from main obj to update
																						let refTableId;
																						let tableName = table.split("_")[1];
																						if (obj[tableName] !== null) {
																							refTableId = obj[tableName];
																						}

																						selectedOptions.push({
																							table,
																							column,
																							refTableId,
																							value: selectedOptionFromDropdown.value,
																							"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																						});
																					});

																					modalContent.appendChild(referenceKeynameDropdown);
																				}
																			}
																		}

																		console.log("+bu, bubt+", bu, bubt);

																		let buttonContainer = document.createElement("div");
																		buttonContainer.setAttribute("class", "modal-button-container");
																		buttonContainer.style.display = "flex";
																		buttonContainer.style.alignItems = "center";
																		buttonContainer.style.justifyContent = "space-between";
																		buttonContainer.style.columnGap = "5%";
																		buttonContainer.style.width = "100%";
																		buttonContainer.style.marginTop = "5%";

																		// Create a submit button for the form
																		let submitButton = document.createElement("button");
																		submitButton.setAttribute("class", "table-submit-button");
																		submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
																		submitButton.innerHTML = "Submit";

																		// Apply styles to the button
																		Object.assign(submitButton.style, submitButtonStyle);

																		submitButton.addEventListener("click", function (e) {
																			e.preventDefault(); // Prevent default form action
																			// Handle form submission here
																			console.log("Form submitted!", obj);

																			// Create an object to store the values of input fields
																			let values = {};

																			// // Add default input field values to the values object
																			// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																			// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																			function replaceSubstringWithTableData(url) {
																				return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																			}

																			// Add default input field values to the values object
																			values["DataModel"] = updatedProps.customUrlValue 
																				? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																				: replaceSubstringWithTableData(bu);
																				
																			values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Initialize an empty FormData object to get the form's input fields values
																			let formData = new FormData(modalContent);
																			let formValues = {};
																			for (const [key, value] of formData.entries()) {
																				formValues[key] = value;
																			}

																			// Create the to_insert object with the keynames from updatedProps.EditableFields
																			// and their corresponding values from formValues
																			let to_insert = {};
																			Object.keys(updatedProps.EditableFields).forEach((key) => {
																				if (formValues[key] !== undefined && !key.includes("-")) {
																					// Check if the form has the field
																					to_insert[key] = formValues[key];
																				}

																				// re-attach the reference id to PUT call else it will set the references to null
																				if (formValues[key] !== undefined && key.includes("-")) {
																					let x = key.split("-");
																					let table = x[0].split("_")[1];
																					if (obj[table] !== null) {
																						to_insert[table] = obj[table];
																					}
																				}
																			});

																			// console.log("+to_insert+", to_insert);
																			// console.log("+selectedOptions+", selectedOptions);

																			// logic to update all table cells the edit button is editing without refreshing
																			for (const key in to_insert) {
																				let columns = [];
																				if (updatedProps[`HeaderField_${i}`]) {
																					Object.keys(updatedProps).map((hF_i) => {
																						if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																					});
																				} else {
																					Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																						if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																					});
																				}

																				// console.log('+columns+', columns);

																				columns.forEach(idx => {
																					let td = tr.getElementsByTagName('td')[idx];

																					let valueToUpdateCellWith = to_insert[key];
																					// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																					// Find the first text node
																					const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																					if (textNode) {
																						textNode.nodeValue = valueToUpdateCellWith;
																					} else {
																						// If no text node exists, create one
																						td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																					}
																				});
																			}

																			// Fetch call (to non-reference table) can be made here using the input field values
																			// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																			fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																				method: "PUT",
																				headers: {
																					"Content-Type": "application/json",
																					Authorization: `Bearer ${values.BearerToken}`,
																				},
																				body: JSON.stringify({
																					to_insert: to_insert,
																				}),
																			})
																				.then((response) => response.json())
																				.then((data) => console.log(data));

																			// Fetch call (for reference table)
																			if (selectedOptions.length > 0) {
																				selectedOptions.forEach((data) => {
																					// make api calls per object
																					let requestBody = {};
																					requestBody[data.column] = data.value; // Assign dynamic key-value pair
																					fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																						method: "PUT",
																						headers: {
																							"Content-Type": "application/json",
																							Authorization: `Bearer ${values.BearerToken}`,
																						},
																						body: JSON.stringify({
																							to_insert: requestBody,
																						}),
																					})
																						.then((response) => response.json())
																						.then((data) => console.log(data));
																				});
																			}

																			// Close the modal when the form is submitted
																			editModal.close();

																			// clear the selectedOptions for next turn
																			selectedOptions = [];

																			// // giving a delay in reload so that it doesn't change the url in the broswer tab
																			// setTimeout(function () {
																			// 	window.top.location.reload();
																			// }, 2000);
																		});

																		// Create a close button for the modal
																		let closeButton = document.createElement("button");
																		closeButton.setAttribute("class", "table-close-button");
																		closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
																		closeButton.innerHTML = "Close";

																		// Apply styles to the button
																		Object.assign(closeButton.style, closeButtonStyle);

																		closeButton.addEventListener("click", function (event) {
																			event.preventDefault(); // Prevent default behavior of the click event
																			editModal.close(); // Close the modal when the close button is clicked
																		});

																		// Append content, submit button, and close button to the modal
																		buttonContainer.appendChild(submitButton);
																		buttonContainer.appendChild(closeButton);
																		modalContent.appendChild(buttonContainer);
																		editModal.appendChild(modalContent);

																		// Append the modal to the document
																		document.body.appendChild(editModal);

																		// Show the modal
																		editModal.showModal();
																	}
																	// Delete modal
																	else if (innerClonedActionButton.getAttribute("data-table-action") === "delete") {
																		// Create the dialog element
																		let deleteModal = document.createElement("dialog");
																		deleteModal.setAttribute("id", "delete-modal");

																		// Apply styles to the modal
																		Object.assign(deleteModal.style, modalStyle);

																		// Create content for the modal
																		let modalContent = document.createElement("form");

																		// Apply styles to the modal content
																		Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																		// Create a label for the data model input field
																		let dataModelLabel = document.createElement("label");
																		dataModelLabel.innerHTML = `Delete`;
																		dataModelLabel.style.width = "100%";
																		dataModelLabel.style.borderBottom = "1px solid gray";
																		dataModelLabel.style.lineHeight = "2";
																		dataModelLabel.style.fontSize = "1.4vw";

																		// Apply styles to the label
																		Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																		// Append label to the modal content
																		modalContent.appendChild(dataModelLabel);

																		// // Create an input field for data model with a default value
																		// let dataModelInputField = document.createElement("input");
																		// dataModelInputField.setAttribute('type', 'text');
																		// dataModelInputField.setAttribute('name', 'DataModel');
																		// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
																		// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

																		// // Apply styles to the input fields
																		// Object.assign(dataModelInputField.style, inputFieldStyle);

																		// modalContent.appendChild(dataModelInputField);

																		// // Create an input field for bearer token with a default value
																		// let bearerTokenInputField = document.createElement("input");
																		// bearerTokenInputField.setAttribute('type', 'text');
																		// bearerTokenInputField.setAttribute('name', 'BearerToken');
																		// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
																		// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

																		// // Apply styles to the input fields
																		// Object.assign(bearerTokenInputField.style, inputFieldStyle);

																		// modalContent.appendChild(bearerTokenInputField);

																		// Create a paragraph tag for confirmation message
																		let confirmationMessage = document.createElement("p");
																		confirmationMessage.style.marginBottom = "5%";
																		confirmationMessage.style.fontSize = "1vw";
																		confirmationMessage.innerHTML = "Are you sure?";

																		// Append confirmation message to the modal content
																		modalContent.appendChild(confirmationMessage);

																		let buttonContainer = document.createElement("div");
																		buttonContainer.setAttribute("class", "modal-button-container");
																		buttonContainer.style.display = "flex";
																		buttonContainer.style.alignItems = "center";
																		buttonContainer.style.justifyContent = "space-between";
																		buttonContainer.style.columnGap = "5%";
																		buttonContainer.style.width = "100%";

																		// Create a submit button for the form
																		let submitButton = document.createElement("button");
																		submitButton.setAttribute("class", "table-delete-yes-button");
																		submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
																		submitButton.innerHTML = "Yes";

																		// Apply styles to the "Yes" button
																		Object.assign(submitButton.style, submitButtonStyle);

																		submitButton.addEventListener("click", async function (e) {
																			e.preventDefault();
																			// Handle form submission here
																			console.log("Form submitted!");

																			// Create an object to store the values of input fields
																			let values = {};

																			// // Add default input field values to the values object
																			// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																			// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																			function replaceSubstringWithTableData(url) {
																				return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																			}

																			// Add default input field values to the values object
																			values["DataModel"] = updatedProps.customUrlValue 
																				? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																				: replaceSubstringWithTableData(bu);
																				
																			values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Use the values in the fetch call or other logic
																			console.log("Input field values:", values);

																			// Make the fetch call to delete the item
																			let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																				method: "DELETE",
																				headers: {
																					"Content-Type": "application/json",
																					Authorization: `Bearer ${values.BearerToken}`,
																				},
																			});
																			// .then(response => {
																			//     if (response.ok) {
																			//         console.log('Item deleted successfully');
																			//     } else {
																			//         console.error('Failed to delete item');
																			//     }
																			// });

																			let responseData = response.json();
																			if (responseData.ok) {
																				console.log("Item deleted successfully");
																			} else {
																				console.error("Failed to delete item");
																			}

																			// Close the modal when the form is submitted
																			deleteModal.close();

																			// giving a delay in reload so that it doesn't change the url in the broswer tab
																			setTimeout(function () {
																				window.top.location.reload();
																			}, 2000);
																		});

																		// Create a close button for the modal
																		let closeButton = document.createElement("button");
																		closeButton.setAttribute("class", "table-delete-no-button");
																		closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
																		closeButton.innerHTML = "No";

																		// Apply styles to the button
																		Object.assign(closeButton.style, closeButtonStyle);

																		closeButton.addEventListener("click", function (e) {
																			e.preventDefault();
																			deleteModal.close(); // Close the modal when the close button is clicked
																		});

																		// Append content, submit button, and close button to the modal
																		buttonContainer.appendChild(submitButton);
																		buttonContainer.appendChild(closeButton);
																		modalContent.appendChild(buttonContainer);
																		deleteModal.appendChild(modalContent);

																		// Append the modal to the document
																		document.body.appendChild(deleteModal);

																		// Show the modal
																		deleteModal.showModal();
																	}
																});

																clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedActionButton);
															}
														});
													}

													// for replicating STELLAR BUTTON inside single column components
													let innerClonedStellarButtons = clonedSingleColumnComponent.getElementsByClassName("stellar-button-container");
													if (innerClonedStellarButtons.length > 0) {
														Array.from(innerClonedStellarButtons).forEach((innerClonedStellarButton, j_btn) => {
															if (innerClonedStellarButton) {
																// changing the clone's IDs so that they are present and available individually in LB and in download
																innerClonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																if (innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
																	innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																}

																// adding click event on stellar-button from the table components
																innerClonedStellarButton.addEventListener("click", function (event) {
																	console.log("stellar-button clicked", "saving data in session storage");
																	event.preventDefault();
																	// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

																	// this registers all stellar button clicks on LS
																	// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
																	// this registers only by the individuals in the column header
																	localStorage.setItem(
																		`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																		}),
																	);

																	sessionStorage.setItem(
																		`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																		}),
																	);
																});

																clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedStellarButton);
															}
														});
													}

													td.appendChild(clonedSingleColumnComponent);

													// to hide the header buttons after they have been cloned
													headerSingleColumnComponents[j].style.display = "none";
												}
											}
											// }
											// console.log("headerSingleColumnComponents:", headerSingleColumnComponents);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating stellar buttons
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let headerStellarButtons;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
											// console.log("headerStellarButtons1:", headerStellarButtons);
											if (headerStellarButtons) {
												if (headerStellarButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												for (let j = 0; j < headerStellarButtons.length; j++) {
													console.log("headerStellarButtons[j]", headerStellarButtons[j]);
													// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
													let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(headerStellarButtons[j], clonedStellarButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
													clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
													if (clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
														clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);
													}

													// if the header stellar button was not in single column component, only then append it to td
													if (!headerStellarButtons[j].parentNode.classList.contains("gjs-cell")) {
														td.appendChild(clonedStellarButton);
														console.log("appended");
													}

													// adding click event on stellar-button from the table components
													clonedStellarButton.addEventListener("click", function (event) {
														console.log("stellar-button clicked", "saving data in session storage");
														event.preventDefault();
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

														// this registers all stellar button clicks on LS
														// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
														// this registers only by the individuals in the column header
														localStorage.setItem(
															`clicked_stellar_button_container_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedStellarButton.getAttribute("id")}`,
															}),
														);

														sessionStorage.setItem(
															`clicked_stellar_button_container_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedStellarButton.getAttribute("id")}`,
															}),
														);

														// highlight the table row that the stellar button has selected
														if (updatedProps.tBodyTrSelectColorHighlight)
															clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";

														customTable.onclick = function(event) {														
															// Check if the click is inside the custom table but outside the clicked row
															// if (
															// 	customTable.contains(event.target) && // Click is inside the customTable
															// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
															// ) {
															// 	console.log('@deselecting row', idx);
															// 	// Determine original color based on row index (even/odd)
															// 	const originalColor = idx % 2 === 0
															// 		? updatedProps.tBodyEvenTrBackgroundColor
															// 		: updatedProps.tBodyOddTrBackgroundColor;

															// 	// Reset the row's background color
															// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
															// }

															// Iterate over all rows in the table
															const rows = customTable.querySelectorAll("tr");
															rows.forEach((row, rowIndex) => {
																// Skip the currently selected row
																if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																	return;
																}

																// Reset the background color based on even/odd index
																const originalColor = rowIndex % 2 === 0
																	? updatedProps.tBodyEvenTrBackgroundColor
																	: updatedProps.tBodyOddTrBackgroundColor;

																row.style.backgroundColor = originalColor;
															});
														};
													});

													// to hide the header buttons after they have been cloned
													headerStellarButtons[j].style.display = "none";
												}
											}
											// }
											// console.log("headerStellarButtons:", headerStellarButtons);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating action buttons
										try {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find action-button component in header column
											let headerActionButtons;
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
											headerActionButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-action-button");

											if (headerActionButtons.length > 0) {
												if (headerActionButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(headerActionButtons).forEach(async (j, j_idx) => {
													// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
													let clonedButton = j.cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													await copyStyles(j, clonedButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedButton.setAttribute("id", `custom_table_action_button_table${id}_row${idx}_column${i}_${j}`);
													if (clonedButton.getElementsByTagName("p")[0]) {
														clonedButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}`);
													}

													// if the header action button was not in single column component, only then append it to td
													console.log("j.parentNode.classList", j.parentNode.classList);
													if (!j.parentNode.classList.contains("gjs-cell")) {
														td.appendChild(clonedButton);
														console.log("appended");
													}

													// adding click event on stellar-button from the table components
													clonedButton.addEventListener("click", async function (event) {
														console.log("action-button clicked", "saving data in session storage");
														event.preventDefault();
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
														localStorage.setItem(
															`custom_table_action_button_table${id}_clicked`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																"data-table-action": `${clonedButton.getAttribute("data-table-action")}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedButton.getAttribute("id")}`,
															}),
														);

														// Style for the modals
														let modalStyle = {
															padding: "2%",
															backgroundColor: "white",
															borderRadius: "5px",
															boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
															maxWidth: "50vw",
															width: "30vw",
															maxHeight: "40vh",
															margin: "auto",
															overflow: "auto",
															"-ms-overflow-style": "none",
															"scrollbar-width": "none",
														};

														// Style for the input fields
														let inputFieldStyle = {
															display: "flex",
															flexDirection: "column",
															marginBottom: "15px",
														};

														// Style for the buttons
														let buttonStyle = {
															width: "100%",
															padding: "10px",
															backgroundColor: "#007bff",
															color: "white",
															border: "none",
															borderRadius: "5px",
															cursor: "pointer",
															// marginRight: 'auto',
															// marginLeft: 'auto',
															marginBottom: "5%",
														};

														let submitButtonStyle = {
															width: "50%",
															padding: "10px",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															color: "white",
															border: "none",
															borderRadius: "5px",
															cursor: "pointer",
															// marginRight: 'auto',
															// marginLeft: 'auto',
															marginBottom: "5%",
														};

														let closeButtonStyle = {
															width: "50%",
															padding: "10px",
															backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
															color: "white",
															border: "none",
															borderRadius: "5px",
															cursor: "pointer",
															// marginRight: 'auto',
															// marginLeft: 'auto',
															marginBottom: "5%",
														};

														// Edit modal
														if (clonedButton.getAttribute("data-table-action") === "edit") {
															// Create the dialog element
															let editModal = document.createElement("dialog");
															editModal.setAttribute("id", "custom-modal");

															// Apply styles to the modal
															Object.assign(editModal.style, modalStyle);

															// Create content for the modal
															let modalContent = document.createElement("form");

															// Apply styles to the modal content
															Object.assign(modalContent.style, {
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
															});

															// Create a label for the data model input field
															let dataModelLabel = document.createElement("label");
															dataModelLabel.innerHTML = `Edit`;
															dataModelLabel.style.width = "100%";
															dataModelLabel.style.borderBottom = "1px solid gray";
															dataModelLabel.style.lineHeight = "2";
															dataModelLabel.style.fontSize = "1.4vw";

															// Apply styles to the label
															Object.assign(dataModelLabel.style, {
																marginBottom: "10px",
															});

															// Append label to the modal content
															modalContent.appendChild(dataModelLabel);

															// variable only for the reference keyname user input
															let selectedOptions = [];
															// Check if updatedProps.EditableFields is not empty
															if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																// Iterate over EditableFields to create form elements
																for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																	// to handle non-Reference field keynames
																	if (!fieldName.includes("-")) {
																		let fieldLabel = document.createElement("label");
																		fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																		fieldLabel.style.textTransform = "capitalize";
																		modalContent.appendChild(fieldLabel);

																		let inputField = document.createElement("input");
																		inputField.setAttribute("name", fieldName);
																		inputField.value = obj[fieldName]; // to initialize input fields with present values
																		Object.assign(inputField.style, {
																			borderRadius: "5px",
																			border: "1px solid gray",
																			marginBottom: "5%",
																			padding: "1%",
																		});

																		// Set input field type based on field type
																		switch (fieldType.toLowerCase()) {
																			case "string":
																				inputField.setAttribute("type", "text");
																				break;
																			case "boolean":
																				inputField.setAttribute("type", "radio");
																				break;
																			case "number":
																				inputField.setAttribute("type", "number");
																				break;
																			case "date-time":
																				inputField.setAttribute("type", "datetime-local");
																				break;
																			case "time":
																				inputField.setAttribute("type", "time");
																				break;
																			case "file":
																				inputField.setAttribute("type", "file");
																				break;
																			default:
																				inputField.setAttribute("type", "text");
																				break;
																		}
																		modalContent.appendChild(inputField);
																	}

																	// to handle Reference field keynames for example - WebProject1_Address-Sector
																	else if (fieldName.includes("-")) {
																		// variables to be used later in this if-condition
																		let [table, column] = fieldName.split("-");
																		// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																		// create its label
																		let refFieldLabel = document.createElement("label");
																		refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																		refFieldLabel.style.textTransform = "capitalize";
																		modalContent.appendChild(refFieldLabel);

																		// create a drodown for these keynames > its options will be all the values from response from that table
																		let referenceKeynameDropdown = document.createElement("select");
																		referenceKeynameDropdown.setAttribute("name", fieldName);
																		referenceKeynameDropdown.setAttribute("id", fieldName);
																		// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																		Object.assign(referenceKeynameDropdown.style, {
																			borderRadius: "5px",
																			border: "1px solid gray",
																			marginBottom: "5%",
																			padding: "1%",
																		});

																		// populate it with options using the response from its data-model API
																		let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																			headers: {
																				Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																			},
																		});
																		let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																		if (referenceKeynameValueOptionsData) {
																			// setting default option --select--
																			let defaultOption = document.createElement("option");
																			// defaultOption.setAttribute('disabled', 'disabled');
																			defaultOption.innerHTML = "--select--";
																			referenceKeynameDropdown.appendChild(defaultOption);

																			// loop over the response to fill the options of its reference table
																			referenceKeynameValueOptionsData.forEach((optObj) => {
																				let option = document.createElement("option");
																				option.value = optObj[`${column}`];
																				option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																				option.innerHTML = optObj[`${column}`];
																				// to initialize input fields with present values
																				if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																					option.setAttribute("selected", true);
																				}
																				referenceKeynameDropdown.appendChild(option);
																			});
																		}

																		referenceKeynameDropdown.addEventListener("change", function (e) {
																			let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																			// console.log('Selected value:', selectedOptionFromDropdown.value);
																			// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																			// get the reference ID of the table row from main obj to update
																			let refTableId;
																			let tableName = table.split("_")[1];
																			if (obj[tableName] !== null) {
																				refTableId = obj[tableName];
																			}

																			selectedOptions.push({
																				table,
																				column,
																				refTableId,
																				value: selectedOptionFromDropdown.value,
																				"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																			});
																		});

																		modalContent.appendChild(referenceKeynameDropdown);
																	}
																}
															}

															console.log("+bu, bubt+", bu, bubt);

															let buttonContainer = document.createElement("div");
															buttonContainer.setAttribute("class", "modal-button-container");
															buttonContainer.style.display = "flex";
															buttonContainer.style.alignItems = "center";
															buttonContainer.style.justifyContent = "space-between";
															buttonContainer.style.columnGap = "5%";
															buttonContainer.style.width = "100%";
															buttonContainer.style.marginTop = "5%";

															// Create a submit button for the form
															let submitButton = document.createElement("button");
															submitButton.setAttribute("class", "table-submit-button");
															submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
															submitButton.innerHTML = "Submit";

															// Apply styles to the button
															Object.assign(submitButton.style, submitButtonStyle);

															submitButton.addEventListener("click", function (e) {
																e.preventDefault(); // Prevent default form action
																// Handle form submission here
																console.log("Form submitted!", obj);

																// Create an object to store the values of input fields
																let values = {};

																// // Add default input field values to the values object
																// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																function replaceSubstringWithTableData(url) {
																	return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																}

																// Add default input field values to the values object
																values["DataModel"] = updatedProps.customUrlValue 
																	? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	: replaceSubstringWithTableData(bu);
																	
																values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Initialize an empty FormData object to get the form's input fields values
																let formData = new FormData(modalContent);
																let formValues = {};
																for (const [key, value] of formData.entries()) {
																	formValues[key] = value;
																}

																// Create the to_insert object with the keynames from updatedProps.EditableFields
																// and their corresponding values from formValues
																let to_insert = {};
																Object.keys(updatedProps.EditableFields).forEach((key) => {
																	if (formValues[key] !== undefined && !key.includes("-")) {
																		// Check if the form has the field
																		to_insert[key] = formValues[key];
																	}

																	// re-attach the reference id to PUT call else it will set the references to null
																	if (formValues[key] !== undefined && key.includes("-")) {
																		let x = key.split("-");
																		let table = x[0].split("_")[1];
																		if (obj[table] !== null) {
																			to_insert[table] = obj[table];
																		}
																	}
																});

																// console.log("+to_insert+", to_insert);
																// console.log("+selectedOptions+", selectedOptions);

																// logic to update all table cells the edit button is editing without refreshing
																for (const key in to_insert) {
																	let columns = [];
																	if (updatedProps[`HeaderField_${i}`]) {
																		Object.keys(updatedProps).map((hF_i) => {
																			if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																		});
																	} else {
																		Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																			if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																		});
																	}

																	// console.log('+columns+', columns);

																	columns.forEach(idx => {
																		let td = tr.getElementsByTagName('td')[idx];

																		let valueToUpdateCellWith = to_insert[key];
																		// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																		// Find the first text node
																		const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																		if (textNode) {
																			textNode.nodeValue = valueToUpdateCellWith;
																		} else {
																			// If no text node exists, create one
																			td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																		}
																	});
																}

																// Fetch call (to non-reference table) can be made here using the input field values
																// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																	method: "PUT",
																	headers: {
																		"Content-Type": "application/json",
																		Authorization: `Bearer ${values.BearerToken}`,
																	},
																	body: JSON.stringify({
																		to_insert: to_insert,
																	}),
																})
																	.then((response) => response.json())
																	.then((data) => console.log(data));

																// Fetch call (for reference table)
																if (selectedOptions.length > 0) {
																	selectedOptions.forEach((data) => {
																		// make api calls per object
																		let requestBody = {};
																		requestBody[data.column] = data.value; // Assign dynamic key-value pair
																		fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																			method: "PUT",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																			body: JSON.stringify({
																				to_insert: requestBody,
																			}),
																		})
																			.then((response) => response.json())
																			.then((data) => console.log(data));
																	});
																}

																// Close the modal when the form is submitted
																editModal.close();

																// clear the selectedOptions for next turn
																selectedOptions = [];

																// // giving a delay in reload so that it doesn't change the url in the broswer tab
																// setTimeout(function () {
																// 	window.top.location.reload();
																// }, 2000);
															});

															// Create a close button for the modal
															let closeButton = document.createElement("button");
															closeButton.setAttribute("class", "table-close-button");
															closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
															closeButton.innerHTML = "Close";

															// Apply styles to the button
															Object.assign(closeButton.style, closeButtonStyle);

															closeButton.addEventListener("click", function (event) {
																event.preventDefault(); // Prevent default behavior of the click event
																editModal.close(); // Close the modal when the close button is clicked
															});

															// Append content, submit button, and close button to the modal
															buttonContainer.appendChild(submitButton);
															buttonContainer.appendChild(closeButton);
															modalContent.appendChild(buttonContainer);
															editModal.appendChild(modalContent);

															// Append the modal to the document
															document.body.appendChild(editModal);

															// Show the modal
															editModal.showModal();
														}
														// Delete modal
														else if (clonedButton.getAttribute("data-table-action") === "delete") {
															// Create the dialog element
															let deleteModal = document.createElement("dialog");
															deleteModal.setAttribute("id", "delete-modal");

															// Apply styles to the modal
															Object.assign(deleteModal.style, modalStyle);

															// Create content for the modal
															let modalContent = document.createElement("form");

															// Apply styles to the modal content
															Object.assign(modalContent.style, {
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
															});

															// Create a label for the data model input field
															let dataModelLabel = document.createElement("label");
															dataModelLabel.innerHTML = `Delete`;
															dataModelLabel.style.width = "100%";
															dataModelLabel.style.borderBottom = "1px solid gray";
															dataModelLabel.style.lineHeight = "2";
															dataModelLabel.style.fontSize = "1.4vw";

															// Apply styles to the label
															Object.assign(dataModelLabel.style, {
																marginBottom: "10px",
															});

															// Append label to the modal content
															modalContent.appendChild(dataModelLabel);

															// // Create an input field for data model with a default value
															// let dataModelInputField = document.createElement("input");
															// dataModelInputField.setAttribute('type', 'text');
															// dataModelInputField.setAttribute('name', 'DataModel');
															// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
															// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

															// // Apply styles to the input fields
															// Object.assign(dataModelInputField.style, inputFieldStyle);

															// modalContent.appendChild(dataModelInputField);

															// // Create an input field for bearer token with a default value
															// let bearerTokenInputField = document.createElement("input");
															// bearerTokenInputField.setAttribute('type', 'text');
															// bearerTokenInputField.setAttribute('name', 'BearerToken');
															// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
															// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

															// // Apply styles to the input fields
															// Object.assign(bearerTokenInputField.style, inputFieldStyle);

															// modalContent.appendChild(bearerTokenInputField);

															// Create a paragraph tag for confirmation message
															let confirmationMessage = document.createElement("p");
															confirmationMessage.style.marginBottom = "5%";
															confirmationMessage.style.fontSize = "1vw";
															confirmationMessage.innerHTML = "Are you sure?";

															// Append confirmation message to the modal content
															modalContent.appendChild(confirmationMessage);

															let buttonContainer = document.createElement("div");
															buttonContainer.setAttribute("class", "modal-button-container");
															buttonContainer.style.display = "flex";
															buttonContainer.style.alignItems = "center";
															buttonContainer.style.justifyContent = "space-between";
															buttonContainer.style.columnGap = "5%";
															buttonContainer.style.width = "100%";

															// Create a submit button for the form
															let submitButton = document.createElement("button");
															submitButton.setAttribute("class", "table-delete-yes-button");
															submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
															submitButton.innerHTML = "Yes";

															// Apply styles to the "Yes" button
															Object.assign(submitButton.style, submitButtonStyle);

															submitButton.addEventListener("click", async function (e) {
																e.preventDefault();
																// Handle form submission here
																console.log("Form submitted!");

																// Create an object to store the values of input fields
																let values = {};

																// // Add default input field values to the values object
																// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																function replaceSubstringWithTableData(url) {
																	return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																}

																// Add default input field values to the values object
																values["DataModel"] = updatedProps.customUrlValue 
																	? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	: replaceSubstringWithTableData(bu);
																	
																values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Use the values in the fetch call or other logic
																console.log("Input field values:", values);

																// Make the fetch call to delete the item
																let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																	method: "DELETE",
																	headers: {
																		"Content-Type": "application/json",
																		Authorization: `Bearer ${values.BearerToken}`,
																	},
																});
																// .then(response => {
																//     if (response.ok) {
																//         console.log('Item deleted successfully');
																//     } else {
																//         console.error('Failed to delete item');
																//     }
																// });

																let responseData = response.json();
																if (responseData.ok) {
																	console.log("Item deleted successfully");
																} else {
																	console.error("Failed to delete item");
																}

																// Close the modal when the form is submitted
																deleteModal.close();

																// giving a delay in reload so that it doesn't change the url in the broswer tab
																setTimeout(function () {
																	window.top.location.reload();
																}, 2000);
															});

															// Create a close button for the modal
															let closeButton = document.createElement("button");
															closeButton.setAttribute("class", "table-delete-no-button");
															closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
															closeButton.innerHTML = "No";

															// Apply styles to the button
															Object.assign(closeButton.style, closeButtonStyle);

															closeButton.addEventListener("click", function (e) {
																e.preventDefault();
																deleteModal.close(); // Close the modal when the close button is clicked
															});

															// Append content, submit button, and close button to the modal
															buttonContainer.appendChild(submitButton);
															buttonContainer.appendChild(closeButton);
															modalContent.appendChild(buttonContainer);
															deleteModal.appendChild(modalContent);

															// Append the modal to the document
															document.body.appendChild(deleteModal);

															// Show the modal
															deleteModal.showModal();
														}
													});

													// to hide the header buttons after they have been cloned
													j.style.display = "none";
												});
											}
											// console.log("headerActionButtons:", headerActionButtons);
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating input_file buttons
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let headerInputFileButtons;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											headerInputFileButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_file_container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (headerInputFileButtons) {
												if (headerInputFileButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												// for (let j = 0; j < headerInputFileButtons.length; j++) {
												// 	// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
												// 	let clonedInputFileButton = headerInputFileButtons[j].cloneNode(true);

												// 	// Copy the styles from the original button to the cloned button and its inner elements
												// 	copyStyles(headerInputFileButtons[j], clonedInputFileButton);

												// 	// changing the clone's IDs so that they are present and available individually in LB and in download
												// 	clonedInputFileButton.setAttribute('id', `input_file_container_table${id}_row${idx}_column${i}_${j}`);
												// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].setAttribute('id', `input_file_label_table${id}_row${idx}_column${i}_${j}`);
												// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].getElementsByTagName('p')[0].setAttribute('id', `input_file_label_p_table${id}_row${idx}_column${i}_${j}`);

												// 	td.appendChild(clonedInputFileButton);

												// 	let headerInputFileClickHandler = function(event) {
												// 		console.log('input_file_label clicked', 'saving data in session storage');
												// 		// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

												// 		// this registers all stellar button clicks on LS
												// 		// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
												// 		// this registers only by the individuals in the column header
												// 		localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, JSON.stringify({
												// 			'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
												// 			tableId: `${id}`,
												// 			rowId: `custom_table_tbody_row${idx}_table${id}`,
												// 			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												// 			buttonId: `${clonedInputFileButton.getAttribute('id')}`,
												// 		}));

												// 		console.log('clonedInputFileButton++', clonedInputFileButton);

												// 		let inputFile = document.createElement('input');
												//         inputFile.type = 'file';
												//         inputFile.style.display = 'none';

												//         inputFile.addEventListener('change', async function (event) {
												//               let file = inputFile.files[0];
												//               console.log('this++', this);
												//               console.log('file is:', file);

												//               let formData = new FormData();
												//               formData.append('file', file);

												//               try {
												//                   const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
												//                       method: 'POST',
												//                       headers: {
												//                           // 'Content-Type': 'multipart/form-data',
												//                       },
												//                       body: formData,
												//                   });

												//                   if (response.ok) {
												//                       const data = await response.json();
												//                       console.log('File uploaded:', data);
												//                       clonedInputFileButton.setAttribute('data_file_url', data["fileLocation"]);

												//                     // Retrieve the object from local storage
												// 			        let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j}`)) || {};

												// 			        // Add the data_file_url and its value to the object
												// 			        clickedFileData.data_file_url = data["fileLocation"];

												// 			        // Convert the modified object back to a JSON string
												// 			        let updatedClickedFileData = JSON.stringify(clickedFileData);

												// 			        // Update the local storage with the modified JSON string
												// 			        localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, updatedClickedFileData);
												//                   } else {
												//                       console.error('Error uploading file:', response.statusText);
												//                   }

												//                   clonedInputFileButton.onclick = null;
												//               } catch (error) {
												//                   console.error('Error uploading file:', error);
												//               } finally {
												//               	console.log('after 1')
												//                   // Remove the inputFile element after API call completes
												//                   if (inputFile && inputFile.parentNode) {
												//                   	console.log('after 2')
												//                       inputFile.parentNode.removeChild(inputFile);
												//                   }
												//               }
												//         });

												//         clonedInputFileButton.appendChild(inputFile);
												//         inputFile.click();
												//         console.log('clicked', clonedInputFileButton);
												// 	};

												// 	// adding click event on stellar-button from the table components
												// 	// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
												// 	clonedInputFileButton.onclick = headerInputFileClickHandler;

												// 	// to hide the header buttons after they have been cloned
												// 	headerInputFileButtons[j].style.display = "none";
												// }

												Array.from(headerInputFileButtons).forEach(async (j, j_idx) => {
													// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
													let clonedInputFileButton = j.cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(j, clonedInputFileButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedInputFileButton.setAttribute("id", `input_file_container_table${id}_row${idx}_column${i}_${j_idx}`);
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].setAttribute("id", `input_file_label_table${id}_row${idx}_column${i}_${j_idx}`);
													if (clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0]) {
														clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0].setAttribute("id", `input_file_label_p_table${id}_row${idx}_column${i}_${j_idx}`);
													}

													td.appendChild(clonedInputFileButton);

													let headerInputFileClickHandler = function (event) {
														console.log("input_file_label clicked", "saving data in session storage");
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

														// this registers all stellar button clicks on LS
														// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
														// this registers only by the individuals in the column header
														localStorage.setItem(
															`clicked_input_file_container_table${id}_column${i}_${j_idx}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedInputFileButton.getAttribute("id")}`,
															}),
														);

														// console.log('clonedInputFileButton++', clonedInputFileButton);

														let inputFile = document.createElement("input");
														inputFile.type = "file";
														inputFile.style.display = "none";

														inputFile.addEventListener("change", async function (event) {
															let file = inputFile.files[0];
															console.log("this++", this);
															console.log("file is:", file);

															let formData = new FormData();
															formData.append("file", file);

															try {
																const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
																	method: "POST",
																	headers: {
																		// 'Content-Type': 'multipart/form-data',
																	},
																	body: formData,
																});

																if (response.ok) {
																	const data = await response.json();
																	console.log("File uploaded:", data);
																	td.setAttribute("data_file_url", data["fileLocation"]);

																	// Retrieve the object from local storage
																	let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`)) || {};

																	// Add the data_file_url and its value to the object
																	clickedFileData.data_file_url = data["fileLocation"];

																	// Convert the modified object back to a JSON string
																	let updatedClickedFileData = JSON.stringify(clickedFileData);

																	// Update the local storage with the modified JSON string
																	localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`, updatedClickedFileData);
																} else {
																	console.error("Error uploading file:", response.statusText);
																}
															} catch (error) {
																console.error("Error uploading file:", error);
															} finally {
																// Remove the inputFile element after API call completes
																if (inputFile && inputFile.parentNode) {
																	inputFile.parentNode.removeChild(inputFile);
																}
															}
														});

														clonedInputFileButton.appendChild(inputFile);
														inputFile.click();

														if (inputFile && inputFile.parentNode) {
															inputFile.parentNode.removeChild(inputFile);
														}
													};

													// adding click event on stellar-button from the table components
													// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].onclick = headerInputFileClickHandler;

													// to hide the header buttons after they have been cloned
													j.style.display = "none";
												});
											}
											// }
											// console.log("headerInputFileButtons:", headerInputFileButtons);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating radio_regular_form
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let radioButtonGroups;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											radioButtonGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("radio_regular_form");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (radioButtonGroups) {
												if (radioButtonGroups.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(radioButtonGroups).forEach((radioButtonGroup, j) => {
													let clonedRadioButtonGroup = radioButtonGroup.cloneNode(true);
													// Copy styles from the original to the cloned button
													copyStyles2(radioButtonGroup, clonedRadioButtonGroup);

													// Change IDs and append cloned radio button groups to the container
													clonedRadioButtonGroup.setAttribute("id", `radio_regular_form_table${id}_row${idx}_column${i}_${j}`);

													// Add event listener to each radio button in the group
													clonedRadioButtonGroup.querySelectorAll('input[type="radio"]').forEach((radioButton) => {
														radioButton.addEventListener("click", (event) => {
															// Get the selected radio button's value and id
															const selectedValue = event.target.value;
															const selectedId = event.target.id;
															const selectedDataDbId = event.target.getAttribute("data_db_id") ? event.target.getAttribute("data_db_id") : "";

															// Store the selected value, id, and other relevant data in local storage
															localStorage.setItem(
																`clicked_radio_regular_form_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	radioButtonId: `${selectedId}`,
																	selectedValue: `${selectedValue}`,
																	selectedDataDbId: `${selectedDataDbId}`,
																}),
															);

															// You can also perform other actions based on the selected radio button
															// For example, update UI, make API calls, etc.
														});
													});

													// Append the container to the table cell
													td.appendChild(clonedRadioButtonGroup);
												});
											}

											// Hide the original radio button groups in the header column
											Array.from(radioButtonGroups).forEach((radioButtonGroup) => {
												radioButtonGroup.style.display = "none";
											});
											// }
											// console.log("radioButtonGroups:", radioButtonGroups);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating checkbox_regular_form
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let checkboxGroups;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											checkboxGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (checkboxGroups) {
												if (checkboxGroups.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(checkboxGroups).forEach((checkboxGroup, j) => {
													let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
													// Copy styles from the original to the cloned checkbox group
													copyStyles2(checkboxGroup, clonedCheckboxGroup);

													// Change IDs and append cloned checkbox groups to the container
													clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_table${id}_row${idx}_column${i}_${j}`);

													// Add event listener to each checkbox in the group
													clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
														checkbox.addEventListener("change", (event) => {
															// Initialize an array to hold the values of checked checkboxes
															let selectedValues = [];
															let selectedIds = [];
															let selectedDataDbIds = [];

															// Iterate over all checkboxes to find the checked ones
															clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
																// If a checkbox is checked, add its value and id to the respective arrays
																if (checkbox.checked) {
																	selectedValues.push(checkbox.value);
																	selectedIds.push(checkbox.id);
																	selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
																}
															});

															// Store the array of selected values and ids in local storage
															localStorage.setItem(
																`clicked_checkbox_regular_form_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	selectedValues: selectedValues,
																	selectedIds: selectedIds,
																	selectedDataDbIds: selectedDataDbIds,
																}),
															);

															// You can also perform other actions based on the selected checkboxes
															// For example, update UI, make API calls, etc.
														});
													});

													// Append the container to the table cell
													td.appendChild(clonedCheckboxGroup);
												});

												// Hide the original radio button groups in the header column
												Array.from(checkboxGroups).forEach((checkboxGroup) => {
													checkboxGroup.style.display = "none";
												});
											}
											// }
											// console.log("checkboxGroups:", checkboxGroups);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating checkbox_regular_form_new
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let checkboxGroupsV2;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											checkboxGroupsV2 = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form_new");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (checkboxGroupsV2) {
												if (checkboxGroupsV2.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(checkboxGroupsV2).forEach((checkboxGroup, j) => {
													let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
													// Copy styles from the original to the cloned checkbox group
													copyStyles2(checkboxGroup, clonedCheckboxGroup);

													// Change IDs and append cloned checkbox groups to the container
													clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_v2_table${id}_row${idx}_column${i}_${j}`);

													// Add event listener to each checkbox in the group
													clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
														checkbox.addEventListener("change", (event) => {
															// Initialize an array to hold the values of checked checkboxes
															let selectedValues = [];
															let selectedIds = [];
															let selectedDataDbIds = [];

															// Iterate over all checkboxes to find the checked ones
															clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
																// If a checkbox is checked, add its value and id to the respective arrays
																if (checkbox.checked) {
																	selectedValues.push(checkbox.value);
																	selectedIds.push(checkbox.id);
																	selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
																}
															});

															// Store the array of selected values and ids in local storage
															localStorage.setItem(
																`clicked_checkbox_regular_form_v2_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	selectedValues: selectedValues,
																	selectedIds: selectedIds,
																	selectedDataDbIds: selectedDataDbIds,
																}),
															);

															// You can also perform other actions based on the selected checkboxes
															// For example, update UI, make API calls, etc.
														});
													});

													// Append the container to the table cell
													td.appendChild(clonedCheckboxGroup);
												});

												// Hide the original radio button groups in the header column
												Array.from(checkboxGroupsV2).forEach((checkboxGroup) => {
													checkboxGroup.style.display = "none";
												});
											}
											// }
											// console.log("checkboxGroupsV2:", checkboxGroupsV2);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating input_select
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let dropdownContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											dropdownContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_select_container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (dropdownContainers) {
												if (dropdownContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(dropdownContainers).forEach((dropdownContainer, j) => {
													let dropdown = dropdownContainer.getElementsByTagName("select")[0];
													let dropdownClone = dropdown.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(dropdown, dropdownClone);

													// Change IDs and append cloned dropdowns to the container
													dropdownClone.setAttribute("id", `input_select_table${id}_row${idx}_column${i}_${j}`);
													dropdownClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const selectedValue = event.target.value;
														const selectedOption = event.target.options[event.target.selectedIndex];
														const selectedId = selectedOption.id;

														// Get the data-db-id attribute value of the selected option
														const selectedDataDbId = selectedOption.getAttribute("data_db_id") ? selectedOption.getAttribute("data_db_id") : "";

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_input_select_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValue: `${selectedValue}`,
																selectedId: `${selectedId}`,
																selectedDataDbId: `${selectedDataDbId}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(dropdownClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(dropdownContainers).forEach((dropdownContainer) => {
													dropdownContainer.style.display = "none";
												});
											}
											// }
											// console.log("dropdownContainers:", dropdownContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_text_field
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputTextContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (inputTextContainers) {
												if (inputTextContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
													let inputText = inputTextContainer.getElementsByTagName("input")[0];
													let inputTextClone = inputText.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputText, inputTextClone);

													// Change IDs and append cloned dropdowns to the container
													inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
													inputTextClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_form_text_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(inputTextClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputTextContainers).forEach((inputTextContainer) => {
													inputTextContainer.style.display = "none";
												});
											}
											// }
											// console.log("inputTextContainers:", inputTextContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_number_field
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputNumberContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (inputNumberContainers) {
												if (inputNumberContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
													let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
													let inputNumberClone = inputNumber.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputNumber, inputNumberClone);

													// Change IDs and append cloned dropdowns to the container
													inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
													inputNumberClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_form_number_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(inputNumberClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
													inputNumberContainer.style.display = "none";
												});
											}
											// }
											// console.log("inputNumberContainers:", inputNumberContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating send email buttons
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let sendEmailButtons;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											sendEmailButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("send-email-button-container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (sendEmailButtons) {
												if (sendEmailButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(sendEmailButtons).forEach((j, j_idx) => {
													// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
													let clonedEmailButton = j.cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(j, clonedEmailButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedEmailButton.setAttribute("id", `send_email_button_container_table${id}_row${idx}_column${i}_${j_idx}`);
													clonedEmailButton.getElementsByClassName("send-email-button")[0].setAttribute("id", `send_email_btn_table${id}_row${idx}_column${i}_${j_idx}`);
													if (clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0]) {
														clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0].setAttribute("id", `send_email_btn_p_table${id}_row${idx}_column${i}_${j_idx}`);
													}

													td.appendChild(clonedEmailButton);

													const sendEmailHandler = async function (sendEmailButtonContainer) {
														let selectedFile;
														// reason - because this script was getting called multiple times, the dialog element was getting added multiple times so this code will be removing all the previously appended ones and then create a new one on-the-go
														if (sendEmailButtonContainer) {
															let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
															modalAlreadyExists.forEach((modal) => {
																console.log("removing modal 2", modal);
																modal.remove();
															});

															// // fetch filter call for content
															// let filteredResponse = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${extractTableName(updatedProps.customUrlValue)}`, {
															// 	method: "POST",
															// 	headers: {
															// 		'Content-Type': "application/json",
															// 		Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
															// 	},
															// 	body: JSON.stringify({
															// 	    filterData: {
															// 	        _id: `${obj[updatedProps.DataModelObjIdKeyname]}`,
															// 	    }
															// 	}),
															// });

															// let filteredResponseData = await filteredResponse.json();

															// Get the keys from updatedProps.HeaderFields object and from HTML attributes
															const headerFieldKeys = Object.keys(updatedProps.HeaderFields);
															const htmlAttributeKeys = Array.from(customTableContainer.attributes)
																.filter((attr) => attr.name.includes("headerfield_"))
																.map((attr) => attr.value);

															console.log("htmlAttributeKeys", htmlAttributeKeys);

															// Combine the keys from both sources
															const allKeys = [...headerFieldKeys, ...htmlAttributeKeys];

															// Accumulate all key-value pairs
															const keyValuePairs = [];

															// Get the values corresponding to the keys from the obj object
															htmlAttributeKeys.forEach((key) => {
																let value;
																if (/:/.test(key)) {
																	// If the key contains a colon (indicating nested properties), split it
																	const [mainKey, subKey] = key.split(/\s*:\s*/);
																	// Check if the main key exists in obj
																	if (obj[`${mainKey}_data`]) {
																		// Check if the main key points to an object
																		if (typeof obj[`${mainKey}_data`] === "object" && obj[`${mainKey}_data`][subKey]) {
																			value = obj[`${mainKey}_data`][subKey];
																			keyValuePairs.push(`${subKey}: ${value}`);
																		}
																	}
																} else {
																	value = obj[key];
																	keyValuePairs.push(`${key}: ${value}`);
																}
																// if (value) {
																//     keyValuePairs.push(`${key}: ${value}`);
																// }
															});

															console.log("allKeys, keyValuePairs", allKeys, keyValuePairs);

															// Join all key-value pairs into a single string
															const filteredKeyValuePairs = keyValuePairs.join("\n");

															console.log("allKeys, filteredKeyValuePairs", allKeys, filteredKeyValuePairs);

															// Create a dialog modal with styling
															const dynamicEmailSendingModal = document.createElement("dialog");
															dynamicEmailSendingModal.setAttribute("class", `custom-email-modal`);
															dynamicEmailSendingModal.setAttribute("id", `custom-email-modal-${sendEmailButtonContainer.id}`);
															Object.assign(dynamicEmailSendingModal.style, {
																margin: "auto",
																width: "35vw",
																overflow: "auto",
																backgroundColor: "white",
																boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																border: "1px solid #ccc",
																borderRadius: "20px",
																zIndex: "1000",
																padding: "0 0 1% 0",
															});

															// modal UI header & footer
															let modalHeader = document.createElement("div");
															Object.assign(modalHeader.style, {
																// backgroundColor: "#1991E7",
																backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																paddingTop: "0.8%",
																paddingBottom: "0.8%",
																paddingLeft: "5%",
																paddingRight: "5%",
																color: "white",
																fontSize: "1vw",
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
															});
															modalHeader.innerHTML = "Compose Message";

															const modalFooter = document.createElement("div");
															Object.assign(modalFooter.style, {
																backgroundColor: "transparent",
																paddingTop: "0.8%",
																paddingBottom: "0.8%",
																paddingLeft: "5%",
																paddingRight: "5%",
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
															});

															// Create a form element
															const emailForm = document.createElement("form");
															Object.assign(emailForm.style, {
																padding: "3% 6%",
																display: "flex",
																flexDirection: "column",
															});

															// Check if EmailFromDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailFromDynamicCheckbox) {
															const emailFromDiv = document.createElement("div");
															Object.assign(emailFromDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailFromLabel = document.createElement("label");
															emailFromLabel.innerHTML = "From";
															// Create an input email field for the sender's email address with styling
															const emailFromField = document.createElement("input");
															Object.assign(emailFromField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailFromField, {
																type: "email",
																id: `emailFrom-${sendEmailButtonContainer.id}`,
																name: "emailFrom",
																placeholder: "Sender's Email Address",
															});
															// visiblity will be hidden because this field is not actually required and it needs to occupy same space for UI alignment purposes
															const emailFromRequiredFieldLabel = document.createElement("span");
															emailFromRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailFromRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
																visibility: "hidden",
															});

															// Append the email input field to the form
															emailFromDiv.appendChild(emailFromLabel);
															emailFromDiv.appendChild(emailFromField);
															emailFromDiv.appendChild(emailFromRequiredFieldLabel);
															emailForm.appendChild(emailFromDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if EmailToDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailToDynamicCheckbox) {
															const emailToDiv = document.createElement("div");
															Object.assign(emailToDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailToLabel = document.createElement("label");
															emailToLabel.innerHTML = "To";
															// Create an input email field for the receiver's email address with styling
															const emailToField = document.createElement("input");
															Object.assign(emailToField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailToField, {
																type: "text",
																id: `emailTo-${sendEmailButtonContainer.id}`,
																name: "emailTo",
																placeholder: "Receiver's Email Addresses (comma separated)",
															});
															const emailToRequiredFieldLabel = document.createElement("span");
															emailToRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailToRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
															});

															// Append the email input field to the form
															emailToDiv.appendChild(emailToLabel);
															emailToDiv.appendChild(emailToField);
															emailToDiv.appendChild(emailToRequiredFieldLabel);
															emailForm.appendChild(emailToDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if EmailSubjectDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailSubjectDynamicCheckbox) {
															const emailSubjectDiv = document.createElement("div");
															Object.assign(emailSubjectDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailSubjectLabel = document.createElement("label");
															emailSubjectLabel.innerHTML = "Subject";
															// Create an input email field for the receiver's email address with styling
															const emailSubjectField = document.createElement("input");
															Object.assign(emailSubjectField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailSubjectField, {
																type: "text",
																id: `emailSubject-${sendEmailButtonContainer.id}`,
																name: "emailSubject",
																placeholder: "Email's subject",
															});
															const emailSubjectRequiredFieldLabel = document.createElement("span");
															emailSubjectRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailSubjectRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
															});

															// Append the email input field to the form
															emailSubjectDiv.appendChild(emailSubjectLabel);
															emailSubjectDiv.appendChild(emailSubjectField);
															emailSubjectDiv.appendChild(emailSubjectRequiredFieldLabel);
															emailForm.appendChild(emailSubjectDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if EmailBodyDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailBodyDynamicCheckbox) {
															const emailBodyDiv = document.createElement("div");
															Object.assign(emailBodyDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailBodyLabel = document.createElement("label");
															emailBodyLabel.innerHTML = "Body";
															// Create an input email field for the receiver's email address with styling
															const emailBodyField = document.createElement("textarea");
															Object.assign(emailBodyField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailBodyField, {
																rows: "5",
																cols: "10",
																id: `emailBody-${sendEmailButtonContainer.id}`,
																name: "emailBody",
																placeholder: "Email's body",
															});
															const emailBodyRequiredFieldLabel = document.createElement("span");
															emailBodyRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailBodyRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
															});

															emailBodyField.value = filteredKeyValuePairs;

															// Append the email input field to the form
															emailBodyDiv.appendChild(emailBodyLabel);
															emailBodyDiv.appendChild(emailBodyField);
															emailBodyDiv.appendChild(emailBodyRequiredFieldLabel);
															emailForm.appendChild(emailBodyDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if FileAttachmentDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.FileAttachmentDynamicCheckbox) {
															// Create an input email field for the receiver's email address with styling
															const fileAttachmentField = document.createElement("input");
															Object.assign(fileAttachmentField.style, {
																// marginBottom: "10px",
																// padding: "5px",
																// width: "80%",
																// borderRadius: "5px",
																// backgroundColor: "transparent",
																// boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
																display: "none",
															});
															Object.assign(fileAttachmentField, {
																type: "file",
																id: `fileAttachment-${sendEmailButtonContainer.id}`,
																name: "fileAttachment",
															});

															// Add event listener for the file input field change event
															fileAttachmentField.addEventListener("change", (event) => {
																selectedFile = event.target.files[0];
																console.log("Selected file:", selectedFile);
																// You can do further processing with the selected file here
															});

															// Create a label for the file input button
															const fileAttachmentLabel = document.createElement("label");
															fileAttachmentLabel.setAttribute("for", "fileAttachmentInput"); // Set the 'for' attribute to match the input field's id
															fileAttachmentLabel.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16"><path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/></svg>';
															Object.assign(fileAttachmentLabel.style, {
																cursor: "pointer",
															});

															// Attach click event to the label
															fileAttachmentLabel.addEventListener("click", () => {
																fileAttachmentField.click();
															});

															// Append the email input field to the form
															modalFooter.appendChild(fileAttachmentLabel);
															modalFooter.appendChild(fileAttachmentField);
															// }
															// // else take directly from props
															// else {}

															// Create a submit button
															const submitButton = document.createElement("button");
															Object.assign(submitButton.style, {
																// marginBottom: "3%",
																// marginRight: "4%",
																alignSelf: "flex-end",
																padding: "1.5% 3%",
																border: "none",
																borderRadius: "25px",
																// backgroundColor: "#007bff",
																backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																color: "white",
																cursor: "pointer",
															});
															submitButton.textContent = "Send";
															submitButton.type = "submit";

															// Add event listener for form submission
															submitButton.addEventListener("click", async (event) => {
																event.preventDefault(); // Prevent form submission

																// Get the entered email values
																const emailFromValue = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`).value : "";
																let emailToValue = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`).value : "";
																emailToValue = emailToValue.includes(",") ? emailToValue.split(/\s*,\s*/) : emailToValue;
																const emailSubjectValue = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`).value : "";
																const emailBodyValue = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`).value : "";
																const fileAttachmentValue = emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`).value : "";

																// to preserve line breaks in email body
																const formattedEmailBodyValue = emailBodyValue.replace(/\n/g, "<br>");

																console.log("form values", emailFromValue, emailToValue, emailSubjectValue, emailBodyValue, formattedEmailBodyValue, fileAttachmentValue);
																console.log("selectedFile", selectedFile);

																// Create a FormData object
																const formData = new FormData();

																// Append form data to FormData object
																formData.append("emailFrom", emailFromValue);
																formData.append("emailTo", emailToValue);
																formData.append("emailSubject", emailSubjectValue);
																formData.append("emailBody", formattedEmailBodyValue);
																formData.append("fileAttachment", selectedFile);

																// Make a fetch call to the API with the entered email address
																try {
																	// send email if email - to, subject, body are provided
																	if (emailToValue !== "" && emailSubjectValue !== "" && emailBodyValue !== "") {
																		const response = await fetch(`${updatedProps.API_HOST}user/custom_email`, {
																			method: "POST",
																			body: formData,
																		});

																		if (response.ok) {
																			console.log("Email sent successfully!");
																			showNotification(true, "Email sent successfully!");
																		} else {
																			console.error("Failed to send email.");
																			showNotification(false, "Failed to send email. Please check the provided values.");
																		}
																	}
																} catch (error) {
																	console.error("Error sending email:", error);
																	showNotification(false, "Error sending email. Please check the provided values.");
																}

																// Close the modal after form submission
																dynamicEmailSendingModal.close();
															});

															// Create a close button
															const closeButton = document.createElement("button");
															closeButton.setAttribute("class", "custom-email-modal-close");
															closeButton.setAttribute("id", `custom-email-modal-close-${sendEmailButtonContainer.id}`);
															Object.assign(closeButton.style, {
																border: "none",
																borderRadius: "3px",
																backgroundColor: "transparent",
																color: "white",
																cursor: "pointer",
															});
															closeButton.textContent = "X";
															closeButton.type = "button";

															// Add event listener for closing the modal
															closeButton.addEventListener("click", () => {
																dynamicEmailSendingModal.close();
															});

															// Append the form and close button to the modal
															dynamicEmailSendingModal.appendChild(modalHeader);
															dynamicEmailSendingModal.appendChild(emailForm);
															modalHeader.appendChild(closeButton);
															modalFooter.appendChild(submitButton);
															dynamicEmailSendingModal.appendChild(modalFooter);

															// Append the modal to the document body
															document.body.appendChild(dynamicEmailSendingModal);

															// Show the modal
															dynamicEmailSendingModal.showModal();
														}
													};

													// adding click event on stellar-button from the table components
													clonedEmailButton.getElementsByClassName("send-email-button")[0].addEventListener("click", function (event) {
														console.log("send-email-button clicked", "saving data in session storage");
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

														// this registers all stellar button clicks on LS
														// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
														// this registers only by the individuals in the column header
														localStorage.setItem(
															`clicked_send_email_button_container_table${id}_column${i}_${j_idx}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedEmailButton.getAttribute("id")}`,
															}),
														);

														sendEmailHandler(clonedEmailButton);
													});

													// to hide the header buttons after they have been cloned
													j.style.display = "none";
												});
											}
											// }
											// console.log("sendEmailButtons:", sendEmailButtons);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form text_area
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let normalTextAreaContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											normalTextAreaContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("normal_text_area_regular_form");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (normalTextAreaContainers) {
												if (normalTextAreaContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer, j) => {
													// let inputText = inputTextContainer.getElementsByTagName('input')[0];
													let normalTextAreaContainerClone = normalTextAreaContainer.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(normalTextAreaContainer, normalTextAreaContainerClone);

													// Change IDs and append cloned dropdowns to the container
													normalTextAreaContainerClone.setAttribute("id", `normal_text_area_field_table${id}_row${idx}_column${i}_${j}`);

													// Event listener for input event
													normalTextAreaContainerClone.addEventListener("input", (event) => {
														const enteredValue = event.target.value;
														localStorage.setItem(
															`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
													});

													// Event listener for keypress event with Enter key
													normalTextAreaContainerClone.addEventListener("keypress", (event) => {
														if (event.key === "Enter") {
															const enteredValue = event.target.value;
															localStorage.setItem(
																`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														}
													});

													// Append the dropdown to the table cell
													td.appendChild(normalTextAreaContainerClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer) => {
													normalTextAreaContainer.style.display = "none";
												});
											}
											// }
											// console.log("normalTextAreaContainers:", normalTextAreaContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating custom-table-row-selection-checkbox-container
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let tableRowSelectionCheckboxContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (tableRowSelectionCheckboxContainers) {
												// if (tableRowSelectionCheckboxContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// }

												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
													let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
													let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

													// to store row info accross page change
													const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

													// Change IDs and append cloned dropdowns to the container
													tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
													
													// Retrieve stored data from session storage
													let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

													// Check if this checkbox should be checked
													const existingData = storedData.find(
														(item) =>
															item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
															item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
															item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
															item.paginationIndex === currentPageIndex
													);
													if (existingData) {
														tableRowSelectionCheckboxClone.checked = true;
													}
													
													tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
														const enteredValue = event.target.value;
														const isChecked = event.target.checked; // Check if the checkbox is checked

														// to store row info accross page change
														const checkboxData = {
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
															enteredValue: `${enteredValue}`,
															paginationIndex: currentPageIndex, // Include the current page index
														};

														// Retrieve existing data
														let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

														if (isChecked) {
															// Checkbox is checked, add object to local storage
															localStorage.setItem(
																`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
															// console.log("currentPageIndex", currentPageIndex);

															// If checked, check if the entry already exists
															const existingIndex = storedData.findIndex(
																(item) =>
																	item.rowId === checkboxData.rowId &&
																	item.cellId === checkboxData.cellId &&
																	item.paginationIndex === checkboxData.paginationIndex &&
																	item.inputId === checkboxData.inputId
															);

															if (existingIndex !== -1) {
																// Update existing entry
																storedData[existingIndex] = checkboxData;
															} else {
																// Add new entry
																storedData.push(checkboxData);
															}
														} else {
															// Checkbox is unchecked, remove object from local storage
															localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
															
															// If unchecked, remove the entry
															storedData = storedData.filter(
																(item) =>
																	!(item.rowId === checkboxData.rowId &&
																	item.cellId === checkboxData.cellId &&
																	item.paginationIndex === checkboxData.paginationIndex &&
																	item.inputId === checkboxData.inputId)
															);
														}

														// You can also perform other actions based on the checkbox state
														// For example, update UI, make API calls, etc.
														
														// Update session storage with the modified array
														sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
													});

													// Append the dropdown to the table cell
													td.appendChild(tableRowSelectionCheckboxClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
													tableRowSelectionCheckboxContainer.style.display = "none";
												});
											}
											// }
											// console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_date_time field
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let dateTimeInputContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (dateTimeInputContainers) {
												// if (dateTimeInputContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
													let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
													let dateTimeInputClone = dateTimeInput.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(dateTimeInput, dateTimeInputClone);

													// Change IDs and append cloned dropdowns to the container
													dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
													dateTimeInputClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_form_date_time_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(dateTimeInputClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
													dateTimeInputContainer.style.display = "none";
												});
											}
											// }
											// console.log("dateTimeInputContainers:", dateTimeInputContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating toggle
										try {
											// plot only if the field_type of header attribute is boolean
											if (fieldType === 'Boolean') {
												// if (i === Number(table_selected_column)) {
												// console.log("i", i);
												// run this everytime api call is done due to user click event
												// find stellar-button component in header column
												let toggleContainers;
												// if (table_selected_column) {
												// console.log("customTableThead", customTableThead);

												// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
												// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

												// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

												toggleContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('.custom-table-toggle-container');
												// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

												if (toggleContainers) {
													// if there are toggle elements being replicated then don't show the data model content in their table cells along with the toggle replicated components
													td.textContent = "";

													// if (toggleContainers.length > 0) {
													// 	td.style.height = 'auto';
													// 	td.style.overflow = 'scroll';
													// 	td.style.scrollbarColor = `transparent transparent`;
													// }

													Array.from(toggleContainers).forEach((toggleContainer, j) => {
														let toggleInnerContainer = toggleContainer.getElementsByClassName("custom-table-inner-toggle-container")[0];
														let toggleClone = toggleInnerContainer.cloneNode(true);
														// Copy styles from the original to the cloned dropdown
														copyStyles(toggleInnerContainer, toggleClone);

														// Initial state of the toggle
														let isOn = false;

														// Change IDs and append cloned dropdowns to the container
														toggleClone.setAttribute("id", `custom_table_inner_toggle_container_table${id}_row${idx}_column${i}_${j}`);
														if (toggleClone.getElementsByClassName("custom-table-toggle")[0]) {
															toggleClone.getElementsByClassName("custom-table-toggle")[0].setAttribute("id", `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`);

															// update toggle once before/outside click event also so that it initializes according to the value coming from backend. If `content` value is true, toggle should be on, else off.
															if (content) {
																isOn = true;
															}
															updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));

															// now attach event to make the toggle function changing color on on/off actions and sending PUT api calls
															toggleClone.getElementsByClassName("custom-table-toggle")[0].addEventListener("click", (event) => {
																isOn = !isOn;

																// if the cell value is false, change it to true before sending it in PUT call payload, else send it as it it. When toggle is on, 'true' value should be send in the backend.
																let cellValue = content ? false : true;

																// a PUT api call will be made to update that particular record of the given table
																updateTable(cellValue, `${obj[updatedProps.DataModelObjIdKeyname]}`, updatedProps.updatingCustomUrlValue, updatedProps.updatingBaseUrlValue, updatedProps.updatingApiPathValue, updatedProps.updatingApiQueryParamValue, updatedProps.updatingApiTableAttributeValue, updatedProps.updatingApiMethodValue, updatedProps.updatingApiUsernameValue, updatedProps.updatingApiPasswordValue, updatedProps.updatingApiBearerTokenValue, updatedProps.updatingApiMoreHeadersValue);

																updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));
																console.log(`Toggle is now: ${isOn ? 'ON' : 'OFF'}`, content);

																// Construct the localStorage key
																let toggleLocalStorageKey = `clicked_custom_table_toggle_table${id}_column${i}`;

																// Retrieve existing data from local storage
																let togglesData = JSON.parse(localStorage.getItem(toggleLocalStorageKey)) || [];

																// Create the new toggle data object
																let newToggleData = {
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	toggleEleId: `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`,
																	toggleStatus: isOn,
																};

																// Check if the toggle already exists
																let existingToggleIndex = togglesData.findIndex(toggle => toggle.toggleEleId === newToggleData.toggleEleId);

																if (existingToggleIndex !== -1) {
																	// Update the existing toggle data
																	togglesData[existingToggleIndex] = newToggleData;
																} else {
																	// Add the new toggle data
																	togglesData.push(newToggleData);
																}

																// Update the local storage with the new/updated toggle data, column-wise
																localStorage.setItem(toggleLocalStorageKey, JSON.stringify(togglesData));
															});
														}

														// Append the dropdown to the table cell
														td.appendChild(toggleClone);
													});

													// Hide the original dropdowns in the header column
													Array.from(toggleContainers).forEach((toggleContainer) => {
														toggleContainer.style.display = "none";
													});
												}
												// }
												// console.log("toggleContainers:", toggleContainers);
												// }
											}										
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating rich text input
										try {
											// console.log("i", i);

											let inputTextContainers;

											// console.log("customTableThead", customTableThead);

											// console.log( //     "thead th i", //     customTableThead //     .getElementsByTagName("tr")[0] //     .querySelectorAll("th")[i] // );

											let richTextIndexesData = updatedProps.richTextIndexes;
											// console.log( "richTextIndexesData::::::::::", richTextIndexesData );

											if (richTextIndexesData.length > 0) {
												richTextIndexesData.forEach((x) => {
													console.log("richTextIndexesData x::::::::::", x);
													console.log("richTextIndexesData::::::", i);
													console.log("richTextIndexesData::::::", i);
													if (x === i) {
														td.innerHTML = "";
														let wrapperDiv = document.createElement("div");
														wrapperDiv.setAttribute(
															"id",
															`rich_text_container_${idx}_${id}`
														);
														wrapperDiv.setAttribute(
															"class",
															"rich_text_table_container"
														);

														wrapperDiv.style.backgroundColor = "white";

														td.appendChild(wrapperDiv);

														let quill = new Quill(wrapperDiv, {
															modules: {
																toolbar: updatedProps.richTextOptions.toolbar ? [
																	[{
																		header: [1, 2, 3, 4, 5, 6, false]
																	}],
																	["bold", "italic", "underline", "strike"],
																	[{
																		list: "bullet"
																	}, {
																		list: "ordered"
																	}],
																	["link"],
																	[{
																		align: []
																	}],
																	[{
																		color: []
																	}, {
																		background: []
																	}],
																] : false,
															},
															placeholder: "",
															theme: "snow",
														});

														updatedProps.richTextOptions.readOnly ? quill.enable(false) : quill.enable(true);

														if (content) {
															console.log('contentRich:::::::',content)
															let jsonContent = JSON.parse(content);
															console.log('jsonContent:::::::::',jsonContent)
															console.log('type jsonContent:::::::::',typeof jsonContent)
															if (jsonContent ) {
																console.log('inside jsonContent')
																quill.setContents(jsonContent);
															}
														} else {
															let richTextContent;
															richTextContent = sessionStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );
															if (!richTextContent) richTextContent = localStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );

															let parsedData = JSON.parse(richTextContent);
															if (parsedData && parsedData.delta) {
																quill.setContents(parsedData.delta);
															}
																
															
													}

														quill.on("text-change", () => {
															const delta = quill.getContents();

															let deltaRef = {
																"data-db-id": `${
																	obj[updatedProps.DataModelObjIdKeyname]
																	}`,
																delta:delta
															}
															
															localStorage.setItem(
																`redslingRichTextTable${id}_row_${idx}_column${x}`,
																JSON.stringify(deltaRef)
															);
															
															sessionStorage.setItem(
																`redslingRichTextTable${id}_row_${idx}_column${x}`,
																JSON.stringify(deltaRef)
															);
														});

													
													}
												});
											}

											// }
											// console.log("inputTextContainers:", inputTextContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										tr.append(td);
									}

									if (customTableTbody) {
										customTableTbody.append(tr);
									}
									
									console.log("+idx+", idx);
							});
						} else {
							// Hide the table during rendering to prevent layout recalculations
							// customTableTbody.style.display = "none";

							// Batch size for chunked rendering
							const BATCH_SIZE = 1000;
							const totalRows = apiData.length;
							let currentRow = 0;

							// Function to render a batch of rows
							function renderBatch() {
								const fragment = document.createDocumentFragment();

								for (let i = 0; i < BATCH_SIZE && currentRow < totalRows; i++, currentRow++) {
									const obj = apiData[currentRow];
									const idx = i;

									let tr = document.createElement("tr");
									tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
									tr.setAttribute("class", "custom-table-tbody-row");
									// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
									tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
									tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
									tr.style.width = "100%";
									tr.style.height = "auto";
									if (idx % 2 !== 0) {
										tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
										// hover effect
										tr.addEventListener("mouseover", function () {
											tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
										});
										tr.addEventListener("mouseleave", function () {
											tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyOddTrBackgroundColor} ${updatedProps.tBodyOddTrBackgroundColor}`;
										});
									} else {
										tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
										// hover effect
										tr.addEventListener("mouseover", function () {
											tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
										});
										tr.addEventListener("mouseleave", function () {
											tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
											// tr.style.scrollbarColor = `${updatedProps.tBodyEvenTrBackgroundColor} ${updatedProps.tBodyEvenTrBackgroundColor}`;
										});
									}

									// get data and add it as table's column
									for (let i = 0; i < updatedProps.numColsInputValue; i++) {
										let content, convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
										// using updatedProps.HeaderFields because only these are present in the downloading code
										// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
										// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
										// }
										// field name and type are for toggle replication
										let fieldName;

										try {
											if (`HeaderField_${i}` in updatedProps) {
												// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
												if (updatedProps[`HeaderField_${i}`].includes(":")) {
													// Replace spaces around colon with an empty string
													const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
													// Split the modified string by colon
													const referenceArray = modifiedStr.split(":");

													const referenceArrayObjName = `${referenceArray[0]}_data`;
													const referenceArrayKeyName = referenceArray[1];

													// console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
													// console.log(obj[referenceArrayObjName]);
													// if the reference object in not undefined, then find the further keyname
													if (obj[referenceArrayObjName]) {
														content = obj[referenceArrayObjName][referenceArrayKeyName];
													}
													// content = obj[referenceArrayObjName][referenceArrayKeyName];
												} else {
													// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
													// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
													if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
														// let convertedDate;
														dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
														targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
														switch (targetFormat) {
															case "":
																convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
																break;

															case "date_mon_year":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
																break;
															case "date_month_year":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
																break;
															case "dd/mm/yyyy":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
																break;
															case "mm/dd/yyyy":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
																break;
															case "dd-mm-yyyy":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
																break;

															case "date_mon_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
																break;
															case "date_month_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
																break;
															case "dd/mm/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
																break;
															case "mm/dd/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
																break;
															case "dd-mm-yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
																break;

															case "date_mon_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
																break;
															case "date_month_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
																break;
															case "dd/mm/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
																break;
															case "mm/dd/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
																break;
															case "dd-mm-yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
																break;

															default:
																console.error("Invalid target date format");
														}

														content = convertedDate;
														// console.log("converted content 0", dateString, content);
													} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
														numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
														// console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 0", convertedNumber, content);
													} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
														timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
														// console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 0", convertedNumber, content);
													} else {
														content = obj[`${updatedProps[`HeaderField_${i}`]}`];
													}

													// applying function from session storage on specific column (by index)
													if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
														try {
															// Retrieve the function string from sessionStorage
															const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

															if (storedFuncString) {
																// Recreate the function using the Function constructor
																const recreatedFunction = new Function(`return (${storedFuncString})`)();
																// console.log('Function recreated:', recreatedFunction);

																// Use the recreated function
																content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
																// console.log('+recreatedFunction content+', content);
															}
														} catch (error) {
															console.error(error);
														}
													}
												}
												// console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
											} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
												// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
												if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
													// Replace spaces around colon with an empty string
													const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
													// Split the modified string by colon
													const referenceArray = modifiedStr.split(":");

													const referenceArrayObjName = `${referenceArray[0]}_data`;
													const referenceArrayKeyName = referenceArray[1];

													// console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
													// console.log(obj[referenceArrayObjName]);
													// if the reference object in not undefined, then find the further keyname
													if (obj[referenceArrayObjName]) {
														content = obj[referenceArrayObjName][referenceArrayKeyName];
													}
													// content = obj[referenceArrayObjName][referenceArrayKeyName];
												} else {
													// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
													if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
														// let convertedDate;
														dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
														switch (targetFormat) {
															case "":
																convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
																break;

															case "date_mon_year":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
																break;
															case "date_month_year":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
																break;
															case "dd/mm/yyyy":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
																break;
															case "mm/dd/yyyy":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
																break;
															case "dd-mm-yyyy":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
																break;

															case "date_mon_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
																break;
															case "date_month_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
																break;
															case "dd/mm/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
																break;
															case "mm/dd/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
																break;
															case "dd-mm-yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
																break;

															case "date_mon_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
																break;
															case "date_month_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
																break;
															case "dd/mm/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
																break;
															case "mm/dd/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
																break;
															case "dd-mm-yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
																break;

															default:
																console.error("Invalid target date format");
														}

														content = convertedDate;
														// console.log("converted content 1", dateString, content);
													} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
														numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
														// console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 1", convertedNumber, content);
													} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
														timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
														// console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 1", convertedNumber, content);
													} else {
														content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													}

													// applying function from session storage on specific column (by index)
													if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
														try {
															// Retrieve the function string from sessionStorage
															const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

															if (storedFuncString) {
																// Recreate the function using the Function constructor
																const recreatedFunction = new Function(`return (${storedFuncString})`)();
																// console.log('Function recreated:', recreatedFunction);

																// Use the recreated function
																content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
																// console.log('+recreatedFunction content+', content);
															}
														} catch (error) {
															console.error(error);
														}
													}
													// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												}
												// console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
											} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
												// Get the value of the attribute from 'this'
												// console.log("customTableContainer", customTableContainer);
												const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

												// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
												if (headerFieldAttribute.includes(":")) {
													// Replace spaces around colon with an empty string
													const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
													// Split the modified string by colon
													const referenceArray = modifiedStr.split(":");

													const referenceArrayObjName = `${referenceArray[0]}_data`;
													const referenceArrayKeyName = referenceArray[1];

													// console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
													// console.log(obj[referenceArrayObjName]);
													// if the reference object is not undefined, then find the further keyname
													if (obj[referenceArrayObjName]) {
														content = obj[referenceArrayObjName][referenceArrayKeyName];
													}
												} else {
													// content = obj[headerFieldAttribute];
													// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
													if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
														// let convertedDate;
														dateString = obj[headerFieldAttribute];
														targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
														switch (targetFormat) {
															case "":
																convertedDate = obj[headerFieldAttribute];
																break;

															case "date_mon_year":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
																break;
															case "date_month_year":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
																break;
															case "dd/mm/yyyy":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
																break;
															case "mm/dd/yyyy":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
																break;
															case "dd-mm-yyyy":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
																break;

															case "date_mon_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
																break;
															case "date_month_year_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
																break;
															case "dd/mm/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
																break;
															case "mm/dd/yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
																break;
															case "dd-mm-yyyy_00_00_am_pm":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
																break;

															case "date_mon_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
																break;
															case "date_month_year_24_00":
																convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
																break;
															case "dd/mm/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
																break;
															case "mm/dd/yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
																break;
															case "dd-mm-yyyy_24_00":
																convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
																break;

															default:
																console.error("Invalid target date format");
														}

														content = convertedDate;
														// console.log("converted content 2", dateString, content);
													} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
														timezoneString = obj[headerFieldAttribute];
														let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
														// console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 2", convertedNumber, content);
													} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
														numberString = obj[headerFieldAttribute];
														let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

														convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
														// console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

														content = convertedNumber;
														// console.log("converted content 2", convertedNumber, content);
													} else {
														content = obj[headerFieldAttribute];
													}

													// applying function from session storage on specific column (by index)
													if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
														try {
															// Retrieve the function string from sessionStorage
															const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

															if (storedFuncString) {
																// Recreate the function using the Function constructor
																const recreatedFunction = new Function(`return (${storedFuncString})`)();
																// console.log('Function recreated:', recreatedFunction);

																// Use the recreated function
																content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
																// console.log('+recreatedFunction content+', content);
															}
														} catch (error) {
															console.error(error);
														}
													}
												}
												// console.log("headerFieldAttribute", headerFieldAttribute, content);
											}
										} catch (e) {
											// statements
											console.error(e);
										}

										// field name and type are for toggle replication
										let fieldType;
										let tableDataFields;
										if (tableData && ("fields" in tableData)) tableDataFields = tableData.fields;
										if (tableDataFields) fieldType = tableDataFields.filter((x) => x.field_name === fieldName)[0]?.field_type;
										
										// // console.log("+fieldType+", fieldType);

										// // console.log("content, i", content, i);

										let td = document.createElement("td");
										td.setAttribute("data-gjs-type", "cell");
										// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
										td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
										td.style.padding = "8px";

										td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
										td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
										td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
										td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
										td.style.borderCollapse = "collapse";
										td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
										td.style.color = `${updatedProps.tBodyTdFontColor}`;
										td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
										td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
										td.innerHTML = content !== undefined ? content : "";

										// for replicating single column component
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let headerSingleColumnComponents;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											headerSingleColumnComponents = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('div[data-gjs-type="custom-table-thead-row"][class*="gjs-row"]');
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (headerSingleColumnComponents) {
												if (headerSingleColumnComponents.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												for (let j = 0; j < headerSingleColumnComponents.length; j++) {
													// row.getElementsByTagName('td')[table_selected_column].append(headerSingleColumnComponents[j]);
													let clonedSingleColumnComponent = headerSingleColumnComponents[j].cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(headerSingleColumnComponents[j], clonedSingleColumnComponent);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedSingleColumnComponent.setAttribute("id", `single_column_component_table${id}_row${idx}_column${i}_${j}`);

													// for replicating ACTION BUTTON inside single column components
													let innerClonedActionButtons = clonedSingleColumnComponent.getElementsByClassName("custom-table-action-button");
													if (innerClonedActionButtons.length > 0) {
														Array.from(innerClonedActionButtons).forEach((innerClonedActionButton, j_btn) => {
															if (innerClonedActionButton) {
																// changing the clone's IDs so that they are present and available individually in LB and in download
																innerClonedActionButton.setAttribute("id", `custom_table_action${innerClonedActionButton.getAttribute("data-table-action")}_button_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																if (innerClonedActionButton.getElementsByTagName("p")[0]) {
																	innerClonedActionButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																}

																// adding click event on stellar-button from the table components
																innerClonedActionButton.addEventListener("click", async function (event) {
																	console.log("action-button clicked", "saving data in session storage");
																	event.preventDefault();
																	// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
																	localStorage.setItem(
																		`custom_table_action_button_table${id}_${innerClonedActionButton.getAttribute("data-table-action")}_column${i}_${j}_element${j_btn}_clicked`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			"data-table-action": `${innerClonedActionButton.getAttribute("data-table-action")}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			buttonId: `${innerClonedActionButton.getAttribute("id")}`,
																		}),
																	);

																	// Style for the modals
																	let modalStyle = {
																		padding: "2%",
																		backgroundColor: "white",
																		borderRadius: "5px",
																		boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																		maxWidth: "50vw",
																		width: "30vw",
																		maxHeight: "40vh",
																		margin: "auto",
																		overflow: "auto",
																		"-ms-overflow-style": "none",
																		"scrollbar-width": "none",
																	};

																	// Style for the input fields
																	let inputFieldStyle = {
																		display: "flex",
																		flexDirection: "column",
																		marginBottom: "15px",
																	};

																	// Style for the buttons
																	let buttonStyle = {
																		width: "100%",
																		padding: "10px",
																		backgroundColor: "#007bff",
																		color: "white",
																		border: "none",
																		borderRadius: "5px",
																		cursor: "pointer",
																		// marginRight: 'auto',
																		// marginLeft: 'auto',
																		marginBottom: "5%",
																	};

																	let submitButtonStyle = {
																		width: "50%",
																		padding: "10px",
																		backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																		color: "white",
																		border: "none",
																		borderRadius: "5px",
																		cursor: "pointer",
																		// marginRight: 'auto',
																		// marginLeft: 'auto',
																		marginBottom: "5%",
																	};

																	let closeButtonStyle = {
																		width: "50%",
																		padding: "10px",
																		backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
																		color: "white",
																		border: "none",
																		borderRadius: "5px",
																		cursor: "pointer",
																		// marginRight: 'auto',
																		// marginLeft: 'auto',
																		marginBottom: "5%",
																	};

																	// Edit modal
																	if (innerClonedActionButton.getAttribute("data-table-action") === "edit") {
																		// Create the dialog element
																		let editModal = document.createElement("dialog");
																		editModal.setAttribute("id", "custom-modal");

																		// Apply styles to the modal
																		Object.assign(editModal.style, modalStyle);

																		// Create content for the modal
																		let modalContent = document.createElement("form");

																		// Apply styles to the modal content
																		Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																		// Create a label for the data model input field
																		let dataModelLabel = document.createElement("label");
																		dataModelLabel.innerHTML = `Edit`;
																		dataModelLabel.style.width = "100%";
																		dataModelLabel.style.borderBottom = "1px solid gray";
																		dataModelLabel.style.lineHeight = "2";
																		dataModelLabel.style.fontSize = "1.4vw";

																		// Apply styles to the label
																		Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																		// Append label to the modal content
																		modalContent.appendChild(dataModelLabel);

																		// variable only for the reference keyname user input
																		let selectedOptions = [];
																		// Check if updatedProps.EditableFields is not empty
																		if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																			// Iterate over EditableFields to create form elements
																			for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																				// to handle non-Reference field keynames
																				if (!fieldName.includes("-")) {
																					let fieldLabel = document.createElement("label");
																					fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																					fieldLabel.style.textTransform = "capitalize";
																					modalContent.appendChild(fieldLabel);

																					let inputField = document.createElement("input");
																					inputField.setAttribute("name", fieldName);
																					inputField.value = obj[fieldName]; // to initialize input fields with present values
																					Object.assign(inputField.style, {
																						borderRadius: "5px",
																						border: "1px solid gray",
																						marginBottom: "5%",
																						padding: "1%",
																					});

																					// Set input field type based on field type
																					switch (fieldType.toLowerCase()) {
																						case "string":
																							inputField.setAttribute("type", "text");
																							break;
																						case "boolean":
																							inputField.setAttribute("type", "radio");
																							break;
																						case "number":
																							inputField.setAttribute("type", "number");
																							break;
																						case "date-time":
																							inputField.setAttribute("type", "datetime-local");
																							break;
																						case "time":
																							inputField.setAttribute("type", "time");
																							break;
																						case "file":
																							inputField.setAttribute("type", "file");
																							break;
																						default:
																							inputField.setAttribute("type", "text");
																							break;
																					}
																					modalContent.appendChild(inputField);
																				}

																				// to handle Reference field keynames for example - WebProject1_Address-Sector
																				else if (fieldName.includes("-")) {
																					// variables to be used later in this if-condition
																					let [table, column] = fieldName.split("-");
																					// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																					// create its label
																					let refFieldLabel = document.createElement("label");
																					refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																					refFieldLabel.style.textTransform = "capitalize";
																					modalContent.appendChild(refFieldLabel);

																					// create a drodown for these keynames > its options will be all the values from response from that table
																					let referenceKeynameDropdown = document.createElement("select");
																					referenceKeynameDropdown.setAttribute("name", fieldName);
																					referenceKeynameDropdown.setAttribute("id", fieldName);
																					// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																					Object.assign(referenceKeynameDropdown.style, {
																						borderRadius: "5px",
																						border: "1px solid gray",
																						marginBottom: "5%",
																						padding: "1%",
																					});

																					// populate it with options using the response from its data-model API
																					let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																						headers: {
																							Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																						},
																					});
																					let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																					if (referenceKeynameValueOptionsData) {
																						// setting default option --select--
																						let defaultOption = document.createElement("option");
																						// defaultOption.setAttribute('disabled', 'disabled');
																						defaultOption.innerHTML = "--select--";
																						referenceKeynameDropdown.appendChild(defaultOption);

																						// loop over the response to fill the options of its reference table
																						referenceKeynameValueOptionsData.forEach((optObj) => {
																							let option = document.createElement("option");
																							option.value = optObj[`${column}`];
																							option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																							option.innerHTML = optObj[`${column}`];
																							// to initialize input fields with present values
																							if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																								option.setAttribute("selected", true);
																							}
																							referenceKeynameDropdown.appendChild(option);
																						});
																					}

																					referenceKeynameDropdown.addEventListener("change", function (e) {
																						let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																						// console.log('Selected value:', selectedOptionFromDropdown.value);
																						// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																						// get the reference ID of the table row from main obj to update
																						let refTableId;
																						let tableName = table.split("_")[1];
																						if (obj[tableName] !== null) {
																							refTableId = obj[tableName];
																						}

																						selectedOptions.push({
																							table,
																							column,
																							refTableId,
																							value: selectedOptionFromDropdown.value,
																							"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																						});
																					});

																					modalContent.appendChild(referenceKeynameDropdown);
																				}
																			}
																		}

																		console.log("+bu, bubt+", bu, bubt);

																		let buttonContainer = document.createElement("div");
																		buttonContainer.setAttribute("class", "modal-button-container");
																		buttonContainer.style.display = "flex";
																		buttonContainer.style.alignItems = "center";
																		buttonContainer.style.justifyContent = "space-between";
																		buttonContainer.style.columnGap = "5%";
																		buttonContainer.style.width = "100%";
																		buttonContainer.style.marginTop = "5%";

																		// Create a submit button for the form
																		let submitButton = document.createElement("button");
																		submitButton.setAttribute("class", "table-submit-button");
																		submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
																		submitButton.innerHTML = "Submit";

																		// Apply styles to the button
																		Object.assign(submitButton.style, submitButtonStyle);

																		submitButton.addEventListener("click", function (e) {
																			e.preventDefault(); // Prevent default form action
																			// Handle form submission here
																			console.log("Form submitted!", obj);

																			// Create an object to store the values of input fields
																			let values = {};

																			// // Add default input field values to the values object
																			// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																			// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																			function replaceSubstringWithTableData(url) {
																				return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																			}

																			// Add default input field values to the values object
																			values["DataModel"] = updatedProps.customUrlValue 
																				? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																				: replaceSubstringWithTableData(bu);
																				
																			values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Initialize an empty FormData object to get the form's input fields values
																			let formData = new FormData(modalContent);
																			let formValues = {};
																			for (const [key, value] of formData.entries()) {
																				formValues[key] = value;
																			}

																			// Create the to_insert object with the keynames from updatedProps.EditableFields
																			// and their corresponding values from formValues
																			let to_insert = {};
																			Object.keys(updatedProps.EditableFields).forEach((key) => {
																				if (formValues[key] !== undefined && !key.includes("-")) {
																					// Check if the form has the field
																					to_insert[key] = formValues[key];
																				}

																				// re-attach the reference id to PUT call else it will set the references to null
																				if (formValues[key] !== undefined && key.includes("-")) {
																					let x = key.split("-");
																					let table = x[0].split("_")[1];
																					if (obj[table] !== null) {
																						to_insert[table] = obj[table];
																					}
																				}
																			});

																			// console.log("+to_insert+", to_insert);
																			// console.log("+selectedOptions+", selectedOptions);

																			// logic to update all table cells the edit button is editing without refreshing
																			for (const key in to_insert) {
																				let columns = [];
																				if (updatedProps[`HeaderField_${i}`]) {
																					Object.keys(updatedProps).map((hF_i) => {
																						if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																					});
																				} else {
																					Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																						if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																					});
																				}

																				// console.log('+columns+', columns);

																				columns.forEach(idx => {
																					let td = tr.getElementsByTagName('td')[idx];

																					let valueToUpdateCellWith = to_insert[key];
																					// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																					// Find the first text node
																					const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																					if (textNode) {
																						textNode.nodeValue = valueToUpdateCellWith;
																					} else {
																						// If no text node exists, create one
																						td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																					}
																				});
																			}

																			// Fetch call (to non-reference table) can be made here using the input field values
																			// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																			fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																				method: "PUT",
																				headers: {
																					"Content-Type": "application/json",
																					Authorization: `Bearer ${values.BearerToken}`,
																				},
																				body: JSON.stringify({
																					to_insert: to_insert,
																				}),
																			})
																				.then((response) => response.json())
																				.then((data) => console.log(data));

																			// Fetch call (for reference table)
																			if (selectedOptions.length > 0) {
																				selectedOptions.forEach((data) => {
																					// make api calls per object
																					let requestBody = {};
																					requestBody[data.column] = data.value; // Assign dynamic key-value pair
																					fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																						method: "PUT",
																						headers: {
																							"Content-Type": "application/json",
																							Authorization: `Bearer ${values.BearerToken}`,
																						},
																						body: JSON.stringify({
																							to_insert: requestBody,
																						}),
																					})
																						.then((response) => response.json())
																						.then((data) => console.log(data));
																				});
																			}

																			// Close the modal when the form is submitted
																			editModal.close();

																			// clear the selectedOptions for next turn
																			selectedOptions = [];

																			// // giving a delay in reload so that it doesn't change the url in the broswer tab
																			// setTimeout(function () {
																			// 	window.top.location.reload();
																			// }, 2000);
																		});

																		// Create a close button for the modal
																		let closeButton = document.createElement("button");
																		closeButton.setAttribute("class", "table-close-button");
																		closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
																		closeButton.innerHTML = "Close";

																		// Apply styles to the button
																		Object.assign(closeButton.style, closeButtonStyle);

																		closeButton.addEventListener("click", function (event) {
																			event.preventDefault(); // Prevent default behavior of the click event
																			editModal.close(); // Close the modal when the close button is clicked
																		});

																		// Append content, submit button, and close button to the modal
																		buttonContainer.appendChild(submitButton);
																		buttonContainer.appendChild(closeButton);
																		modalContent.appendChild(buttonContainer);
																		editModal.appendChild(modalContent);

																		// Append the modal to the document
																		document.body.appendChild(editModal);

																		// Show the modal
																		editModal.showModal();
																	}
																	// Delete modal
																	else if (innerClonedActionButton.getAttribute("data-table-action") === "delete") {
																		// Create the dialog element
																		let deleteModal = document.createElement("dialog");
																		deleteModal.setAttribute("id", "delete-modal");

																		// Apply styles to the modal
																		Object.assign(deleteModal.style, modalStyle);

																		// Create content for the modal
																		let modalContent = document.createElement("form");

																		// Apply styles to the modal content
																		Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																		// Create a label for the data model input field
																		let dataModelLabel = document.createElement("label");
																		dataModelLabel.innerHTML = `Delete`;
																		dataModelLabel.style.width = "100%";
																		dataModelLabel.style.borderBottom = "1px solid gray";
																		dataModelLabel.style.lineHeight = "2";
																		dataModelLabel.style.fontSize = "1.4vw";

																		// Apply styles to the label
																		Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																		// Append label to the modal content
																		modalContent.appendChild(dataModelLabel);

																		// // Create an input field for data model with a default value
																		// let dataModelInputField = document.createElement("input");
																		// dataModelInputField.setAttribute('type', 'text');
																		// dataModelInputField.setAttribute('name', 'DataModel');
																		// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
																		// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

																		// // Apply styles to the input fields
																		// Object.assign(dataModelInputField.style, inputFieldStyle);

																		// modalContent.appendChild(dataModelInputField);

																		// // Create an input field for bearer token with a default value
																		// let bearerTokenInputField = document.createElement("input");
																		// bearerTokenInputField.setAttribute('type', 'text');
																		// bearerTokenInputField.setAttribute('name', 'BearerToken');
																		// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
																		// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

																		// // Apply styles to the input fields
																		// Object.assign(bearerTokenInputField.style, inputFieldStyle);

																		// modalContent.appendChild(bearerTokenInputField);

																		// Create a paragraph tag for confirmation message
																		let confirmationMessage = document.createElement("p");
																		confirmationMessage.style.marginBottom = "5%";
																		confirmationMessage.style.fontSize = "1vw";
																		confirmationMessage.innerHTML = "Are you sure?";

																		// Append confirmation message to the modal content
																		modalContent.appendChild(confirmationMessage);

																		let buttonContainer = document.createElement("div");
																		buttonContainer.setAttribute("class", "modal-button-container");
																		buttonContainer.style.display = "flex";
																		buttonContainer.style.alignItems = "center";
																		buttonContainer.style.justifyContent = "space-between";
																		buttonContainer.style.columnGap = "5%";
																		buttonContainer.style.width = "100%";

																		// Create a submit button for the form
																		let submitButton = document.createElement("button");
																		submitButton.setAttribute("class", "table-delete-yes-button");
																		submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
																		submitButton.innerHTML = "Yes";

																		// Apply styles to the "Yes" button
																		Object.assign(submitButton.style, submitButtonStyle);

																		submitButton.addEventListener("click", async function (e) {
																			e.preventDefault();
																			// Handle form submission here
																			console.log("Form submitted!");

																			// Create an object to store the values of input fields
																			let values = {};

																			// // Add default input field values to the values object
																			// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																			// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																			function replaceSubstringWithTableData(url) {
																				return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																			}

																			// Add default input field values to the values object
																			values["DataModel"] = updatedProps.customUrlValue 
																				? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																				: replaceSubstringWithTableData(bu);
																				
																			values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																			// Use the values in the fetch call or other logic
																			console.log("Input field values:", values);

																			// Make the fetch call to delete the item
																			let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																				method: "DELETE",
																				headers: {
																					"Content-Type": "application/json",
																					Authorization: `Bearer ${values.BearerToken}`,
																				},
																			});
																			// .then(response => {
																			//     if (response.ok) {
																			//         console.log('Item deleted successfully');
																			//     } else {
																			//         console.error('Failed to delete item');
																			//     }
																			// });

																			let responseData = response.json();
																			if (responseData.ok) {
																				console.log("Item deleted successfully");
																			} else {
																				console.error("Failed to delete item");
																			}

																			// Close the modal when the form is submitted
																			deleteModal.close();

																			// giving a delay in reload so that it doesn't change the url in the broswer tab
																			setTimeout(function () {
																				window.top.location.reload();
																			}, 2000);
																		});

																		// Create a close button for the modal
																		let closeButton = document.createElement("button");
																		closeButton.setAttribute("class", "table-delete-no-button");
																		closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
																		closeButton.innerHTML = "No";

																		// Apply styles to the button
																		Object.assign(closeButton.style, closeButtonStyle);

																		closeButton.addEventListener("click", function (e) {
																			e.preventDefault();
																			deleteModal.close(); // Close the modal when the close button is clicked
																		});

																		// Append content, submit button, and close button to the modal
																		buttonContainer.appendChild(submitButton);
																		buttonContainer.appendChild(closeButton);
																		modalContent.appendChild(buttonContainer);
																		deleteModal.appendChild(modalContent);

																		// Append the modal to the document
																		document.body.appendChild(deleteModal);

																		// Show the modal
																		deleteModal.showModal();
																	}
																});

																clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedActionButton);
															}
														});
													}

													// for replicating STELLAR BUTTON inside single column components
													let innerClonedStellarButtons = clonedSingleColumnComponent.getElementsByClassName("stellar-button-container");
													if (innerClonedStellarButtons.length > 0) {
														Array.from(innerClonedStellarButtons).forEach((innerClonedStellarButton, j_btn) => {
															if (innerClonedStellarButton) {
																// changing the clone's IDs so that they are present and available individually in LB and in download
																innerClonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																if (innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
																	innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
																}

																// adding click event on stellar-button from the table components
																innerClonedStellarButton.addEventListener("click", function (event) {
																	console.log("stellar-button clicked", "saving data in session storage");
																	event.preventDefault();
																	// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

																	// this registers all stellar button clicks on LS
																	// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
																	// this registers only by the individuals in the column header
																	localStorage.setItem(
																		`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																		}),
																	);

																	sessionStorage.setItem(
																		`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																		JSON.stringify({
																			"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																			tableId: `${id}`,
																			rowId: `custom_table_tbody_row${idx}_table${id}`,
																			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																			buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																		}),
																	);
																});

																clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedStellarButton);
															}
														});
													}

													td.appendChild(clonedSingleColumnComponent);

													// to hide the header buttons after they have been cloned
													headerSingleColumnComponents[j].style.display = "none";
												}
											}
											// }
											// console.log("headerSingleColumnComponents:", headerSingleColumnComponents);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating stellar buttons
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let headerStellarButtons;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
											// console.log("headerStellarButtons1:", headerStellarButtons);
											if (headerStellarButtons) {
												if (headerStellarButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												for (let j = 0; j < headerStellarButtons.length; j++) {
													console.log("headerStellarButtons[j]", headerStellarButtons[j]);
													// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
													let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(headerStellarButtons[j], clonedStellarButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
													clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
													if (clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
														clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);
													}

													// if the header stellar button was not in single column component, only then append it to td
													if (!headerStellarButtons[j].parentNode.classList.contains("gjs-cell")) {
														td.appendChild(clonedStellarButton);
														console.log("appended");
													}

													// adding click event on stellar-button from the table components
													clonedStellarButton.addEventListener("click", function (event) {
														console.log("stellar-button clicked", "saving data in session storage");
														event.preventDefault();
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

														// this registers all stellar button clicks on LS
														// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
														// this registers only by the individuals in the column header
														localStorage.setItem(
															`clicked_stellar_button_container_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedStellarButton.getAttribute("id")}`,
															}),
														);

														sessionStorage.setItem(
															`clicked_stellar_button_container_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedStellarButton.getAttribute("id")}`,
															}),
														);

														// highlight the table row that the stellar button has selected
														if (updatedProps.tBodyTrSelectColorHighlight)
															clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";

														customTable.onclick = function(event) {														
															// Check if the click is inside the custom table but outside the clicked row
															// if (
															// 	customTable.contains(event.target) && // Click is inside the customTable
															// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
															// ) {
															// 	console.log('@deselecting row', idx);
															// 	// Determine original color based on row index (even/odd)
															// 	const originalColor = idx % 2 === 0
															// 		? updatedProps.tBodyEvenTrBackgroundColor
															// 		: updatedProps.tBodyOddTrBackgroundColor;

															// 	// Reset the row's background color
															// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
															// }

															// Iterate over all rows in the table
															const rows = customTable.querySelectorAll("tr");
															rows.forEach((row, rowIndex) => {
																// Skip the currently selected row
																if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																	return;
																}

																// Reset the background color based on even/odd index
																const originalColor = rowIndex % 2 === 0
																	? updatedProps.tBodyEvenTrBackgroundColor
																	: updatedProps.tBodyOddTrBackgroundColor;

																row.style.backgroundColor = originalColor;
															});
														};
													});

													// to hide the header buttons after they have been cloned
													headerStellarButtons[j].style.display = "none";
												}
											}
											// }
											// console.log("headerStellarButtons:", headerStellarButtons);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating action buttons
										try {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find action-button component in header column
											let headerActionButtons;
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
											headerActionButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-action-button");

											if (headerActionButtons.length > 0) {
												if (headerActionButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(headerActionButtons).forEach(async (j, j_idx) => {
													// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
													let clonedButton = j.cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													await copyStyles(j, clonedButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedButton.setAttribute("id", `custom_table_action_button_table${id}_row${idx}_column${i}_${j}`);
													if (clonedButton.getElementsByTagName("p")[0]) {
														clonedButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}`);
													}

													// if the header action button was not in single column component, only then append it to td
													console.log("j.parentNode.classList", j.parentNode.classList);
													if (!j.parentNode.classList.contains("gjs-cell")) {
														td.appendChild(clonedButton);
														console.log("appended");
													}

													// adding click event on stellar-button from the table components
													clonedButton.addEventListener("click", async function (event) {
														console.log("action-button clicked", "saving data in session storage");
														event.preventDefault();
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
														localStorage.setItem(
															`custom_table_action_button_table${id}_clicked`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																"data-table-action": `${clonedButton.getAttribute("data-table-action")}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedButton.getAttribute("id")}`,
															}),
														);

														// Style for the modals
														let modalStyle = {
															padding: "2%",
															backgroundColor: "white",
															borderRadius: "5px",
															boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
															maxWidth: "50vw",
															width: "30vw",
															maxHeight: "40vh",
															margin: "auto",
															overflow: "auto",
															"-ms-overflow-style": "none",
															"scrollbar-width": "none",
														};

														// Style for the input fields
														let inputFieldStyle = {
															display: "flex",
															flexDirection: "column",
															marginBottom: "15px",
														};

														// Style for the buttons
														let buttonStyle = {
															width: "100%",
															padding: "10px",
															backgroundColor: "#007bff",
															color: "white",
															border: "none",
															borderRadius: "5px",
															cursor: "pointer",
															// marginRight: 'auto',
															// marginLeft: 'auto',
															marginBottom: "5%",
														};

														let submitButtonStyle = {
															width: "50%",
															padding: "10px",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															color: "white",
															border: "none",
															borderRadius: "5px",
															cursor: "pointer",
															// marginRight: 'auto',
															// marginLeft: 'auto',
															marginBottom: "5%",
														};

														let closeButtonStyle = {
															width: "50%",
															padding: "10px",
															backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
															color: "white",
															border: "none",
															borderRadius: "5px",
															cursor: "pointer",
															// marginRight: 'auto',
															// marginLeft: 'auto',
															marginBottom: "5%",
														};

														// Edit modal
														if (clonedButton.getAttribute("data-table-action") === "edit") {
															// Create the dialog element
															let editModal = document.createElement("dialog");
															editModal.setAttribute("id", "custom-modal");

															// Apply styles to the modal
															Object.assign(editModal.style, modalStyle);

															// Create content for the modal
															let modalContent = document.createElement("form");

															// Apply styles to the modal content
															Object.assign(modalContent.style, {
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
															});

															// Create a label for the data model input field
															let dataModelLabel = document.createElement("label");
															dataModelLabel.innerHTML = `Edit`;
															dataModelLabel.style.width = "100%";
															dataModelLabel.style.borderBottom = "1px solid gray";
															dataModelLabel.style.lineHeight = "2";
															dataModelLabel.style.fontSize = "1.4vw";

															// Apply styles to the label
															Object.assign(dataModelLabel.style, {
																marginBottom: "10px",
															});

															// Append label to the modal content
															modalContent.appendChild(dataModelLabel);

															// variable only for the reference keyname user input
															let selectedOptions = [];
															// Check if updatedProps.EditableFields is not empty
															if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																// Iterate over EditableFields to create form elements
																for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																	// to handle non-Reference field keynames
																	if (!fieldName.includes("-")) {
																		let fieldLabel = document.createElement("label");
																		fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																		fieldLabel.style.textTransform = "capitalize";
																		modalContent.appendChild(fieldLabel);

																		let inputField = document.createElement("input");
																		inputField.setAttribute("name", fieldName);
																		inputField.value = obj[fieldName]; // to initialize input fields with present values
																		Object.assign(inputField.style, {
																			borderRadius: "5px",
																			border: "1px solid gray",
																			marginBottom: "5%",
																			padding: "1%",
																		});

																		// Set input field type based on field type
																		switch (fieldType.toLowerCase()) {
																			case "string":
																				inputField.setAttribute("type", "text");
																				break;
																			case "boolean":
																				inputField.setAttribute("type", "radio");
																				break;
																			case "number":
																				inputField.setAttribute("type", "number");
																				break;
																			case "date-time":
																				inputField.setAttribute("type", "datetime-local");
																				break;
																			case "time":
																				inputField.setAttribute("type", "time");
																				break;
																			case "file":
																				inputField.setAttribute("type", "file");
																				break;
																			default:
																				inputField.setAttribute("type", "text");
																				break;
																		}
																		modalContent.appendChild(inputField);
																	}

																	// to handle Reference field keynames for example - WebProject1_Address-Sector
																	else if (fieldName.includes("-")) {
																		// variables to be used later in this if-condition
																		let [table, column] = fieldName.split("-");
																		// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																		// create its label
																		let refFieldLabel = document.createElement("label");
																		refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																		refFieldLabel.style.textTransform = "capitalize";
																		modalContent.appendChild(refFieldLabel);

																		// create a drodown for these keynames > its options will be all the values from response from that table
																		let referenceKeynameDropdown = document.createElement("select");
																		referenceKeynameDropdown.setAttribute("name", fieldName);
																		referenceKeynameDropdown.setAttribute("id", fieldName);
																		// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																		Object.assign(referenceKeynameDropdown.style, {
																			borderRadius: "5px",
																			border: "1px solid gray",
																			marginBottom: "5%",
																			padding: "1%",
																		});

																		// populate it with options using the response from its data-model API
																		let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																			headers: {
																				Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																			},
																		});
																		let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																		if (referenceKeynameValueOptionsData) {
																			// setting default option --select--
																			let defaultOption = document.createElement("option");
																			// defaultOption.setAttribute('disabled', 'disabled');
																			defaultOption.innerHTML = "--select--";
																			referenceKeynameDropdown.appendChild(defaultOption);

																			// loop over the response to fill the options of its reference table
																			referenceKeynameValueOptionsData.forEach((optObj) => {
																				let option = document.createElement("option");
																				option.value = optObj[`${column}`];
																				option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																				option.innerHTML = optObj[`${column}`];
																				// to initialize input fields with present values
																				if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																					option.setAttribute("selected", true);
																				}
																				referenceKeynameDropdown.appendChild(option);
																			});
																		}

																		referenceKeynameDropdown.addEventListener("change", function (e) {
																			let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																			// console.log('Selected value:', selectedOptionFromDropdown.value);
																			// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																			// get the reference ID of the table row from main obj to update
																			let refTableId;
																			let tableName = table.split("_")[1];
																			if (obj[tableName] !== null) {
																				refTableId = obj[tableName];
																			}

																			selectedOptions.push({
																				table,
																				column,
																				refTableId,
																				value: selectedOptionFromDropdown.value,
																				"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																			});
																		});

																		modalContent.appendChild(referenceKeynameDropdown);
																	}
																}
															}

															console.log("+bu, bubt+", bu, bubt);

															let buttonContainer = document.createElement("div");
															buttonContainer.setAttribute("class", "modal-button-container");
															buttonContainer.style.display = "flex";
															buttonContainer.style.alignItems = "center";
															buttonContainer.style.justifyContent = "space-between";
															buttonContainer.style.columnGap = "5%";
															buttonContainer.style.width = "100%";
															buttonContainer.style.marginTop = "5%";

															// Create a submit button for the form
															let submitButton = document.createElement("button");
															submitButton.setAttribute("class", "table-submit-button");
															submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
															submitButton.innerHTML = "Submit";

															// Apply styles to the button
															Object.assign(submitButton.style, submitButtonStyle);

															submitButton.addEventListener("click", function (e) {
																e.preventDefault(); // Prevent default form action
																// Handle form submission here
																console.log("Form submitted!", obj);

																// Create an object to store the values of input fields
																let values = {};

																// // Add default input field values to the values object
																// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																function replaceSubstringWithTableData(url) {
																	return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																}

																// Add default input field values to the values object
																values["DataModel"] = updatedProps.customUrlValue 
																	? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	: replaceSubstringWithTableData(bu);
																	
																values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Initialize an empty FormData object to get the form's input fields values
																let formData = new FormData(modalContent);
																let formValues = {};
																for (const [key, value] of formData.entries()) {
																	formValues[key] = value;
																}

																// Create the to_insert object with the keynames from updatedProps.EditableFields
																// and their corresponding values from formValues
																let to_insert = {};
																Object.keys(updatedProps.EditableFields).forEach((key) => {
																	if (formValues[key] !== undefined && !key.includes("-")) {
																		// Check if the form has the field
																		to_insert[key] = formValues[key];
																	}

																	// re-attach the reference id to PUT call else it will set the references to null
																	if (formValues[key] !== undefined && key.includes("-")) {
																		let x = key.split("-");
																		let table = x[0].split("_")[1];
																		if (obj[table] !== null) {
																			to_insert[table] = obj[table];
																		}
																	}
																});

																// console.log("+to_insert+", to_insert);
																// console.log("+selectedOptions+", selectedOptions);

																// logic to update all table cells the edit button is editing without refreshing
																for (const key in to_insert) {
																	let columns = [];
																	if (updatedProps[`HeaderField_${i}`]) {
																		Object.keys(updatedProps).map((hF_i) => {
																			if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																		});
																	} else {
																		Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																			if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																		});
																	}

																	// console.log('+columns+', columns);

																	columns.forEach(idx => {
																		let td = tr.getElementsByTagName('td')[idx];

																		let valueToUpdateCellWith = to_insert[key];
																		// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																		// Find the first text node
																		const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																		if (textNode) {
																			textNode.nodeValue = valueToUpdateCellWith;
																		} else {
																			// If no text node exists, create one
																			td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																		}
																	});
																}

																// Fetch call (to non-reference table) can be made here using the input field values
																// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																	method: "PUT",
																	headers: {
																		"Content-Type": "application/json",
																		Authorization: `Bearer ${values.BearerToken}`,
																	},
																	body: JSON.stringify({
																		to_insert: to_insert,
																	}),
																})
																	.then((response) => response.json())
																	.then((data) => console.log(data));

																// Fetch call (for reference table)
																if (selectedOptions.length > 0) {
																	selectedOptions.forEach((data) => {
																		// make api calls per object
																		let requestBody = {};
																		requestBody[data.column] = data.value; // Assign dynamic key-value pair
																		fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																			method: "PUT",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																			body: JSON.stringify({
																				to_insert: requestBody,
																			}),
																		})
																			.then((response) => response.json())
																			.then((data) => console.log(data));
																	});
																}

																// Close the modal when the form is submitted
																editModal.close();

																// clear the selectedOptions for next turn
																selectedOptions = [];

																// // giving a delay in reload so that it doesn't change the url in the broswer tab
																// setTimeout(function () {
																// 	window.top.location.reload();
																// }, 2000);
															});

															// Create a close button for the modal
															let closeButton = document.createElement("button");
															closeButton.setAttribute("class", "table-close-button");
															closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
															closeButton.innerHTML = "Close";

															// Apply styles to the button
															Object.assign(closeButton.style, closeButtonStyle);

															closeButton.addEventListener("click", function (event) {
																event.preventDefault(); // Prevent default behavior of the click event
																editModal.close(); // Close the modal when the close button is clicked
															});

															// Append content, submit button, and close button to the modal
															buttonContainer.appendChild(submitButton);
															buttonContainer.appendChild(closeButton);
															modalContent.appendChild(buttonContainer);
															editModal.appendChild(modalContent);

															// Append the modal to the document
															document.body.appendChild(editModal);

															// Show the modal
															editModal.showModal();
														}
														// Delete modal
														else if (clonedButton.getAttribute("data-table-action") === "delete") {
															// Create the dialog element
															let deleteModal = document.createElement("dialog");
															deleteModal.setAttribute("id", "delete-modal");

															// Apply styles to the modal
															Object.assign(deleteModal.style, modalStyle);

															// Create content for the modal
															let modalContent = document.createElement("form");

															// Apply styles to the modal content
															Object.assign(modalContent.style, {
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
															});

															// Create a label for the data model input field
															let dataModelLabel = document.createElement("label");
															dataModelLabel.innerHTML = `Delete`;
															dataModelLabel.style.width = "100%";
															dataModelLabel.style.borderBottom = "1px solid gray";
															dataModelLabel.style.lineHeight = "2";
															dataModelLabel.style.fontSize = "1.4vw";

															// Apply styles to the label
															Object.assign(dataModelLabel.style, {
																marginBottom: "10px",
															});

															// Append label to the modal content
															modalContent.appendChild(dataModelLabel);

															// // Create an input field for data model with a default value
															// let dataModelInputField = document.createElement("input");
															// dataModelInputField.setAttribute('type', 'text');
															// dataModelInputField.setAttribute('name', 'DataModel');
															// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
															// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

															// // Apply styles to the input fields
															// Object.assign(dataModelInputField.style, inputFieldStyle);

															// modalContent.appendChild(dataModelInputField);

															// // Create an input field for bearer token with a default value
															// let bearerTokenInputField = document.createElement("input");
															// bearerTokenInputField.setAttribute('type', 'text');
															// bearerTokenInputField.setAttribute('name', 'BearerToken');
															// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
															// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

															// // Apply styles to the input fields
															// Object.assign(bearerTokenInputField.style, inputFieldStyle);

															// modalContent.appendChild(bearerTokenInputField);

															// Create a paragraph tag for confirmation message
															let confirmationMessage = document.createElement("p");
															confirmationMessage.style.marginBottom = "5%";
															confirmationMessage.style.fontSize = "1vw";
															confirmationMessage.innerHTML = "Are you sure?";

															// Append confirmation message to the modal content
															modalContent.appendChild(confirmationMessage);

															let buttonContainer = document.createElement("div");
															buttonContainer.setAttribute("class", "modal-button-container");
															buttonContainer.style.display = "flex";
															buttonContainer.style.alignItems = "center";
															buttonContainer.style.justifyContent = "space-between";
															buttonContainer.style.columnGap = "5%";
															buttonContainer.style.width = "100%";

															// Create a submit button for the form
															let submitButton = document.createElement("button");
															submitButton.setAttribute("class", "table-delete-yes-button");
															submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
															submitButton.innerHTML = "Yes";

															// Apply styles to the "Yes" button
															Object.assign(submitButton.style, submitButtonStyle);

															submitButton.addEventListener("click", async function (e) {
																e.preventDefault();
																// Handle form submission here
																console.log("Form submitted!");

																// Create an object to store the values of input fields
																let values = {};

																// // Add default input field values to the values object
																// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																function replaceSubstringWithTableData(url) {
																	return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																}

																// Add default input field values to the values object
																values["DataModel"] = updatedProps.customUrlValue 
																	? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	: replaceSubstringWithTableData(bu);
																	
																values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																// Use the values in the fetch call or other logic
																console.log("Input field values:", values);

																// Make the fetch call to delete the item
																let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																	method: "DELETE",
																	headers: {
																		"Content-Type": "application/json",
																		Authorization: `Bearer ${values.BearerToken}`,
																	},
																});
																// .then(response => {
																//     if (response.ok) {
																//         console.log('Item deleted successfully');
																//     } else {
																//         console.error('Failed to delete item');
																//     }
																// });

																let responseData = response.json();
																if (responseData.ok) {
																	console.log("Item deleted successfully");
																} else {
																	console.error("Failed to delete item");
																}

																// Close the modal when the form is submitted
																deleteModal.close();

																// giving a delay in reload so that it doesn't change the url in the broswer tab
																setTimeout(function () {
																	window.top.location.reload();
																}, 2000);
															});

															// Create a close button for the modal
															let closeButton = document.createElement("button");
															closeButton.setAttribute("class", "table-delete-no-button");
															closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
															closeButton.innerHTML = "No";

															// Apply styles to the button
															Object.assign(closeButton.style, closeButtonStyle);

															closeButton.addEventListener("click", function (e) {
																e.preventDefault();
																deleteModal.close(); // Close the modal when the close button is clicked
															});

															// Append content, submit button, and close button to the modal
															buttonContainer.appendChild(submitButton);
															buttonContainer.appendChild(closeButton);
															modalContent.appendChild(buttonContainer);
															deleteModal.appendChild(modalContent);

															// Append the modal to the document
															document.body.appendChild(deleteModal);

															// Show the modal
															deleteModal.showModal();
														}
													});

													// to hide the header buttons after they have been cloned
													j.style.display = "none";
												});
											}
											// console.log("headerActionButtons:", headerActionButtons);
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating input_file buttons
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let headerInputFileButtons;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											headerInputFileButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_file_container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (headerInputFileButtons) {
												if (headerInputFileButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												// for (let j = 0; j < headerInputFileButtons.length; j++) {
												// 	// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
												// 	let clonedInputFileButton = headerInputFileButtons[j].cloneNode(true);

												// 	// Copy the styles from the original button to the cloned button and its inner elements
												// 	copyStyles(headerInputFileButtons[j], clonedInputFileButton);

												// 	// changing the clone's IDs so that they are present and available individually in LB and in download
												// 	clonedInputFileButton.setAttribute('id', `input_file_container_table${id}_row${idx}_column${i}_${j}`);
												// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].setAttribute('id', `input_file_label_table${id}_row${idx}_column${i}_${j}`);
												// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].getElementsByTagName('p')[0].setAttribute('id', `input_file_label_p_table${id}_row${idx}_column${i}_${j}`);

												// 	td.appendChild(clonedInputFileButton);

												// 	let headerInputFileClickHandler = function(event) {
												// 		console.log('input_file_label clicked', 'saving data in session storage');
												// 		// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

												// 		// this registers all stellar button clicks on LS
												// 		// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
												// 		// this registers only by the individuals in the column header
												// 		localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, JSON.stringify({
												// 			'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
												// 			tableId: `${id}`,
												// 			rowId: `custom_table_tbody_row${idx}_table${id}`,
												// 			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												// 			buttonId: `${clonedInputFileButton.getAttribute('id')}`,
												// 		}));

												// 		console.log('clonedInputFileButton++', clonedInputFileButton);

												// 		let inputFile = document.createElement('input');
												//         inputFile.type = 'file';
												//         inputFile.style.display = 'none';

												//         inputFile.addEventListener('change', async function (event) {
												//               let file = inputFile.files[0];
												//               console.log('this++', this);
												//               console.log('file is:', file);

												//               let formData = new FormData();
												//               formData.append('file', file);

												//               try {
												//                   const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
												//                       method: 'POST',
												//                       headers: {
												//                           // 'Content-Type': 'multipart/form-data',
												//                       },
												//                       body: formData,
												//                   });

												//                   if (response.ok) {
												//                       const data = await response.json();
												//                       console.log('File uploaded:', data);
												//                       clonedInputFileButton.setAttribute('data_file_url', data["fileLocation"]);

												//                     // Retrieve the object from local storage
												// 			        let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j}`)) || {};

												// 			        // Add the data_file_url and its value to the object
												// 			        clickedFileData.data_file_url = data["fileLocation"];

												// 			        // Convert the modified object back to a JSON string
												// 			        let updatedClickedFileData = JSON.stringify(clickedFileData);

												// 			        // Update the local storage with the modified JSON string
												// 			        localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, updatedClickedFileData);
												//                   } else {
												//                       console.error('Error uploading file:', response.statusText);
												//                   }

												//                   clonedInputFileButton.onclick = null;
												//               } catch (error) {
												//                   console.error('Error uploading file:', error);
												//               } finally {
												//               	console.log('after 1')
												//                   // Remove the inputFile element after API call completes
												//                   if (inputFile && inputFile.parentNode) {
												//                   	console.log('after 2')
												//                       inputFile.parentNode.removeChild(inputFile);
												//                   }
												//               }
												//         });

												//         clonedInputFileButton.appendChild(inputFile);
												//         inputFile.click();
												//         console.log('clicked', clonedInputFileButton);
												// 	};

												// 	// adding click event on stellar-button from the table components
												// 	// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
												// 	clonedInputFileButton.onclick = headerInputFileClickHandler;

												// 	// to hide the header buttons after they have been cloned
												// 	headerInputFileButtons[j].style.display = "none";
												// }

												Array.from(headerInputFileButtons).forEach(async (j, j_idx) => {
													// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
													let clonedInputFileButton = j.cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(j, clonedInputFileButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedInputFileButton.setAttribute("id", `input_file_container_table${id}_row${idx}_column${i}_${j_idx}`);
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].setAttribute("id", `input_file_label_table${id}_row${idx}_column${i}_${j_idx}`);
													if (clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0]) {
														clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0].setAttribute("id", `input_file_label_p_table${id}_row${idx}_column${i}_${j_idx}`);
													}

													td.appendChild(clonedInputFileButton);

													let headerInputFileClickHandler = function (event) {
														console.log("input_file_label clicked", "saving data in session storage");
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

														// this registers all stellar button clicks on LS
														// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
														// this registers only by the individuals in the column header
														localStorage.setItem(
															`clicked_input_file_container_table${id}_column${i}_${j_idx}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedInputFileButton.getAttribute("id")}`,
															}),
														);

														// console.log('clonedInputFileButton++', clonedInputFileButton);

														let inputFile = document.createElement("input");
														inputFile.type = "file";
														inputFile.style.display = "none";

														inputFile.addEventListener("change", async function (event) {
															let file = inputFile.files[0];
															console.log("this++", this);
															console.log("file is:", file);

															let formData = new FormData();
															formData.append("file", file);

															try {
																const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
																	method: "POST",
																	headers: {
																		// 'Content-Type': 'multipart/form-data',
																	},
																	body: formData,
																});

																if (response.ok) {
																	const data = await response.json();
																	console.log("File uploaded:", data);
																	td.setAttribute("data_file_url", data["fileLocation"]);

																	// Retrieve the object from local storage
																	let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`)) || {};

																	// Add the data_file_url and its value to the object
																	clickedFileData.data_file_url = data["fileLocation"];

																	// Convert the modified object back to a JSON string
																	let updatedClickedFileData = JSON.stringify(clickedFileData);

																	// Update the local storage with the modified JSON string
																	localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`, updatedClickedFileData);
																} else {
																	console.error("Error uploading file:", response.statusText);
																}
															} catch (error) {
																console.error("Error uploading file:", error);
															} finally {
																// Remove the inputFile element after API call completes
																if (inputFile && inputFile.parentNode) {
																	inputFile.parentNode.removeChild(inputFile);
																}
															}
														});

														clonedInputFileButton.appendChild(inputFile);
														inputFile.click();

														if (inputFile && inputFile.parentNode) {
															inputFile.parentNode.removeChild(inputFile);
														}
													};

													// adding click event on stellar-button from the table components
													// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].onclick = headerInputFileClickHandler;

													// to hide the header buttons after they have been cloned
													j.style.display = "none";
												});
											}
											// }
											// console.log("headerInputFileButtons:", headerInputFileButtons);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating radio_regular_form
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let radioButtonGroups;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											radioButtonGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("radio_regular_form");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (radioButtonGroups) {
												if (radioButtonGroups.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(radioButtonGroups).forEach((radioButtonGroup, j) => {
													let clonedRadioButtonGroup = radioButtonGroup.cloneNode(true);
													// Copy styles from the original to the cloned button
													copyStyles2(radioButtonGroup, clonedRadioButtonGroup);

													// Change IDs and append cloned radio button groups to the container
													clonedRadioButtonGroup.setAttribute("id", `radio_regular_form_table${id}_row${idx}_column${i}_${j}`);

													// Add event listener to each radio button in the group
													clonedRadioButtonGroup.querySelectorAll('input[type="radio"]').forEach((radioButton) => {
														radioButton.addEventListener("click", (event) => {
															// Get the selected radio button's value and id
															const selectedValue = event.target.value;
															const selectedId = event.target.id;
															const selectedDataDbId = event.target.getAttribute("data_db_id") ? event.target.getAttribute("data_db_id") : "";

															// Store the selected value, id, and other relevant data in local storage
															localStorage.setItem(
																`clicked_radio_regular_form_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	radioButtonId: `${selectedId}`,
																	selectedValue: `${selectedValue}`,
																	selectedDataDbId: `${selectedDataDbId}`,
																}),
															);

															// You can also perform other actions based on the selected radio button
															// For example, update UI, make API calls, etc.
														});
													});

													// Append the container to the table cell
													td.appendChild(clonedRadioButtonGroup);
												});
											}

											// Hide the original radio button groups in the header column
											Array.from(radioButtonGroups).forEach((radioButtonGroup) => {
												radioButtonGroup.style.display = "none";
											});
											// }
											// console.log("radioButtonGroups:", radioButtonGroups);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating checkbox_regular_form
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let checkboxGroups;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											checkboxGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (checkboxGroups) {
												if (checkboxGroups.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(checkboxGroups).forEach((checkboxGroup, j) => {
													let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
													// Copy styles from the original to the cloned checkbox group
													copyStyles2(checkboxGroup, clonedCheckboxGroup);

													// Change IDs and append cloned checkbox groups to the container
													clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_table${id}_row${idx}_column${i}_${j}`);

													// Add event listener to each checkbox in the group
													clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
														checkbox.addEventListener("change", (event) => {
															// Initialize an array to hold the values of checked checkboxes
															let selectedValues = [];
															let selectedIds = [];
															let selectedDataDbIds = [];

															// Iterate over all checkboxes to find the checked ones
															clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
																// If a checkbox is checked, add its value and id to the respective arrays
																if (checkbox.checked) {
																	selectedValues.push(checkbox.value);
																	selectedIds.push(checkbox.id);
																	selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
																}
															});

															// Store the array of selected values and ids in local storage
															localStorage.setItem(
																`clicked_checkbox_regular_form_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	selectedValues: selectedValues,
																	selectedIds: selectedIds,
																	selectedDataDbIds: selectedDataDbIds,
																}),
															);

															// You can also perform other actions based on the selected checkboxes
															// For example, update UI, make API calls, etc.
														});
													});

													// Append the container to the table cell
													td.appendChild(clonedCheckboxGroup);
												});

												// Hide the original radio button groups in the header column
												Array.from(checkboxGroups).forEach((checkboxGroup) => {
													checkboxGroup.style.display = "none";
												});
											}
											// }
											// console.log("checkboxGroups:", checkboxGroups);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating checkbox_regular_form_new
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let checkboxGroupsV2;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											checkboxGroupsV2 = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form_new");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (checkboxGroupsV2) {
												if (checkboxGroupsV2.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(checkboxGroupsV2).forEach((checkboxGroup, j) => {
													let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
													// Copy styles from the original to the cloned checkbox group
													copyStyles2(checkboxGroup, clonedCheckboxGroup);

													// Change IDs and append cloned checkbox groups to the container
													clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_v2_table${id}_row${idx}_column${i}_${j}`);

													// Add event listener to each checkbox in the group
													clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
														checkbox.addEventListener("change", (event) => {
															// Initialize an array to hold the values of checked checkboxes
															let selectedValues = [];
															let selectedIds = [];
															let selectedDataDbIds = [];

															// Iterate over all checkboxes to find the checked ones
															clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
																// If a checkbox is checked, add its value and id to the respective arrays
																if (checkbox.checked) {
																	selectedValues.push(checkbox.value);
																	selectedIds.push(checkbox.id);
																	selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
																}
															});

															// Store the array of selected values and ids in local storage
															localStorage.setItem(
																`clicked_checkbox_regular_form_v2_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	selectedValues: selectedValues,
																	selectedIds: selectedIds,
																	selectedDataDbIds: selectedDataDbIds,
																}),
															);

															// You can also perform other actions based on the selected checkboxes
															// For example, update UI, make API calls, etc.
														});
													});

													// Append the container to the table cell
													td.appendChild(clonedCheckboxGroup);
												});

												// Hide the original radio button groups in the header column
												Array.from(checkboxGroupsV2).forEach((checkboxGroup) => {
													checkboxGroup.style.display = "none";
												});
											}
											// }
											// console.log("checkboxGroupsV2:", checkboxGroupsV2);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating input_select
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let dropdownContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											dropdownContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_select_container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (dropdownContainers) {
												if (dropdownContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(dropdownContainers).forEach((dropdownContainer, j) => {
													let dropdown = dropdownContainer.getElementsByTagName("select")[0];
													let dropdownClone = dropdown.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(dropdown, dropdownClone);

													// Change IDs and append cloned dropdowns to the container
													dropdownClone.setAttribute("id", `input_select_table${id}_row${idx}_column${i}_${j}`);
													dropdownClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const selectedValue = event.target.value;
														const selectedOption = event.target.options[event.target.selectedIndex];
														const selectedId = selectedOption.id;

														// Get the data-db-id attribute value of the selected option
														const selectedDataDbId = selectedOption.getAttribute("data_db_id") ? selectedOption.getAttribute("data_db_id") : "";

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_input_select_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValue: `${selectedValue}`,
																selectedId: `${selectedId}`,
																selectedDataDbId: `${selectedDataDbId}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(dropdownClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(dropdownContainers).forEach((dropdownContainer) => {
													dropdownContainer.style.display = "none";
												});
											}
											// }
											// console.log("dropdownContainers:", dropdownContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_text_field
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputTextContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (inputTextContainers) {
												if (inputTextContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
													let inputText = inputTextContainer.getElementsByTagName("input")[0];
													let inputTextClone = inputText.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputText, inputTextClone);

													// Change IDs and append cloned dropdowns to the container
													inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
													inputTextClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_form_text_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(inputTextClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputTextContainers).forEach((inputTextContainer) => {
													inputTextContainer.style.display = "none";
												});
											}
											// }
											// console.log("inputTextContainers:", inputTextContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_number_field
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputNumberContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (inputNumberContainers) {
												if (inputNumberContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
													let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
													let inputNumberClone = inputNumber.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputNumber, inputNumberClone);

													// Change IDs and append cloned dropdowns to the container
													inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
													inputNumberClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_form_number_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(inputNumberClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
													inputNumberContainer.style.display = "none";
												});
											}
											// }
											// console.log("inputNumberContainers:", inputNumberContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating send email buttons
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let sendEmailButtons;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											sendEmailButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("send-email-button-container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (sendEmailButtons) {
												if (sendEmailButtons.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(sendEmailButtons).forEach((j, j_idx) => {
													// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
													let clonedEmailButton = j.cloneNode(true);

													// Copy the styles from the original button to the cloned button and its inner elements
													copyStyles(j, clonedEmailButton);

													// changing the clone's IDs so that they are present and available individually in LB and in download
													clonedEmailButton.setAttribute("id", `send_email_button_container_table${id}_row${idx}_column${i}_${j_idx}`);
													clonedEmailButton.getElementsByClassName("send-email-button")[0].setAttribute("id", `send_email_btn_table${id}_row${idx}_column${i}_${j_idx}`);
													if (clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0]) {
														clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0].setAttribute("id", `send_email_btn_p_table${id}_row${idx}_column${i}_${j_idx}`);
													}

													td.appendChild(clonedEmailButton);

													const sendEmailHandler = async function (sendEmailButtonContainer) {
														let selectedFile;
														// reason - because this script was getting called multiple times, the dialog element was getting added multiple times so this code will be removing all the previously appended ones and then create a new one on-the-go
														if (sendEmailButtonContainer) {
															let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
															modalAlreadyExists.forEach((modal) => {
																console.log("removing modal 2", modal);
																modal.remove();
															});

															// // fetch filter call for content
															// let filteredResponse = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${extractTableName(updatedProps.customUrlValue)}`, {
															// 	method: "POST",
															// 	headers: {
															// 		'Content-Type': "application/json",
															// 		Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
															// 	},
															// 	body: JSON.stringify({
															// 	    filterData: {
															// 	        _id: `${obj[updatedProps.DataModelObjIdKeyname]}`,
															// 	    }
															// 	}),
															// });

															// let filteredResponseData = await filteredResponse.json();

															// Get the keys from updatedProps.HeaderFields object and from HTML attributes
															const headerFieldKeys = Object.keys(updatedProps.HeaderFields);
															const htmlAttributeKeys = Array.from(customTableContainer.attributes)
																.filter((attr) => attr.name.includes("headerfield_"))
																.map((attr) => attr.value);

															console.log("htmlAttributeKeys", htmlAttributeKeys);

															// Combine the keys from both sources
															const allKeys = [...headerFieldKeys, ...htmlAttributeKeys];

															// Accumulate all key-value pairs
															const keyValuePairs = [];

															// Get the values corresponding to the keys from the obj object
															htmlAttributeKeys.forEach((key) => {
																let value;
																if (/:/.test(key)) {
																	// If the key contains a colon (indicating nested properties), split it
																	const [mainKey, subKey] = key.split(/\s*:\s*/);
																	// Check if the main key exists in obj
																	if (obj[`${mainKey}_data`]) {
																		// Check if the main key points to an object
																		if (typeof obj[`${mainKey}_data`] === "object" && obj[`${mainKey}_data`][subKey]) {
																			value = obj[`${mainKey}_data`][subKey];
																			keyValuePairs.push(`${subKey}: ${value}`);
																		}
																	}
																} else {
																	value = obj[key];
																	keyValuePairs.push(`${key}: ${value}`);
																}
																// if (value) {
																//     keyValuePairs.push(`${key}: ${value}`);
																// }
															});

															console.log("allKeys, keyValuePairs", allKeys, keyValuePairs);

															// Join all key-value pairs into a single string
															const filteredKeyValuePairs = keyValuePairs.join("\n");

															console.log("allKeys, filteredKeyValuePairs", allKeys, filteredKeyValuePairs);

															// Create a dialog modal with styling
															const dynamicEmailSendingModal = document.createElement("dialog");
															dynamicEmailSendingModal.setAttribute("class", `custom-email-modal`);
															dynamicEmailSendingModal.setAttribute("id", `custom-email-modal-${sendEmailButtonContainer.id}`);
															Object.assign(dynamicEmailSendingModal.style, {
																margin: "auto",
																width: "35vw",
																overflow: "auto",
																backgroundColor: "white",
																boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																border: "1px solid #ccc",
																borderRadius: "20px",
																zIndex: "1000",
																padding: "0 0 1% 0",
															});

															// modal UI header & footer
															let modalHeader = document.createElement("div");
															Object.assign(modalHeader.style, {
																// backgroundColor: "#1991E7",
																backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																paddingTop: "0.8%",
																paddingBottom: "0.8%",
																paddingLeft: "5%",
																paddingRight: "5%",
																color: "white",
																fontSize: "1vw",
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
															});
															modalHeader.innerHTML = "Compose Message";

															const modalFooter = document.createElement("div");
															Object.assign(modalFooter.style, {
																backgroundColor: "transparent",
																paddingTop: "0.8%",
																paddingBottom: "0.8%",
																paddingLeft: "5%",
																paddingRight: "5%",
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
															});

															// Create a form element
															const emailForm = document.createElement("form");
															Object.assign(emailForm.style, {
																padding: "3% 6%",
																display: "flex",
																flexDirection: "column",
															});

															// Check if EmailFromDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailFromDynamicCheckbox) {
															const emailFromDiv = document.createElement("div");
															Object.assign(emailFromDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailFromLabel = document.createElement("label");
															emailFromLabel.innerHTML = "From";
															// Create an input email field for the sender's email address with styling
															const emailFromField = document.createElement("input");
															Object.assign(emailFromField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailFromField, {
																type: "email",
																id: `emailFrom-${sendEmailButtonContainer.id}`,
																name: "emailFrom",
																placeholder: "Sender's Email Address",
															});
															// visiblity will be hidden because this field is not actually required and it needs to occupy same space for UI alignment purposes
															const emailFromRequiredFieldLabel = document.createElement("span");
															emailFromRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailFromRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
																visibility: "hidden",
															});

															// Append the email input field to the form
															emailFromDiv.appendChild(emailFromLabel);
															emailFromDiv.appendChild(emailFromField);
															emailFromDiv.appendChild(emailFromRequiredFieldLabel);
															emailForm.appendChild(emailFromDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if EmailToDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailToDynamicCheckbox) {
															const emailToDiv = document.createElement("div");
															Object.assign(emailToDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailToLabel = document.createElement("label");
															emailToLabel.innerHTML = "To";
															// Create an input email field for the receiver's email address with styling
															const emailToField = document.createElement("input");
															Object.assign(emailToField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailToField, {
																type: "text",
																id: `emailTo-${sendEmailButtonContainer.id}`,
																name: "emailTo",
																placeholder: "Receiver's Email Addresses (comma separated)",
															});
															const emailToRequiredFieldLabel = document.createElement("span");
															emailToRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailToRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
															});

															// Append the email input field to the form
															emailToDiv.appendChild(emailToLabel);
															emailToDiv.appendChild(emailToField);
															emailToDiv.appendChild(emailToRequiredFieldLabel);
															emailForm.appendChild(emailToDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if EmailSubjectDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailSubjectDynamicCheckbox) {
															const emailSubjectDiv = document.createElement("div");
															Object.assign(emailSubjectDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailSubjectLabel = document.createElement("label");
															emailSubjectLabel.innerHTML = "Subject";
															// Create an input email field for the receiver's email address with styling
															const emailSubjectField = document.createElement("input");
															Object.assign(emailSubjectField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailSubjectField, {
																type: "text",
																id: `emailSubject-${sendEmailButtonContainer.id}`,
																name: "emailSubject",
																placeholder: "Email's subject",
															});
															const emailSubjectRequiredFieldLabel = document.createElement("span");
															emailSubjectRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailSubjectRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
															});

															// Append the email input field to the form
															emailSubjectDiv.appendChild(emailSubjectLabel);
															emailSubjectDiv.appendChild(emailSubjectField);
															emailSubjectDiv.appendChild(emailSubjectRequiredFieldLabel);
															emailForm.appendChild(emailSubjectDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if EmailBodyDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.EmailBodyDynamicCheckbox) {
															const emailBodyDiv = document.createElement("div");
															Object.assign(emailBodyDiv.style, {
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																columnGap: "5%",
															});

															const emailBodyLabel = document.createElement("label");
															emailBodyLabel.innerHTML = "Body";
															// Create an input email field for the receiver's email address with styling
															const emailBodyField = document.createElement("textarea");
															Object.assign(emailBodyField.style, {
																marginBottom: "10px",
																padding: "5px",
																width: "80%",
																borderRadius: "5px",
																backgroundColor: "transparent",
																boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
															});
															Object.assign(emailBodyField, {
																rows: "5",
																cols: "10",
																id: `emailBody-${sendEmailButtonContainer.id}`,
																name: "emailBody",
																placeholder: "Email's body",
															});
															const emailBodyRequiredFieldLabel = document.createElement("span");
															emailBodyRequiredFieldLabel.innerHTML = "(required)";
															Object.assign(emailBodyRequiredFieldLabel.style, {
																color: "red",
																fontStyle: "italic",
																fontSize: "0.9vw",
																marginLeft: "1%",
															});

															emailBodyField.value = filteredKeyValuePairs;

															// Append the email input field to the form
															emailBodyDiv.appendChild(emailBodyLabel);
															emailBodyDiv.appendChild(emailBodyField);
															emailBodyDiv.appendChild(emailBodyRequiredFieldLabel);
															emailForm.appendChild(emailBodyDiv);
															// }
															// // else take directly from props
															// else {}

															// Check if FileAttachmentDynamicCheckbox is true and then create an input field for it in the modal
															// if (props.FileAttachmentDynamicCheckbox) {
															// Create an input email field for the receiver's email address with styling
															const fileAttachmentField = document.createElement("input");
															Object.assign(fileAttachmentField.style, {
																// marginBottom: "10px",
																// padding: "5px",
																// width: "80%",
																// borderRadius: "5px",
																// backgroundColor: "transparent",
																// boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
																display: "none",
															});
															Object.assign(fileAttachmentField, {
																type: "file",
																id: `fileAttachment-${sendEmailButtonContainer.id}`,
																name: "fileAttachment",
															});

															// Add event listener for the file input field change event
															fileAttachmentField.addEventListener("change", (event) => {
																selectedFile = event.target.files[0];
																console.log("Selected file:", selectedFile);
																// You can do further processing with the selected file here
															});

															// Create a label for the file input button
															const fileAttachmentLabel = document.createElement("label");
															fileAttachmentLabel.setAttribute("for", "fileAttachmentInput"); // Set the 'for' attribute to match the input field's id
															fileAttachmentLabel.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16"><path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/></svg>';
															Object.assign(fileAttachmentLabel.style, {
																cursor: "pointer",
															});

															// Attach click event to the label
															fileAttachmentLabel.addEventListener("click", () => {
																fileAttachmentField.click();
															});

															// Append the email input field to the form
															modalFooter.appendChild(fileAttachmentLabel);
															modalFooter.appendChild(fileAttachmentField);
															// }
															// // else take directly from props
															// else {}

															// Create a submit button
															const submitButton = document.createElement("button");
															Object.assign(submitButton.style, {
																// marginBottom: "3%",
																// marginRight: "4%",
																alignSelf: "flex-end",
																padding: "1.5% 3%",
																border: "none",
																borderRadius: "25px",
																// backgroundColor: "#007bff",
																backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																color: "white",
																cursor: "pointer",
															});
															submitButton.textContent = "Send";
															submitButton.type = "submit";

															// Add event listener for form submission
															submitButton.addEventListener("click", async (event) => {
																event.preventDefault(); // Prevent form submission

																// Get the entered email values
																const emailFromValue = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`).value : "";
																let emailToValue = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`).value : "";
																emailToValue = emailToValue.includes(",") ? emailToValue.split(/\s*,\s*/) : emailToValue;
																const emailSubjectValue = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`).value : "";
																const emailBodyValue = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`).value : "";
																const fileAttachmentValue = emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`).value : "";

																// to preserve line breaks in email body
																const formattedEmailBodyValue = emailBodyValue.replace(/\n/g, "<br>");

																console.log("form values", emailFromValue, emailToValue, emailSubjectValue, emailBodyValue, formattedEmailBodyValue, fileAttachmentValue);
																console.log("selectedFile", selectedFile);

																// Create a FormData object
																const formData = new FormData();

																// Append form data to FormData object
																formData.append("emailFrom", emailFromValue);
																formData.append("emailTo", emailToValue);
																formData.append("emailSubject", emailSubjectValue);
																formData.append("emailBody", formattedEmailBodyValue);
																formData.append("fileAttachment", selectedFile);

																// Make a fetch call to the API with the entered email address
																try {
																	// send email if email - to, subject, body are provided
																	if (emailToValue !== "" && emailSubjectValue !== "" && emailBodyValue !== "") {
																		const response = await fetch(`${updatedProps.API_HOST}user/custom_email`, {
																			method: "POST",
																			body: formData,
																		});

																		if (response.ok) {
																			console.log("Email sent successfully!");
																			showNotification(true, "Email sent successfully!");
																		} else {
																			console.error("Failed to send email.");
																			showNotification(false, "Failed to send email. Please check the provided values.");
																		}
																	}
																} catch (error) {
																	console.error("Error sending email:", error);
																	showNotification(false, "Error sending email. Please check the provided values.");
																}

																// Close the modal after form submission
																dynamicEmailSendingModal.close();
															});

															// Create a close button
															const closeButton = document.createElement("button");
															closeButton.setAttribute("class", "custom-email-modal-close");
															closeButton.setAttribute("id", `custom-email-modal-close-${sendEmailButtonContainer.id}`);
															Object.assign(closeButton.style, {
																border: "none",
																borderRadius: "3px",
																backgroundColor: "transparent",
																color: "white",
																cursor: "pointer",
															});
															closeButton.textContent = "X";
															closeButton.type = "button";

															// Add event listener for closing the modal
															closeButton.addEventListener("click", () => {
																dynamicEmailSendingModal.close();
															});

															// Append the form and close button to the modal
															dynamicEmailSendingModal.appendChild(modalHeader);
															dynamicEmailSendingModal.appendChild(emailForm);
															modalHeader.appendChild(closeButton);
															modalFooter.appendChild(submitButton);
															dynamicEmailSendingModal.appendChild(modalFooter);

															// Append the modal to the document body
															document.body.appendChild(dynamicEmailSendingModal);

															// Show the modal
															dynamicEmailSendingModal.showModal();
														}
													};

													// adding click event on stellar-button from the table components
													clonedEmailButton.getElementsByClassName("send-email-button")[0].addEventListener("click", function (event) {
														console.log("send-email-button clicked", "saving data in session storage");
														// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

														// this registers all stellar button clicks on LS
														// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
														// this registers only by the individuals in the column header
														localStorage.setItem(
															`clicked_send_email_button_container_table${id}_column${i}_${j_idx}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																buttonId: `${clonedEmailButton.getAttribute("id")}`,
															}),
														);

														sendEmailHandler(clonedEmailButton);
													});

													// to hide the header buttons after they have been cloned
													j.style.display = "none";
												});
											}
											// }
											// console.log("sendEmailButtons:", sendEmailButtons);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form text_area
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let normalTextAreaContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											normalTextAreaContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("normal_text_area_regular_form");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (normalTextAreaContainers) {
												if (normalTextAreaContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
												}

												Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer, j) => {
													// let inputText = inputTextContainer.getElementsByTagName('input')[0];
													let normalTextAreaContainerClone = normalTextAreaContainer.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(normalTextAreaContainer, normalTextAreaContainerClone);

													// Change IDs and append cloned dropdowns to the container
													normalTextAreaContainerClone.setAttribute("id", `normal_text_area_field_table${id}_row${idx}_column${i}_${j}`);

													// Event listener for input event
													normalTextAreaContainerClone.addEventListener("input", (event) => {
														const enteredValue = event.target.value;
														localStorage.setItem(
															`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
													});

													// Event listener for keypress event with Enter key
													normalTextAreaContainerClone.addEventListener("keypress", (event) => {
														if (event.key === "Enter") {
															const enteredValue = event.target.value;
															localStorage.setItem(
																`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														}
													});

													// Append the dropdown to the table cell
													td.appendChild(normalTextAreaContainerClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer) => {
													normalTextAreaContainer.style.display = "none";
												});
											}
											// }
											// console.log("normalTextAreaContainers:", normalTextAreaContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating custom-table-row-selection-checkbox-container
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let tableRowSelectionCheckboxContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (tableRowSelectionCheckboxContainers) {
												// if (tableRowSelectionCheckboxContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// }

												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
													let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
													let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

													// to store row info accross page change
													const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

													// Change IDs and append cloned dropdowns to the container
													tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
													
													// Retrieve stored data from session storage
													let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

													// Check if this checkbox should be checked
													const existingData = storedData.find(
														(item) =>
															item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
															item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
															item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
															item.paginationIndex === currentPageIndex
													);
													if (existingData) {
														tableRowSelectionCheckboxClone.checked = true;
													}
													
													tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
														const enteredValue = event.target.value;
														const isChecked = event.target.checked; // Check if the checkbox is checked

														// to store row info accross page change
														const checkboxData = {
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
															enteredValue: `${enteredValue}`,
															paginationIndex: currentPageIndex, // Include the current page index
														};

														// Retrieve existing data
														let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

														if (isChecked) {
															// Checkbox is checked, add object to local storage
															localStorage.setItem(
																`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
															// console.log("currentPageIndex", currentPageIndex);

															// If checked, check if the entry already exists
															const existingIndex = storedData.findIndex(
																(item) =>
																	item.rowId === checkboxData.rowId &&
																	item.cellId === checkboxData.cellId &&
																	item.paginationIndex === checkboxData.paginationIndex &&
																	item.inputId === checkboxData.inputId
															);

															if (existingIndex !== -1) {
																// Update existing entry
																storedData[existingIndex] = checkboxData;
															} else {
																// Add new entry
																storedData.push(checkboxData);
															}
														} else {
															// Checkbox is unchecked, remove object from local storage
															localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
															
															// If unchecked, remove the entry
															storedData = storedData.filter(
																(item) =>
																	!(item.rowId === checkboxData.rowId &&
																	item.cellId === checkboxData.cellId &&
																	item.paginationIndex === checkboxData.paginationIndex &&
																	item.inputId === checkboxData.inputId)
															);
														}

														// You can also perform other actions based on the checkbox state
														// For example, update UI, make API calls, etc.
														
														// Update session storage with the modified array
														sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
													});

													// Append the dropdown to the table cell
													td.appendChild(tableRowSelectionCheckboxClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
													tableRowSelectionCheckboxContainer.style.display = "none";
												});
											}
											// }
											// console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_date_time field
										try {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let dateTimeInputContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (dateTimeInputContainers) {
												// if (dateTimeInputContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
													let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
													let dateTimeInputClone = dateTimeInput.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(dateTimeInput, dateTimeInputClone);

													// Change IDs and append cloned dropdowns to the container
													dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
													dateTimeInputClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_form_date_time_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													td.appendChild(dateTimeInputClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
													dateTimeInputContainer.style.display = "none";
												});
											}
											// }
											// console.log("dateTimeInputContainers:", dateTimeInputContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating toggle
										try {
											// plot only if the field_type of header attribute is boolean
											if (fieldType === 'Boolean') {
												// if (i === Number(table_selected_column)) {
												// console.log("i", i);
												// run this everytime api call is done due to user click event
												// find stellar-button component in header column
												let toggleContainers;
												// if (table_selected_column) {
												// console.log("customTableThead", customTableThead);

												// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
												// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

												// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

												toggleContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('.custom-table-toggle-container');
												// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

												if (toggleContainers) {
													// if there are toggle elements being replicated then don't show the data model content in their table cells along with the toggle replicated components
													td.textContent = "";

													// if (toggleContainers.length > 0) {
													// 	td.style.height = 'auto';
													// 	td.style.overflow = 'scroll';
													// 	td.style.scrollbarColor = `transparent transparent`;
													// }

													Array.from(toggleContainers).forEach((toggleContainer, j) => {
														let toggleInnerContainer = toggleContainer.getElementsByClassName("custom-table-inner-toggle-container")[0];
														let toggleClone = toggleInnerContainer.cloneNode(true);
														// Copy styles from the original to the cloned dropdown
														copyStyles(toggleInnerContainer, toggleClone);

														// Initial state of the toggle
														let isOn = false;

														// Change IDs and append cloned dropdowns to the container
														toggleClone.setAttribute("id", `custom_table_inner_toggle_container_table${id}_row${idx}_column${i}_${j}`);
														if (toggleClone.getElementsByClassName("custom-table-toggle")[0]) {
															toggleClone.getElementsByClassName("custom-table-toggle")[0].setAttribute("id", `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`);

															// update toggle once before/outside click event also so that it initializes according to the value coming from backend. If `content` value is true, toggle should be on, else off.
															if (content) {
																isOn = true;
															}
															updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));

															// now attach event to make the toggle function changing color on on/off actions and sending PUT api calls
															toggleClone.getElementsByClassName("custom-table-toggle")[0].addEventListener("click", (event) => {
																isOn = !isOn;

																// if the cell value is false, change it to true before sending it in PUT call payload, else send it as it it. When toggle is on, 'true' value should be send in the backend.
																let cellValue = content ? false : true;

																// a PUT api call will be made to update that particular record of the given table
																updateTable(cellValue, `${obj[updatedProps.DataModelObjIdKeyname]}`, updatedProps.updatingCustomUrlValue, updatedProps.updatingBaseUrlValue, updatedProps.updatingApiPathValue, updatedProps.updatingApiQueryParamValue, updatedProps.updatingApiTableAttributeValue, updatedProps.updatingApiMethodValue, updatedProps.updatingApiUsernameValue, updatedProps.updatingApiPasswordValue, updatedProps.updatingApiBearerTokenValue, updatedProps.updatingApiMoreHeadersValue);

																updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));
																console.log(`Toggle is now: ${isOn ? 'ON' : 'OFF'}`, content);

																// Construct the localStorage key
																let toggleLocalStorageKey = `clicked_custom_table_toggle_table${id}_column${i}`;

																// Retrieve existing data from local storage
																let togglesData = JSON.parse(localStorage.getItem(toggleLocalStorageKey)) || [];

																// Create the new toggle data object
																let newToggleData = {
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	toggleEleId: `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`,
																	toggleStatus: isOn,
																};

																// Check if the toggle already exists
																let existingToggleIndex = togglesData.findIndex(toggle => toggle.toggleEleId === newToggleData.toggleEleId);

																if (existingToggleIndex !== -1) {
																	// Update the existing toggle data
																	togglesData[existingToggleIndex] = newToggleData;
																} else {
																	// Add the new toggle data
																	togglesData.push(newToggleData);
																}

																// Update the local storage with the new/updated toggle data, column-wise
																localStorage.setItem(toggleLocalStorageKey, JSON.stringify(togglesData));
															});
														}

														// Append the dropdown to the table cell
														td.appendChild(toggleClone);
													});

													// Hide the original dropdowns in the header column
													Array.from(toggleContainers).forEach((toggleContainer) => {
														toggleContainer.style.display = "none";
													});
												}
												// }
												// console.log("toggleContainers:", toggleContainers);
												// }
											}										
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating rich text input
										try {
											// console.log("i", i);

											let inputTextContainers;

											// console.log("customTableThead", customTableThead);

											// console.log( //     "thead th i", //     customTableThead //     .getElementsByTagName("tr")[0] //     .querySelectorAll("th")[i] // );

											let richTextIndexesData = updatedProps.richTextIndexes;
											// console.log( "richTextIndexesData::::::::::", richTextIndexesData );

											if (richTextIndexesData.length > 0) {
												richTextIndexesData.forEach((x) => {
													console.log("richTextIndexesData x::::::::::", x);
													console.log("richTextIndexesData::::::", i);
													console.log("richTextIndexesData::::::", i);
													if (x === i) {
														td.innerHTML = "";
														let wrapperDiv = document.createElement("div");
														wrapperDiv.setAttribute(
															"id",
															`rich_text_container_${idx}_${id}`
														);
														wrapperDiv.setAttribute(
															"class",
															"rich_text_table_container"
														);

														wrapperDiv.style.backgroundColor = "white";

														td.appendChild(wrapperDiv);

														let quill = new Quill(wrapperDiv, {
															modules: {
																toolbar: updatedProps.richTextOptions.toolbar ? [
																	[{
																		header: [1, 2, 3, 4, 5, 6, false]
																	}],
																	["bold", "italic", "underline", "strike"],
																	[{
																		list: "bullet"
																	}, {
																		list: "ordered"
																	}],
																	["link"],
																	[{
																		align: []
																	}],
																	[{
																		color: []
																	}, {
																		background: []
																	}],
																] : false,
															},
															placeholder: "",
															theme: "snow",
														});

														updatedProps.richTextOptions.readOnly ? quill.enable(false) : quill.enable(true);

														if (content) {
															console.log('contentRich:::::::',content)
															let jsonContent = JSON.parse(content);
															console.log('jsonContent:::::::::',jsonContent)
															console.log('type jsonContent:::::::::',typeof jsonContent)
															if (jsonContent ) {
																console.log('inside jsonContent')
																quill.setContents(jsonContent);
															}
														} else {
															let richTextContent;
															richTextContent = sessionStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );
															if (!richTextContent) richTextContent = localStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );

															let parsedData = JSON.parse(richTextContent);
															if (parsedData && parsedData.delta) {
																quill.setContents(parsedData.delta);
															}
																
															
													}

														quill.on("text-change", () => {
															const delta = quill.getContents();

															let deltaRef = {
																"data-db-id": `${
																	obj[updatedProps.DataModelObjIdKeyname]
																	}`,
																delta:delta
															}
															
															localStorage.setItem(
																`redslingRichTextTable${id}_row_${idx}_column${x}`,
																JSON.stringify(deltaRef)
															);
															
															sessionStorage.setItem(
																`redslingRichTextTable${id}_row_${idx}_column${x}`,
																JSON.stringify(deltaRef)
															);
														});

													
													}
												});
											}

											// }
											// console.log("inputTextContainers:", inputTextContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										tr.append(td);
									}

									if (customTableTbody) {
										fragment.appendChild(tr);
									}
									
									console.log("+idx+", idx);
								}

								if (customTableTbody) {
									// Append the current batch to the table body
									customTableTbody.appendChild(fragment);
								}

								// Schedule the next batch
								if (currentRow < totalRows) {
									setTimeout(renderBatch, 0); // Yield to the browser
								} else {
									// Show the table after all rows are rendered
									// customTableTbody.style.display = "";
									console.log("Rendering complete!");
								}
							}

							// Start rendering in chunks
							renderBatch();
						}						
					}

					// if the response is a nested array-of-objects
					else if (typeof apiData === "object" && !isArrayofObjects(apiData)) {
						let nestedArrOfObj = findKeyValue(apiData, updatedProps.nestedArrOfObjKeynameValue);
						console.log("api nestedArrOfObj", nestedArrOfObj);

						if (isArrayofObjects(nestedArrOfObj)) {
							console.log("#");

							// loop over nestedArrOfObj array as a table's row
							nestedArrOfObj.forEach((obj, idx) => {
								let tr = document.createElement("tr");
								tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
								tr.setAttribute("class", "custom-table-tbody-row");
								// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
								tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
								tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
								tr.style.width = "100%";
								if (idx % 2 !== 0) {
									tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
									// hover effect
									tr.addEventListener("mouseover", function () {
										tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
									});
									tr.addEventListener("mouseleave", function () {
										tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
									});
								} else {
									tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
									// hover effect
									tr.addEventListener("mouseover", function () {
										tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
									});
									tr.addEventListener("mouseleave", function () {
										tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
									});
								}

								// get data and add it as table's column
								for (let i = 0; i < updatedProps.numColsInputValue; i++) {
									let content, convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
									// using updatedProps.HeaderFields because only these are present in the downloading code
									// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
									// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
									// }
									// field name and type are for toggle replication
									let fieldName;

									try {
										if (`HeaderField_${i}` in updatedProps) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else {
												// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 0", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else {
													content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
										} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else {
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 1", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else {
													content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
												// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
											}
											console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
										} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
											// Get the value of the attribute from 'this'
											console.log("customTableContainer", customTableContainer);
											const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (headerFieldAttribute.includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object is not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
											} else {
												// content = obj[headerFieldAttribute];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[headerFieldAttribute];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[headerFieldAttribute];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 2", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
													content = obj[headerFieldAttribute];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("headerFieldAttribute", headerFieldAttribute, content);
										}
									} catch (e) {
										// statements
										console.error(e);
									}

									// field name and type are for toggle replication
									let fieldType;
									let tableDataFields;
                  					if (tableData && ("fields" in tableData)) tableDataFields = tableData.fields;
									if (tableDataFields) fieldType = tableDataFields.filter((x) => x.field_name === fieldName)[0]?.field_type;
									
            						// console.log("+fieldType+", fieldType);

									// console.log("content, i", content, i);

									let td = document.createElement("td");
									td.setAttribute("data-gjs-type", "cell");
									// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
									td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
									td.style.padding = "8px";
									td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
									td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
									td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
									td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
									td.style.borderCollapse = "collapse";
									td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
									td.style.color = `${updatedProps.tBodyTdFontColor}`;
									td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
									td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
									td.innerHTML = content !== undefined ? content : "";

									// for replicating single column component
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerSingleColumnComponents;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerSingleColumnComponents = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('div[data-gjs-type="custom-table-thead-row"][class*="gjs-row"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerSingleColumnComponents) {
											if (headerSingleColumnComponents.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerSingleColumnComponents.length; j++) {
												// row.getElementsByTagName('td')[table_selected_column].append(headerSingleColumnComponents[j]);
												let clonedSingleColumnComponent = headerSingleColumnComponents[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerSingleColumnComponents[j], clonedSingleColumnComponent);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedSingleColumnComponent.setAttribute("id", `single_column_component_table${id}_row${idx}_column${i}_${j}`);

												// for replicating ACTION BUTTON inside single column components
												let innerClonedActionButtons = clonedSingleColumnComponent.getElementsByClassName("custom-table-action-button");
												if (innerClonedActionButtons.length > 0) {
													Array.from(innerClonedActionButtons).forEach((innerClonedActionButton, j_btn) => {
														if (innerClonedActionButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedActionButton.setAttribute("id", `custom_table_action${innerClonedActionButton.getAttribute("data-table-action")}_button_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedActionButton.getElementsByTagName("p")[0]) {
																innerClonedActionButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedActionButton.addEventListener("click", async function (event) {
																console.log("action-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
																localStorage.setItem(
																	`custom_table_action_button_table${id}_${innerClonedActionButton.getAttribute("data-table-action")}_column${i}_${j}_element${j_btn}_clicked`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"data-table-action": `${innerClonedActionButton.getAttribute("data-table-action")}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedActionButton.getAttribute("id")}`,
																	}),
																);

																// Style for the modals
																let modalStyle = {
																	padding: "2%",
																	backgroundColor: "white",
																	borderRadius: "5px",
																	boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																	maxWidth: "50vw",
																	width: "30vw",
																	maxHeight: "40vh",
																	margin: "auto",
																	overflow: "auto",
																	"-ms-overflow-style": "none",
																	"scrollbar-width": "none",
																};

																// Style for the input fields
																let inputFieldStyle = {
																	display: "flex",
																	flexDirection: "column",
																	marginBottom: "15px",
																};

																// Style for the buttons
																let buttonStyle = {
																	width: "100%",
																	padding: "10px",
																	backgroundColor: "#007bff",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let submitButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let closeButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																// Edit modal
																if (innerClonedActionButton.getAttribute("data-table-action") === "edit") {
																	// Create the dialog element
																	let editModal = document.createElement("dialog");
																	editModal.setAttribute("id", "custom-modal");

																	// Apply styles to the modal
																	Object.assign(editModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Edit`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// variable only for the reference keyname user input
																	let selectedOptions = [];
																	// Check if updatedProps.EditableFields is not empty
																	if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																		// Iterate over EditableFields to create form elements
																		for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																			// to handle non-Reference field keynames
																			if (!fieldName.includes("-")) {
																				let fieldLabel = document.createElement("label");
																				fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																				fieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(fieldLabel);

																				let inputField = document.createElement("input");
																				inputField.setAttribute("name", fieldName);
																				inputField.value = obj[fieldName]; // to initialize input fields with present values
																				Object.assign(inputField.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// Set input field type based on field type
																				switch (fieldType.toLowerCase()) {
																					case "string":
																						inputField.setAttribute("type", "text");
																						break;
																					case "boolean":
																						inputField.setAttribute("type", "radio");
																						break;
																					case "number":
																						inputField.setAttribute("type", "number");
																						break;
																					case "date-time":
																						inputField.setAttribute("type", "datetime-local");
																						break;
																					case "time":
																						inputField.setAttribute("type", "time");
																						break;
																					case "file":
																						inputField.setAttribute("type", "file");
																						break;
																					default:
																						inputField.setAttribute("type", "text");
																						break;
																				}
																				modalContent.appendChild(inputField);
																			}

																			// to handle Reference field keynames for example - WebProject1_Address-Sector
																			else if (fieldName.includes("-")) {
																				// variables to be used later in this if-condition
																				let [table, column] = fieldName.split("-");
																				// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																				// create its label
																				let refFieldLabel = document.createElement("label");
																				refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																				refFieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(refFieldLabel);

																				// create a drodown for these keynames > its options will be all the values from response from that table
																				let referenceKeynameDropdown = document.createElement("select");
																				referenceKeynameDropdown.setAttribute("name", fieldName);
																				referenceKeynameDropdown.setAttribute("id", fieldName);
																				// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																				Object.assign(referenceKeynameDropdown.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// populate it with options using the response from its data-model API
																				let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																					headers: {
																						Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																					},
																				});
																				let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																				if (referenceKeynameValueOptionsData) {
																					// setting default option --select--
																					let defaultOption = document.createElement("option");
																					// defaultOption.setAttribute('disabled', 'disabled');
																					defaultOption.innerHTML = "--select--";
																					referenceKeynameDropdown.appendChild(defaultOption);

																					// loop over the response to fill the options of its reference table
																					referenceKeynameValueOptionsData.forEach((optObj) => {
																						let option = document.createElement("option");
																						option.value = optObj[`${column}`];
																						option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																						option.innerHTML = optObj[`${column}`];
																						// to initialize input fields with present values
																						if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																							option.setAttribute("selected", true);
																						}
																						referenceKeynameDropdown.appendChild(option);
																					});
																				}

																				referenceKeynameDropdown.addEventListener("change", function (e) {
																					let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																					// console.log('Selected value:', selectedOptionFromDropdown.value);
																					// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																					// get the reference ID of the table row from main obj to update
																					let refTableId;
																					let tableName = table.split("_")[1];
																					if (obj[tableName] !== null) {
																						refTableId = obj[tableName];
																					}

																					selectedOptions.push({
																						table,
																						column,
																						refTableId,
																						value: selectedOptionFromDropdown.value,
																						"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																					});
																				});

																				modalContent.appendChild(referenceKeynameDropdown);
																			}
																		}
																	}

																	console.log("+bu, bubt+", bu, bubt);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";
																	buttonContainer.style.marginTop = "5%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-submit-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
																	submitButton.innerHTML = "Submit";

																	// Apply styles to the button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", function (e) {
																		e.preventDefault(); // Prevent default form action
																		// Handle form submission here
																		console.log("Form submitted!", obj);

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Initialize an empty FormData object to get the form's input fields values
																		let formData = new FormData(modalContent);
																		let formValues = {};
																		for (const [key, value] of formData.entries()) {
																			formValues[key] = value;
																		}

																		// Create the to_insert object with the keynames from updatedProps.EditableFields
																		// and their corresponding values from formValues
																		let to_insert = {};
																		Object.keys(updatedProps.EditableFields).forEach((key) => {
																			if (formValues[key] !== undefined && !key.includes("-")) {
																				// Check if the form has the field
																				to_insert[key] = formValues[key];
																			}

																			// re-attach the reference id to PUT call else it will set the references to null
																			if (formValues[key] !== undefined && key.includes("-")) {
																				let x = key.split("-");
																				let table = x[0].split("_")[1];
																				if (obj[table] !== null) {
																					to_insert[table] = obj[table];
																				}
																			}
																		});

																		console.log("+to_insert+", to_insert);
																		console.log("+selectedOptions+", selectedOptions);

																		// logic to update all table cells the edit button is editing without refreshing
																		for (const key in to_insert) {
																			let columns = [];
																			if (updatedProps[`HeaderField_${i}`]) {
																				Object.keys(updatedProps).map((hF_i) => {
																					if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			} else {
																				Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																					if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			}

																			// console.log('+columns+', columns);

																			columns.forEach(idx => {
																				let td = tr.getElementsByTagName('td')[idx];

																				let valueToUpdateCellWith = to_insert[key];
																				// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																				// Find the first text node
																				const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																				if (textNode) {
																					textNode.nodeValue = valueToUpdateCellWith;
																				} else {
																					// If no text node exists, create one
																					td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																				}
																			});
																		}

																		// Fetch call (to non-reference table) can be made here using the input field values
																		// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																		fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "PUT",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																			body: JSON.stringify({
																				to_insert: to_insert,
																			}),
																		})
																			.then((response) => response.json())
																			.then((data) => console.log(data));

																		// Fetch call (for reference table)
																		if (selectedOptions.length > 0) {
																			selectedOptions.forEach((data) => {
																				// make api calls per object
																				let requestBody = {};
																				requestBody[data.column] = data.value; // Assign dynamic key-value pair
																				fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																					method: "PUT",
																					headers: {
																						"Content-Type": "application/json",
																						Authorization: `Bearer ${values.BearerToken}`,
																					},
																					body: JSON.stringify({
																						to_insert: requestBody,
																					}),
																				})
																					.then((response) => response.json())
																					.then((data) => console.log(data));
																			});
																		}

																		// Close the modal when the form is submitted
																		editModal.close();

																		// clear the selectedOptions for next turn
																		selectedOptions = [];

																		// // giving a delay in reload so that it doesn't change the url in the broswer tab
																		// setTimeout(function () {
																		// 	window.top.location.reload();
																		// }, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-close-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
																	closeButton.innerHTML = "Close";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (event) {
																		event.preventDefault(); // Prevent default behavior of the click event
																		editModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	editModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(editModal);

																	// Show the modal
																	editModal.showModal();
																}
																// Delete modal
																else if (innerClonedActionButton.getAttribute("data-table-action") === "delete") {
																	// Create the dialog element
																	let deleteModal = document.createElement("dialog");
																	deleteModal.setAttribute("id", "delete-modal");

																	// Apply styles to the modal
																	Object.assign(deleteModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Delete`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// // Create an input field for data model with a default value
																	// let dataModelInputField = document.createElement("input");
																	// dataModelInputField.setAttribute('type', 'text');
																	// dataModelInputField.setAttribute('name', 'DataModel');
																	// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
																	// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(dataModelInputField.style, inputFieldStyle);

																	// modalContent.appendChild(dataModelInputField);

																	// // Create an input field for bearer token with a default value
																	// let bearerTokenInputField = document.createElement("input");
																	// bearerTokenInputField.setAttribute('type', 'text');
																	// bearerTokenInputField.setAttribute('name', 'BearerToken');
																	// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
																	// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(bearerTokenInputField.style, inputFieldStyle);

																	// modalContent.appendChild(bearerTokenInputField);

																	// Create a paragraph tag for confirmation message
																	let confirmationMessage = document.createElement("p");
																	confirmationMessage.style.marginBottom = "5%";
																	confirmationMessage.style.fontSize = "1vw";
																	confirmationMessage.innerHTML = "Are you sure?";

																	// Append confirmation message to the modal content
																	modalContent.appendChild(confirmationMessage);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-delete-yes-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
																	submitButton.innerHTML = "Yes";

																	// Apply styles to the "Yes" button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", async function (e) {
																		e.preventDefault();
																		// Handle form submission here
																		console.log("Form submitted!");

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Use the values in the fetch call or other logic
																		console.log("Input field values:", values);

																		// Make the fetch call to delete the item
																		let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "DELETE",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																		});
																		// .then(response => {
																		//     if (response.ok) {
																		//         console.log('Item deleted successfully');
																		//     } else {
																		//         console.error('Failed to delete item');
																		//     }
																		// });

																		let responseData = response.json();
																		if (responseData.ok) {
																			console.log("Item deleted successfully");
																		} else {
																			console.error("Failed to delete item");
																		}

																		// Close the modal when the form is submitted
																		deleteModal.close();

																		// giving a delay in reload so that it doesn't change the url in the broswer tab
																		setTimeout(function () {
																			window.top.location.reload();
																		}, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-delete-no-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
																	closeButton.innerHTML = "No";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (e) {
																		e.preventDefault();
																		deleteModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	deleteModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(deleteModal);

																	// Show the modal
																	deleteModal.showModal();
																}
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedActionButton);
														}
													});
												}

												// for replicating STELLAR BUTTON inside single column components
												let innerClonedStellarButtons = clonedSingleColumnComponent.getElementsByClassName("stellar-button-container");
												if (innerClonedStellarButtons.length > 0) {
													Array.from(innerClonedStellarButtons).forEach((innerClonedStellarButton, j_btn) => {
														if (innerClonedStellarButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
																innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedStellarButton.addEventListener("click", function (event) {
																console.log("stellar-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

																// this registers all stellar button clicks on LS
																// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
																// this registers only by the individuals in the column header
																localStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);

																sessionStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedStellarButton);
														}
													});
												}

												td.appendChild(clonedSingleColumnComponent);

												// to hide the header buttons after they have been cloned
												headerSingleColumnComponents[j].style.display = "none";
											}
										}
										// }
										console.log("headerSingleColumnComponents:", headerSingleColumnComponents);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating stellar buttons
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerStellarButtons;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										// console.log("headerStellarButtons1:", headerStellarButtons);
										if (headerStellarButtons) {
											if (headerStellarButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerStellarButtons.length; j++) {
												// console.log("headerStellarButtons[j]", headerStellarButtons[j]);
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerStellarButtons[j], clonedStellarButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
												clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
												if (clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
													clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header stellar button was not in single column component, only then append it to td
												if (!headerStellarButtons[j].parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedStellarButton);
													// console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedStellarButton.addEventListener("click", function (event) {
													// console.log("stellar-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													sessionStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													// highlight the table row that the stellar button has selected
													if (updatedProps.tBodyTrSelectColorHighlight)
														clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";

													customTable.onclick = function(event) {														
														// Check if the click is inside the custom table but outside the clicked row
														// if (
														// 	customTable.contains(event.target) && // Click is inside the customTable
														// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
														// ) {
														// 	// console.log('@deselecting row', idx);
														// 	// Determine original color based on row index (even/odd)
														// 	const originalColor = idx % 2 === 0
														// 		? updatedProps.tBodyEvenTrBackgroundColor
														// 		: updatedProps.tBodyOddTrBackgroundColor;

														// 	// Reset the row's background color
														// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
														// }

														// Iterate over all rows in the table
														const rows = customTable.querySelectorAll("tr");
														rows.forEach((row, rowIndex) => {
															// Skip the currently selected row
															if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																return;
															}

															// Reset the background color based on even/odd index
															const originalColor = rowIndex % 2 === 0
																? updatedProps.tBodyEvenTrBackgroundColor
																: updatedProps.tBodyOddTrBackgroundColor;

															row.style.backgroundColor = originalColor;
														});
													};
												});

												// to hide the header buttons after they have been cloned
												headerStellarButtons[j].style.display = "none";
											}
										}
										// }
										// console.log("headerStellarButtons:", headerStellarButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating action buttons
									try {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find action-button component in header column
										let headerActionButtons;
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										headerActionButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-action-button");

										if (headerActionButtons.length > 0) {
											if (headerActionButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(headerActionButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												await copyStyles(j, clonedButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedButton.setAttribute("id", `custom_table_action_button_table${id}_row${idx}_column${i}_${j}`);
												if (clonedButton.getElementsByTagName("p")[0]) {
													clonedButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header action button was not in single column component, only then append it to td
												// console.log("j.parentNode.classList", j.parentNode.classList);
												if (!j.parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedButton);
													// console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedButton.addEventListener("click", async function (event) {
													// console.log("action-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
													localStorage.setItem(
														`custom_table_action_button_table${id}_clicked`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															"data-table-action": `${clonedButton.getAttribute("data-table-action")}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedButton.getAttribute("id")}`,
														}),
													);

													// Style for the modals
													let modalStyle = {
														padding: "2%",
														backgroundColor: "white",
														borderRadius: "5px",
														boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
														maxWidth: "50vw",
														width: "30vw",
														maxHeight: "40vh",
														margin: "auto",
														overflow: "auto",
														"-ms-overflow-style": "none",
														"scrollbar-width": "none",
													};

													// Style for the input fields
													let inputFieldStyle = {
														display: "flex",
														flexDirection: "column",
														marginBottom: "15px",
													};

													// Style for the buttons
													let buttonStyle = {
														width: "100%",
														padding: "10px",
														backgroundColor: "#007bff",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let submitButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let closeButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													// Edit modal
													if (clonedButton.getAttribute("data-table-action") === "edit") {
														// Create the dialog element
														let editModal = document.createElement("dialog");
														editModal.setAttribute("id", "custom-modal");

														// Apply styles to the modal
														Object.assign(editModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Edit`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// variable only for the reference keyname user input
														let selectedOptions = [];
														// Check if updatedProps.EditableFields is not empty
														if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
															// Iterate over EditableFields to create form elements
															for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																// to handle non-Reference field keynames
																if (!fieldName.includes("-")) {
																	let fieldLabel = document.createElement("label");
																	fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																	fieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(fieldLabel);

																	let inputField = document.createElement("input");
																	inputField.setAttribute("name", fieldName);
																	inputField.value = obj[fieldName]; // to initialize input fields with present values
																	Object.assign(inputField.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// Set input field type based on field type
																	switch (fieldType.toLowerCase()) {
																		case "string":
																			inputField.setAttribute("type", "text");
																			break;
																		case "boolean":
																			inputField.setAttribute("type", "radio");
																			break;
																		case "number":
																			inputField.setAttribute("type", "number");
																			break;
																		case "date-time":
																			inputField.setAttribute("type", "datetime-local");
																			break;
																		case "time":
																			inputField.setAttribute("type", "time");
																			break;
																		case "file":
																			inputField.setAttribute("type", "file");
																			break;
																		default:
																			inputField.setAttribute("type", "text");
																			break;
																	}
																	modalContent.appendChild(inputField);
																}

																// to handle Reference field keynames for example - WebProject1_Address-Sector
																else if (fieldName.includes("-")) {
																	// variables to be used later in this if-condition
																	let [table, column] = fieldName.split("-");
																	// // console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																	// create its label
																	let refFieldLabel = document.createElement("label");
																	refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																	refFieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(refFieldLabel);

																	// create a drodown for these keynames > its options will be all the values from response from that table
																	let referenceKeynameDropdown = document.createElement("select");
																	referenceKeynameDropdown.setAttribute("name", fieldName);
																	referenceKeynameDropdown.setAttribute("id", fieldName);
																	// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																	Object.assign(referenceKeynameDropdown.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// populate it with options using the response from its data-model API
																	let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																		headers: {
																			Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																		},
																	});
																	let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																	if (referenceKeynameValueOptionsData) {
																		// setting default option --select--
																		let defaultOption = document.createElement("option");
																		// defaultOption.setAttribute('disabled', 'disabled');
																		defaultOption.innerHTML = "--select--";
																		referenceKeynameDropdown.appendChild(defaultOption);

																		// loop over the response to fill the options of its reference table
																		referenceKeynameValueOptionsData.forEach((optObj) => {
																			let option = document.createElement("option");
																			option.value = optObj[`${column}`];
																			option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																			option.innerHTML = optObj[`${column}`];
																			// to initialize input fields with present values
																			if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																				option.setAttribute("selected", true);
																			}
																			referenceKeynameDropdown.appendChild(option);
																		});
																	}

																	referenceKeynameDropdown.addEventListener("change", function (e) {
																		let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																		// // console.log('Selected value:', selectedOptionFromDropdown.value);
																		// // console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																		// get the reference ID of the table row from main obj to update
																		let refTableId;
																		let tableName = table.split("_")[1];
																		if (obj[tableName] !== null) {
																			refTableId = obj[tableName];
																		}

																		selectedOptions.push({
																			table,
																			column,
																			refTableId,
																			value: selectedOptionFromDropdown.value,
																			"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																		});
																	});

																	modalContent.appendChild(referenceKeynameDropdown);
																}
															}
														}

														// console.log("+bu, bubt+", bu, bubt);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";
														buttonContainer.style.marginTop = "5%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-submit-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
														submitButton.innerHTML = "Submit";

														// Apply styles to the button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", function (e) {
															e.preventDefault(); // Prevent default form action
															// Handle form submission here
															// console.log("Form submitted!", obj);

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Initialize an empty FormData object to get the form's input fields values
															let formData = new FormData(modalContent);
															let formValues = {};
															for (const [key, value] of formData.entries()) {
																formValues[key] = value;
															}

															// Create the to_insert object with the keynames from updatedProps.EditableFields
															// and their corresponding values from formValues
															let to_insert = {};
															Object.keys(updatedProps.EditableFields).forEach((key) => {
																if (formValues[key] !== undefined && !key.includes("-")) {
																	// Check if the form has the field
																	to_insert[key] = formValues[key];
																}

																// re-attach the reference id to PUT call else it will set the references to null
																if (formValues[key] !== undefined && key.includes("-")) {
																	let x = key.split("-");
																	let table = x[0].split("_")[1];
																	if (obj[table] !== null) {
																		to_insert[table] = obj[table];
																	}
																}
															});

															// console.log("+to_insert+", to_insert);
															// console.log("+selectedOptions+", selectedOptions);

															// logic to update all table cells the edit button is editing without refreshing
															for (const key in to_insert) {
																let columns = [];
																if (updatedProps[`HeaderField_${i}`]) {
																	Object.keys(updatedProps).map((hF_i) => {
																		if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																} else {
																	Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																		if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																}

																// console.log('+columns+', columns);

																columns.forEach(idx => {
																	let td = tr.getElementsByTagName('td')[idx];

																	let valueToUpdateCellWith = to_insert[key];
																	// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																	// Find the first text node
																	const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																	if (textNode) {
																		textNode.nodeValue = valueToUpdateCellWith;
																	} else {
																		// If no text node exists, create one
																		td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																	}
																});
															}

															// Fetch call (to non-reference table) can be made here using the input field values
															// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
															fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "PUT",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
																body: JSON.stringify({
																	to_insert: to_insert,
																}),
															})
																.then((response) => response.json())
																.then((data) => console.log(data));

															// Fetch call (for reference table)
															if (selectedOptions.length > 0) {
																selectedOptions.forEach((data) => {
																	// make api calls per object
																	let requestBody = {};
																	requestBody[data.column] = data.value; // Assign dynamic key-value pair
																	fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																		method: "PUT",
																		headers: {
																			"Content-Type": "application/json",
																			Authorization: `Bearer ${values.BearerToken}`,
																		},
																		body: JSON.stringify({
																			to_insert: requestBody,
																		}),
																	})
																		.then((response) => response.json())
																		.then((data) => console.log(data));
																});
															}

															// Close the modal when the form is submitted
															editModal.close();

															// clear the selectedOptions for next turn
															selectedOptions = [];

															// // giving a delay in reload so that it doesn't change the url in the broswer tab
															// setTimeout(function () {
															// 	window.top.location.reload();
															// }, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-close-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
														closeButton.innerHTML = "Close";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (event) {
															event.preventDefault(); // Prevent default behavior of the click event
															editModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														editModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(editModal);

														// Show the modal
														editModal.showModal();
													}
													// Delete modal
													else if (clonedButton.getAttribute("data-table-action") === "delete") {
														// Create the dialog element
														let deleteModal = document.createElement("dialog");
														deleteModal.setAttribute("id", "delete-modal");

														// Apply styles to the modal
														Object.assign(deleteModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Delete`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// // Create an input field for data model with a default value
														// let dataModelInputField = document.createElement("input");
														// dataModelInputField.setAttribute('type', 'text');
														// dataModelInputField.setAttribute('name', 'DataModel');
														// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
														// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

														// // Apply styles to the input fields
														// Object.assign(dataModelInputField.style, inputFieldStyle);

														// modalContent.appendChild(dataModelInputField);

														// // Create an input field for bearer token with a default value
														// let bearerTokenInputField = document.createElement("input");
														// bearerTokenInputField.setAttribute('type', 'text');
														// bearerTokenInputField.setAttribute('name', 'BearerToken');
														// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
														// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

														// // Apply styles to the input fields
														// Object.assign(bearerTokenInputField.style, inputFieldStyle);

														// modalContent.appendChild(bearerTokenInputField);

														// Create a paragraph tag for confirmation message
														let confirmationMessage = document.createElement("p");
														confirmationMessage.style.marginBottom = "5%";
														confirmationMessage.style.fontSize = "1vw";
														confirmationMessage.innerHTML = "Are you sure?";

														// Append confirmation message to the modal content
														modalContent.appendChild(confirmationMessage);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-delete-yes-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
														submitButton.innerHTML = "Yes";

														// Apply styles to the "Yes" button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", async function (e) {
															e.preventDefault();
															// Handle form submission here
															// console.log("Form submitted!");

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Use the values in the fetch call or other logic
															// console.log("Input field values:", values);

															// Make the fetch call to delete the item
															let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "DELETE",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
															});
															// .then(response => {
															//     if (response.ok) {
															//         // console.log('Item deleted successfully');
															//     } else {
															//         console.error('Failed to delete item');
															//     }
															// });

															let responseData = response.json();
															if (responseData.ok) {
																// console.log("Item deleted successfully");
															} else {
																console.error("Failed to delete item");
															}

															// Close the modal when the form is submitted
															deleteModal.close();

															// giving a delay in reload so that it doesn't change the url in the broswer tab
															setTimeout(function () {
																window.top.location.reload();
															}, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-delete-no-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
														closeButton.innerHTML = "No";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (e) {
															e.preventDefault();
															deleteModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														deleteModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(deleteModal);

														// Show the modal
														deleteModal.showModal();
													}
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// console.log("headerActionButtons:", headerActionButtons);
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_file buttons
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerInputFileButtons;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerInputFileButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_file_container");
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerInputFileButtons) {
											if (headerInputFileButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											// for (let j = 0; j < headerInputFileButtons.length; j++) {
											// 	// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
											// 	let clonedInputFileButton = headerInputFileButtons[j].cloneNode(true);

											// 	// Copy the styles from the original button to the cloned button and its inner elements
											// 	copyStyles(headerInputFileButtons[j], clonedInputFileButton);

											// 	// changing the clone's IDs so that they are present and available individually in LB and in download
											// 	clonedInputFileButton.setAttribute('id', `input_file_container_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].setAttribute('id', `input_file_label_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].getElementsByTagName('p')[0].setAttribute('id', `input_file_label_p_table${id}_row${idx}_column${i}_${j}`);

											// 	td.appendChild(clonedInputFileButton);

											// 	let headerInputFileClickHandler = function(event) {
											// 		// console.log('input_file_label clicked', 'saving data in session storage');
											// 		// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

											// 		// this registers all stellar button clicks on LS
											// 		// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
											// 		// this registers only by the individuals in the column header
											// 		localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, JSON.stringify({
											// 			'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
											// 			tableId: `${id}`,
											// 			rowId: `custom_table_tbody_row${idx}_table${id}`,
											// 			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
											// 			buttonId: `${clonedInputFileButton.getAttribute('id')}`,
											// 		}));

											// 		// console.log('clonedInputFileButton++', clonedInputFileButton);

											// 		let inputFile = document.createElement('input');
											//         inputFile.type = 'file';
											//         inputFile.style.display = 'none';

											//         inputFile.addEventListener('change', async function (event) {
											//               let file = inputFile.files[0];
											//               // console.log('this++', this);
											//               // console.log('file is:', file);

											//               let formData = new FormData();
											//               formData.append('file', file);

											//               try {
											//                   const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
											//                       method: 'POST',
											//                       headers: {
											//                           // 'Content-Type': 'multipart/form-data',
											//                       },
											//                       body: formData,
											//                   });

											//                   if (response.ok) {
											//                       const data = await response.json();
											//                       // console.log('File uploaded:', data);
											//                       clonedInputFileButton.setAttribute('data_file_url', data["fileLocation"]);

											//                     // Retrieve the object from local storage
											// 			        let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j}`)) || {};

											// 			        // Add the data_file_url and its value to the object
											// 			        clickedFileData.data_file_url = data["fileLocation"];

											// 			        // Convert the modified object back to a JSON string
											// 			        let updatedClickedFileData = JSON.stringify(clickedFileData);

											// 			        // Update the local storage with the modified JSON string
											// 			        localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, updatedClickedFileData);
											//                   } else {
											//                       console.error('Error uploading file:', response.statusText);
											//                   }

											//                   clonedInputFileButton.onclick = null;
											//               } catch (error) {
											//                   console.error('Error uploading file:', error);
											//               } finally {
											//               	// console.log('after 1')
											//                   // Remove the inputFile element after API call completes
											//                   if (inputFile && inputFile.parentNode) {
											//                   	// console.log('after 2')
											//                       inputFile.parentNode.removeChild(inputFile);
											//                   }
											//               }
											//         });

											//         clonedInputFileButton.appendChild(inputFile);
											//         inputFile.click();
											//         // console.log('clicked', clonedInputFileButton);
											// 	};

											// 	// adding click event on stellar-button from the table components
											// 	// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
											// 	clonedInputFileButton.onclick = headerInputFileClickHandler;

											// 	// to hide the header buttons after they have been cloned
											// 	headerInputFileButtons[j].style.display = "none";
											// }

											Array.from(headerInputFileButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
												let clonedInputFileButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedInputFileButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedInputFileButton.setAttribute("id", `input_file_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].setAttribute("id", `input_file_label_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0]) {
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0].setAttribute("id", `input_file_label_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedInputFileButton);

												let headerInputFileClickHandler = function (event) {
													// console.log("input_file_label clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_input_file_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedInputFileButton.getAttribute("id")}`,
														}),
													);

													// // console.log('clonedInputFileButton++', clonedInputFileButton);

													let inputFile = document.createElement("input");
													inputFile.type = "file";
													inputFile.style.display = "none";

													inputFile.addEventListener("change", async function (event) {
														let file = inputFile.files[0];
														// console.log("this++", this);
														// console.log("file is:", file);

														let formData = new FormData();
														formData.append("file", file);

														try {
															const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
																method: "POST",
																headers: {
																	// 'Content-Type': 'multipart/form-data',
																},
																body: formData,
															});

															if (response.ok) {
																const data = await response.json();
																// console.log("File uploaded:", data);
																td.setAttribute("data_file_url", data["fileLocation"]);

																// Retrieve the object from local storage
																let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`)) || {};

																// Add the data_file_url and its value to the object
																clickedFileData.data_file_url = data["fileLocation"];

																// Convert the modified object back to a JSON string
																let updatedClickedFileData = JSON.stringify(clickedFileData);

																// Update the local storage with the modified JSON string
																localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`, updatedClickedFileData);
															} else {
																console.error("Error uploading file:", response.statusText);
															}
														} catch (error) {
															console.error("Error uploading file:", error);
														} finally {
															// Remove the inputFile element after API call completes
															if (inputFile && inputFile.parentNode) {
																inputFile.parentNode.removeChild(inputFile);
															}
														}
													});

													clonedInputFileButton.appendChild(inputFile);
													inputFile.click();

													if (inputFile && inputFile.parentNode) {
														inputFile.parentNode.removeChild(inputFile);
													}
												};

												// adding click event on stellar-button from the table components
												// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].onclick = headerInputFileClickHandler;

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										// console.log("headerInputFileButtons:", headerInputFileButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating radio_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let radioButtonGroups;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										radioButtonGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("radio_regular_form");
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (radioButtonGroups) {
											if (radioButtonGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(radioButtonGroups).forEach((radioButtonGroup, j) => {
												let clonedRadioButtonGroup = radioButtonGroup.cloneNode(true);
												// Copy styles from the original to the cloned button
												copyStyles2(radioButtonGroup, clonedRadioButtonGroup);

												// Change IDs and append cloned radio button groups to the container
												clonedRadioButtonGroup.setAttribute("id", `radio_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each radio button in the group
												clonedRadioButtonGroup.querySelectorAll('input[type="radio"]').forEach((radioButton) => {
													radioButton.addEventListener("click", (event) => {
														// Get the selected radio button's value and id
														const selectedValue = event.target.value;
														const selectedId = event.target.id;
														const selectedDataDbId = event.target.getAttribute("data_db_id") ? event.target.getAttribute("data_db_id") : "";

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_radio_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																radioButtonId: `${selectedId}`,
																selectedValue: `${selectedValue}`,
																selectedDataDbId: `${selectedDataDbId}`,
															}),
														);

														// You can also perform other actions based on the selected radio button
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedRadioButtonGroup);
											});
										}

										// Hide the original radio button groups in the header column
										Array.from(radioButtonGroups).forEach((radioButtonGroup) => {
											radioButtonGroup.style.display = "none";
										});
										// }
										// console.log("radioButtonGroups:", radioButtonGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroups;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form");
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroups) {
											if (checkboxGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroups).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroups).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										// console.log("checkboxGroups:", checkboxGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form_new
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroupsV2;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroupsV2 = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form_new");
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroupsV2) {
											if (checkboxGroupsV2.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroupsV2).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_v2_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_v2_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroupsV2).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										// console.log("checkboxGroupsV2:", checkboxGroupsV2);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_select
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dropdownContainers;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dropdownContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_select_container");
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dropdownContainers) {
											if (dropdownContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(dropdownContainers).forEach((dropdownContainer, j) => {
												let dropdown = dropdownContainer.getElementsByTagName("select")[0];
												let dropdownClone = dropdown.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dropdown, dropdownClone);

												// Change IDs and append cloned dropdowns to the container
												dropdownClone.setAttribute("id", `input_select_table${id}_row${idx}_column${i}_${j}`);
												dropdownClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const selectedValue = event.target.value;
													const selectedOption = event.target.options[event.target.selectedIndex];
													const selectedId = selectedOption.id;

													// Get the data-db-id attribute value of the selected option
													const selectedDataDbId = selectedOption.getAttribute("data_db_id") ? selectedOption.getAttribute("data_db_id") : "";

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_input_select_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															selectedValue: `${selectedValue}`,
															selectedId: `${selectedId}`,
															selectedDataDbId: `${selectedDataDbId}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dropdownClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dropdownContainers).forEach((dropdownContainer) => {
												dropdownContainer.style.display = "none";
											});
										}
										// }
										// console.log("dropdownContainers:", dropdownContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_text_field
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputTextContainers;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputTextContainers) {
											if (inputTextContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
												let inputText = inputTextContainer.getElementsByTagName("input")[0];
												let inputTextClone = inputText.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputText, inputTextClone);

												// Change IDs and append cloned dropdowns to the container
												inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
												inputTextClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_text_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputTextClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputTextContainers).forEach((inputTextContainer) => {
												inputTextContainer.style.display = "none";
											});
										}
										// }
										// console.log("inputTextContainers:", inputTextContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_number_field
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputNumberContainers;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputNumberContainers) {
											if (inputNumberContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
												let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
												let inputNumberClone = inputNumber.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputNumber, inputNumberClone);

												// Change IDs and append cloned dropdowns to the container
												inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
												inputNumberClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_number_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputNumberClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
												inputNumberContainer.style.display = "none";
											});
										}
										// }
										// console.log("inputNumberContainers:", inputNumberContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating send email buttons
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let sendEmailButtons;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										sendEmailButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("send-email-button-container");
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (sendEmailButtons) {
											if (sendEmailButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(sendEmailButtons).forEach((j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedEmailButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedEmailButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedEmailButton.setAttribute("id", `send_email_button_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedEmailButton.getElementsByClassName("send-email-button")[0].setAttribute("id", `send_email_btn_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0]) {
													clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0].setAttribute("id", `send_email_btn_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedEmailButton);

												const sendEmailHandler = async function (sendEmailButtonContainer) {
													let selectedFile;
													// reason - because this script was getting called multiple times, the dialog element was getting added multiple times so this code will be removing all the previously appended ones and then create a new one on-the-go
													if (sendEmailButtonContainer) {
														let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
														modalAlreadyExists.forEach((modal) => {
															// console.log("removing modal 2", modal);
															modal.remove();
														});

														// // fetch filter call for content
														// let filteredResponse = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${extractTableName(updatedProps.customUrlValue)}`, {
														// 	method: "POST",
														// 	headers: {
														// 		'Content-Type': "application/json",
														// 		Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
														// 	},
														// 	body: JSON.stringify({
														// 	    filterData: {
														// 	        _id: `${obj[updatedProps.DataModelObjIdKeyname]}`,
														// 	    }
														// 	}),
														// });

														// let filteredResponseData = await filteredResponse.json();

														// Get the keys from updatedProps.HeaderFields object and from HTML attributes
														const headerFieldKeys = Object.keys(updatedProps.HeaderFields);
														const htmlAttributeKeys = Array.from(customTableContainer.attributes)
															.filter((attr) => attr.name.includes("headerfield_"))
															.map((attr) => attr.value);

														// console.log("htmlAttributeKeys", htmlAttributeKeys);

														// Combine the keys from both sources
														const allKeys = [...headerFieldKeys, ...htmlAttributeKeys];

														// Accumulate all key-value pairs
														const keyValuePairs = [];

														// Get the values corresponding to the keys from the obj object
														htmlAttributeKeys.forEach((key) => {
															let value;
															if (/:/.test(key)) {
																// If the key contains a colon (indicating nested properties), split it
																const [mainKey, subKey] = key.split(/\s*:\s*/);
																// Check if the main key exists in obj
																if (obj[`${mainKey}_data`]) {
																	// Check if the main key points to an object
																	if (typeof obj[`${mainKey}_data`] === "object" && obj[`${mainKey}_data`][subKey]) {
																		value = obj[`${mainKey}_data`][subKey];
																		keyValuePairs.push(`${subKey}: ${value}`);
																	}
																}
															} else {
																value = obj[key];
																keyValuePairs.push(`${key}: ${value}`);
															}
															// if (value) {
															//     keyValuePairs.push(`${key}: ${value}`);
															// }
														});

														// console.log("allKeys, keyValuePairs", allKeys, keyValuePairs);

														// Join all key-value pairs into a single string
														const filteredKeyValuePairs = keyValuePairs.join("\n");

														// console.log("allKeys, filteredKeyValuePairs", allKeys, filteredKeyValuePairs);

														// Create a dialog modal with styling
														const dynamicEmailSendingModal = document.createElement("dialog");
														dynamicEmailSendingModal.setAttribute("class", `custom-email-modal`);
														dynamicEmailSendingModal.setAttribute("id", `custom-email-modal-${sendEmailButtonContainer.id}`);
														Object.assign(dynamicEmailSendingModal.style, {
															margin: "auto",
															width: "35vw",
															overflow: "auto",
															backgroundColor: "white",
															boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
															border: "1px solid #ccc",
															borderRadius: "20px",
															zIndex: "1000",
															padding: "0 0 1% 0",
														});

														// modal UI header & footer
														let modalHeader = document.createElement("div");
														Object.assign(modalHeader.style, {
															// backgroundColor: "#1991E7",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															color: "white",
															fontSize: "1vw",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});
														modalHeader.innerHTML = "Compose Message";

														const modalFooter = document.createElement("div");
														Object.assign(modalFooter.style, {
															backgroundColor: "transparent",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});

														// Create a form element
														const emailForm = document.createElement("form");
														Object.assign(emailForm.style, {
															padding: "3% 6%",
															display: "flex",
															flexDirection: "column",
														});

														// Check if EmailFromDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailFromDynamicCheckbox) {
														const emailFromDiv = document.createElement("div");
														Object.assign(emailFromDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailFromLabel = document.createElement("label");
														emailFromLabel.innerHTML = "From";
														// Create an input email field for the sender's email address with styling
														const emailFromField = document.createElement("input");
														Object.assign(emailFromField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailFromField, {
															type: "email",
															id: `emailFrom-${sendEmailButtonContainer.id}`,
															name: "emailFrom",
															placeholder: "Sender's Email Address",
														});
														// visiblity will be hidden because this field is not actually required and it needs to occupy same space for UI alignment purposes
														const emailFromRequiredFieldLabel = document.createElement("span");
														emailFromRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailFromRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
															visibility: "hidden",
														});

														// Append the email input field to the form
														emailFromDiv.appendChild(emailFromLabel);
														emailFromDiv.appendChild(emailFromField);
														emailFromDiv.appendChild(emailFromRequiredFieldLabel);
														emailForm.appendChild(emailFromDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailToDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailToDynamicCheckbox) {
														const emailToDiv = document.createElement("div");
														Object.assign(emailToDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailToLabel = document.createElement("label");
														emailToLabel.innerHTML = "To";
														// Create an input email field for the receiver's email address with styling
														const emailToField = document.createElement("input");
														Object.assign(emailToField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailToField, {
															type: "text",
															id: `emailTo-${sendEmailButtonContainer.id}`,
															name: "emailTo",
															placeholder: "Receiver's Email Addresses (comma separated)",
														});
														const emailToRequiredFieldLabel = document.createElement("span");
														emailToRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailToRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailToDiv.appendChild(emailToLabel);
														emailToDiv.appendChild(emailToField);
														emailToDiv.appendChild(emailToRequiredFieldLabel);
														emailForm.appendChild(emailToDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailSubjectDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailSubjectDynamicCheckbox) {
														const emailSubjectDiv = document.createElement("div");
														Object.assign(emailSubjectDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailSubjectLabel = document.createElement("label");
														emailSubjectLabel.innerHTML = "Subject";
														// Create an input email field for the receiver's email address with styling
														const emailSubjectField = document.createElement("input");
														Object.assign(emailSubjectField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailSubjectField, {
															type: "text",
															id: `emailSubject-${sendEmailButtonContainer.id}`,
															name: "emailSubject",
															placeholder: "Email's subject",
														});
														const emailSubjectRequiredFieldLabel = document.createElement("span");
														emailSubjectRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailSubjectRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailSubjectDiv.appendChild(emailSubjectLabel);
														emailSubjectDiv.appendChild(emailSubjectField);
														emailSubjectDiv.appendChild(emailSubjectRequiredFieldLabel);
														emailForm.appendChild(emailSubjectDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailBodyDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailBodyDynamicCheckbox) {
														const emailBodyDiv = document.createElement("div");
														Object.assign(emailBodyDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailBodyLabel = document.createElement("label");
														emailBodyLabel.innerHTML = "Body";
														// Create an input email field for the receiver's email address with styling
														const emailBodyField = document.createElement("textarea");
														Object.assign(emailBodyField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailBodyField, {
															rows: "5",
															cols: "10",
															id: `emailBody-${sendEmailButtonContainer.id}`,
															name: "emailBody",
															placeholder: "Email's body",
														});
														const emailBodyRequiredFieldLabel = document.createElement("span");
														emailBodyRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailBodyRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														emailBodyField.value = filteredKeyValuePairs;

														// Append the email input field to the form
														emailBodyDiv.appendChild(emailBodyLabel);
														emailBodyDiv.appendChild(emailBodyField);
														emailBodyDiv.appendChild(emailBodyRequiredFieldLabel);
														emailForm.appendChild(emailBodyDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if FileAttachmentDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.FileAttachmentDynamicCheckbox) {
														// Create an input email field for the receiver's email address with styling
														const fileAttachmentField = document.createElement("input");
														Object.assign(fileAttachmentField.style, {
															// marginBottom: "10px",
															// padding: "5px",
															// width: "80%",
															// borderRadius: "5px",
															// backgroundColor: "transparent",
															// boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
															display: "none",
														});
														Object.assign(fileAttachmentField, {
															type: "file",
															id: `fileAttachment-${sendEmailButtonContainer.id}`,
															name: "fileAttachment",
														});

														// Add event listener for the file input field change event
														fileAttachmentField.addEventListener("change", (event) => {
															selectedFile = event.target.files[0];
															// console.log("Selected file:", selectedFile);
															// You can do further processing with the selected file here
														});

														// Create a label for the file input button
														const fileAttachmentLabel = document.createElement("label");
														fileAttachmentLabel.setAttribute("for", "fileAttachmentInput"); // Set the 'for' attribute to match the input field's id
														fileAttachmentLabel.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16"><path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/></svg>';
														Object.assign(fileAttachmentLabel.style, {
															cursor: "pointer",
														});

														// Attach click event to the label
														fileAttachmentLabel.addEventListener("click", () => {
															fileAttachmentField.click();
														});

														// Append the email input field to the form
														modalFooter.appendChild(fileAttachmentLabel);
														modalFooter.appendChild(fileAttachmentField);
														// }
														// // else take directly from props
														// else {}

														// Create a submit button
														const submitButton = document.createElement("button");
														Object.assign(submitButton.style, {
															// marginBottom: "3%",
															// marginRight: "4%",
															alignSelf: "flex-end",
															padding: "1.5% 3%",
															border: "none",
															borderRadius: "25px",
															// backgroundColor: "#007bff",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															color: "white",
															cursor: "pointer",
														});
														submitButton.textContent = "Send";
														submitButton.type = "submit";

														// Add event listener for form submission
														submitButton.addEventListener("click", async (event) => {
															event.preventDefault(); // Prevent form submission

															// Get the entered email values
															const emailFromValue = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`).value : "";
															let emailToValue = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`).value : "";
															emailToValue = emailToValue.includes(",") ? emailToValue.split(/\s*,\s*/) : emailToValue;
															const emailSubjectValue = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`).value : "";
															const emailBodyValue = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`).value : "";
															const fileAttachmentValue = emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`).value : "";

															// to preserve line breaks in email body
															const formattedEmailBodyValue = emailBodyValue.replace(/\n/g, "<br>");

															// console.log("form values", emailFromValue, emailToValue, emailSubjectValue, emailBodyValue, formattedEmailBodyValue, fileAttachmentValue);
															// console.log("selectedFile", selectedFile);

															// Create a FormData object
															const formData = new FormData();

															// Append form data to FormData object
															formData.append("emailFrom", emailFromValue);
															formData.append("emailTo", emailToValue);
															formData.append("emailSubject", emailSubjectValue);
															formData.append("emailBody", formattedEmailBodyValue);
															formData.append("fileAttachment", selectedFile);

															// Make a fetch call to the API with the entered email address
															try {
																// send email if email - to, subject, body are provided
																if (emailToValue !== "" && emailSubjectValue !== "" && emailBodyValue !== "") {
																	const response = await fetch(`${updatedProps.API_HOST}user/custom_email`, {
																		method: "POST",
																		body: formData,
																	});

																	if (response.ok) {
																		// console.log("Email sent successfully!");
																		showNotification(true, "Email sent successfully!");
																	} else {
																		console.error("Failed to send email.");
																		showNotification(false, "Failed to send email. Please check the provided values.");
																	}
																}
															} catch (error) {
																console.error("Error sending email:", error);
																showNotification(false, "Error sending email. Please check the provided values.");
															}

															// Close the modal after form submission
															dynamicEmailSendingModal.close();
														});

														// Create a close button
														const closeButton = document.createElement("button");
														closeButton.setAttribute("class", "custom-email-modal-close");
														closeButton.setAttribute("id", `custom-email-modal-close-${sendEmailButtonContainer.id}`);
														Object.assign(closeButton.style, {
															border: "none",
															borderRadius: "3px",
															backgroundColor: "transparent",
															color: "white",
															cursor: "pointer",
														});
														closeButton.textContent = "X";
														closeButton.type = "button";

														// Add event listener for closing the modal
														closeButton.addEventListener("click", () => {
															dynamicEmailSendingModal.close();
														});

														// Append the form and close button to the modal
														dynamicEmailSendingModal.appendChild(modalHeader);
														dynamicEmailSendingModal.appendChild(emailForm);
														modalHeader.appendChild(closeButton);
														modalFooter.appendChild(submitButton);
														dynamicEmailSendingModal.appendChild(modalFooter);

														// Append the modal to the document body
														document.body.appendChild(dynamicEmailSendingModal);

														// Show the modal
														dynamicEmailSendingModal.showModal();
													}
												};

												// adding click event on stellar-button from the table components
												clonedEmailButton.getElementsByClassName("send-email-button")[0].addEventListener("click", function (event) {
													// console.log("send-email-button clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_send_email_button_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedEmailButton.getAttribute("id")}`,
														}),
													);

													sendEmailHandler(clonedEmailButton);
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										// console.log("sendEmailButtons:", sendEmailButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form text_area
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let normalTextAreaContainers;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										normalTextAreaContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("normal_text_area_regular_form");
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (normalTextAreaContainers) {
											if (normalTextAreaContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer, j) => {
												// let inputText = inputTextContainer.getElementsByTagName('input')[0];
												let normalTextAreaContainerClone = normalTextAreaContainer.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(normalTextAreaContainer, normalTextAreaContainerClone);

												// Change IDs and append cloned dropdowns to the container
												normalTextAreaContainerClone.setAttribute("id", `normal_text_area_field_table${id}_row${idx}_column${i}_${j}`);

												// Event listener for input event
												normalTextAreaContainerClone.addEventListener("input", (event) => {
													const enteredValue = event.target.value;
													localStorage.setItem(
														`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);
												});

												// Event listener for keypress event with Enter key
												normalTextAreaContainerClone.addEventListener("keypress", (event) => {
													if (event.key === "Enter") {
														const enteredValue = event.target.value;
														localStorage.setItem(
															`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
													}
												});

												// Append the dropdown to the table cell
												td.appendChild(normalTextAreaContainerClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer) => {
												normalTextAreaContainer.style.display = "none";
											});
										}
										// }
										// console.log("normalTextAreaContainers:", normalTextAreaContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating custom-table-row-selection-checkbox-container
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let tableRowSelectionCheckboxContainers;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (tableRowSelectionCheckboxContainers) {
											// if (tableRowSelectionCheckboxContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// }

											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
												let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
												let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

												// to store row info accross page change
												const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

												// Change IDs and append cloned dropdowns to the container
												tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
												
												// Retrieve stored data from session storage
									            let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

									            // Check if this checkbox should be checked
									            const existingData = storedData.find(
									                (item) =>
									                    item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
									                    item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
									                    item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
									                    item.paginationIndex === currentPageIndex
									            );
									            if (existingData) {
									                tableRowSelectionCheckboxClone.checked = true;
									            }
												
												tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
													const enteredValue = event.target.value;
													const isChecked = event.target.checked; // Check if the checkbox is checked

													// to store row info accross page change
													const checkboxData = {
												        "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												        tableId: `${id}`,
												        rowId: `custom_table_tbody_row${idx}_table${id}`,
												        cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												        inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
												        enteredValue: `${enteredValue}`,
												        paginationIndex: currentPageIndex, // Include the current page index
												    };

												    // Retrieve existing data
												    let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

													if (isChecked) {
														// Checkbox is checked, add object to local storage
														localStorage.setItem(
															`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
														// // console.log("currentPageIndex", currentPageIndex);

														// If checked, check if the entry already exists
									                    const existingIndex = storedData.findIndex(
									                        (item) =>
									                            item.rowId === checkboxData.rowId &&
									                            item.cellId === checkboxData.cellId &&
									                            item.paginationIndex === checkboxData.paginationIndex &&
									                            item.inputId === checkboxData.inputId
									                    );

									                    if (existingIndex !== -1) {
									                        // Update existing entry
									                        storedData[existingIndex] = checkboxData;
									                    } else {
									                        // Add new entry
									                        storedData.push(checkboxData);
									                    }
													} else {
														// Checkbox is unchecked, remove object from local storage
														localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
														
														// If unchecked, remove the entry
									                    storedData = storedData.filter(
									                        (item) =>
									                            !(item.rowId === checkboxData.rowId &&
									                              item.cellId === checkboxData.cellId &&
									                              item.paginationIndex === checkboxData.paginationIndex &&
									                              item.inputId === checkboxData.inputId)
									                    );
													}

													// You can also perform other actions based on the checkbox state
													// For example, update UI, make API calls, etc.
													
													// Update session storage with the modified array
													sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
												});

												// Append the dropdown to the table cell
												td.appendChild(tableRowSelectionCheckboxClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
												tableRowSelectionCheckboxContainer.style.display = "none";
											});
										}
										// }
										// console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_date_time field
									try {
										// if (i === Number(table_selected_column)) {
										// console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dateTimeInputContainers;
										// if (table_selected_column) {
										// console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
										// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dateTimeInputContainers) {
											// if (dateTimeInputContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// 	td.style.scrollbarColor = `transparent transparent`;
											// }

											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
												let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
												let dateTimeInputClone = dateTimeInput.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dateTimeInput, dateTimeInputClone);

												// Change IDs and append cloned dropdowns to the container
												dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
												dateTimeInputClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_date_time_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dateTimeInputClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
												dateTimeInputContainer.style.display = "none";
											});
										}
										// }
										// console.log("dateTimeInputContainers:", dateTimeInputContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating toggle
									try {
										// plot only if the field_type of header attribute is boolean
										if (fieldType === 'Boolean') {
											// if (i === Number(table_selected_column)) {
											// console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let toggleContainers;
											// if (table_selected_column) {
											// console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// // console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											// console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											toggleContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('.custom-table-toggle-container');
											// console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (toggleContainers) {
												// if there are toggle elements being replicated then don't show the data model content in their table cells along with the toggle replicated components
												td.textContent = "";

												// if (toggleContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(toggleContainers).forEach((toggleContainer, j) => {
												    let toggleInnerContainer = toggleContainer.getElementsByClassName("custom-table-inner-toggle-container")[0];
												    let toggleClone = toggleInnerContainer.cloneNode(true);
												    // Copy styles from the original to the cloned dropdown
												    copyStyles(toggleInnerContainer, toggleClone);

												    // Initial state of the toggle
												    let isOn = false;

												    // Change IDs and append cloned dropdowns to the container
												    toggleClone.setAttribute("id", `custom_table_inner_toggle_container_table${id}_row${idx}_column${i}_${j}`);
												    if (toggleClone.getElementsByClassName("custom-table-toggle")[0]) {
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].setAttribute("id", `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`);

												        // update toggle once before/outside click event also so that it initializes according to the value coming from backend. If `content` value is true, toggle should be on, else off.
												        if (content) {
												        	isOn = true;
												        }
												        updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));

												        // now attach event to make the toggle function changing color on on/off actions and sending PUT api calls
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].addEventListener("click", (event) => {
												            isOn = !isOn;

												            // if the cell value is false, change it to true before sending it in PUT call payload, else send it as it it. When toggle is on, 'true' value should be send in the backend.
												            let cellValue = content ? false : true;

												            // a PUT api call will be made to update that particular record of the given table
												            updateTable(cellValue, `${obj[updatedProps.DataModelObjIdKeyname]}`, updatedProps.updatingCustomUrlValue, updatedProps.updatingBaseUrlValue, updatedProps.updatingApiPathValue, updatedProps.updatingApiQueryParamValue, updatedProps.updatingApiTableAttributeValue, updatedProps.updatingApiMethodValue, updatedProps.updatingApiUsernameValue, updatedProps.updatingApiPasswordValue, updatedProps.updatingApiBearerTokenValue, updatedProps.updatingApiMoreHeadersValue);

												            updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));
												            // console.log(`Toggle is now: ${isOn ? 'ON' : 'OFF'}`, content);

												            // Construct the localStorage key
												            let toggleLocalStorageKey = `clicked_custom_table_toggle_table${id}_column${i}`;

												            // Retrieve existing data from local storage
												            let togglesData = JSON.parse(localStorage.getItem(toggleLocalStorageKey)) || [];

												            // Create the new toggle data object
												            let newToggleData = {
												                "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												                tableId: `${id}`,
												                rowId: `custom_table_tbody_row${idx}_table${id}`,
												                cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												                toggleEleId: `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`,
												                toggleStatus: isOn,
												            };

												            // Check if the toggle already exists
												            let existingToggleIndex = togglesData.findIndex(toggle => toggle.toggleEleId === newToggleData.toggleEleId);

												            if (existingToggleIndex !== -1) {
												                // Update the existing toggle data
												                togglesData[existingToggleIndex] = newToggleData;
												            } else {
												                // Add the new toggle data
												                togglesData.push(newToggleData);
												            }

												            // Update the local storage with the new/updated toggle data, column-wise
												            localStorage.setItem(toggleLocalStorageKey, JSON.stringify(togglesData));
												        });
												    }

												    // Append the dropdown to the table cell
												    td.appendChild(toggleClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(toggleContainers).forEach((toggleContainer) => {
													toggleContainer.style.display = "none";
												});
											}
											// }
											// console.log("toggleContainers:", toggleContainers);
											// }
										}										
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating rich text input
                                    try {
                                        // console.log("i", i);

                                        let inputTextContainers;

                                        // console.log("customTableThead", customTableThead);

                                        // console.log( "thead th i", customTableThead .getElementsByTagName("tr")[0] .querySelectorAll("th")[i] );

                                        let richTextIndexesData = updatedProps.richTextIndexes;
                                        // console.log( "richTextIndexesData::::::::::", richTextIndexesData );

                                        if (richTextIndexesData.length > 0) {
                                            richTextIndexesData.forEach((x) => {
                                                // console.log("richTextIndexesData x::::::::::", x);
                                                // console.log("richTextIndexesData::::::", i);
                                                // console.log("richTextIndexesData::::::", i);
                                                if (x === i) {
                                                    td.innerHTML = "";
                                                    let wrapperDiv = document.createElement("div");
                                                    wrapperDiv.setAttribute(
                                                        "id",
                                                        `rich_text_container_${idx}_${id}`
                                                    );
                                                    wrapperDiv.setAttribute(
                                                        "class",
                                                        "rich_text_table_container"
                                                    );

                                                    wrapperDiv.style.backgroundColor = "white";

                                                    td.appendChild(wrapperDiv);

                                                    let quill = new Quill(wrapperDiv, {
                                                        modules: {
                                                            toolbar: updatedProps.richTextOptions.toolbar ? [
                                                                [{
                                                                    header: [1, 2, 3, 4, 5, 6, false]
                                                                }],
                                                                ["bold", "italic", "underline", "strike"],
                                                                [{
                                                                    list: "bullet"
                                                                }, {
                                                                    list: "ordered"
                                                                }],
                                                                ["link"],
                                                                [{
                                                                    align: []
                                                                }],
                                                                [{
                                                                    color: []
                                                                }, {
                                                                    background: []
                                                                }],
                                                            ] : false,
                                                        },
                                                        placeholder: "",
                                                        theme: "snow",
                                                    });

                                                    updatedProps.richTextOptions.readOnly ? quill.enable(false) : quill.enable(true);

                                                    if (content) {
                                                        // console.log('contentRich:::::::',content)
                                                        let jsonContent = JSON.parse(content);
                                                        // console.log('jsonContent:::::::::',jsonContent)
                                                        // console.log('type jsonContent:::::::::',typeof jsonContent)
                                                        if (jsonContent ) {
                                                            // console.log('inside jsonContent')
                                                            quill.setContents(jsonContent);
                                                        }
                                                    } else {
                                                        let richTextContent;
                                                        richTextContent = sessionStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );
                                                        if (!richTextContent) richTextContent = localStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );

                                                        let parsedData = JSON.parse(richTextContent);
                                                        if (parsedData && parsedData.delta) {
                                                            quill.setContents(parsedData.delta);
                                                        }
                                                            
                                                        
                                                   }

                                                    quill.on("text-change", () => {
                                                        const delta = quill.getContents();

                                                        let deltaRef = {
                                                            "data-db-id": `${
                                                                obj[updatedProps.DataModelObjIdKeyname]
                                                                }`,
                                                            delta:delta
                                                        }
                                                        
                                                        localStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );
                                                        
                                                        sessionStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );
                                                    });                                                   
                                                }
                                            });
                                        }

                                        // }
                                        // console.log("inputTextContainers:", inputTextContainers);
                                        // }
                                    } catch (e) {
										// statements
										console.log(e);
									}

									tr.append(td);
								}

								if (customTableTbody) {
									customTableTbody.append(tr);
								}								
							});
						}
					}

					// if the response has a nested array-of-objects within the objects of main array-of-objects
					else if (hasArrayOfObjectsWithinArray(apiData)) {
						console.log("###");

						apiData.forEach((obj, idx) => {
							let tr = document.createElement("tr");
							tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
							tr.setAttribute("class", "custom-table-tbody-row");
							// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
							tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
							tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
							tr.style.width = "100%";
							tr.style.height = "auto";
							if (idx % 2 !== 0) {
								tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
								// hover effect
								tr.addEventListener("mouseover", function () {
									tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
									// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
								});
								tr.addEventListener("mouseleave", function () {
									tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
									// tr.style.scrollbarColor = `${updatedProps.tBodyOddTrBackgroundColor} ${updatedProps.tBodyOddTrBackgroundColor}`;
								});
							} else {
								tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
								// hover effect
								tr.addEventListener("mouseover", function () {
									tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
									// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
								});
								tr.addEventListener("mouseleave", function () {
									tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
									// tr.style.scrollbarColor = `${updatedProps.tBodyEvenTrBackgroundColor} ${updatedProps.tBodyEvenTrBackgroundColor}`;
								});
							}

							// get data and add it as table's column
							for (let i = 0; i < updatedProps.numColsInputValue; i++) {
								let content, nestedContent = [], convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
								let nestedColumn; // variable to check if that column is going to be nested or not

								// field name and type are for toggle replication
								let fieldName;

								// using updatedProps.HeaderFields because only these are present in the downloading code
								// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
								// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
								// }

								// to get the values of the header keynames entered in header input fields for plotting
								try {
									if (`HeaderField_${i}` in updatedProps) {
										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (updatedProps[`HeaderField_${i}`].includes("-")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*-\s*/g, "-");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split("-");

											const referenceArrayObjName = `${referenceArray[0]}`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object in not undefined, then find the further keyname
											if (isArrayofObjects(obj[referenceArrayObjName])) {
												obj[referenceArrayObjName].forEach((nesObj) => {
													nestedContent.push(nesObj[referenceArrayKeyName]);
												});
												content = nestedContent;
											}
											// content = obj[referenceArrayObjName][referenceArrayKeyName];
										} else {
											// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 0", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
												numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
												let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

												convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
												console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

												content = convertedNumber;
												console.log("converted content 0", convertedNumber, content);
											} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
												timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
												let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

												convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
												console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

												content = convertedNumber;
												console.log("converted content 0", convertedNumber, content);
											} else {
											content = obj[`${updatedProps[`HeaderField_${i}`]}`];
											}
										}
										console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
									} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (updatedProps.HeaderFields[`HeaderField_${i}`].includes("-")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*-\s*/g, "-");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split("-");

											const referenceArrayObjName = `${referenceArray[0]}`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object in not undefined, then find the further keyname
											if (isArrayofObjects(obj[referenceArrayObjName])) {
												obj[referenceArrayObjName].forEach((nesObj) => {
													nestedContent.push(nesObj[referenceArrayKeyName]);
												});
												content = nestedContent;
											}
											// content = obj[referenceArrayObjName][referenceArrayKeyName];
										} else {
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 1", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else {
												content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
											}
											// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
										}
										console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
									} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
										// Get the value of the attribute from 'this'
										console.log("customTableContainer", customTableContainer);
										const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (headerFieldAttribute.includes("-")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = headerFieldAttribute.replace(/\s*-\s*/g, "-");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split("-");

											const referenceArrayObjName = `${referenceArray[0]}`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object is not undefined, then find the further keyname
											if (isArrayofObjects(obj[referenceArrayObjName])) {
												obj[referenceArrayObjName].forEach((nesObj) => {
													nestedContent.push(nesObj[referenceArrayKeyName]);
												});
												content = nestedContent;
											}
										} else {
											// content = obj[headerFieldAttribute];
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[headerFieldAttribute];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[headerFieldAttribute];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 2", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
												content = obj[headerFieldAttribute];
											}
										}
										console.log("headerFieldAttribute", headerFieldAttribute, content);
									}
								} catch (e) {
									// statements
									console.error(e);
								}

								try {
									if (`HeaderField_${i}` in updatedProps) {
										// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
										if (updatedProps[`HeaderField_${i}`].includes(":")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split(":");

											const referenceArrayObjName = `${referenceArray[0]}_data`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object in not undefined, then find the further keyname
											if (obj[referenceArrayObjName]) {
												content = obj[referenceArrayObjName][referenceArrayKeyName];
											}
											// content = obj[referenceArrayObjName][referenceArrayKeyName];
										} else {
											// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 0", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
												numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
												let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

												convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
												console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

												content = convertedNumber;
												console.log("converted content 0", convertedNumber, content);
											} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else {
												content = obj[`${updatedProps[`HeaderField_${i}`]}`];
											}

											// applying function from session storage on specific column (by index)
											if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
												try {
													// Retrieve the function string from sessionStorage
													const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

													if (storedFuncString) {
														// Recreate the function using the Function constructor
														const recreatedFunction = new Function(`return (${storedFuncString})`)();
														console.log('Function recreated:', recreatedFunction);

														// Use the recreated function
														content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
														console.log('+recreatedFunction content+', content);
													}
												} catch (error) {
													console.error(error);
												}
											}
										}
										console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
										// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
										fieldName = updatedProps[`HeaderField_${i}`];
									} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
										// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
										if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split(":");

											const referenceArrayObjName = `${referenceArray[0]}_data`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object in not undefined, then find the further keyname
											if (obj[referenceArrayObjName]) {
												content = obj[referenceArrayObjName][referenceArrayKeyName];
											}
											// content = obj[referenceArrayObjName][referenceArrayKeyName];
										} else {
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 1", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
												numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

												convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
												console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

												content = convertedNumber;
												console.log("converted content 1", convertedNumber, content);
											} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else {
												content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
											}

											// applying function from session storage on specific column (by index)
											if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
												try {
													// Retrieve the function string from sessionStorage
													const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

													if (storedFuncString) {
														// Recreate the function using the Function constructor
														const recreatedFunction = new Function(`return (${storedFuncString})`)();
														console.log('Function recreated:', recreatedFunction);

														// Use the recreated function
														content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
														console.log('+recreatedFunction content+', content);
													}
												} catch (error) {
													console.error(error);
												}
											}
											// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
										}
										console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
										// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
										fieldName = updatedProps.HeaderFields[`HeaderField_${i}`];
									} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
										// Get the value of the attribute from 'this'
										console.log("customTableContainer", customTableContainer);
										const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

										// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
										if (headerFieldAttribute.includes(":")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split(":");

											const referenceArrayObjName = `${referenceArray[0]}_data`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object is not undefined, then find the further keyname
											if (obj[referenceArrayObjName]) {
												content = obj[referenceArrayObjName][referenceArrayKeyName];
											}
										} else {
											// content = obj[headerFieldAttribute];
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[headerFieldAttribute];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[headerFieldAttribute];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 2", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
												numberString = obj[headerFieldAttribute];
												let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

												convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
												console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

												content = convertedNumber;
												console.log("converted content 2", convertedNumber, content);
											} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
												content = obj[headerFieldAttribute];
											}

											// applying function from session storage on specific column (by index)
											if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
												try {
													// Retrieve the function string from sessionStorage
													const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

													if (storedFuncString) {
														// Recreate the function using the Function constructor
														const recreatedFunction = new Function(`return (${storedFuncString})`)();
														console.log('Function recreated:', recreatedFunction);

														// Use the recreated function
														content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
														console.log('+recreatedFunction content+', content);
													}
												} catch (error) {
													console.error(error);
												}
											}
										}
										console.log("headerFieldAttribute", headerFieldAttribute, content);
										// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
										fieldName = headerFieldAttribute;
									}
								} catch (e) {
									// statements
									console.error(e);
								}

								// to replicate components based on number of rows in nested table - but in an independent column
								// if there is a reserved keyword "nested-column" in the headerField_i then using the structure of 'obj' append stellar buttons and checkboxes those many times in an indepedent column
								try {
									if (`HeaderField_${i}` in updatedProps) {
										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (updatedProps[`HeaderField_${i}`].includes("nested-column")) {
											nestedColumn = true;
										} else {
											nestedColumn = false;
										}
										console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
									} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (updatedProps.HeaderFields[`HeaderField_${i}`].includes("nested-column")) {
											nestedColumn = true;
										} else {
											nestedColumn = false;
										}
										console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
									} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
										// Get the value of the attribute from 'this'
										console.log("customTableContainer", customTableContainer);
										const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (headerFieldAttribute.includes("nested-column")) {
											nestedColumn = true;
										} else {
											nestedColumn = false;
										}
										console.log("headerFieldAttribute", headerFieldAttribute, content);
									}
								} catch (e) {
									// statements
									console.error(e);
								}

								// console.log("content, i", content, i);

								let td = document.createElement("td");
								td.setAttribute("data-gjs-type", "cell");
								// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
								td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
								td.style.padding = "0";

								td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
								td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
								td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
								td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
								td.style.borderCollapse = "collapse";
								td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
								td.style.color = `${updatedProps.tBodyTdFontColor}`;
								td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
								td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
								// plot content directly if its not an array
								td.innerHTML = content !== undefined && !Array.isArray(content) ? content : "";

								// if the content of the cell is not an array, still hande attaching of special components in the cells
								if (!Array.isArray(content)) {
									console.log("i here 1", i);
									// for replicating custom-table-row-selection-checkbox-container
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let tableRowSelectionCheckboxContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (tableRowSelectionCheckboxContainers) {
											// if (tableRowSelectionCheckboxContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// }

											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
												let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
												let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

												// to store row info accross page change
												const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

												// Change IDs and append cloned dropdowns to the container
												tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
												
												// Retrieve stored data from session storage
									            let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

									            // Check if this checkbox should be checked
									            const existingData = storedData.find(
									                (item) =>
									                    item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
									                    item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
									                    item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
									                    item.paginationIndex === currentPageIndex
									            );
									            if (existingData) {
									                tableRowSelectionCheckboxClone.checked = true;
									            }
												
												tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
													const enteredValue = event.target.value;
													const isChecked = event.target.checked; // Check if the checkbox is checked

													// to store row info accross page change
													const checkboxData = {
												        "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												        tableId: `${id}`,
												        rowId: `custom_table_tbody_row${idx}_table${id}`,
												        cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												        inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
												        enteredValue: `${enteredValue}`,
												        paginationIndex: currentPageIndex, // Include the current page index
												    };

												    // Retrieve existing data
												    let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

													if (isChecked) {
														// Checkbox is checked, add object to local storage
														localStorage.setItem(
															`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
														// console.log("currentPageIndex", currentPageIndex);

														// If checked, check if the entry already exists
									                    const existingIndex = storedData.findIndex(
									                        (item) =>
									                            item.rowId === checkboxData.rowId &&
									                            item.cellId === checkboxData.cellId &&
									                            item.paginationIndex === checkboxData.paginationIndex &&
									                            item.inputId === checkboxData.inputId
									                    );

									                    if (existingIndex !== -1) {
									                        // Update existing entry
									                        storedData[existingIndex] = checkboxData;
									                    } else {
									                        // Add new entry
									                        storedData.push(checkboxData);
									                    }
													} else {
														// Checkbox is unchecked, remove object from local storage
														localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
														
														// If unchecked, remove the entry
									                    storedData = storedData.filter(
									                        (item) =>
									                            !(item.rowId === checkboxData.rowId &&
									                              item.cellId === checkboxData.cellId &&
									                              item.paginationIndex === checkboxData.paginationIndex &&
									                              item.inputId === checkboxData.inputId)
									                    );
													}

													// You can also perform other actions based on the checkbox state
													// For example, update UI, make API calls, etc.
													
													// Update session storage with the modified array
													sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
												});

												// Append the dropdown to the table cell
												td.appendChild(tableRowSelectionCheckboxClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
												tableRowSelectionCheckboxContainer.style.display = "none";
											});
										}
										// }
										console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_date_time field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dateTimeInputContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dateTimeInputContainers) {
											// if (dateTimeInputContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// 	td.style.scrollbarColor = `transparent transparent`;
											// }

											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
												let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
												let dateTimeInputClone = dateTimeInput.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dateTimeInput, dateTimeInputClone);

												// Change IDs and append cloned dropdowns to the container
												dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
												dateTimeInputClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_date_time_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dateTimeInputClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
												dateTimeInputContainer.style.display = "none";
											});
										}
										// }
										console.log("dateTimeInputContainers:", dateTimeInputContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_text_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i here 2", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputTextContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputTextContainers) {
											if (inputTextContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
												let inputText = inputTextContainer.getElementsByTagName("input")[0];
												let inputTextClone = inputText.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputText, inputTextClone);

												// Change IDs and append cloned dropdowns to the container
												inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
												inputTextClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_text_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputTextClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputTextContainers).forEach((inputTextContainer) => {
												inputTextContainer.style.display = "none";
											});
										}
										// }
										console.log("inputTextContainers:", inputTextContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_number_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputNumberContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputNumberContainers) {
											if (inputNumberContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
												let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
												let inputNumberClone = inputNumber.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputNumber, inputNumberClone);

												// Change IDs and append cloned dropdowns to the container
												inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
												inputNumberClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_number_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputNumberClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
												inputNumberContainer.style.display = "none";
											});
										}
										// }
										console.log("inputNumberContainers:", inputNumberContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if content is an array, then create a table with nested rows and also attach special components in the nested cells of the nested rows
								if (Array.isArray(content)) {
									console.log("i here 3", i);
									let nestedTable = document.createElement("table");

									nestedTable.style.width = "none"; // Override width
									nestedTable.style.marginTop = "0"; // Override margin-top
									nestedTable.style.marginLeft = "0"; // Override margin-left

									content.forEach((row, row_idx) => {
										let nestedTr = document.createElement("tr");

										let nestedTd = document.createElement("td");
										nestedTd.setAttribute("data-gjs-type", "cell");
										// nestedTd.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
										nestedTd.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
										nestedTd.style.padding = "8px";

										nestedTd.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
										nestedTd.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
										nestedTd.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
										nestedTd.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
										nestedTd.style.borderCollapse = "collapse";
										nestedTd.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
										nestedTd.style.color = `${updatedProps.tBodyTdFontColor}`;
										nestedTd.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
										nestedTd.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;

										nestedTd.innerHTML = `${row}`;

										// // getting the nested table data
										// let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
										// // if that table name's key exists in obj and if it is not empty
										// if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {}

										// for replicating custom-table-row-selection-checkbox-container (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let tableRowSelectionCheckboxContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (tableRowSelectionCheckboxContainers) {
												// if (tableRowSelectionCheckboxContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// }

												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
													let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
													let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

													// Change IDs and append cloned dropdowns to the container
													tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
													tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
														const enteredValue = event.target.value;
														const isChecked = event.target.checked; // Check if the checkbox is checked

														if (isChecked) {
															// Checkbox is checked, add object to local storage

															// getting the nested table data
															let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
															// if that table name's key exists in obj and if it is not empty
															if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
																localStorage.setItem(
																	`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															} else {
																localStorage.setItem(
																	`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															}

															// localStorage.setItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`, JSON.stringify({
															//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
															//     tableId: `${id}`,
															//     rowId: `custom_table_tbody_row${idx}_table${id}`,
															//     nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
															//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															//     enteredValue: `${enteredValue}`,
															// }));
														} else {
															// Checkbox is unchecked, remove object from local storage
															localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
														}

														// You can also perform other actions based on the checkbox state
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													nestedTd.appendChild(tableRowSelectionCheckboxClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
													tableRowSelectionCheckboxContainer.style.display = "none";
												});
											}
											// }
											console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_date_time field (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let dateTimeInputContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (dateTimeInputContainers) {
												// if (dateTimeInputContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
													let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
													let dateTimeInputClone = dateTimeInput.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(dateTimeInput, dateTimeInputClone);

													// Change IDs and append cloned dropdowns to the container
													dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
													dateTimeInputClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														// getting the nested table data
														let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
														// if that table name's key exists in obj and if it is not empty
														if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
															localStorage.setItem(
																`clicked_form_date_time_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														} else {
															localStorage.setItem(
																`clicked_form_date_time_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														}

														// localStorage.setItem(`clicked_form_date_time_field_table${id}_column${i}_nestedtable_row_${j}`, JSON.stringify({
														//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
														//     tableId: `${id}`,
														//     rowId: `custom_table_tbody_row${idx}_table${id}`,
														//     nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
														//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
														//     enteredValue: `${enteredValue}`,
														// }));

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													nestedTd.appendChild(dateTimeInputClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
													dateTimeInputContainer.style.display = "none";
												});
											}
											// }
											console.log("dateTimeInputContainers:", dateTimeInputContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_text_field (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i here 4", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputTextContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (i === 8) {
												console.log("inputTextContainers i here:", inputTextContainers);
											}

											if (inputTextContainers) {
												if (inputTextContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
													let inputText = inputTextContainer.getElementsByTagName("input")[0];
													let inputTextClone = inputText.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputText, inputTextClone);

													// Change IDs and append cloned dropdowns to the container
													inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
													inputTextClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														// getting the nested table data
														let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
														// if that table name's key exists in obj and if it is not empty
														if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
															localStorage.setItem(
																`clicked_form_text_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														} else {
															localStorage.setItem(
																`clicked_form_text_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														}

														// localStorage.setItem(`clicked_form_text_field_table${id}_column${i}_${j}`, JSON.stringify({
														//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
														//     tableId: `${id}`,
														//     rowId: `custom_table_tbody_row${idx}_table${id}`,
														//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
														//     enteredValue: `${enteredValue}`,
														// }));

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													nestedTd.appendChild(inputTextClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputTextContainers).forEach((inputTextContainer) => {
													inputTextContainer.style.display = "none";
												});
											}
											// }
											console.log("inputTextContainers:", inputTextContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_number_field (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputNumberContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (inputNumberContainers) {
												if (inputNumberContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
													let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
													let inputNumberClone = inputNumber.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputNumber, inputNumberClone);

													// Change IDs and append cloned dropdowns to the container
													inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
													inputNumberClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														// getting the nested table data
														let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
														// if that table name's key exists in obj and if it is not empty
														if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
															localStorage.setItem(
																`clicked_form_number_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														} else {
															localStorage.setItem(
																`clicked_form_number_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														}

														// localStorage.setItem(`clicked_form_number_field_table${id}_column${i}_${j}`, JSON.stringify({
														//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
														//     tableId: `${id}`,
														//     rowId: `custom_table_tbody_row${idx}_table${id}`,
														//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
														//     enteredValue: `${enteredValue}`,
														// }));

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													nestedTd.appendChild(inputNumberClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
													inputNumberContainer.style.display = "none";
												});
											}
											// }
											console.log("inputNumberContainers:", inputNumberContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										nestedTr.appendChild(nestedTd);
										nestedTable.appendChild(nestedTr);
									});

									td.appendChild(nestedTable);
								}

								console.log("obj ++", obj);
								// if the column is nested then add as many amounts of stellar button in each td cell as there are objects
								if (nestedColumn) {
									console.log("adding nested stellar buttons");

									// find the nested table data in the obj and then loop over its elements which will create as many stellar buttons as required
									let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
									// if that table name's key exists in obj and if it is not empty
									if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
										// loop over each object in the array-of-objects in obj so that you can add those many exact number of stellar buttons in the cells and also get the _id reference
										obj[extractTableName(tableApi)].forEach((nestedObj, nestedObj_idx) => {
											// for replicating stellar buttons
											try {
												// if (i === Number(table_selected_column)) {
												console.log("i", i);
												// run this everytime api call is done due to user click event
												// find stellar-button component in header column
												let headerStellarButtons;
												// if (table_selected_column) {
												console.log("customTableThead", customTableThead);

												// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
												// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

												console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

												headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
												console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

												if (headerStellarButtons) {
													if (headerStellarButtons.length > 0) {
														td.style.height = "auto";
														td.style.overflow = "scroll";
														td.style.scrollbarColor = `transparent transparent`;
													}

													for (let j = 0; j < headerStellarButtons.length; j++) {
														// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
														let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

														// Copy the styles from the original button to the cloned button and its inner elements
														copyStyles(headerStellarButtons[j], clonedStellarButton);

														// changing the clone's IDs so that they are present and available individually in LB and in download
														clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
														clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
														clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);

														td.appendChild(clonedStellarButton);

														// adding click event on stellar-button from the table components
														clonedStellarButton.addEventListener("click", function (event) {
															console.log("stellar-button clicked", "saving data in local storage");
															// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

															// this registers all stellar button clicks on LS
															// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
															// this registers only by the individuals in the column header
															localStorage.setItem(
																`clicked_stellar_button_container_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	// 'nested-data-db-id': `${obj[extractTableName(tableApi)][nestedObj_idx][updatedProps.DataModelObjIdKeyname]}`,
																	"nested-data-db-id": `${nestedObj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${nestedObj_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	buttonId: `${clonedStellarButton.getAttribute("id")}`,
																}),
															);

															// highlight the table row that the stellar button has selected
															if (updatedProps.tBodyTrSelectColorHighlight)
																clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";
		
															customTable.onclick = function(event) {														
																// Check if the click is inside the custom table but outside the clicked row
																// if (
																// 	customTable.contains(event.target) && // Click is inside the customTable
																// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
																// ) {
																// 	console.log('@deselecting row', idx);
																// 	// Determine original color based on row index (even/odd)
																// 	const originalColor = idx % 2 === 0
																// 		? updatedProps.tBodyEvenTrBackgroundColor
																// 		: updatedProps.tBodyOddTrBackgroundColor;
		
																// 	// Reset the row's background color
																// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
																// }
		
																// Iterate over all rows in the table
																const rows = customTable.querySelectorAll("tr");
																rows.forEach((row, rowIndex) => {
																	// Skip the currently selected row
																	if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																		return;
																	}
		
																	// Reset the background color based on even/odd index
																	const originalColor = rowIndex % 2 === 0
																		? updatedProps.tBodyEvenTrBackgroundColor
																		: updatedProps.tBodyOddTrBackgroundColor;
		
																	row.style.backgroundColor = originalColor;
																});
															};
														});

														// to hide the header buttons after they have been cloned
														headerStellarButtons[j].style.display = "none";
													}
												}
												// }
												console.log("headerStellarButtons:", headerStellarButtons);
												// }
											} catch (e) {
												// statements
												console.log(e);
											}
										});
									}
								}

								tr.append(td);
							}

							if (customTableTbody) {
								customTableTbody.append(tr);
							}							
						});
					}
				}

				// plotting paginated table but without api query params for fetching data in batches
				async function plotPaginatedTableWithoutBatches(page, updatedProps, sortingOrder, colName) {
					console.log("page2, updatedProps", page, updatedProps);

					// indices for batching apiData
					let startIndex = page * updatedProps.numRowsPerPageValue;
    				let endIndex = startIndex + updatedProps.numRowsPerPageValue;
    				console.log("startIndex, endIndex", startIndex, endIndex);

					// fetching API data
					let apiData, tableData;
					let bu, bubt; // base url bearer token, temp var

					// if only custom url is provided and its a GET call
					if (updatedProps.customUrlValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a GET call
					else if (!updatedProps.customUrlValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
						// concatenating base url and its remaining part
						let url, dynamicBaseUrlBearerToken;
						let forDownloadUrl;

						if (updatedProps.baseUrlValue === "baseUrl1") {
							url = updatedProps.options.baseUrl1 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL1}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl1BearerToken;
							dynamicBaseUrlBearerToken = baseUrl1BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl2") {
							url = updatedProps.options.baseUrl2 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL2}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl2BearerToken;
							dynamicBaseUrlBearerToken = baseUrl2BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl3") {
							url = updatedProps.options.baseUrl3 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL3}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl3BearerToken;
							dynamicBaseUrlBearerToken = baseUrl3BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl4") {
							url = updatedProps.options.baseUrl4 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL4}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl4BearerToken;
							dynamicBaseUrlBearerToken = baseUrl4BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl5") {
							url = updatedProps.options.baseUrl5 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL5}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl5BearerToken;
							dynamicBaseUrlBearerToken = baseUrl5BearerToken;
						}

						const urlDownload3 = window.top.location.href;
						const isSubstringPresent3 = (urlDownload3.indexOf("/editor/") !== -1 && urlDownload3.indexOf("?projectId=") !== -1) || urlDownload3.indexOf("/large_preview/") !== -1 || urlDownload3.indexOf("/tab_preview/") !== -1 || urlDownload3.indexOf("/mobile_preview/") !== -1 || urlDownload3.indexOf("/fragment_editor/") !== -1;
       			 		if (!isSubstringPresent3) {
       			 			// running in download
       			 			url = forDownloadUrl;
       			 		}

						bubt = dynamicBaseUrlBearerToken;
						console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only custom url is provided and its a POST call
					else if (updatedProps.customUrlValue && updatedProps.apiBodyValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a POST call
					else if (!updatedProps.customUrlValue && updatedProps.apiBodyValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
						// concatenating base url and its remaining part
						let url, dynamicBaseUrlBearerToken;
						let forDownloadUrl;

						if (updatedProps.baseUrlValue === "baseUrl1") {
							url = updatedProps.options.baseUrl1 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL1}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl1BearerToken;
							dynamicBaseUrlBearerToken = baseUrl1BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl2") {
							url = updatedProps.options.baseUrl2 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL2}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl2BearerToken;
							dynamicBaseUrlBearerToken = baseUrl2BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl3") {
							url = updatedProps.options.baseUrl3 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL3}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl3BearerToken;
							dynamicBaseUrlBearerToken = baseUrl3BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl4") {
							url = updatedProps.options.baseUrl4 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL4}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl4BearerToken;
							dynamicBaseUrlBearerToken = baseUrl4BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl5") {
							url = updatedProps.options.baseUrl5 + updatedProps.apiPathValue;
							forDownloadUrl = "{{API_URL5}}" + updatedProps.apiPathValue;
							// baseUrlBearerToken = updatedProps.options.baseUrl5BearerToken;
							dynamicBaseUrlBearerToken = baseUrl5BearerToken;
						}

						const urlDownload4 = window.top.location.href;
						const isSubstringPresent4 = (urlDownload4.indexOf("/editor/") !== -1 && urlDownload4.indexOf("?projectId=") !== -1) || urlDownload4.indexOf("/large_preview/") !== -1 || urlDownload4.indexOf("/tab_preview/") !== -1 || urlDownload4.indexOf("/mobile_preview/") !== -1 || urlDownload4.indexOf("/fragment_editor/") !== -1;
       			 		if (!isSubstringPresent4) {
       			 			// running in download
       			 			url = forDownloadUrl;
       			 		}

						bu = url;
						bubt = dynamicBaseUrlBearerToken;
						console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if sortingOrder is provided (either ascending or descending), get the sorted apiData
					if (sortingOrder) apiData = returnSortedApiData(page, updatedProps, sortingOrder, colName);

					console.log("apiData:", apiData);

					// clearing tbody every time before plotting - for view change
					if (customTableTbody) {
						customTableTbody.innerHTML = "";
					}					

					// CHECKING & PLOTTING DATASTRUCTURES
					// if the response is: an array-of-objects
					if (isArrayofObjects(apiData)) {
						console.log("#");

						// loop over apiData array as a table's row
						apiData.slice(startIndex, endIndex).forEach((obj, idx) => {
								let tr = document.createElement("tr");
								tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
								tr.setAttribute("class", "custom-table-tbody-row");
								// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
								tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
								tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
								tr.style.width = "100%";
								tr.style.height = "auto";
								if (idx % 2 !== 0) {
									tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
									// hover effect
									tr.addEventListener("mouseover", function () {
										tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
										// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
									});
									tr.addEventListener("mouseleave", function () {
										tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
										// tr.style.scrollbarColor = `${updatedProps.tBodyOddTrBackgroundColor} ${updatedProps.tBodyOddTrBackgroundColor}`;
									});
								} else {
									tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
									// hover effect
									tr.addEventListener("mouseover", function () {
										tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
										// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
									});
									tr.addEventListener("mouseleave", function () {
										tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
										// tr.style.scrollbarColor = `${updatedProps.tBodyEvenTrBackgroundColor} ${updatedProps.tBodyEvenTrBackgroundColor}`;
									});
								}

								// get data and add it as table's column
								for (let i = 0; i < updatedProps.numColsInputValue; i++) {
									let content, convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
									// using updatedProps.HeaderFields because only these are present in the downloading code
									// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
									// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
									// }
									// field name and type are for toggle replication
									let fieldName;

									try {
										if (`HeaderField_${i}` in updatedProps) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else {
												// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 0", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else {
													content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
										} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else {
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 1", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else {
													content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
												// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
											}
											console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
										} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
											// Get the value of the attribute from 'this'
											console.log("customTableContainer", customTableContainer);
											const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (headerFieldAttribute.includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object is not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
											} else {
												// content = obj[headerFieldAttribute];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[headerFieldAttribute];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[headerFieldAttribute];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 2", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
													content = obj[headerFieldAttribute];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("headerFieldAttribute", headerFieldAttribute, content);
										}
									} catch (e) {
										// statements
										console.error(e);
									}

									// field name and type are for toggle replication
									let fieldType;
									let tableDataFields;
                  					if (tableData && ("fields" in tableData)) tableDataFields = tableData.fields;
									if (tableDataFields) fieldType = tableDataFields.filter((x) => x.field_name === fieldName)[0]?.field_type;
									
            						// console.log("+fieldType+", fieldType);

									// console.log("content, i", content, i);

									let td = document.createElement("td");
									td.setAttribute("data-gjs-type", "cell");
									// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
									td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
									td.style.padding = "8px";

									td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
									td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
									td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
									td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
									td.style.borderCollapse = "collapse";
									td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
									td.style.color = `${updatedProps.tBodyTdFontColor}`;
									td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
									td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
									td.innerHTML = content !== undefined ? content : "";

									// for replicating single column component
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerSingleColumnComponents;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerSingleColumnComponents = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('div[data-gjs-type="custom-table-thead-row"][class*="gjs-row"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerSingleColumnComponents) {
											if (headerSingleColumnComponents.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerSingleColumnComponents.length; j++) {
												// row.getElementsByTagName('td')[table_selected_column].append(headerSingleColumnComponents[j]);
												let clonedSingleColumnComponent = headerSingleColumnComponents[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerSingleColumnComponents[j], clonedSingleColumnComponent);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedSingleColumnComponent.setAttribute("id", `single_column_component_table${id}_row${idx}_column${i}_${j}`);

												// for replicating ACTION BUTTON inside single column components
												let innerClonedActionButtons = clonedSingleColumnComponent.getElementsByClassName("custom-table-action-button");
												if (innerClonedActionButtons.length > 0) {
													Array.from(innerClonedActionButtons).forEach((innerClonedActionButton, j_btn) => {
														if (innerClonedActionButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedActionButton.setAttribute("id", `custom_table_action${innerClonedActionButton.getAttribute("data-table-action")}_button_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedActionButton.getElementsByTagName("p")[0]) {
																innerClonedActionButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedActionButton.addEventListener("click", async function (event) {
																console.log("action-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
																localStorage.setItem(
																	`custom_table_action_button_table${id}_${innerClonedActionButton.getAttribute("data-table-action")}_column${i}_${j}_element${j_btn}_clicked`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"data-table-action": `${innerClonedActionButton.getAttribute("data-table-action")}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedActionButton.getAttribute("id")}`,
																	}),
																);

																// Style for the modals
																let modalStyle = {
																	padding: "2%",
																	backgroundColor: "white",
																	borderRadius: "5px",
																	boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																	maxWidth: "50vw",
																	width: "30vw",
																	maxHeight: "40vh",
																	margin: "auto",
																	overflow: "auto",
																	"-ms-overflow-style": "none",
																	"scrollbar-width": "none",
																};

																// Style for the input fields
																let inputFieldStyle = {
																	display: "flex",
																	flexDirection: "column",
																	marginBottom: "15px",
																};

																// Style for the buttons
																let buttonStyle = {
																	width: "100%",
																	padding: "10px",
																	backgroundColor: "#007bff",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let submitButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let closeButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																// Edit modal
																if (innerClonedActionButton.getAttribute("data-table-action") === "edit") {
																	// Create the dialog element
																	let editModal = document.createElement("dialog");
																	editModal.setAttribute("id", "custom-modal");

																	// Apply styles to the modal
																	Object.assign(editModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Edit`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// variable only for the reference keyname user input
																	let selectedOptions = [];
																	// Check if updatedProps.EditableFields is not empty
																	if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																		// Iterate over EditableFields to create form elements
																		for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																			// to handle non-Reference field keynames
																			if (!fieldName.includes("-")) {
																				let fieldLabel = document.createElement("label");
																				fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																				fieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(fieldLabel);

																				let inputField = document.createElement("input");
																				inputField.setAttribute("name", fieldName);
																				inputField.value = obj[fieldName]; // to initialize input fields with present values
																				Object.assign(inputField.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// Set input field type based on field type
																				switch (fieldType.toLowerCase()) {
																					case "string":
																						inputField.setAttribute("type", "text");
																						break;
																					case "boolean":
																						inputField.setAttribute("type", "radio");
																						break;
																					case "number":
																						inputField.setAttribute("type", "number");
																						break;
																					case "date-time":
																						inputField.setAttribute("type", "datetime-local");
																						break;
																					case "time":
																						inputField.setAttribute("type", "time");
																						break;
																					case "file":
																						inputField.setAttribute("type", "file");
																						break;
																					default:
																						inputField.setAttribute("type", "text");
																						break;
																				}
																				modalContent.appendChild(inputField);
																			}

																			// to handle Reference field keynames for example - WebProject1_Address-Sector
																			else if (fieldName.includes("-")) {
																				// variables to be used later in this if-condition
																				let [table, column] = fieldName.split("-");
																				// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																				// create its label
																				let refFieldLabel = document.createElement("label");
																				refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																				refFieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(refFieldLabel);

																				// create a drodown for these keynames > its options will be all the values from response from that table
																				let referenceKeynameDropdown = document.createElement("select");
																				referenceKeynameDropdown.setAttribute("name", fieldName);
																				referenceKeynameDropdown.setAttribute("id", fieldName);
																				// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																				Object.assign(referenceKeynameDropdown.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// populate it with options using the response from its data-model API
																				let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																					headers: {
																						Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																					},
																				});
																				let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																				if (referenceKeynameValueOptionsData) {
																					// setting default option --select--
																					let defaultOption = document.createElement("option");
																					// defaultOption.setAttribute('disabled', 'disabled');
																					defaultOption.innerHTML = "--select--";
																					referenceKeynameDropdown.appendChild(defaultOption);

																					// loop over the response to fill the options of its reference table
																					referenceKeynameValueOptionsData.forEach((optObj) => {
																						let option = document.createElement("option");
																						option.value = optObj[`${column}`];
																						option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																						option.innerHTML = optObj[`${column}`];
																						// to initialize input fields with present values
																						if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																							option.setAttribute("selected", true);
																						}
																						referenceKeynameDropdown.appendChild(option);
																					});
																				}

																				referenceKeynameDropdown.addEventListener("change", function (e) {
																					let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																					// console.log('Selected value:', selectedOptionFromDropdown.value);
																					// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																					// get the reference ID of the table row from main obj to update
																					let refTableId;
																					let tableName = table.split("_")[1];
																					if (obj[tableName] !== null) {
																						refTableId = obj[tableName];
																					}

																					selectedOptions.push({
																						table,
																						column,
																						refTableId,
																						value: selectedOptionFromDropdown.value,
																						"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																					});
																				});

																				modalContent.appendChild(referenceKeynameDropdown);
																			}
																		}
																	}

																	console.log("+bu, bubt+", bu, bubt);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";
																	buttonContainer.style.marginTop = "5%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-submit-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
																	submitButton.innerHTML = "Submit";

																	// Apply styles to the button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", function (e) {
																		e.preventDefault(); // Prevent default form action
																		// Handle form submission here
																		console.log("Form submitted!", obj);

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Initialize an empty FormData object to get the form's input fields values
																		let formData = new FormData(modalContent);
																		let formValues = {};
																		for (const [key, value] of formData.entries()) {
																			formValues[key] = value;
																		}

																		// Create the to_insert object with the keynames from updatedProps.EditableFields
																		// and their corresponding values from formValues
																		let to_insert = {};
																		Object.keys(updatedProps.EditableFields).forEach((key) => {
																			if (formValues[key] !== undefined && !key.includes("-")) {
																				// Check if the form has the field
																				to_insert[key] = formValues[key];
																			}

																			// re-attach the reference id to PUT call else it will set the references to null
																			if (formValues[key] !== undefined && key.includes("-")) {
																				let x = key.split("-");
																				let table = x[0].split("_")[1];
																				if (obj[table] !== null) {
																					to_insert[table] = obj[table];
																				}
																			}
																		});

																		console.log("+to_insert+", to_insert);
																		console.log("+selectedOptions+", selectedOptions);

																		// logic to update all table cells the edit button is editing without refreshing
																		for (const key in to_insert) {
																			let columns = [];
																			if (updatedProps[`HeaderField_${i}`]) {
																				Object.keys(updatedProps).map((hF_i) => {
																					if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			} else {
																				Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																					if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			}

																			// console.log('+columns+', columns);

																			columns.forEach(idx => {
																				let td = tr.getElementsByTagName('td')[idx];

																				let valueToUpdateCellWith = to_insert[key];
																				// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																				// Find the first text node
																				const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																				if (textNode) {
																					textNode.nodeValue = valueToUpdateCellWith;
																				} else {
																					// If no text node exists, create one
																					td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																				}
																			});
																		}

																		// Fetch call (to non-reference table) can be made here using the input field values
																		// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																		fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "PUT",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																			body: JSON.stringify({
																				to_insert: to_insert,
																			}),
																		})
																			.then((response) => response.json())
																			.then((data) => console.log(data));

																		// Fetch call (for reference table)
																		if (selectedOptions.length > 0) {
																			selectedOptions.forEach((data) => {
																				// make api calls per object
																				let requestBody = {};
																				requestBody[data.column] = data.value; // Assign dynamic key-value pair
																				fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																					method: "PUT",
																					headers: {
																						"Content-Type": "application/json",
																						Authorization: `Bearer ${values.BearerToken}`,
																					},
																					body: JSON.stringify({
																						to_insert: requestBody,
																					}),
																				})
																					.then((response) => response.json())
																					.then((data) => console.log(data));
																			});
																		}

																		// Close the modal when the form is submitted
																		editModal.close();

																		// clear the selectedOptions for next turn
																		selectedOptions = [];

																		// // giving a delay in reload so that it doesn't change the url in the broswer tab
																		// setTimeout(function () {
																		// 	window.top.location.reload();
																		// }, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-close-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
																	closeButton.innerHTML = "Close";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (event) {
																		event.preventDefault(); // Prevent default behavior of the click event
																		editModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	editModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(editModal);

																	// Show the modal
																	editModal.showModal();
																}
																// Delete modal
																else if (innerClonedActionButton.getAttribute("data-table-action") === "delete") {
																	// Create the dialog element
																	let deleteModal = document.createElement("dialog");
																	deleteModal.setAttribute("id", "delete-modal");

																	// Apply styles to the modal
																	Object.assign(deleteModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Delete`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// // Create an input field for data model with a default value
																	// let dataModelInputField = document.createElement("input");
																	// dataModelInputField.setAttribute('type', 'text');
																	// dataModelInputField.setAttribute('name', 'DataModel');
																	// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
																	// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(dataModelInputField.style, inputFieldStyle);

																	// modalContent.appendChild(dataModelInputField);

																	// // Create an input field for bearer token with a default value
																	// let bearerTokenInputField = document.createElement("input");
																	// bearerTokenInputField.setAttribute('type', 'text');
																	// bearerTokenInputField.setAttribute('name', 'BearerToken');
																	// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
																	// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(bearerTokenInputField.style, inputFieldStyle);

																	// modalContent.appendChild(bearerTokenInputField);

																	// Create a paragraph tag for confirmation message
																	let confirmationMessage = document.createElement("p");
																	confirmationMessage.style.marginBottom = "5%";
																	confirmationMessage.style.fontSize = "1vw";
																	confirmationMessage.innerHTML = "Are you sure?";

																	// Append confirmation message to the modal content
																	modalContent.appendChild(confirmationMessage);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-delete-yes-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
																	submitButton.innerHTML = "Yes";

																	// Apply styles to the "Yes" button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", async function (e) {
																		e.preventDefault();
																		// Handle form submission here
																		console.log("Form submitted!");

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Use the values in the fetch call or other logic
																		console.log("Input field values:", values);

																		// Make the fetch call to delete the item
																		let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "DELETE",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																		});
																		// .then(response => {
																		//     if (response.ok) {
																		//         console.log('Item deleted successfully');
																		//     } else {
																		//         console.error('Failed to delete item');
																		//     }
																		// });

																		let responseData = response.json();
																		if (responseData.ok) {
																			console.log("Item deleted successfully");
																		} else {
																			console.error("Failed to delete item");
																		}

																		// Close the modal when the form is submitted
																		deleteModal.close();

																		// giving a delay in reload so that it doesn't change the url in the broswer tab
																		setTimeout(function () {
																			window.top.location.reload();
																		}, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-delete-no-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
																	closeButton.innerHTML = "No";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (e) {
																		e.preventDefault();
																		deleteModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	deleteModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(deleteModal);

																	// Show the modal
																	deleteModal.showModal();
																}
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedActionButton);
														}
													});
												}

												// for replicating STELLAR BUTTON inside single column components
												let innerClonedStellarButtons = clonedSingleColumnComponent.getElementsByClassName("stellar-button-container");
												if (innerClonedStellarButtons.length > 0) {
													Array.from(innerClonedStellarButtons).forEach((innerClonedStellarButton, j_btn) => {
														if (innerClonedStellarButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
																innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedStellarButton.addEventListener("click", function (event) {
																console.log("stellar-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

																// this registers all stellar button clicks on LS
																// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
																// this registers only by the individuals in the column header
																localStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);

																sessionStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedStellarButton);
														}
													});
												}

												td.appendChild(clonedSingleColumnComponent);

												// to hide the header buttons after they have been cloned
												headerSingleColumnComponents[j].style.display = "none";
											}
										}
										// }
										console.log("headerSingleColumnComponents:", headerSingleColumnComponents);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating stellar buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerStellarButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										console.log("headerStellarButtons1:", headerStellarButtons);
										if (headerStellarButtons) {
											if (headerStellarButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerStellarButtons.length; j++) {
												console.log("headerStellarButtons[j]", headerStellarButtons[j]);
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerStellarButtons[j], clonedStellarButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
												clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
												if (clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
													clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header stellar button was not in single column component, only then append it to td
												if (!headerStellarButtons[j].parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedStellarButton);
													console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedStellarButton.addEventListener("click", function (event) {
													console.log("stellar-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													sessionStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													// highlight the table row that the stellar button has selected
													if (updatedProps.tBodyTrSelectColorHighlight)
														clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";

													customTable.onclick = function(event) {														
														// Check if the click is inside the custom table but outside the clicked row
														// if (
														// 	customTable.contains(event.target) && // Click is inside the customTable
														// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
														// ) {
														// 	console.log('@deselecting row', idx);
														// 	// Determine original color based on row index (even/odd)
														// 	const originalColor = idx % 2 === 0
														// 		? updatedProps.tBodyEvenTrBackgroundColor
														// 		: updatedProps.tBodyOddTrBackgroundColor;

														// 	// Reset the row's background color
														// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
														// }

														// Iterate over all rows in the table
														const rows = customTable.querySelectorAll("tr");
														rows.forEach((row, rowIndex) => {
															// Skip the currently selected row
															if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																return;
															}

															// Reset the background color based on even/odd index
															const originalColor = rowIndex % 2 === 0
																? updatedProps.tBodyEvenTrBackgroundColor
																: updatedProps.tBodyOddTrBackgroundColor;

															row.style.backgroundColor = originalColor;
														});
													};
												});

												// to hide the header buttons after they have been cloned
												headerStellarButtons[j].style.display = "none";
											}
										}
										// }
										console.log("headerStellarButtons:", headerStellarButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating action buttons
									try {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find action-button component in header column
										let headerActionButtons;
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										headerActionButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-action-button");

										if (headerActionButtons.length > 0) {
											if (headerActionButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(headerActionButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												await copyStyles(j, clonedButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedButton.setAttribute("id", `custom_table_action_button_table${id}_row${idx}_column${i}_${j}`);
												if (clonedButton.getElementsByTagName("p")[0]) {
													clonedButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header action button was not in single column component, only then append it to td
												console.log("j.parentNode.classList", j.parentNode.classList);
												if (!j.parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedButton);
													console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedButton.addEventListener("click", async function (event) {
													console.log("action-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
													localStorage.setItem(
														`custom_table_action_button_table${id}_clicked`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															"data-table-action": `${clonedButton.getAttribute("data-table-action")}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedButton.getAttribute("id")}`,
														}),
													);

													// Style for the modals
													let modalStyle = {
														padding: "2%",
														backgroundColor: "white",
														borderRadius: "5px",
														boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
														maxWidth: "50vw",
														width: "30vw",
														maxHeight: "40vh",
														margin: "auto",
														overflow: "auto",
														"-ms-overflow-style": "none",
														"scrollbar-width": "none",
													};

													// Style for the input fields
													let inputFieldStyle = {
														display: "flex",
														flexDirection: "column",
														marginBottom: "15px",
													};

													// Style for the buttons
													let buttonStyle = {
														width: "100%",
														padding: "10px",
														backgroundColor: "#007bff",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let submitButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let closeButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													// Edit modal
													if (clonedButton.getAttribute("data-table-action") === "edit") {
														// Create the dialog element
														let editModal = document.createElement("dialog");
														editModal.setAttribute("id", "custom-modal");

														// Apply styles to the modal
														Object.assign(editModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Edit`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// variable only for the reference keyname user input
														let selectedOptions = [];
														// Check if updatedProps.EditableFields is not empty
														if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
															// Iterate over EditableFields to create form elements
															for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																// to handle non-Reference field keynames
																if (!fieldName.includes("-")) {
																	let fieldLabel = document.createElement("label");
																	fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																	fieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(fieldLabel);

																	let inputField = document.createElement("input");
																	inputField.setAttribute("name", fieldName);
																	inputField.value = obj[fieldName]; // to initialize input fields with present values
																	Object.assign(inputField.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// Set input field type based on field type
																	switch (fieldType.toLowerCase()) {
																		case "string":
																			inputField.setAttribute("type", "text");
																			break;
																		case "boolean":
																			inputField.setAttribute("type", "radio");
																			break;
																		case "number":
																			inputField.setAttribute("type", "number");
																			break;
																		case "date-time":
																			inputField.setAttribute("type", "datetime-local");
																			break;
																		case "time":
																			inputField.setAttribute("type", "time");
																			break;
																		case "file":
																			inputField.setAttribute("type", "file");
																			break;
																		default:
																			inputField.setAttribute("type", "text");
																			break;
																	}
																	modalContent.appendChild(inputField);
																}

																// to handle Reference field keynames for example - WebProject1_Address-Sector
																else if (fieldName.includes("-")) {
																	// variables to be used later in this if-condition
																	let [table, column] = fieldName.split("-");
																	// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																	// create its label
																	let refFieldLabel = document.createElement("label");
																	refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																	refFieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(refFieldLabel);

																	// create a drodown for these keynames > its options will be all the values from response from that table
																	let referenceKeynameDropdown = document.createElement("select");
																	referenceKeynameDropdown.setAttribute("name", fieldName);
																	referenceKeynameDropdown.setAttribute("id", fieldName);
																	// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																	Object.assign(referenceKeynameDropdown.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// populate it with options using the response from its data-model API
																	let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																		headers: {
																			Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																		},
																	});
																	let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																	if (referenceKeynameValueOptionsData) {
																		// setting default option --select--
																		let defaultOption = document.createElement("option");
																		// defaultOption.setAttribute('disabled', 'disabled');
																		defaultOption.innerHTML = "--select--";
																		referenceKeynameDropdown.appendChild(defaultOption);

																		// loop over the response to fill the options of its reference table
																		referenceKeynameValueOptionsData.forEach((optObj) => {
																			let option = document.createElement("option");
																			option.value = optObj[`${column}`];
																			option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																			option.innerHTML = optObj[`${column}`];
																			// to initialize input fields with present values
																			if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																				option.setAttribute("selected", true);
																			}
																			referenceKeynameDropdown.appendChild(option);
																		});
																	}

																	referenceKeynameDropdown.addEventListener("change", function (e) {
																		let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																		// console.log('Selected value:', selectedOptionFromDropdown.value);
																		// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																		// get the reference ID of the table row from main obj to update
																		let refTableId;
																		let tableName = table.split("_")[1];
																		if (obj[tableName] !== null) {
																			refTableId = obj[tableName];
																		}

																		selectedOptions.push({
																			table,
																			column,
																			refTableId,
																			value: selectedOptionFromDropdown.value,
																			"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																		});
																	});

																	modalContent.appendChild(referenceKeynameDropdown);
																}
															}
														}

														console.log("+bu, bubt+", bu, bubt);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";
														buttonContainer.style.marginTop = "5%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-submit-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
														submitButton.innerHTML = "Submit";

														// Apply styles to the button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", function (e) {
															e.preventDefault(); // Prevent default form action
															// Handle form submission here
															console.log("Form submitted!", obj);

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Initialize an empty FormData object to get the form's input fields values
															let formData = new FormData(modalContent);
															let formValues = {};
															for (const [key, value] of formData.entries()) {
																formValues[key] = value;
															}

															// Create the to_insert object with the keynames from updatedProps.EditableFields
															// and their corresponding values from formValues
															let to_insert = {};
															Object.keys(updatedProps.EditableFields).forEach((key) => {
																if (formValues[key] !== undefined && !key.includes("-")) {
																	// Check if the form has the field
																	to_insert[key] = formValues[key];
																}

																// re-attach the reference id to PUT call else it will set the references to null
																if (formValues[key] !== undefined && key.includes("-")) {
																	let x = key.split("-");
																	let table = x[0].split("_")[1];
																	if (obj[table] !== null) {
																		to_insert[table] = obj[table];
																	}
																}
															});

															console.log("+to_insert+", to_insert);
															console.log("+selectedOptions+", selectedOptions);

															// logic to update all table cells the edit button is editing without refreshing
															for (const key in to_insert) {
																let columns = [];
																if (updatedProps[`HeaderField_${i}`]) {
																	Object.keys(updatedProps).map((hF_i) => {
																		if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																} else {
																	Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																		if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																}

																// console.log('+columns+', columns);

																columns.forEach(idx => {
																	let td = tr.getElementsByTagName('td')[idx];

																	let valueToUpdateCellWith = to_insert[key];
																	// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																	// Find the first text node
																	const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																	if (textNode) {
																		textNode.nodeValue = valueToUpdateCellWith;
																	} else {
																		// If no text node exists, create one
																		td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																	}
																});
															}

															// Fetch call (to non-reference table) can be made here using the input field values
															// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
															fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "PUT",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
																body: JSON.stringify({
																	to_insert: to_insert,
																}),
															})
																.then((response) => response.json())
																.then((data) => console.log(data));

															// Fetch call (for reference table)
															if (selectedOptions.length > 0) {
																selectedOptions.forEach((data) => {
																	// make api calls per object
																	let requestBody = {};
																	requestBody[data.column] = data.value; // Assign dynamic key-value pair
																	fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																		method: "PUT",
																		headers: {
																			"Content-Type": "application/json",
																			Authorization: `Bearer ${values.BearerToken}`,
																		},
																		body: JSON.stringify({
																			to_insert: requestBody,
																		}),
																	})
																		.then((response) => response.json())
																		.then((data) => console.log(data));
																});
															}

															// Close the modal when the form is submitted
															editModal.close();

															// clear the selectedOptions for next turn
															selectedOptions = [];

															// // giving a delay in reload so that it doesn't change the url in the broswer tab
															// setTimeout(function () {
															// 	window.top.location.reload();
															// }, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-close-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
														closeButton.innerHTML = "Close";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (event) {
															event.preventDefault(); // Prevent default behavior of the click event
															editModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														editModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(editModal);

														// Show the modal
														editModal.showModal();
													}
													// Delete modal
													else if (clonedButton.getAttribute("data-table-action") === "delete") {
														// Create the dialog element
														let deleteModal = document.createElement("dialog");
														deleteModal.setAttribute("id", "delete-modal");

														// Apply styles to the modal
														Object.assign(deleteModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Delete`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// // Create an input field for data model with a default value
														// let dataModelInputField = document.createElement("input");
														// dataModelInputField.setAttribute('type', 'text');
														// dataModelInputField.setAttribute('name', 'DataModel');
														// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
														// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

														// // Apply styles to the input fields
														// Object.assign(dataModelInputField.style, inputFieldStyle);

														// modalContent.appendChild(dataModelInputField);

														// // Create an input field for bearer token with a default value
														// let bearerTokenInputField = document.createElement("input");
														// bearerTokenInputField.setAttribute('type', 'text');
														// bearerTokenInputField.setAttribute('name', 'BearerToken');
														// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
														// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

														// // Apply styles to the input fields
														// Object.assign(bearerTokenInputField.style, inputFieldStyle);

														// modalContent.appendChild(bearerTokenInputField);

														// Create a paragraph tag for confirmation message
														let confirmationMessage = document.createElement("p");
														confirmationMessage.style.marginBottom = "5%";
														confirmationMessage.style.fontSize = "1vw";
														confirmationMessage.innerHTML = "Are you sure?";

														// Append confirmation message to the modal content
														modalContent.appendChild(confirmationMessage);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-delete-yes-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
														submitButton.innerHTML = "Yes";

														// Apply styles to the "Yes" button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", async function (e) {
															e.preventDefault();
															// Handle form submission here
															console.log("Form submitted!");

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Use the values in the fetch call or other logic
															console.log("Input field values:", values);

															// Make the fetch call to delete the item
															let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "DELETE",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
															});
															// .then(response => {
															//     if (response.ok) {
															//         console.log('Item deleted successfully');
															//     } else {
															//         console.error('Failed to delete item');
															//     }
															// });

															let responseData = response.json();
															if (responseData.ok) {
																console.log("Item deleted successfully");
															} else {
																console.error("Failed to delete item");
															}

															// Close the modal when the form is submitted
															deleteModal.close();

															// giving a delay in reload so that it doesn't change the url in the broswer tab
															setTimeout(function () {
																window.top.location.reload();
															}, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-delete-no-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
														closeButton.innerHTML = "No";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (e) {
															e.preventDefault();
															deleteModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														deleteModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(deleteModal);

														// Show the modal
														deleteModal.showModal();
													}
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										console.log("headerActionButtons:", headerActionButtons);
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_file buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerInputFileButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerInputFileButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_file_container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerInputFileButtons) {
											if (headerInputFileButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											// for (let j = 0; j < headerInputFileButtons.length; j++) {
											// 	// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
											// 	let clonedInputFileButton = headerInputFileButtons[j].cloneNode(true);

											// 	// Copy the styles from the original button to the cloned button and its inner elements
											// 	copyStyles(headerInputFileButtons[j], clonedInputFileButton);

											// 	// changing the clone's IDs so that they are present and available individually in LB and in download
											// 	clonedInputFileButton.setAttribute('id', `input_file_container_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].setAttribute('id', `input_file_label_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].getElementsByTagName('p')[0].setAttribute('id', `input_file_label_p_table${id}_row${idx}_column${i}_${j}`);

											// 	td.appendChild(clonedInputFileButton);

											// 	let headerInputFileClickHandler = function(event) {
											// 		console.log('input_file_label clicked', 'saving data in session storage');
											// 		// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

											// 		// this registers all stellar button clicks on LS
											// 		// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
											// 		// this registers only by the individuals in the column header
											// 		localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, JSON.stringify({
											// 			'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
											// 			tableId: `${id}`,
											// 			rowId: `custom_table_tbody_row${idx}_table${id}`,
											// 			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
											// 			buttonId: `${clonedInputFileButton.getAttribute('id')}`,
											// 		}));

											// 		console.log('clonedInputFileButton++', clonedInputFileButton);

											// 		let inputFile = document.createElement('input');
											//         inputFile.type = 'file';
											//         inputFile.style.display = 'none';

											//         inputFile.addEventListener('change', async function (event) {
											//               let file = inputFile.files[0];
											//               console.log('this++', this);
											//               console.log('file is:', file);

											//               let formData = new FormData();
											//               formData.append('file', file);

											//               try {
											//                   const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
											//                       method: 'POST',
											//                       headers: {
											//                           // 'Content-Type': 'multipart/form-data',
											//                       },
											//                       body: formData,
											//                   });

											//                   if (response.ok) {
											//                       const data = await response.json();
											//                       console.log('File uploaded:', data);
											//                       clonedInputFileButton.setAttribute('data_file_url', data["fileLocation"]);

											//                     // Retrieve the object from local storage
											// 			        let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j}`)) || {};

											// 			        // Add the data_file_url and its value to the object
											// 			        clickedFileData.data_file_url = data["fileLocation"];

											// 			        // Convert the modified object back to a JSON string
											// 			        let updatedClickedFileData = JSON.stringify(clickedFileData);

											// 			        // Update the local storage with the modified JSON string
											// 			        localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, updatedClickedFileData);
											//                   } else {
											//                       console.error('Error uploading file:', response.statusText);
											//                   }

											//                   clonedInputFileButton.onclick = null;
											//               } catch (error) {
											//                   console.error('Error uploading file:', error);
											//               } finally {
											//               	console.log('after 1')
											//                   // Remove the inputFile element after API call completes
											//                   if (inputFile && inputFile.parentNode) {
											//                   	console.log('after 2')
											//                       inputFile.parentNode.removeChild(inputFile);
											//                   }
											//               }
											//         });

											//         clonedInputFileButton.appendChild(inputFile);
											//         inputFile.click();
											//         console.log('clicked', clonedInputFileButton);
											// 	};

											// 	// adding click event on stellar-button from the table components
											// 	// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
											// 	clonedInputFileButton.onclick = headerInputFileClickHandler;

											// 	// to hide the header buttons after they have been cloned
											// 	headerInputFileButtons[j].style.display = "none";
											// }

											Array.from(headerInputFileButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
												let clonedInputFileButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedInputFileButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedInputFileButton.setAttribute("id", `input_file_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].setAttribute("id", `input_file_label_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0]) {
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0].setAttribute("id", `input_file_label_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedInputFileButton);

												let headerInputFileClickHandler = function (event) {
													console.log("input_file_label clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_input_file_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedInputFileButton.getAttribute("id")}`,
														}),
													);

													// console.log('clonedInputFileButton++', clonedInputFileButton);

													let inputFile = document.createElement("input");
													inputFile.type = "file";
													inputFile.style.display = "none";

													inputFile.addEventListener("change", async function (event) {
														let file = inputFile.files[0];
														console.log("this++", this);
														console.log("file is:", file);

														let formData = new FormData();
														formData.append("file", file);

														try {
															const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
																method: "POST",
																headers: {
																	// 'Content-Type': 'multipart/form-data',
																},
																body: formData,
															});

															if (response.ok) {
																const data = await response.json();
																console.log("File uploaded:", data);
																td.setAttribute("data_file_url", data["fileLocation"]);

																// Retrieve the object from local storage
																let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`)) || {};

																// Add the data_file_url and its value to the object
																clickedFileData.data_file_url = data["fileLocation"];

																// Convert the modified object back to a JSON string
																let updatedClickedFileData = JSON.stringify(clickedFileData);

																// Update the local storage with the modified JSON string
																localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`, updatedClickedFileData);
															} else {
																console.error("Error uploading file:", response.statusText);
															}
														} catch (error) {
															console.error("Error uploading file:", error);
														} finally {
															// Remove the inputFile element after API call completes
															if (inputFile && inputFile.parentNode) {
																inputFile.parentNode.removeChild(inputFile);
															}
														}
													});

													clonedInputFileButton.appendChild(inputFile);
													inputFile.click();

													if (inputFile && inputFile.parentNode) {
														inputFile.parentNode.removeChild(inputFile);
													}
												};

												// adding click event on stellar-button from the table components
												// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].onclick = headerInputFileClickHandler;

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										console.log("headerInputFileButtons:", headerInputFileButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating radio_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let radioButtonGroups;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										radioButtonGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("radio_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (radioButtonGroups) {
											if (radioButtonGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(radioButtonGroups).forEach((radioButtonGroup, j) => {
												let clonedRadioButtonGroup = radioButtonGroup.cloneNode(true);
												// Copy styles from the original to the cloned button
												copyStyles2(radioButtonGroup, clonedRadioButtonGroup);

												// Change IDs and append cloned radio button groups to the container
												clonedRadioButtonGroup.setAttribute("id", `radio_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each radio button in the group
												clonedRadioButtonGroup.querySelectorAll('input[type="radio"]').forEach((radioButton) => {
													radioButton.addEventListener("click", (event) => {
														// Get the selected radio button's value and id
														const selectedValue = event.target.value;
														const selectedId = event.target.id;
														const selectedDataDbId = event.target.getAttribute("data_db_id") ? event.target.getAttribute("data_db_id") : "";

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_radio_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																radioButtonId: `${selectedId}`,
																selectedValue: `${selectedValue}`,
																selectedDataDbId: `${selectedDataDbId}`,
															}),
														);

														// You can also perform other actions based on the selected radio button
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedRadioButtonGroup);
											});
										}

										// Hide the original radio button groups in the header column
										Array.from(radioButtonGroups).forEach((radioButtonGroup) => {
											radioButtonGroup.style.display = "none";
										});
										// }
										console.log("radioButtonGroups:", radioButtonGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroups;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroups) {
											if (checkboxGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroups).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroups).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										console.log("checkboxGroups:", checkboxGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form_new
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroupsV2;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroupsV2 = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form_new");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroupsV2) {
											if (checkboxGroupsV2.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroupsV2).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_v2_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_v2_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroupsV2).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										console.log("checkboxGroupsV2:", checkboxGroupsV2);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_select
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dropdownContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dropdownContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_select_container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dropdownContainers) {
											if (dropdownContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(dropdownContainers).forEach((dropdownContainer, j) => {
												let dropdown = dropdownContainer.getElementsByTagName("select")[0];
												let dropdownClone = dropdown.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dropdown, dropdownClone);

												// Change IDs and append cloned dropdowns to the container
												dropdownClone.setAttribute("id", `input_select_table${id}_row${idx}_column${i}_${j}`);
												dropdownClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const selectedValue = event.target.value;
													const selectedOption = event.target.options[event.target.selectedIndex];
													const selectedId = selectedOption.id;

													// Get the data-db-id attribute value of the selected option
													const selectedDataDbId = selectedOption.getAttribute("data_db_id") ? selectedOption.getAttribute("data_db_id") : "";

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_input_select_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															selectedValue: `${selectedValue}`,
															selectedId: `${selectedId}`,
															selectedDataDbId: `${selectedDataDbId}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dropdownClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dropdownContainers).forEach((dropdownContainer) => {
												dropdownContainer.style.display = "none";
											});
										}
										// }
										console.log("dropdownContainers:", dropdownContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_text_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputTextContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputTextContainers) {
											if (inputTextContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
												let inputText = inputTextContainer.getElementsByTagName("input")[0];
												let inputTextClone = inputText.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputText, inputTextClone);

												// Change IDs and append cloned dropdowns to the container
												inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
												inputTextClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_text_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputTextClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputTextContainers).forEach((inputTextContainer) => {
												inputTextContainer.style.display = "none";
											});
										}
										// }
										console.log("inputTextContainers:", inputTextContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_number_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputNumberContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputNumberContainers) {
											if (inputNumberContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
												let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
												let inputNumberClone = inputNumber.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputNumber, inputNumberClone);

												// Change IDs and append cloned dropdowns to the container
												inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
												inputNumberClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_number_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputNumberClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
												inputNumberContainer.style.display = "none";
											});
										}
										// }
										console.log("inputNumberContainers:", inputNumberContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating send email buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let sendEmailButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										sendEmailButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("send-email-button-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (sendEmailButtons) {
											if (sendEmailButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(sendEmailButtons).forEach((j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedEmailButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedEmailButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedEmailButton.setAttribute("id", `send_email_button_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedEmailButton.getElementsByClassName("send-email-button")[0].setAttribute("id", `send_email_btn_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0]) {
													clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0].setAttribute("id", `send_email_btn_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedEmailButton);

												const sendEmailHandler = async function (sendEmailButtonContainer) {
													let selectedFile;
													// reason - because this script was getting called multiple times, the dialog element was getting added multiple times so this code will be removing all the previously appended ones and then create a new one on-the-go
													if (sendEmailButtonContainer) {
														let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
														modalAlreadyExists.forEach((modal) => {
															console.log("removing modal 2", modal);
															modal.remove();
														});

														// // fetch filter call for content
														// let filteredResponse = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${extractTableName(updatedProps.customUrlValue)}`, {
														// 	method: "POST",
														// 	headers: {
														// 		'Content-Type': "application/json",
														// 		Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
														// 	},
														// 	body: JSON.stringify({
														// 	    filterData: {
														// 	        _id: `${obj[updatedProps.DataModelObjIdKeyname]}`,
														// 	    }
														// 	}),
														// });

														// let filteredResponseData = await filteredResponse.json();

														// Get the keys from updatedProps.HeaderFields object and from HTML attributes
														const headerFieldKeys = Object.keys(updatedProps.HeaderFields);
														const htmlAttributeKeys = Array.from(customTableContainer.attributes)
															.filter((attr) => attr.name.includes("headerfield_"))
															.map((attr) => attr.value);

														console.log("htmlAttributeKeys", htmlAttributeKeys);

														// Combine the keys from both sources
														const allKeys = [...headerFieldKeys, ...htmlAttributeKeys];

														// Accumulate all key-value pairs
														const keyValuePairs = [];

														// Get the values corresponding to the keys from the obj object
														htmlAttributeKeys.forEach((key) => {
															let value;
															if (/:/.test(key)) {
																// If the key contains a colon (indicating nested properties), split it
																const [mainKey, subKey] = key.split(/\s*:\s*/);
																// Check if the main key exists in obj
																if (obj[`${mainKey}_data`]) {
																	// Check if the main key points to an object
																	if (typeof obj[`${mainKey}_data`] === "object" && obj[`${mainKey}_data`][subKey]) {
																		value = obj[`${mainKey}_data`][subKey];
																		keyValuePairs.push(`${subKey}: ${value}`);
																	}
																}
															} else {
																value = obj[key];
																keyValuePairs.push(`${key}: ${value}`);
															}
															// if (value) {
															//     keyValuePairs.push(`${key}: ${value}`);
															// }
														});

														console.log("allKeys, keyValuePairs", allKeys, keyValuePairs);

														// Join all key-value pairs into a single string
														const filteredKeyValuePairs = keyValuePairs.join("\n");

														console.log("allKeys, filteredKeyValuePairs", allKeys, filteredKeyValuePairs);

														// Create a dialog modal with styling
														const dynamicEmailSendingModal = document.createElement("dialog");
														dynamicEmailSendingModal.setAttribute("class", `custom-email-modal`);
														dynamicEmailSendingModal.setAttribute("id", `custom-email-modal-${sendEmailButtonContainer.id}`);
														Object.assign(dynamicEmailSendingModal.style, {
															margin: "auto",
															width: "35vw",
															overflow: "auto",
															backgroundColor: "white",
															boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
															border: "1px solid #ccc",
															borderRadius: "20px",
															zIndex: "1000",
															padding: "0 0 1% 0",
														});

														// modal UI header & footer
														let modalHeader = document.createElement("div");
														Object.assign(modalHeader.style, {
															// backgroundColor: "#1991E7",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															color: "white",
															fontSize: "1vw",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});
														modalHeader.innerHTML = "Compose Message";

														const modalFooter = document.createElement("div");
														Object.assign(modalFooter.style, {
															backgroundColor: "transparent",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});

														// Create a form element
														const emailForm = document.createElement("form");
														Object.assign(emailForm.style, {
															padding: "3% 6%",
															display: "flex",
															flexDirection: "column",
														});

														// Check if EmailFromDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailFromDynamicCheckbox) {
														const emailFromDiv = document.createElement("div");
														Object.assign(emailFromDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailFromLabel = document.createElement("label");
														emailFromLabel.innerHTML = "From";
														// Create an input email field for the sender's email address with styling
														const emailFromField = document.createElement("input");
														Object.assign(emailFromField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailFromField, {
															type: "email",
															id: `emailFrom-${sendEmailButtonContainer.id}`,
															name: "emailFrom",
															placeholder: "Sender's Email Address",
														});
														// visiblity will be hidden because this field is not actually required and it needs to occupy same space for UI alignment purposes
														const emailFromRequiredFieldLabel = document.createElement("span");
														emailFromRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailFromRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
															visibility: "hidden",
														});

														// Append the email input field to the form
														emailFromDiv.appendChild(emailFromLabel);
														emailFromDiv.appendChild(emailFromField);
														emailFromDiv.appendChild(emailFromRequiredFieldLabel);
														emailForm.appendChild(emailFromDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailToDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailToDynamicCheckbox) {
														const emailToDiv = document.createElement("div");
														Object.assign(emailToDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailToLabel = document.createElement("label");
														emailToLabel.innerHTML = "To";
														// Create an input email field for the receiver's email address with styling
														const emailToField = document.createElement("input");
														Object.assign(emailToField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailToField, {
															type: "text",
															id: `emailTo-${sendEmailButtonContainer.id}`,
															name: "emailTo",
															placeholder: "Receiver's Email Addresses (comma separated)",
														});
														const emailToRequiredFieldLabel = document.createElement("span");
														emailToRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailToRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailToDiv.appendChild(emailToLabel);
														emailToDiv.appendChild(emailToField);
														emailToDiv.appendChild(emailToRequiredFieldLabel);
														emailForm.appendChild(emailToDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailSubjectDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailSubjectDynamicCheckbox) {
														const emailSubjectDiv = document.createElement("div");
														Object.assign(emailSubjectDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailSubjectLabel = document.createElement("label");
														emailSubjectLabel.innerHTML = "Subject";
														// Create an input email field for the receiver's email address with styling
														const emailSubjectField = document.createElement("input");
														Object.assign(emailSubjectField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailSubjectField, {
															type: "text",
															id: `emailSubject-${sendEmailButtonContainer.id}`,
															name: "emailSubject",
															placeholder: "Email's subject",
														});
														const emailSubjectRequiredFieldLabel = document.createElement("span");
														emailSubjectRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailSubjectRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailSubjectDiv.appendChild(emailSubjectLabel);
														emailSubjectDiv.appendChild(emailSubjectField);
														emailSubjectDiv.appendChild(emailSubjectRequiredFieldLabel);
														emailForm.appendChild(emailSubjectDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailBodyDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailBodyDynamicCheckbox) {
														const emailBodyDiv = document.createElement("div");
														Object.assign(emailBodyDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailBodyLabel = document.createElement("label");
														emailBodyLabel.innerHTML = "Body";
														// Create an input email field for the receiver's email address with styling
														const emailBodyField = document.createElement("textarea");
														Object.assign(emailBodyField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailBodyField, {
															rows: "5",
															cols: "10",
															id: `emailBody-${sendEmailButtonContainer.id}`,
															name: "emailBody",
															placeholder: "Email's body",
														});
														const emailBodyRequiredFieldLabel = document.createElement("span");
														emailBodyRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailBodyRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														emailBodyField.value = filteredKeyValuePairs;

														// Append the email input field to the form
														emailBodyDiv.appendChild(emailBodyLabel);
														emailBodyDiv.appendChild(emailBodyField);
														emailBodyDiv.appendChild(emailBodyRequiredFieldLabel);
														emailForm.appendChild(emailBodyDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if FileAttachmentDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.FileAttachmentDynamicCheckbox) {
														// Create an input email field for the receiver's email address with styling
														const fileAttachmentField = document.createElement("input");
														Object.assign(fileAttachmentField.style, {
															// marginBottom: "10px",
															// padding: "5px",
															// width: "80%",
															// borderRadius: "5px",
															// backgroundColor: "transparent",
															// boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
															display: "none",
														});
														Object.assign(fileAttachmentField, {
															type: "file",
															id: `fileAttachment-${sendEmailButtonContainer.id}`,
															name: "fileAttachment",
														});

														// Add event listener for the file input field change event
														fileAttachmentField.addEventListener("change", (event) => {
															selectedFile = event.target.files[0];
															console.log("Selected file:", selectedFile);
															// You can do further processing with the selected file here
														});

														// Create a label for the file input button
														const fileAttachmentLabel = document.createElement("label");
														fileAttachmentLabel.setAttribute("for", "fileAttachmentInput"); // Set the 'for' attribute to match the input field's id
														fileAttachmentLabel.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16"><path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/></svg>';
														Object.assign(fileAttachmentLabel.style, {
															cursor: "pointer",
														});

														// Attach click event to the label
														fileAttachmentLabel.addEventListener("click", () => {
															fileAttachmentField.click();
														});

														// Append the email input field to the form
														modalFooter.appendChild(fileAttachmentLabel);
														modalFooter.appendChild(fileAttachmentField);
														// }
														// // else take directly from props
														// else {}

														// Create a submit button
														const submitButton = document.createElement("button");
														Object.assign(submitButton.style, {
															// marginBottom: "3%",
															// marginRight: "4%",
															alignSelf: "flex-end",
															padding: "1.5% 3%",
															border: "none",
															borderRadius: "25px",
															// backgroundColor: "#007bff",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															color: "white",
															cursor: "pointer",
														});
														submitButton.textContent = "Send";
														submitButton.type = "submit";

														// Add event listener for form submission
														submitButton.addEventListener("click", async (event) => {
															event.preventDefault(); // Prevent form submission

															// Get the entered email values
															const emailFromValue = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`).value : "";
															let emailToValue = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`).value : "";
															emailToValue = emailToValue.includes(",") ? emailToValue.split(/\s*,\s*/) : emailToValue;
															const emailSubjectValue = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`).value : "";
															const emailBodyValue = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`).value : "";
															const fileAttachmentValue = emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`).value : "";

															// to preserve line breaks in email body
															const formattedEmailBodyValue = emailBodyValue.replace(/\n/g, "<br>");

															console.log("form values", emailFromValue, emailToValue, emailSubjectValue, emailBodyValue, formattedEmailBodyValue, fileAttachmentValue);
															console.log("selectedFile", selectedFile);

															// Create a FormData object
															const formData = new FormData();

															// Append form data to FormData object
															formData.append("emailFrom", emailFromValue);
															formData.append("emailTo", emailToValue);
															formData.append("emailSubject", emailSubjectValue);
															formData.append("emailBody", formattedEmailBodyValue);
															formData.append("fileAttachment", selectedFile);

															// Make a fetch call to the API with the entered email address
															try {
																// send email if email - to, subject, body are provided
																if (emailToValue !== "" && emailSubjectValue !== "" && emailBodyValue !== "") {
																	const response = await fetch(`${updatedProps.API_HOST}user/custom_email`, {
																		method: "POST",
																		body: formData,
																	});

																	if (response.ok) {
																		console.log("Email sent successfully!");
																		showNotification(true, "Email sent successfully!");
																	} else {
																		console.error("Failed to send email.");
																		showNotification(false, "Failed to send email. Please check the provided values.");
																	}
																}
															} catch (error) {
																console.error("Error sending email:", error);
																showNotification(false, "Error sending email. Please check the provided values.");
															}

															// Close the modal after form submission
															dynamicEmailSendingModal.close();
														});

														// Create a close button
														const closeButton = document.createElement("button");
														closeButton.setAttribute("class", "custom-email-modal-close");
														closeButton.setAttribute("id", `custom-email-modal-close-${sendEmailButtonContainer.id}`);
														Object.assign(closeButton.style, {
															border: "none",
															borderRadius: "3px",
															backgroundColor: "transparent",
															color: "white",
															cursor: "pointer",
														});
														closeButton.textContent = "X";
														closeButton.type = "button";

														// Add event listener for closing the modal
														closeButton.addEventListener("click", () => {
															dynamicEmailSendingModal.close();
														});

														// Append the form and close button to the modal
														dynamicEmailSendingModal.appendChild(modalHeader);
														dynamicEmailSendingModal.appendChild(emailForm);
														modalHeader.appendChild(closeButton);
														modalFooter.appendChild(submitButton);
														dynamicEmailSendingModal.appendChild(modalFooter);

														// Append the modal to the document body
														document.body.appendChild(dynamicEmailSendingModal);

														// Show the modal
														dynamicEmailSendingModal.showModal();
													}
												};

												// adding click event on stellar-button from the table components
												clonedEmailButton.getElementsByClassName("send-email-button")[0].addEventListener("click", function (event) {
													console.log("send-email-button clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_send_email_button_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedEmailButton.getAttribute("id")}`,
														}),
													);

													sendEmailHandler(clonedEmailButton);
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										console.log("sendEmailButtons:", sendEmailButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form text_area
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let normalTextAreaContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										normalTextAreaContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("normal_text_area_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (normalTextAreaContainers) {
											if (normalTextAreaContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer, j) => {
												// let inputText = inputTextContainer.getElementsByTagName('input')[0];
												let normalTextAreaContainerClone = normalTextAreaContainer.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(normalTextAreaContainer, normalTextAreaContainerClone);

												// Change IDs and append cloned dropdowns to the container
												normalTextAreaContainerClone.setAttribute("id", `normal_text_area_field_table${id}_row${idx}_column${i}_${j}`);

												// Event listener for input event
												normalTextAreaContainerClone.addEventListener("input", (event) => {
													const enteredValue = event.target.value;
													localStorage.setItem(
														`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);
												});

												// Event listener for keypress event with Enter key
												normalTextAreaContainerClone.addEventListener("keypress", (event) => {
													if (event.key === "Enter") {
														const enteredValue = event.target.value;
														localStorage.setItem(
															`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
													}
												});

												// Append the dropdown to the table cell
												td.appendChild(normalTextAreaContainerClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer) => {
												normalTextAreaContainer.style.display = "none";
											});
										}
										// }
										console.log("normalTextAreaContainers:", normalTextAreaContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating custom-table-row-selection-checkbox-container
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let tableRowSelectionCheckboxContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (tableRowSelectionCheckboxContainers) {
											// if (tableRowSelectionCheckboxContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// }

											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
												let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
												let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

												// to store row info accross page change
												const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

												// Change IDs and append cloned dropdowns to the container
												tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
												
												// Retrieve stored data from session storage
									            let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

									            // Check if this checkbox should be checked
									            const existingData = storedData.find(
									                (item) =>
									                    item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
									                    item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
									                    item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
									                    item.paginationIndex === currentPageIndex
									            );
									            if (existingData) {
									                tableRowSelectionCheckboxClone.checked = true;
									            }
												
												tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
													const enteredValue = event.target.value;
													const isChecked = event.target.checked; // Check if the checkbox is checked

													// to store row info accross page change
													const checkboxData = {
												        "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												        tableId: `${id}`,
												        rowId: `custom_table_tbody_row${idx}_table${id}`,
												        cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												        inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
												        enteredValue: `${enteredValue}`,
												        paginationIndex: currentPageIndex, // Include the current page index
												    };

												    // Retrieve existing data
												    let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

													if (isChecked) {
														// Checkbox is checked, add object to local storage
														localStorage.setItem(
															`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
														// console.log("currentPageIndex", currentPageIndex);

														// If checked, check if the entry already exists
									                    const existingIndex = storedData.findIndex(
									                        (item) =>
									                            item.rowId === checkboxData.rowId &&
									                            item.cellId === checkboxData.cellId &&
									                            item.paginationIndex === checkboxData.paginationIndex &&
									                            item.inputId === checkboxData.inputId
									                    );

									                    if (existingIndex !== -1) {
									                        // Update existing entry
									                        storedData[existingIndex] = checkboxData;
									                    } else {
									                        // Add new entry
									                        storedData.push(checkboxData);
									                    }
													} else {
														// Checkbox is unchecked, remove object from local storage
														localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
														
														// If unchecked, remove the entry
									                    storedData = storedData.filter(
									                        (item) =>
									                            !(item.rowId === checkboxData.rowId &&
									                              item.cellId === checkboxData.cellId &&
									                              item.paginationIndex === checkboxData.paginationIndex &&
									                              item.inputId === checkboxData.inputId)
									                    );
													}

													// You can also perform other actions based on the checkbox state
													// For example, update UI, make API calls, etc.
													
													// Update session storage with the modified array
													sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
												});

												// Append the dropdown to the table cell
												td.appendChild(tableRowSelectionCheckboxClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
												tableRowSelectionCheckboxContainer.style.display = "none";
											});
										}
										// }
										console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_date_time field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dateTimeInputContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dateTimeInputContainers) {
											// if (dateTimeInputContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// 	td.style.scrollbarColor = `transparent transparent`;
											// }

											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
												let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
												let dateTimeInputClone = dateTimeInput.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dateTimeInput, dateTimeInputClone);

												// Change IDs and append cloned dropdowns to the container
												dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
												dateTimeInputClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_date_time_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dateTimeInputClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
												dateTimeInputContainer.style.display = "none";
											});
										}
										// }
										console.log("dateTimeInputContainers:", dateTimeInputContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating toggle
									try {
										// plot only if the field_type of header attribute is boolean
										if (fieldType === 'Boolean') {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let toggleContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											toggleContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('.custom-table-toggle-container');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (toggleContainers) {
												// if there are toggle elements being replicated then don't show the data model content in their table cells along with the toggle replicated components
												td.textContent = "";

												// if (toggleContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(toggleContainers).forEach((toggleContainer, j) => {
												    let toggleInnerContainer = toggleContainer.getElementsByClassName("custom-table-inner-toggle-container")[0];
												    let toggleClone = toggleInnerContainer.cloneNode(true);
												    // Copy styles from the original to the cloned dropdown
												    copyStyles(toggleInnerContainer, toggleClone);

												    // Initial state of the toggle
												    let isOn = false;

												    // Change IDs and append cloned dropdowns to the container
												    toggleClone.setAttribute("id", `custom_table_inner_toggle_container_table${id}_row${idx}_column${i}_${j}`);
												    if (toggleClone.getElementsByClassName("custom-table-toggle")[0]) {
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].setAttribute("id", `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`);

												        // update toggle once before/outside click event also so that it initializes according to the value coming from backend. If `content` value is true, toggle should be on, else off.
												        if (content) {
												        	isOn = true;
												        }
												        updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));

												        // now attach event to make the toggle function changing color on on/off actions and sending PUT api calls
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].addEventListener("click", (event) => {
												            isOn = !isOn;

												            // if the cell value is false, change it to true before sending it in PUT call payload, else send it as it it. When toggle is on, 'true' value should be send in the backend.
												            let cellValue = content ? false : true;

												            // a PUT api call will be made to update that particular record of the given table
												            updateTable(cellValue, `${obj[updatedProps.DataModelObjIdKeyname]}`, updatedProps.updatingCustomUrlValue, updatedProps.updatingBaseUrlValue, updatedProps.updatingApiPathValue, updatedProps.updatingApiQueryParamValue, updatedProps.updatingApiTableAttributeValue, updatedProps.updatingApiMethodValue, updatedProps.updatingApiUsernameValue, updatedProps.updatingApiPasswordValue, updatedProps.updatingApiBearerTokenValue, updatedProps.updatingApiMoreHeadersValue);

												            updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));
												            console.log(`Toggle is now: ${isOn ? 'ON' : 'OFF'}`, content);

												            // Construct the localStorage key
												            let toggleLocalStorageKey = `clicked_custom_table_toggle_table${id}_column${i}`;

												            // Retrieve existing data from local storage
												            let togglesData = JSON.parse(localStorage.getItem(toggleLocalStorageKey)) || [];

												            // Create the new toggle data object
												            let newToggleData = {
												                "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												                tableId: `${id}`,
												                rowId: `custom_table_tbody_row${idx}_table${id}`,
												                cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												                toggleEleId: `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`,
												                toggleStatus: isOn,
												            };

												            // Check if the toggle already exists
												            let existingToggleIndex = togglesData.findIndex(toggle => toggle.toggleEleId === newToggleData.toggleEleId);

												            if (existingToggleIndex !== -1) {
												                // Update the existing toggle data
												                togglesData[existingToggleIndex] = newToggleData;
												            } else {
												                // Add the new toggle data
												                togglesData.push(newToggleData);
												            }

												            // Update the local storage with the new/updated toggle data, column-wise
												            localStorage.setItem(toggleLocalStorageKey, JSON.stringify(togglesData));
												        });
												    }

												    // Append the dropdown to the table cell
												    td.appendChild(toggleClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(toggleContainers).forEach((toggleContainer) => {
													toggleContainer.style.display = "none";
												});
											}
											// }
											console.log("toggleContainers:", toggleContainers);
											// }
										}										
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating rich text input
                                    try {
                                        console.log("i", i);

                                        let inputTextContainers;

                                        console.log("customTableThead", customTableThead);

                                        console.log(
                                            "thead th i",
                                            customTableThead
                                            .getElementsByTagName("tr")[0]
                                            .querySelectorAll("th")[i]
                                        );

                                        let richTextIndexesData = updatedProps.richTextIndexes;
                                        console.log(
                                            "richTextIndexesData::::::::::",
                                            richTextIndexesData
                                        );

                                        if (richTextIndexesData.length > 0) {
                                            richTextIndexesData.forEach((x) => {
                                                console.log("richTextIndexesData x::::::::::", x);
                                                console.log("richTextIndexesData::::::", i);
                                                console.log("richTextIndexesData::::::", i);
                                                if (x === i) {
                                                    td.innerHTML = "";
                                                    let wrapperDiv = document.createElement("div");
                                                    wrapperDiv.setAttribute(
                                                        "id",
                                                        `rich_text_container_${idx}_${id}`
                                                    );
                                                    wrapperDiv.setAttribute(
                                                        "class",
                                                        "rich_text_table_container"
                                                    );

                                                    wrapperDiv.style.backgroundColor = "white";

                                                    td.appendChild(wrapperDiv);

                                                    let quill = new Quill(wrapperDiv, {
                                                        modules: {
                                                            toolbar: updatedProps.richTextOptions.toolbar ? [
                                                                [{
                                                                    header: [1, 2, 3, 4, 5, 6, false]
                                                                }],
                                                                ["bold", "italic", "underline", "strike"],
                                                                [{
                                                                    list: "bullet"
                                                                }, {
                                                                    list: "ordered"
                                                                }],
                                                                ["link"],
                                                                [{
                                                                    align: []
                                                                }],
                                                                [{
                                                                    color: []
                                                                }, {
                                                                    background: []
                                                                }],
                                                            ] : false,
                                                        },
                                                        placeholder: "",
                                                        theme: "snow",
                                                    });

                                                    updatedProps.richTextOptions.readOnly ? quill.enable(false) : quill.enable(true);

                                                    if (content) {
                                                        console.log('contentRich:::::::',content)
                                                        let jsonContent = JSON.parse(content);
                                                        console.log('jsonContent:::::::::',jsonContent)
                                                        console.log('type jsonContent:::::::::',typeof jsonContent)
                                                        if (jsonContent ) {
                                                            console.log('inside jsonContent')
                                                            quill.setContents(jsonContent);
                                                        }
                                                    } else {
                                                        let richTextContent;
                                                        richTextContent = sessionStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );
                                                        if (!richTextContent) richTextContent = localStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );

                                                        let parsedData = JSON.parse(richTextContent);
                                                        if (parsedData && parsedData.delta) {
                                                            quill.setContents(parsedData.delta);
                                                        }
                                                            
                                                        
                                                   }

                                                    quill.on("text-change", () => {
                                                        const delta = quill.getContents();

                                                        let deltaRef = {
                                                            "data-db-id": `${
                                                                obj[updatedProps.DataModelObjIdKeyname]
                                                                }`,
                                                            delta:delta
                                                        }
                                                        
                                                        localStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );
                                                        
                                                        sessionStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );
                                                    });

                                                   
                                                }
                                            });
                                        }

                                        // }
                                        console.log("inputTextContainers:", inputTextContainers);
                                        // }
                                    } catch (e) {
										// statements
										console.log(e);
									}

									tr.append(td);
								}

								if (customTableTbody) {
									customTableTbody.append(tr);
								}
								
								console.log("+idx+", idx);
						});
					}

					// if the response is a nested array-of-objects
					else if (typeof apiData === "object" && !isArrayofObjects(apiData)) {
						let nestedArrOfObj = findKeyValue(apiData, updatedProps.nestedArrOfObjKeynameValue);
						console.log("api nestedArrOfObj", nestedArrOfObj);

						if (isArrayofObjects(nestedArrOfObj)) {
							console.log("#");

							// loop over nestedArrOfObj array as a table's row
							nestedArrOfObj.slice(startIndex, endIndex).forEach((obj, idx) => {
								let tr = document.createElement("tr");
								tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
								tr.setAttribute("class", "custom-table-tbody-row");
								// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
								tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
								tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
								tr.style.width = "100%";
								if (idx % 2 !== 0) {
									tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
									// hover effect
									tr.addEventListener("mouseover", function () {
										tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
									});
									tr.addEventListener("mouseleave", function () {
										tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
									});
								} else {
									tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
									// hover effect
									tr.addEventListener("mouseover", function () {
										tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
									});
									tr.addEventListener("mouseleave", function () {
										tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
									});
								}

								// get data and add it as table's column
								for (let i = 0; i < updatedProps.numColsInputValue; i++) {
									let content, convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
									// using updatedProps.HeaderFields because only these are present in the downloading code
									// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
									// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
									// }
									// field name and type are for toggle replication
									let fieldName;

									try {
										if (`HeaderField_${i}` in updatedProps) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else {
												// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 0", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else {
													content = obj[`${updatedProps[`HeaderField_${i}`]}`];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
										} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object in not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
												// content = obj[referenceArrayObjName][referenceArrayKeyName];
											} else {
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 1", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else {
													content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
												// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
											}
											console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
										} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
											// Get the value of the attribute from 'this'
											console.log("customTableContainer", customTableContainer);
											const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

											// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
											if (headerFieldAttribute.includes(":")) {
												// Replace spaces around colon with an empty string
												const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
												// Split the modified string by colon
												const referenceArray = modifiedStr.split(":");

												const referenceArrayObjName = `${referenceArray[0]}_data`;
												const referenceArrayKeyName = referenceArray[1];

												console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
												console.log(obj[referenceArrayObjName]);
												// if the reference object is not undefined, then find the further keyname
												if (obj[referenceArrayObjName]) {
													content = obj[referenceArrayObjName][referenceArrayKeyName];
												}
											} else {
												// content = obj[headerFieldAttribute];
												// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
												if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
													// let convertedDate;
													dateString = obj[headerFieldAttribute];
													targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
													switch (targetFormat) {
														case "":
															convertedDate = obj[headerFieldAttribute];
															break;

														case "date_mon_year":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
															break;
														case "date_month_year":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
															break;
														case "dd/mm/yyyy":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
															break;
														case "mm/dd/yyyy":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
															break;
														case "dd-mm-yyyy":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
															break;

														case "date_mon_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
															break;
														case "date_month_year_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
															break;
														case "dd/mm/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
															break;
														case "mm/dd/yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
															break;
														case "dd-mm-yyyy_00_00_am_pm":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
															break;

														case "date_mon_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
															break;
														case "date_month_year_24_00":
															convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
															break;
														case "dd/mm/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
															break;
														case "mm/dd/yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
															break;
														case "dd-mm-yyyy_24_00":
															convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
															break;

														default:
															console.error("Invalid target date format");
													}

													content = convertedDate;
													console.log("converted content 2", dateString, content);
												} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
													content = obj[headerFieldAttribute];
												}

												// applying function from session storage on specific column (by index)
												if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
													try {
														// Retrieve the function string from sessionStorage
														const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

														if (storedFuncString) {
															// Recreate the function using the Function constructor
															const recreatedFunction = new Function(`return (${storedFuncString})`)();
															console.log('Function recreated:', recreatedFunction);

															// Use the recreated function
															content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
															console.log('+recreatedFunction content+', content);
														}
													} catch (error) {
														console.error(error);
													}
												}
											}
											console.log("headerFieldAttribute", headerFieldAttribute, content);
										}
									} catch (e) {
										// statements
										console.error(e);
									}

									// field name and type are for toggle replication
									let fieldType;
									let tableDataFields;
                  					if (tableData && ("fields" in tableData)) tableDataFields = tableData.fields;
									if (tableDataFields) fieldType = tableDataFields.filter((x) => x.field_name === fieldName)[0]?.field_type;
									
            						// console.log("+fieldType+", fieldType);

									// console.log("content, i", content, i);

									let td = document.createElement("td");
									td.setAttribute("data-gjs-type", "cell");
									// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
									td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
									td.style.padding = "8px";
									td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
									td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
									td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
									td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
									td.style.borderCollapse = "collapse";
									td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
									td.style.color = `${updatedProps.tBodyTdFontColor}`;
									td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
									td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
									td.innerHTML = content !== undefined ? content : "";

									// for replicating single column component
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerSingleColumnComponents;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerSingleColumnComponents = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('div[data-gjs-type="custom-table-thead-row"][class*="gjs-row"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerSingleColumnComponents) {
											if (headerSingleColumnComponents.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerSingleColumnComponents.length; j++) {
												// row.getElementsByTagName('td')[table_selected_column].append(headerSingleColumnComponents[j]);
												let clonedSingleColumnComponent = headerSingleColumnComponents[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerSingleColumnComponents[j], clonedSingleColumnComponent);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedSingleColumnComponent.setAttribute("id", `single_column_component_table${id}_row${idx}_column${i}_${j}`);

												// for replicating ACTION BUTTON inside single column components
												let innerClonedActionButtons = clonedSingleColumnComponent.getElementsByClassName("custom-table-action-button");
												if (innerClonedActionButtons.length > 0) {
													Array.from(innerClonedActionButtons).forEach((innerClonedActionButton, j_btn) => {
														if (innerClonedActionButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedActionButton.setAttribute("id", `custom_table_action${innerClonedActionButton.getAttribute("data-table-action")}_button_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedActionButton.getElementsByTagName("p")[0]) {
																innerClonedActionButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedActionButton.addEventListener("click", async function (event) {
																console.log("action-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
																localStorage.setItem(
																	`custom_table_action_button_table${id}_${innerClonedActionButton.getAttribute("data-table-action")}_column${i}_${j}_element${j_btn}_clicked`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"data-table-action": `${innerClonedActionButton.getAttribute("data-table-action")}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedActionButton.getAttribute("id")}`,
																	}),
																);

																// Style for the modals
																let modalStyle = {
																	padding: "2%",
																	backgroundColor: "white",
																	borderRadius: "5px",
																	boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
																	maxWidth: "50vw",
																	width: "30vw",
																	maxHeight: "40vh",
																	margin: "auto",
																	overflow: "auto",
																	"-ms-overflow-style": "none",
																	"scrollbar-width": "none",
																};

																// Style for the input fields
																let inputFieldStyle = {
																	display: "flex",
																	flexDirection: "column",
																	marginBottom: "15px",
																};

																// Style for the buttons
																let buttonStyle = {
																	width: "100%",
																	padding: "10px",
																	backgroundColor: "#007bff",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let submitButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																let closeButtonStyle = {
																	width: "50%",
																	padding: "10px",
																	backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
																	color: "white",
																	border: "none",
																	borderRadius: "5px",
																	cursor: "pointer",
																	// marginRight: 'auto',
																	// marginLeft: 'auto',
																	marginBottom: "5%",
																};

																// Edit modal
																if (innerClonedActionButton.getAttribute("data-table-action") === "edit") {
																	// Create the dialog element
																	let editModal = document.createElement("dialog");
																	editModal.setAttribute("id", "custom-modal");

																	// Apply styles to the modal
																	Object.assign(editModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Edit`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// variable only for the reference keyname user input
																	let selectedOptions = [];
																	// Check if updatedProps.EditableFields is not empty
																	if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
																		// Iterate over EditableFields to create form elements
																		for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																			// to handle non-Reference field keynames
																			if (!fieldName.includes("-")) {
																				let fieldLabel = document.createElement("label");
																				fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																				fieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(fieldLabel);

																				let inputField = document.createElement("input");
																				inputField.setAttribute("name", fieldName);
																				inputField.value = obj[fieldName]; // to initialize input fields with present values
																				Object.assign(inputField.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// Set input field type based on field type
																				switch (fieldType.toLowerCase()) {
																					case "string":
																						inputField.setAttribute("type", "text");
																						break;
																					case "boolean":
																						inputField.setAttribute("type", "radio");
																						break;
																					case "number":
																						inputField.setAttribute("type", "number");
																						break;
																					case "date-time":
																						inputField.setAttribute("type", "datetime-local");
																						break;
																					case "time":
																						inputField.setAttribute("type", "time");
																						break;
																					case "file":
																						inputField.setAttribute("type", "file");
																						break;
																					default:
																						inputField.setAttribute("type", "text");
																						break;
																				}
																				modalContent.appendChild(inputField);
																			}

																			// to handle Reference field keynames for example - WebProject1_Address-Sector
																			else if (fieldName.includes("-")) {
																				// variables to be used later in this if-condition
																				let [table, column] = fieldName.split("-");
																				// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																				// create its label
																				let refFieldLabel = document.createElement("label");
																				refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																				refFieldLabel.style.textTransform = "capitalize";
																				modalContent.appendChild(refFieldLabel);

																				// create a drodown for these keynames > its options will be all the values from response from that table
																				let referenceKeynameDropdown = document.createElement("select");
																				referenceKeynameDropdown.setAttribute("name", fieldName);
																				referenceKeynameDropdown.setAttribute("id", fieldName);
																				// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																				Object.assign(referenceKeynameDropdown.style, {
																					borderRadius: "5px",
																					border: "1px solid gray",
																					marginBottom: "5%",
																					padding: "1%",
																				});

																				// populate it with options using the response from its data-model API
																				let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																					headers: {
																						Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																					},
																				});
																				let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																				if (referenceKeynameValueOptionsData) {
																					// setting default option --select--
																					let defaultOption = document.createElement("option");
																					// defaultOption.setAttribute('disabled', 'disabled');
																					defaultOption.innerHTML = "--select--";
																					referenceKeynameDropdown.appendChild(defaultOption);

																					// loop over the response to fill the options of its reference table
																					referenceKeynameValueOptionsData.forEach((optObj) => {
																						let option = document.createElement("option");
																						option.value = optObj[`${column}`];
																						option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																						option.innerHTML = optObj[`${column}`];
																						// to initialize input fields with present values
																						if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																							option.setAttribute("selected", true);
																						}
																						referenceKeynameDropdown.appendChild(option);
																					});
																				}

																				referenceKeynameDropdown.addEventListener("change", function (e) {
																					let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																					// console.log('Selected value:', selectedOptionFromDropdown.value);
																					// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																					// get the reference ID of the table row from main obj to update
																					let refTableId;
																					let tableName = table.split("_")[1];
																					if (obj[tableName] !== null) {
																						refTableId = obj[tableName];
																					}

																					selectedOptions.push({
																						table,
																						column,
																						refTableId,
																						value: selectedOptionFromDropdown.value,
																						"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																					});
																				});

																				modalContent.appendChild(referenceKeynameDropdown);
																			}
																		}
																	}

																	console.log("+bu, bubt+", bu, bubt);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";
																	buttonContainer.style.marginTop = "5%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-submit-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
																	submitButton.innerHTML = "Submit";

																	// Apply styles to the button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", function (e) {
																		e.preventDefault(); // Prevent default form action
																		// Handle form submission here
																		console.log("Form submitted!", obj);

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Initialize an empty FormData object to get the form's input fields values
																		let formData = new FormData(modalContent);
																		let formValues = {};
																		for (const [key, value] of formData.entries()) {
																			formValues[key] = value;
																		}

																		// Create the to_insert object with the keynames from updatedProps.EditableFields
																		// and their corresponding values from formValues
																		let to_insert = {};
																		Object.keys(updatedProps.EditableFields).forEach((key) => {
																			if (formValues[key] !== undefined && !key.includes("-")) {
																				// Check if the form has the field
																				to_insert[key] = formValues[key];
																			}

																			// re-attach the reference id to PUT call else it will set the references to null
																			if (formValues[key] !== undefined && key.includes("-")) {
																				let x = key.split("-");
																				let table = x[0].split("_")[1];
																				if (obj[table] !== null) {
																					to_insert[table] = obj[table];
																				}
																			}
																		});

																		console.log("+to_insert+", to_insert);
																		console.log("+selectedOptions+", selectedOptions);

																		// logic to update all table cells the edit button is editing without refreshing
																		for (const key in to_insert) {
																			let columns = [];
																			if (updatedProps[`HeaderField_${i}`]) {
																				Object.keys(updatedProps).map((hF_i) => {
																					if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			} else {
																				Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																					if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																				});
																			}

																			// console.log('+columns+', columns);

																			columns.forEach(idx => {
																				let td = tr.getElementsByTagName('td')[idx];

																				let valueToUpdateCellWith = to_insert[key];
																				// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																				// Find the first text node
																				const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																				if (textNode) {
																					textNode.nodeValue = valueToUpdateCellWith;
																				} else {
																					// If no text node exists, create one
																					td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																				}
																			});
																		}

																		// Fetch call (to non-reference table) can be made here using the input field values
																		// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
																		fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "PUT",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																			body: JSON.stringify({
																				to_insert: to_insert,
																			}),
																		})
																			.then((response) => response.json())
																			.then((data) => console.log(data));

																		// Fetch call (for reference table)
																		if (selectedOptions.length > 0) {
																			selectedOptions.forEach((data) => {
																				// make api calls per object
																				let requestBody = {};
																				requestBody[data.column] = data.value; // Assign dynamic key-value pair
																				fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																					method: "PUT",
																					headers: {
																						"Content-Type": "application/json",
																						Authorization: `Bearer ${values.BearerToken}`,
																					},
																					body: JSON.stringify({
																						to_insert: requestBody,
																					}),
																				})
																					.then((response) => response.json())
																					.then((data) => console.log(data));
																			});
																		}

																		// Close the modal when the form is submitted
																		editModal.close();

																		// clear the selectedOptions for next turn
																		selectedOptions = [];

																		// // giving a delay in reload so that it doesn't change the url in the broswer tab
																		// setTimeout(function () {
																		// 	window.top.location.reload();
																		// }, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-close-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
																	closeButton.innerHTML = "Close";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (event) {
																		event.preventDefault(); // Prevent default behavior of the click event
																		editModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	editModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(editModal);

																	// Show the modal
																	editModal.showModal();
																}
																// Delete modal
																else if (innerClonedActionButton.getAttribute("data-table-action") === "delete") {
																	// Create the dialog element
																	let deleteModal = document.createElement("dialog");
																	deleteModal.setAttribute("id", "delete-modal");

																	// Apply styles to the modal
																	Object.assign(deleteModal.style, modalStyle);

																	// Create content for the modal
																	let modalContent = document.createElement("form");

																	// Apply styles to the modal content
																	Object.assign(modalContent.style, { display: "flex", flexDirection: "column", alignItems: "flex-start" });

																	// Create a label for the data model input field
																	let dataModelLabel = document.createElement("label");
																	dataModelLabel.innerHTML = `Delete`;
																	dataModelLabel.style.width = "100%";
																	dataModelLabel.style.borderBottom = "1px solid gray";
																	dataModelLabel.style.lineHeight = "2";
																	dataModelLabel.style.fontSize = "1.4vw";

																	// Apply styles to the label
																	Object.assign(dataModelLabel.style, { marginBottom: "10px" });

																	// Append label to the modal content
																	modalContent.appendChild(dataModelLabel);

																	// // Create an input field for data model with a default value
																	// let dataModelInputField = document.createElement("input");
																	// dataModelInputField.setAttribute('type', 'text');
																	// dataModelInputField.setAttribute('name', 'DataModel');
																	// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
																	// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(dataModelInputField.style, inputFieldStyle);

																	// modalContent.appendChild(dataModelInputField);

																	// // Create an input field for bearer token with a default value
																	// let bearerTokenInputField = document.createElement("input");
																	// bearerTokenInputField.setAttribute('type', 'text');
																	// bearerTokenInputField.setAttribute('name', 'BearerToken');
																	// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
																	// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

																	// // Apply styles to the input fields
																	// Object.assign(bearerTokenInputField.style, inputFieldStyle);

																	// modalContent.appendChild(bearerTokenInputField);

																	// Create a paragraph tag for confirmation message
																	let confirmationMessage = document.createElement("p");
																	confirmationMessage.style.marginBottom = "5%";
																	confirmationMessage.style.fontSize = "1vw";
																	confirmationMessage.innerHTML = "Are you sure?";

																	// Append confirmation message to the modal content
																	modalContent.appendChild(confirmationMessage);

																	let buttonContainer = document.createElement("div");
																	buttonContainer.setAttribute("class", "modal-button-container");
																	buttonContainer.style.display = "flex";
																	buttonContainer.style.alignItems = "center";
																	buttonContainer.style.justifyContent = "space-between";
																	buttonContainer.style.columnGap = "5%";
																	buttonContainer.style.width = "100%";

																	// Create a submit button for the form
																	let submitButton = document.createElement("button");
																	submitButton.setAttribute("class", "table-delete-yes-button");
																	submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
																	submitButton.innerHTML = "Yes";

																	// Apply styles to the "Yes" button
																	Object.assign(submitButton.style, submitButtonStyle);

																	submitButton.addEventListener("click", async function (e) {
																		e.preventDefault();
																		// Handle form submission here
																		console.log("Form submitted!");

																		// Create an object to store the values of input fields
																		let values = {};

																		// // Add default input field values to the values object
																		// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
																		// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Function to replace the substring between "/customroutes/" and the table name with "table_data"
																	    function replaceSubstringWithTableData(url) {
																	        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
																	    }

																	    // Add default input field values to the values object
																	    values["DataModel"] = updatedProps.customUrlValue 
																	        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
																	        : replaceSubstringWithTableData(bu);
																	        
																	    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

																		// Use the values in the fetch call or other logic
																		console.log("Input field values:", values);

																		// Make the fetch call to delete the item
																		let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																			method: "DELETE",
																			headers: {
																				"Content-Type": "application/json",
																				Authorization: `Bearer ${values.BearerToken}`,
																			},
																		});
																		// .then(response => {
																		//     if (response.ok) {
																		//         console.log('Item deleted successfully');
																		//     } else {
																		//         console.error('Failed to delete item');
																		//     }
																		// });

																		let responseData = response.json();
																		if (responseData.ok) {
																			console.log("Item deleted successfully");
																		} else {
																			console.error("Failed to delete item");
																		}

																		// Close the modal when the form is submitted
																		deleteModal.close();

																		// giving a delay in reload so that it doesn't change the url in the broswer tab
																		setTimeout(function () {
																			window.top.location.reload();
																		}, 2000);
																	});

																	// Create a close button for the modal
																	let closeButton = document.createElement("button");
																	closeButton.setAttribute("class", "table-delete-no-button");
																	closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
																	closeButton.innerHTML = "No";

																	// Apply styles to the button
																	Object.assign(closeButton.style, closeButtonStyle);

																	closeButton.addEventListener("click", function (e) {
																		e.preventDefault();
																		deleteModal.close(); // Close the modal when the close button is clicked
																	});

																	// Append content, submit button, and close button to the modal
																	buttonContainer.appendChild(submitButton);
																	buttonContainer.appendChild(closeButton);
																	modalContent.appendChild(buttonContainer);
																	deleteModal.appendChild(modalContent);

																	// Append the modal to the document
																	document.body.appendChild(deleteModal);

																	// Show the modal
																	deleteModal.showModal();
																}
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedActionButton);
														}
													});
												}

												// for replicating STELLAR BUTTON inside single column components
												let innerClonedStellarButtons = clonedSingleColumnComponent.getElementsByClassName("stellar-button-container");
												if (innerClonedStellarButtons.length > 0) {
													Array.from(innerClonedStellarButtons).forEach((innerClonedStellarButton, j_btn) => {
														if (innerClonedStellarButton) {
															// changing the clone's IDs so that they are present and available individually in LB and in download
															innerClonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															if (innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
																innerClonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}_element${j_btn}`);
															}

															// adding click event on stellar-button from the table components
															innerClonedStellarButton.addEventListener("click", function (event) {
																console.log("stellar-button clicked", "saving data in session storage");
																event.preventDefault();
																// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

																// this registers all stellar button clicks on LS
																// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
																// this registers only by the individuals in the column header
																localStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);

																sessionStorage.setItem(
																	`clicked_stellar_button_container_table${id}_column${i}_${j}_element${j_btn}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		buttonId: `${innerClonedStellarButton.getAttribute("id")}`,
																	}),
																);
															});

															clonedSingleColumnComponent.querySelector('div[class*="gjs-cell"]').appendChild(innerClonedStellarButton);
														}
													});
												}

												td.appendChild(clonedSingleColumnComponent);

												// to hide the header buttons after they have been cloned
												headerSingleColumnComponents[j].style.display = "none";
											}
										}
										// }
										console.log("headerSingleColumnComponents:", headerSingleColumnComponents);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating stellar buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerStellarButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										console.log("headerStellarButtons1:", headerStellarButtons);
										if (headerStellarButtons) {
											if (headerStellarButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											for (let j = 0; j < headerStellarButtons.length; j++) {
												console.log("headerStellarButtons[j]", headerStellarButtons[j]);
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(headerStellarButtons[j], clonedStellarButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
												clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
												if (clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0]) {
													clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header stellar button was not in single column component, only then append it to td
												if (!headerStellarButtons[j].parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedStellarButton);
													console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedStellarButton.addEventListener("click", function (event) {
													console.log("stellar-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													sessionStorage.setItem(
														`clicked_stellar_button_container_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedStellarButton.getAttribute("id")}`,
														}),
													);

													// highlight the table row that the stellar button has selected
													if (updatedProps.tBodyTrSelectColorHighlight)
														clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";

													customTable.onclick = function(event) {														
														// Check if the click is inside the custom table but outside the clicked row
														// if (
														// 	customTable.contains(event.target) && // Click is inside the customTable
														// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
														// ) {
														// 	console.log('@deselecting row', idx);
														// 	// Determine original color based on row index (even/odd)
														// 	const originalColor = idx % 2 === 0
														// 		? updatedProps.tBodyEvenTrBackgroundColor
														// 		: updatedProps.tBodyOddTrBackgroundColor;

														// 	// Reset the row's background color
														// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
														// }

														// Iterate over all rows in the table
														const rows = customTable.querySelectorAll("tr");
														rows.forEach((row, rowIndex) => {
															// Skip the currently selected row
															if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																return;
															}

															// Reset the background color based on even/odd index
															const originalColor = rowIndex % 2 === 0
																? updatedProps.tBodyEvenTrBackgroundColor
																: updatedProps.tBodyOddTrBackgroundColor;

															row.style.backgroundColor = originalColor;
														});
													};
												});

												// to hide the header buttons after they have been cloned
												headerStellarButtons[j].style.display = "none";
											}
										}
										// }
										console.log("headerStellarButtons:", headerStellarButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating action buttons
									try {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find action-button component in header column
										let headerActionButtons;
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);
										headerActionButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-action-button");

										if (headerActionButtons.length > 0) {
											if (headerActionButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(headerActionButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												await copyStyles(j, clonedButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedButton.setAttribute("id", `custom_table_action_button_table${id}_row${idx}_column${i}_${j}`);
												if (clonedButton.getElementsByTagName("p")[0]) {
													clonedButton.getElementsByTagName("p")[0].setAttribute("id", `custom_table_action_button_p_table${id}_row${idx}_column${i}_${j}`);
												}

												// if the header action button was not in single column component, only then append it to td
												console.log("j.parentNode.classList", j.parentNode.classList);
												if (!j.parentNode.classList.contains("gjs-cell")) {
													td.appendChild(clonedButton);
													console.log("appended");
												}

												// adding click event on stellar-button from the table components
												clonedButton.addEventListener("click", async function (event) {
													console.log("action-button clicked", "saving data in session storage");
													event.preventDefault();
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({
													localStorage.setItem(
														`custom_table_action_button_table${id}_clicked`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															"data-table-action": `${clonedButton.getAttribute("data-table-action")}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedButton.getAttribute("id")}`,
														}),
													);

													// Style for the modals
													let modalStyle = {
														padding: "2%",
														backgroundColor: "white",
														borderRadius: "5px",
														boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
														maxWidth: "50vw",
														width: "30vw",
														maxHeight: "40vh",
														margin: "auto",
														overflow: "auto",
														"-ms-overflow-style": "none",
														"scrollbar-width": "none",
													};

													// Style for the input fields
													let inputFieldStyle = {
														display: "flex",
														flexDirection: "column",
														marginBottom: "15px",
													};

													// Style for the buttons
													let buttonStyle = {
														width: "100%",
														padding: "10px",
														backgroundColor: "#007bff",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let submitButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													let closeButtonStyle = {
														width: "50%",
														padding: "10px",
														backgroundImage: "linear-gradient(180deg, #FF0000, #8E191D)",
														color: "white",
														border: "none",
														borderRadius: "5px",
														cursor: "pointer",
														// marginRight: 'auto',
														// marginLeft: 'auto',
														marginBottom: "5%",
													};

													// Edit modal
													if (clonedButton.getAttribute("data-table-action") === "edit") {
														// Create the dialog element
														let editModal = document.createElement("dialog");
														editModal.setAttribute("id", "custom-modal");

														// Apply styles to the modal
														Object.assign(editModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Edit`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// variable only for the reference keyname user input
														let selectedOptions = [];
														// Check if updatedProps.EditableFields is not empty
														if (updatedProps.EditableFields && Object.keys(updatedProps.EditableFields).length) {
															// Iterate over EditableFields to create form elements
															for (const [fieldName, fieldType] of Object.entries(updatedProps.EditableFields)) {
																// to handle non-Reference field keynames
																if (!fieldName.includes("-")) {
																	let fieldLabel = document.createElement("label");
																	fieldLabel.textContent = fieldName.replace(/_/g, " "); // Replace underscores with spaces for display
																	fieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(fieldLabel);

																	let inputField = document.createElement("input");
																	inputField.setAttribute("name", fieldName);
																	inputField.value = obj[fieldName]; // to initialize input fields with present values
																	Object.assign(inputField.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// Set input field type based on field type
																	switch (fieldType.toLowerCase()) {
																		case "string":
																			inputField.setAttribute("type", "text");
																			break;
																		case "boolean":
																			inputField.setAttribute("type", "radio");
																			break;
																		case "number":
																			inputField.setAttribute("type", "number");
																			break;
																		case "date-time":
																			inputField.setAttribute("type", "datetime-local");
																			break;
																		case "time":
																			inputField.setAttribute("type", "time");
																			break;
																		case "file":
																			inputField.setAttribute("type", "file");
																			break;
																		default:
																			inputField.setAttribute("type", "text");
																			break;
																	}
																	modalContent.appendChild(inputField);
																}

																// to handle Reference field keynames for example - WebProject1_Address-Sector
																else if (fieldName.includes("-")) {
																	// variables to be used later in this if-condition
																	let [table, column] = fieldName.split("-");
																	// console.log('*+', obj, table, column, obj[`${table.split('_')[1]}_data`]);

																	// create its label
																	let refFieldLabel = document.createElement("label");
																	refFieldLabel.textContent = fieldName.replace(/-/g, " "); // Replace underscores with spaces for display
																	refFieldLabel.style.textTransform = "capitalize";
																	modalContent.appendChild(refFieldLabel);

																	// create a drodown for these keynames > its options will be all the values from response from that table
																	let referenceKeynameDropdown = document.createElement("select");
																	referenceKeynameDropdown.setAttribute("name", fieldName);
																	referenceKeynameDropdown.setAttribute("id", fieldName);
																	// referenceKeynameDropdown.value = obj[`${table.split('_')[1]}_data`][column];
																	Object.assign(referenceKeynameDropdown.style, {
																		borderRadius: "5px",
																		border: "1px solid gray",
																		marginBottom: "5%",
																		padding: "1%",
																	});

																	// populate it with options using the response from its data-model API
																	let referenceKeynameValueOptions = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${table}`, {
																		headers: {
																			Authorization: `Bearer ${updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt}`,
																		},
																	});
																	let referenceKeynameValueOptionsData = await referenceKeynameValueOptions.json();
																	if (referenceKeynameValueOptionsData) {
																		// setting default option --select--
																		let defaultOption = document.createElement("option");
																		// defaultOption.setAttribute('disabled', 'disabled');
																		defaultOption.innerHTML = "--select--";
																		referenceKeynameDropdown.appendChild(defaultOption);

																		// loop over the response to fill the options of its reference table
																		referenceKeynameValueOptionsData.forEach((optObj) => {
																			let option = document.createElement("option");
																			option.value = optObj[`${column}`];
																			option.setAttribute("data-db-id", optObj[updatedProps.DataModelObjIdKeyname]);
																			option.innerHTML = optObj[`${column}`];
																			// to initialize input fields with present values
																			if (obj[`${table.split("_")[1]}_data`] && optObj[`${column}`] === obj[`${table.split("_")[1]}_data`][column]) {
																				option.setAttribute("selected", true);
																			}
																			referenceKeynameDropdown.appendChild(option);
																		});
																	}

																	referenceKeynameDropdown.addEventListener("change", function (e) {
																		let selectedOptionFromDropdown = referenceKeynameDropdown.options[referenceKeynameDropdown.selectedIndex];
																		// console.log('Selected value:', selectedOptionFromDropdown.value);
																		// console.log('Data-db-id:', selectedOptionFromDropdown.getAttribute('data-db-id'));

																		// get the reference ID of the table row from main obj to update
																		let refTableId;
																		let tableName = table.split("_")[1];
																		if (obj[tableName] !== null) {
																			refTableId = obj[tableName];
																		}

																		selectedOptions.push({
																			table,
																			column,
																			refTableId,
																			value: selectedOptionFromDropdown.value,
																			"data-db-id": selectedOptionFromDropdown.getAttribute("data-db-id"),
																		});
																	});

																	modalContent.appendChild(referenceKeynameDropdown);
																}
															}
														}

														console.log("+bu, bubt+", bu, bubt);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";
														buttonContainer.style.marginTop = "5%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-submit-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_submit_button`);
														submitButton.innerHTML = "Submit";

														// Apply styles to the button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", function (e) {
															e.preventDefault(); // Prevent default form action
															// Handle form submission here
															console.log("Form submitted!", obj);

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Initialize an empty FormData object to get the form's input fields values
															let formData = new FormData(modalContent);
															let formValues = {};
															for (const [key, value] of formData.entries()) {
																formValues[key] = value;
															}

															// Create the to_insert object with the keynames from updatedProps.EditableFields
															// and their corresponding values from formValues
															let to_insert = {};
															Object.keys(updatedProps.EditableFields).forEach((key) => {
																if (formValues[key] !== undefined && !key.includes("-")) {
																	// Check if the form has the field
																	to_insert[key] = formValues[key];
																}

																// re-attach the reference id to PUT call else it will set the references to null
																if (formValues[key] !== undefined && key.includes("-")) {
																	let x = key.split("-");
																	let table = x[0].split("_")[1];
																	if (obj[table] !== null) {
																		to_insert[table] = obj[table];
																	}
																}
															});

															console.log("+to_insert+", to_insert);
															console.log("+selectedOptions+", selectedOptions);

															// logic to update all table cells the edit button is editing without refreshing
															for (const key in to_insert) {
																let columns = [];
																if (updatedProps[`HeaderField_${i}`]) {
																	Object.keys(updatedProps).map((hF_i) => {
																		if (updatedProps[hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																} else {
																	Object.keys(updatedProps['HeaderFields']).map((hF_i) => {
																		if (updatedProps['HeaderFields'][hF_i] === key) columns.push(hF_i.split('_')[1]);
																	});
																}

																// console.log('+columns+', columns);

																columns.forEach(idx => {
																	let td = tr.getElementsByTagName('td')[idx];

																	let valueToUpdateCellWith = to_insert[key];
																	// console.log('+valueToUpdateCellWith', valueToUpdateCellWith);

																	// Find the first text node
																	const textNode = Array.from(td.childNodes).find(node => node.nodeType === Node.TEXT_NODE);

																	if (textNode) {
																		textNode.nodeValue = valueToUpdateCellWith;
																	} else {
																		// If no text node exists, create one
																		td.insertBefore(document.createTextNode(valueToUpdateCellWith), td.firstChild);
																	}
																});
															}

															// Fetch call (to non-reference table) can be made here using the input field values
															// Example: fetch('your-api-endpoint', { method: 'POST', body: new FormData(modalContent) });
															fetch(`${values.DataModel}` + `?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "PUT",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
																body: JSON.stringify({
																	to_insert: to_insert,
																}),
															})
																.then((response) => response.json())
																.then((data) => console.log(data));

															// Fetch call (for reference table)
															if (selectedOptions.length > 0) {
																selectedOptions.forEach((data) => {
																	// make api calls per object
																	let requestBody = {};
																	requestBody[data.column] = data.value; // Assign dynamic key-value pair
																	fetch(`${updatedProps.API_HOST}customroutes/table_data/${data.table}` + `?id=${data.refTableId}`, {
																		method: "PUT",
																		headers: {
																			"Content-Type": "application/json",
																			Authorization: `Bearer ${values.BearerToken}`,
																		},
																		body: JSON.stringify({
																			to_insert: requestBody,
																		}),
																	})
																		.then((response) => response.json())
																		.then((data) => console.log(data));
																});
															}

															// Close the modal when the form is submitted
															editModal.close();

															// clear the selectedOptions for next turn
															selectedOptions = [];

															// // giving a delay in reload so that it doesn't change the url in the broswer tab
															// setTimeout(function () {
															// 	window.top.location.reload();
															// }, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-close-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_close_button`);
														closeButton.innerHTML = "Close";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (event) {
															event.preventDefault(); // Prevent default behavior of the click event
															editModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														editModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(editModal);

														// Show the modal
														editModal.showModal();
													}
													// Delete modal
													else if (clonedButton.getAttribute("data-table-action") === "delete") {
														// Create the dialog element
														let deleteModal = document.createElement("dialog");
														deleteModal.setAttribute("id", "delete-modal");

														// Apply styles to the modal
														Object.assign(deleteModal.style, modalStyle);

														// Create content for the modal
														let modalContent = document.createElement("form");

														// Apply styles to the modal content
														Object.assign(modalContent.style, {
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
														});

														// Create a label for the data model input field
														let dataModelLabel = document.createElement("label");
														dataModelLabel.innerHTML = `Delete`;
														dataModelLabel.style.width = "100%";
														dataModelLabel.style.borderBottom = "1px solid gray";
														dataModelLabel.style.lineHeight = "2";
														dataModelLabel.style.fontSize = "1.4vw";

														// Apply styles to the label
														Object.assign(dataModelLabel.style, {
															marginBottom: "10px",
														});

														// Append label to the modal content
														modalContent.appendChild(dataModelLabel);

														// // Create an input field for data model with a default value
														// let dataModelInputField = document.createElement("input");
														// dataModelInputField.setAttribute('type', 'text');
														// dataModelInputField.setAttribute('name', 'DataModel');
														// dataModelInputField.setAttribute('placeholder', 'Platform Data Model API');
														// dataModelInputField.setAttribute('value', `${updatedProps.customUrlValue}`); // Set default value

														// // Apply styles to the input fields
														// Object.assign(dataModelInputField.style, inputFieldStyle);

														// modalContent.appendChild(dataModelInputField);

														// // Create an input field for bearer token with a default value
														// let bearerTokenInputField = document.createElement("input");
														// bearerTokenInputField.setAttribute('type', 'text');
														// bearerTokenInputField.setAttribute('name', 'BearerToken');
														// bearerTokenInputField.setAttribute('placeholder', 'Platform API Bearer Token');
														// // bearerTokenInputField.setAttribute('value', 'Data Model Bearer Token'); // Set default value

														// // Apply styles to the input fields
														// Object.assign(bearerTokenInputField.style, inputFieldStyle);

														// modalContent.appendChild(bearerTokenInputField);

														// Create a paragraph tag for confirmation message
														let confirmationMessage = document.createElement("p");
														confirmationMessage.style.marginBottom = "5%";
														confirmationMessage.style.fontSize = "1vw";
														confirmationMessage.innerHTML = "Are you sure?";

														// Append confirmation message to the modal content
														modalContent.appendChild(confirmationMessage);

														let buttonContainer = document.createElement("div");
														buttonContainer.setAttribute("class", "modal-button-container");
														buttonContainer.style.display = "flex";
														buttonContainer.style.alignItems = "center";
														buttonContainer.style.justifyContent = "space-between";
														buttonContainer.style.columnGap = "5%";
														buttonContainer.style.width = "100%";

														// Create a submit button for the form
														let submitButton = document.createElement("button");
														submitButton.setAttribute("class", "table-delete-yes-button");
														submitButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_yes_button`);
														submitButton.innerHTML = "Yes";

														// Apply styles to the "Yes" button
														Object.assign(submitButton.style, submitButtonStyle);

														submitButton.addEventListener("click", async function (e) {
															e.preventDefault();
															// Handle form submission here
															console.log("Form submitted!");

															// Create an object to store the values of input fields
															let values = {};

															// // Add default input field values to the values object
															// values["DataModel"] = updatedProps.customUrlValue ? updatedProps.customUrlValue.replace("data_grid", "table_data") : bu.replace("data_grid", "table_data");
															// values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Function to replace the substring between "/customroutes/" and the table name with "table_data"
														    function replaceSubstringWithTableData(url) {
														        return url.replace(/(\/customroutes\/)[^\/]+(?=\/)/, '$1table_data');
														    }

														    // Add default input field values to the values object
														    values["DataModel"] = updatedProps.customUrlValue 
														        ? replaceSubstringWithTableData(updatedProps.customUrlValue) 
														        : replaceSubstringWithTableData(bu);
														        
														    values["BearerToken"] = updatedProps.apiBearerTokenValue ? updatedProps.apiBearerTokenValue : bubt;

															// Use the values in the fetch call or other logic
															console.log("Input field values:", values);

															// Make the fetch call to delete the item
															let response = await fetch(`${values.DataModel}?id=${obj[updatedProps.DataModelObjIdKeyname]}`, {
																method: "DELETE",
																headers: {
																	"Content-Type": "application/json",
																	Authorization: `Bearer ${values.BearerToken}`,
																},
															});
															// .then(response => {
															//     if (response.ok) {
															//         console.log('Item deleted successfully');
															//     } else {
															//         console.error('Failed to delete item');
															//     }
															// });

															let responseData = response.json();
															if (responseData.ok) {
																console.log("Item deleted successfully");
															} else {
																console.error("Failed to delete item");
															}

															// Close the modal when the form is submitted
															deleteModal.close();

															// giving a delay in reload so that it doesn't change the url in the broswer tab
															setTimeout(function () {
																window.top.location.reload();
															}, 2000);
														});

														// Create a close button for the modal
														let closeButton = document.createElement("button");
														closeButton.setAttribute("class", "table-delete-no-button");
														closeButton.setAttribute("id", `table${id}_row${idx}_column${i}_delete_no_button`);
														closeButton.innerHTML = "No";

														// Apply styles to the button
														Object.assign(closeButton.style, closeButtonStyle);

														closeButton.addEventListener("click", function (e) {
															e.preventDefault();
															deleteModal.close(); // Close the modal when the close button is clicked
														});

														// Append content, submit button, and close button to the modal
														buttonContainer.appendChild(submitButton);
														buttonContainer.appendChild(closeButton);
														modalContent.appendChild(buttonContainer);
														deleteModal.appendChild(modalContent);

														// Append the modal to the document
														document.body.appendChild(deleteModal);

														// Show the modal
														deleteModal.showModal();
													}
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										console.log("headerActionButtons:", headerActionButtons);
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_file buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let headerInputFileButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										headerInputFileButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_file_container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (headerInputFileButtons) {
											if (headerInputFileButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											// for (let j = 0; j < headerInputFileButtons.length; j++) {
											// 	// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
											// 	let clonedInputFileButton = headerInputFileButtons[j].cloneNode(true);

											// 	// Copy the styles from the original button to the cloned button and its inner elements
											// 	copyStyles(headerInputFileButtons[j], clonedInputFileButton);

											// 	// changing the clone's IDs so that they are present and available individually in LB and in download
											// 	clonedInputFileButton.setAttribute('id', `input_file_container_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].setAttribute('id', `input_file_label_table${id}_row${idx}_column${i}_${j}`);
											// 	clonedInputFileButton.getElementsByClassName('input_file_label')[0].getElementsByTagName('p')[0].setAttribute('id', `input_file_label_p_table${id}_row${idx}_column${i}_${j}`);

											// 	td.appendChild(clonedInputFileButton);

											// 	let headerInputFileClickHandler = function(event) {
											// 		console.log('input_file_label clicked', 'saving data in session storage');
											// 		// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

											// 		// this registers all stellar button clicks on LS
											// 		// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
											// 		// this registers only by the individuals in the column header
											// 		localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, JSON.stringify({
											// 			'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
											// 			tableId: `${id}`,
											// 			rowId: `custom_table_tbody_row${idx}_table${id}`,
											// 			cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
											// 			buttonId: `${clonedInputFileButton.getAttribute('id')}`,
											// 		}));

											// 		console.log('clonedInputFileButton++', clonedInputFileButton);

											// 		let inputFile = document.createElement('input');
											//         inputFile.type = 'file';
											//         inputFile.style.display = 'none';

											//         inputFile.addEventListener('change', async function (event) {
											//               let file = inputFile.files[0];
											//               console.log('this++', this);
											//               console.log('file is:', file);

											//               let formData = new FormData();
											//               formData.append('file', file);

											//               try {
											//                   const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
											//                       method: 'POST',
											//                       headers: {
											//                           // 'Content-Type': 'multipart/form-data',
											//                       },
											//                       body: formData,
											//                   });

											//                   if (response.ok) {
											//                       const data = await response.json();
											//                       console.log('File uploaded:', data);
											//                       clonedInputFileButton.setAttribute('data_file_url', data["fileLocation"]);

											//                     // Retrieve the object from local storage
											// 			        let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j}`)) || {};

											// 			        // Add the data_file_url and its value to the object
											// 			        clickedFileData.data_file_url = data["fileLocation"];

											// 			        // Convert the modified object back to a JSON string
											// 			        let updatedClickedFileData = JSON.stringify(clickedFileData);

											// 			        // Update the local storage with the modified JSON string
											// 			        localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j}`, updatedClickedFileData);
											//                   } else {
											//                       console.error('Error uploading file:', response.statusText);
											//                   }

											//                   clonedInputFileButton.onclick = null;
											//               } catch (error) {
											//                   console.error('Error uploading file:', error);
											//               } finally {
											//               	console.log('after 1')
											//                   // Remove the inputFile element after API call completes
											//                   if (inputFile && inputFile.parentNode) {
											//                   	console.log('after 2')
											//                       inputFile.parentNode.removeChild(inputFile);
											//                   }
											//               }
											//         });

											//         clonedInputFileButton.appendChild(inputFile);
											//         inputFile.click();
											//         console.log('clicked', clonedInputFileButton);
											// 	};

											// 	// adding click event on stellar-button from the table components
											// 	// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
											// 	clonedInputFileButton.onclick = headerInputFileClickHandler;

											// 	// to hide the header buttons after they have been cloned
											// 	headerInputFileButtons[j].style.display = "none";
											// }

											Array.from(headerInputFileButtons).forEach(async (j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerInputFileButtons[j]);
												let clonedInputFileButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedInputFileButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedInputFileButton.setAttribute("id", `input_file_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].setAttribute("id", `input_file_label_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0]) {
													clonedInputFileButton.getElementsByClassName("input_file_label")[0].getElementsByTagName("p")[0].setAttribute("id", `input_file_label_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedInputFileButton);

												let headerInputFileClickHandler = function (event) {
													console.log("input_file_label clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_input_file_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedInputFileButton.getAttribute("id")}`,
														}),
													);

													// console.log('clonedInputFileButton++', clonedInputFileButton);

													let inputFile = document.createElement("input");
													inputFile.type = "file";
													inputFile.style.display = "none";

													inputFile.addEventListener("change", async function (event) {
														let file = inputFile.files[0];
														console.log("this++", this);
														console.log("file is:", file);

														let formData = new FormData();
														formData.append("file", file);

														try {
															const response = await fetch(`${props.API_HOST}uploadRoutes/upload`, {
																method: "POST",
																headers: {
																	// 'Content-Type': 'multipart/form-data',
																},
																body: formData,
															});

															if (response.ok) {
																const data = await response.json();
																console.log("File uploaded:", data);
																td.setAttribute("data_file_url", data["fileLocation"]);

																// Retrieve the object from local storage
																let clickedFileData = JSON.parse(localStorage.getItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`)) || {};

																// Add the data_file_url and its value to the object
																clickedFileData.data_file_url = data["fileLocation"];

																// Convert the modified object back to a JSON string
																let updatedClickedFileData = JSON.stringify(clickedFileData);

																// Update the local storage with the modified JSON string
																localStorage.setItem(`clicked_input_file_container_table${id}_column${i}_${j_idx}`, updatedClickedFileData);
															} else {
																console.error("Error uploading file:", response.statusText);
															}
														} catch (error) {
															console.error("Error uploading file:", error);
														} finally {
															// Remove the inputFile element after API call completes
															if (inputFile && inputFile.parentNode) {
																inputFile.parentNode.removeChild(inputFile);
															}
														}
													});

													clonedInputFileButton.appendChild(inputFile);
													inputFile.click();

													if (inputFile && inputFile.parentNode) {
														inputFile.parentNode.removeChild(inputFile);
													}
												};

												// adding click event on stellar-button from the table components
												// clonedInputFileButton.addEventListener('click', headerInputFileClickHandler);
												clonedInputFileButton.getElementsByClassName("input_file_label")[0].onclick = headerInputFileClickHandler;

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										console.log("headerInputFileButtons:", headerInputFileButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating radio_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let radioButtonGroups;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										radioButtonGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("radio_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (radioButtonGroups) {
											if (radioButtonGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(radioButtonGroups).forEach((radioButtonGroup, j) => {
												let clonedRadioButtonGroup = radioButtonGroup.cloneNode(true);
												// Copy styles from the original to the cloned button
												copyStyles2(radioButtonGroup, clonedRadioButtonGroup);

												// Change IDs and append cloned radio button groups to the container
												clonedRadioButtonGroup.setAttribute("id", `radio_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each radio button in the group
												clonedRadioButtonGroup.querySelectorAll('input[type="radio"]').forEach((radioButton) => {
													radioButton.addEventListener("click", (event) => {
														// Get the selected radio button's value and id
														const selectedValue = event.target.value;
														const selectedId = event.target.id;
														const selectedDataDbId = event.target.getAttribute("data_db_id") ? event.target.getAttribute("data_db_id") : "";

														// Store the selected value, id, and other relevant data in local storage
														localStorage.setItem(
															`clicked_radio_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																radioButtonId: `${selectedId}`,
																selectedValue: `${selectedValue}`,
																selectedDataDbId: `${selectedDataDbId}`,
															}),
														);

														// You can also perform other actions based on the selected radio button
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedRadioButtonGroup);
											});
										}

										// Hide the original radio button groups in the header column
										Array.from(radioButtonGroups).forEach((radioButtonGroup) => {
											radioButtonGroup.style.display = "none";
										});
										// }
										console.log("radioButtonGroups:", radioButtonGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroups;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroups = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroups) {
											if (checkboxGroups.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroups).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroups).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										console.log("checkboxGroups:", checkboxGroups);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating checkbox_regular_form_new
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let checkboxGroupsV2;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										checkboxGroupsV2 = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("checkbox_regular_form_new");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (checkboxGroupsV2) {
											if (checkboxGroupsV2.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(checkboxGroupsV2).forEach((checkboxGroup, j) => {
												let clonedCheckboxGroup = checkboxGroup.cloneNode(true);
												// Copy styles from the original to the cloned checkbox group
												copyStyles2(checkboxGroup, clonedCheckboxGroup);

												// Change IDs and append cloned checkbox groups to the container
												clonedCheckboxGroup.setAttribute("id", `checkbox_regular_form_v2_table${id}_row${idx}_column${i}_${j}`);

												// Add event listener to each checkbox in the group
												clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
													checkbox.addEventListener("change", (event) => {
														// Initialize an array to hold the values of checked checkboxes
														let selectedValues = [];
														let selectedIds = [];
														let selectedDataDbIds = [];

														// Iterate over all checkboxes to find the checked ones
														clonedCheckboxGroup.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
															// If a checkbox is checked, add its value and id to the respective arrays
															if (checkbox.checked) {
																selectedValues.push(checkbox.value);
																selectedIds.push(checkbox.id);
																selectedDataDbIds.push(checkbox.getAttribute("data_db_id") ? checkbox.getAttribute("data_db_id") : "");
															}
														});

														// Store the array of selected values and ids in local storage
														localStorage.setItem(
															`clicked_checkbox_regular_form_v2_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																selectedValues: selectedValues,
																selectedIds: selectedIds,
																selectedDataDbIds: selectedDataDbIds,
															}),
														);

														// You can also perform other actions based on the selected checkboxes
														// For example, update UI, make API calls, etc.
													});
												});

												// Append the container to the table cell
												td.appendChild(clonedCheckboxGroup);
											});

											// Hide the original radio button groups in the header column
											Array.from(checkboxGroupsV2).forEach((checkboxGroup) => {
												checkboxGroup.style.display = "none";
											});
										}
										// }
										console.log("checkboxGroupsV2:", checkboxGroupsV2);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating input_select
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dropdownContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dropdownContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("input_select_container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dropdownContainers) {
											if (dropdownContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(dropdownContainers).forEach((dropdownContainer, j) => {
												let dropdown = dropdownContainer.getElementsByTagName("select")[0];
												let dropdownClone = dropdown.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dropdown, dropdownClone);

												// Change IDs and append cloned dropdowns to the container
												dropdownClone.setAttribute("id", `input_select_table${id}_row${idx}_column${i}_${j}`);
												dropdownClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const selectedValue = event.target.value;
													const selectedOption = event.target.options[event.target.selectedIndex];
													const selectedId = selectedOption.id;

													// Get the data-db-id attribute value of the selected option
													const selectedDataDbId = selectedOption.getAttribute("data_db_id") ? selectedOption.getAttribute("data_db_id") : "";

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_input_select_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															selectedValue: `${selectedValue}`,
															selectedId: `${selectedId}`,
															selectedDataDbId: `${selectedDataDbId}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dropdownClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dropdownContainers).forEach((dropdownContainer) => {
												dropdownContainer.style.display = "none";
											});
										}
										// }
										console.log("dropdownContainers:", dropdownContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_text_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputTextContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputTextContainers) {
											if (inputTextContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
												let inputText = inputTextContainer.getElementsByTagName("input")[0];
												let inputTextClone = inputText.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputText, inputTextClone);

												// Change IDs and append cloned dropdowns to the container
												inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
												inputTextClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_text_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputTextClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputTextContainers).forEach((inputTextContainer) => {
												inputTextContainer.style.display = "none";
											});
										}
										// }
										console.log("inputTextContainers:", inputTextContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_number_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputNumberContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputNumberContainers) {
											if (inputNumberContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
												let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
												let inputNumberClone = inputNumber.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputNumber, inputNumberClone);

												// Change IDs and append cloned dropdowns to the container
												inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
												inputNumberClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_number_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputNumberClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
												inputNumberContainer.style.display = "none";
											});
										}
										// }
										console.log("inputNumberContainers:", inputNumberContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating send email buttons
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let sendEmailButtons;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										sendEmailButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("send-email-button-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (sendEmailButtons) {
											if (sendEmailButtons.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(sendEmailButtons).forEach((j, j_idx) => {
												// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
												let clonedEmailButton = j.cloneNode(true);

												// Copy the styles from the original button to the cloned button and its inner elements
												copyStyles(j, clonedEmailButton);

												// changing the clone's IDs so that they are present and available individually in LB and in download
												clonedEmailButton.setAttribute("id", `send_email_button_container_table${id}_row${idx}_column${i}_${j_idx}`);
												clonedEmailButton.getElementsByClassName("send-email-button")[0].setAttribute("id", `send_email_btn_table${id}_row${idx}_column${i}_${j_idx}`);
												if (clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0]) {
													clonedEmailButton.getElementsByClassName("send-email-button")[0].getElementsByTagName("p")[0].setAttribute("id", `send_email_btn_p_table${id}_row${idx}_column${i}_${j_idx}`);
												}

												td.appendChild(clonedEmailButton);

												const sendEmailHandler = async function (sendEmailButtonContainer) {
													let selectedFile;
													// reason - because this script was getting called multiple times, the dialog element was getting added multiple times so this code will be removing all the previously appended ones and then create a new one on-the-go
													if (sendEmailButtonContainer) {
														let modalAlreadyExists = document.querySelectorAll(`#custom-email-modal-${sendEmailButtonContainer.id}`);
														modalAlreadyExists.forEach((modal) => {
															console.log("removing modal 2", modal);
															modal.remove();
														});

														// // fetch filter call for content
														// let filteredResponse = await fetch(`${updatedProps.API_HOST}customroutes/data_grid/${extractTableName(updatedProps.customUrlValue)}`, {
														// 	method: "POST",
														// 	headers: {
														// 		'Content-Type': "application/json",
														// 		Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
														// 	},
														// 	body: JSON.stringify({
														// 	    filterData: {
														// 	        _id: `${obj[updatedProps.DataModelObjIdKeyname]}`,
														// 	    }
														// 	}),
														// });

														// let filteredResponseData = await filteredResponse.json();

														// Get the keys from updatedProps.HeaderFields object and from HTML attributes
														const headerFieldKeys = Object.keys(updatedProps.HeaderFields);
														const htmlAttributeKeys = Array.from(customTableContainer.attributes)
															.filter((attr) => attr.name.includes("headerfield_"))
															.map((attr) => attr.value);

														console.log("htmlAttributeKeys", htmlAttributeKeys);

														// Combine the keys from both sources
														const allKeys = [...headerFieldKeys, ...htmlAttributeKeys];

														// Accumulate all key-value pairs
														const keyValuePairs = [];

														// Get the values corresponding to the keys from the obj object
														htmlAttributeKeys.forEach((key) => {
															let value;
															if (/:/.test(key)) {
																// If the key contains a colon (indicating nested properties), split it
																const [mainKey, subKey] = key.split(/\s*:\s*/);
																// Check if the main key exists in obj
																if (obj[`${mainKey}_data`]) {
																	// Check if the main key points to an object
																	if (typeof obj[`${mainKey}_data`] === "object" && obj[`${mainKey}_data`][subKey]) {
																		value = obj[`${mainKey}_data`][subKey];
																		keyValuePairs.push(`${subKey}: ${value}`);
																	}
																}
															} else {
																value = obj[key];
																keyValuePairs.push(`${key}: ${value}`);
															}
															// if (value) {
															//     keyValuePairs.push(`${key}: ${value}`);
															// }
														});

														console.log("allKeys, keyValuePairs", allKeys, keyValuePairs);

														// Join all key-value pairs into a single string
														const filteredKeyValuePairs = keyValuePairs.join("\n");

														console.log("allKeys, filteredKeyValuePairs", allKeys, filteredKeyValuePairs);

														// Create a dialog modal with styling
														const dynamicEmailSendingModal = document.createElement("dialog");
														dynamicEmailSendingModal.setAttribute("class", `custom-email-modal`);
														dynamicEmailSendingModal.setAttribute("id", `custom-email-modal-${sendEmailButtonContainer.id}`);
														Object.assign(dynamicEmailSendingModal.style, {
															margin: "auto",
															width: "35vw",
															overflow: "auto",
															backgroundColor: "white",
															boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
															border: "1px solid #ccc",
															borderRadius: "20px",
															zIndex: "1000",
															padding: "0 0 1% 0",
														});

														// modal UI header & footer
														let modalHeader = document.createElement("div");
														Object.assign(modalHeader.style, {
															// backgroundColor: "#1991E7",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															color: "white",
															fontSize: "1vw",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});
														modalHeader.innerHTML = "Compose Message";

														const modalFooter = document.createElement("div");
														Object.assign(modalFooter.style, {
															backgroundColor: "transparent",
															paddingTop: "0.8%",
															paddingBottom: "0.8%",
															paddingLeft: "5%",
															paddingRight: "5%",
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														});

														// Create a form element
														const emailForm = document.createElement("form");
														Object.assign(emailForm.style, {
															padding: "3% 6%",
															display: "flex",
															flexDirection: "column",
														});

														// Check if EmailFromDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailFromDynamicCheckbox) {
														const emailFromDiv = document.createElement("div");
														Object.assign(emailFromDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailFromLabel = document.createElement("label");
														emailFromLabel.innerHTML = "From";
														// Create an input email field for the sender's email address with styling
														const emailFromField = document.createElement("input");
														Object.assign(emailFromField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailFromField, {
															type: "email",
															id: `emailFrom-${sendEmailButtonContainer.id}`,
															name: "emailFrom",
															placeholder: "Sender's Email Address",
														});
														// visiblity will be hidden because this field is not actually required and it needs to occupy same space for UI alignment purposes
														const emailFromRequiredFieldLabel = document.createElement("span");
														emailFromRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailFromRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
															visibility: "hidden",
														});

														// Append the email input field to the form
														emailFromDiv.appendChild(emailFromLabel);
														emailFromDiv.appendChild(emailFromField);
														emailFromDiv.appendChild(emailFromRequiredFieldLabel);
														emailForm.appendChild(emailFromDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailToDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailToDynamicCheckbox) {
														const emailToDiv = document.createElement("div");
														Object.assign(emailToDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailToLabel = document.createElement("label");
														emailToLabel.innerHTML = "To";
														// Create an input email field for the receiver's email address with styling
														const emailToField = document.createElement("input");
														Object.assign(emailToField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailToField, {
															type: "text",
															id: `emailTo-${sendEmailButtonContainer.id}`,
															name: "emailTo",
															placeholder: "Receiver's Email Addresses (comma separated)",
														});
														const emailToRequiredFieldLabel = document.createElement("span");
														emailToRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailToRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailToDiv.appendChild(emailToLabel);
														emailToDiv.appendChild(emailToField);
														emailToDiv.appendChild(emailToRequiredFieldLabel);
														emailForm.appendChild(emailToDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailSubjectDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailSubjectDynamicCheckbox) {
														const emailSubjectDiv = document.createElement("div");
														Object.assign(emailSubjectDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailSubjectLabel = document.createElement("label");
														emailSubjectLabel.innerHTML = "Subject";
														// Create an input email field for the receiver's email address with styling
														const emailSubjectField = document.createElement("input");
														Object.assign(emailSubjectField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailSubjectField, {
															type: "text",
															id: `emailSubject-${sendEmailButtonContainer.id}`,
															name: "emailSubject",
															placeholder: "Email's subject",
														});
														const emailSubjectRequiredFieldLabel = document.createElement("span");
														emailSubjectRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailSubjectRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														// Append the email input field to the form
														emailSubjectDiv.appendChild(emailSubjectLabel);
														emailSubjectDiv.appendChild(emailSubjectField);
														emailSubjectDiv.appendChild(emailSubjectRequiredFieldLabel);
														emailForm.appendChild(emailSubjectDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if EmailBodyDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.EmailBodyDynamicCheckbox) {
														const emailBodyDiv = document.createElement("div");
														Object.assign(emailBodyDiv.style, {
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															columnGap: "5%",
														});

														const emailBodyLabel = document.createElement("label");
														emailBodyLabel.innerHTML = "Body";
														// Create an input email field for the receiver's email address with styling
														const emailBodyField = document.createElement("textarea");
														Object.assign(emailBodyField.style, {
															marginBottom: "10px",
															padding: "5px",
															width: "80%",
															borderRadius: "5px",
															backgroundColor: "transparent",
															boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
														});
														Object.assign(emailBodyField, {
															rows: "5",
															cols: "10",
															id: `emailBody-${sendEmailButtonContainer.id}`,
															name: "emailBody",
															placeholder: "Email's body",
														});
														const emailBodyRequiredFieldLabel = document.createElement("span");
														emailBodyRequiredFieldLabel.innerHTML = "(required)";
														Object.assign(emailBodyRequiredFieldLabel.style, {
															color: "red",
															fontStyle: "italic",
															fontSize: "0.9vw",
															marginLeft: "1%",
														});

														emailBodyField.value = filteredKeyValuePairs;

														// Append the email input field to the form
														emailBodyDiv.appendChild(emailBodyLabel);
														emailBodyDiv.appendChild(emailBodyField);
														emailBodyDiv.appendChild(emailBodyRequiredFieldLabel);
														emailForm.appendChild(emailBodyDiv);
														// }
														// // else take directly from props
														// else {}

														// Check if FileAttachmentDynamicCheckbox is true and then create an input field for it in the modal
														// if (props.FileAttachmentDynamicCheckbox) {
														// Create an input email field for the receiver's email address with styling
														const fileAttachmentField = document.createElement("input");
														Object.assign(fileAttachmentField.style, {
															// marginBottom: "10px",
															// padding: "5px",
															// width: "80%",
															// borderRadius: "5px",
															// backgroundColor: "transparent",
															// boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3)',
															display: "none",
														});
														Object.assign(fileAttachmentField, {
															type: "file",
															id: `fileAttachment-${sendEmailButtonContainer.id}`,
															name: "fileAttachment",
														});

														// Add event listener for the file input field change event
														fileAttachmentField.addEventListener("change", (event) => {
															selectedFile = event.target.files[0];
															console.log("Selected file:", selectedFile);
															// You can do further processing with the selected file here
														});

														// Create a label for the file input button
														const fileAttachmentLabel = document.createElement("label");
														fileAttachmentLabel.setAttribute("for", "fileAttachmentInput"); // Set the 'for' attribute to match the input field's id
														fileAttachmentLabel.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16"><path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/></svg>';
														Object.assign(fileAttachmentLabel.style, {
															cursor: "pointer",
														});

														// Attach click event to the label
														fileAttachmentLabel.addEventListener("click", () => {
															fileAttachmentField.click();
														});

														// Append the email input field to the form
														modalFooter.appendChild(fileAttachmentLabel);
														modalFooter.appendChild(fileAttachmentField);
														// }
														// // else take directly from props
														// else {}

														// Create a submit button
														const submitButton = document.createElement("button");
														Object.assign(submitButton.style, {
															// marginBottom: "3%",
															// marginRight: "4%",
															alignSelf: "flex-end",
															padding: "1.5% 3%",
															border: "none",
															borderRadius: "25px",
															// backgroundColor: "#007bff",
															backgroundImage: "linear-gradient(180deg, #62C4FB, #066FAB)",
															color: "white",
															cursor: "pointer",
														});
														submitButton.textContent = "Send";
														submitButton.type = "submit";

														// Add event listener for form submission
														submitButton.addEventListener("click", async (event) => {
															event.preventDefault(); // Prevent form submission

															// Get the entered email values
															const emailFromValue = emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailFrom-${sendEmailButtonContainer.id}"]`).value : "";
															let emailToValue = emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailTo-${sendEmailButtonContainer.id}"]`).value : "";
															emailToValue = emailToValue.includes(",") ? emailToValue.split(/\s*,\s*/) : emailToValue;
															const emailSubjectValue = emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="emailSubject-${sendEmailButtonContainer.id}"]`).value : "";
															const emailBodyValue = emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`textarea[id="emailBody-${sendEmailButtonContainer.id}"]`).value : "";
															const fileAttachmentValue = emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`) ? emailForm.querySelector(`input[id="fileAttachment-${sendEmailButtonContainer.id}"]`).value : "";

															// to preserve line breaks in email body
															const formattedEmailBodyValue = emailBodyValue.replace(/\n/g, "<br>");

															console.log("form values", emailFromValue, emailToValue, emailSubjectValue, emailBodyValue, formattedEmailBodyValue, fileAttachmentValue);
															console.log("selectedFile", selectedFile);

															// Create a FormData object
															const formData = new FormData();

															// Append form data to FormData object
															formData.append("emailFrom", emailFromValue);
															formData.append("emailTo", emailToValue);
															formData.append("emailSubject", emailSubjectValue);
															formData.append("emailBody", formattedEmailBodyValue);
															formData.append("fileAttachment", selectedFile);

															// Make a fetch call to the API with the entered email address
															try {
																// send email if email - to, subject, body are provided
																if (emailToValue !== "" && emailSubjectValue !== "" && emailBodyValue !== "") {
																	const response = await fetch(`${updatedProps.API_HOST}user/custom_email`, {
																		method: "POST",
																		body: formData,
																	});

																	if (response.ok) {
																		console.log("Email sent successfully!");
																		showNotification(true, "Email sent successfully!");
																	} else {
																		console.error("Failed to send email.");
																		showNotification(false, "Failed to send email. Please check the provided values.");
																	}
																}
															} catch (error) {
																console.error("Error sending email:", error);
																showNotification(false, "Error sending email. Please check the provided values.");
															}

															// Close the modal after form submission
															dynamicEmailSendingModal.close();
														});

														// Create a close button
														const closeButton = document.createElement("button");
														closeButton.setAttribute("class", "custom-email-modal-close");
														closeButton.setAttribute("id", `custom-email-modal-close-${sendEmailButtonContainer.id}`);
														Object.assign(closeButton.style, {
															border: "none",
															borderRadius: "3px",
															backgroundColor: "transparent",
															color: "white",
															cursor: "pointer",
														});
														closeButton.textContent = "X";
														closeButton.type = "button";

														// Add event listener for closing the modal
														closeButton.addEventListener("click", () => {
															dynamicEmailSendingModal.close();
														});

														// Append the form and close button to the modal
														dynamicEmailSendingModal.appendChild(modalHeader);
														dynamicEmailSendingModal.appendChild(emailForm);
														modalHeader.appendChild(closeButton);
														modalFooter.appendChild(submitButton);
														dynamicEmailSendingModal.appendChild(modalFooter);

														// Append the modal to the document body
														document.body.appendChild(dynamicEmailSendingModal);

														// Show the modal
														dynamicEmailSendingModal.showModal();
													}
												};

												// adding click event on stellar-button from the table components
												clonedEmailButton.getElementsByClassName("send-email-button")[0].addEventListener("click", function (event) {
													console.log("send-email-button clicked", "saving data in session storage");
													// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

													// this registers all stellar button clicks on LS
													// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
													// this registers only by the individuals in the column header
													localStorage.setItem(
														`clicked_send_email_button_container_table${id}_column${i}_${j_idx}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															buttonId: `${clonedEmailButton.getAttribute("id")}`,
														}),
													);

													sendEmailHandler(clonedEmailButton);
												});

												// to hide the header buttons after they have been cloned
												j.style.display = "none";
											});
										}
										// }
										console.log("sendEmailButtons:", sendEmailButtons);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form text_area
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let normalTextAreaContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										normalTextAreaContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("normal_text_area_regular_form");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (normalTextAreaContainers) {
											if (normalTextAreaContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
											}

											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer, j) => {
												// let inputText = inputTextContainer.getElementsByTagName('input')[0];
												let normalTextAreaContainerClone = normalTextAreaContainer.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(normalTextAreaContainer, normalTextAreaContainerClone);

												// Change IDs and append cloned dropdowns to the container
												normalTextAreaContainerClone.setAttribute("id", `normal_text_area_field_table${id}_row${idx}_column${i}_${j}`);

												// Event listener for input event
												normalTextAreaContainerClone.addEventListener("input", (event) => {
													const enteredValue = event.target.value;
													localStorage.setItem(
														`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);
												});

												// Event listener for keypress event with Enter key
												normalTextAreaContainerClone.addEventListener("keypress", (event) => {
													if (event.key === "Enter") {
														const enteredValue = event.target.value;
														localStorage.setItem(
															`clicked_normal_text_area_field_table${id}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
													}
												});

												// Append the dropdown to the table cell
												td.appendChild(normalTextAreaContainerClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(normalTextAreaContainers).forEach((normalTextAreaContainer) => {
												normalTextAreaContainer.style.display = "none";
											});
										}
										// }
										console.log("normalTextAreaContainers:", normalTextAreaContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating custom-table-row-selection-checkbox-container
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let tableRowSelectionCheckboxContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (tableRowSelectionCheckboxContainers) {
											// if (tableRowSelectionCheckboxContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// }

											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
												let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
												let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

												// to store row info accross page change
												const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

												// Change IDs and append cloned dropdowns to the container
												tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
												
												// Retrieve stored data from session storage
									            let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

									            // Check if this checkbox should be checked
									            const existingData = storedData.find(
									                (item) =>
									                    item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
									                    item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
									                    item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
									                    item.paginationIndex === currentPageIndex
									            );
									            if (existingData) {
									                tableRowSelectionCheckboxClone.checked = true;
									            }
												
												tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
													const enteredValue = event.target.value;
													const isChecked = event.target.checked; // Check if the checkbox is checked

													// to store row info accross page change
													const checkboxData = {
												        "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												        tableId: `${id}`,
												        rowId: `custom_table_tbody_row${idx}_table${id}`,
												        cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												        inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
												        enteredValue: `${enteredValue}`,
												        paginationIndex: currentPageIndex, // Include the current page index
												    };

												    // Retrieve existing data
												    let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

													if (isChecked) {
														// Checkbox is checked, add object to local storage
														localStorage.setItem(
															`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
														// console.log("currentPageIndex", currentPageIndex);

														// If checked, check if the entry already exists
									                    const existingIndex = storedData.findIndex(
									                        (item) =>
									                            item.rowId === checkboxData.rowId &&
									                            item.cellId === checkboxData.cellId &&
									                            item.paginationIndex === checkboxData.paginationIndex &&
									                            item.inputId === checkboxData.inputId
									                    );

									                    if (existingIndex !== -1) {
									                        // Update existing entry
									                        storedData[existingIndex] = checkboxData;
									                    } else {
									                        // Add new entry
									                        storedData.push(checkboxData);
									                    }
													} else {
														// Checkbox is unchecked, remove object from local storage
														localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
														
														// If unchecked, remove the entry
									                    storedData = storedData.filter(
									                        (item) =>
									                            !(item.rowId === checkboxData.rowId &&
									                              item.cellId === checkboxData.cellId &&
									                              item.paginationIndex === checkboxData.paginationIndex &&
									                              item.inputId === checkboxData.inputId)
									                    );
													}

													// You can also perform other actions based on the checkbox state
													// For example, update UI, make API calls, etc.
													
													// Update session storage with the modified array
													sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
												});

												// Append the dropdown to the table cell
												td.appendChild(tableRowSelectionCheckboxClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
												tableRowSelectionCheckboxContainer.style.display = "none";
											});
										}
										// }
										console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_date_time field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dateTimeInputContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dateTimeInputContainers) {
											// if (dateTimeInputContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// 	td.style.scrollbarColor = `transparent transparent`;
											// }

											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
												let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
												let dateTimeInputClone = dateTimeInput.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dateTimeInput, dateTimeInputClone);

												// Change IDs and append cloned dropdowns to the container
												dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
												dateTimeInputClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_date_time_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dateTimeInputClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
												dateTimeInputContainer.style.display = "none";
											});
										}
										// }
										console.log("dateTimeInputContainers:", dateTimeInputContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating toggle
									try {
										// plot only if the field_type of header attribute is boolean
										if (fieldType === 'Boolean') {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let toggleContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											toggleContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('.custom-table-toggle-container');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (toggleContainers) {
												// if there are toggle elements being replicated then don't show the data model content in their table cells along with the toggle replicated components
												td.textContent = "";

												// if (toggleContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(toggleContainers).forEach((toggleContainer, j) => {
												    let toggleInnerContainer = toggleContainer.getElementsByClassName("custom-table-inner-toggle-container")[0];
												    let toggleClone = toggleInnerContainer.cloneNode(true);
												    // Copy styles from the original to the cloned dropdown
												    copyStyles(toggleInnerContainer, toggleClone);

												    // Initial state of the toggle
												    let isOn = false;

												    // Change IDs and append cloned dropdowns to the container
												    toggleClone.setAttribute("id", `custom_table_inner_toggle_container_table${id}_row${idx}_column${i}_${j}`);
												    if (toggleClone.getElementsByClassName("custom-table-toggle")[0]) {
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].setAttribute("id", `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`);

												        // update toggle once before/outside click event also so that it initializes according to the value coming from backend. If `content` value is true, toggle should be on, else off.
												        if (content) {
												        	isOn = true;
												        }
												        updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));

												        // now attach event to make the toggle function changing color on on/off actions and sending PUT api calls
												        toggleClone.getElementsByClassName("custom-table-toggle")[0].addEventListener("click", (event) => {
												            isOn = !isOn;

												            // if the cell value is false, change it to true before sending it in PUT call payload, else send it as it it. When toggle is on, 'true' value should be send in the backend.
												            let cellValue = content ? false : true;

												            // a PUT api call will be made to update that particular record of the given table
												            updateTable(cellValue, `${obj[updatedProps.DataModelObjIdKeyname]}`, updatedProps.updatingCustomUrlValue, updatedProps.updatingBaseUrlValue, updatedProps.updatingApiPathValue, updatedProps.updatingApiQueryParamValue, updatedProps.updatingApiTableAttributeValue, updatedProps.updatingApiMethodValue, updatedProps.updatingApiUsernameValue, updatedProps.updatingApiPasswordValue, updatedProps.updatingApiBearerTokenValue, updatedProps.updatingApiMoreHeadersValue);

												            updateToggle(toggleClone.getElementsByClassName("custom-table-toggle")[0], isOn, toggleInnerContainer.getAttribute('data-isOnColor'), toggleInnerContainer.getAttribute('data-isOffColor'));
												            console.log(`Toggle is now: ${isOn ? 'ON' : 'OFF'}`, content);

												            // Construct the localStorage key
												            let toggleLocalStorageKey = `clicked_custom_table_toggle_table${id}_column${i}`;

												            // Retrieve existing data from local storage
												            let togglesData = JSON.parse(localStorage.getItem(toggleLocalStorageKey)) || [];

												            // Create the new toggle data object
												            let newToggleData = {
												                "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												                tableId: `${id}`,
												                rowId: `custom_table_tbody_row${idx}_table${id}`,
												                cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												                toggleEleId: `custom_table_toggle_table${id}_row${idx}_column${i}_${j}`,
												                toggleStatus: isOn,
												            };

												            // Check if the toggle already exists
												            let existingToggleIndex = togglesData.findIndex(toggle => toggle.toggleEleId === newToggleData.toggleEleId);

												            if (existingToggleIndex !== -1) {
												                // Update the existing toggle data
												                togglesData[existingToggleIndex] = newToggleData;
												            } else {
												                // Add the new toggle data
												                togglesData.push(newToggleData);
												            }

												            // Update the local storage with the new/updated toggle data, column-wise
												            localStorage.setItem(toggleLocalStorageKey, JSON.stringify(togglesData));
												        });
												    }

												    // Append the dropdown to the table cell
												    td.appendChild(toggleClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(toggleContainers).forEach((toggleContainer) => {
													toggleContainer.style.display = "none";
												});
											}
											// }
											console.log("toggleContainers:", toggleContainers);
											// }
										}										
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating rich text input
                                    try {
                                        console.log("i", i);

                                        let inputTextContainers;

                                        console.log("customTableThead", customTableThead);

                                        console.log(
                                            "thead th i",
                                            customTableThead
                                            .getElementsByTagName("tr")[0]
                                            .querySelectorAll("th")[i]
                                        );

                                        let richTextIndexesData = updatedProps.richTextIndexes;
                                        console.log(
                                            "richTextIndexesData::::::::::",
                                            richTextIndexesData
                                        );

                                        if (richTextIndexesData.length > 0) {
                                            richTextIndexesData.forEach((x) => {
                                                console.log("richTextIndexesData x::::::::::", x);
                                                console.log("richTextIndexesData::::::", i);
                                                console.log("richTextIndexesData::::::", i);
                                                if (x === i) {
                                                    td.innerHTML = "";
                                                    let wrapperDiv = document.createElement("div");
                                                    wrapperDiv.setAttribute(
                                                        "id",
                                                        `rich_text_container_${idx}_${id}`
                                                    );
                                                    wrapperDiv.setAttribute(
                                                        "class",
                                                        "rich_text_table_container"
                                                    );

                                                    wrapperDiv.style.backgroundColor = "white";

                                                    td.appendChild(wrapperDiv);

                                                    let quill = new Quill(wrapperDiv, {
                                                        modules: {
                                                            toolbar: updatedProps.richTextOptions.toolbar ? [
                                                                [{
                                                                    header: [1, 2, 3, 4, 5, 6, false]
                                                                }],
                                                                ["bold", "italic", "underline", "strike"],
                                                                [{
                                                                    list: "bullet"
                                                                }, {
                                                                    list: "ordered"
                                                                }],
                                                                ["link"],
                                                                [{
                                                                    align: []
                                                                }],
                                                                [{
                                                                    color: []
                                                                }, {
                                                                    background: []
                                                                }],
                                                            ] : false,
                                                        },
                                                        placeholder: "",
                                                        theme: "snow",
                                                    });

                                                    updatedProps.richTextOptions.readOnly ? quill.enable(false) : quill.enable(true);

                                                    if (content) {
                                                        console.log('contentRich:::::::',content)
                                                        let jsonContent = JSON.parse(content);
                                                        console.log('jsonContent:::::::::',jsonContent)
                                                        console.log('type jsonContent:::::::::',typeof jsonContent)
                                                        if (jsonContent ) {
                                                            console.log('inside jsonContent')
                                                            quill.setContents(jsonContent);
                                                        }
                                                    } else {
                                                        let richTextContent;
                                                        richTextContent = sessionStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );
                                                        if (!richTextContent) richTextContent = localStorage.getItem(`redslingRichTextTable${id}_row_${idx}_column${x}` );

                                                        let parsedData = JSON.parse(richTextContent);
                                                        if (parsedData && parsedData.delta) {
                                                            quill.setContents(parsedData.delta);
                                                        }
                                                            
                                                        
                                                   }

                                                    quill.on("text-change", () => {
                                                        const delta = quill.getContents();

                                                        let deltaRef = {
                                                            "data-db-id": `${
                                                                obj[updatedProps.DataModelObjIdKeyname]
                                                                }`,
                                                            delta:delta
                                                        }
                                                        
                                                        localStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );
                                                        
                                                        sessionStorage.setItem(
                                                            `redslingRichTextTable${id}_row_${idx}_column${x}`,
                                                            JSON.stringify(deltaRef)
                                                        );
                                                    });                                                   
                                                }
                                            });
                                        }

                                        // }
                                        console.log("inputTextContainers:", inputTextContainers);
                                        // }
                                    } catch (e) {
										// statements
										console.log(e);
									}

									tr.append(td);
								}

								if (customTableTbody) {
									customTableTbody.append(tr);
								}								
							});
						}
					}

					// if the response has a nested array-of-objects within the objects of main array-of-objects
					else if (hasArrayOfObjectsWithinArray(apiData)) {
						console.log("###");

						apiData.slice(startIndex, endIndex).forEach((obj, idx) => {
							let tr = document.createElement("tr");
							tr.setAttribute("data-gjs-type", "custom-table-tbody-row");
							tr.setAttribute("class", "custom-table-tbody-row");
							// tr.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_${idx}`)}`);
							tr.setAttribute("id", `custom_table_tbody_row${idx}_table${id}`);
							tr.setAttribute("data-db-id", `${obj[updatedProps.DataModelObjIdKeyname]}`);
							tr.style.width = "100%";
							tr.style.height = "auto";
							if (idx % 2 !== 0) {
								tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
								// hover effect
								tr.addEventListener("mouseover", function () {
									tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
									// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
								});
								tr.addEventListener("mouseleave", function () {
									tr.style.backgroundColor = updatedProps.tBodyOddTrBackgroundColor;
									// tr.style.scrollbarColor = `${updatedProps.tBodyOddTrBackgroundColor} ${updatedProps.tBodyOddTrBackgroundColor}`;
								});
							} else {
								tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
								// hover effect
								tr.addEventListener("mouseover", function () {
									tr.style.backgroundColor = updatedProps.tBodyTrHoverColor;
									// tr.style.scrollbarColor = `${updatedProps.tBodyTrHoverColor} ${updatedProps.tBodyTrHoverColor}`;
								});
								tr.addEventListener("mouseleave", function () {
									tr.style.backgroundColor = updatedProps.tBodyEvenTrBackgroundColor;
									// tr.style.scrollbarColor = `${updatedProps.tBodyEvenTrBackgroundColor} ${updatedProps.tBodyEvenTrBackgroundColor}`;
								});
							}

							// get data and add it as table's column
							for (let i = 0; i < updatedProps.numColsInputValue; i++) {
								let content, nestedContent = [], convertedDate, dateString, targetFormat, numberString, convertedNumber, timezoneString;
								let nestedColumn; // variable to check if that column is going to be nested or not

								// field name and type are for toggle replication
								let fieldName;

								// using updatedProps.HeaderFields because only these are present in the downloading code
								// if (`HeaderField_${i}` in updatedProps.HeaderFields) {
								// 	content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
								// }

								// to get the values of the header keynames entered in header input fields for plotting
								try {
									if (`HeaderField_${i}` in updatedProps) {
										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (updatedProps[`HeaderField_${i}`].includes("-")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*-\s*/g, "-");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split("-");

											const referenceArrayObjName = `${referenceArray[0]}`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object in not undefined, then find the further keyname
											if (isArrayofObjects(obj[referenceArrayObjName])) {
												obj[referenceArrayObjName].forEach((nesObj) => {
													nestedContent.push(nesObj[referenceArrayKeyName]);
												});
												content = nestedContent;
											}
											// content = obj[referenceArrayObjName][referenceArrayKeyName];
										} else {
											// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 0", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else {
												content = obj[`${updatedProps[`HeaderField_${i}`]}`];
											}
										}
										console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
									} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (updatedProps.HeaderFields[`HeaderField_${i}`].includes("-")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*-\s*/g, "-");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split("-");

											const referenceArrayObjName = `${referenceArray[0]}`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object in not undefined, then find the further keyname
											if (isArrayofObjects(obj[referenceArrayObjName])) {
												obj[referenceArrayObjName].forEach((nesObj) => {
													nestedContent.push(nesObj[referenceArrayKeyName]);
												});
												content = nestedContent;
											}
											// content = obj[referenceArrayObjName][referenceArrayKeyName];
										} else {
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 1", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else {
												content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
											}
											// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
										}
										console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
									} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
										// Get the value of the attribute from 'this'
										console.log("customTableContainer", customTableContainer);
										const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (headerFieldAttribute.includes("-")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = headerFieldAttribute.replace(/\s*-\s*/g, "-");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split("-");

											const referenceArrayObjName = `${referenceArray[0]}`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object is not undefined, then find the further keyname
											if (isArrayofObjects(obj[referenceArrayObjName])) {
												obj[referenceArrayObjName].forEach((nesObj) => {
													nestedContent.push(nesObj[referenceArrayKeyName]);
												});
												content = nestedContent;
											}
										} else {
											// content = obj[headerFieldAttribute];
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[headerFieldAttribute];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[headerFieldAttribute];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 2", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
													numberString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
													console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
												content = obj[headerFieldAttribute];
											}
										}
										console.log("headerFieldAttribute", headerFieldAttribute, content);
									}
								} catch (e) {
									// statements
									console.error(e);
								}

								try {
									if (`HeaderField_${i}` in updatedProps) {
										// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
										if (updatedProps[`HeaderField_${i}`].includes(":")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = updatedProps[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split(":");

											const referenceArrayObjName = `${referenceArray[0]}_data`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object in not undefined, then find the further keyname
											if (obj[referenceArrayObjName]) {
												content = obj[referenceArrayObjName][referenceArrayKeyName];
											}
											// content = obj[referenceArrayObjName][referenceArrayKeyName];
										} else {
											// content = obj[`${updatedProps[`HeaderField_${i}`]}`];
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[`${updatedProps[`HeaderField_${i}`]}`];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[`${updatedProps[`HeaderField_${i}`]}`];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 0", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
												numberString = obj[`${updatedProps[`HeaderField_${i}`]}`];
												let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

												convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
												console.log("numberString, targetFormat, convertedNumber 0", numberString, targetFormat, convertedNumber);

												content = convertedNumber;
												console.log("converted content 0", convertedNumber, content);
											} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 0", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 0", convertedNumber, content);
												} else {
												content = obj[`${updatedProps[`HeaderField_${i}`]}`];
											}

											// applying function from session storage on specific column (by index)
											if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
												try {
													// Retrieve the function string from sessionStorage
													const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

													if (storedFuncString) {
														// Recreate the function using the Function constructor
														const recreatedFunction = new Function(`return (${storedFuncString})`)();
														console.log('Function recreated:', recreatedFunction);

														// Use the recreated function
														content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
														console.log('+recreatedFunction content+', content);
													}
												} catch (error) {
													console.error(error);
												}
											}
										}
										console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
										// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
										fieldName = updatedProps[`HeaderField_${i}`];
									} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
										// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
										if (updatedProps.HeaderFields[`HeaderField_${i}`].includes(":")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = updatedProps.HeaderFields[`HeaderField_${i}`].replace(/\s*:\s*/g, ":");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split(":");

											const referenceArrayObjName = `${referenceArray[0]}_data`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object in not undefined, then find the further keyname
											if (obj[referenceArrayObjName]) {
												content = obj[referenceArrayObjName][referenceArrayKeyName];
											}
											// content = obj[referenceArrayObjName][referenceArrayKeyName];
										} else {
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 1", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
												numberString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
												let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

												convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
												console.log("numberString, targetFormat, convertedNumber 1", numberString, targetFormat, convertedNumber);

												content = convertedNumber;
												console.log("converted content 1", convertedNumber, content);
											} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 1", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 1", convertedNumber, content);
												} else {
												content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
											}

											// applying function from session storage on specific column (by index)
											if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
												try {
													// Retrieve the function string from sessionStorage
													const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

													if (storedFuncString) {
														// Recreate the function using the Function constructor
														const recreatedFunction = new Function(`return (${storedFuncString})`)();
														console.log('Function recreated:', recreatedFunction);

														// Use the recreated function
														content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
														console.log('+recreatedFunction content+', content);
													}
												} catch (error) {
													console.error(error);
												}
											}
											// content = obj[`${updatedProps.HeaderFields[`HeaderField_${i}`]}`];
										}
										console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
										// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
										fieldName = updatedProps.HeaderFields[`HeaderField_${i}`];
									} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
										// Get the value of the attribute from 'this'
										console.log("customTableContainer", customTableContainer);
										const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

										// if the HeaderField_i is a REFERENCE INPUT like - Address : Street, then look for 'Street' in 'Address_data'
										if (headerFieldAttribute.includes(":")) {
											// Replace spaces around colon with an empty string
											const modifiedStr = headerFieldAttribute.replace(/\s*:\s*/g, ":");
											// Split the modified string by colon
											const referenceArray = modifiedStr.split(":");

											const referenceArrayObjName = `${referenceArray[0]}_data`;
											const referenceArrayKeyName = referenceArray[1];

											console.log("reference obj", referenceArrayObjName, referenceArrayKeyName);
											console.log(obj[referenceArrayObjName]);
											// if the reference object is not undefined, then find the further keyname
											if (obj[referenceArrayObjName]) {
												content = obj[referenceArrayObjName][referenceArrayKeyName];
											}
										} else {
											// content = obj[headerFieldAttribute];
											// check if this column is in the object updatedProps['ColumnwiseDisplayDateFormats'] and if it is then it is a 'date' column and proceed with date formating
											if (updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`)) {
												// let convertedDate;
												dateString = obj[headerFieldAttribute];
												targetFormat = updatedProps.ColumnwiseDisplayDateFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayDateFormats[`col${i}`] : "";
												switch (targetFormat) {
													case "":
														convertedDate = obj[headerFieldAttribute];
														break;

													case "date_mon_year":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY");
														break;
													case "date_month_year":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY");
														break;
													case "dd/mm/yyyy":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY");
														break;
													case "mm/dd/yyyy":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY");
														break;
													case "dd-mm-yyyy":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY");
														break;

													case "date_mon_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY HH:mm am/pm");
														break;
													case "date_month_year_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY HH:mm am/pm");
														break;
													case "dd/mm/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY HH:mm am/pm");
														break;
													case "mm/dd/yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY HH:mm am/pm");
														break;
													case "dd-mm-yyyy_00_00_am_pm":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY HH:mm am/pm");
														break;

													case "date_mon_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMM YYYY 24:00");
														break;
													case "date_month_year_24_00":
														convertedDate = convertDateFormat(dateString, "DD MMMM YYYY 24:00");
														break;
													case "dd/mm/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD/MM/YYYY 24:00");
														break;
													case "mm/dd/yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "MM/DD/YYYY 24:00");
														break;
													case "dd-mm-yyyy_24_00":
														convertedDate = convertDateFormat(dateString, "DD-MM-YYYY 24:00");
														break;

													default:
														console.error("Invalid target date format");
												}

												content = convertedDate;
												console.log("converted content 2", dateString, content);
											} else if (updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`)) {
												numberString = obj[headerFieldAttribute];
												let targetFormat = updatedProps.ColumnwiseDisplayNumberFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayNumberFormats[`col${i}`] : "";

												convertedNumber = (targetFormat === "") ? numberString : convertNumberFormat(numberString, targetFormat);
												console.log("numberString, targetFormat, convertedNumber 2", numberString, targetFormat, convertedNumber);

												content = convertedNumber;
												console.log("converted content 2", convertedNumber, content);
											} else if (updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`)) {
													timezoneString = obj[headerFieldAttribute];
													let targetFormat = updatedProps.ColumnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${i}`) ? updatedProps.ColumnwiseDisplayTimeZoneFormats[`col${i}`] : "";

													convertedNumber = (targetFormat === "") ? timezoneString : convertToTimeZone(timezoneString, targetFormat);
													console.log("timezoneString, targetFormat, convertedNumber 2", timezoneString, targetFormat, convertedNumber);

													content = convertedNumber;
													console.log("converted content 2", convertedNumber, content);
												} else {
												content = obj[headerFieldAttribute];
											}

											// applying function from session storage on specific column (by index)
											if (updatedProps.applyFunctionsFromStorage.hasOwnProperty(`col${i}`)) {
												try {
													// Retrieve the function string from sessionStorage
													const storedFuncString = sessionStorage.getItem(`${updatedProps.applyFunctionsFromStorage[`col${i}`]}`);

													if (storedFuncString) {
														// Recreate the function using the Function constructor
														const recreatedFunction = new Function(`return (${storedFuncString})`)();
														console.log('Function recreated:', recreatedFunction);

														// Use the recreated function
														content = recreatedFunction(obj[`${updatedProps[`HeaderField_${i}`]}`]);
														console.log('+recreatedFunction content+', content);
													}
												} catch (error) {
													console.error(error);
												}
											}
										}
										console.log("headerFieldAttribute", headerFieldAttribute, content);
										// to get the fieldName of that column to filter out its field type for the toggle elements in the rows
										fieldName = headerFieldAttribute;
									}
								} catch (e) {
									// statements
									console.error(e);
								}

								// to replicate components based on number of rows in nested table - but in an independent column
								// if there is a reserved keyword "nested-column" in the headerField_i then using the structure of 'obj' append stellar buttons and checkboxes those many times in an indepedent column
								try {
									if (`HeaderField_${i}` in updatedProps) {
										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (updatedProps[`HeaderField_${i}`].includes("nested-column")) {
											nestedColumn = true;
										} else {
											nestedColumn = false;
										}
										console.log("updatedProps", `${updatedProps[`HeaderField_${i}`]}`, content);
									} else if (`HeaderField_${i}` in updatedProps.HeaderFields) {
										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (updatedProps.HeaderFields[`HeaderField_${i}`].includes("nested-column")) {
											nestedColumn = true;
										} else {
											nestedColumn = false;
										}
										console.log("updatedProps.HeaderFields", `${updatedProps.HeaderFields[`HeaderField_${i}`]}`, content);
									} else if (customTableContainer.hasAttribute(`data-headerfield_${i}`)) {
										// Get the value of the attribute from 'this'
										console.log("customTableContainer", customTableContainer);
										const headerFieldAttribute = customTableContainer.getAttribute(`data-headerfield_${i}`);

										// if the HeaderField_i is a nested structure like - specifications - engine, then look for 'specifications' in 'engine'
										if (headerFieldAttribute.includes("nested-column")) {
											nestedColumn = true;
										} else {
											nestedColumn = false;
										}
										console.log("headerFieldAttribute", headerFieldAttribute, content);
									}
								} catch (e) {
									// statements
									console.error(e);
								}

								// console.log("content, i", content, i);

								let td = document.createElement("td");
								td.setAttribute("data-gjs-type", "cell");
								// td.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
								td.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
								td.style.padding = "0";

								td.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
								td.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
								td.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
								td.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
								td.style.borderCollapse = "collapse";
								td.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
								td.style.color = `${updatedProps.tBodyTdFontColor}`;
								td.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
								td.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;
								// plot content directly if its not an array
								td.innerHTML = content !== undefined && !Array.isArray(content) ? content : "";

								// if the content of the cell is not an array, still hande attaching of special components in the cells
								if (!Array.isArray(content)) {
									console.log("i here 1", i);
									// for replicating custom-table-row-selection-checkbox-container
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let tableRowSelectionCheckboxContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (tableRowSelectionCheckboxContainers) {
											// if (tableRowSelectionCheckboxContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// }

											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
												let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
												let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

												// to store row info accross page change
												const SESSION_STORAGE_KEY = `selectedMultipleTable${id}RowData`;

												// Change IDs and append cloned dropdowns to the container
												tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
												
												// Retrieve stored data from session storage
									            let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

									            // Check if this checkbox should be checked
									            const existingData = storedData.find(
									                (item) =>
									                    item.rowId === `custom_table_tbody_row${idx}_table${id}` &&
									                    item.cellId === `custom_table_tbody_row_cell_${idx}_table${id}_column${i}` &&
									                    item.inputId === `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}` &&
									                    item.paginationIndex === currentPageIndex
									            );
									            if (existingData) {
									                tableRowSelectionCheckboxClone.checked = true;
									            }
												
												tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
													const enteredValue = event.target.value;
													const isChecked = event.target.checked; // Check if the checkbox is checked

													// to store row info accross page change
													const checkboxData = {
												        "data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
												        tableId: `${id}`,
												        rowId: `custom_table_tbody_row${idx}_table${id}`,
												        cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
												        inputId: `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
												        enteredValue: `${enteredValue}`,
												        paginationIndex: currentPageIndex, // Include the current page index
												    };

												    // Retrieve existing data
												    let storedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) || [];

													if (isChecked) {
														// Checkbox is checked, add object to local storage
														localStorage.setItem(
															`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`,
															JSON.stringify({
																"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																tableId: `${id}`,
																rowId: `custom_table_tbody_row${idx}_table${id}`,
																cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																enteredValue: `${enteredValue}`,
															}),
														);
														// console.log("currentPageIndex", currentPageIndex);

														// If checked, check if the entry already exists
									                    const existingIndex = storedData.findIndex(
									                        (item) =>
									                            item.rowId === checkboxData.rowId &&
									                            item.cellId === checkboxData.cellId &&
									                            item.paginationIndex === checkboxData.paginationIndex &&
									                            item.inputId === checkboxData.inputId
									                    );

									                    if (existingIndex !== -1) {
									                        // Update existing entry
									                        storedData[existingIndex] = checkboxData;
									                    } else {
									                        // Add new entry
									                        storedData.push(checkboxData);
									                    }
													} else {
														// Checkbox is unchecked, remove object from local storage
														localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
														
														// If unchecked, remove the entry
									                    storedData = storedData.filter(
									                        (item) =>
									                            !(item.rowId === checkboxData.rowId &&
									                              item.cellId === checkboxData.cellId &&
									                              item.paginationIndex === checkboxData.paginationIndex &&
									                              item.inputId === checkboxData.inputId)
									                    );
													}

													// You can also perform other actions based on the checkbox state
													// For example, update UI, make API calls, etc.
													
													// Update session storage with the modified array
													sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storedData));
												});

												// Append the dropdown to the table cell
												td.appendChild(tableRowSelectionCheckboxClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
												tableRowSelectionCheckboxContainer.style.display = "none";
											});
										}
										// }
										console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_date_time field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let dateTimeInputContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (dateTimeInputContainers) {
											// if (dateTimeInputContainers.length > 0) {
											// 	td.style.height = 'auto';
											// 	td.style.overflow = 'scroll';
											// 	td.style.scrollbarColor = `transparent transparent`;
											// }

											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
												let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
												let dateTimeInputClone = dateTimeInput.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(dateTimeInput, dateTimeInputClone);

												// Change IDs and append cloned dropdowns to the container
												dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_${j}`);
												dateTimeInputClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_date_time_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(dateTimeInputClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
												dateTimeInputContainer.style.display = "none";
											});
										}
										// }
										console.log("dateTimeInputContainers:", dateTimeInputContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_text_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i here 2", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputTextContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputTextContainers) {
											if (inputTextContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
												let inputText = inputTextContainer.getElementsByTagName("input")[0];
												let inputTextClone = inputText.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputText, inputTextClone);

												// Change IDs and append cloned dropdowns to the container
												inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_${j}`);
												inputTextClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_text_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputTextClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputTextContainers).forEach((inputTextContainer) => {
												inputTextContainer.style.display = "none";
											});
										}
										// }
										console.log("inputTextContainers:", inputTextContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}

									// for replicating form_number_field
									try {
										// if (i === Number(table_selected_column)) {
										console.log("i", i);
										// run this everytime api call is done due to user click event
										// find stellar-button component in header column
										let inputNumberContainers;
										// if (table_selected_column) {
										console.log("customTableThead", customTableThead);

										// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
										// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

										console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

										inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
										console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

										if (inputNumberContainers) {
											if (inputNumberContainers.length > 0) {
												td.style.height = "auto";
												td.style.overflow = "scroll";
												td.style.scrollbarColor = `transparent transparent`;
											}

											Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
												let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
												let inputNumberClone = inputNumber.cloneNode(true);
												// Copy styles from the original to the cloned dropdown
												copyStyles1(inputNumber, inputNumberClone);

												// Change IDs and append cloned dropdowns to the container
												inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_${j}`);
												inputNumberClone.addEventListener("change", (event) => {
													// Get the selected option's value and id
													const enteredValue = event.target.value;

													// Store the selected value, id, and other relevant data in local storage
													localStorage.setItem(
														`clicked_form_number_field_table${id}_column${i}_${j}`,
														JSON.stringify({
															"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
															tableId: `${id}`,
															rowId: `custom_table_tbody_row${idx}_table${id}`,
															cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															enteredValue: `${enteredValue}`,
														}),
													);

													// You can also perform other actions based on the selected dropdown option
													// For example, update UI, make API calls, etc.
												});

												// Append the dropdown to the table cell
												td.appendChild(inputNumberClone);
											});

											// Hide the original dropdowns in the header column
											Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
												inputNumberContainer.style.display = "none";
											});
										}
										// }
										console.log("inputNumberContainers:", inputNumberContainers);
										// }
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if content is an array, then create a table with nested rows and also attach special components in the nested cells of the nested rows
								if (Array.isArray(content)) {
									console.log("i here 3", i);
									let nestedTable = document.createElement("table");

									nestedTable.style.width = "none"; // Override width
									nestedTable.style.marginTop = "0"; // Override margin-top
									nestedTable.style.marginLeft = "0"; // Override margin-left

									content.forEach((row, row_idx) => {
										let nestedTr = document.createElement("tr");

										let nestedTd = document.createElement("td");
										nestedTd.setAttribute("data-gjs-type", "cell");
										// nestedTd.setAttribute('id', `${generateRandomId(`custom_table_tbody_row_cell_${idx}`)}`);
										nestedTd.setAttribute("id", `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`);
										nestedTd.style.padding = "8px";

										nestedTd.style.borderTop = `${updatedProps.tBodyTrBorderTopWidth}px ${updatedProps.tBodyTrBorderTopType} ${updatedProps.tBodyTrBorderTopColor}`;
										nestedTd.style.borderRight = `${updatedProps.tBodyTrBorderRightWidth}px ${updatedProps.tBodyTrBorderRightType} ${updatedProps.tBodyTrBorderRightColor}`;
										nestedTd.style.borderBottom = `${updatedProps.tBodyTrBorderBottomWidth}px ${updatedProps.tBodyTrBorderBottomType} ${updatedProps.tBodyTrBorderBottomColor}`;
										nestedTd.style.borderLeft = `${updatedProps.tBodyTrBorderLeftWidth}px ${updatedProps.tBodyTrBorderLeftType} ${updatedProps.tBodyTrBorderLeftColor}`;
										nestedTd.style.borderCollapse = "collapse";
										nestedTd.style.fontSize = `${updatedProps.tBodyTdFontSize}`;
										nestedTd.style.color = `${updatedProps.tBodyTdFontColor}`;
										nestedTd.style.fontFamily = `${updatedProps.tBodyTdFontFamily}`;
										nestedTd.style.textAlign = `${updatedProps.tBodyTdTextAlign}`;

										nestedTd.innerHTML = `${row}`;

										// // getting the nested table data
										// let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
										// // if that table name's key exists in obj and if it is not empty
										// if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {}

										// for replicating custom-table-row-selection-checkbox-container (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let tableRowSelectionCheckboxContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											tableRowSelectionCheckboxContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("custom-table-row-selection-checkbox-container");
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (tableRowSelectionCheckboxContainers) {
												// if (tableRowSelectionCheckboxContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// }

												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer, j) => {
													let tableRowSelectionCheckbox = tableRowSelectionCheckboxContainer.getElementsByTagName("input")[0];
													let tableRowSelectionCheckboxClone = tableRowSelectionCheckbox.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(tableRowSelectionCheckbox, tableRowSelectionCheckboxClone);

													// Change IDs and append cloned dropdowns to the container
													tableRowSelectionCheckboxClone.setAttribute("id", `custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
													tableRowSelectionCheckboxClone.addEventListener("change", (event) => {
														const enteredValue = event.target.value;
														const isChecked = event.target.checked; // Check if the checkbox is checked

														if (isChecked) {
															// Checkbox is checked, add object to local storage

															// getting the nested table data
															let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
															// if that table name's key exists in obj and if it is not empty
															if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
																localStorage.setItem(
																	`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															} else {
																localStorage.setItem(
																	`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																	JSON.stringify({
																		"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																		tableId: `${id}`,
																		rowId: `custom_table_tbody_row${idx}_table${id}`,
																		nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																		cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																		enteredValue: `${enteredValue}`,
																	}),
																);
															}

															// localStorage.setItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`, JSON.stringify({
															//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
															//     tableId: `${id}`,
															//     rowId: `custom_table_tbody_row${idx}_table${id}`,
															//     nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
															//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
															//     enteredValue: `${enteredValue}`,
															// }));
														} else {
															// Checkbox is unchecked, remove object from local storage
															localStorage.removeItem(`clicked_custom_table_row_selection_checkbox_table${id}_row${idx}_column${i}_${j}`);
														}

														// You can also perform other actions based on the checkbox state
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													nestedTd.appendChild(tableRowSelectionCheckboxClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(tableRowSelectionCheckboxContainers).forEach((tableRowSelectionCheckboxContainer) => {
													tableRowSelectionCheckboxContainer.style.display = "none";
												});
											}
											// }
											console.log("tableRowSelectionCheckboxContainers:", tableRowSelectionCheckboxContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_date_time field (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let dateTimeInputContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											dateTimeInputContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="dateTimeInputNew"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (dateTimeInputContainers) {
												// if (dateTimeInputContainers.length > 0) {
												// 	td.style.height = 'auto';
												// 	td.style.overflow = 'scroll';
												// 	td.style.scrollbarColor = `transparent transparent`;
												// }

												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer, j) => {
													let dateTimeInput = dateTimeInputContainer.getElementsByTagName("input")[0];
													let dateTimeInputClone = dateTimeInput.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(dateTimeInput, dateTimeInputClone);

													// Change IDs and append cloned dropdowns to the container
													dateTimeInputClone.setAttribute("id", `form_date_time_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
													dateTimeInputClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														// getting the nested table data
														let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
														// if that table name's key exists in obj and if it is not empty
														if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
															localStorage.setItem(
																`clicked_form_date_time_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														} else {
															localStorage.setItem(
																`clicked_form_date_time_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														}

														// localStorage.setItem(`clicked_form_date_time_field_table${id}_column${i}_nestedtable_row_${j}`, JSON.stringify({
														//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
														//     tableId: `${id}`,
														//     rowId: `custom_table_tbody_row${idx}_table${id}`,
														//     nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
														//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
														//     enteredValue: `${enteredValue}`,
														// }));

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													nestedTd.appendChild(dateTimeInputClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(dateTimeInputContainers).forEach((dateTimeInputContainer) => {
													dateTimeInputContainer.style.display = "none";
												});
											}
											// }
											console.log("dateTimeInputContainers:", dateTimeInputContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_text_field (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i here 4", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputTextContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputTextContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_text_field"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (i === 8) {
												console.log("inputTextContainers i here:", inputTextContainers);
											}

											if (inputTextContainers) {
												if (inputTextContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputTextContainers).forEach((inputTextContainer, j) => {
													let inputText = inputTextContainer.getElementsByTagName("input")[0];
													let inputTextClone = inputText.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputText, inputTextClone);

													// Change IDs and append cloned dropdowns to the container
													inputTextClone.setAttribute("id", `form_text_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
													inputTextClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														// getting the nested table data
														let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
														// if that table name's key exists in obj and if it is not empty
														if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
															localStorage.setItem(
																`clicked_form_text_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														} else {
															localStorage.setItem(
																`clicked_form_text_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														}

														// localStorage.setItem(`clicked_form_text_field_table${id}_column${i}_${j}`, JSON.stringify({
														//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
														//     tableId: `${id}`,
														//     rowId: `custom_table_tbody_row${idx}_table${id}`,
														//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
														//     enteredValue: `${enteredValue}`,
														// }));

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													nestedTd.appendChild(inputTextClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputTextContainers).forEach((inputTextContainer) => {
													inputTextContainer.style.display = "none";
												});
											}
											// }
											console.log("inputTextContainers:", inputTextContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										// for replicating form_number_field (STORE ALL THE LOGS, ROW WISE AN COLUMN WISE - bulk edit/delete)
										try {
											// if (i === Number(table_selected_column)) {
											console.log("i", i);
											// run this everytime api call is done due to user click event
											// find stellar-button component in header column
											let inputNumberContainers;
											// if (table_selected_column) {
											console.log("customTableThead", customTableThead);

											// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
											// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

											console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

											inputNumberContainers = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].querySelectorAll('[data-gjs-type="form_number_field"]');
											console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

											if (inputNumberContainers) {
												if (inputNumberContainers.length > 0) {
													td.style.height = "auto";
													td.style.overflow = "scroll";
													td.style.scrollbarColor = `transparent transparent`;
												}

												Array.from(inputNumberContainers).forEach((inputNumberContainer, j) => {
													let inputNumber = inputNumberContainer.getElementsByTagName("input")[0];
													let inputNumberClone = inputNumber.cloneNode(true);
													// Copy styles from the original to the cloned dropdown
													copyStyles1(inputNumber, inputNumberClone);

													// Change IDs and append cloned dropdowns to the container
													inputNumberClone.setAttribute("id", `form_number_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`);
													inputNumberClone.addEventListener("change", (event) => {
														// Get the selected option's value and id
														const enteredValue = event.target.value;

														// Store the selected value, id, and other relevant data in local storage
														// getting the nested table data
														let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
														// if that table name's key exists in obj and if it is not empty
														if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
															localStorage.setItem(
																`clicked_form_number_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	"nested-data-db-id": `${obj[extractTableName(tableApi)][row_idx][updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														} else {
															localStorage.setItem(
																`clicked_form_number_field_table${id}_row${idx}_column${i}_nestedtable_row${row_idx}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${row_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	enteredValue: `${enteredValue}`,
																}),
															);
														}

														// localStorage.setItem(`clicked_form_number_field_table${id}_column${i}_${j}`, JSON.stringify({
														//     'data-db-id': `${obj[updatedProps.DataModelObjIdKeyname]}`,
														//     tableId: `${id}`,
														//     rowId: `custom_table_tbody_row${idx}_table${id}`,
														//     cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
														//     enteredValue: `${enteredValue}`,
														// }));

														// You can also perform other actions based on the selected dropdown option
														// For example, update UI, make API calls, etc.
													});

													// Append the dropdown to the table cell
													nestedTd.appendChild(inputNumberClone);
												});

												// Hide the original dropdowns in the header column
												Array.from(inputNumberContainers).forEach((inputNumberContainer) => {
													inputNumberContainer.style.display = "none";
												});
											}
											// }
											console.log("inputNumberContainers:", inputNumberContainers);
											// }
										} catch (e) {
											// statements
											console.log(e);
										}

										nestedTr.appendChild(nestedTd);
										nestedTable.appendChild(nestedTr);
									});

									td.appendChild(nestedTable);
								}

								console.log("obj ++", obj);
								// if the column is nested then add as many amounts of stellar button in each td cell as there are objects
								if (nestedColumn) {
									console.log("adding nested stellar buttons");

									// find the nested table data in the obj and then loop over its elements which will create as many stellar buttons as required
									let tableApi = updatedProps.customUrlValue ? updatedProps.customUrlValue : bu;
									// if that table name's key exists in obj and if it is not empty
									if (extractTableName(tableApi) in obj && obj[extractTableName(tableApi)].length > 0) {
										// loop over each object in the array-of-objects in obj so that you can add those many exact number of stellar buttons in the cells and also get the _id reference
										obj[extractTableName(tableApi)].forEach((nestedObj, nestedObj_idx) => {
											// for replicating stellar buttons
											try {
												// if (i === Number(table_selected_column)) {
												console.log("i", i);
												// run this everytime api call is done due to user click event
												// find stellar-button component in header column
												let headerStellarButtons;
												// if (table_selected_column) {
												console.log("customTableThead", customTableThead);

												// let customTableTheadTrThs = customTableThead.getElementsByTagName('tr')[0].querySelectorAll('th[data-gjs-type="custom-table-thead-row"]');
												// console.log('customTableTheadTrThs', customTableTheadTrThs, customTableTheadTrThs.length);

												console.log("thead th i", customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i]);

												headerStellarButtons = customTableThead.getElementsByTagName("tr")[0].querySelectorAll("th")[i].getElementsByClassName("stellar-button-container");
												console.log("tr > th of", i, customTableThead.getElementsByTagName("tr")[0].getElementsByTagName("th")[i]);

												if (headerStellarButtons) {
													if (headerStellarButtons.length > 0) {
														td.style.height = "auto";
														td.style.overflow = "scroll";
														td.style.scrollbarColor = `transparent transparent`;
													}

													for (let j = 0; j < headerStellarButtons.length; j++) {
														// row.getElementsByTagName('td')[table_selected_column].append(headerStellarButtons[j]);
														let clonedStellarButton = headerStellarButtons[j].cloneNode(true);

														// Copy the styles from the original button to the cloned button and its inner elements
														copyStyles(headerStellarButtons[j], clonedStellarButton);

														// changing the clone's IDs so that they are present and available individually in LB and in download
														clonedStellarButton.setAttribute("id", `stellar_button_container_table${id}_row${idx}_column${i}_${j}`);
														clonedStellarButton.getElementsByClassName("stellar-btn")[0].setAttribute("id", `stellar_btn_table${id}_row${idx}_column${i}_${j}`);
														clonedStellarButton.getElementsByClassName("stellar-btn")[0].getElementsByTagName("p")[0].setAttribute("id", `stellar_btn_p_table${id}_row${idx}_column${i}_${j}`);

														td.appendChild(clonedStellarButton);

														// adding click event on stellar-button from the table components
														clonedStellarButton.addEventListener("click", function (event) {
															console.log("stellar-button clicked", "saving data in local storage");
															// localStorage.setItem(`stellar_btn_table${id}_row${idx}_column${i}_${j}`, JSON.stringify({

															// this registers all stellar button clicks on LS
															// localStorage.setItem(`stellar_button_container_table${id}_row${idx}_column${i}_${j}_clicked`, JSON.stringify({
															// this registers only by the individuals in the column header
															localStorage.setItem(
																`clicked_stellar_button_container_table${id}_column${i}_${j}`,
																JSON.stringify({
																	"data-db-id": `${obj[updatedProps.DataModelObjIdKeyname]}`,
																	// 'nested-data-db-id': `${obj[extractTableName(tableApi)][nestedObj_idx][updatedProps.DataModelObjIdKeyname]}`,
																	"nested-data-db-id": `${nestedObj[updatedProps.DataModelObjIdKeyname]}`,
																	tableId: `${id}`,
																	rowId: `custom_table_tbody_row${idx}_table${id}`,
																	nestedRowId: `custom_table_tbody_nestedrow${nestedObj_idx}_table${id}`,
																	cellId: `custom_table_tbody_row_cell_${idx}_table${id}_column${i}`,
																	buttonId: `${clonedStellarButton.getAttribute("id")}`,
																}),
															);

															// highlight the table row that the stellar button has selected
															if (updatedProps.tBodyTrSelectColorHighlight)
																clonedStellarButton.parentElement.parentElement.style.backgroundColor = "#D3D3D3";
		
															customTable.onclick = function(event) {														
																// Check if the click is inside the custom table but outside the clicked row
																// if (
																// 	customTable.contains(event.target) && // Click is inside the customTable
																// 	!clonedStellarButton.parentElement.parentElement.contains(event.target) // Click is not in the current row
																// ) {
																// 	console.log('@deselecting row', idx);
																// 	// Determine original color based on row index (even/odd)
																// 	const originalColor = idx % 2 === 0
																// 		? updatedProps.tBodyEvenTrBackgroundColor
																// 		: updatedProps.tBodyOddTrBackgroundColor;
		
																// 	// Reset the row's background color
																// 	clonedStellarButton.parentElement.parentElement.style.backgroundColor = originalColor;
																// }
		
																// Iterate over all rows in the table
																const rows = customTable.querySelectorAll("tr");
																rows.forEach((row, rowIndex) => {
																	// Skip the currently selected row
																	if (row.contains(clonedStellarButton.parentElement.parentElement)) {
																		return;
																	}
		
																	// Reset the background color based on even/odd index
																	const originalColor = rowIndex % 2 === 0
																		? updatedProps.tBodyEvenTrBackgroundColor
																		: updatedProps.tBodyOddTrBackgroundColor;
		
																	row.style.backgroundColor = originalColor;
																});
															};
														});

														// to hide the header buttons after they have been cloned
														headerStellarButtons[j].style.display = "none";
													}
												}
												// }
												console.log("headerStellarButtons:", headerStellarButtons);
												// }
											} catch (e) {
												// statements
												console.log(e);
											}
										});
									}
								}

								tr.append(td);
							}

							if (customTableTbody) {
								customTableTbody.append(tr);
							}							
						});
					}
				}

				const updateTotalRowsResultsInTableFooter = async function ($, props) {
					let paginationRequired = props.paginationRequired;
					let TotalRows = props.TotalRow;
					let showDBSizeInTableFooter = props.showDBSizeInTableFooter;
					let useTablePlottingApiForPagination = props.useTablePlottingApiForPagination;
					let numRowsPerPageValue = props.numRowsPerPageValue;
					let numColsInputValue = props.numColsInputValue;

					let totalRowsAPIValue = props.totalRowsAPIValue;
					let totalRowsAPIBaseUrlValue = props.totalRowsAPIBaseUrlValue;
					let totalRowsAPIPathValue = props.totalRowsAPIPathValue;
					let totalRowsAPIKeynameValue = props.totalRowsAPIKeynameValue;
					let totalRowsAPIMethodValue = props.totalRowsAPIMethodValue;
					let totalRowsAPIUsernameValue = props.totalRowsAPIUsernameValue;
					let totalRowsAPIPasswordValue = props.totalRowsAPIPasswordValue;
					let totalRowsAPIBearerTokenValue = props.totalRowsAPIBearerTokenValue;
					let totalRowsAPIMoreHeadersValue = props.totalRowsAPIMoreHeadersValue;
					let totalRowsAPIBodyValue = props.totalRowsAPIBodyValue;
					let totalRowsAPINestedArrOfObjKeynameValue = props.totalRowsAPINestedArrOfObjKeynameValue;

					let customUrlValue = props.customUrlValue;
					let baseUrlValue = props.baseUrlValue;
					let apiPathValue = props.apiPathValue;
					let apiMethodValue = props.apiMethodValue;
					let apiUsernameValue = props.apiUsernameValue;
					let apiPasswordValue = props.apiPasswordValue;
					let apiBearerTokenValue = props.apiBearerTokenValue;
					let apiMoreHeadersValue = props.apiMoreHeadersValue;
					let apiBodyValue = props.apiBodyValue;
					let nestedArrOfObjKeynameValue = props.nestedArrOfObjKeynameValue;
					// wrapping these values in updatedProps named object because the if cases below are extracting value from updatedProps - so this was a shortcut
					let updatedProps = {
						customUrlValue,
						baseUrlValue,
						apiPathValue,
						apiMethodValue,
						apiUsernameValue,
						apiPasswordValue,
						apiBearerTokenValue,
						apiMoreHeadersValue,
						apiBodyValue,
						nestedArrOfObjKeynameValue,
					};

					console.log("++ paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue", paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue);
					console.log("++ customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue", customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue);

					// base url bearer token taken directly from local storage after getting downloaded
					let baseUrlData, dynamicBaseUrlBearerToken, baseUrl1BearerToken, baseUrl2BearerToken, baseUrl3BearerToken, baseUrl4BearerToken, baseUrl5BearerToken;
					// getting baseUrl data from local storage for run-time access
					let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
					if (baseUrlDataFromStorage) {
						baseUrlData = JSON.parse(baseUrlDataFromStorage);
					}
					// extracting token values from base URLs from baseUrlData
					if (baseUrlData) {
						try {
							if (`baseUrl1_${props.projectId}` in baseUrlData) {
								baseUrl1BearerToken = baseUrlData[`baseUrl1_${props.projectId}`].token;
							}
							if (`baseUrl2_${props.projectId}` in baseUrlData) {
								baseUrl2BearerToken = baseUrlData[`baseUrl2_${props.projectId}`].token;
							}
							if (`baseUrl3_${props.projectId}` in baseUrlData) {
								baseUrl3BearerToken = baseUrlData[`baseUrl3_${props.projectId}`].token;
							}
							if (`baseUrl4_${props.projectId}` in baseUrlData) {
								baseUrl4BearerToken = baseUrlData[`baseUrl4_${props.projectId}`].token;
							}
							if (`baseUrl5_${props.projectId}` in baseUrlData) {
								baseUrl5BearerToken = baseUrlData[`baseUrl5_${props.projectId}`].token;
							}
						} catch (e) {
							// statements
							console.log(e);
						}
					}

					// helper function to check if a variable is an array-of-objects or not
					function isArrayofObjects(variable) {
						// Check if the variable is an array
						if (!Array.isArray(variable)) {
							return false;
						}

						// Check if all elements in the array are objects
						for (const element of variable) {
							if (typeof element !== "object" || Array.isArray(element) || element === null) {
								return false;
							}
						}
						return true;
					}

					// helper function to get value of a key in nested object
					function findKeyValue(obj, key) {
						if (obj.hasOwnProperty(key)) {
							// If the current object has the key, return its value
							return obj[key];
						}

						for (var prop in obj) {
							if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
								// If the current property is an object, recursively search within it
								var result = findKeyValue(obj[prop], key);
								if (result !== undefined) {
									return result;
								}
							}
						}
						// Key not found in the object
						return undefined;
					}

					// helper function to check if there are any nested array-of-objects in the objects of the main array-of-objects
					function hasArrayOfObjectsWithinArray(dataStructure) {
						if (!Array.isArray(dataStructure)) {
							return false; // Not an array
						}

						for (const obj of dataStructure) {
							if (typeof obj === "object" && obj !== null) {
								for (const value of Object.values(obj)) {
									if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object" && value[0] !== null) {
										return true; // Found an array of objects with nested array
									}
								}
							}
						}

						return false; // Not found
					}

					// make an api call to get the TotalRows
					let totalRows;
					let totalRowsVal;
					let apiData;

					// use total-rows-api if the user has this api, else use the table-plotting api
					if (!useTablePlottingApiForPagination) {
						console.log('!useTablePlottingApiForPagination');
						// if only totalRowsAPIValue is provided and its a GET call
						if (totalRowsAPIValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only totalRowsAPIBaseUrlValue is provided and its a GET call
						else if (!totalRowsAPIValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
							// concatenating base url and its remaining part
							let url, dynamicBaseUrlBearerToken;
							let forDownloadUrl;

							if (totalRowsAPIBaseUrlValue === "baseUrl1") {
								url = props.options.baseUrl1 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL1}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl1BearerToken;
								dynamicBaseUrlBearerToken = baseUrl1BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
								url = props.options.baseUrl2 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL2}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl2BearerToken;
								dynamicBaseUrlBearerToken = baseUrl2BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
								url = props.options.baseUrl3 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL3}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl3BearerToken;
								dynamicBaseUrlBearerToken = baseUrl3BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
								url = props.options.baseUrl4 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL4}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl4BearerToken;
								dynamicBaseUrlBearerToken = baseUrl4BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
								url = props.options.baseUrl5 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL5}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl5BearerToken;
								dynamicBaseUrlBearerToken = baseUrl5BearerToken;
							}

							const urlDownload1 = window.top.location.href;
							const isSubstringPresent1 = (urlDownload1.indexOf("/editor/") !== -1 && urlDownload1.indexOf("?projectId=") !== -1) || urlDownload1.indexOf("/large_preview/") !== -1 || urlDownload1.indexOf("/tab_preview/") !== -1 || urlDownload1.indexOf("/mobile_preview/") !== -1 || urlDownload1.indexOf("/fragment_editor/") !== -1;
	       			 		if (!isSubstringPresent1) {
	       			 			// running in download
	       			 			url = forDownloadUrl;
	       			 		}

							console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only totalRowsAPIValue is provided and its a POST call
						else if (totalRowsAPIValue && totalRowsAPIBodyValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only totalRowsAPIBaseUrlValue is provided and its a POST call
						else if (!totalRowsAPIValue && totalRowsAPIBodyValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
							// concatenating base url and its remaining part
							let url, dynamicBaseUrlBearerToken;
							let forDownloadUrl;

							if (totalRowsAPIBaseUrlValue === "baseUrl1") {
								url = props.options.baseUrl1 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL1}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl1BearerToken;
								dynamicBaseUrlBearerToken = baseUrl1BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
								url = props.options.baseUrl2 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL2}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl2BearerToken;
								dynamicBaseUrlBearerToken = baseUrl2BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
								url = props.options.baseUrl3 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL3}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl3BearerToken;
								dynamicBaseUrlBearerToken = baseUrl3BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
								url = props.options.baseUrl4 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL4}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl4BearerToken;
								dynamicBaseUrlBearerToken = baseUrl4BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
								url = props.options.baseUrl5 + totalRowsAPIPathValue;
								forDownloadUrl = "{{API_URL5}}" + totalRowsAPIPathValue;
								// baseUrlBearerToken = props.options.baseUrl5BearerToken;
								dynamicBaseUrlBearerToken = baseUrl5BearerToken;
							}

							const urlDownload2 = window.top.location.href;
							const isSubstringPresent2 = (urlDownload2.indexOf("/editor/") !== -1 && urlDownload2.indexOf("?projectId=") !== -1) || urlDownload2.indexOf("/large_preview/") !== -1 || urlDownload2.indexOf("/tab_preview/") !== -1 || urlDownload2.indexOf("/mobile_preview/") !== -1 || urlDownload2.indexOf("/fragment_editor/") !== -1;
	       			 		if (!isSubstringPresent2) {
	       			 			// running in download
	       			 			url = forDownloadUrl;
	       			 		}
	       			 		
							console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}
					} else {
						console.log('useTablePlottingApiForPagination');
						// if only custom url is provided and its a GET call
						if (props.customUrlValue && (!props.baseUrlValue || props.baseUrlValue === "null") && !props.apiPathValue && props.apiMethodValue === "GET") {
							// if nothing is provided, for authentication
							if (!props.apiUsernameValue && !props.apiPasswordValue && !props.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${props.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!props.apiUsernameValue && !props.apiPasswordValue && props.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${props.apiBearerTokenValue}`,
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${props.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (props.apiUsernameValue && props.apiPasswordValue && !props.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(props.apiUsernameValue + ":" + props.apiPasswordValue),
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${props.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only base url is provided and its a GET call
						else if (!props.customUrlValue && (props.baseUrlValue || !props.baseUrlValue === "null") && props.apiPathValue && props.apiMethodValue === "GET") {
							// concatenating base url and its remaining part
							let url, dynamicBaseUrlBearerToken;
							let forDownloadUrl;

							if (props.baseUrlValue === "baseUrl1") {
								url = props.options.baseUrl1 + props.apiPathValue;
								forDownloadUrl = "{{API_URL1}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl1BearerToken;
								dynamicBaseUrlBearerToken = baseUrl1BearerToken;
							} else if (props.baseUrlValue === "baseUrl2") {
								url = props.options.baseUrl2 + props.apiPathValue;
								forDownloadUrl = "{{API_URL2}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl2BearerToken;
								dynamicBaseUrlBearerToken = baseUrl2BearerToken;
							} else if (props.baseUrlValue === "baseUrl3") {
								url = props.options.baseUrl3 + props.apiPathValue;
								forDownloadUrl = "{{API_URL3}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl3BearerToken;
								dynamicBaseUrlBearerToken = baseUrl3BearerToken;
							} else if (props.baseUrlValue === "baseUrl4") {
								url = props.options.baseUrl4 + props.apiPathValue;
								forDownloadUrl = "{{API_URL4}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl4BearerToken;
								dynamicBaseUrlBearerToken = baseUrl4BearerToken;
							} else if (props.baseUrlValue === "baseUrl5") {
								url = props.options.baseUrl5 + props.apiPathValue;
								forDownloadUrl = "{{API_URL5}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl5BearerToken;
								dynamicBaseUrlBearerToken = baseUrl5BearerToken;
							}

							const urlDownload3 = window.top.location.href;
							const isSubstringPresent3 = (urlDownload3.indexOf("/editor/") !== -1 && urlDownload3.indexOf("?projectId=") !== -1) || urlDownload3.indexOf("/large_preview/") !== -1 || urlDownload3.indexOf("/tab_preview/") !== -1 || urlDownload3.indexOf("/mobile_preview/") !== -1 || urlDownload3.indexOf("/fragment_editor/") !== -1;
	       			 		if (!isSubstringPresent3) {
	       			 			// running in download
	       			 			url = forDownloadUrl;
	       			 		}

							console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!props.apiUsernameValue && !props.apiPasswordValue && !props.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!props.apiUsernameValue && !props.apiPasswordValue && !props.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (props.apiUsernameValue && props.apiPasswordValue && !props.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(props.apiUsernameValue + ":" + props.apiPasswordValue),
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only custom url is provided and its a POST call
						else if (props.customUrlValue && props.apiBodyValue && (!props.baseUrlValue || props.baseUrlValue === "null") && !props.apiPathValue && props.apiMethodValue === "POST") {
							// if nothing is provided, for authentication
							if (!props.apiUsernameValue && !props.apiPasswordValue && !props.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: props.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${props.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!props.apiUsernameValue && !props.apiPasswordValue && props.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: props.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${props.apiBearerTokenValue}`,
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${props.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (props.apiUsernameValue && props.apiPasswordValue && !props.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: props.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(props.apiUsernameValue + ":" + props.apiPasswordValue),
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${props.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only base url is provided and its a POST call
						else if (!props.customUrlValue && props.apiBodyValue && (props.baseUrlValue || !props.baseUrlValue === "null") && props.apiPathValue && props.apiMethodValue === "POST") {
							// concatenating base url and its remaining part
							let url, dynamicBaseUrlBearerToken;
							let forDownloadUrl;

							if (props.baseUrlValue === "baseUrl1") {
								url = props.options.baseUrl1 + props.apiPathValue;
								forDownloadUrl = "{{API_URL1}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl1BearerToken;
								dynamicBaseUrlBearerToken = baseUrl1BearerToken;
							} else if (props.baseUrlValue === "baseUrl2") {
								url = props.options.baseUrl2 + props.apiPathValue;
								forDownloadUrl = "{{API_URL2}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl2BearerToken;
								dynamicBaseUrlBearerToken = baseUrl2BearerToken;
							} else if (props.baseUrlValue === "baseUrl3") {
								url = props.options.baseUrl3 + props.apiPathValue;
								forDownloadUrl = "{{API_URL3}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl3BearerToken;
								dynamicBaseUrlBearerToken = baseUrl3BearerToken;
							} else if (props.baseUrlValue === "baseUrl4") {
								url = props.options.baseUrl4 + props.apiPathValue;
								forDownloadUrl = "{{API_URL4}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl4BearerToken;
								dynamicBaseUrlBearerToken = baseUrl4BearerToken;
							} else if (props.baseUrlValue === "baseUrl5") {
								url = props.options.baseUrl5 + props.apiPathValue;
								forDownloadUrl = "{{API_URL5}}" + props.apiPathValue;
								// baseUrlBearerToken = props.options.baseUrl5BearerToken;
								dynamicBaseUrlBearerToken = baseUrl5BearerToken;
							}

							const urlDownload4 = window.top.location.href;
							const isSubstringPresent4 = (urlDownload4.indexOf("/editor/") !== -1 && urlDownload4.indexOf("?projectId=") !== -1) || urlDownload4.indexOf("/large_preview/") !== -1 || urlDownload4.indexOf("/tab_preview/") !== -1 || urlDownload4.indexOf("/mobile_preview/") !== -1 || urlDownload4.indexOf("/fragment_editor/") !== -1;
	       			 		if (!isSubstringPresent4) {
	       			 			// running in download
	       			 			url = forDownloadUrl;
	       			 		}

							console.log("url, dynamicBaseUrlBearerToken", url, dynamicBaseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!props.apiUsernameValue && !props.apiPasswordValue && !props.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: props.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!props.apiUsernameValue && !props.apiPasswordValue && !props.apiBearerTokenValue && dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: props.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (props.apiUsernameValue && props.apiPasswordValue && !props.apiBearerTokenValue && !dynamicBaseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: props.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(props.apiUsernameValue + ":" + props.apiPasswordValue),
									},
								};

								if (props.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									props.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}
					}

					console.log("++ totalRows:", totalRows);

					try {
						if (typeof totalRows === "object" && !Array.isArray(totalRows) && !apiData) {
							totalRowsVal = findKeyValue(totalRows, totalRowsAPIKeynameValue);
						} else if (!totalRows && apiData) {
							// take the length of the response, if the plotting api is provided and not the total-rows api
							totalRowsVal = apiData.length;
						}
					} catch (e) {
						// statements
						console.log(e);
					}

					console.log("++ totalRowsVal:", totalRowsVal);

					// updating pagination
					if (paginationRequired && !isSubstringPresent) {
						let customTablePagination = customTableTfoot.getElementsByClassName("custom-table-tfoot-row-for-pagination")[0].getElementsByTagName("td")[0].getElementsByClassName("custom-table-pagination")[0];
						let customTablePaginationContent;

						customTablePaginationContent = `
							<li class="uk-pagination-previous" style="list-style: none;"><a href="">Previous <</a></li>
							<ul class="uk-pagination uk-pagination-left" data-uk-pagination="{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}}"></ul>
							<li class="uk-pagination-next" style="list-style: none;"><a href="">> Next</a></li>
						`;

						if (customTablePagination) {
							customTablePagination.innerHTML = customTablePaginationContent;
						}
						
						console.log("pagination updated");
					}

					// Find the .uk-pagination element within custom-table-tfoot
					const ukPagination = $(customTableTfoot).find(".uk-pagination");

					console.log("ukPagination1", ukPagination, this);

					// Attach the event to the found element
					ukPagination.on("select.uk.pagination", function (e, pageIndex) {
						console.log("ukPagination (2)", ukPagination);
						// Update the global variable with the selected page/index
						currentPageIndex = pageIndex + 1;
						currentPageIndexForPlotPaginatedTableWithoutBatches = pageIndex + 1;

						// Remove the uk-active class from all li elements
						ukPagination.find("li").removeClass("uk-active");
						// Add the uk-active class to the selected li element
						ukPagination.find("li:contains(" + currentPageIndex + ")").addClass("uk-active");
						ukPagination.find("li:contains(" + currentPageIndex + ")").children().first().css({"background": "#009dd8", "color": "white"});

						ukPagination.find("li:contains(" + (currentPageIndex - 1) + ")").children().first().css({"background": "#f7f7f7", "color": "black"});

						// run this function everytime currentPageIndex is updated
						showAlert(props);
					});

					// Attach click event for the "Previous" button
					$(customTableTfoot)
						.find(".uk-pagination-previous")
						.on("click", function (e) {
							e.preventDefault();
							if (currentPageIndex > 1) {
								// Update the global variable with the previous page/index
								currentPageIndex--;
								currentPageIndexForPlotPaginatedTableWithoutBatches--;

								// Remove the uk-active class from all li elements
								ukPagination.find("li").removeClass("uk-active");
								// Add the uk-active class to the selected li element
								ukPagination.find("li:contains(" + currentPageIndex + ")").addClass("uk-active");
								ukPagination.find("li:contains(" + currentPageIndex + ")").children().first().css({"background": "#009dd8", "color": "white"});
								
								ukPagination.find("li:contains(" + (currentPageIndex + 1) + ")").children().first().css({"background": "#f7f7f7", "color": "black"});
								// Trigger the uk-pagination to go to the previous page
								ukPagination.find("li:contains(" + currentPageIndex + ")").trigger("click");

								// run this function everytime currentPageIndex is updated
								showAlert(props);
							}
						});

					// Attach click event for the "Next" button
					$(customTableTfoot)
						.find(".uk-pagination-next")
						.on("click", function (e) {
							e.preventDefault();
							// Update the global variable with the next page/index
							currentPageIndex++;
							currentPageIndexForPlotPaginatedTableWithoutBatches++;

							// Remove the uk-active class from all li elements
							ukPagination.find("li").removeClass("uk-active");
							// Add the uk-active class to the selected li element
							ukPagination.find("li:contains(" + currentPageIndex + ")").addClass("uk-active");
							ukPagination.find("li:contains(" + currentPageIndex + ")").children().first().css({"background": "#009dd8", "color": "white"});
							
							ukPagination.find("li:contains(" + (currentPageIndex - 1) + ")").children().first().css({"background": "#f7f7f7", "color": "black"});
							// Trigger the uk-pagination to go to the next page
							ukPagination.find("li:contains(" + currentPageIndex + ")").trigger("click");

							// run this function everytime currentPageIndex is updated
							showAlert(props);
						});

					// updating total amount in the footer as - x results
					try {
						if (totalRowsVal && showDBSizeInTableFooter) {
							// create the element
							let newTrTr = document.createElement("tr");
							newTrTr.setAttribute("data-gjs-type", "custom-table-tfoot-row");
							newTrTr.setAttribute("class", "custom-table-tfoot-row");

							let newTd = document.createElement("td");
							newTd.setAttribute("colspan", `${numColsInputValue}`);
							newTd.style.paddingTop = `${props.tableTfootCellPaddingTop}vh`;
							newTd.style.paddingRight = `${props.tableTfootCellPaddingRight}vw`;
							newTd.style.paddingBottom = `${props.tableTfootCellPaddingBottom}vh`;
							newTd.style.paddingLeft = `${props.tableTfootCellPaddingLeft}vw`;

							let pTag = document.createElement("p");
							pTag.setAttribute("class", "showDBSizeInTableFooter");
							// pTag.style.padding = "8px";
							pTag.style.borderLeft = "1px solid black";
							pTag.style.borderRight = "1px solid black";
							pTag.style.backgroundColor = "inherit";
							pTag.style.background = "inherit";
							pTag.style.textAlign = "inherit";
							pTag.style.marginTop = `${props.tableTfootTotalRowsMarginTop}vh`;
							pTag.style.marginRight = `${props.tableTfootTotalRowsMarginRight}vw`;
							pTag.style.marginBottom = `${props.tableTfootTotalRowsMarginBottom}vh`;
							pTag.style.marginLeft = `${props.tableTfootTotalRowsMarginLeft}vw`;
							pTag.textContent = `${totalRowsVal} results`;

							newTd.appendChild(pTag);

							newTrTr.appendChild(newTd);

							if (customTableTfoot.getElementsByClassName("showDBSizeInTableFooter")[0]) {
								console.log("++ replacewith");
								customTableTfoot.getElementsByClassName("custom-table-tfoot-row")[0].getElementsByTagName("td")[0].getElementsByClassName("showDBSizeInTableFooter")[0].replaceWith(pTag);
							} else {
								console.log("++ append");
								customTableTfoot.appendChild(newTrTr);
							}
						} else {
							console.log("++ customTableTfoot ^", customTableTfoot);
							if (customTableTfoot.getElementsByClassName("custom-table-tfoot-row")[0]) {
								customTableTfoot.getElementsByClassName("custom-table-tfoot-row")[0].remove();
							}
						}
					} catch (e) {
						// statements
						console.log("showDBSizeInTableFooter error", e);
					}
				};

				if (!isSubstringPresent) {
					updateTotalRowsResultsInTableFooter($, updatedProps);					
				}

				// to update table props as soon as window is updated and then trigger rendering
				// if (!isSubstringPresent) {
			    //     setInterval(function () {
			    //       Object.keys(props).forEach(function (key) {
			    //         if (window[`${key}${idEl}`]) {
			    //         	console.log("check+ window[`${key}${idEl}`]", window[`${key}${idEl}`]);
			    //           if(props[key]===window[`${key}${idEl}`]){
			    //             // console.log('');
			    //           } else {
			    //             props[key] = window[`${key}${idEl}`];
			    //             if (updatedProps.paginationRequired) {
			    //             	if (!props.useTablePlottingApiForPagination)
			    //             		plotPaginatedTable(currentPageIndex, updatedProps);
			    //             	else
			    //             		plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps);
			    //             } else {
			    //             	plotTable(currentPageIndex, updatedProps);
			    //             }		                
			    //           }
			    //         } else {
				// 	        // Check for custom attributes in the parent iframe
				// 	        const parentIframe = window.frameElement || document.querySelector('iframe');
				// 	        if (parentIframe) {
				// 	          const iframeParent = parentIframe.parentElement;
				// 	          if (iframeParent) {
				// 	            const dataKey = 'data-' + key.replace(/([A-Z])/g, '-$1').toLowerCase() + `${idEl}`; // Convert key to kebab-case
				// 	            const iframeAttrValue = iframeParent.getAttribute(dataKey);
				// 	            if (iframeAttrValue !== null) {
				// 	              // Parse and use the attribute value if it exists
				// 	              const parsedValue = isNaN(iframeAttrValue) ? iframeAttrValue : parseFloat(iframeAttrValue); // Convert to number if applicable
				// 	              if (props[key] !== parsedValue) {
				// 	                props[key] = parsedValue;
				// 	                if (updatedProps.paginationRequired) {
				// 	                  if (!props.useTablePlottingApiForPagination) {
				// 	                    plotPaginatedTable(currentPageIndex, updatedProps);
				// 	                    console.log("check+ plotted");
				// 	                  } else {
				// 	                    plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps);
				// 	                    console.log("check+ plotted");
				// 	                  }
				// 	                } else {
				// 	                  plotTable(currentPageIndex, updatedProps);
				// 	                  console.log("check+ plotted");
				// 	                }
				// 	              }
				// 	            }
				// 	          }
				// 	          console.log("check+ iframeParent from table", iframeParent);
				// 	        }
				// 	      }
			    //       });
			    //     }, 500);
			    // } else {
				//   setInterval(function () {
				//     // Iterate over props keys
				//     Object.keys(props).forEach(function (key) {
				//       // Check for custom attributes in the parent iframe
				//         const parentIframe = window.frameElement || document.querySelector('iframe');
				//         if (parentIframe) {
				//           const iframeParent = parentIframe.parentElement;
				//           if (iframeParent) {
				//             const dataKey = 'data-' + key.replace(/([A-Z])/g, '-$1').toLowerCase() + `${idEl}`; // Convert key to kebab-case
				//             const iframeAttrValue = iframeParent.getAttribute(dataKey);
				//             if (iframeAttrValue !== null) {
				//               // Parse and use the attribute value if it exists
				//               const parsedValue = isNaN(iframeAttrValue) ? iframeAttrValue : parseFloat(iframeAttrValue); // Convert to number if applicable
				//               if (props[key] !== parsedValue) {
				//                 props[key] = parsedValue;
				//                 if (updatedProps.paginationRequired) {
				//                   if (!props.useTablePlottingApiForPagination) {
				//                     plotPaginatedTable(currentPageIndex, updatedProps);
				//                     console.log("check+ plotted");
				//                   } else {
				//                     plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps);
				//                     console.log("check+ plotted");
				//                   }
				//                 } else {
				//                   plotTable(currentPageIndex, updatedProps);
				//                   console.log("check+ plotted");
				//                 }
				//               }
				//             }
				//           }
				//           console.log("check+ iframeParent from table", iframeParent);
				//         }
				//     });
				//   }, 500);
				// }

				if (!isSubstringPresent) {
				  const intervalId = setInterval(function () {
				    let forceTablePlottingLB = sessionStorage.getItem("forceTablePlottingLB") === "true";
				    let shouldContinue = false; // Flag to determine if any updates are made

				    Object.keys(props).forEach(function (key) {
				      if (window[`${key}${idEl}`]) {
				        // console.log("check+ window[`${key}${idEl}`]", window[`${key}${idEl}`]);
				        if (!forceTablePlottingLB && props[key] === window[`${key}${idEl}`]) {
				          return; // Skip if no changes and no forceTablePlottingLB flag
				        } else if (forceTablePlottingLB && props[key] === window[`${key}${idEl}`]) {
				          // console.log("@@@-@@@"); // Force plotting logic
				          if (updatedProps.paginationRequired) {
				            if (!props.useTablePlottingApiForPagination) {
				              plotPaginatedTable(currentPageIndex, updatedProps);
							  if (!isSubstringPresent) {
								updateTotalRowsResultsInTableFooter($, updatedProps);
							  }
				            } else {
				              plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps);
							  if (!isSubstringPresent) {
								updateTotalRowsResultsInTableFooter($, updatedProps);
							  }
				            }
				          } else {
				            plotTable(currentPageIndex, updatedProps);
							if (!isSubstringPresent) {
								updateTotalRowsResultsInTableFooter($, updatedProps);
							}
				          }
				          sessionStorage.removeItem("forceTablePlottingLB");
				        } else {
				          props[key] = window[`${key}${idEl}`];
				          shouldContinue = true; // Update flag if change occurs
				        }
				      } else {
				        // Check for custom attributes in the parent iframe
				        const parentIframe = window.frameElement || document.querySelector("iframe");
				        if (parentIframe) {
				          const iframeParent = parentIframe.parentElement;
				          if (iframeParent) {
				            const dataKey = "data-" + key.replace(/([A-Z])/g, "-$1").toLowerCase() + `${idEl}`;
				            const iframeAttrValue = iframeParent.getAttribute(dataKey);
				            if (iframeAttrValue !== null) {
				              const parsedValue = isNaN(iframeAttrValue) ? iframeAttrValue : parseFloat(iframeAttrValue);
				              if (props[key] !== parsedValue) {
				                props[key] = parsedValue;
				                shouldContinue = true; // Update flag if change occurs
				              }
				            }
				          }
				        }
				      }
				    });

				    // Trigger rendering functions only if an update occurred
				    if (shouldContinue || forceTablePlottingLB) {
				      if (updatedProps.paginationRequired) {
				        if (!props.useTablePlottingApiForPagination) {
				          plotPaginatedTable(currentPageIndex, updatedProps);
						  if (!isSubstringPresent) {
							updateTotalRowsResultsInTableFooter($, updatedProps);
						  }
				        } else {
				          plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps);
						  if (!isSubstringPresent) {
							updateTotalRowsResultsInTableFooter($, updatedProps);
						  }
				        }
				      } else {
				        plotTable(currentPageIndex, updatedProps);
						if (!isSubstringPresent) {
							updateTotalRowsResultsInTableFooter($, updatedProps);
						  }
				      }
				      sessionStorage.removeItem("forceTablePlottingLB"); // Remove force flag after plotting
				    }
				  }, 500);
				} else {
				  const intervalId = setInterval(function () {
				    let forceTablePlottingLB = sessionStorage.getItem("forceTablePlottingLB") === "true";
				    let shouldContinue = false; // Flag to determine if any updates are made

				    // Iterate over props keys
				    Object.keys(props).forEach(function (key) {
				      const parentIframe = window.frameElement || document.querySelector("iframe");
				      if (parentIframe) {
				        const iframeParent = parentIframe.parentElement;
				        if (iframeParent) {
				          const dataKey = "data-" + key.replace(/([A-Z])/g, "-$1").toLowerCase() + `${idEl}`;
				          const iframeAttrValue = iframeParent.getAttribute(dataKey);

				          if (iframeAttrValue !== null) {
				            const parsedValue = isNaN(iframeAttrValue) ? iframeAttrValue : parseFloat(iframeAttrValue);

				            // Update only if the value has changed
				            if (props[key] !== parsedValue) {
				              props[key] = parsedValue;
				              shouldContinue = true; // Mark that an update occurred
				            }
				          }
				        }
				      }
				    });

				    // If updates were made, or force flag is set, trigger rendering functions
				    if (shouldContinue || forceTablePlottingLB) {
				      if (updatedProps.paginationRequired) {
				        if (!props.useTablePlottingApiForPagination) {
				          plotPaginatedTable(currentPageIndex, updatedProps);
						  if (!isSubstringPresent) {
							updateTotalRowsResultsInTableFooter($, updatedProps);
						  }
				        } else {
				          plotPaginatedTableWithoutBatches(currentPageIndexForPlotPaginatedTableWithoutBatches, updatedProps);
						  if (!isSubstringPresent) {
							updateTotalRowsResultsInTableFooter($, updatedProps);
						  }
				        }
				      } else {
				        plotTable(currentPageIndex, updatedProps);
						if (!isSubstringPresent) {
							updateTotalRowsResultsInTableFooter($, updatedProps);
						  }
				      }
				      sessionStorage.removeItem("forceTablePlottingLB"); // Remove force flag after plotting
				    } else {
				      // No updates detected, clear the interval
				      console.log("No updates detected, clearing interval.");
				      clearInterval(intervalId);
				    }
				  }, 500);
				}
			};

			// getting the table_id_selected_column index value from sessionStorage
			for (let i = 0; i < sessionStorage.length; i++) {
				const key = sessionStorage.key(i);

				// Check if the key contains the substring '_selected_column'
				if (key && key.includes("_selected_column")) {
					// Get the value associated with the key
					const value = sessionStorage.getItem(key);
					table_selected_column = value;
				}
			}
			console.log("table_selected_column", table_selected_column);

			// // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
			// const url = window.top.location.href;
			// const isSubstringPresent = url.indexOf('/editor/') !== -1 && url.indexOf('?projectId=') !== -1;

			// Check if jQuery is defined
			// if (typeof window.jQuery === "undefined") {
			//   // Load jQuery dynamically
			//   const script = document.createElement("script");
			//   script.onload = function () {
			//     // Pass jQuery to the initLib function
			//     initLib(window.jQuery, Quill, props, customTableContainer.id);
			//     if (!isSubstringPresent) {
			//       updateTotalRowsResultsInTableFooter(props);
			//     }
			//   };
			//   script.src = "https://code.jquery.com/jquery-3.7.1.min.js";
			//   document.body.appendChild(script);
			// } else {
			//   // jQuery is already available, directly call initLib
			//   initLib(window.jQuery, Quill, props, customTableContainer.id);
			//   if (!isSubstringPresent) {
			//     updateTotalRowsResultsInTableFooter(props);
			//   }
			// }

			// Check if jQuery is defined and Quill.js is defined
			if (typeof window.jQuery === "undefined") {
				console.log("jquery undefined", window.Quill);
				// Load jQuery dynamically
				const jqueryScript = document.createElement("script");
				jqueryScript.onload = function () {
					// Pass jQuery to the initLib function
					// initLib(window.jQuery, props, customTableContainer.id);

					// Check if Quill is defined
					if (typeof window.Quill === "undefined") {
						// Load Quill dynamically
						const quillScript = document.createElement("script");
						quillScript.onload = function () {
							// Now both jQuery and Quill are available, you can initialize your component
							// Call the function to initialize your component using jQuery and Quill
							initLib(window.jQuery, Quill, props, customTableContainer.id);
							// if (!isSubstringPresent) {
							// 	updateTotalRowsResultsInTableFooter($, props);
							// }
						};
						quillScript.src = "https://cdn.jsdelivr.net/npm/quill@2.0.0-rc.2/dist/quill.js";
						document.body.appendChild(quillScript);
					} else {
						// Quill is already available, directly call initLib
						initLib(window.jQuery, window.Quill, props, customTableContainer.id);
						// if (!isSubstringPresent) {
						// 	updateTotalRowsResultsInTableFooter($, props);
						// }						
					}
				};
				jqueryScript.src = "https://code.jquery.com/jquery-3.7.1.min.js";
				document.body.appendChild(jqueryScript);
			} else {
				console.log("jquery defined", window.Quill);
				// jQuery is already available, directly check and load Quill
				if (typeof window.Quill === "undefined") {
					// Load Quill dynamically
					const quillScript = document.createElement("script");
					quillScript.onload = function () {
						// Now both jQuery and Quill are available, you can initialize your component
						// Call the function to initialize your component using jQuery and Quill
						initLib(window.jQuery, Quill, props, customTableContainer.id);
						// if (!isSubstringPresent) {
						// 	updateTotalRowsResultsInTableFooter($, props);
						// }
					};
					quillScript.src = "https://cdn.jsdelivr.net/npm/quill@2.0.0-rc.2/dist/quill.js";
					document.body.appendChild(quillScript);
				} else {
					// Quill is already available, directly call initLib
					initLib(window.jQuery, window.Quill, props, customTableContainer.id);
					// if (!isSubstringPresent) {
					// 	updateTotalRowsResultsInTableFooter($, props);
					// }
				}
			}
		};

		// table container - assembles all the table related component types in one place
		editor.DomComponents.addType("custom-table-container", {
			model: {
				defaults: {
					tagName: "div",
					type: "custom-table-container",
					attributes: {
						class: "custom-table-container",
					},
					script: paginationScript,

					"style-default": {
						padding: "0.3em",
						"overflow-x": "auto",
						"scrollbar-color": "rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)",
					},

					style: {
						padding: "0.3em",
						"overflow-x": "auto",
						"scrollbar-color": "rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)",
					},

					// default model properties
					numColsInputValue: 3,
					numRowsPerPageValue: 5,
					TotalRows: 50,
					paginationRequired: true,
					addColAtIdxValue: "",
					deleteColAtIdxValue: "",
					dynamicTableFooterColumns: false,
					showDBSizeInTableFooter: true,
					useTablePlottingApiForPagination: false,
					SortingBtnsInAllCols: false,

					API_HOST: API_HOST,
					projectId: localStorage.getItem("project_id") ? localStorage.getItem("project_id") : options["project_id"] ? options["project_id"] : "",
					DataModelObjIdKeyname: "_id",
					options: options,

					// Table plotting API traits
					customUrlValue: "",
					baseUrlValue: "",
					apiPathValue: "",
					apiMethodValue: "",
					apiUsernameValue: "",
					apiPasswordValue: "",
					apiBearerTokenValue: "",
					apiMoreHeadersValue: "",
					apiBodyValue: "",
					nestedArrOfObjKeynameValue: "",

					// trait to plot using custom array-of-objects (usually from LB), without using API
					// always use together
					nonAPICustomDataCacheKeyname: "", // holds keyname to store the nonAPICustomData in cache
					
					// Pagination & Total-rows plotting API traits
					totalRowsAPIValue: "",
					totalRowsAPIBaseUrlValue: "",
					totalRowsAPIPathValue: "",
					totalRowsAPIKeynameValue: "",
					totalRowsAPIMethodValue: "",
					totalRowsAPIUsernameValue: "",
					totalRowsAPIPasswordValue: "",
					totalRowsAPIBearerTokenValue: "",
					totalRowsAPIMoreHeadersValue: "",
					totalRowsAPIBodyValue: "",
					totalRowsAPINestedArrOfObjKeynameValue: "",

					// Table updating API traits
					updatingCustomUrlValue: "",
					updatingBaseUrlValue: "",
					updatingApiPathValue: "",
					updatingApiQueryParamValue: "",
					updatingApiTableAttributeValue: "",
					updatingApiMethodValue: "",
					updatingApiUsernameValue: "",
					updatingApiPasswordValue: "",
					updatingApiBearerTokenValue: "",
					updatingApiMoreHeadersValue: "",

					// so that HeaderField_i are saved in export/downloaded code also
					HeaderFields: {},
					EditableFields: {},
					ColumnwiseDisplayDateFormats: {},
					ColumnwiseDisplayTimeZoneFormats: {},
					ColumnwiseDisplayNumberFormats: {},
					displayReferenceLabels: "",
					richTextIndexes: [],
					richTextOptions: {
						toolbar: true, // No toolbar
						readOnly: false,
					},
					applyFunctionsFromStorage: {},

					// thead styling properties
					tableTheadFontSize: options.table_constants ? options.table_constants.tableTheadFontSize : "1.2vw",
					tableTheadFontColor: options.table_constants ? options.table_constants.tableTheadFontColor : "white",
					tableTheadFontFamily: options.table_constants ? options.table_constants.tableTheadFontFamily : "inherit",
					tableTheadRowBackgroundColor: options.table_constants ? options.table_constants.tableTheadRowBackgroundColor : "",
					tableTheadRowEnableBackgroundColor: false,
					tableTheadRowEnableLinearGradient1: true,
					tableTheadRowLinearGradient1: options.table_constants ? options.table_constants.tableTheadRowLinearGradient1 : "#714BEE,",
					// tableTheadRowLinearGradient1: "#714BEE,",
					tableTheadRowLinearGradient2: options.table_constants ? options.table_constants.tableTheadRowLinearGradient2 : "#005C8F",
					// tableTheadRowLinearGradient2: "#005C8F",
					tableTheadRowLinearGradientAngle: "180deg",

					tableTheadRowBorderTopWidth: options.table_constants ? options.table_constants.tableTheadRowBorderTopWidth : "",
					tableTheadRowBorderTopType: options.table_constants ? options.table_constants.tableTheadRowBorderTopType : "",
					tableTheadRowBorderTopColor: options.table_constants ? options.table_constants.tableTheadRowBorderTopColor : "",

					tableTheadRowBorderRightWidth: options.table_constants ? options.table_constants.tableTheadRowBorderRightWidth : "",
					tableTheadRowBorderRightType: options.table_constants ? options.table_constants.tableTheadRowBorderRightType : "",
					tableTheadRowBorderRightColor: options.table_constants ? options.table_constants.tableTheadRowBorderRightColor : "",

					tableTheadRowBorderBottomWidth: options.table_constants ? options.table_constants.tableTheadRowBorderBottomWidth : "",
					tableTheadRowBorderBottomType: options.table_constants ? options.table_constants.tableTheadRowBorderBottomType : "",
					tableTheadRowBorderBottomColor: options.table_constants ? options.table_constants.tableTheadRowBorderBottomColor : "",

					tableTheadRowBorderLeftWidth: options.table_constants ? options.table_constants.tableTheadRowBorderLeftWidth : "",
					tableTheadRowBorderLeftType: options.table_constants ? options.table_constants.tableTheadRowBorderLeftType : "",
					tableTheadRowBorderLeftColor: options.table_constants ? options.table_constants.tableTheadRowBorderLeftColor : "",

					// tbody styling properties
					tBodyTdFontSize: options.table_constants ? options.table_constants.tableTbodyFontSize : "1.2vw",
					tBodyTdFontColor: options.table_constants ? options.table_constants.tableTbodyFontColor : "#000000",
					tBodyTdFontFamily: options.table_constants ? options.table_constants.tableTbodyFontFamily : "Arial, sans-serif",
					tBodyTdTextAlign: options.table_constants ? options.table_constants.tableTbodyTextAlign : "center",
					tBodyOddTrBackgroundColor: options.table_constants ? options.table_constants.tableTbodyRowOddBackground : "#C3C8C8",
					// tBodyOddTrBackgroundColor: "#C3C8C8",
					tBodyEvenTrBackgroundColor: options.table_constants ? options.table_constants.tableTbodyRowEvenBackground : "#EFF2F2",
					// tBodyEvenTrBackgroundColor: "#EFF2F2",
					tBodyTrHoverColor: options.table_constants ? options.table_constants.tableTbodyRowHoverColor : "rgba(0, 0, 0, 0.5)",
					tBodyTrSelectColorHighlight: false,

					tBodyTrBorderTopWidth: options.table_constants ? options.table_constants.tableTbodyRowBorderTopWidth : "",
					tBodyTrBorderTopType: options.table_constants ? options.table_constants.tableTbodyRowBorderTopType : "",
					tBodyTrBorderTopColor: options.table_constants ? options.table_constants.tableTbodyRowBorderTopColor : "",

					tBodyTrBorderRightWidth: options.table_constants ? options.table_constants.tableTbodyRowBorderRightWidth : "1",
					tBodyTrBorderRightType: options.table_constants ? options.table_constants.tableTbodyRowBorderRightType : "solid",
					tBodyTrBorderRightColor: options.table_constants ? options.table_constants.tableTbodyRowBorderRightColor : "#000000",

					tBodyTrBorderBottomWidth: options.table_constants ? options.table_constants.tableTbodyRowBorderBottomWidth : "",
					tBodyTrBorderBottomType: options.table_constants ? options.table_constants.tableTbodyRowBorderBottomType : "",
					tBodyTrBorderBottomColor: options.table_constants ? options.table_constants.tableTbodyRowBorderBottomColor : "",

					tBodyTrBorderLeftWidth: options.table_constants ? options.table_constants.tableTbodyRowBorderLeftWidth : "1",
					tBodyTrBorderLeftType: options.table_constants ? options.table_constants.tableTbodyRowBorderLeftType : "solid",
					tBodyTrBorderLeftColor: options.table_constants ? options.table_constants.tableTbodyRowBorderLeftColor : "#000000",

					// tfoot styling properties
					tableTfootFontSize: options.table_constants ? options.table_constants.tableTfootFontSize : "1.2vw",
					tableTfootFontColor: options.table_constants ? options.table_constants.tableTfootFontColor : "white",
					tableTfootFontFamily: options.table_constants ? options.table_constants.tableTfootFontFamily : "inherit",
					tableTfootRowBackgroundColor: options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : "",
					tableTfootRowEnableBackgroundColor: true,
					tableTfootRowEnableLinearGradient1: false,
					tableTfootRowLinearGradient1: options.table_constants ? options.table_constants.tableTfootRowLinearGradient1 : "#714BEE,",
					tableTfootRowLinearGradient2: options.table_constants ? options.table_constants.tableTfootRowLinearGradient2 : "#005C8F",
					tableTfootRowLinearGradientAngle: "180",

					tableTfootRowBorderTopWidth: options.table_constants ? options.table_constants.tableTfootRowBorderTopWidth : "",
					tableTfootRowBorderTopType: options.table_constants ? options.table_constants.tableTfootRowBorderTopType : "",
					tableTfootRowBorderTopColor: options.table_constants ? options.table_constants.tableTfootRowBorderTopColor : "",

					tableTfootRowBorderRightWidth: options.table_constants ? options.table_constants.tableTfootRowBorderRightWidth : "",
					tableTfootRowBorderRightType: options.table_constants ? options.table_constants.tableTfootRowBorderRightType : "",
					tableTfootRowBorderRightColor: options.table_constants ? options.table_constants.tableTfootRowBorderRightColor : "",

					tableTfootRowBorderBottomWidth: options.table_constants ? options.table_constants.tableTfootRowBorderBottomWidth : "",
					tableTfootRowBorderBottomType: options.table_constants ? options.table_constants.tableTfootRowBorderBottomType : "",
					tableTfootRowBorderBottomColor: options.table_constants ? options.table_constants.tableTfootRowBorderBottomColor : "",

					tableTfootRowBorderLeftWidth: options.table_constants ? options.table_constants.tableTfootRowBorderLeftWidth : "",
					tableTfootRowBorderLeftType: options.table_constants ? options.table_constants.tableTfootRowBorderLeftType : "",
					tableTfootRowBorderLeftColor: options.table_constants ? options.table_constants.tableTfootRowBorderLeftColor : "",
					
					tableTfootTotalRowsMarginTop: options.table_constants ? options.table_constants.tableTfootTotalRowsMarginTop : 0,
					tableTfootTotalRowsMarginRight: options.table_constants ? options.table_constants.tableTfootTotalRowsMarginRight : 0,
					tableTfootTotalRowsMarginBottom: options.table_constants ? options.table_constants.tableTfootTotalRowsMarginBottom : 0,
					tableTfootTotalRowsMarginLeft: options.table_constants ? options.table_constants.tableTfootTotalRowsMarginLeft : 0,
					tableTfootCellPaddingTop: options.table_constants ? options.table_constants.tableTfootCellPaddingTop : 0,
					tableTfootCellPaddingRight: options.table_constants ? options.table_constants.tableTfootCellPaddingRight : 0,
					tableTfootCellPaddingBottom: options.table_constants ? options.table_constants.tableTfootCellPaddingBottom : 0,
					tableTfootCellPaddingLeft: options.table_constants ? options.table_constants.tableTfootCellPaddingLeft : 0,

					// function traits: traits to trigger functions in table

					// // calcDiffBtwCurrDateNApiRespVal(date): takes a date input and calculates difference between current date and provided date and returns value in years
					// calcDiffBtwCurrDateNApiRespVal: {
					// 	triggerFunc: true, // to control the triggerring of the function from LB
					// 	colIdx: 1, // to specify which column to trigger this function on
					// },
					
					traits: [
						{
							type: "checkbox",
							name: "paginationRequired",
							label: "Pagination Required",
							value: true,
							changeProp: true,
						},
						// {
						// 	type: "number",
						// 	name: "numRowsPerPage",
						// 	label: "Number of rows per page (table view)",
						// 	placeholder: "5",
						// 	value: 5,
						// 	min: "1",
						// 	changeProp: true,
						// },
					],

					changeProp: true,
					"script-props": [
						"displayReferenceLabels",
						"DataModelObjIdKeyname",
						"projectId",
						"API_HOST",
						"options",
						"tBodyTdFontSize",
						"tBodyTdFontColor",
						"tBodyTdFontFamily",
						"tBodyTdTextAlign",
						"tBodyOddTrBackgroundColor",
						"tBodyEvenTrBackgroundColor",
						"tBodyTrBorderTopWidth",
						"tBodyTrBorderTopType",
						"tBodyTrBorderTopColor",
						"tBodyTrBorderRightWidth",
						"tBodyTrBorderRightType",
						"tBodyTrBorderRightColor",
						"tBodyTrBorderBottomWidth",
						"tBodyTrBorderBottomType",
						"tBodyTrBorderBottomColor",
						"tBodyTrBorderLeftWidth",
						"tBodyTrBorderLeftType",
						"tBodyTrBorderLeftColor",
						"tBodyTrHoverColor",
						"tBodyTrSelectColorHighlight",
						"HeaderFields",
						"EditableFields",
						"ColumnwiseDisplayDateFormats",
						"ColumnwiseDisplayTimeZoneFormats",
						"ColumnwiseDisplayNumberFormats",
						"numColsInputValue",
						"paginationRequired",
						"numRowsPerPageValue",
						"dynamicTableFooterColumns",
						"showDBSizeInTableFooter",
						"useTablePlottingApiForPagination",
						"SortingBtnsInAllCols",
						"TotalRows",
						"customUrlValue",
						"baseUrlValue",
						"apiPathValue",
						"apiMethodValue",
						"apiUsernameValue",
						"apiPasswordValue",
						"apiBearerTokenValue",
						"apiMoreHeadersValue",
						"apiBodyValue",
						"nestedArrOfObjKeynameValue",
						"nonAPICustomDataCacheKeyname",
						"totalRowsAPIValue",
						"totalRowsAPIBaseUrlValue",
						"totalRowsAPIPathValue",
						"totalRowsAPIKeynameValue",
						"totalRowsAPIMethodValue",
						"totalRowsAPIUsernameValue",
						"totalRowsAPIPasswordValue",
						"totalRowsAPIBearerTokenValue",
						"totalRowsAPIMoreHeadersValue",
						"totalRowsAPIBodyValue",
						"totalRowsAPINestedArrOfObjKeynameValue",
						"updatingCustomUrlValue",
						"updatingBaseUrlValue",
						"updatingApiPathValue",
						"updatingApiQueryParamValue",
						"updatingApiTableAttributeValue",
						"updatingApiMethodValue",
						"updatingApiUsernameValue",
						"updatingApiPasswordValue",
						"updatingApiBearerTokenValue",
						"updatingApiMoreHeadersValue",
						"addColAtIdxValue",
						"deleteColAtIdxValue",
						"tableTheadFontSize",
						"tableTheadFontColor",
						"tableTheadFontFamily",
						"tableTheadRowBackgroundColor",
						"tableTheadRowLinearGradient1",
						"tableTheadRowLinearGradient2",
						"tableTheadRowLinearGradientAngle",
						"tableTheadRowBorderTopWidth",
						"tableTheadRowBorderTopType",
						"tableTheadRowBorderTopColor",
						"tableTheadRowBorderRightWidth",
						"tableTheadRowBorderRightType",
						"tableTheadRowBorderRightColor",
						"tableTheadRowBorderBottomWidth",
						"tableTheadRowBorderBottomType",
						"tableTheadRowBorderBottomColor",
						"tableTheadRowBorderLeftWidth",
						"tableTheadRowBorderLeftType",
						"tableTheadRowBorderLeftColor",
						"tableTheadRowEnableBackgroundColor",
						"tableTheadRowEnableLinearGradient1",
						"tableTfootFontSize",
						"tableTfootFontColor",
						"tableTfootFontFamily",
						"tableTfootRowBackgroundColor",
						"tableTfootRowLinearGradient1",
						"tableTfootRowLinearGradient2",
						"tableTfootRowLinearGradientAngle",
						"tableTfootRowBorderTopWidth",
						"tableTfootRowBorderTopType",
						"tableTfootRowBorderTopColor",
						"tableTfootRowBorderRightWidth",
						"tableTfootRowBorderRightType",
						"tableTfootRowBorderRightColor",
						"tableTfootRowBorderBottomWidth",
						"tableTfootRowBorderBottomType",
						"tableTfootRowBorderBottomColor",
						"tableTfootRowBorderLeftWidth",
						"tableTfootRowBorderLeftType",
						"tableTfootRowBorderLeftColor",
						"tableTfootRowEnableBackgroundColor",
						"tableTfootRowEnableLinearGradient1",
						"tableTfootTotalRowsMarginTop",
						"tableTfootTotalRowsMarginRight",
						"tableTfootTotalRowsMarginBottom",
						"tableTfootTotalRowsMarginLeft",
						"tableTfootCellPaddingTop",
						"tableTfootCellPaddingRight",
						"tableTfootCellPaddingBottom",
						"tableTfootCellPaddingLeft",
						"richTextIndexes",
						"richTextOptions",
						"applyFunctionsFromStorage",
					],

					components: [
						// {
						// 	tagName: "button",
						// 	type: "customize-table-btn",
						// 	attributes: {class: "customize-table-btn"},
						// 	content: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/></svg>',
						// },

						// <table> tag
						{
							tagName: "table",
							type: "custom-table",
							attributes: {
								class: "custom-table",
							},

							"style-default": {
								width: "100%",
								"max-width": "100%",
								margin: "auto",
								"border-collapse": "collapse",
								"border-left": "1px solid black",
								"border-right": "1px solid black",
								"text-align": "center",
								"table-layout": "auto",
							},

							style: {
								width: "100%",
								"max-width": "100%",
								margin: "auto",
								"border-collapse": "collapse",
								"border-left": "1px solid black",
								"border-right": "1px solid black",
								"text-align": "center",
								"table-layout": "auto",
							},

							// traits: [
							// 	"id",
							// ],

							components: [
								// <thead> tag
								{
									tagName: "thead",
									type: "custom-table-thead",
									attributes: {
										class: "custom-table-thead",
									},

									"style-default": {
										width: "100%",
										"white-space": "nowrap",
									},

									style: {
										width: "100%",
										"white-space": "nowrap",
									},

									components: [
										{
											tagName: "tr",
											type: "custom-table-thead-row",
											attributes: {
												class: "custom-table-thead-row",
											},

											"style-default": {
												width: "100%",
												// "background-image": "linear-gradient(180deg, #714BEE, #005C8F)",
											},

											style: {
												width: "100%",
												// "background-image": "linear-gradient(180deg, #714BEE, #005C8F)",
											},

											components: [
												{
													tagName: "th",
													// type: "custom-table-thead-row-th",

													"style-default": {
														padding: "8px",
														// "border-left": "1px solid black",
														// "border-right": "1px solid black",
														"border-collapse": "collapse",
														"font-size": options.table_constants ? options.table_constants.tableTheadFontSize + "vw" : "",
														color: options.table_constants ? options.table_constants.tableTheadFontColor : "",
														"font-family": options.table_constants ? options.table_constants.tableTheadFontFamily : "",
														"background-color": options.table_constants ? options.table_constants.tableTheadRowBackgroundColor : "",
														"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTheadRowLinearGradient1}, ${options.table_constants.tableTheadRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
														"border-top": options.table_constants ? `${options.table_constants.tableTheadRowBorderTopWidth} ${options.table_constants.tableTheadRowBorderTopType} ${options.table_constants.tableTheadRowBorderTopColor}` : "",
														"border-right": options.table_constants ? `${options.table_constants.tableTheadRowBorderRightWidth} ${options.table_constants.tableTheadRowBorderRightType} ${options.table_constants.tableTheadRowBorderRightColor}` : "",
														"border-bottom": options.table_constants ? `${options.table_constants.tableTheadRowBorderBottomWidth} ${options.table_constants.tableTheadRowBorderBottomType} ${options.table_constants.tableTheadRowBorderBottomColor}` : "",
														"border-left": options.table_constants ? `${options.table_constants.tableTheadRowBorderLeftWidth} ${options.table_constants.tableTheadRowBorderLeftType} ${options.table_constants.tableTheadRowBorderLeftColor}` : "",
													},

													style: {
														padding: "8px",
														// "border-left": "1px solid black",
														// "border-right": "1px solid black",
														"border-collapse": "collapse",
														"font-size": options.table_constants ? options.table_constants.tableTheadFontSize + "vw" : "",
														color: options.table_constants ? options.table_constants.tableTheadFontColor : "",
														"font-family": options.table_constants ? options.table_constants.tableTheadFontFamily : "",
														"background-color": options.table_constants ? options.table_constants.tableTheadRowBackgroundColor : "",
														"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTheadRowLinearGradient1}, ${options.table_constants.tableTheadRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
														"border-top": options.table_constants ? `${options.table_constants.tableTheadRowBorderTopWidth} ${options.table_constants.tableTheadRowBorderTopType} ${options.table_constants.tableTheadRowBorderTopColor}` : "",
														"border-right": options.table_constants ? `${options.table_constants.tableTheadRowBorderRightWidth} ${options.table_constants.tableTheadRowBorderRightType} ${options.table_constants.tableTheadRowBorderRightColor}` : "",
														"border-bottom": options.table_constants ? `${options.table_constants.tableTheadRowBorderBottomWidth} ${options.table_constants.tableTheadRowBorderBottomType} ${options.table_constants.tableTheadRowBorderBottomColor}` : "",
														"border-left": options.table_constants ? `${options.table_constants.tableTheadRowBorderLeftWidth} ${options.table_constants.tableTheadRowBorderLeftType} ${options.table_constants.tableTheadRowBorderLeftColor}` : "",
													},

													components: [
														{
															tagName: "p",
															type: "text",
															editable: true,

															// "style-default": {
															// 	'font-size': '1.2vw',
															// },

															// style: {
															// 	'font-size': '1.2vw',
															// },

															components: {
																type: "textnode",
																content: "Header Field 0",
															},
														},
													],
												},
												{
													tagName: "th",
													// type: "custom-table-thead-row-th",
													// type: "cell",

													"style-default": {
														padding: "8px",
														// "border-left": "1px solid black",
														// "border-right": "1px solid black",
														"border-collapse": "collapse",
														"font-size": options.table_constants ? options.table_constants.tableTheadFontSize + "vw" : "",
														color: options.table_constants ? options.table_constants.tableTheadFontColor : "",
														"font-family": options.table_constants ? options.table_constants.tableTheadFontFamily : "",
														"background-color": options.table_constants ? options.table_constants.tableTheadRowBackgroundColor : "",
														"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTheadRowLinearGradient1}, ${options.table_constants.tableTheadRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
														"border-top": options.table_constants ? `${options.table_constants.tableTheadRowBorderTopWidth} ${options.table_constants.tableTheadRowBorderTopType} ${options.table_constants.tableTheadRowBorderTopColor}` : "",
														"border-right": options.table_constants ? `${options.table_constants.tableTheadRowBorderRightWidth} ${options.table_constants.tableTheadRowBorderRightType} ${options.table_constants.tableTheadRowBorderRightColor}` : "",
														"border-bottom": options.table_constants ? `${options.table_constants.tableTheadRowBorderBottomWidth} ${options.table_constants.tableTheadRowBorderBottomType} ${options.table_constants.tableTheadRowBorderBottomColor}` : "",
														"border-left": options.table_constants ? `${options.table_constants.tableTheadRowBorderLeftWidth} ${options.table_constants.tableTheadRowBorderLeftType} ${options.table_constants.tableTheadRowBorderLeftColor}` : "",
													},

													style: {
														padding: "8px",
														// "border-left": "1px solid black",
														// "border-right": "1px solid black",
														"border-collapse": "collapse",
														"font-size": options.table_constants ? options.table_constants.tableTheadFontSize + "vw" : "",
														color: options.table_constants ? options.table_constants.tableTheadFontColor : "",
														"font-family": options.table_constants ? options.table_constants.tableTheadFontFamily : "",
														"background-color": options.table_constants ? options.table_constants.tableTheadRowBackgroundColor : "",
														"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTheadRowLinearGradient1}, ${options.table_constants.tableTheadRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
														"border-top": options.table_constants ? `${options.table_constants.tableTheadRowBorderTopWidth} ${options.table_constants.tableTheadRowBorderTopType} ${options.table_constants.tableTheadRowBorderTopColor}` : "",
														"border-right": options.table_constants ? `${options.table_constants.tableTheadRowBorderRightWidth} ${options.table_constants.tableTheadRowBorderRightType} ${options.table_constants.tableTheadRowBorderRightColor}` : "",
														"border-bottom": options.table_constants ? `${options.table_constants.tableTheadRowBorderBottomWidth} ${options.table_constants.tableTheadRowBorderBottomType} ${options.table_constants.tableTheadRowBorderBottomColor}` : "",
														"border-left": options.table_constants ? `${options.table_constants.tableTheadRowBorderLeftWidth} ${options.table_constants.tableTheadRowBorderLeftType} ${options.table_constants.tableTheadRowBorderLeftColor}` : "",
													},

													components: [
														{
															tagName: "p",
															type: "text",
															editable: true,

															// "style-default": {
															// 	'font-size': '1.2vw',
															// },

															// style: {
															// 	'font-size': '1.2vw',
															// },

															components: {
																type: "textnode",
																content: "Header Field 1",
															},
														},
													],
												},
												{
													tagName: "th",
													// type: "custom-table-thead-row-th",
													// type: "cell",

													"style-default": {
														padding: "8px",
														// "border-left": "1px solid black",
														// "border-right": "1px solid black",
														"border-collapse": "collapse",
														"font-size": options.table_constants ? options.table_constants.tableTheadFontSize + "vw" : "",
														color: options.table_constants ? options.table_constants.tableTheadFontColor : "",
														"font-family": options.table_constants ? options.table_constants.tableTheadFontFamily : "",
														"background-color": options.table_constants ? options.table_constants.tableTheadRowBackgroundColor : "",
														"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTheadRowLinearGradient1}, ${options.table_constants.tableTheadRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
														"border-top": options.table_constants ? `${options.table_constants.tableTheadRowBorderTopWidth} ${options.table_constants.tableTheadRowBorderTopType} ${options.table_constants.tableTheadRowBorderTopColor}` : "",
														"border-right": options.table_constants ? `${options.table_constants.tableTheadRowBorderRightWidth} ${options.table_constants.tableTheadRowBorderRightType} ${options.table_constants.tableTheadRowBorderRightColor}` : "",
														"border-bottom": options.table_constants ? `${options.table_constants.tableTheadRowBorderBottomWidth} ${options.table_constants.tableTheadRowBorderBottomType} ${options.table_constants.tableTheadRowBorderBottomColor}` : "",
														"border-left": options.table_constants ? `${options.table_constants.tableTheadRowBorderLeftWidth} ${options.table_constants.tableTheadRowBorderLeftType} ${options.table_constants.tableTheadRowBorderLeftColor}` : "",
													},

													style: {
														padding: "8px",
														// "border-left": "1px solid black",
														// "border-right": "1px solid black",
														"border-collapse": "collapse",
														"font-size": options.table_constants ? options.table_constants.tableTheadFontSize + "vw" : "",
														color: options.table_constants ? options.table_constants.tableTheadFontColor : "",
														"font-family": options.table_constants ? options.table_constants.tableTheadFontFamily : "",
														"background-color": options.table_constants ? options.table_constants.tableTheadRowBackgroundColor : "",
														"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTheadRowLinearGradient1}, ${options.table_constants.tableTheadRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
														"border-top": options.table_constants ? `${options.table_constants.tableTheadRowBorderTopWidth} ${options.table_constants.tableTheadRowBorderTopType} ${options.table_constants.tableTheadRowBorderTopColor}` : "",
														"border-right": options.table_constants ? `${options.table_constants.tableTheadRowBorderRightWidth} ${options.table_constants.tableTheadRowBorderRightType} ${options.table_constants.tableTheadRowBorderRightColor}` : "",
														"border-bottom": options.table_constants ? `${options.table_constants.tableTheadRowBorderBottomWidth} ${options.table_constants.tableTheadRowBorderBottomType} ${options.table_constants.tableTheadRowBorderBottomColor}` : "",
														"border-left": options.table_constants ? `${options.table_constants.tableTheadRowBorderLeftWidth} ${options.table_constants.tableTheadRowBorderLeftType} ${options.table_constants.tableTheadRowBorderLeftColor}` : "",
													},

													components: [
														{
															tagName: "p",
															type: "text",
															editable: true,

															// "style-default": {
															// 	'font-size': '1.2vw',
															// },

															// style: {
															// 	'font-size': '1.2vw',
															// },

															components: {
																type: "textnode",
																content: "Header Field 2",
															},
														},
													],
												},
											],
										},
									],
								},
								{
									tagName: "tbody",
									type: "custom-table-tbody",
									attributes: {
										class: "custom-table-tbody",
									},
								},
								{
									tagName: "tfoot",
									type: "custom-table-tfoot",
									attributes: {
										class: "custom-table-tfoot",
									},

									"style-default": {
										width: "100%",
										// background: "lightgray",
										// 'background-image': 'linear-gradient(180deg, #714BEE, #005C8F)',
										"font-size": options.table_constants ? options.table_constants.tableTfootFontSize + "vw" : "",
										"font-family": options.table_constants ? options.table_constants.tableTfootFontFamily : "",
										color: options.table_constants ? options.table_constants.tableTfootFontColor : "white",
										"background-color": options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : "lightgray",
										"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
										"border-top": options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : "",
										"border-right": options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : "",
										"border-bottom": options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : "",
										"border-left": options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : "",
									},

									style: {
										width: "100%",
										// background: "lightgray",
										// 'background-image': 'linear-gradient(180deg, #714BEE, #005C8F)',
										"font-size": options.table_constants ? options.table_constants.tableTfootFontSize + "vw" : "",
										"font-family": options.table_constants ? options.table_constants.tableTfootFontFamily : "",
										color: options.table_constants ? options.table_constants.tableTfootFontColor : "white",
										"background-color": options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : "lightgray",
										"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
										"border-top": options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : "",
										"border-right": options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : "",
										"border-bottom": options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : "",
										"border-left": options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : "",
									},

									components: [
										{
											tagName: "tr",
											type: "custom-table-tfoot-row-for-pagination",
											attributes: {
												class: "custom-table-tfoot-row-for-pagination",
											},

											"style-default": {
												width: "100%",
												"font-size": options.table_constants ? options.table_constants.tableTfootFontSize + "vw" : "",
												"font-family": options.table_constants ? options.table_constants.tableTfootFontFamily : "",
												color: options.table_constants ? options.table_constants.tableTfootFontColor : "white",
												"background-color": options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : "lightgray",
												"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
												"border-top": options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : "",
												"border-right": options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : "",
												"border-bottom": options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : "",
												"border-left": options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : "",
											},

											style: {
												width: "100%",
												"font-size": options.table_constants ? options.table_constants.tableTfootFontSize + "vw" : "",
												"font-family": options.table_constants ? options.table_constants.tableTfootFontFamily : "",
												color: options.table_constants ? options.table_constants.tableTfootFontColor : "white",
												"background-color": options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : "lightgray",
												"background-image": options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : "linear-gradient(180deg, #714BEE, #005C8F)",
												"border-top": options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : "",
												"border-right": options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : "",
												"border-bottom": options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : "",
												"border-left": options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : "",
											},

											components: [
												{
													tagName: "td",
													attributes: {
														colspan: "3",
													},

													"style-default": {
														padding: "8px",
														"border-left": "1px solid black",
														"border-right": "1px solid black",
													},

													style: {
														padding: "8px",
														"border-left": "1px solid black",
														"border-right": "1px solid black",
													},

													components: [
														{
															tagName: "div",
															type: "custom-table-pagination",
															attributes: {
																class: "custom-table-pagination",
															},
															editable: true,

															"style-default": {
																display: "flex",
																"align-items": "flex-start",
																"justify-content": "flex-start",
																"column-gap": "0.4em",
																"overflow-x": "auto",
																"max-width": "100%",
																width: "max-content",
																margin: "auto",
																"padding-top": "1%",
															},

															style: {
																display: "flex",
																"align-items": "flex-start",
																"justify-content": "flex-start",
																"column-gap": "0.4em",
																"overflow-x": "auto",
																"max-width": "100%",
																width: "max-content",
																margin: "auto",
																"padding-top": "1%",
															},

															// components: [
															// 	'<li class="uk-pagination-previous" style="list-style: none;"><a href="">Previous <</a></li>',
															// 	{
															// 		tagName: "ul",
															// 		attributes: {
															// 			class: "uk-pagination uk-pagination-left",
															// 			"data-uk-pagination": "{items: 50, itemsOnPage: 5}",
															// 		},
															// 	},
															// 	'<li class="uk-pagination-next" style="list-style: none;"><a href="">> Next</a></li>',
															// ],

															// set as content so that it doesn't disappear on rerendering of the component
															content: `
																<li class="uk-pagination-previous" style="list-style: none;"><a href="">Previous <</a></li>
																<ul class="uk-pagination uk-pagination-left" data-uk-pagination="{items: 50, itemsOnPage: 5}"></ul>
																<li class="uk-pagination-next" style="list-style: none;"><a href="">> Next</a></li>
															`,
														},
														// {
														// 	type: "custom-table-pagination",
														// },
													],
												},
											],
										},
									],
								},
							],
						},
					],
				},

				init() {
					// this.on("change:numRowsPerPage", this.Paginate);
					this.on("change:paginationRequired", this.Paginate);

					this.listenTo(this, "change:numRowsPerPageValue", this.Paginate);
					this.listenTo(this, "change:showDBSizeInTableFooter", this.Paginate);
					this.listenTo(this, "change:useTablePlottingApiForPagination", this.Paginate);
					
					this.listenTo(this, "change:SortingBtnsInAllCols", this.SortingBtnsInAllColsHandler);

					// so that pagination and total-rows update on customURL also
					this.on("change:customUrlValue", this.Paginate);
					this.on("change:baseUrlValue", this.Paginate);
					this.on("change:apiPathValue", this.Paginate);
					this.on("change:apiMethodValue", this.Paginate);
					this.on("change:apiUsernameValue", this.Paginate);
					this.on("change:apiPasswordValue", this.Paginate);
					this.on("change:apiBearerTokenValue", this.Paginate);
					this.on("change:apiMoreHeadersValue", this.Paginate);
					this.on("change:apiBodyValue", this.Paginate);
					this.on("change:nestedArrOfObjKeynameValue", this.Paginate);

					this.listenTo(this, "change:totalRowsAPIValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPIBaseUrlValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPIPathValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPIKeynameValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPIMethodValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPIUsernameValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPIPasswordValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPIBearerTokenValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPIMoreHeadersValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPIBodyValue", this.Paginate);
					this.listenTo(this, "change:totalRowsAPINestedArrOfObjKeynameValue", this.Paginate);

					// Ensure HeaderFields is an empty object during initialization
					// this.set("HeaderFields", {});
          			// this.set("richTextIndexes", []);
					// this.attributes.HeaderFields = {};

					// THE UPDATED PROPS ARE HERE, so use these to update the session storage
					// console.log("init props", this.props());
					// sessionStorage.setItem(`updatedProps_${this.getAttributes()['id']}`, JSON.stringify(this.props()));
				},

				// to add sorting buttons to all columns of the table and removes it as well
				SortingBtnsInAllColsHandler() {
					let {SortingBtnsInAllCols} = this.props();

					console.log('@@ SortingBtnsInAllCols', SortingBtnsInAllCols);

					const customTableContainer = this;
					const customTable = customTableContainer.find(".custom-table")[0];
					const customTableThead = customTable.find(".custom-table-thead")[0];

					// adding custom-table-sort-container to columns that don't have one
					let allRows = customTableThead.find('.custom-table-thead-row');
					allRows.forEach((row) => {
						let allHeaderCells = row.find('th[data-gjs-type="custom-table-thead-row"');
						// console.log('@@ allHeaderCells', allHeaderCells);

						allHeaderCells.forEach((cell) => {
							let sortingBtnContainerIsThere =  cell.find('.custom-table-sort-container');

							// if this trait is true, add custom-table-sort-container to all columns
							if (SortingBtnsInAllCols) {
								// if there are no sorting buttons then add them
								if ((sortingBtnContainerIsThere.length === 0)) {
									cell.components().push({type: "custom-table-sort-container"});
								}
							} 
							// else remove if already any custom-table-sort-container & don't add more
							else {
								if ((sortingBtnContainerIsThere.length !== 0)) {
									sortingBtnContainerIsThere.forEach(btn => btn.remove());
								}
							}							
						});
					});
				},

				// to define pagination button
				async Paginate() {
					let { paginationRequired, TotalRows } = this.props();
					let showDBSizeInTableFooter = this.get("showDBSizeInTableFooter");
					let useTablePlottingApiForPagination = this.get("useTablePlottingApiForPagination");
					let numRowsPerPageValue = this.get("numRowsPerPageValue");
					let numColsInputValue = this.get("numColsInputValue");

					let totalRowsAPIValue = this.get("totalRowsAPIValue");
					let totalRowsAPIBaseUrlValue = this.get("totalRowsAPIBaseUrlValue");
					let totalRowsAPIPathValue = this.get("totalRowsAPIPathValue");
					let totalRowsAPIKeynameValue = this.get("totalRowsAPIKeynameValue");
					let totalRowsAPIMethodValue = this.get("totalRowsAPIMethodValue");
					let totalRowsAPIUsernameValue = this.get("totalRowsAPIUsernameValue");
					let totalRowsAPIPasswordValue = this.get("totalRowsAPIPasswordValue");
					let totalRowsAPIBearerTokenValue = this.get("totalRowsAPIBearerTokenValue");
					let totalRowsAPIMoreHeadersValue = this.get("totalRowsAPIMoreHeadersValue");
					let totalRowsAPIBodyValue = this.get("totalRowsAPIBodyValue");
					let totalRowsAPINestedArrOfObjKeynameValue = this.get("totalRowsAPINestedArrOfObjKeynameValue");

					let customUrlValue = this.get("customUrlValue");
					let baseUrlValue = this.get("baseUrlValue");
					let apiPathValue = this.get("apiPathValue");
					let apiMethodValue = this.get("apiMethodValue");
					let apiUsernameValue = this.get("apiUsernameValue");
					let apiPasswordValue = this.get("apiPasswordValue");
					let apiBearerTokenValue = this.get("apiBearerTokenValue");
					let apiMoreHeadersValue = this.get("apiMoreHeadersValue");
					let apiBodyValue = this.get("apiBodyValue");
					let nestedArrOfObjKeynameValue = this.get("nestedArrOfObjKeynameValue");
					// wrapping these values in updatedProps named object because the if cases below are extracting value from updatedProps - so this was a shortcut
					let updatedProps = {
						customUrlValue,
						baseUrlValue,
						apiPathValue,
						apiMethodValue,
						apiUsernameValue,
						apiPasswordValue,
						apiBearerTokenValue,
						apiMoreHeadersValue,
						apiBodyValue,
						nestedArrOfObjKeynameValue,
					};

					console.log("paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, customUrlValue, apiBearerTokenValue, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue, this.props()", paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, customUrlValue, apiBearerTokenValue, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue, this.props());
					console.log("-> customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue", customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue);

					const customTableContainer = this;
					const customTable = customTableContainer.find(".custom-table")[0];
					const customTableThead = customTable.find(".custom-table-thead")[0];
					const customTableTbody = customTable.find(".custom-table-tbody")[0];
					const customTableTfoot = customTable.find(".custom-table-tfoot")[0];

					// make an api call to get the TotalRows
					let totalRows;
					let apiData;
					// let tableName = customUrlValue.split('/');
					// tableName = tableName[tableName.length - 1].trim();
					// console.log('tableName:', tableName);

					// let trResponse = await fetch(`${totalRowsAPIValue}`, {
					// 	headers: {
					//       	"Content-Type": "application/json",
					//     	Authorization: `Bearer ${apiBearerTokenValue}`,
					//     },
					// });
					// let trResponseData = await trResponse.json();
					// totalRows = trResponseData;

					// use total-rows-api if the user has this api, else use the table-plotting api
					if (!useTablePlottingApiForPagination) {
						// if only totalRowsAPIValue is provided and its a GET call
						if (totalRowsAPIValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only totalRowsAPIBaseUrlValue is provided and its a GET call
						else if (!totalRowsAPIValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
							// concatenating base url and its remaining part
							let url, baseUrlBearerToken;
							if (totalRowsAPIBaseUrlValue === "baseUrl1") {
								url = options.baseUrl1 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl1BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
								url = options.baseUrl2 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl2BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
								url = options.baseUrl3 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl3BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
								url = options.baseUrl4 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl4BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
								url = options.baseUrl5 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl5BearerToken;
							}
							console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${baseUrlBearerToken}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only totalRowsAPIValue is provided and its a POST call
						else if (totalRowsAPIValue && totalRowsAPIBodyValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only totalRowsAPIBaseUrlValue is provided and its a POST call
						else if (!totalRowsAPIValue && totalRowsAPIBodyValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
							// concatenating base url and its remaining part
							let url, baseUrlBearerToken;
							if (totalRowsAPIBaseUrlValue === "baseUrl1") {
								url = options.baseUrl1 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl1BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
								url = options.baseUrl2 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl2BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
								url = options.baseUrl3 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl3BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
								url = options.baseUrl4 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl4BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
								url = options.baseUrl5 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl5BearerToken;
							}
							console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${baseUrlBearerToken}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}
					} else {
						// if only custom url is provided and its a GET call
						if (updatedProps.customUrlValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only base url is provided and its a GET call
						else if (!updatedProps.customUrlValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
							// concatenating base url and its remaining part
							let url, baseUrlBearerToken;
							if (updatedProps.baseUrlValue === "baseUrl1") {
								url = options.baseUrl1 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl1BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl2") {
								url = options.baseUrl2 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl2BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl3") {
								url = options.baseUrl3 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl3BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl4") {
								url = options.baseUrl4 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl4BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl5") {
								url = options.baseUrl5 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl5BearerToken;
							}
							console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${baseUrlBearerToken}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only custom url is provided and its a POST call
						else if (updatedProps.customUrlValue && updatedProps.apiBodyValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only base url is provided and its a POST call
						else if (!updatedProps.customUrlValue && updatedProps.apiBodyValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
							// concatenating base url and its remaining part
							let url, baseUrlBearerToken;
							if (updatedProps.baseUrlValue === "baseUrl1") {
								url = options.baseUrl1 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl1BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl2") {
								url = options.baseUrl2 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl2BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl3") {
								url = options.baseUrl3 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl3BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl4") {
								url = options.baseUrl4 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl4BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl5") {
								url = options.baseUrl5 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl5BearerToken;
							}
							console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${baseUrlBearerToken}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}
					}

					console.log("totalRows, apiData:", totalRows, apiData);

					let totalRowsVal;
					try {
						// if (totalRowsAPIKeynameValue && `${totalRowsAPIKeynameValue}` in totalRows) {
						// 	totalRowsVal = totalRows[`${totalRowsAPIKeynameValue}`];
						// } else {
						// 	totalRowsVal = undefined;
						// }

						// if (typeof totalRows === 'object' && !Array.isArray(totalRows)) {
						// 	let nestedObj = this.findKeyValue(totalRows, totalRowsAPINestedArrOfObjKeynameValue);
						// 	console.log('api nestedObj', nestedObj);

						// 	if (totalRowsAPIKeynameValue && `${totalRowsAPIKeynameValue}` in nestedObj) {
						// 		totalRowsVal = nestedObj[`${totalRowsAPIKeynameValue}`];
						// 	}
						// }

						if (typeof totalRows === "object" && !Array.isArray(totalRows) && !apiData) {
							totalRowsVal = this.findKeyValue(totalRows, totalRowsAPIKeynameValue);
						} else if (!totalRows && apiData) {
							// take the length of the response, if the plotting api is provided and not the total-rows api
							console.log('apiData.length', apiData.length);
							totalRowsVal = apiData.length;
						}
					} catch (e) {
						// statements
						console.log(e);
					}

					console.log("totalRowsVal:", totalRowsVal);

					// if pagination is required, add it to the table
					// if (paginationRequired) {
					// 	// if the components are alreaady there, then replace anyway else add them
					// 	if (customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination-previous').length !== 0) {
					// 		customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination-previous')[0].replaceWith({
					// 			tagName: "li",
					// 			attributes: {
					// 				class: "uk-pagination-previous",
					// 			},

					// 			'style-default': {
					// 				'list-style': 'none',
					// 			},

					// 			style: {
					// 				'list-style': 'none',
					// 			},

					// 			components: {
					// 				tagName: "span",

					// 				content: "Previous <",
					// 			},
					// 		});
					// 	} else if (customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination-previous').length === 0) {
					// 		customTableTfoot.find('.custom-table-pagination')[0].components().add({
					// 			tagName: "li",
					// 			attributes: {
					// 				class: "uk-pagination-previous",
					// 			},

					// 			'style-default': {
					// 				'list-style': 'none',
					// 			},

					// 			style: {
					// 				'list-style': 'none',
					// 			},

					// 			components: {
					// 				tagName: "span",

					// 				content: "Previous <",
					// 			},
					// 		}, {at: 0});
					// 	}

					// 	if (customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination').length === 0) {
					// 		console.log('uk1')
					// 		customTableTfoot.find('.custom-table-pagination')[0].append({
					// 			tagName: "ul",
					// 			attributes: {
					// 				class: "uk-pagination uk-pagination-left",
					// 				"data-uk-pagination": `{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}}`,
					// 			},
					// 		});
					// 	} else if (customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination').length !== 0) {
					// 		console.log('uk2')
					// 		// remove the old pagination and then add a new one
					// 		// customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].remove();

					// 		customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].replaceWith({
					// 			tagName: "ul",
					// 			attributes: {
					// 				class: "uk-pagination uk-pagination-left",
					// 				"data-uk-pagination": `{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}}`,
					// 			},
					// 		});

					// 		// // customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].attributes.attributes["data-uk-pagination"] = `{items: ${totalRows ? totalRows : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPage)}}`;
					// 		// console.log('before', customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].getEl());
					// 		// // customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].getAttributes()['data-uk-pagination'] = `{items: ${totalRows ? totalRows : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPage)}}`;
					// 		// customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].setAttributes({'data-uk-pagination': `{items: ${totalRows ? totalRows : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPage)}}`});
					// 		// console.log('after', customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].getEl());
					// 	}

					// 	// if the components are alreaady there, then replace anyway else add them
					// 	if (customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination-next').length !== 0) {
					// 		customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination-next')[0].replaceWith({
					// 			tagName: "li",
					// 			attributes: {
					// 				class: "uk-pagination-next",
					// 			},

					// 			'style-default': {
					// 				'list-style': 'none',
					// 			},

					// 			style: {
					// 				'list-style': 'none',
					// 			},

					// 			components: {
					// 				tagName: "span",

					// 				content: "> Next",
					// 			},
					// 		});
					// 	} else if (customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination-next').length === 0) {
					// 		customTableTfoot.find('.custom-table-pagination')[0].components().add({
					// 			tagName: "li",
					// 			attributes: {
					// 				class: "uk-pagination-next",
					// 			},

					// 			'style-default': {
					// 				'list-style': 'none',
					// 			},

					// 			style: {
					// 				'list-style': 'none',
					// 			},

					// 			components: {
					// 				tagName: "span",

					// 				content: "> Next",
					// 			},
					// 		});
					// 	}
					// }

					if (paginationRequired) {
						console.log("uk");
						// if the components are alreaady there, then replace anyway else add them
						// let customTablePagination = customTableTfoot.find(".custom-table-tfoot-row-for-pagination")[0].find("td")[0].find(".custom-table-pagination")[0];
						const customTableTfootRow = customTableTfoot.find(".custom-table-tfoot-row-for-pagination")[0];
					    console.log("customTableTfootRow:", customTableTfootRow);

					    const tfootTd = customTableTfootRow ? customTableTfootRow.find("td")[0] : null;
					    console.log("tfootTd:", tfootTd);

					    const customTablePagination = tfootTd ? tfootTd.find(".custom-table-pagination")[0] : null;
					    console.log("customTablePagination:", customTablePagination);
					    
						let customTablePaginationContent;

						// if (customTableTfoot.find(".custom-table-pagination")[0].find(".uk-pagination").length === 0) {
						if (customTablePagination && customTablePagination.find(".uk-pagination").length === 0) {
							console.log("uk1");
							// customTableTfoot.find('.custom-table-pagination')[0].append({
							// 	tagName: "ul",
							// 	attributes: {
							// 		class: "uk-pagination uk-pagination-left",
							// 		"data-uk-pagination": `{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}}`,
							// 	},
							// });

							// this way 3 issues are resolving (using set("content")) - 1. next/prev buttons and pagination work in download, 2. pagination belt stays at the bottom of the table, 3. pagination doesn't disappear on component's view rerendering
							customTablePaginationContent = `
								<li class="uk-pagination-previous" style="list-style: none;"><a href="">Previous <</a></li>
								<ul class="uk-pagination uk-pagination-left" data-uk-pagination="{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}}"></ul>
								<li class="uk-pagination-next" style="list-style: none;"><a href="">> Next</a></li>
							`;
							customTablePagination.set("content", customTablePaginationContent);
						} else if (customTablePagination && customTablePagination.find(".uk-pagination").length !== 0) {
							console.log("uk2");
							// remove the old pagination and then add a new one
							// customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].remove();

							// customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].replaceWith({
							// 	tagName: "ul",
							// 	attributes: {
							// 		class: "uk-pagination uk-pagination-left",
							// 		"data-uk-pagination": `{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}}`,
							// 	},
							// });

							customTablePaginationContent = `
								<li class="uk-pagination-previous" style="list-style: none;"><a href="">Previous <</a></li>
								<ul class="uk-pagination uk-pagination-left" data-uk-pagination="{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}}"></ul>
								<li class="uk-pagination-next" style="list-style: none;"><a href="">> Next</a></li>
							`;
							customTablePagination.set("content", customTablePaginationContent);

							// // customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].attributes.attributes["data-uk-pagination"] = `{items: ${totalRows ? totalRows : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPage)}}`;
							// console.log('before', customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].getEl());
							// // customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].getAttributes()['data-uk-pagination'] = `{items: ${totalRows ? totalRows : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPage)}}`;
							// customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].setAttributes({'data-uk-pagination': `{items: ${totalRows ? totalRows : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPage)}}`});
							// console.log('after', customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination')[0].getEl());
						}
					} else {
						// otherwise if not required, hide it
						if (customTableTfoot.find(".custom-table-tfoot-row-for-pagination")[0].find("td")[0].find(".custom-table-pagination")[0].find(".uk-pagination")) {
							console.log("uk3");
							customTableTfoot.find(".custom-table-tfoot-row-for-pagination")[0].find("td")[0].find(".custom-table-pagination")[0].set("content", "");
						}
					}

					// // to make sure to append the "next button" after the pagination
					// let nxtBtn = customTableTfoot.find('.custom-table-pagination')[0].find('.uk-pagination-next')[0];
					// customTableTfoot.find('.custom-table-pagination')[0].components().remove(nxtBtn, { temporary: true }); // temporary option will avoid removing component related styles
					// customTableTfoot.find('.custom-table-pagination')[0].components().add(nxtBtn, { at: 2 });

					// adding total amount in the footer as - x results
					try {
						if (totalRowsVal && showDBSizeInTableFooter) {
							if (customTableTfoot.find(".showDBSizeInTableFooter")[0]) {
								console.log("replacewith");
								// customTableTfoot.find('.showDBSizeInTableFooter')[0].replaceWith(`<p class="showDBSizeInTableFooter">${totalRowsVal} results</p>`);
								// customTableTfoot.find('.custom-table-tfoot-row')[0].replaceWith({
								// 	tagName: "tr",
								// 	type: "custom-table-tfoot-row",
								// 	attributes: {class: "custom-table-tfoot-row"},

								// 	"style-default": {
								// 		width: "100%",
								// 		'background-color': options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : '',
								// 		'background-image': options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : 'linear-gradient(180deg, #714BEE, #005C8F)',
								// 		'border-top': options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : '',
								// 		'border-right': options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : '',
								// 		'border-bottom': options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : '',
								// 		'border-left': options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : '',
								// 	},

								// 	style: {
								// 		width: "100%",
								// 		'background-color': options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : '',
								// 		'background-image': options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : 'linear-gradient(180deg, #714BEE, #005C8F)',
								// 		'border-top': options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : '',
								// 		'border-right': options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : '',
								// 		'border-bottom': options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : '',
								// 		'border-left': options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : '',
								// 	},

								// 	components: [
								// 		{
								// 			tagName: "td",
								// 			editable: true,
								// 			attributes: {colspan: `${numColsInputValue}`},

								// 			"style-default": {
								// 				'font-size': options.table_constants ? options.table_constants.tableTfootFontSize : '',
								// 				'font-color': options.table_constants ? options.table_constants.tableTfootFontColor : '',
								// 				'font-family': options.table_constants ? options.table_constants.tableTfootFontFamily : '',
								// 			},

								// 			style: {
								// 				'font-size': options.table_constants ? options.table_constants.tableTfootFontSize : '',
								// 				'font-color': options.table_constants ? options.table_constants.tableTfootFontColor : '',
								// 				'font-family': options.table_constants ? options.table_constants.tableTfootFontFamily : '',
								// 			},

								// 			components: [
								// 				{
								// 		            tagName: "p",
								// 		            type: "text",
								// 		            editable: true,
								// 		            attributes: {class: "showDBSizeInTableFooter"},

								// 		            "style-default": {
								// 						padding: "8px",
								// 						'border-left': '1px solid black',
								// 						'border-right': '1px solid black',
								// 					},

								// 					style: {
								// 						padding: "8px",
								// 						'border-left': '1px solid black',
								// 						'border-right': '1px solid black',
								// 					},

								// 		            components: {
								// 		                type: "textnode",
								// 		                content: `${totalRowsVal} results`,
								// 		            },
								// 		        },
								// 			],
								// 		},
								// 	],
								// });

								customTableTfoot
									.find(".custom-table-tfoot-row")[0]
									.find("td")[0]
									.find(".showDBSizeInTableFooter")[0]
									.replaceWith({
										tagName: "p",
										type: "text",
										editable: true,
										attributes: { class: "showDBSizeInTableFooter" },

										"style-default": {
											// padding: "8px",
											"border-left": "1px solid black",
											"border-right": "1px solid black",
											"background-color": "inherit",
											background: "inherit",
											"text-align": "inherit",
												
											"margin-top": this.get("tableTfootTotalRowsMarginTop") ? `${this.get("tableTfootTotalRowsMarginTop")}vh` : 0,
											"margin-right": this.get("tableTfootTotalRowsMarginRight") ? `${this.get("tableTfootTotalRowsMarginRight")}vw` : 0,
											"margin-bottom": this.get("tableTfootTotalRowsMarginBottom") ? `${this.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
											"margin-left": this.get("tableTfootTotalRowsMarginLeft") ? `${this.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
										},

										style: {
											// padding: "8px",
											"border-left": "1px solid black",
											"border-right": "1px solid black",
											"background-color": "inherit",
											background: "inherit",
											"text-align": "inherit",
											
											"margin-top": this.get("tableTfootTotalRowsMarginTop") ? `${this.get("tableTfootTotalRowsMarginTop")}vh` : 0,
											"margin-right": this.get("tableTfootTotalRowsMarginRight") ? `${this.get("tableTfootTotalRowsMarginRight")}vw` : 0,
											"margin-bottom": this.get("tableTfootTotalRowsMarginBottom") ? `${this.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
											"margin-left": this.get("tableTfootTotalRowsMarginLeft") ? `${this.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
										},

										components: {
											type: "textnode",
											content: `${totalRowsVal} results`,
										},
									});
							} else {
								console.log("append");
								// customTableTfoot.append(`<tr><td colspan="${numColsInputValue}"><p class="showDBSizeInTableFooter">${totalRowsVal} results</p></td></tr>`);
								customTableTfoot.append({
									tagName: "tr",
									type: "custom-table-tfoot-row",
									attributes: { class: "custom-table-tfoot-row" },

									"style-default": {
										width: "100%",
										"background-color": this.get("tableTfootRowEnableBackgroundColor") ? this.get("tableTfootRowBackgroundColor") : "lightgray",
										"background-image": this.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${this.get("tableTfootRowLinearGradient1")}, ${this.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
										"border-top": this.get("tableTfootRowBorderTopWidth") || this.get("tableTfootRowBorderTopType") || this.get("tableTfootRowBorderTopColor") ? `${this.get("tableTfootRowBorderTopWidth")}px ${this.get("tableTfootRowBorderTopType")} ${this.get("tableTfootRowBorderTopColor")}` : "",
										"border-right": this.get("tableTfootRowBorderRightWidth") || this.get("tableTfootRowBorderRightType") || this.get("tableTfootRowBorderRightColor") ? `${this.get("tableTfootRowBorderRightWidth")}px ${this.get("tableTfootRowBorderRightType")} ${this.get("tableTfootRowBorderRightColor")}` : "",
										"border-bottom": this.get("tableTfootRowBorderBottomWidth") || this.get("tableTfootRowBorderBottomType") || this.get("tableTfootRowBorderBottomColor") ? `${this.get("tableTfootRowBorderBottomWidth")}px ${this.get("tableTfootRowBorderBottomType")} ${this.get("tableTfootRowBorderBottomColor")}` : "",
										"border-left": this.get("tableTfootRowBorderLeftWidth") || this.get("tableTfootRowBorderLeftType") || this.get("tableTfootRowBorderLeftColor") ? `${this.get("tableTfootRowBorderLeftWidth")}px ${this.get("tableTfootRowBorderLeftType")} ${this.get("tableTfootRowBorderLeftColor")}` : "",
									},

									style: {
										width: "100%",
										"background-color": this.get("tableTfootRowEnableBackgroundColor") ? this.get("tableTfootRowBackgroundColor") : "lightgray",
										"background-image": this.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${this.get("tableTfootRowLinearGradient1")}, ${this.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
										"border-top": this.get("tableTfootRowBorderTopWidth") || this.get("tableTfootRowBorderTopType") || this.get("tableTfootRowBorderTopColor") ? `${this.get("tableTfootRowBorderTopWidth")}px ${this.get("tableTfootRowBorderTopType")} ${this.get("tableTfootRowBorderTopColor")}` : "",
										"border-right": this.get("tableTfootRowBorderRightWidth") || this.get("tableTfootRowBorderRightType") || this.get("tableTfootRowBorderRightColor") ? `${this.get("tableTfootRowBorderRightWidth")}px ${this.get("tableTfootRowBorderRightType")} ${this.get("tableTfootRowBorderRightColor")}` : "",
										"border-bottom": this.get("tableTfootRowBorderBottomWidth") || this.get("tableTfootRowBorderBottomType") || this.get("tableTfootRowBorderBottomColor") ? `${this.get("tableTfootRowBorderBottomWidth")}px ${this.get("tableTfootRowBorderBottomType")} ${this.get("tableTfootRowBorderBottomColor")}` : "",
										"border-left": this.get("tableTfootRowBorderLeftWidth") || this.get("tableTfootRowBorderLeftType") || this.get("tableTfootRowBorderLeftColor") ? `${this.get("tableTfootRowBorderLeftWidth")}px ${this.get("tableTfootRowBorderLeftType")} ${this.get("tableTfootRowBorderLeftColor")}` : "",
									},

									components: [
										{
											tagName: "td",
											editable: true,
											attributes: { colspan: `${numColsInputValue}` },

											"style-default": {
												"font-size": this.get("tableTfootFontSize") ? `${this.get("tableTfootFontSize")}vw` : "",
												color: this.get("tableTfootFontColor") ? this.get("tableTfootFontColor") : "",
												"font-family": this.get("tableTfootFontFamily") ? this.get("tableTfootFontFamily") : "",
												
												"padding-top": this.get("tableTfootCellPaddingTop") ? `${this.get("tableTfootCellPaddingTop")}vh` : 0,
												"padding-right": this.get("tableTfootCellPaddingRight") ? `${this.get("tableTfootCellPaddingRight")}vw` : 0,
												"padding-bottom": this.get("tableTfootCellPaddingBottom") ? `${this.get("tableTfootCellPaddingBottom")}vh` : 0,
												"padding-left": this.get("tableTfootCellPaddingLeft") ? `${this.get("tableTfootCellPaddingLeft")}vw` : 0,
											},

											style: {
												"font-size": this.get("tableTfootFontSize") ? `${this.get("tableTfootFontSize")}vw` : "",
												color: this.get("tableTfootFontColor") ? this.get("tableTfootFontColor") : "",
												"font-family": this.get("tableTfootFontFamily") ? this.get("tableTfootFontFamily") : "",
												
												"padding-top": this.get("tableTfootCellPaddingTop") ? `${this.get("tableTfootCellPaddingTop")}vh` : 0,
												"padding-right": this.get("tableTfootCellPaddingRight") ? `${this.get("tableTfootCellPaddingRight")}vw` : 0,
												"padding-bottom": this.get("tableTfootCellPaddingBottom") ? `${this.get("tableTfootCellPaddingBottom")}vh` : 0,
												"padding-left": this.get("tableTfootCellPaddingLeft") ? `${this.get("tableTfootCellPaddingLeft")}vw` : 0,
											},

											components: [
												{
													tagName: "p",
													type: "text",
													editable: true,
													attributes: { class: "showDBSizeInTableFooter" },

													"style-default": {
														// padding: "8px",
														"border-left": "1px solid black",
														"border-right": "1px solid black",
												
														"margin-top": this.get("tableTfootTotalRowsMarginTop") ? `${this.get("tableTfootTotalRowsMarginTop")}vh` : 0,
														"margin-right": this.get("tableTfootTotalRowsMarginRight") ? `${this.get("tableTfootTotalRowsMarginRight")}vw` : 0,
														"margin-bottom": this.get("tableTfootTotalRowsMarginBottom") ? `${this.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
														"margin-left": this.get("tableTfootTotalRowsMarginLeft") ? `${this.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
													},

													style: {
														// padding: "8px",
														"border-left": "1px solid black",
														"border-right": "1px solid black",
												
														"margin-top": this.get("tableTfootTotalRowsMarginTop") ? `${this.get("tableTfootTotalRowsMarginTop")}vh` : 0,
														"margin-right": this.get("tableTfootTotalRowsMarginRight") ? `${this.get("tableTfootTotalRowsMarginRight")}vw` : 0,
														"margin-bottom": this.get("tableTfootTotalRowsMarginBottom") ? `${this.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
														"margin-left": this.get("tableTfootTotalRowsMarginLeft") ? `${this.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
													},

													components: {
														type: "textnode",
														content: `${totalRowsVal} results`,
													},
												},
											],
										},
									],
								});
							}
						} else {
							console.log("customTableTfoot ^", customTableTfoot, this);
							if (customTableTfoot.find(".custom-table-tfoot-row")[0]) {
								customTableTfoot.find(".custom-table-tfoot-row")[0].remove();
							}
						}
					} catch (e) {
						// statements
						console.log("custom-table-tfoot-row error", e);
					}

					// to rerender the view for the updates to show, like pagination changes, result changes, etc
					this.view.render();
				},

				// helper function to get value of a key in nested object
				findKeyValue(obj, key) {
					if (obj.hasOwnProperty(key)) {
						// If the current object has the key, return its value
						return obj[key];
					}

					for (var prop in obj) {
						if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
							// If the current property is an object, recursively search within it
							var result = findKeyValue(obj[prop], key);
							if (result !== undefined) {
								return result;
							}
						}
					}
					// Key not found in the object
					return undefined;
				},

				// triggers a component-update everytime a model property is updated
				updated(property, value, prevValue) {
					console.log("+++ property", property, "value", value, "prevValue", prevValue);
					console.log("this from updated()", this.getEl());

					// if any of the HeaderField_i property is updated, run the script to make sure it receives the updated properties
					if (property.includes("HeaderField_")) {
						this.attributes.script.call(this.getEl(), this.props());
						console.log("updated() model props ***", this.props());
						console.log("ran script from updated()");
					}
				},

				removed() {
					// sessionStorage.removeItem("updatedProps");

					// only remove keys related to this component
					for (let i = 0; i < sessionStorage.length; i++) {
						const key = sessionStorage.key(i);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes("_selected_column")) {
							sessionStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let j = 0; j < localStorage.length; j++) {
						const key = localStorage.key(j);

						// Check if the key contains the substring '_selected_column'
						if (key && (key.includes("custom_table_action_button_") || key.includes("clicked_stellar_button_container_table"))) {
							localStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let k = 0; k < localStorage.length; k++) {
						const key = localStorage.key(k);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes("clicked_input_file_container_table")) {
							localStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let l = 0; l < localStorage.length; l++) {
						const key = localStorage.key(l);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes("clicked_radio_regular_form_table")) {
							localStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let m = 0; m < localStorage.length; m++) {
						const key = localStorage.key(m);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes("clicked_checkbox_regular_form_table")) {
							localStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let n = 0; n < localStorage.length; n++) {
						const key = localStorage.key(n);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes("clicked_input_select_table")) {
							localStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let n = 0; n < localStorage.length; n++) {
						const key = localStorage.key(n);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes("clicked_form_text_field_table")) {
							localStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let o = 0; o < localStorage.length; o++) {
						const key = localStorage.key(o);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes("clicked_send_email_button_container_table")) {
							localStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let o = 0; o < localStorage.length; o++) {
						const key = localStorage.key(o);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes("normal_text_area_field_table")) {
							localStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let o = 0; o < localStorage.length; o++) {
						const key = localStorage.key(o);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes("clicked_custom_table_row_selection_checkbox_table")) {
							localStorage.removeItem(key);
						}
					}

					// only remove keys related to this component
					for (let p = 0; p < sessionStorage.length; p++) {
						const key = sessionStorage.key(p);

						// Check if the key contains the substring '_selected_column'
						if (key && key.includes(`updatedProps_${this.attributes.attributes.id}`)) {
							sessionStorage.removeItem(key);
						}
					}

					// sessionStorage.clear();
				},
			},

			view: {
				events: {
					"click .customize-table-btn": "openModal",
				},

				openModal(event) {
					event.preventDefault();
					event.stopPropagation();

					console.log("**model**", this.model);

					const form = document.createElement("div");
					form.setAttribute("class", "custom-table-form");

					form.innerHTML = `
                    	<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item" role="presentation">
							    <button class="nav-link active" id="table-structure-tab" data-bs-toggle="tab" data-bs-target="#table-structure-tab-pane" type="button" role="tab" aria-controls="table-structure-tab-pane" aria-selected="true">Table Structure</button>
							</li>
							
							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="api-table-plotting-tab" data-bs-toggle="tab" data-bs-target="#api-table-plotting-tab-pane" type="button" role="tab" aria-controls="api-table-plotting-tab-pane" aria-selected="false">API Table Plotting</button>
							</li>
							
							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="api-table-updating-tab" data-bs-toggle="tab" data-bs-target="#api-table-updating-tab-pane" type="button" role="tab" aria-controls="api-table-updating-tab-pane" aria-selected="false">API Table Updating</button>
							</li>
							
							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="editable-api-fields-tab" data-bs-toggle="tab" data-bs-target="#editable-api-fields-tab-pane" type="button" role="tab" aria-controls="editable-api-fields-tab-pane" aria-selected="false">Define Editable API Fields</button>
							</li>
							
							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="total-rows-api-tab" data-bs-toggle="tab" data-bs-target="#total-rows-api-tab-pane" type="button" role="tab" aria-controls="total-rows-api-tab-pane" aria-selected="false">Total Rows, API</button>
							</li>
							
							<li class="nav-item" role="presentation">
							    <button class="nav-link" id="table-styling-tab" data-bs-toggle="tab" data-bs-target="#table-styling-tab-pane" type="button" role="tab" aria-controls="table-styling-tab-pane" aria-selected="false">Table Styling</button>
							</li>
						</ul>

						<div class="tab-content" id="myTabContent">
						  	<div class="tab-pane fade show active" id="table-structure-tab-pane" role="tabpanel" aria-labelledby="table-structure-tab" tabindex="0">
						  		<br>

						  		<!-- number of columns -->
		                        <div>
		                        	<span>
		                        		<label for="numCols" title="Note: This will replace the existing columns and add new columns">Number of columns/Append columns</label>
		                        	</span>		                            
		                            <input type="number" name="numCols" id="numCols" class="numCols" placeholder="Columns" min="1">
		                        	
		                        	<!-- submit button -->
		                        	<button class="num-cols-table-btn">Apply</button>
		                        </div>     

						  		<!-- number of rows per page -->
		                        <div>
		                        	<span>
		                            	<label for="numRowsPerPage">Number of rows per page</label>
		                            </span>
		                            <input type="number" name="numRowsPerPage" id="numRowsPerPage" class="numRowsPerPage" min="1">
		                        	
		                        	<!-- submit button -->
		                        	<button class="num-rows-per-page-btn">Apply</button>
		                        </div>                       

				            	<!-- add column at index -->
		                        <!-- <div> -->
		                        	<!-- <small><i>Indexing starts from 0</i></small> -->
		                        	<!-- <br> -->
		                            <!-- <label for="addColAtIdx" title="Note: Indexing starts from 0">Add a column at index</label> -->
		                            <!-- <input type="number" name="addColAtIdx" id="addColAtIdx" class="addColAtIdx" placeholder="Column index" min="0"> -->
		                        	<!-- submit button -->
		                        	<!-- <button class="insert-cols-table-btn">Apply</button> -->
		                        <!-- </div> -->     

						  		<!-- keyname for data-db-id from data table -->
		                        <div>
		                        	<span>
		                            	<label for="dataModelObjIdKeyname">Data model's object ID keyname</label>
		                            </span>
		                            <input type="text" name="dataModelObjIdKeyname" id="dataModelObjIdKeyname" class="dataModelObjIdKeyname">
		                        	
		                        	<!-- submit button -->
		                        	<button class="dataModelObjIdKeyname-btn">Apply</button>
		                        </div> 

		                        <!-- Should footer columns be dynamic or static (not dynamic) -->
		                        <div>
		                        	<span>
		                            	<label for="dynamicTableFooterColumns" title="Note: If table footer columns are dynamic, then if you move a column in header to left/right, if will move the footers columns accordingly.">Allow table footer columns to be dynamic</label>
		                            </span>
		                            <input type="checkbox" name="dynamicTableFooterColumns" id="dynamicTableFooterColumns" class="dynamicTableFooterColumns">
		                        </div>

		                        <!-- Should the 'x results' be shown in the table footer -->
		                        <div>
		                        	<span>
		                            	<label for="showDBSizeInTableFooter" title="Note: If this is checked, it will display the dataset size in the table footer.">Show dataset size in footer</label>
		                            </span>
		                            <input type="checkbox" name="showDBSizeInTableFooter" id="showDBSizeInTableFooter" class="showDBSizeInTableFooter">
		                        </div>

		                        <!-- Total Rows, API - Not Available -->
		                        <div>
		                        	<span>
		                            	<label for="useTablePlottingApiForPagination" title="Note: If total-rows API is not available, the table will use the plotting API to calculate total rows in the data for pagination">Use table plotting API to initialize pagination</label>
		                            </span>
		                            <input type="checkbox" name="useTablePlottingApiForPagination" id="useTablePlottingApiForPagination" class="useTablePlottingApiForPagination">
		                        </div>

		                        <!-- Put sorting buttons in all columns or not -->
		                        <div>
		                        	<span>
		                            	<label for="SortingBtnsInAllCols" title="Note: Check this checkbox to add sorting buttons to all columns of this table and uncheck it to remove all the sorting buttons if already added in the columns.">Add Sorting buttons to all columns of the table</label>
		                            </span>
		                            <input type="checkbox" name="SortingBtnsInAllCols" id="SortingBtnsInAllCols" class="SortingBtnsInAllCols">
		                        </div>

								<!-- Highlight the row selected by stellar button with lightgray backgroun-color -->
		                        <div>
		                        	<span>
		                            	<label for="rowHighlightOnBtnClick" title="Note: This will disable the hover functionality of the table body.">Highlight Table Row If A Stellar-Button Is Clicked</label>
		                            </span>
		                            <input type="checkbox" name="rowHighlightOnBtnClick" id="rowHighlightOnBtnClick" class="rowHighlightOnBtnClick">
		                        </div>

		                        <!-- what reference labels to show in the edit-modal -->
		                        <div>
		                        	<span>
		                            	<label for="referenceAttributeLabels" title="These labels will be shown in the edit-modal of the rows. Format - Table_name1: x1, y1; Table_name2: x2, y2; Table_name3: x3, y3">Reference attribute labels</label>
		                            </span>
		                            <input type="text" name="referenceAttributeLabels" id="referenceAttributeLabels" class="referenceAttributeLabels" placeholder="Table_name1: x1, y1; Table_name2: x2, y2; Table_name3: x3, y3">
		                        	
		                        	<!-- submit button -->
		                        	<button class="referenceAttributeLabels-btn">Apply</button>
		                        </div> 
						  	</div>

						  	<div class="tab-pane fade" id="api-table-plotting-tab-pane" role="tabpanel" aria-labelledby="api-table-plotting-tab" tabindex="0">
						  		<br>
						  		
						  		<!-- custom url -->
		                        <div>
		                        	<span>
		                            	<label for="custom-url">Custom URL</label>
		                            </span>
		                            <input type="text" name="custom-url" id="custom-url" class="custom-url" placeholder="A complete API URL">
		                        </div>

		                        <!-- base urls -->
		                        <div>
		                        	<span>
		                            	<label for="base-url">Base URL</label>
		                            </span>
		                            <select name="base-url" id="base-url" class="base-url">
		                            	<option value="baseUrl1">Base URL #1</option>
		                            	<option value="baseUrl2">Base URL #2</option>
		                            	<option value="baseUrl3">Base URL #3</option>
		                            	<option value="baseUrl4">Base URL #4</option>
		                            	<option value="baseUrl5">Base URL #5</option>
		                            	<option value="null">No Base URL</option>
		                            </select>
		                        </div>

		                        <!-- api path -->
		                        <div>
		                        	<span>
		                            	<label for="api-path" title="Don't start with '/'">API Path</label>
		                            </span>
		                            <input type="text" name="api-path" id="api-path" class="api-path" placeholder="Remaining API path of the Base URL">
		                        </div>

		                        <!-- api method -->
		                        <div>
		                        	<span>
		                            	<label for="api-method">*API Method</label>
		                            </span>
		                            <select name="api-method" id="api-method" class="api-method">
		                            	<option value="GET" selected>GET</option>
		                            	<option value="POST">POST</option>
		                            </select>
		                        </div>

		                        <!-- api username -->
		                        <div>
		                        	<span>
		                            	<label for="api-username">API Username</label>
		                            </span>
		                            <input type="text" name="api-username" id="api-username" class="api-username" placeholder="Username for API authentication">
		                        </div>

		                        <!-- api password -->
		                        <div>
		                        	<span>
		                            	<label for="api-password">API Password</label>
		                            </span>
		                            <input type="text" name="api-password" id="api-password" class="api-password" placeholder="Password for API authentication">
		                        </div>

		                        <!-- api bearer token -->
		                        <div>
		                        	<span>
		                            	<label for="api-bearer-token" title="Note: Provide it without the word 'Bearer'">Bearer Token</label>
		                            </span>
		                            <input type="text" name="api-bearer-token" id="api-bearer-token" class="api-bearer-token" placeholder="Bearer token for Custom URL">
		                        </div>

		                        <!-- api more headers -->
		                        <div>
		                        	<span>
		                            	<label for="api-more-headers" title="Note: With/Without spaces after commas">More Headers</label>
		                            </span>
		                            <input type="text" name="api-more-headers" id="api-more-headers" class="api-more-headers" placeholder="k1:v1,k2:v2,k3:v3,...">
		                        </div>

		                        <!-- api body -->
		                        <div>
		                        	<span>
		                            	<label for="api-body" title="Note: API body content for a POST call">API Body</label>
		                            </span>
		                            <textarea id="api-body" name="api-body" class="api-body" rows="4" cols="50"></textarea>
		                        </div>

		                        <!-- nested array-of-objects keyname -->
		                        <div>
		                        	<span>
		                            	<label for="nested-array-of-objects-keyname" title="kename for nested array-of-objects in API response">Keyname for nested array-of-objects</label>
		                            </span>
		                            <input type="text" name="nested-array-of-objects-keyname" id="nested-array-of-objects-keyname" class="nested-array-of-objects-keyname" placeholder="k in { k : [{k1:v1}, {k2, v2}] }">
		                        </div>

		                        <!-- submit button -->
		                        <button class="plot-table-btn">Plot</button>
						  	</div>

						  	<div class="tab-pane fade" id="api-table-updating-tab-pane" role="tabpanel" aria-labelledby="api-table-updating-tab" tabindex="0">
						  		<br>
						  		
						  		<!-- custom url -->
		                        <div>
		                        	<span>
		                            	<label for="api-updating-custom-url">Custom URL</label>
		                            </span>
		                            <input type="text" name="api-updating-custom-url" id="api-updating-custom-url" class="api-updating-custom-url" placeholder="A complete API URL">
		                        </div>

		                        <!-- base urls -->
		                        <div>
		                        	<span>
		                            	<label for="api-updating-base-url">Base URL</label>
		                            </span>
		                            <select name="api-updating-base-url" id="api-updating-base-url" class="api-updating-base-url">
		                            	<option selected disabled>--select a base URL--</option>
		                            	<option value="baseUrl1">Base URL #1</option>
		                            	<option value="baseUrl2">Base URL #2</option>
		                            	<option value="baseUrl3">Base URL #3</option>
		                            	<option value="baseUrl4">Base URL #4</option>
		                            	<option value="baseUrl5">Base URL #5</option>
		                            	<option value="null">No Base URL</option>
		                            </select>
		                        </div>

		                        <!-- api path -->
		                        <div>
		                        	<span>
		                            	<label for="api-updating-api-path" title="Don't start with '/'">API Path</label>
		                            </span>
		                            <input type="text" name="api-updating-api-path" id="api-updating-api-path" class="api-updating-api-path" placeholder="Remaining API path of the Base URL">
		                        </div>

		                        <!-- query parameter -->
		                        <div>
		                        	<span>
		                            	<label for="api-updating-query-parameter" title="The query parameter that is used in a PUT call. It is attached after a ''?" at the end of the main URL.>Query Parameter</label>
		                            </span>
		                            <input type="text" name="api-updating-query-parameter" id="api-updating-query-parameter" class="api-updating-query-parameter" placeholder="url?queryparameter='...'">
		                        </div>

		                        <!-- table attribute -->
		                        <div>
		                        	<span>
		                            	<label for="api-updating-table-attribute" title="The table attribute is the key name that is passed in the API body of a PUT call.">Table Attribute</label>
		                            </span>
		                            <input type="text" name="api-updating-table-attribute" id="api-updating-table-attribute" class="api-updating-table-attribute" placeholder="{'to_insert': {'the_table_attribute': 'value'}}">
		                        </div>

		                        <!-- api method -->
		                        <div>
		                        	<span>
		                            	<label for="api-method">*API Method</label>
		                            </span>
		                            <select name="api-updating-api-method" id="api-updating-api-method" class="api-updating-api-method">
		                            	<option value="PUT" selected>PUT</option>
		                            	<option value="POST">POST</option>
		                            </select>
		                        </div>

		                        <!-- api username -->
		                        <div>
		                        	<span>
		                            	<label for="api-updating-api-username">API Username</label>
		                            </span>
		                            <input type="text" name="api-updating-api-username" id="api-updating-api-username" class="api-updating-api-username" placeholder="Username for API authentication">
		                        </div>

		                        <!-- api password -->
		                        <div>
		                        	<span>
		                            	<label for="api-updating-api-password">API Password</label>
		                            </span>
		                            <input type="text" name="api-updating-api-password" id="api-updating-api-password" class="api-updating-api-password" placeholder="Password for API authentication">
		                        </div>

		                        <!-- api bearer token -->
		                        <div>
		                        	<span>
		                            	<label for="api-updating-api-bearer-token" title="Note: Provide it without the word 'Bearer'">Bearer Token</label>
		                            </span>
		                            <input type="text" name="api-updating-api-bearer-token" id="api-updating-api-bearer-token" class="api-updating-api-bearer-token" placeholder="Bearer token for Custom URL">
		                        </div>

		                        <!-- api more headers -->
		                        <div>
		                        	<span>
		                            	<label for="api-updating-api-more-headers" title="Note: With/Without spaces after commas">More Headers</label>
		                            </span>
		                            <input type="text" name="api-updating-api-more-headers" id="api-updating-api-more-headers" class="api-updating-api-more-headers" placeholder="k1:v1,k2:v2,k3:v3,...">
		                        </div>

		                        <!-- submit button -->
		                        <button class="update-table-btn">Save</button>
						  	</div>

						  	<div class="tab-pane fade" id="editable-api-fields-tab-pane" role="tabpanel" aria-labelledby="editable-api-fields-tab" tabindex="0">
						  		<br>

						  		<!-- Search API fields -->
						  		<div class="search-editable-api-fields-container">
						  			<label title="Press [Enter] key to search | Format for references: <table name> - <field name>">Search</label>

						  			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
									  	<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
									</svg>
						  			
						  			<input type="text" id="search-editable-api-fields" class="search-editable-api-fields" name="search-editable-api-fields" placeholder="Search by a field/column name">
						  		</div>
						  		
						  		<!-- API fields -->
						  		<div class="editable-api-fields"></div>

		                        <!-- submit button -->
		                        <button class="editable-api-fields-btn">Apply</button>
						  	</div>

						  	<div class="tab-pane fade" id="total-rows-api-tab-pane" role="tabpanel" aria-labelledby="total-rows-api-tab" tabindex="0">
						  		<br>

		                        <!-- total rows api -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api" title="Note: Provide an API that returns the total rows in the main API data">Total Rows API</label>
		                            </span>
		                            <input type="text" name="total-rows-api" id="total-rows-api" class="total-rows-api">
		                        </div>

		                        <!-- total rows api base urls -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-base-url">Base URL</label>
		                            </span>
		                            <select name="total-rows-api-base-url" id="total-rows-api-base-url" class="total-rows-api-base-url">
		                            	<option value="baseUrl1">Base URL #1</option>
		                            	<option value="baseUrl2">Base URL #2</option>
		                            	<option value="baseUrl3">Base URL #3</option>
		                            	<option value="baseUrl4">Base URL #4</option>
		                            	<option value="baseUrl5">Base URL #5</option>
		                            	<option value="null">No Base URL</option>
		                            </select>
		                        </div>

		                        <!-- total rows api keyname -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-keyname" title="Note: Provide a keyname from Total-Rows-API response that holds the amount. Example - k in { "k" : 30 ]">Total Rows API Keyname</label>
		                            </span>
		                            <input type="text" name="total-rows-api-keyname" id="total-rows-api-keyname" class="total-rows-api-keyname">
		                        </div>

		                        <!-- api path -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-path" title="Don't start with '/'">API Path</label>
		                            </span>
		                            <input type="text" name="total-rows-api-path" id="total-rows-api-path" class="total-rows-api-path" placeholder="Remaining API path of the Base URL">
		                        </div>

		                        <!-- api method -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-method">*API Method</label>
		                            </span>
		                            <select name="total-rows-api-method" id="total-rows-api-method" class="total-rows-api-method">
		                            	<option value="GET" selected>GET</option>
		                            	<option value="POST">POST</option>
		                            </select>
		                        </div>

		                        <!-- api username -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-username">Total Rows, API Username</label>
		                            </span>
		                            <input type="text" name="total-rows-api-username" id="total-rows-api-username" class="total-rows-api-username" placeholder="Username for API authentication">
		                        </div>

		                        <!-- api password -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-password">Total Rows, API Password</label>
		                            </span>
		                            <input type="text" name="total-rows-api-password" id="total-rows-api-password" class="total-rows-api-password" placeholder="Password for API authentication">
		                        </div>

		                        <!-- api bearer token -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-bearer-token" title="Note: Provide it without the word 'Bearer'">Total Rows, API Bearer Token</label>
		                            </span>
		                            <input type="text" name="total-rows-api-bearer-token" id="total-rows-api-bearer-token" class="total-rows-api-bearer-token" placeholder="Bearer token for Total Rows, API">
		                        </div>

		                        <!-- api more headers -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-more-headers" title="Note: With/Without spaces after commas">More Headers</label>
		                            </span>
		                            <input type="text" name="total-rows-api-more-headers" id="total-rows-api-more-headers" class="total-rows-api-more-headers" placeholder="k1:v1,k2:v2,k3:v3,...">
		                        </div>

		                        <!-- api body -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-body" title="Note: API body content for a POST call">API Body</label>
		                            </span>
		                            <textarea id="total-rows-api-body" name="total-rows-api-body" class="total-rows-api-body" rows="4" cols="50"></textarea>
		                        </div>

		                        <!-- nested array-of-objects keyname -->
		                        <div>
		                        	<span>
		                            	<label for="total-rows-api-nested-array-of-objects-keyname" title="kename for nested array-of-objects in API response">Keyname for nested array-of-objects</label>
		                            </span>
		                            <input type="text" name="total-rows-api-nested-array-of-objects-keyname" id="total-rows-api-nested-array-of-objects-keyname" class="total-rows-api-nested-array-of-objects-keyname" placeholder="k in { k : [{k1:v1}, {k2, v2}] }">
		                        </div>

		                        <!-- submit button -->
		                        <button class="store-total-rows-api-btn">Apply</button>
						  	</div>

						  	<div class="tab-pane fade" id="table-styling-tab-pane" role="tabpanel" aria-labelledby="table-styling-tab" tabindex="0">
						  		<br>

						  		<span>
							  		<div class="thead-styling-props">
							  			<h5>Header</h5>
								  		<!-- header font-size -->
				                        <div>
				                        	<span>
				                            	<label for="table-thead-font-size" title="The unit is (vw); E.g., 2vw, 5vw">Font size, for header text (in vw)</label>
				                            </span>
				                            <input type="number" name="table-thead-font-size" id="table-thead-font-size" class="table-thead-font-size" placeholder="Enter a value">
				                        </div>

				                        <!-- header font color -->
				                        <div>
				                        	<span>
				                            	<label for="table-thead-font-color">Font color, for header text</label>
				                            </span>
				                            <input type="color" name="table-thead-font-color" id="table-thead-font-color" class="table-thead-font-color" value="#ffffff">
				                        </div>

				                        <!-- header font-family -->
				                        <div>
				                        	<span>
					                        	<label for="table-thead-font-family" title="Select Font Family">Font Family, for header text</label>
									        </span>
									        <select name="table-thead-font-family" id="table-thead-font-family" class="table-thead-font-family">
									            <option value="Arial, sans-serif">Arial</option>
											    <option value="Helvetica, sans-serif">Helvetica</option>
											    <option value="Times New Roman, serif">Times New Roman</option>
											    <option value="Times, serif">Times</option>
											    <option value="Courier New, monospace">Courier New</option>
											    <option value="Courier, monospace">Courier</option>
											    <option value="Verdana, sans-serif">Verdana</option>
											    <option value="Georgia, serif">Georgia</option>
											    <option value="Palatino, serif">Palatino</option>
											    <option value="Garamond, serif">Garamond</option>
											    <option value="Bookman, serif">Bookman</option>
											    <option value="Comic Sans MS, cursive">Comic Sans MS</option>
											    <option value="Trebuchet MS, sans-serif">Trebuchet MS</option>
											    <option value="Arial Black, sans-serif">Arial Black</option>
											    <option value="Impact, sans-serif">Impact</option>
									        </select>
				                        </div>			                        

								        <!-- header background-color -->
				                        <div>
				                        	<span>
				                            	<label for="table-thead-row-background-color">Background color, for header row</label>
				                            </span>
				                            <input type="checkbox" name="table-thead-row-enable-background-color" id="table-thead-row-enable-background-color" class="table-thead-row-enable-background-color">
				                            <input type="color" name="table-thead-row-background-color" id="table-thead-row-background-color" class="table-thead-row-background-color" value="#808080">
				                        </div>

				                        <!-- header linear-gradient -->
				                        <div>
				                        	<span>
				                            	<label for="table-thead-row-linear-gradient-1">Linear gradient, for header row</label>
				                            </span>
				                            <input type="checkbox" name="table-thead-row-enable-linear-gradient-1" id="table-thead-row-enable-linear-gradient-1" class="table-thead-row-enable-linear-gradient-1">
				                            <input type="color" name="table-thead-row-linear-gradient-1" id="table-thead-row-linear-gradient-1" class="table-thead-row-linear-gradient-1" value="#A020F0">
				                            <input type="color" name="table-thead-row-linear-gradient-2" id="table-thead-row-linear-gradient-2" class="table-thead-row-linear-gradient-2" value="#0000FF">				                        	
				                        </div>
				                       <div>
				                        	<span>
				                        		<label for="table-thead-row-linear-gradient-angle">Gradient Angle</label>
	  										</span>
	  										<input type="number" id="table-thead-row-linear-gradient-angle" name="table-thead-row-linear-gradient-angle" class="table-thead-row-linear-gradient-angle" value="90" min="0" max="360" step="1">
				                        </div>

				                        <!-- header border-top -->
				                        <div>
				                        	<span>
				                        		<label for="table-thead-row-border-top-width" title="The unit is (px); E.g., 2px, 5px">Top border width, for header row (in px)</label>
				                        	</span>
				                        	<input type="number" id="table-thead-row-border-top-width" name="table-thead-row-border-top-width" class="table-thead-row-border-top-width" value="1" min="0">
				                        	<select id="table-thead-row-border-top-type" name="table-thead-row-border-top-type" class="table-thead-row-border-top-type">
											    <option value="solid">Solid</option>
												<option value="dotted">Dotted</option>
												<option value="dashed">Dashed</option>
												<option value="double">Double</option>
												<option value="groove">Groove</option>
												<option value="ridge">Ridge</option>
												<option value="inset">Inset</option>
												<option value="outset">Outset</option>
												<option value="none">None</option>
												<option value="hidden">Hidden</option>
										    </select>
				                        	<input type="color" name="table-thead-row-border-top-color" id="table-thead-row-border-top-color" class="table-thead-row-border-top-color" value="#000000">
				                        </div>

				                        <!-- header border-right -->
				                        <div>
				                        	<span>
				                        		<label for="table-thead-row-border-right-width" title="The unit is (px); E.g., 2px, 5px">Right border width, for header row (in px)</label>
				                        	</span>
				                        	<input type="number" id="table-thead-row-border-right-width" name="table-thead-row-border-right-width" class="table-thead-row-border-right-width" value="1" min="0">
				                        	<select id="table-thead-row-border-right-type" name="table-thead-row-border-right-type" class="table-thead-row-border-right-type">
											    <option value="solid">Solid</option>
												<option value="dotted">Dotted</option>
												<option value="dashed">Dashed</option>
												<option value="double">Double</option>
												<option value="groove">Groove</option>
												<option value="ridge">Ridge</option>
												<option value="inset">Inset</option>
												<option value="outset">Outset</option>
												<option value="none">None</option>
												<option value="hidden">Hidden</option>
										    </select>
				                        	<input type="color" name="table-thead-row-border-right-color" id="table-thead-row-border-right-color" class="table-thead-row-border-right-color" value="#000000">
				                        </div>

				                        <!-- header border-bottom -->
				                        <div>
				                        	<span>
				                        		<label for="table-thead-row-border-bottom-width" title="The unit is (px); E.g., 2px, 5px">Bottom border width, for header row (in px)</label>
				                        	</span>
				                        	<input type="number" id="table-thead-row-border-bottom-width" name="table-thead-row-border-bottom-width" class="table-thead-row-border-bottom-width" value="1" min="0">
				                        	<select id="table-thead-row-border-bottom-type" name="table-thead-row-border-bottom-type" class="table-thead-row-border-bottom-type">
											    <option value="solid">Solid</option>
												<option value="dotted">Dotted</option>
												<option value="dashed">Dashed</option>
												<option value="double">Double</option>
												<option value="groove">Groove</option>
												<option value="ridge">Ridge</option>
												<option value="inset">Inset</option>
												<option value="outset">Outset</option>
												<option value="none">None</option>
												<option value="hidden">Hidden</option>
										    </select>
				                        	<input type="color" name="table-thead-row-border-bottom-color" id="table-thead-row-border-bottom-color" class="table-thead-row-border-bottom-color" value="#000000">
				                        </div>

				                        <!-- header border-left -->
				                        <div>
				                        	<span>
				                        		<label for="table-thead-row-border-left-width" title="The unit is (px); E.g., 2px, 5px">Left border width, for header row (in px)</label>
				                        	</span>
				                        	<input type="number" id="table-thead-row-border-left-width" name="table-thead-row-border-left-width" class="table-thead-row-border-left-width" value="1" min="0">
				                        	<select id="table-thead-row-border-left-type" name="table-thead-row-border-left-type" class="table-thead-row-border-left-type">
											    <option value="solid">Solid</option>
												<option value="dotted">Dotted</option>
												<option value="dashed">Dashed</option>
												<option value="double">Double</option>
												<option value="groove">Groove</option>
												<option value="ridge">Ridge</option>
												<option value="inset">Inset</option>
												<option value="outset">Outset</option>
												<option value="none">None</option>
												<option value="hidden">Hidden</option>
										    </select>
				                        	<input type="color" name="table-thead-row-border-left-color" id="table-thead-row-border-left-color" class="table-thead-row-border-left-color" value="#000000">
				                        </div>
							  		</div>

			                        <div class="tbody-styling-props">
			                        	<h5>Body</h5>
								  		<!-- body font-size -->
				                        <div>
				                        	<span>
				                            	<label for="table-tbody-font-size" title="The unit is (vw); E.g., 2vw, 5vw">Font size, for body text (in vw)</label>
				                            </span>
				                            <input type="number" name="table-tbody-font-size" id="table-tbody-font-size" class="table-tbody-font-size" placeholder="Enter a value">
				                        </div>

				                        <!-- body font color -->
				                        <div>
				                        	<span>
				                            	<label for="table-tbody-font-color">Font color, for body text</label>
				                            </span>
				                            <input type="color" name="table-tbody-font-color" id="table-tbody-font-color" class="table-tbody-font-color" value="#ffffff">
				                        </div>

				                        <!-- body font-family -->
				                        <div>
				                        	<span>
					                        	<label for="table-tbody-font-family" title="Select Font Family">Font Family, for body text</label>
									        </span>
									        <select name="table-tbody-font-family" id="table-tbody-font-family" class="table-tbody-font-family">
									            <option value="Arial, sans-serif">Arial</option>
											    <option value="Helvetica, sans-serif">Helvetica</option>
											    <option value="Times New Roman, serif">Times New Roman</option>
											    <option value="Times, serif">Times</option>
											    <option value="Courier New, monospace">Courier New</option>
											    <option value="Courier, monospace">Courier</option>
											    <option value="Verdana, sans-serif">Verdana</option>
											    <option value="Georgia, serif">Georgia</option>
											    <option value="Palatino, serif">Palatino</option>
											    <option value="Garamond, serif">Garamond</option>
											    <option value="Bookman, serif">Bookman</option>
											    <option value="Comic Sans MS, cursive">Comic Sans MS</option>
											    <option value="Trebuchet MS, sans-serif">Trebuchet MS</option>
											    <option value="Arial Black, sans-serif">Arial Black</option>
											    <option value="Impact, sans-serif">Impact</option>
									        </select>
				                        </div>

				                        <!-- body (odd) row background-color -->
				                        <div>
				                        	<span>
				                            	<label for="table-tbody-odd-row-background-color">Odd Rows background color, for body rows</label>
				                            </span>
				                            <input type="checkbox" name="table-tbody-odd-row-enable-background-color" id="table-tbody-odd-row-enable-background-color" class="table-tbody-odd-row-enable-background-color">
				                            <input type="color" name="table-tbody-odd-row-background-color" id="table-tbody-odd-row-background-color" class="table-tbody-odd-row-background-color" value="#C3C8C8">
				                        </div>

				                        <!-- body (even) row background-color -->
				                        <div>
				                        	<span>
				                            	<label for="table-tbody-even-row-background-color">Even Rows background color, for body rows</label>
				                            </span>
				                            <input type="checkbox" name="table-tbody-even-row-enable-background-color" id="table-tbody-even-row-enable-background-color" class="table-tbody-even-row-enable-background-color">
				                            <input type="color" name="table-tbody-even-row-background-color" id="table-tbody-even-row-background-color" class="table-tbody-even-row-background-color" value="#EFF2F2">
				                        </div>

				                        <!-- body border-top -->
				                        <div>
				                        	<span>
				                        		<label for="table-tbody-row-border-top-width" title="The unit is (px); E.g., 2px, 5px">Top border width, for body row (in px)</label>
				                        	</span>
				                        	<input type="number" id="table-tbody-row-border-top-width" name="table-tbody-row-border-top-width" class="table-tbody-row-border-top-width" value="1" min="0">
				                        	<select id="table-tbody-row-border-top-type" name="table-tbody-row-border-top-type" class="table-tbody-row-border-top-type">
											    <option value="solid">Solid</option>
												<option value="dotted">Dotted</option>
												<option value="dashed">Dashed</option>
												<option value="double">Double</option>
												<option value="groove">Groove</option>
												<option value="ridge">Ridge</option>
												<option value="inset">Inset</option>
												<option value="outset">Outset</option>
												<option value="none">None</option>
												<option value="hidden">Hidden</option>
										    </select>
				                        	<input type="color" name="table-tbody-row-border-top-color" id="table-tbody-row-border-top-color" class="table-tbody-row-border-top-color" value="#000000">
				                        </div>

				                        <!-- body border-right -->
				                        <div>
				                        	<span>
				                        		<label for="table-tbody-row-border-right-width" title="The unit is (px); E.g., 2px, 5px">Right border width, for body row (in px)</label>
				                        	</span>
				                        	<input type="number" id="table-tbody-row-border-right-width" name="table-tbody-row-border-right-width" class="table-tbody-row-border-right-width" value="1" min="0">
				                        	<select id="table-tbody-row-border-right-type" name="table-tbody-row-border-right-type" class="table-tbody-row-border-right-type">
											    <option value="solid">Solid</option>
												<option value="dotted">Dotted</option>
												<option value="dashed">Dashed</option>
												<option value="double">Double</option>
												<option value="groove">Groove</option>
												<option value="ridge">Ridge</option>
												<option value="inset">Inset</option>
												<option value="outset">Outset</option>
												<option value="none">None</option>
												<option value="hidden">Hidden</option>
										    </select>
				                        	<input type="color" name="table-tbody-row-border-right-color" id="table-tbody-row-border-right-color" class="table-tbody-row-border-right-color" value="#000000">
				                        </div>

				                        <!-- body border-bottom -->
				                        <div>
				                        	<span>
				                        		<label for="table-tbody-row-border-bottom-width" title="The unit is (px); E.g., 2px, 5px">Bottom border width, for body row (in px)</label>
				                        	</span>
				                        	<input type="number" id="table-tbody-row-border-bottom-width" name="table-tbody-row-border-bottom-width" class="table-tbody-row-border-bottom-width" value="1" min="0">
				                        	<select id="table-tbody-row-border-bottom-type" name="table-tbody-row-border-bottom-type" class="table-tbody-row-border-bottom-type">
											    <option value="solid">Solid</option>
												<option value="dotted">Dotted</option>
												<option value="dashed">Dashed</option>
												<option value="double">Double</option>
												<option value="groove">Groove</option>
												<option value="ridge">Ridge</option>
												<option value="inset">Inset</option>
												<option value="outset">Outset</option>
												<option value="none">None</option>
												<option value="hidden">Hidden</option>
										    </select>
				                        	<input type="color" name="table-tbody-row-border-bottom-color" id="table-tbody-row-border-bottom-color" class="table-tbody-row-border-bottom-color" value="#000000">
				                        </div>

				                        <!-- body border-left -->
				                        <div>
				                        	<span>
				                        		<label for="table-tbody-row-border-left-width" title="The unit is (px); E.g., 2px, 5px">Left border width, for body row (in px)</label>
				                        	</span>
				                        	<input type="number" id="table-tbody-row-border-left-width" name="table-tbody-row-border-left-width" class="table-tbody-row-border-left-width" value="1" min="0">
				                        	<select id="table-tbody-row-border-left-type" name="table-tbody-row-border-left-type" class="table-tbody-row-border-left-type">
											    <option value="solid">Solid</option>
												<option value="dotted">Dotted</option>
												<option value="dashed">Dashed</option>
												<option value="double">Double</option>
												<option value="groove">Groove</option>
												<option value="ridge">Ridge</option>
												<option value="inset">Inset</option>
												<option value="outset">Outset</option>
												<option value="none">None</option>
												<option value="hidden">Hidden</option>
										    </select>
				                        	<input type="color" name="table-tbody-row-border-left-color" id="table-tbody-row-border-left-color" class="table-tbody-row-border-left-color" value="#000000">
				                        </div>

				                        <!-- body row hover color -->
				                        <div>
				                        	<span>
				                            	<label for="table-tbody-row-hover-color">Hover color, for body rows</label>
				                            </span>
				                            <input type="color" name="table-tbody-row-hover-color" id="table-tbody-row-hover-color" class="table-tbody-row-hover-color" value="#000000">
				                        </div>
							  		</div>
						  		</span>

						  		<hr>

		                        <div class="tfoot-styling-props">
		                        	<h5>Footer</h5>
							  		<!-- footer font-size -->
			                        <div>
			                        	<span>
			                            	<label for="table-tfoot-font-size" title="The unit is (vw); E.g., 2vw, 5vw">Font size, for footer text (in vw)</label>
			                            </span>
			                            <input type="number" name="table-tfoot-font-size" id="table-tfoot-font-size" class="table-tfoot-font-size" placeholder="Enter a value">
			                        </div>

			                        <!-- footer font color -->
			                        <div>
			                        	<span>
			                            	<label for="table-tfoot-font-color">Font color, for footer text</label>
			                            </span>
			                            <input type="color" name="table-tfoot-font-color" id="table-tfoot-font-color" class="table-tfoot-font-color" value="#ffffff">
			                        </div>

			                        <!-- footer font-family -->
			                        <div>
			                        	<span>
				                        	<label for="table-tfoot-font-family" title="Select Font Family">Font Family, for footer text</label>
								        </span>
								        <select name="table-tfoot-font-family" id="table-tfoot-font-family" class="table-tfoot-font-family">
								            <option value="Arial, sans-serif">Arial</option>
										    <option value="Helvetica, sans-serif">Helvetica</option>
										    <option value="Times New Roman, serif">Times New Roman</option>
										    <option value="Times, serif">Times</option>
										    <option value="Courier New, monospace">Courier New</option>
										    <option value="Courier, monospace">Courier</option>
										    <option value="Verdana, sans-serif">Verdana</option>
										    <option value="Georgia, serif">Georgia</option>
										    <option value="Palatino, serif">Palatino</option>
										    <option value="Garamond, serif">Garamond</option>
										    <option value="Bookman, serif">Bookman</option>
										    <option value="Comic Sans MS, cursive">Comic Sans MS</option>
										    <option value="Trebuchet MS, sans-serif">Trebuchet MS</option>
										    <option value="Arial Black, sans-serif">Arial Black</option>
										    <option value="Impact, sans-serif">Impact</option>
								        </select>
			                        </div>			                        

							        <!-- footer background-color -->
			                        <div>
			                        	<span>
			                            	<label for="table-tfoot-row-background-color">Background color, for footer row</label>
			                            </span>
			                            <input type="checkbox" name="table-tfoot-row-enable-background-color" id="table-tfoot-row-enable-background-color" class="table-tfoot-row-enable-background-color">
			                            <input type="color" name="table-tfoot-row-background-color" id="table-tfoot-row-background-color" class="table-tfoot-row-background-color" value="#808080">
			                        </div>

			                        <!-- footer linear-gradient -->
			                        <div>
			                        	<span>
			                            	<label for="table-tfoot-row-linear-gradient-1">Linear gradient, for footer row</label>
			                            </span>
			                            <input type="checkbox" name="table-tfoot-row-enable-linear-gradient-1" id="table-tfoot-row-enable-linear-gradient-1" class="table-tfoot-row-enable-linear-gradient-1">
			                            <input type="color" name="table-tfoot-row-linear-gradient-1" id="table-tfoot-row-linear-gradient-1" class="table-tfoot-row-linear-gradient-1" value="#A020F0">
			                            <input type="color" name="table-tfoot-row-linear-gradient-2" id="table-tfoot-row-linear-gradient-2" class="table-tfoot-row-linear-gradient-2" value="#0000FF">
			                        </div>
			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-row-linear-gradient-angle">Gradient Angle</label>
  										</span>
  										<input type="number" id="table-tfoot-row-linear-gradient-angle" name="table-tfoot-row-linear-gradient-angle" class="table-tfoot-row-linear-gradient-angle" value="90" min="0" max="360" step="1">
  									</div>

			                        <!-- footer border-top -->
			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-row-border-top-width" title="The unit is (px); E.g., 2px, 5px">Top border width, for footer row (in px)</label>
			                        	</span>
			                        	<input type="number" id="table-tfoot-row-border-top-width" name="table-tfoot-row-border-top-width" class="table-tfoot-row-border-top-width" value="1" min="0">
			                        	<select id="table-tfoot-row-border-top-type" name="table-tfoot-row-border-top-type" class="table-tfoot-row-border-top-type">
										    <option value="solid">Solid</option>
											<option value="dotted">Dotted</option>
											<option value="dashed">Dashed</option>
											<option value="double">Double</option>
											<option value="groove">Groove</option>
											<option value="ridge">Ridge</option>
											<option value="inset">Inset</option>
											<option value="outset">Outset</option>
											<option value="none">None</option>
											<option value="hidden">Hidden</option>
									    </select>
			                        	<input type="color" name="table-tfoot-row-border-top-color" id="table-tfoot-row-border-top-color" class="table-tfoot-row-border-top-color" value="#000000">
			                        </div>

			                        <!-- footer border-right -->
			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-row-border-right-width" title="The unit is (px); E.g., 2px, 5px">Right border width, for footer row (in px)</label>
			                        	</span>
			                        	<input type="number" id="table-tfoot-row-border-right-width" name="table-tfoot-row-border-right-width" class="table-tfoot-row-border-right-width" value="1" min="0">
			                        	<select id="table-tfoot-row-border-right-type" name="table-tfoot-row-border-right-type" class="table-tfoot-row-border-right-type">
										    <option value="solid">Solid</option>
											<option value="dotted">Dotted</option>
											<option value="dashed">Dashed</option>
											<option value="double">Double</option>
											<option value="groove">Groove</option>
											<option value="ridge">Ridge</option>
											<option value="inset">Inset</option>
											<option value="outset">Outset</option>
											<option value="none">None</option>
											<option value="hidden">Hidden</option>
									    </select>
			                        	<input type="color" name="table-tfoot-row-border-right-color" id="table-tfoot-row-border-right-color" class="table-tfoot-row-border-right-color" value="#000000">
			                        </div>

			                        <!-- footer border-bottom -->
			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-row-border-bottom-width" title="The unit is (px); E.g., 2px, 5px">Bottom border width, for footer row (in px)</label>
			                        	</span>
			                        	<input type="number" id="table-tfoot-row-border-bottom-width" name="table-tfoot-row-border-bottom-width" class="table-tfoot-row-border-bottom-width" value="1" min="0">
			                        	<select id="table-tfoot-row-border-bottom-type" name="table-tfoot-row-border-bottom-type" class="table-tfoot-row-border-bottom-type">
										    <option value="solid">Solid</option>
											<option value="dotted">Dotted</option>
											<option value="dashed">Dashed</option>
											<option value="double">Double</option>
											<option value="groove">Groove</option>
											<option value="ridge">Ridge</option>
											<option value="inset">Inset</option>
											<option value="outset">Outset</option>
											<option value="none">None</option>
											<option value="hidden">Hidden</option>
									    </select>
			                        	<input type="color" name="table-tfoot-row-border-bottom-color" id="table-tfoot-row-border-bottom-color" class="table-tfoot-row-border-bottom-color" value="#000000">
			                        </div>

			                        <!-- footer border-left -->
			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-row-border-left-width" title="The unit is (px); E.g., 2px, 5px">Left border width, for footer row (in px)</label>
			                        	</span>
			                        	<input type="number" id="table-tfoot-row-border-left-width" name="table-tfoot-row-border-left-width" class="table-tfoot-row-border-left-width" value="1" min="0">
			                        	<select id="table-tfoot-row-border-left-type" name="table-tfoot-row-border-left-type" class="table-tfoot-row-border-left-type">
										    <option value="solid">Solid</option>
											<option value="dotted">Dotted</option>
											<option value="dashed">Dashed</option>
											<option value="double">Double</option>
											<option value="groove">Groove</option>
											<option value="ridge">Ridge</option>
											<option value="inset">Inset</option>
											<option value="outset">Outset</option>
											<option value="none">None</option>
											<option value="hidden">Hidden</option>
									    </select>
			                        	<input type="color" name="table-tfoot-row-border-left-color" id="table-tfoot-row-border-left-color" class="table-tfoot-row-border-left-color" value="#000000">
			                        </div>

			                        <!-- footer Total Rows result (p tag) resizing -->
			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-total-rows-margin-top">Total Rows Result Text, Margin-Top (in vh)</label>
  										</span>
  										<input type="number" id="table-tfoot-total-rows-margin-top" name="table-tfoot-total-rows-margin-top" class="table-tfoot-total-rows-margin-top" value="0" min="0" step="1">
  									</div>

  									<div>
			                        	<span>
			                        		<label for="table-tfoot-total-rows-margin-right">Total Rows Result Text, Margin-Right (in vw)</label>
  										</span>
  										<input type="number" id="table-tfoot-total-rows-margin-right" name="table-tfoot-total-rows-margin-right" class="table-tfoot-total-rows-margin-right" value="0" min="0" step="1">
  									</div>

			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-total-rows-margin-bottom">Total Rows Result Text, Margin-Bottom (in vh)</label>
  										</span>
  										<input type="number" id="table-tfoot-total-rows-margin-bottom" name="table-tfoot-total-rows-margin-bottom" class="table-tfoot-total-rows-margin-bottom" value="0" min="0" step="1">
  									</div>

			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-total-rows-margin-left">Total Rows Result Text, Margin-Left (in vw)</label>
  										</span>
  										<input type="number" id="table-tfoot-total-rows-margin-left" name="table-tfoot-total-rows-margin-left" class="table-tfoot-total-rows-margin-left" value="0" min="0" step="1">
  									</div>

  									<!-- footer Total Rows result (Cell) resizing -->  									
			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-cell-padding-top">Tfoot Cell, Padding-Top (in vh)</label>
  										</span>
  										<input type="number" id="table-tfoot-cell-padding-top" name="table-tfoot-cell-padding-top" class="table-tfoot-cell-padding-top" value="0" min="0" step="1">
  									</div>

  									<div>
			                        	<span>
			                        		<label for="table-tfoot-cell-padding-right">Tfoot Cell, Padding-Right (in vw)</label>
  										</span>
  										<input type="number" id="table-tfoot-cell-padding-right" name="table-tfoot-cell-padding-right" class="table-tfoot-cell-padding-right" value="0" min="0" step="1">
  									</div>

			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-cell-padding-bottom">Tfoot Cell, Padding-Bottom (in vh)</label>
  										</span>
  										<input type="number" id="table-tfoot-cell-padding-bottom" name="table-tfoot-cell-padding-bottom" class="table-tfoot-cell-padding-bottom" value="0" min="0" step="1">
  									</div>

			                        <div>
			                        	<span>
			                        		<label for="table-tfoot-cell-padding-left">Tfoot Cell, Padding-Left (in vw)</label>
  										</span>
  										<input type="number" id="table-tfoot-cell-padding-left" name="table-tfoot-cell-padding-left" class="table-tfoot-cell-padding-left" value="0" min="0" step="1">
  									</div>
						  		</div>

						  		<hr>

		                        <!-- table content alignment -->
		                        <div class="table-content-alignment-container">
		                        	<span>
		                            	<label for="table-content-alignment" title="Align the content of this table">Align content</label>
		                            </span>
		                            <select name="table-content-alignment" id="table-content-alignment" class="table-content-alignment">
		                            	<option value="left">Left</option>
		                            	<option value="center">Center</option>
		                            	<option value="right">Right</option>
		                            </select>
		                        </div>

		                        <!-- submit button -->
		                        <button class="table-styling-btn">Apply</button>
						  	</div>
						</div>
                    `;

					editor.Modal.open({
						title: "Custom Table Form",
						content: `${form.outerHTML}`,
						attributes: {
							thisModelRef: this.model,
							thisTableRef: this.model.components().models.find((comp) => comp.attributes.type === "custom-table"),
						},
					});
				},

				// this onRender method is for adding functionality to the table headers and also tbody
				// what shouldn't get downloaded to go in onRender function's { el } arg, not { model } arg
				onRender({ el, model }) {
					console.log("onRender el, model", el, model);
					// so that these components don't show in preview editor (because same plugin file, this one, is getting imported in the preivew's editor also)
					if (!window.top.location.href.includes("large_preview/")) {
						// if custom-table-container has been rendered, it means you can get the custom-table which is inside it so use el.getElemen..... and add the required element above it
						// if (el.className === "custom-table-container") {
						// this button is only for editing purposes and will not get downloaded
						let customizeTableBtn = document.createElement("button");
						customizeTableBtn.setAttribute("class", "customize-table-btn");
						customizeTableBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16"><path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/></svg>';

						// Insert the customizeTableBtn element next to the custom-table element
						el.getElementsByClassName("custom-table")[0].insertAdjacentElement("beforebegin", customizeTableBtn);
						// }

						// adding buttons to thead's headers
						let allTh = el.querySelectorAll(".custom-table > thead > tr > th");
						allTh.forEach((th, idx) => {
							// box holding all the table functionality
							let colFunc = document.createElement("span");
							colFunc.style.paddingTop = "0.2em";
							colFunc.style.paddingBottom = "0.2em";
							colFunc.style.display = "flex";
							colFunc.style.columnGap = "0.4em";
							th.appendChild(colFunc);

							// adding '+' icon which is when clicked on, will add a column next to current column
							let addColumnBtn = document.createElement("span");
							addColumnBtn.setAttribute("title", "Add a new column to the right.");
							addColumnBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/></svg>';
							addColumnBtn.style.zIndex = "999";
							addColumnBtn.style.cursor = "pointer";
							colFunc.appendChild(addColumnBtn);
							// on click it should add column
							addColumnBtn.addEventListener("click", (event) => {
								addColumnAtIndex(
									editor,
									model,
									model.components().models.find((comp) => comp.attributes.type === "custom-table"),
									idx + 1,
									model.get("numColsInputValue"),
									model.get("tableTheadFontSize"),
									model.get("tableTheadFontColor"),
									model.get("tableTheadFontFamily"),
									model.get("tableTheadRowBackgroundColor"),
									model.get("tableTheadRowEnableBackgroundColor"),
									model.get("tableTheadRowEnableLinearGradient1"),
									model.get("tableTheadRowLinearGradient1"),
									model.get("tableTheadRowLinearGradient2"),
									model.get("tableTheadRowLinearGradientAngle"),
									model.get("tableTheadRowBorderTopWidth"),
									model.get("tableTheadRowBorderTopType"),
									model.get("tableTheadRowBorderTopColor"),
									model.get("tableTheadRowBorderRightWidth"),
									model.get("tableTheadRowBorderRightType"),
									model.get("tableTheadRowBorderRightColor"),
									model.get("tableTheadRowBorderBottomWidth"),
									model.get("tableTheadRowBorderBottomType"),
									model.get("tableTheadRowBorderBottomColor"),
									model.get("tableTheadRowBorderLeftWidth"),
									model.get("tableTheadRowBorderLeftType"),
									model.get("tableTheadRowBorderLeftColor"),
								);
							});

							// add 'TRASH' icon to delete a column
							let deleteColumnBtn = document.createElement("span");
							deleteColumnBtn.setAttribute("title", "Delete this column.");
							deleteColumnBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/></svg>';
							deleteColumnBtn.style.zIndex = "999";
							deleteColumnBtn.style.cursor = "pointer";
							colFunc.appendChild(deleteColumnBtn);
							// on click it should add column
							deleteColumnBtn.addEventListener("click", (event) => {
								deleteColumnAtIndex(
									editor,
									model,
									model.components().models.find((comp) => comp.attributes.type === "custom-table"),
									idx,
									model.get("numColsInputValue"),
								);
							});

							// add 'LEFT' icon to MOVE a column to left
							let moveLeftColumnBtn = document.createElement("span");
							moveLeftColumnBtn.setAttribute("title", "Move this column to the left.");
							moveLeftColumnBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-90deg-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/></svg>';
							moveLeftColumnBtn.style.zIndex = "999";
							moveLeftColumnBtn.style.cursor = "pointer";
							colFunc.appendChild(moveLeftColumnBtn);
							// on click it should add column
							moveLeftColumnBtn.addEventListener("click", (event) => {
								moveColumnToLeft(
									editor,
									model,
									model.components().models.find((comp) => comp.attributes.type === "custom-table"),
									idx,
									model.get("numColsInputValue"),
								);
							});

							// add 'RIGHT' icon to MOVE a column to right
							let moveRightColumnBtn = document.createElement("span");
							moveRightColumnBtn.setAttribute("title", "Move this column to the right.");
							moveRightColumnBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-90deg-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z"/></svg>';
							moveRightColumnBtn.style.zIndex = "999";
							moveRightColumnBtn.style.cursor = "pointer";
							colFunc.appendChild(moveRightColumnBtn);
							// on click it should add column
							moveRightColumnBtn.addEventListener("click", (event) => {
								moveColumnToRight(
									editor,
									model,
									model.components().models.find((comp) => comp.attributes.type === "custom-table"),
									idx,
									model.get("numColsInputValue"),
								);
							});

							// Replicate all the inner components to all the cells of that column by getting that column's index
							let applyElementsToColBtn = document.createElement("span");
							applyElementsToColBtn.setAttribute("title", "Apply all the elements to this column cells");
							applyElementsToColBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16"><path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/></svg>';
							applyElementsToColBtn.style.zIndex = "999";
							applyElementsToColBtn.style.cursor = "pointer";
							colFunc.appendChild(applyElementsToColBtn);
							// on click it should add column
							applyElementsToColBtn.addEventListener("click", (event) => {
								applyElementsToColumn(
									editor,
									model,
									model.components().models.find((comp) => comp.attributes.type === "custom-table"),
									idx,
								);
							});

							// Open a modal to select Data formating options (for example - Date formats)
							let applyFormatsToColDataBtn = document.createElement("span");
							applyFormatsToColDataBtn.setAttribute("title", "Change formats of different datatypes in the table body");
							applyFormatsToColDataBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app-indicator" viewBox="0 0 16 16"><path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1z"/><path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/></svg>';
							applyFormatsToColDataBtn.style.zIndex = "999";
							applyFormatsToColDataBtn.style.cursor = "pointer";
							colFunc.appendChild(applyFormatsToColDataBtn);
							// on click it should open a popup
							applyFormatsToColDataBtn.addEventListener("click", (event) => {
								// create a form every time this modal opens to provide new fields
								const form = document.createElement("div");
								form.setAttribute("class", "custom-table-formats-form");

								form.innerHTML = `
			                    	<div class="formats-form">
			                    		<div>
				                    		<div class="formats-form-datatype-container">
				                    			<label for="formats-form-datatype" title"Data type of the data displaying in this column">Select the data type</label>
				                    			<select id="formats-form-datatype" name="formats-form-datatype" class="formats-form-datatype">
				                    				<option value="null" selected disabled>Choose</option>
				                    				<option value="datetime-local">Date-Time</option>
				                    				<option value="datetime-zones">Time Zones</option>
				                    				<option value="number">Number</option>
				                    				<option value="text">Text</option>
				                    				<option value="richText">RichText</option>
				                    			</select>
				                    		</div>

				                    		<div class="formats-form-date-format-container">
				                    			<label for="formats-form-date-format" title"Data type of the data displaying in this column">Select a date format to display date</label>
				                    			<select id="formats-form-date-format" name="formats-form-date-format" class="formats-form-date-format">
				                    				<option value="null" selected disabled>-- select --</option>
				                    				<option value="default">Default format</option>
				                    				
				                    				<hr style="margin: 5% auto 5% auto">
				                    				
				                    				<option value="date_mon_year">Date Mon Year</option>
				                    				<option value="date_month_year">Date Month Year</option>
				                    				<option value="dd/mm/yyyy">dd/mm/yyyy</option>
				                    				<option value="mm/dd/yyyy">mm/dd/yyyy</option>
				                    				<option value="dd-mm-yyyy">dd-mm-yyyy</option>
				                    				
				                    				<hr style="margin: 5% auto 5% auto">
				                    				
				                    				<option value="date_mon_year_00_00_am_pm">Date Mon Year 00:00 am/pm</option>
				                    				<option value="date_month_year_00_00_am_pm">Date Month Year 00:00 am/pm</option>
				                    				<option value="dd/mm/yyyy_00_00_am_pm">dd/mm/yyyy 00:00 am/pm</option>
				                    				<option value="mm/dd/yyyy_00_00_am_pm">mm/dd/yyyy 00:00 am/pm</option>
				                    				<option value="dd-mm-yyyy_00_00_am_pm">dd-mm-yyyy 00:00 am/pm</option>
				                    				
				                    				<hr style="margin: 5% auto 5% auto">
				                    				
				                    				<option value="date_mon_year_24_00">Date Mon Year 24:00</option>
				                    				<option value="date_month_year_24_00">Date Month Year 24:00</option>
				                    				<option value="dd/mm/yyyy_24_00">dd/mm/yyyy 24:00</option>
				                    				<option value="mm/dd/yyyy_24_00">mm/dd/yyyy 24:00</option>
				                    				<option value="dd-mm-yyyy_24_00">dd-mm-yyyy 24:00</option>
				                    			</select>
				                    		</div>

				                    		<div class="formats-form-timezone-format-container">
				                    			<label for="formats-form-timezone-format" title"Only UTC formats are supported for time zone conversions.">Select a time zone format to convert UTC date-time</label>
				                    			<select id="formats-form-timezone-format" name="formats-form-timezone-format" class="formats-form-timezone-format">
				                    				<option value="null" selected disabled>-- select --</option>
											        <option value="default" title="Default format">Default format</option>
											        
											        <hr style="margin: 5% auto 5% auto">
											        
											        <optgroup label="Global Time Zones">
											            <!-- <option value="UTC" title="The time standard often used across the world">UTC (Coordinated Universal Time)</option> -->
											            <option value="GMT" title="Often used interchangeably with UTC but historically has slight differences">GMT (Greenwich Mean Time)</option>
											            <option value="CET" title="UTC +1, used in countries like Germany, France, and Italy">CET (Central European Time)</option>
											            <option value="EST" title="UTC -5, used in the eastern part of the United States and Canada">EST (Eastern Standard Time)</option>
											            <option value="PST" title="UTC -8, used in the western part of the United States and Canada">PST (Pacific Standard Time)</option>
											            <option value="IST" title="UTC +5:30, used in India and Sri Lanka">IST (Indian Standard Time)</option>
											            <option value="CST" title="UTC +8, used in China and other regions in East Asia">CST (China Standard Time)</option>
											            <option value="JST" title="UTC +9, used in Japan">JST (Japan Standard Time)</option>
											            <option value="ICT" title="UTC +7, used in several countries such as Thailand, Cambodia, and Vietnam">ICT (Indochina Time)</option>
											            <option value="AEST" title="UTC +10, used in eastern Australia">AEST (Australian Eastern Standard Time)</option>
											            <option value="ACST" title="UTC +9:30, used in central Australia">ACST (Australian Central Standard Time)</option>
											            <option value="AWST" title="UTC +8, used in western Australia">AWST (Australian Western Standard Time)</option>
											        </optgroup>
											        
											        <hr style="margin: 5% auto 5% auto">
											        
											        <optgroup label="Daylight Saving Time Zones">
											            <option value="EDT" title="UTC -4, used in eastern parts of the United States during daylight saving time">EDT (Eastern Daylight Time)</option>
											            <option value="PDT" title="UTC -7, used in the western United States during daylight saving time">PDT (Pacific Daylight Time)</option>
											            <option value="CEST" title="UTC +2, used in parts of Europe during daylight saving time">CEST (Central European Summer Time)</option>
											        </optgroup>
											        
											        <hr style="margin: 5% auto 5% auto">
											        
											        <optgroup label="Other Time Zones">
											            <option value="BST" title="UTC +1, used in the UK during daylight saving time">BST (British Summer Time)</option>
											            <option value="MSK" title="UTC +3, used in Moscow, Russia">MSK (Moscow Standard Time)</option>
											            <option value="AKST" title="UTC -9, used in Alaska">AKST (Alaska Standard Time)</option>
											            <option value="HST" title="UTC -10, used in Hawaii">HST (Hawaii Standard Time)</option>
											        </optgroup>
				                    			</select>
				                    		</div>

				                    		<div class="formats-form-number-format-container">
				                    			<label for="formats-form-number-format" title"Data type of the data displaying in this column">Select a number format to display numbers</label>
				                    			<select id="formats-form-number-format" name="formats-form-number-format" class="formats-form-number-format">
				                    				<option value="null" selected disabled>-- select --</option>
				                    				<option value="default">Default format</option>
				                    				
				                    				<hr style="margin: 5% auto 5% auto">
				                    				
				                    				<!-- Separators -->
				                    				<optgroup label="Separators">
					                    				<option value="no_sep" title="Numbers will be presented without any separators.">No Separator</option>
					                    				<option value="aps_sep" title="Apostrophes are used to separate groups of three digits.">Apostrophe Separator</option>
					                    				<option value="spc_sep" title="Regular spaces are used instead of commas to separate groups of three digits.">Space Separator</option>
					                    				<option value="spc_sep_underscore" title="Underscores are used instead of commas to separate groups of three digits.">Underscore Separator</option>
					                    				
					                    				<option value="wes_int_frt" title="Commas are placed every three digits to the left of the decimal point.">Western (International) Format</option>
					                    				<option value="eu_frt" title="Periods are used instead of commas to separate thousands, and commas are used as decimal separators.">European Format</option>
					                    				<option value="ind_num_sys" title="The first group from the right contains three digits, and subsequent groups contain two digits.">Indian Numbering System</option>
					                    				<option value="est_asn_frt" title="Numbers are often grouped in units of four digits instead of three.">Chinese/Japanese/Korean (East Asian) Format</option>
				                    				</optgroup>

				                    				<hr style="margin: 5% auto 5% auto">

				                    				<!-- Decimal Places -->
				                    				<optgroup label="Decimal Places">
					                    				<option value="two_dec" title="Example: 123.45">Two Decimal Places</option>
					                    				<option value="four_dec" title="Example: 123.4500">Four Decimal Places</option>
					                    				<option value="six_dec" title="Example: 123.450000">Six Decimal Places</option>
					                    				<option value="eight_dec" title="Example: 123.45000000">Eight Decimal Places</option>
				                    				</optgroup>

				                    				<hr style="margin: 5% auto 5% auto">

				                    				<!-- Separators & Two Decimal Places, Both -->
				                    				<optgroup label="Separators & Two Decimal Places, Both">
					                    				<option value="aps_sep_two_dec" title="Apostrophes are used to separate groups of three digits.">Apostrophe Separator + Two Decimal Places</option>
					                    				<option value="spc_sep_two_dec" title="Regular spaces are used instead of commas to separate groups of three digits.">Space Separator + Two Decimal Places</option>
					                    				<option value="spc_sep_underscore_two_dec" title="Underscores are used instead of commas to separate groups of three digits.">Underscore Separator + Two Decimal Places</option>
					                    				<option value="wes_int_frt_two_dec" title="Commas are placed every three digits to the left of the decimal point.">Western (International) Format + Two Decimal Places</option>
					                    				<option value="eu_frt_two_dec" title="Periods are used instead of commas to separate thousands, and commas are used as decimal separators.">European Format + Two Decimal Places</option>
					                    				<option value="ind_num_sys_two_dec" title="The first group from the right contains three digits, and subsequent groups contain two digits.">Indian Numbering System + Two Decimal Places</option>
					                    				<option value="est_asn_frt_two_dec" title="Numbers are often grouped in units of four digits instead of three.">Chinese/Japanese/Korean (East Asian) Format + Two Decimal Places</option>
				                    				</optgroup>

				                    				<hr style="margin: 5% auto 5% auto">

				                    				<!-- Separators & Four Decimal Places, Both -->
				                    				<optgroup label="Separators & Four Decimal Places, Both">
					                    				<option value="aps_sep_four_dec" title="Apostrophes are used to separate groups of three digits.">Apostrophe Separator + Four Decimal Places</option>
					                    				<option value="spc_sep_four_dec" title="Regular spaces are used instead of commas to separate groups of three digits.">Space Separator + Four Decimal Places</option>
					                    				<option value="spc_sep_underscore_four_dec" title="Underscores are used instead of commas to separate groups of three digits.">Underscore Separator + Four Decimal Places</option>
					                    				<option value="wes_int_frt_four_dec" title="Commas are placed every three digits to the left of the decimal point.">Western (International) Format + Four Decimal Places</option>
					                    				<option value="eu_frt_four_dec" title="Periods are used instead of commas to separate thousands, and commas are used as decimal separators.">European Format + Four Decimal Places</option>
					                    				<option value="ind_num_sys_four_dec" title="The first group from the right contains three digits, and subsequent groups contain two digits.">Indian Numbering System + Four Decimal Places</option>
					                    				<option value="est_asn_frt_four_dec" title="Numbers are often grouped in units of four digits instead of three.">Chinese/Japanese/Korean (East Asian) Format + Four Decimal Places</option>
				                    				</optgroup>

				                    				<!-- Separators & Six Decimal Places, Both -->
				                    				<optgroup label="Separators & Six Decimal Places, Both">
					                    				<option value="aps_sep_six_dec" title="Apostrophes are used to separate groups of three digits.">Apostrophe Separator + Six Decimal Places</option>
					                    				<option value="spc_sep_six_dec" title="Regular spaces are used instead of commas to separate groups of three digits.">Space Separator + Six Decimal Places</option>
					                    				<option value="spc_sep_underscore_six_dec" title="Underscores are used instead of commas to separate groups of three digits.">Underscore Separator + Six Decimal Places</option>
					                    				<option value="wes_int_frt_six_dec" title="Commas are placed every three digits to the left of the decimal point.">Western (International) Format + Six Decimal Places</option>
					                    				<option value="eu_frt_six_dec" title="Periods are used instead of commas to separate thousands, and commas are used as decimal separators.">European Format + Six Decimal Places</option>
					                    				<option value="ind_num_sys_six_dec" title="The first group from the right contains three digits, and subsequent groups contain two digits.">Indian Numbering System + Six Decimal Places</option>
					                    				<option value="est_asn_frt_six_dec" title="Numbers are often grouped in units of four digits instead of three.">Chinese/Japanese/Korean (East Asian) Format + Six Decimal Places</option>
				                    				</optgroup>

				                    				<hr style="margin: 5% auto 5% auto">

				                    				<!-- Separators & Eight Decimal Places, Both -->
				                    				<optgroup label="Separators & Eight Decimal Places, Both">
					                    				<option value="aps_sep_eight_dec" title="Apostrophes are used to separate groups of three digits.">Apostrophe Separator + Eight Decimal Places</option>
					                    				<option value="spc_sep_eight_dec" title="Regular spaces are used instead of commas to separate groups of three digits.">Space Separator + Eight Decimal Places</option>
					                    				<option value="spc_sep_underscore_eight_dec" title="Underscores are used instead of commas to separate groups of three digits.">Underscore Separator + Eight Decimal Places</option>
					                    				<option value="wes_int_frt_eight_dec" title="Commas are placed every three digits to the left of the decimal point.">Western (International) Format + Eight Decimal Places</option>
					                    				<option value="eu_frt_eight_dec" title="Periods are used instead of commas to separate thousands, and commas are used as decimal separators.">European Format + Eight Decimal Places</option>
					                    				<option value="ind_num_sys_eight_dec" title="The first group from the right contains three digits, and subsequent groups contain two digits.">Indian Numbering System + Eight Decimal Places</option>
					                    				<option value="est_asn_frt_eight_dec" title="Numbers are often grouped in units of four digits instead of three.">Chinese/Japanese/Korean (East Asian) Format + Eight Decimal Places</option>
				                    				</optgroup>
				                    			</select>
				                    		</div>

				                    		<div class="formats-form-richText-container">
				                    			<input type="checkbox" id="rte-toolbar-switch" class="rte-toolbar-switch" name="rte-toolbar-switch" checked>
				                    			<label for="rte-toolbar-switch" title="Uncheck the box to enable RTE toolbar and vice-versa.">Enable/Disable RTE Toolbar</label>
				                    			<input type="checkbox" id="rte-textfield-readonly" class="rte-textfield-readonly" name="rte-textfield-readonly">
				                    			<label for="rte-textfield-readonly" title="Check the box to make the text field read-only (users cannot interact with the field) and vice-versa.">Read-Only RTE Text Field</label>
				                    		</div>
			                    		</div>

										<br>

										<div class="apply-functions-to-columns-container">
											<label for="apply-functions-to-columns-function-name" title="Mention the function name to apply on column data.">Function To Transform Data</label>
											<input type="text" id="apply-functions-to-columns-function-name" class="apply-functions-to-columns-function-name" name="apply-functions-to-columns-function-name" placeholder="Function Name">
										</div>

			                    		<!-- submit button -->
	                        			<button class="formats-form-apply-btn">Apply</button>
			                    	</div>
			                    `;

								editor.Modal.open({
									title: `Apply Formats To This Column (${idx}) Data`,
									content: `${form.outerHTML}`,
									attributes: {
										thisModelRef: this.model,
										thisTableRef: this.model.components().models.find((comp) => comp.attributes.type === "custom-table"),
										colIdx: idx,
										class: "custom-table-formats-form-modal",
									},
								});
							});

							// adding an input field that will store keynames as model properties for plotting
							let apiKeynameInput = document.createElement("input");
							apiKeynameInput.setAttribute("type", "text");
							apiKeynameInput.setAttribute("id", `th_${idx}`);
							apiKeynameInput.setAttribute("class", `th_${idx}`);
							apiKeynameInput.setAttribute("placeholder", `API keyname for header field ${idx + 1}`);

							// to keep displaying the entered values by the user in the input fields after refresh
							if (model.get(`HeaderField_${idx}`)) {
								// console.log(`thHeaderField_${idx}`, model.get(`HeaderField_${idx}`));
								apiKeynameInput.setAttribute("value", model.get(`HeaderField_${idx}`));
								apiKeynameInput.value = model.get(`HeaderField_${idx}`);
							}

							apiKeynameInput.style.border = "1px solid black";
							apiKeynameInput.style.borderRadius = "5px";
							apiKeynameInput.style.boxShadow = "0 1px 2px 0px black";
							apiKeynameInput.style.width = "80%";
							apiKeynameInput.style.fontSize = "0.8vw";
							apiKeynameInput.style.padding = "0.15em";
							apiKeynameInput.style.color = "black";

							th.appendChild(apiKeynameInput);

							// Handle input events to store user input in component's properties
							apiKeynameInput.addEventListener("input", (event) => {
								const inputValue = event.target.value;
								model.set("HeaderField_" + idx, inputValue);

								// so that HeaderField_i are saved in export/downloaded code also
								model.attributes.HeaderFields[`HeaderField_${idx}`] = inputValue;
								// model.set(`HeaderField_${idx}`, inputValue );

								// setting attributes to the <div> container also so that these fields are available in download
								model.attributes.attributes[`data-headerfield_${idx}`] = inputValue;

								console.log("model (1)", model);
								// recall paginationScript so that it gets the updated HeaderField_i model properties and update updatedProps in sessionStorage
								model.attributes.script.call(model.getEl(), model.props());
								console.log("onRender model props ***", model.props());
							});
						});

						// adding buttons to tfoot
						let tfoot = el.querySelector(".custom-table > tfoot");
						// adding '+' icon which is when clicked on, will add an editable row in tfoot
						let addRowInTfootBtn = document.createElement("span");
						addRowInTfootBtn.setAttribute("title", "Append a new row in tfoot.");
						addRowInTfootBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/></svg>';
						addRowInTfootBtn.style.zIndex = "999";
						addRowInTfootBtn.style.backgrouund = "transparent";
						addRowInTfootBtn.style.cursor = "pointer";
						tfoot.appendChild(addRowInTfootBtn);
						// on click it should add column
						addRowInTfootBtn.addEventListener("click", (event) => {
							addRowInTfoot(
								editor,
								model,
								model.components().models.find((comp) => comp.attributes.type === "custom-table"),
								model.get("numColsInputValue"),
							);
						});

						// // adding default styling to all tr in thead, tbody & tfoot
						// let stylingTrsInThead = model.find('.custom-table')[0].find('.custom-table-thead-row');
						// stylingTrsInThead.forEach((tr) => tr.setStyle({ 'background-image': 'linear-gradient(purple, blue)', color: 'white' }));
						// // stylingTrsInThead.setStyle({ 'background-color': 'rgba(255, 65, 65, 0.3)' });

						// let stylingTfoot = model.find('.custom-table')[0].find('.custom-table-tfoot')[0];
						// stylingTfoot.setStyle({ 'background-color': 'rgba(128, 128, 128, 0.3)' });
					}
				},
			},
		});

		// listening to modal opening
		editor.on("modal", (props) => {
			console.log("modal props", props);

			// checking for custom-table-form
			if (document.getElementsByClassName("gjs-mdl-content")[0] !== undefined && document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-table-form")[0] !== undefined) {
				// number of columns modal input pointer
				const numCols = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("numCols")[0];
				const numRowsPerPage = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("numRowsPerPage")[0];
				const dataModelObjIdKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("dataModelObjIdKeyname")[0];
				// add columns at index input pointer
				// const addColAtIdx = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("addColAtIdx")[0];
				const dynamicTableFooterColumns = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("dynamicTableFooterColumns")[0];
				const showDBSizeInTableFooter = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("showDBSizeInTableFooter")[0];
				const useTablePlottingApiForPagination = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("useTablePlottingApiForPagination")[0];
				const sortingBtnsInAllCols = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("SortingBtnsInAllCols")[0];
				const rowHighlightOnBtnClick = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("rowHighlightOnBtnClick")[0];
				const referenceAttributeLabels = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("referenceAttributeLabels")[0];
				
				// pointer to the editable-api-fields div to add API fields dynamically.
				const editableApiFieldsContainer = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("editable-api-fields")[0];
				const searchEditableApiFields = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("search-editable-api-fields")[0];

				// styling input pointers
				// thead input pointers
				const tableTheadFontSize = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-font-size")[0];
				const tableTheadFontColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-font-color")[0];
				const tableTheadFontFamily = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-font-family")[0];

				const tableTheadRowBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-background-color")[0];
				const tableTheadRowEnableBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-enable-background-color")[0];
				const tableTheadRowEnableLinearGradient1 = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-enable-linear-gradient-1")[0];
				const tableTheadRowLinearGradient1 = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-linear-gradient-1")[0];
				const tableTheadRowLinearGradient2 = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-linear-gradient-2")[0];
				const tableTheadRowLinearGradientAngle = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-linear-gradient-angle")[0];

				const tableTheadRowBorderTopWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-top-width")[0];
				const tableTheadRowBorderTopType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-top-type")[0];
				const tableTheadRowBorderTopColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-top-color")[0];

				const tableTheadRowBorderRightWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-right-width")[0];
				const tableTheadRowBorderRightType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-right-type")[0];
				const tableTheadRowBorderRightColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-right-color")[0];

				const tableTheadRowBorderBottomWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-bottom-width")[0];
				const tableTheadRowBorderBottomType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-bottom-type")[0];
				const tableTheadRowBorderBottomColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-bottom-color")[0];

				const tableTheadRowBorderLeftWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-left-width")[0];
				const tableTheadRowBorderLeftType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-left-type")[0];
				const tableTheadRowBorderLeftColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-thead-row-border-left-color")[0];

				// tbody input pointers
				const tableTbodyFontSize = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-font-size")[0];
				const tableTbodyFontColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-font-color")[0];
				const tableTbodyFontFamily = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-font-family")[0];
				const tableTbodyOddRowBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-odd-row-background-color")[0];
				const tableTbodyOddRowEnableBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-odd-row-enable-background-color")[0];
				const tableTbodyEvenRowBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-even-row-background-color")[0];
				const tableTbodyEvenRowEnableBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-even-row-enable-background-color")[0];
				const tableTbodyRowHoverColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-hover-color")[0];

				const tableTbodyRowBorderTopWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-top-width")[0];
				const tableTbodyRowBorderTopType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-top-type")[0];
				const tableTbodyRowBorderTopColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-top-color")[0];

				const tableTbodyRowBorderRightWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-right-width")[0];
				const tableTbodyRowBorderRightType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-right-type")[0];
				const tableTbodyRowBorderRightColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-right-color")[0];

				const tableTbodyRowBorderBottomWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-bottom-width")[0];
				const tableTbodyRowBorderBottomType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-bottom-type")[0];
				const tableTbodyRowBorderBottomColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-bottom-color")[0];

				const tableTbodyRowBorderLeftWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-left-width")[0];
				const tableTbodyRowBorderLeftType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-left-type")[0];
				const tableTbodyRowBorderLeftColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tbody-row-border-left-color")[0];

				// tfoot input pointers
				const tableTfootFontSize = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-font-size")[0];
				const tableTfootFontColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-font-color")[0];
				const tableTfootFontFamily = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-font-family")[0];

				const tableTfootRowBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-background-color")[0];
				const tableTfootRowEnableBackgroundColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-enable-background-color")[0];
				const tableTfootRowEnableLinearGradient1 = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-enable-linear-gradient-1")[0];
				const tableTfootRowLinearGradient1 = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-linear-gradient-1")[0];
				const tableTfootRowLinearGradient2 = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-linear-gradient-2")[0];
				const tableTfootRowLinearGradientAngle = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-linear-gradient-angle")[0];

				const tableTfootRowBorderTopWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-top-width")[0];
				const tableTfootRowBorderTopType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-top-type")[0];
				const tableTfootRowBorderTopColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-top-color")[0];

				const tableTfootRowBorderRightWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-right-width")[0];
				const tableTfootRowBorderRightType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-right-type")[0];
				const tableTfootRowBorderRightColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-right-color")[0];

				const tableTfootRowBorderBottomWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-bottom-width")[0];
				const tableTfootRowBorderBottomType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-bottom-type")[0];
				const tableTfootRowBorderBottomColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-bottom-color")[0];

				const tableTfootRowBorderLeftWidth = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-left-width")[0];
				const tableTfootRowBorderLeftType = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-left-type")[0];
				const tableTfootRowBorderLeftColor = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-row-border-left-color")[0];
				
				const tableTfootTotalRowsMarginTop = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-total-rows-margin-top")[0];
				const tableTfootTotalRowsMarginRight = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-total-rows-margin-right")[0];
				const tableTfootTotalRowsMarginBottom = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-total-rows-margin-bottom")[0];
				const tableTfootTotalRowsMarginLeft = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-total-rows-margin-left")[0];
				const tableTfootCellPaddingTop = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-cell-padding-top")[0];
				const tableTfootCellPaddingRight = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-cell-padding-right")[0];
				const tableTfootCellPaddingBottom = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-cell-padding-bottom")[0];
				const tableTfootCellPaddingLeft = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-tfoot-cell-padding-left")[0];

				const tableContentAlignment = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-content-alignment")[0];

				// API trait modal inputs pointers
				const customUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-url")[0];
				const baseUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("base-url")[0];
				const apiPath = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-path")[0];
				const apiMethod = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-method")[0];
				const apiUsername = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-username")[0];
				const apiPassword = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-password")[0];
				const apiBearerToken = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-bearer-token")[0];
				const apiMoreHeaders = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-more-headers")[0];
				const apiBody = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-body")[0];
				const nestedArrOfObjKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("nested-array-of-objects-keyname")[0];

				const totalRowsApi = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api")[0];
				const totalRowsApiBaseUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-base-url")[0];
				const totalRowsApiPath = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-path")[0];
				const totalRowsApiKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-keyname")[0];
				const totalRowsApiMethod = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-method")[0];
				const totalRowsApiUsername = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-username")[0];
				const totalRowsApiPassword = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-password")[0];
				const totalRowsApiBearerToken = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-bearer-token")[0];
				const totalRowsApiMoreHeaders = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-more-headers")[0];
				const totalRowsApiBody = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-body")[0];
				const totalRowsApiNestedArrOfObjKeyname = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("total-rows-api-nested-array-of-objects-keyname")[0];

				const updateCustomUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-custom-url")[0];
				const updateBaseUrl = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-base-url")[0];
				const updateApiPath = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-api-path")[0];
				const updateQueryParam = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-query-parameter")[0];
				const updateTableAttribute = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-table-attribute")[0];
				const updateApiMethod = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-api-method")[0];
				const updateApiUsername = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-api-username")[0];
				const updateApiPassword = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-api-password")[0];
				const updateApiBearerToken = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-api-bearer-token")[0];
				const updateApiMoreHeaders = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("api-updating-api-more-headers")[0];

				// submit button pointers
				const numColstableButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("num-cols-table-btn")[0];
				const numRowsPerPageButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("num-rows-per-page-btn")[0];
				const dataModelObjIdKeynameButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("dataModelObjIdKeyname-btn")[0];
				const referenceAttributeLabelsButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("referenceAttributeLabels-btn")[0];
				// const insertColstableButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("insert-cols-table-btn")[0];
				const plottableButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("plot-table-btn")[0];
				const storeTotalRowsApiButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("store-total-rows-api-btn")[0];
				const tableStylingButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("table-styling-btn")[0];
				const editableApiFieldsButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("editable-api-fields-btn")[0];
				const updateTableButton = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("update-table-btn")[0];

				// console.log("ATB: ", numColstableButton, numRowsPerPageButton, plottableButton, storeTotalRowsApiButton, tableStylingButton, referenceAttributeLabelsButton, editableApiFieldsButton, editableApiFieldsContainer, numCols, dynamicTableFooterColumns, showDBSizeInTableFooter, tableTheadFontSize, totalRowsApi, totalRowsApiKeyname, totalRowsApiUsername, totalRowsApiPassword, totalRowsApiBearerToken, totalRowsApiMoreHeaders, totalRowsApiNestedArrOfObjKeyname, customUrl, baseUrl, apiPath, apiMethod, apiUsername, apiPassword, apiBearerToken, apiMoreHeaders, apiBody, nestedArrOfObjKeyname);

				try {
					// reattaching values in the modal's input fields for the user, so that they know what values they had entered previously
					numCols.value = props.attributes.thisModelRef.attributes.numColsInputValue;
					numRowsPerPage.value = props.attributes.thisModelRef.attributes.numRowsPerPageValue;
					dataModelObjIdKeyname.value = props.attributes.thisModelRef.attributes.DataModelObjIdKeyname;
					// addColAtIdx.value = props.attributes.thisModelRef.attributes.addColAtIdxValue;
					dynamicTableFooterColumns.checked = props.attributes.thisModelRef.attributes.dynamicTableFooterColumns;
					showDBSizeInTableFooter.checked = props.attributes.thisModelRef.attributes.showDBSizeInTableFooter;
					useTablePlottingApiForPagination.checked = props.attributes.thisModelRef.attributes.useTablePlottingApiForPagination;
					sortingBtnsInAllCols.checked = props.attributes.thisModelRef.attributes.SortingBtnsInAllCols;
					rowHighlightOnBtnClick.checked = props.attributes.thisModelRef.attributes.tBodyTrSelectColorHighlight;
					referenceAttributeLabels.value = props.attributes.thisModelRef.attributes.displayReferenceLabels;

					customUrl.value = props.attributes.thisModelRef.attributes.customUrlValue;
					baseUrl.value = props.attributes.thisModelRef.attributes.baseUrlValue;
					apiPath.value = props.attributes.thisModelRef.attributes.apiPathValue;
					apiMethod.value = props.attributes.thisModelRef.attributes.apiMethodValue;
					apiUsername.value = props.attributes.thisModelRef.attributes.apiUsernameValue;
					apiPassword.value = props.attributes.thisModelRef.attributes.apiPasswordValue;
					apiBearerToken.value = props.attributes.thisModelRef.attributes.apiBearerTokenValue;
					apiMoreHeaders.value = props.attributes.thisModelRef.attributes.apiMoreHeadersValue;
					apiBody.value = props.attributes.thisModelRef.attributes.apiBodyValue;
					nestedArrOfObjKeyname.value = props.attributes.thisModelRef.attributes.nestedArrOfObjKeynameValue;

					totalRowsApi.value = props.attributes.thisModelRef.attributes.totalRowsAPIValue;
					totalRowsApiBaseUrl.value = props.attributes.thisModelRef.attributes.totalRowsAPIBaseUrlValue;
					totalRowsApiPath.value = props.attributes.thisModelRef.attributes.totalRowsAPIPathValue;
					totalRowsApiKeyname.value = props.attributes.thisModelRef.attributes.totalRowsAPIKeynameValue;
					totalRowsApiMethod.value = props.attributes.thisModelRef.attributes.totalRowsAPIMethodValue;
					totalRowsApiUsername.value = props.attributes.thisModelRef.attributes.totalRowsAPIUsernameValue;
					totalRowsApiPassword.value = props.attributes.thisModelRef.attributes.totalRowsAPIPasswordValue;
					totalRowsApiBearerToken.value = props.attributes.thisModelRef.attributes.totalRowsAPIBearerTokenValue;
					totalRowsApiMoreHeaders.value = props.attributes.thisModelRef.attributes.totalRowsAPIMoreHeadersValue;
					totalRowsApiBody.value = props.attributes.thisModelRef.attributes.totalRowsAPIBodyValue;
					totalRowsApiNestedArrOfObjKeyname.value = props.attributes.thisModelRef.attributes.totalRowsAPINestedArrOfObjKeynameValue;

					updateCustomUrl.value = props.attributes.thisModelRef.attributes.updatingCustomUrlValue;
					updateBaseUrl.value = props.attributes.thisModelRef.attributes.updatingBaseUrlValue;
					updateApiPath.value = props.attributes.thisModelRef.attributes.updatingApiPathValue;
					updateQueryParam.value = props.attributes.thisModelRef.attributes.updatingApiQueryParamValue;
					updateTableAttribute.value = props.attributes.thisModelRef.attributes.updatingApiTableAttributeValue;
					updateApiMethod.value = props.attributes.thisModelRef.attributes.updatingApiMethodValue;
					updateApiUsername.value = props.attributes.thisModelRef.attributes.updatingApiUsernameValue;
					updateApiPassword.value = props.attributes.thisModelRef.attributes.updatingApiPasswordValue;
					updateApiBearerToken.value = props.attributes.thisModelRef.attributes.updatingApiBearerTokenValue;
					updateApiMoreHeaders.value = props.attributes.thisModelRef.attributes.updatingApiMoreHeadersValue;

					// props.attributes.thisTableRef.find('.custom-table-thead-row')[0].find('th').forEach((th) => {
					//     th.find('p').forEach((pTag) => {
					//         let existingStyles = pTag.getStyle();
					//         if ('font-size' in existingStyles) {
					//         	tableTheadFontSize.value = existingStyles['font-size'].split('vw')[0];
					//         } if ('color' in existingStyles) {
					//     		tableTheadFontColor.value = existingStyles['color'];
					//     	} if ('font-family' in existingStyles) {
					//     		tableTheadFontFamily.value = existingStyles['font-family'];
					//     	} if ('text-align' in existingStyles) {
					//     		tableContentAlignment.value = existingStyles['text-align'];
					//     	}
					//     });
					// });

					// let tableTheadRow = props.attributes.thisTableRef.find('.custom-table-thead-row')[0];
					// let tableTheadRowExistingStyles = tableTheadRow.getStyle();
					// console.log('tableTheadRowExistingStyles', tableTheadRowExistingStyles);

					// if ('background-color' in tableTheadRowExistingStyles) {
					// 	tableTheadRowBackgroundColor.value = tableTheadRowExistingStyles['background-color'];
					// }

					// if ('background-image' in tableTheadRowExistingStyles) {
					// 	let {angle, startColor, endColor} = extractLinearGradientValues(tableTheadRowExistingStyles['background-image']);
					// 	// console.log('angle, startColor, endColor', angle, startColor, endColor);
					// 	tableTheadRowLinearGradientAngle.value = angle.split('deg')[0];
					// 	tableTheadRowLinearGradient1.value = startColor;
					// 	tableTheadRowLinearGradient2.value = endColor;
					// }

					// if ('border-top' in tableTheadRowExistingStyles) {
					// 	let {width, style, color} = parseBorder(tableTheadRowExistingStyles['border-top']);
					// 	// console.log('width, style, color', width, style, color);
					// 	tableTheadRowBorderTopWidth.value = width.split('px')[0];
					// 	tableTheadRowBorderTopType.value = style;
					// 	tableTheadRowBorderTopColor.value = color;
					// }

					// if ('border-right' in tableTheadRowExistingStyles) {
					// 	let {width, style, color} = parseBorder(tableTheadRowExistingStyles['border-right']);
					// 	// console.log('width, style, color', width, style, color);
					// 	tableTheadRowBorderRightWidth.value = width.split('px')[0];
					// 	tableTheadRowBorderRightType.value = style;
					// 	tableTheadRowBorderRightColor.value = color;
					// }

					// if ('border-bottom' in tableTheadRowExistingStyles) {
					// 	let {width, style, color} = parseBorder(tableTheadRowExistingStyles['border-bottom']);
					// 	// console.log('width, style, color', width, style, color);
					// 	tableTheadRowBorderBottomWidth.value = width.split('px')[0];
					// 	tableTheadRowBorderBottomType.value = style;
					// 	tableTheadRowBorderBottomColor.value = color;
					// }

					// if ('border-left' in tableTheadRowExistingStyles) {
					// 	let {width, style, color} = parseBorder(tableTheadRowExistingStyles['border-left']);
					// 	// console.log('width, style, color', width, style, color);
					// 	tableTheadRowBorderLeftWidth.value = width.split('px')[0];
					// 	tableTheadRowBorderLeftType.value = style;
					// 	tableTheadRowBorderLeftColor.value = color;
					// }

					tableTheadFontSize.value = props.attributes.thisModelRef.attributes.tableTheadFontSize;
					tableTheadFontColor.value = props.attributes.thisModelRef.attributes.tableTheadFontColor;
					tableTheadFontFamily.value = props.attributes.thisModelRef.attributes.tableTheadFontFamily;
					tableTheadRowBackgroundColor.value = props.attributes.thisModelRef.attributes.tableTheadRowBackgroundColor;
					tableTheadRowLinearGradient1.value = props.attributes.thisModelRef.attributes.tableTheadRowLinearGradient1;
					tableTheadRowLinearGradient2.value = props.attributes.thisModelRef.attributes.tableTheadRowLinearGradient2;
					tableTheadRowLinearGradientAngle.value = props.attributes.thisModelRef.attributes.tableTheadRowLinearGradientAngle;

					tableTheadRowBorderTopWidth.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderTopWidth;
					tableTheadRowBorderTopType.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderTopType;
					tableTheadRowBorderTopColor.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderTopColor;

					tableTheadRowBorderRightWidth.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderRightWidth;
					tableTheadRowBorderRightType.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderRightType;
					tableTheadRowBorderRightColor.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderRightColor;

					tableTheadRowBorderBottomWidth.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderBottomWidth;
					tableTheadRowBorderBottomType.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderBottomType;
					tableTheadRowBorderBottomColor.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderBottomColor;

					tableTheadRowBorderLeftWidth.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderLeftWidth;
					tableTheadRowBorderLeftType.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderLeftType;
					tableTheadRowBorderLeftColor.value = props.attributes.thisModelRef.attributes.tableTheadRowBorderLeftColor;

					tableTfootFontSize.value = props.attributes.thisModelRef.attributes.tableTfootFontSize;
					tableTfootFontColor.value = props.attributes.thisModelRef.attributes.tableTfootFontColor;
					tableTfootFontFamily.value = props.attributes.thisModelRef.attributes.tableTfootFontFamily;
					tableTfootRowBackgroundColor.value = props.attributes.thisModelRef.attributes.tableTfootRowBackgroundColor;
					tableTfootRowLinearGradient1.value = props.attributes.thisModelRef.attributes.tableTfootRowLinearGradient1;
					tableTfootRowLinearGradient2.value = props.attributes.thisModelRef.attributes.tableTfootRowLinearGradient2;
					tableTfootRowLinearGradientAngle.value = props.attributes.thisModelRef.attributes.tableTfootRowLinearGradientAngle;

					tableTfootRowBorderTopWidth.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderTopWidth;
					tableTfootRowBorderTopType.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderTopType;
					tableTfootRowBorderTopColor.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderTopColor;

					tableTfootRowBorderRightWidth.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderRightWidth;
					tableTfootRowBorderRightType.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderRightType;
					tableTfootRowBorderRightColor.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderRightColor;

					tableTfootRowBorderBottomWidth.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderBottomWidth;
					tableTfootRowBorderBottomType.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderBottomType;
					tableTfootRowBorderBottomColor.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderBottomColor;

					tableTfootRowBorderLeftWidth.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderLeftWidth;
					tableTfootRowBorderLeftType.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderLeftType;
					tableTfootRowBorderLeftColor.value = props.attributes.thisModelRef.attributes.tableTfootRowBorderLeftColor;
					
					tableTfootTotalRowsMarginTop.value = props.attributes.thisModelRef.attributes.tableTfootTotalRowsMarginTop;
					tableTfootTotalRowsMarginRight.value = props.attributes.thisModelRef.attributes.tableTfootTotalRowsMarginRight;
					tableTfootTotalRowsMarginBottom.value = props.attributes.thisModelRef.attributes.tableTfootTotalRowsMarginBottom;
					tableTfootTotalRowsMarginLeft.value = props.attributes.thisModelRef.attributes.tableTfootTotalRowsMarginLeft;
					tableTfootCellPaddingTop.value = props.attributes.thisModelRef.attributes.tableTfootCellPaddingTop;
					tableTfootCellPaddingRight.value = props.attributes.thisModelRef.attributes.tableTfootCellPaddingRight;
					tableTfootCellPaddingBottom.value = props.attributes.thisModelRef.attributes.tableTfootCellPaddingBottom;
					tableTfootCellPaddingLeft.value = props.attributes.thisModelRef.attributes.tableTfootCellPaddingLeft;

					// let tableTfoot = props.attributes.thisTableRef
					//     .find(".custom-table-tfoot")[0]
					//     .find("td");
					// if (tableTfoot.length) {
					//     tableTfoot.forEach((td) => {
					//         td.find("p").forEach((pTag) => {
					//             let existingStyles = pTag.getStyle();
					//             if ("font-size" in existingStyles) {
					//                 tableTfootFontSize.value =
					//                     existingStyles["font-size"].split("vw")[0];
					//             }
					//             if ("color" in existingStyles) {
					//                 tableTfootFontColor.value = existingStyles["color"];
					//             }
					//             if ("font-family" in existingStyles) {
					//                 tableTfootFontFamily.value = existingStyles["font-family"];
					//             }
					//             if ("text-align" in existingStyles) {
					//                 tableContentAlignment.value = existingStyles["text-align"];
					//             }
					//         });
					//     });
					// }

					// let tableTfootRow = props.attributes.thisTableRef.find(
					//     ".custom-table-tfoot-row"
					// );
					// if (tableTfootRow) {
					//     tableTfootRow.forEach((tr) => {
					//         let tableTfootRowExistingStyles = tr.getStyle();
					//         if ("background-color" in tableTfootRowExistingStyles) {
					//             tableTfootRowBackgroundColor.value =
					//                 tableTfootRowExistingStyles["background-color"];
					//         }

					//         if ("background-image" in tableTfootRowExistingStyles) {
					//             let {
					//                 angle,
					//                 startColor,
					//                 endColor
					//             } =
					//             extractLinearGradientValues(
					//                 tableTfootRowExistingStyles["background-image"]
					//             );
					//             // console.log('angle, startColor, endColor', angle, startColor, endColor);
					//             tableTfootRowLinearGradientAngle.value = angle.split("deg")[0];
					//             tableTfootRowLinearGradient1.value = startColor;
					//             tableTfootRowLinearGradient2.value = endColor;
					//         }

					//         if ("border-top" in tableTfootRowExistingStyles) {
					//             let {
					//                 width,
					//                 style,
					//                 color
					//             } = parseBorder(
					//                 tableTfootRowExistingStyles["border-top"]
					//             );
					//             // console.log('width, style, color', width, style, color);
					//             tableTfootRowBorderTopWidth.value = width.split("px")[0];
					//             tableTfootRowBorderTopType.value = style;
					//             tableTfootRowBorderTopColor.value = color;
					//         }

					//         if ("border-right" in tableTfootRowExistingStyles) {
					//             let {
					//                 width,
					//                 style,
					//                 color
					//             } = parseBorder(
					//                 tableTfootRowExistingStyles["border-right"]
					//             );
					//             // console.log('width, style, color', width, style, color);
					//             tableTfootRowBorderRightWidth.value = width.split("px")[0];
					//             tableTfootRowBorderRightType.value = style;
					//             tableTfootRowBorderRightColor.value = color;
					//         }

					//         if ("border-bottom" in tableTfootRowExistingStyles) {
					//             let {
					//                 width,
					//                 style,
					//                 color
					//             } = parseBorder(
					//                 tableTfootRowExistingStyles["border-bottom"]
					//             );
					//             // console.log('width, style, color', width, style, color);
					//             tableTfootRowBorderBottomWidth.value = width.split("px")[0];
					//             tableTfootRowBorderBottomType.value = style;
					//             tableTfootRowBorderBottomColor.value = color;
					//         }

					//         if ("border-left" in tableTfootRowExistingStyles) {
					//             let {
					//                 width,
					//                 style,
					//                 color
					//             } = parseBorder(
					//                 tableTfootRowExistingStyles["border-left"]
					//             );
					//             // console.log('width, style, color', width, style, color);
					//             tableTfootRowBorderLeftWidth.value = width.split("px")[0];
					//             tableTfootRowBorderLeftType.value = style;
					//             tableTfootRowBorderLeftColor.value = color;
					//         }
					//     });
					// }

					tableTbodyFontSize.value = props.attributes.thisModelRef.attributes.tBodyTdFontSize.split("vw")[0];
					tableTbodyFontColor.value = props.attributes.thisModelRef.attributes.tBodyTdFontColor;
					tableTbodyFontFamily.value = props.attributes.thisModelRef.attributes.tBodyTdFontFamily;
					tableTbodyOddRowBackgroundColor.value = props.attributes.thisModelRef.attributes.tBodyOddTrBackgroundColor;
					tableTbodyEvenRowBackgroundColor.value = props.attributes.thisModelRef.attributes.tBodyEvenTrBackgroundColor;
					tableTbodyRowHoverColor.value = props.attributes.thisModelRef.attributes.tBodyTrHoverColor;

					tableTbodyRowBorderTopWidth.value = props.attributes.thisModelRef.attributes.tBodyTrBorderTopWidth;
					tableTbodyRowBorderTopType.value = props.attributes.thisModelRef.attributes.tBodyTrBorderTopType;
					tableTbodyRowBorderTopColor.value = props.attributes.thisModelRef.attributes.tBodyTrBorderTopColor;

					tableTbodyRowBorderRightWidth.value = props.attributes.thisModelRef.attributes.tBodyTrBorderRightWidth;
					tableTbodyRowBorderRightType.value = props.attributes.thisModelRef.attributes.tBodyTrBorderRightType;
					tableTbodyRowBorderRightColor.value = props.attributes.thisModelRef.attributes.tBodyTrBorderRightColor;

					tableTbodyRowBorderBottomWidth.value = props.attributes.thisModelRef.attributes.tBodyTrBorderBottomWidth;
					tableTbodyRowBorderBottomType.value = props.attributes.thisModelRef.attributes.tBodyTrBorderBottomType;
					tableTbodyRowBorderBottomColor.value = props.attributes.thisModelRef.attributes.tBodyTrBorderBottomColor;

					tableTbodyRowBorderLeftWidth.value = props.attributes.thisModelRef.attributes.tBodyTrBorderLeftWidth;
					tableTbodyRowBorderLeftType.value = props.attributes.thisModelRef.attributes.tBodyTrBorderLeftType;
					tableTbodyRowBorderLeftColor.value = props.attributes.thisModelRef.attributes.tBodyTrBorderLeftColor;

					tableContentAlignment.value = props.attributes.thisModelRef.attributes.tBodyTdTextAlign;
				} catch (e) {
					// statements
					console.log(e);
				}

				try {
					// take actions every time a submit button is clicked
					numColstableButton.addEventListener("click", () => {
						// if in the modal, the user provides header fields to put to the table headers
						if (numCols.value) {
							// also sending the table Reference so that if there are multiple tables, the modal applies changes to the one on which the user opened the modal
							createHeaderFields(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, numCols.value, tableTheadFontSize.value, tableTheadFontColor.value, tableTheadFontFamily.value, tableTheadRowBackgroundColor.value, tableTheadRowEnableBackgroundColor.value, tableTheadRowEnableLinearGradient1.value, tableTheadRowLinearGradient1.value, tableTheadRowLinearGradient2.value, tableTheadRowLinearGradientAngle.value, tableTheadRowBorderTopWidth.value, tableTheadRowBorderTopType.value, tableTheadRowBorderTopColor.value, tableTheadRowBorderRightWidth.value, tableTheadRowBorderRightType.value, tableTheadRowBorderRightColor.value, tableTheadRowBorderBottomWidth.value, tableTheadRowBorderBottomType.value, tableTheadRowBorderBottomColor.value, tableTheadRowBorderLeftWidth.value, tableTheadRowBorderLeftType.value, tableTheadRowBorderLeftColor.value);
						}

						// close the modal on clicking the button, at the end
						editor.Modal.close();
					});

					numRowsPerPageButton.addEventListener("click", () => {
						// if in the modal, the user provides header fields to put to the table headers
						if (numRowsPerPage.value) {
							// also sending the table Reference so that if there are multiple tables, the modal applies changes to the one on which the user opened the modal
							setNumberOfRowsPerPage(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, numRowsPerPage.value);
						}

						// close the modal on clicking the button, at the end
						editor.Modal.close();
					});

					dataModelObjIdKeynameButton.addEventListener("click", () => {
						// if in the modal, the user provides header fields to put to the table headers
						if (dataModelObjIdKeyname.value) {
							// also sending the table Reference so that if there are multiple tables, the modal applies changes to the one on which the user opened the modal
							props.attributes.thisModelRef.set("DataModelObjIdKeyname", dataModelObjIdKeyname.value);
							props.attributes.thisModelRef.attributes.script.call(props.attributes.thisModelRef.getEl(), props.attributes.thisModelRef.props());
						}

						// close the modal on clicking the button, at the end
						editor.Modal.close();
					});

					dynamicTableFooterColumns.addEventListener("change", () => {
						// if in the modal, the user turns the dynamic ability on footer columns, on
						if (dynamicTableFooterColumns.checked) {
							props.attributes.thisModelRef.attributes.dynamicTableFooterColumns = true;
						} else {
							props.attributes.thisModelRef.attributes.dynamicTableFooterColumns = false;
						}

						console.log("model updated", props.attributes.thisModelRef);
						// close the modal on clicking the button, at the end
						// editor.Modal.close();
					});

					showDBSizeInTableFooter.addEventListener("change", () => {
						// if in the modal, the user turns the dynamic ability on footer columns, on
						if (showDBSizeInTableFooter.checked) {
							props.attributes.thisModelRef.set("showDBSizeInTableFooter", true);
						} else {
							props.attributes.thisModelRef.set("showDBSizeInTableFooter", false);
						}

						console.log("model updated", props.attributes.thisModelRef);
						// close the modal on clicking the button, at the end
						// editor.Modal.close();
					});

					useTablePlottingApiForPagination.addEventListener("change", () => {
						// if in the modal, the user turns the dynamic ability on footer columns, on
						if (useTablePlottingApiForPagination.checked) {
							props.attributes.thisModelRef.set("useTablePlottingApiForPagination", true);

							// if user does not have total-rows api then they check this checkbox and the total-rows api tab should get disabled so that user doesn't provide inputs there
							document.getElementsByClassName("gjs-mdl-content")[0].querySelector("#total-rows-api-tab").setAttribute("disabled", "true");
						} else {
							props.attributes.thisModelRef.set("useTablePlottingApiForPagination", false);

							let totalRowsApiTab = document.getElementsByClassName("gjs-mdl-content")[0].querySelector("#total-rows-api-tab");
							if (totalRowsApiTab.hasAttribute("disabled")) {
								totalRowsApiTab.removeAttribute("disabled");
							}
						}

						console.log("model updated", props.attributes.thisModelRef);
						// close the modal on clicking the button, at the end
						// editor.Modal.close();
					});

					sortingBtnsInAllCols.addEventListener("change", () => {
						// if in the modal, the user turns the dynamic ability on footer columns, on
						if (sortingBtnsInAllCols.checked) {
							props.attributes.thisModelRef.set("SortingBtnsInAllCols", true);
						} else {
							props.attributes.thisModelRef.set("SortingBtnsInAllCols", false);
						}

						console.log("model updated", props.attributes.thisModelRef);
						// close the modal on clicking the button, at the end
						// editor.Modal.close();
					});

					rowHighlightOnBtnClick.addEventListener("change", () => {
						// if in the modal, the user turns the dynamic ability on footer columns, on
						if (rowHighlightOnBtnClick.checked) {
							props.attributes.thisModelRef.set("tBodyTrSelectColorHighlight", true);
						} else {
							props.attributes.thisModelRef.set("tBodyTrSelectColorHighlight", false);
						}

						console.log("model updated", props.attributes.thisModelRef);
						// close the modal on clicking the button, at the end
						// editor.Modal.close();
					});

					// keep the total-rows api tab disabled after refreshing also, if the user chose this option previously
					if (useTablePlottingApiForPagination.checked) {
						document.getElementsByClassName("gjs-mdl-content")[0].querySelector("#total-rows-api-tab").setAttribute("disabled", "true");
					} else {
						let totalRowsApiTab = document.getElementsByClassName("gjs-mdl-content")[0].querySelector("#total-rows-api-tab");
						if (totalRowsApiTab.hasAttribute("disabled")) {
							totalRowsApiTab.removeAttribute("disabled");
						}
					}

					// making the numRowsPerPage input disabled when paginationRequired trait is turned off
					console.log("+paginationRequired+", props.attributes.thisModelRef.attributes.paginationRequired);
					if (!props.attributes.thisModelRef.attributes.paginationRequired) {
						numRowsPerPage.disabled = true;
						numRowsPerPage.style.color = "gray";
						numRowsPerPageButton.style.pointerEvents = "none";
						numRowsPerPageButton.style.backgroundImage = "";
						numRowsPerPageButton.style.background = "rgba(128, 128, 128, 0.9)";
					} else {
						numRowsPerPage.disabled = false;
						numRowsPerPage.style.color = "black";
						numRowsPerPageButton.style.pointerEvents = "auto";
						numRowsPerPageButton.style.backgroundImage = "linear-gradient(180deg, #0096FF, #00008B)";
						numRowsPerPageButton.style.background = "";
					}

					// insertColstableButton.addEventListener("click", () => {
					// 	// if in the modal, the user provides the index position at which a column will be added
					// 	if (addColAtIdx.value) {
					// 		// also sending the table Reference so that if there are multiple tables, the modal applies changes to the one on which the user opened the modal
					// 		addColumnAtIndex(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, addColAtIdx.value, numCols.value);
					// 	}

					// 	// close the modal on clicking the button, at the end
					// 	editor.Modal.close();
					// });

					plottableButton.addEventListener("click", () => {
						// if number-of-columns and the api related inputs are provided
						if (customUrl.value || baseUrl.value || apiPath.value || apiMethod.value || apiUsername.value || apiPassword.value || apiBearerToken.value || apiMoreHeaders.value || apiBody.value) {
							// get data and plot the table columns
							plotTable(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, customUrl.value, baseUrl.value, apiPath.value, apiMethod.value, apiUsername.value, apiPassword.value, apiBearerToken.value, apiMoreHeaders.value, apiBody.value, nestedArrOfObjKeyname.value);
							// updateMainTableEditModal(editor, editableApiFieldsContainer, customUrl.value, baseUrl.value, apiPath.value, apiMethod.value, apiUsername.value, apiPassword.value, apiBearerToken.value, apiMoreHeaders.value, apiBody.value, nestedArrOfObjKeyname.value);
						}

						// close the modal on clicking the button, at the end
						editor.Modal.close();
					});

					updateTableButton.addEventListener("click", () => {
						// if number-of-columns and the api related inputs are provided
						if (updateCustomUrl.value || updateBaseUrl.value || updateApiPath.value || updateQueryParam.value || updateTableAttribute.value || updateApiMethod.value || updateApiUsername.value || updateApiPassword.value || updateApiBearerToken.value || updateApiMoreHeaders.value) {
							// get data and plot the table columns
							updateTable(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, updateCustomUrl.value, updateBaseUrl.value, updateApiPath.value, updateQueryParam.value, updateTableAttribute.value, updateApiMethod.value, updateApiUsername.value, updateApiPassword.value, updateApiBearerToken.value, updateApiMoreHeaders.value);
							// updateMainTableEditModal(editor, editableApiFieldsContainer, customUrl.value, baseUrl.value, apiPath.value, apiMethod.value, apiUsername.value, apiPassword.value, apiBearerToken.value, apiMoreHeaders.value, apiBody.value, nestedArrOfObjKeyname.value);
						}

						// close the modal on clicking the button, at the end
						editor.Modal.close();
					});

					storeTotalRowsApiButton.addEventListener("click", () => {
						// if number-of-columns and the api related inputs are provided
						if (totalRowsApi.value || totalRowsApiKeyname.value || totalRowsApiUsername.value || totalRowsApiPassword.value || totalRowsApiBearerToken.value || totalRowsApiMoreHeaders.value) {
							// get data and plot the table columns
							storeTotalRowsApi(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, totalRowsApi.value, totalRowsApiBaseUrl.value, totalRowsApiPath.value, totalRowsApiKeyname.value, totalRowsApiMethod.value, totalRowsApiUsername.value, totalRowsApiPassword.value, totalRowsApiBearerToken.value, totalRowsApiMoreHeaders.value, totalRowsApiBody.value, totalRowsApiNestedArrOfObjKeyname.value);
						}

						// close the modal on clicking the button, at the end
						editor.Modal.close();
					});

					referenceAttributeLabelsButton.addEventListener("click", () => {
						// if in the modal, the user turns the dynamic ability on footer columns, on
						if (referenceAttributeLabels.value) {
							props.attributes.thisModelRef.set("displayReferenceLabels", referenceAttributeLabels.value);
							props.attributes.thisModelRef.attributes.script.call(props.attributes.thisModelRef.getEl(), props.attributes.thisModelRef.props());
						}

						console.log("model updated", props.attributes.thisModelRef);
						// close the modal on clicking the button, at the end
						editor.Modal.close();
					});

					// styling table elements
					tableStylingButton.addEventListener("click", () => {
						props.attributes.thisModelRef.set({
							tableTheadFontSize: tableTheadFontSize.value,
							tableTheadFontColor: tableTheadFontColor.value,
							tableTheadFontFamily: tableTheadFontFamily.value,
							tableTheadRowBackgroundColor: tableTheadRowBackgroundColor.value,
							tableTheadRowLinearGradient1: tableTheadRowLinearGradient1.value,
							tableTheadRowLinearGradient2: tableTheadRowLinearGradient2.value,
							tableTheadRowLinearGradientAngle: tableTheadRowLinearGradientAngle.value,
							tableTheadRowBorderTopWidth: tableTheadRowBorderTopWidth.value,
							tableTheadRowBorderTopType: tableTheadRowBorderTopType.value,
							tableTheadRowBorderTopColor: tableTheadRowBorderTopColor.value,
							tableTheadRowBorderRightWidth: tableTheadRowBorderRightWidth.value,
							tableTheadRowBorderRightType: tableTheadRowBorderRightType.value,
							tableTheadRowBorderRightColor: tableTheadRowBorderRightColor.value,
							tableTheadRowBorderBottomWidth: tableTheadRowBorderBottomWidth.value,
							tableTheadRowBorderBottomType: tableTheadRowBorderBottomType.value,
							tableTheadRowBorderBottomColor: tableTheadRowBorderBottomColor.value,
							tableTheadRowBorderLeftWidth: tableTheadRowBorderLeftWidth.value,
							tableTheadRowBorderLeftType: tableTheadRowBorderLeftType.value,
							tableTheadRowBorderLeftColor: tableTheadRowBorderLeftColor.value,
							tableTheadRowEnableBackgroundColor: tableTheadRowEnableBackgroundColor.checked,
							tableTheadRowEnableLinearGradient1: tableTheadRowEnableLinearGradient1.checked,

							tableTfootFontSize: tableTfootFontSize.value,
							tableTfootFontColor: tableTfootFontColor.value,
							tableTfootFontFamily: tableTfootFontFamily.value,
							tableTfootRowBackgroundColor: tableTfootRowBackgroundColor.value,
							tableTfootRowLinearGradient1: tableTfootRowLinearGradient1.value,
							tableTfootRowLinearGradient2: tableTfootRowLinearGradient2.value,
							tableTfootRowLinearGradientAngle: tableTfootRowLinearGradientAngle.value,
							tableTfootRowBorderTopWidth: tableTfootRowBorderTopWidth.value,
							tableTfootRowBorderTopType: tableTfootRowBorderTopType.value,
							tableTfootRowBorderTopColor: tableTfootRowBorderTopColor.value,
							tableTfootRowBorderRightWidth: tableTfootRowBorderRightWidth.value,
							tableTfootRowBorderRightType: tableTfootRowBorderRightType.value,
							tableTfootRowBorderRightColor: tableTfootRowBorderRightColor.value,
							tableTfootRowBorderBottomWidth: tableTfootRowBorderBottomWidth.value,
							tableTfootRowBorderBottomType: tableTfootRowBorderBottomType.value,
							tableTfootRowBorderBottomColor: tableTfootRowBorderBottomColor.value,
							tableTfootRowBorderLeftWidth: tableTfootRowBorderLeftWidth.value,
							tableTfootRowBorderLeftType: tableTfootRowBorderLeftType.value,
							tableTfootRowBorderLeftColor: tableTfootRowBorderLeftColor.value,
							tableTfootRowEnableBackgroundColor: tableTfootRowEnableBackgroundColor.checked,
							tableTfootRowEnableLinearGradient1: tableTfootRowEnableLinearGradient1.checked,							
							tableTfootTotalRowsMarginTop: tableTfootTotalRowsMarginTop.value,
							tableTfootTotalRowsMarginRight: tableTfootTotalRowsMarginRight.value,
							tableTfootTotalRowsMarginBottom: tableTfootTotalRowsMarginBottom.value,
							tableTfootTotalRowsMarginLeft: tableTfootTotalRowsMarginLeft.value,
							tableTfootCellPaddingTop: tableTfootCellPaddingTop.value,
							tableTfootCellPaddingRight: tableTfootCellPaddingRight.value,
							tableTfootCellPaddingBottom: tableTfootCellPaddingBottom.value,
							tableTfootCellPaddingLeft: tableTfootCellPaddingLeft.value,
						});
						// setting styles
						// if (tableTheadFontSize.value || tableTheadFontColor.value || tableTheadFontFamily.value || tableTheadRowBackgroundColor.value || tableTbodyFontSize.value || tableTfootFontSize.value || tableContentAlignment.value) {}
						// styles for - thead th
						let tableThead = props.attributes.thisTableRef.find(".custom-table-thead-row")[0].find("th");
						if (tableThead) {
							tableThead.forEach((th) => {
								th.find("p").forEach((pTag) => {
									let existingStyles = pTag.getStyle();

									if (tableTheadFontSize.value) {
										existingStyles["font-size"] = `${tableTheadFontSize.value}vw`;
									}

									if (tableTheadFontColor.value) {
										existingStyles["color"] = `${tableTheadFontColor.value}`;
									}

									if (tableTheadFontFamily.value) {
										existingStyles["font-family"] = `${tableTheadFontFamily.value}`;
									}

									if (tableContentAlignment.value) {
										existingStyles["text-align"] = `${tableContentAlignment.value}`;
									}

									pTag.setStyle(existingStyles);
								});
							});
						}

						let tableTheadRowThs = props.attributes.thisTableRef.find(".custom-table-thead-row")[0].find('th');
						tableTheadRowThs.forEach(th => {
							let tableTheadRowExistingStyles = th.getStyle();

							if (tableTheadRowBackgroundColor.value && tableTheadRowEnableBackgroundColor.checked) {
								// if background-image: linear-grad() is set, then unset it so that background-color can work
								if ("background-image" in tableTheadRowExistingStyles) {
									tableTheadRowExistingStyles["background-image"] = ``;
								}
								tableTheadRowExistingStyles["background-color"] = `${tableTheadRowBackgroundColor.value}`;
							}

							if (((tableTheadRowLinearGradient1.value && tableTheadRowLinearGradient2.value) || tableTheadRowLinearGradientAngle.value) && tableTheadRowEnableLinearGradient1.checked) {
								// if background-color is set, then unset it so that background-color can work
								if ("background-color" in tableTheadRowExistingStyles) {
									tableTheadRowExistingStyles["background-color"] = ``;
								}
								tableTheadRowExistingStyles["background-image"] = `linear-gradient(${tableTheadRowLinearGradientAngle.value ? tableTheadRowLinearGradientAngle.value : 180}deg, ${tableTheadRowLinearGradient1.value}, ${tableTheadRowLinearGradient2.value})`;
							}

							if (tableTheadRowBorderTopWidth.value && tableTheadRowBorderTopType.value && tableTheadRowBorderTopColor.value) {
								tableTheadRowExistingStyles["border-top"] = `${tableTheadRowBorderTopWidth.value}px ${tableTheadRowBorderTopType.value} ${tableTheadRowBorderTopColor.value}`;
							}

							if (tableTheadRowBorderRightWidth.value && tableTheadRowBorderRightType.value && tableTheadRowBorderRightColor.value) {
								tableTheadRowExistingStyles["border-right"] = `${tableTheadRowBorderRightWidth.value}px ${tableTheadRowBorderRightType.value} ${tableTheadRowBorderRightColor.value}`;
							}

							if (tableTheadRowBorderBottomWidth.value && tableTheadRowBorderBottomType.value && tableTheadRowBorderBottomColor.value) {
								tableTheadRowExistingStyles["border-bottom"] = `${tableTheadRowBorderBottomWidth.value}px ${tableTheadRowBorderBottomType.value} ${tableTheadRowBorderBottomColor.value}`;
							}

							if (tableTheadRowBorderLeftWidth.value && tableTheadRowBorderLeftType.value && tableTheadRowBorderLeftColor.value) {
								tableTheadRowExistingStyles["border-left"] = `${tableTheadRowBorderLeftWidth.value}px ${tableTheadRowBorderLeftType.value} ${tableTheadRowBorderLeftColor.value}`;
							}
							th.setStyle(tableTheadRowExistingStyles);
							// console.log("tableTheadRow, tableTheadRowExistingStyles", th, tableTheadRowExistingStyles);
						});
						

						// styles for - tfoot td
						let tableTfoot = props.attributes.thisTableRef.find(".custom-table-tfoot")[0].find("td");
						if (tableTfoot.length) {
							tableTfoot.forEach((td) => {
								td.find("p").forEach((pTag) => {
									let existingStyles = pTag.getStyle();

									if (tableTfootFontSize.value) {
										existingStyles["font-size"] = `${tableTfootFontSize.value}vw`;
									}

									if (tableTfootFontColor.value) {
										existingStyles["color"] = `${tableTfootFontColor.value}`;
									}

									if (tableTfootFontFamily.value) {
										existingStyles["font-family"] = `${tableTfootFontFamily.value}`;
									}

									if (tableContentAlignment.value) {
										existingStyles["text-align"] = `${tableContentAlignment.value}`;
									}

									if (tableTfootTotalRowsMarginTop.value) {
										existingStyles["margin-top"] = `${tableTfootTotalRowsMarginTop.value}vh`;
									}

									if (tableTfootTotalRowsMarginRight.value) {
										existingStyles["margin-right"] = `${tableTfootTotalRowsMarginRight.value}vw`;
									}

									if (tableTfootTotalRowsMarginBottom.value) {
										existingStyles["margin-bottom"] = `${tableTfootTotalRowsMarginBottom.value}vh`;
									}

									if (tableTfootTotalRowsMarginLeft.value) {
										existingStyles["margin-left"] = `${tableTfootTotalRowsMarginLeft.value}vw`;
									}

									pTag.setStyle(existingStyles);
								});

								let tdExistingStyles = td.getStyle();

								if (tableTfootCellPaddingTop.value) {
									tdExistingStyles["padding-top"] = `${tableTfootCellPaddingTop.value}vh`;
								}

								if (tableTfootCellPaddingRight.value) {
									tdExistingStyles["padding-right"] = `${tableTfootCellPaddingRight.value}vw`;
								}

								if (tableTfootCellPaddingBottom.value) {
									tdExistingStyles["padding-bottom"] = `${tableTfootCellPaddingBottom.value}vh`;
								}

								if (tableTfootCellPaddingLeft.value) {
									tdExistingStyles["padding-left"] = `${tableTfootCellPaddingLeft.value}vw`;
								}

								td.setStyle(tdExistingStyles);
							});
						}

						let tableTfootRow = props.attributes.thisTableRef.find(".custom-table-tfoot")[0].find("tr");
						if (tableTfootRow.length) {
							tableTfootRow.forEach((tr) => {
								let tableTfootRowExistingStyles = tr.getStyle();
								if (tableTfootRowBackgroundColor.value && tableTfootRowEnableBackgroundColor.checked) {
									// if background-image: linear-grad() is set, then unset it so that background-color can work
									if ("background-image" in tableTfootRowExistingStyles) {
										tableTfootRowExistingStyles["background-image"] = ``;
									}
									tableTfootRowExistingStyles["background-color"] = `${tableTfootRowBackgroundColor.value} !important`;
								}

								if (((tableTfootRowLinearGradient1.value && tableTfootRowLinearGradient2.value) || tableTfootRowLinearGradientAngle.value) && tableTfootRowEnableLinearGradient1.checked) {
									// if background-color is set, then unset it so that background-color can work
									if ("background-color" in tableTfootRowExistingStyles) {
										tableTfootRowExistingStyles["background-color"] = ``;
									}
									tableTfootRowExistingStyles["background-image"] = `linear-gradient(${tableTfootRowLinearGradientAngle.value ? tableTfootRowLinearGradientAngle.value : 180}deg, ${tableTfootRowLinearGradient1.value}, ${tableTfootRowLinearGradient2.value})`;
								}

								if (tableTfootRowBorderTopWidth.value && tableTfootRowBorderTopType.value && tableTfootRowBorderTopColor.value) {
									tableTfootRowExistingStyles["border-top"] = `${tableTfootRowBorderTopWidth.value}px ${tableTfootRowBorderTopType.value} ${tableTfootRowBorderTopColor.value}`;
								}

								if (tableTfootRowBorderRightWidth.value && tableTfootRowBorderRightType.value && tableTfootRowBorderRightColor.value) {
									tableTfootRowExistingStyles["border-right"] = `${tableTfootRowBorderRightWidth.value}px ${tableTfootRowBorderRightType.value} ${tableTfootRowBorderRightColor.value}`;
								}

								if (tableTfootRowBorderBottomWidth.value && tableTfootRowBorderBottomType.value && tableTfootRowBorderBottomColor.value) {
									tableTfootRowExistingStyles["border-bottom"] = `${tableTfootRowBorderBottomWidth.value}px ${tableTfootRowBorderBottomType.value} ${tableTfootRowBorderBottomColor.value}`;
								}

								if (tableTfootRowBorderLeftWidth.value && tableTfootRowBorderLeftType.value && tableTfootRowBorderLeftColor.value) {
									tableTfootRowExistingStyles["border-left"] = `${tableTfootRowBorderLeftWidth.value}px ${tableTfootRowBorderLeftType.value} ${tableTfootRowBorderLeftColor.value}`;
								}
								tr.setStyle(tableTfootRowExistingStyles);
							});
						}

						let tableTfootPaginationRow = props.attributes.thisTableRef.find(".custom-table-tfoot-row-for-pagination");
						if (tableTfootRow.length) {
							tableTfootPaginationRow.forEach((tr) => {
								let tableTfootRowExistingStyles = tr.getStyle();
								if (tableTfootRowBackgroundColor.value && tableTfootRowEnableBackgroundColor.checked) {
									// if background-image: linear-grad() is set, then unset it so that background-color can work
									if ("background-image" in tableTfootRowExistingStyles) {
										tableTfootRowExistingStyles["background-image"] = ``;
									}
									tableTfootRowExistingStyles["background-color"] = `${tableTfootRowBackgroundColor.value} !important`;
								}

								if (((tableTfootRowLinearGradient1.value && tableTfootRowLinearGradient2.value) || tableTfootRowLinearGradientAngle.value) && tableTfootRowEnableLinearGradient1.checked) {
									// if background-color is set, then unset it so that background-color can work
									if ("background-color" in tableTfootRowExistingStyles) {
										tableTfootRowExistingStyles["background-color"] = ``;
									}
									tableTfootRowExistingStyles["background-image"] = `linear-gradient(${tableTfootRowLinearGradientAngle.value ? tableTfootRowLinearGradientAngle.value : 180}deg, ${tableTfootRowLinearGradient1.value}, ${tableTfootRowLinearGradient2.value})`;
								}

								if (tableTfootRowBorderTopWidth.value && tableTfootRowBorderTopType.value && tableTfootRowBorderTopColor.value) {
									tableTfootRowExistingStyles["border-top"] = `${tableTfootRowBorderTopWidth.value}px ${tableTfootRowBorderTopType.value} ${tableTfootRowBorderTopColor.value}`;
								}

								if (tableTfootRowBorderRightWidth.value && tableTfootRowBorderRightType.value && tableTfootRowBorderRightColor.value) {
									tableTfootRowExistingStyles["border-right"] = `${tableTfootRowBorderRightWidth.value}px ${tableTfootRowBorderRightType.value} ${tableTfootRowBorderRightColor.value}`;
								}

								if (tableTfootRowBorderBottomWidth.value && tableTfootRowBorderBottomType.value && tableTfootRowBorderBottomColor.value) {
									tableTfootRowExistingStyles["border-bottom"] = `${tableTfootRowBorderBottomWidth.value}px ${tableTfootRowBorderBottomType.value} ${tableTfootRowBorderBottomColor.value}`;
								}

								if (tableTfootRowBorderLeftWidth.value && tableTfootRowBorderLeftType.value && tableTfootRowBorderLeftColor.value) {
									tableTfootRowExistingStyles["border-left"] = `${tableTfootRowBorderLeftWidth.value}px ${tableTfootRowBorderLeftType.value} ${tableTfootRowBorderLeftColor.value}`;
								}
								tr.setStyle(tableTfootRowExistingStyles);
							});
						}

						// updating styling props for tBodyTd
						if (tableTbodyFontSize.value || tableTbodyFontColor.value || tableTbodyFontFamily.value || tableContentAlignment.value || tableTbodyOddRowBackgroundColor.value || tableTbodyRowBorderTopWidth.value || tableTbodyRowBorderTopType.value || tableTbodyRowBorderTopColor.value || tableTbodyRowBorderRightWidth.value || tableTbodyRowBorderRightType.value || tableTbodyRowBorderRightColor.value || tableTbodyRowBorderBottomWidth.value || tableTbodyRowBorderBottomType.value || tableTbodyRowBorderBottomColor.value || tableTbodyRowBorderLeftWidth.value || tableTbodyRowBorderLeftType.value || tableTbodyRowBorderLeftColor.value || tableTbodyRowHoverColor.value) {
							// props.attributes.thisModelRef.set('tBodyTdTextAlign', `${tableContentAlignment.value}`);
							// props.attributes.thisModelRef.set('tBodyTdFontSize', `${tableTbodyFontSize.value}vw`);
							// props.attributes.thisModelRef.set('tBodyTdFontColor', `${tableTbodyFontColor.value}`);
							// props.attributes.thisModelRef.set('tBodyTdFontFamily', `${tableTbodyFontFamily.value}`);

							props.attributes.thisModelRef.attributes.tBodyTdTextAlign = `${tableContentAlignment.value}`;
							props.attributes.thisModelRef.attributes.tBodyTdFontSize = `${tableTbodyFontSize.value}vw`;
							props.attributes.thisModelRef.attributes.tBodyTdFontColor = `${tableTbodyFontColor.value}`;
							props.attributes.thisModelRef.attributes.tBodyTdFontFamily = `${tableTbodyFontFamily.value}`;
							props.attributes.thisModelRef.attributes.tBodyTrHoverColor = `${tableTbodyRowHoverColor.value}`;
							if (tableTbodyOddRowEnableBackgroundColor.checked) {
								props.attributes.thisModelRef.attributes.tBodyOddTrBackgroundColor = `${tableTbodyOddRowBackgroundColor.value}`;
							}
							if (tableTbodyEvenRowEnableBackgroundColor.checked) {
								props.attributes.thisModelRef.attributes.tBodyEvenTrBackgroundColor = `${tableTbodyEvenRowBackgroundColor.value}`;
							}
							props.attributes.thisModelRef.attributes.tBodyTrBorderTopWidth = `${tableTbodyRowBorderTopWidth.value}`;
							props.attributes.thisModelRef.attributes.tBodyTrBorderTopType = `${tableTbodyRowBorderTopType.value}`;
							props.attributes.thisModelRef.attributes.tBodyTrBorderTopColor = `${tableTbodyRowBorderTopColor.value}`;

							props.attributes.thisModelRef.attributes.tBodyTrBorderRightWidth = `${tableTbodyRowBorderRightWidth.value}`;
							props.attributes.thisModelRef.attributes.tBodyTrBorderRightType = `${tableTbodyRowBorderRightType.value}`;
							props.attributes.thisModelRef.attributes.tBodyTrBorderRightColor = `${tableTbodyRowBorderRightColor.value}`;

							props.attributes.thisModelRef.attributes.tBodyTrBorderBottomWidth = `${tableTbodyRowBorderBottomWidth.value}`;
							props.attributes.thisModelRef.attributes.tBodyTrBorderBottomType = `${tableTbodyRowBorderBottomType.value}`;
							props.attributes.thisModelRef.attributes.tBodyTrBorderBottomColor = `${tableTbodyRowBorderBottomColor.value}`;

							props.attributes.thisModelRef.attributes.tBodyTrBorderLeftWidth = `${tableTbodyRowBorderLeftWidth.value}`;
							props.attributes.thisModelRef.attributes.tBodyTrBorderLeftType = `${tableTbodyRowBorderLeftType.value}`;
							props.attributes.thisModelRef.attributes.tBodyTrBorderLeftColor = `${tableTbodyRowBorderLeftColor.value}`;
							// ---------- call script only when tBodyTd related styling props are modified
							console.log("rerunning script");
							props.attributes.thisModelRef.attributes.script.call(props.attributes.thisModelRef.getEl(), props.attributes.thisModelRef.props());
						}

						// close the modal on clicking the button, at the end
						editor.Modal.close();
					});
				} catch (e) {
					// statements
					console.log(e);
				}

				// dynamically creating checkboxes in the modal outside the plotTableButton click event so that checkboxs append everytime the modal is opened
				if (searchEditableApiFields.value || customUrl.value || baseUrl.value || apiPath.value || apiMethod.value || apiUsername.value || apiPassword.value || apiBearerToken.value || apiMoreHeaders.value || apiBody.value) {
					// Call the updateMainTableEditModal function
					updateMainTableEditModal(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, editableApiFieldsContainer, editableApiFieldsButton, searchEditableApiFields.value, customUrl.value, baseUrl.value, apiPath.value, apiMethod.value, apiUsername.value, apiPassword.value, apiBearerToken.value, apiMoreHeaders.value, apiBody.value, nestedArrOfObjKeyname.value);

					// call the function also when the search-filter is provided
					searchEditableApiFields.addEventListener("change", function (e) {
						// Call the updateMainTableEditModal function
						updateMainTableEditModal(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, editableApiFieldsContainer, editableApiFieldsButton, searchEditableApiFields.value, customUrl.value, baseUrl.value, apiPath.value, apiMethod.value, apiUsername.value, apiPassword.value, apiBearerToken.value, apiMoreHeaders.value, apiBody.value, nestedArrOfObjKeyname.value);
					});
				}
			}

			// checking for custom-table-formats-form
			if (document.getElementsByClassName("gjs-mdl-content")[0] !== undefined && document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("custom-table-formats-form")[0] !== undefined) {
				const columnDatatype = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-datatype")[0];
				const columnDateFormat = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-date-format")[0];
				const columnTimezoneFormat = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-timezone-format")[0];
				const columnNumberFormat = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-number-format")[0];
				const rteToolbarSwitch = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("rte-toolbar-switch")[0];
				const rteTextFieldReadOnly = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("rte-textfield-readonly")[0];
				const applyFunctionsToColumnsFunctionName = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("apply-functions-to-columns-function-name")[0];
				const formatApplyBtn = document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-apply-btn")[0];

				// reassigning previous values that the user had set
				rteToolbarSwitch.checked = props.attributes.thisModelRef.attributes.richTextOptions.toolbar;
				rteTextFieldReadOnly.checked = props.attributes.thisModelRef.attributes.richTextOptions.readOnly;
				applyFunctionsToColumnsFunctionName.value = props.attributes.thisModelRef.attributes.applyFunctionsFromStorage[`col${props.attributes.colIdx}`] || '';

				try {
					// show the formats-form-date-format when formats-form-datatype is seleted to be a datetime-local
					columnDatatype.addEventListener("change", function () {
						if (columnDatatype.value === "datetime-local") {
							document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-date-format-container")[0].style.display = "flex";
						} else {
							document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-date-format-container")[0].style.display = "none";
						}

						if (columnDatatype.value === "datetime-zones") {
							document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-timezone-format-container")[0].style.display = "flex";
						} else {
							document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-timezone-format-container")[0].style.display = "none";
						}

						if (columnDatatype.value === "number") {
							document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-number-format-container")[0].style.display = "flex";
						} else {
							document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-number-format-container")[0].style.display = "none";
						}

						if (columnDatatype.value === "richText") {
							document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-richText-container")[0].style.display = "flex";
						} else {
							document.getElementsByClassName("gjs-mdl-content")[0].getElementsByClassName("formats-form-richText-container")[0].style.display = "none";
						}
					});

					// take actions every time a apply button is clicked
					formatApplyBtn.addEventListener("click", () => {
						// if in the modal, the user provides header fields to put to the table headers
						if (columnDatatype.value === "datetime-local" && columnDateFormat.value !== "null" && columnDateFormat.value !== "default") {
							// console.log('columnDatatype.value, columnDateFormat.value', columnDatatype.value, columnDateFormat.value);

							// apply the date format to dates coming in apiData (response)
							applyDisplayDateFormats(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, props.attributes.colIdx, columnDatatype.value, columnDateFormat.value);
						} else if (columnDatatype.value === "datetime-local" && columnDateFormat.value === "default") {
							// else if its default format then display the default format and remove the choices
							let columnwiseDisplayDateFormats = props.attributes.thisModelRef.get("ColumnwiseDisplayDateFormats");
							
							// Create a copy of the object and delete the specific column key
							const updatedColumnwiseDisplayDateFormats = { ...columnwiseDisplayDateFormats };
							delete updatedColumnwiseDisplayDateFormats[`col${props.attributes.colIdx}`]; // Remove the key

							// Update the model with the new object without the deleted key
							props.attributes.thisModelRef.set("ColumnwiseDisplayDateFormats", updatedColumnwiseDisplayDateFormats);

							props.attributes.thisModelRef.attributes.script.call(props.attributes.thisModelRef.getEl(), props.attributes.thisModelRef.props());
						} 
						else if (columnDatatype.value === "datetime-zones" && columnTimezoneFormat.value !== "null" && columnTimezoneFormat.value !== "default") {
							// console.log('columnDatatype.value, columnTimezoneFormat.value', columnDatatype.value, columnTimezoneFormat.value);

							// apply the date format to dates coming in apiData (response)
							applyDisplayTimeZoneFormats(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, props.attributes.colIdx, columnDatatype.value, columnTimezoneFormat.value);
						} else if (columnDatatype.value === "datetime-zones" && columnTimezoneFormat.value === "default") {
							// else if its default format then display the default format and remove the choices
							let columnwiseDisplayTimeZoneFormats = props.attributes.thisModelRef.get("ColumnwiseDisplayTimeZoneFormats");
							
							// Create a copy of the object and delete the specific column key
							const updatedColumnwiseDisplayTimeZoneFormats = { ...columnwiseDisplayTimeZoneFormats };
							delete updatedColumnwiseDisplayTimeZoneFormats[`col${props.attributes.colIdx}`]; // Remove the key

							// Update the model with the new object without the deleted key
							props.attributes.thisModelRef.set("ColumnwiseDisplayTimeZoneFormats", updatedColumnwiseDisplayTimeZoneFormats);

							props.attributes.thisModelRef.attributes.script.call(props.attributes.thisModelRef.getEl(), props.attributes.thisModelRef.props());
						} 
						else if (columnDatatype.value === 'number' && columnNumberFormat.value !== "null" && columnNumberFormat.value !== "default") {
							// apply the date format to dates coming in apiData (response)
							applyDisplayNumberFormats(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, props.attributes.colIdx, columnDatatype.value, columnNumberFormat.value);
						} else if (columnDatatype.value === 'number' && columnNumberFormat.value === "default") {
							// apply the date format to dates coming in apiData (response)
							let columnwiseDisplayNumberFormats = props.attributes.thisModelRef.get("ColumnwiseDisplayNumberFormats");
							
							// Create a copy of the object and delete the specific column key
							const updatedColumnwiseDisplayNumberFormats = { ...columnwiseDisplayNumberFormats };
							delete updatedColumnwiseDisplayNumberFormats[`col${props.attributes.colIdx}`]; // Remove the key

							// Update the model with the new object without the deleted key
							props.attributes.thisModelRef.set("ColumnwiseDisplayNumberFormats", updatedColumnwiseDisplayNumberFormats);

							props.attributes.thisModelRef.attributes.script.call(props.attributes.thisModelRef.getEl(), props.attributes.thisModelRef.props());
						} else if (columnDatatype.value === "richText") {
							let modRef = props.attributes.thisModelRef.get("richTextIndexes");
							let richTextIndexesMod;
							if (!modRef.includes(props.attributes.colIdx)) {
								// console.log("modRef:::::", modRef);
								modRef.push(props.attributes.colIdx);
								// console.log("modRef1111111111:::::", modRef);
								if (modRef.length > 1) {
									modRef.sort((a, b) => a - b);
								}

								richTextIndexesMod = [...modRef];
								console.log("richTextIndexesMod::::::::::", richTextIndexesMod);
							}

							// to save the numCols value as a model property so that next time the modal is open, the value is there that the user enetered previously
							if (richTextIndexesMod) {
								props.attributes.thisModelRef.set({richTextIndexes: richTextIndexesMod});
							}

							// updating table props/traits
							props.attributes.thisModelRef.set({
							  richTextOptions: {
							    toolbar: rteToolbarSwitch.checked,
							    readOnly: rteTextFieldReadOnly.checked,
							  }
							});

							props.attributes.thisModelRef.attributes.script.call(props.attributes.thisModelRef.getEl(), props.attributes.thisModelRef.props());

							// TO RENDER THE QUILL LIB PROPERLY
							// props.attributes.thisModelRef.view.render();
						}

						if (applyFunctionsToColumnsFunctionName.value) {
							applyFunctionsFromStorageFunc(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, props.attributes.colIdx, applyFunctionsToColumnsFunctionName.value);
						} else {
							applyFunctionsFromStorageFunc(editor, props.attributes.thisModelRef, props.attributes.thisTableRef, props.attributes.colIdx, applyFunctionsToColumnsFunctionName.value);
						}

						// close the modal on clicking the button, at the end
						editor.Modal.close();
					});
				} catch (e) {
					// statements
					console.log(e);
				}
			}
		});

		// Table operations -
		// CREATE HEADER FIELDS - this replaces the header fields of the table with the user's input from the modal
		function createHeaderFields(editorRef, thisModelRef, thisTableRef, numColsInputValue, tableTheadFontSize, tableTheadFontColor, tableTheadFontFamily, tableTheadRowBackgroundColor, tableTheadRowEnableBackgroundColor, tableTheadRowEnableLinearGradient1, tableTheadRowLinearGradient1, tableTheadRowLinearGradient2, tableTheadRowLinearGradientAngle, tableTheadRowBorderTopWidth, tableTheadRowBorderTopType, tableTheadRowBorderTopColor, tableTheadRowBorderRightWidth, tableTheadRowBorderRightType, tableTheadRowBorderRightColor, tableTheadRowBorderBottomWidth, tableTheadRowBorderBottomType, tableTheadRowBorderBottomColor, tableTheadRowBorderLeftWidth, tableTheadRowBorderLeftType, tableTheadRowBorderLeftColor) {
			// console.log(
			// 	"editorRef, thisModelRef, thisTableRef, numColsInputValue, tableTheadFontSize, tableTheadFontColor, tableTheadFontFamily, tableTheadRowBackgroundColor, tableTheadRowEnableBackgroundColor, tableTheadRowEnableLinearGradient1, tableTheadRowLinearGradient1, tableTheadRowLinearGradient2, tableTheadRowLinearGradientAngle, tableTheadRowBorderTopWidth, tableTheadRowBorderTopType, tableTheadRowBorderTopColor, tableTheadRowBorderRightWidth, tableTheadRowBorderRightType, tableTheadRowBorderRightColor, tableTheadRowBorderBottomWidth, tableTheadRowBorderBottomType, tableTheadRowBorderBottomColor, tableTheadRowBorderLeftWidth, tableTheadRowBorderLeftType, tableTheadRowBorderLeftColor",
			// 	editorRef,
			// 	thisModelRef,
			// 	thisTableRef,
			// 	numColsInputValue,
			// 	tableTheadFontSize,
			// 	tableTheadFontColor,
			// 	tableTheadFontFamily,
			// 	tableTheadRowBackgroundColor,
			// 	tableTheadRowEnableBackgroundColor,
			// 	tableTheadRowEnableLinearGradient1,
			// 	tableTheadRowLinearGradient1,
			// 	tableTheadRowLinearGradient2,
			// 	tableTheadRowLinearGradientAngle,
			// 	tableTheadRowBorderTopWidth,
			// 	tableTheadRowBorderTopType,
			// 	tableTheadRowBorderTopColor,
			// 	tableTheadRowBorderRightWidth,
			// 	tableTheadRowBorderRightType,
			// 	tableTheadRowBorderRightColor,
			// 	tableTheadRowBorderBottomWidth,
			// 	tableTheadRowBorderBottomType,
			// 	tableTheadRowBorderBottomColor,
			// 	tableTheadRowBorderLeftWidth,
			// 	tableTheadRowBorderLeftType,
			// 	tableTheadRowBorderLeftColor,
			// );

			// Get all the model properties
			const props = thisModelRef.props();

			// to save the numCols value as a model property so that next time the modal is open, the value is there that the user enetered previously
			thisModelRef.attributes.numColsInputValue = Number(numColsInputValue);

			// get all the components in the editor instance
			// const allComps = getAllComponents(editorRef.DomComponents.getWrapper());
			// console.log('allComps', allComps);

			// after getting all the components in the editor instance, find the table component to add/replace headers to it
			// const tableComponent = allComps.find((ele) => ele.attributes.type === 'custom-table');

			// no need to find the table component, get the table reference from the modal properties
			const tableComponent = thisTableRef;
			console.log("tableComponent -", tableComponent);

			// Find the <thead> and <tr> in the table
			const thead = tableComponent.find("thead")[0];
			if (!thead) {
				console.error("Thead not found in the table.");
				return;
			}
			const tr = thead.find("tr")[0];
			if (!tr) {
				console.error("TR not found in the table.");
				return;
			}
		
			// Get the current number of <th> elements
			const existingThCount = tr.components().length;
		
			// Calculate the difference
			const diff = numColsInputValue - existingThCount;
			
			// Add or remove <th> elements as needed
			if (diff > 0) {
				// Add new <th> elements
				for (let i = 0; i < diff; i++) {
					const newTh = {
						tagName: "th",
						"style-default": {
							padding: "8px",
							"border-collapse": "collapse",
							"border-left": "1px solid black",
							"border-right": "1px solid black",
							"font-size": props.tableTheadFontSize ? props.tableTheadFontSize : "",
							color: props.tableTheadFontColor ? props.tableTheadFontColor : "white",
							"font-family": props.tableTheadFontFamily ? props.tableTheadFontFamily : "",
							"background-color": props.tableTheadRowEnableBackgroundColor ? props.tableTheadRowBackgroundColor : "",
							"background-image": props.tableTheadRowEnableLinearGradient1
								? `linear-gradient(${props.tableTheadRowLinearGradientAngle ? props.tableTheadRowLinearGradientAngle : '180deg'}, ${props.tableTheadRowLinearGradient1}, ${props.tableTheadRowLinearGradient2})`
								: "",
							"border-top": props.tableTheadRowBorderTopWidth || props.tableTheadRowBorderTopType || props.tableTheadRowBorderTopColor
								? `${props.tableTheadRowBorderTopWidth}px ${props.tableTheadRowBorderTopType} ${props.tableTheadRowBorderTopColor}`
								: "inherit",
							"border-right": props.tableTheadRowBorderRightWidth || props.tableTheadRowBorderRightType || props.tableTheadRowBorderRightColor
								? `${props.tableTheadRowBorderRightWidth}px ${props.tableTheadRowBorderRightType} ${props.tableTheadRowBorderRightColor}`
								: "inherit",
							"border-bottom": props.tableTheadRowBorderBottomWidth || props.tableTheadRowBorderBottomType || props.tableTheadRowBorderBottomColor
								? `${props.tableTheadRowBorderBottomWidth}px ${props.tableTheadRowBorderBottomType} ${props.tableTheadRowBorderBottomColor}`
								: "inherit",
							"border-left": props.tableTheadRowBorderLeftWidth || props.tableTheadRowBorderLeftType || props.tableTheadRowBorderLeftColor
								? `${props.tableTheadRowBorderLeftWidth}px ${props.tableTheadRowBorderLeftType} ${props.tableTheadRowBorderLeftColor}`
								: "inherit",
						},
						style: {
							padding: "8px",
							"border-collapse": "collapse",
							"border-left": "1px solid black",
							"border-right": "1px solid black",
							"font-size": props.tableTheadFontSize ? props.tableTheadFontSize : "",
							color: props.tableTheadFontColor ? props.tableTheadFontColor : "white",
							"font-family": props.tableTheadFontFamily ? props.tableTheadFontFamily : "",
							"background-color": props.tableTheadRowEnableBackgroundColor ? props.tableTheadRowBackgroundColor : "",
							"background-image": props.tableTheadRowEnableLinearGradient1
								? `linear-gradient(${props.tableTheadRowLinearGradientAngle ? props.tableTheadRowLinearGradientAngle : '180deg'}, ${props.tableTheadRowLinearGradient1}, ${props.tableTheadRowLinearGradient2})`
								: "",
							"border-top": props.tableTheadRowBorderTopWidth || props.tableTheadRowBorderTopType || props.tableTheadRowBorderTopColor
								? `${props.tableTheadRowBorderTopWidth}px ${props.tableTheadRowBorderTopType} ${props.tableTheadRowBorderTopColor}`
								: "inherit",
							"border-right": props.tableTheadRowBorderRightWidth || props.tableTheadRowBorderRightType || props.tableTheadRowBorderRightColor
								? `${props.tableTheadRowBorderRightWidth}px ${props.tableTheadRowBorderRightType} ${props.tableTheadRowBorderRightColor}`
								: "inherit",
							"border-bottom": props.tableTheadRowBorderBottomWidth || props.tableTheadRowBorderBottomType || props.tableTheadRowBorderBottomColor
								? `${props.tableTheadRowBorderBottomWidth}px ${props.tableTheadRowBorderBottomType} ${props.tableTheadRowBorderBottomColor}`
								: "inherit",
							"border-left": props.tableTheadRowBorderLeftWidth || props.tableTheadRowBorderLeftType || props.tableTheadRowBorderLeftColor
								? `${props.tableTheadRowBorderLeftWidth}px ${props.tableTheadRowBorderLeftType} ${props.tableTheadRowBorderLeftColor}`
								: "inherit",
						},
						components: [
							{
								tagName: "p",
								type: "text",
								editable: true,
								components: {
									type: "textnode",
									content: `Header Field ${existingThCount + i + 1}`,
								},
							},
						],
					};
					if (thisModelRef.get("SortingBtnsInAllCols")) {
						newTh.components.push({ type: "custom-table-sort-container" });
					}
					tr.append(newTh);
				}
			} else if (diff < 0) {
				// Remove extra <th> elements from the end
				for (let i = 0; i < Math.abs(diff); i++) {
					const lastTh = tr.components().at(tr.components().length - 1);
					console.log("+lastTh+", lastTh.getEl());
					if (lastTh) {
						lastTh.remove();
					}
				}
			}

			// updating colspan of <td> in custom-table-tfoot-row-for-pagination so that it keeps spanning full width
			let paginationTd = tableComponent.find(".custom-table-tfoot")[0].find(".custom-table-tfoot-row-for-pagination")[0].find("td")[0];
			paginationTd.setAttributes({
				colspan: `${numColsInputValue}`,
			});

			console.log("running updateRowInTfoot");
			// run addRowInTfoot() also to update the row's cells in tfoot when columns are updated
			updateRowInTfoot(editorRef, thisModelRef, thisTableRef, numColsInputValue);

			// After updating the header fields, trigger a re-render
			thisModelRef.view.render();

			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// when plotTable is run, call the script of the component so that it gets the updated model properties
			thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("createHeaderFields() model props ****", thisModelRef.props());
		}

		// SET NUMBER-OF-ROWS-PER-PAGE - this sets the number of rows per page for pagination
		function setNumberOfRowsPerPage(editorRef, thisModelRef, thisTableRef, numRowsPerPageValue) {
			console.log("editorRef, thisModelRef, thisTableRef, numRowsPerPageValue", editorRef, thisModelRef, thisTableRef, numRowsPerPageValue);
			// to make sure that the script gets the updated updatedProps
			thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			// to save the numRowsPerPage value as a model property so that next time the modal is open, the value is there that the user enetered previously
			thisModelRef.set("numRowsPerPageValue", `${numRowsPerPageValue}`);
			console.log("createHeaderFields() model props ****", thisModelRef.props());
		}

		// INSERT COLUMN AT INDEX - this iterates through each row in the table and inserts a new cell at the specified index
		function addColumnAtIndex(editorRef, thisModelRef, thisTableRef, addColAtIdxValue, numColsInputValue, tableTheadFontSize, tableTheadFontColor, tableTheadFontFamily, tableTheadRowBackgroundColor, tableTheadRowEnableBackgroundColor, tableTheadRowEnableLinearGradient1, tableTheadRowLinearGradient1, tableTheadRowLinearGradient2, tableTheadRowLinearGradientAngle, tableTheadRowBorderTopWidth, tableTheadRowBorderTopType, tableTheadRowBorderTopColor, tableTheadRowBorderRightWidth, tableTheadRowBorderRightType, tableTheadRowBorderRightColor, tableTheadRowBorderBottomWidth, tableTheadRowBorderBottomType, tableTheadRowBorderBottomColor, tableTheadRowBorderLeftWidth, tableTheadRowBorderLeftType, tableTheadRowBorderLeftColor) {
			// console.log(
			// 	"editorRef, thisModelRef, thisTableRef, addColAtIdxValue, numColsInputValue, tableTheadFontSize, tableTheadFontColor, tableTheadFontFamily, tableTheadRowBackgroundColor, tableTheadRowEnableBackgroundColor, tableTheadRowEnableLinearGradient1, tableTheadRowLinearGradient1, tableTheadRowLinearGradient2, tableTheadRowLinearGradientAngle, tableTheadRowBorderTopWidth, tableTheadRowBorderTopType, tableTheadRowBorderTopColor, tableTheadRowBorderRightWidth, tableTheadRowBorderRightType, tableTheadRowBorderRightColor, tableTheadRowBorderBottomWidth, tableTheadRowBorderBottomType, tableTheadRowBorderBottomColor, tableTheadRowBorderLeftWidth, tableTheadRowBorderLeftType, tableTheadRowBorderLeftColor",
			// 	editorRef,
			// 	thisModelRef,
			// 	thisTableRef,
			// 	addColAtIdxValue,
			// 	numColsInputValue,
			// 	tableTheadFontSize,
			// 	tableTheadFontColor,
			// 	tableTheadFontFamily,
			// 	tableTheadRowBackgroundColor,
			// 	tableTheadRowEnableBackgroundColor,
			// 	tableTheadRowEnableLinearGradient1,
			// 	tableTheadRowLinearGradient1,
			// 	tableTheadRowLinearGradient2,
			// 	tableTheadRowLinearGradientAngle,
			// 	tableTheadRowBorderTopWidth,
			// 	tableTheadRowBorderTopType,
			// 	tableTheadRowBorderTopColor,
			// 	tableTheadRowBorderRightWidth,
			// 	tableTheadRowBorderRightType,
			// 	tableTheadRowBorderRightColor,
			// 	tableTheadRowBorderBottomWidth,
			// 	tableTheadRowBorderBottomType,
			// 	tableTheadRowBorderBottomColor,
			// 	tableTheadRowBorderLeftWidth,
			// 	tableTheadRowBorderLeftType,
			// 	tableTheadRowBorderLeftColor,
			// );

			// to save the numCols value as a model property so that next time the modal is open, the value is there that the user enetered previously
			thisModelRef.attributes.addColAtIdxValue = addColAtIdxValue;
			// and increase the number of columns property of the table, by 1, so that replotting function uses the loop of correct number-of-columns value
			thisModelRef.attributes.numColsInputValue = Number(thisModelRef.attributes.numColsInputValue) + 1;

			// Get all the model properties
			const props = thisModelRef.props();

			// get all the components in the editor instance
			// const allComps = getAllComponents(editorRef.DomComponents.getWrapper());
			// console.log('allComps', allComps);

			// after getting all the components in the editor instance, find the table component to add/replace headers to it
			// const tableComponent = allComps.find((ele) => ele.attributes.type === 'custom-table');

			// no need to find the table component, get the table reference from the modal properties
			const tableComponent = thisTableRef;
			console.log("tableComponent", tableComponent);

			// find the <tbody> and <thead> in the table component's to check if its empty or not and add a column accordingly
			const tbody = tableComponent.find("tbody")[0];
			const thead = tableComponent.find("thead")[0];
			console.log("thead, tbody", thead, tbody);

			// check if tbody is empty or not/has any rows plotted or not
			if (thead.components().length !== 0) {
				console.log("thead is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// iterate through each row in the tbody
				thead.components().forEach((row) => {
					// create a new cell (<td>) at the specified index
					let th = {
						tagName: "th",
						// type: "custom-table-thead-row-th",

						"style-default": {
							padding: "8px",
							"border-collapse": "collapse",
							// "border-left": "1px solid black",
							// "border-right": "1px solid black",
							"font-size": props.tableTheadFontSize ? props.tableTheadFontSize + "vw" : "",
							color: props.tableTheadFontColor ? props.tableTheadFontColor : "white",
							"font-family": props.tableTheadFontFamily ? props.tableTheadFontFamily : "",
							"background-color": props.tableTheadRowEnableBackgroundColor ? props.tableTheadRowBackgroundColor : "",
							"background-image": props.tableTheadRowEnableLinearGradient1 ? `linear-gradient(${props.tableTheadRowLinearGradientAngle ? props.tableTheadRowLinearGradientAngle : '180deg'}, ${props.tableTheadRowLinearGradient1}, ${props.tableTheadRowLinearGradient2})` : "",
							"border-top": props.tableTheadRowBorderTopWidth || props.tableTheadRowBorderTopType || props.tableTheadRowBorderTopColor ? `${props.tableTheadRowBorderTopWidth}px ${props.tableTheadRowBorderTopType} ${props.tableTheadRowBorderTopColor}` : "inherit",
							"border-right": props.tableTheadRowBorderRightWidth || props.tableTheadRowBorderRightType || props.tableTheadRowBorderRightColor ? `${props.tableTheadRowBorderRightWidth}px ${props.tableTheadRowBorderRightType} ${props.tableTheadRowBorderRightColor}` : "inherit",
							"border-bottom": props.tableTheadRowBorderBottomWidth || props.tableTheadRowBorderBottomType || props.tableTheadRowBorderBottomColor ? `${props.tableTheadRowBorderBottomWidth}px ${props.tableTheadRowBorderBottomType} ${props.tableTheadRowBorderBottomColor}` : "inherit",
							"border-left": props.tableTheadRowBorderLeftWidth || props.tableTheadRowBorderLeftType || props.tableTheadRowBorderLeftColor ? `${props.tableTheadRowBorderLeftWidth}px ${props.tableTheadRowBorderLeftType} ${props.tableTheadRowBorderLeftColor}` : "inherit",
						},

						style: {
							padding: "8px",
							"border-collapse": "collapse",
							// "border-left": "1px solid black",
							// "border-right": "1px solid black",
							"font-size": props.tableTheadFontSize ? props.tableTheadFontSize + "vw" : "",
							color: props.tableTheadFontColor ? props.tableTheadFontColor : "white",
							"font-family": props.tableTheadFontFamily ? props.tableTheadFontFamily : "",
							"background-color": props.tableTheadRowEnableBackgroundColor ? props.tableTheadRowBackgroundColor : "",
							"background-image": props.tableTheadRowEnableLinearGradient1 ? `linear-gradient(${props.tableTheadRowLinearGradientAngle ? props.tableTheadRowLinearGradientAngle : '180deg'}, ${props.tableTheadRowLinearGradient1}, ${props.tableTheadRowLinearGradient2})` : "",
							"border-top": props.tableTheadRowBorderTopWidth || props.tableTheadRowBorderTopType || props.tableTheadRowBorderTopColor ? `${props.tableTheadRowBorderTopWidth}px ${props.tableTheadRowBorderTopType} ${props.tableTheadRowBorderTopColor}` : "inherit",
							"border-right": props.tableTheadRowBorderRightWidth || props.tableTheadRowBorderRightType || props.tableTheadRowBorderRightColor ? `${props.tableTheadRowBorderRightWidth}px ${props.tableTheadRowBorderRightType} ${props.tableTheadRowBorderRightColor}` : "inherit",
							"border-bottom": props.tableTheadRowBorderBottomWidth || props.tableTheadRowBorderBottomType || props.tableTheadRowBorderBottomColor ? `${props.tableTheadRowBorderBottomWidth}px ${props.tableTheadRowBorderBottomType} ${props.tableTheadRowBorderBottomColor}` : "inherit",
							"border-left": props.tableTheadRowBorderLeftWidth || props.tableTheadRowBorderLeftType || props.tableTheadRowBorderLeftColor ? `${props.tableTheadRowBorderLeftWidth}px ${props.tableTheadRowBorderLeftType} ${props.tableTheadRowBorderLeftColor}` : "inherit",
						},

						components: [
							{
								tagName: "p",
								type: "text",
								editable: true,

								// "style-default": {
								// 	'font-size': '1.2vw',
								// },

								// style: {
								// 	'font-size': '1.2vw',
								// },

								components: {
									type: "textnode",
									content: `New Header Field ${addColAtIdxValue}`,
								},
							},
							// {
							// 	type: "custom-table-sort-container",
							// },
						],
					};

					if (thisModelRef.get('SortingBtnsInAllCols')) th.components.push({type: "custom-table-sort-container"});

					// insert the new cell at the specified index
					row.components().add(th, {
						at: addColAtIdxValue,
					});

					// shift existing property values in the model to accommodate the new column
					for (let i = numColsInputValue; i > addColAtIdxValue; i--) {
						console.log(`${i}, HeaderField_${i} HeaderField_${i - 1}`);
						thisModelRef.set(`HeaderField_${i}`, thisModelRef.get(`HeaderField_${i - 1}`));
						// so that HeaderField_i are saved in export/downloaded code also
						thisModelRef.attributes.HeaderFields[`HeaderField_${i}`] = thisModelRef.attributes.HeaderFields[`HeaderField_${i - 1}`];
						thisModelRef.attributes.attributes[`data-headerfield_${i}`] = thisModelRef.attributes.attributes[`data-headerfield_${i - 1}`];
					}

					// set the new header field for the added column
					thisModelRef.set(`HeaderField_${addColAtIdxValue}`, `New Header Field ${addColAtIdxValue}`);
					// so that HeaderField_i are saved in export/downloaded code also
					thisModelRef.attributes.HeaderFields[`HeaderField_${addColAtIdxValue}`] = `New Header Field ${addColAtIdxValue}`;
					thisModelRef.attributes.attributes[`data-headerfield_${addColAtIdxValue}`] = `New Header Field ${addColAtIdxValue}`;
				});
			} else {
				console.log("thead is empty");
			}

			// check if tbody is empty or not/has any rows plotted or not
			if (tbody.components().length !== 0) {
				console.log("tbody is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// iterate through each row in the tbody
				tbody.components().forEach((row) => {
					// create a new cell (<td>) at the specified index
					let td = {
						tagName: "td",
						content: "New Cell Content",

						// components: [
						//     {
						//         tagName: "p",
						//         type: "text",
						//         editable: true,
						//         components: {
						//             type: "textnode",
						//             content: "New Cell Content", // You can set the initial content here
						//         },
						//     },
						// ],
					};

					// insert the new cell at the specified index
					row.components().add(td, {
						at: addColAtIdxValue,
					});
				});
			} else {
				console.log("tbody is empty");
			}

			// After updating the header fields, trigger a re-render so that onRender runs and adds table icons
			thisModelRef.view.render();
			console.log("model (2)", thisModelRef);

			// updating colspan of <td> in custom-table-tfoot-row-for-pagination so that it keeps spanning full width
			let paginationTd = tableComponent.find(".custom-table-tfoot")[0].find(".custom-table-tfoot-row-for-pagination")[0].find("td")[0];
			paginationTd.setAttributes({
				colspan: `${numColsInputValue + 1}`,
			});

			console.log("running updateRowInTfoot");
			// run addRowInTfoot() also to update the row's cells in tfoot when columns are updated
			updateRowInTfoot(editorRef, thisModelRef, thisTableRef, numColsInputValue + 1, addColAtIdxValue);

			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// when plotTable is run, call the script of the component so that it gets the updated model properties
			thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("addColumnAtIndex() model props ****", thisModelRef.props());
		}

		// DELETE COLUMN AT INDEX - this iterates through each row in the table and inserts a new cell at the specified index
		function deleteColumnAtIndex(editorRef, thisModelRef, thisTableRef, deleteColAtIdxValue, numColsInputValue) {
			console.log("editorRef, thisModelRef, thisTableRef, deleteColAtIdxValue, numColsInputValue", editorRef, thisModelRef, thisTableRef, deleteColAtIdxValue, numColsInputValue);

			// to save the numCols value as a model property so that next time the modal is open, the value is there that the user enetered previously
			thisModelRef.attributes.deleteColAtIdxValue = deleteColAtIdxValue;
			// and increase the number of columns property of the table, by 1, so that replotting function uses the loop of correct number-of-columns value
			thisModelRef.attributes.numColsInputValue = Number(thisModelRef.attributes.numColsInputValue) - 1;

			// get all the components in the editor instance
			// const allComps = getAllComponents(editorRef.DomComponents.getWrapper());
			// console.log('allComps', allComps);

			// after getting all the components in the editor instance, find the table component to add/replace headers to it
			// const tableComponent = allComps.find((ele) => ele.attributes.type === 'custom-table');

			// no need to find the table component, get the table reference from the modal properties
			const tableComponent = thisTableRef;
			console.log("tableComponent", tableComponent);

			// find the <tbody> and <thead> in the table component's to check if its empty or not and add a column accordingly
			const tbody = tableComponent.find("tbody")[0];
			const thead = tableComponent.find("thead")[0];
			console.log("thead, tbody", thead, tbody);

			// check if tbody is empty or not/has any rows plotted or not
			if (thead.components().length !== 0) {
				console.log("thead is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// iterate through each row in thead
				thead.components().forEach((row) => {
					// remove the cell at the specified index
					const cellToRemove = row.components().at(deleteColAtIdxValue);
					cellToRemove && cellToRemove.remove();

					// shift existing property values in the model to accommodate the deleted column
					for (let i = deleteColAtIdxValue; i < thisModelRef.attributes.numColsInputValue; i++) {
						thisModelRef.set(`HeaderField_${i}`, thisModelRef.get(`HeaderField_${i + 1}`));
						// so that HeaderField_i are saved in export/downloaded code also
						thisModelRef.attributes.HeaderFields[`HeaderField_${i}`] = thisModelRef.attributes.HeaderFields[`HeaderField_${i + 1}`];
						thisModelRef.attributes.attributes[`data-headerfield_${i}`] = thisModelRef.attributes.attributes[`data-headerfield_${i + 1}`];
					}

					// remove the last HeaderField property since we shifted all the others
					thisModelRef.unset(`HeaderField_${thisModelRef.attributes.numColsInputValue}`);
					// so that HeaderField_i are saved in export/downloaded code also
					delete thisModelRef.attributes.HeaderFields[`HeaderField_${thisModelRef.attributes.numColsInputValue}`];
					delete thisModelRef.attributes.attributes[`data-headerfield_${thisModelRef.attributes.numColsInputValue}`];
				});
			} else {
				console.log("thead is empty");
			}

			// check if tbody is empty or not/has any rows plotted or not
			if (tbody.components().length !== 0) {
				console.log("tbody is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// iterate through each row in tbody
				tbody.components().forEach((row) => {
					// remove the cell at the specified index
					const cellToRemove = row.components().at(deleteColAtIdxValue);
					cellToRemove && cellToRemove.remove();
				});
			} else {
				console.log("tbody is empty");
			}

			// After updating the header fields, trigger a re-render
			thisModelRef.view.render();
			console.log("model (2)", thisModelRef);

			// updating colspan of <td> in custom-table-tfoot-row-for-pagination so that it keeps spanning full width
			// let paginationTd = tableComponent.find(".custom-table-tfoot")[0].find(".custom-table-tfoot-row-for-pagination")[0].find("td")[0];
			let ctTfoot = tableComponent.find(".custom-table-tfoot")[0];
			let paginationTr = ctTfoot ? ctTfoot.find(".custom-table-tfoot-row-for-pagination")[0] : undefined;
			let paginationTd = paginationTr ? paginationTr.find("td")[0] : undefined;

			if (paginationTd) {
				paginationTd.setAttributes({
					colspan: `${numColsInputValue - 1}`,
				});
			}

			console.log("running updateRowInTfoot");
			// run addRowInTfoot() also to update the row's cells in tfoot when columns are updated
			updateRowInTfoot(editorRef, thisModelRef, thisTableRef, numColsInputValue - 1);

			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// when plotTable is run, call the script of the component so that it gets the updated model properties
			thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("deleteColumnAtIndex() model props ****", thisModelRef.props());
		}

		// MOVE COLUMN to RIGHT - this moves a column one step to the right (swapping with the right column)
		function moveColumnToRight(editorRef, thisModelRef, thisTableRef, currentIndex, numColsInputValue) {
			console.log("editorRef, thisModelRef, thisTableRef, currentIndex, numColsInputValue", editorRef, thisModelRef, thisTableRef, currentIndex, numColsInputValue);

			// get all the components in the editor instance
			// const allComps = getAllComponents(editorRef.DomComponents.getWrapper());
			// console.log('allComps', allComps);

			// after getting all the components in the editor instance, find the table component to add/replace headers to it
			// const tableComponent = allComps.find((ele) => ele.attributes.type === 'custom-table');

			// no need to find the table component, get the table reference from the modal properties
			const tableComponent = thisTableRef;
			console.log("tableComponent", tableComponent);

			// find the <tbody> and <thead> and <tfoot> in the table component's to check if its empty or not and add a column accordingly
			const tbody = tableComponent.find("tbody")[0];
			const thead = tableComponent.find("thead")[0];
			const tfoot = tableComponent.find("tfoot")[0];
			console.log("thead, tbody, tfoot", thead, tbody, tfoot);

			// check if thead is empty or not/has any rows plotted or not
			if (thead.components().length !== 0) {
				console.log("thead is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// iterate through each row in thead
				thead.components().forEach((row) => {
					// move only if there is a column to the right in this row
					// Check if there is a column to the right
					if (currentIndex < row.components().length - 1) {
						// // Swap the positions of the current cell and the cell to the right in thead
						// const currentCell = row.components().at(currentIndex);
						// const nextCell = row.components().at(currentIndex + 1);

						// // Remove the current cell and insert it to the right
						// row.components().remove(currentIndex);
						// row.components().add(nextCell, { at: currentIndex });

						// Swap the positions of the current cell and the cell to the right
						const newCells = row.components().reduce((acc, cell, index) => {
							if (index === currentIndex) {
								const nextCell = row
									.components()
									.at(currentIndex + 1)
									.clone();
								acc.push(nextCell);
							} else if (index === currentIndex + 1) {
								const currentCell = row.components().at(currentIndex).clone();
								acc.push(currentCell);
							} else {
								const clonedCell = cell.clone();
								acc.push(clonedCell);
							}
							return acc;
						}, []);

						row.components().reset(newCells);

						// Update model properties in thead
						const tempValue = thisModelRef.get(`HeaderField_${currentIndex}`);
						thisModelRef.set(`HeaderField_${currentIndex}`, thisModelRef.get(`HeaderField_${currentIndex + 1}`));
						thisModelRef.set(`HeaderField_${currentIndex + 1}`, tempValue);
						// so that HeaderField_i are saved in export/downloaded code also
						const tempObjValue = thisModelRef.attributes.HeaderFields[`HeaderField_${currentIndex}`];
						thisModelRef.attributes.HeaderFields[`HeaderField_${currentIndex}`] = thisModelRef.attributes.HeaderFields[`HeaderField_${currentIndex + 1}`];
						thisModelRef.attributes.HeaderFields[`HeaderField_${currentIndex + 1}`] = tempObjValue;

						const tempAttrValue = thisModelRef.attributes.attributes[`data-headerfield_${currentIndex}`];
						thisModelRef.attributes.attributes[`data-headerfield_${currentIndex}`] = thisModelRef.attributes.attributes[`data-headerfield_${currentIndex + 1}`];
						thisModelRef.attributes.attributes[`data-headerfield_${currentIndex + 1}`] = tempAttrValue;
					}
				});
			} else {
				console.log("thead is empty");
			}

			// check if tbody is empty or not/has any rows plotted or not
			if (tbody.components().length !== 0) {
				console.log("tbody is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// iterate through each row in tbody
				tbody.components().forEach((row) => {
					// move only if there is a column to the right in this row
					// Check if there is a column to the right
					if (currentIndex < row.components().length - 1) {
						// Swap the positions of the current cell and the cell to the right
						const newCells = row.components().reduce((acc, cell, index) => {
							if (index === currentIndex) {
								const nextCell = row
									.components()
									.at(currentIndex + 1)
									.clone();
								acc.push(nextCell);
							} else if (index === currentIndex + 1) {
								const currentCell = row.components().at(currentIndex).clone();
								acc.push(currentCell);
							} else {
								const clonedCell = cell.clone();
								acc.push(clonedCell);
							}
							return acc;
						}, []);

						row.components().reset(newCells);
					}
				});
			} else {
				console.log("tbody is empty");
			}

			// check if tfoot is empty or not/has any rows plotted or not
			if (tfoot.components().length !== 0) {
				console.log("tfoot is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// if dynamicTableFooterColumns is true, then iterate through each row in tfoot
				if (thisModelRef.attributes.dynamicTableFooterColumns) {
					console.log("footer columns are dynamic");
					tfoot.components().forEach((row) => {
						console.log("tfoot row", row);
						// apply only on the row elements, not the pagination elements or any others
						if (row.attributes.type === "custom-table-tfoot-row") {
							// move only if there is a column to the right in this row
							// Check if there is a column to the right
							if (currentIndex < row.components().length - 1) {
								// Swap the positions of the current cell and the cell to the right
								const newCells = row.components().reduce((acc, cell, index) => {
									if (index === currentIndex) {
										const nextCell = row
											.components()
											.at(currentIndex + 1)
											.clone();
										acc.push(nextCell);
									} else if (index === currentIndex + 1) {
										const currentCell = row.components().at(currentIndex).clone();
										acc.push(currentCell);
									} else {
										const clonedCell = cell.clone();
										acc.push(clonedCell);
									}
									return acc;
								}, []);

								row.components().reset(newCells);
							}
						}
					});
				}
			} else {
				console.log("tfoot is empty");
			}

			// After updating the header fields, trigger a re-render
			thisModelRef.view.render();
			console.log("model (2)", thisModelRef);

			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// when plotTable is run, call the script of the component so that it gets the updated model properties
			thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("moveColumnToRight() model props ****", thisModelRef.props());
		}

		// MOVE COLUMN to LEFT - this moves a column one step to the right (swapping with the right column)
		function moveColumnToLeft(editorRef, thisModelRef, thisTableRef, currentIndex, numColsInputValue) {
			console.log("editorRef, thisModelRef, thisTableRef, currentIndex, numColsInputValue", editorRef, thisModelRef, thisTableRef, currentIndex, numColsInputValue);

			// get all the components in the editor instance
			// const allComps = getAllComponents(editorRef.DomComponents.getWrapper());
			// console.log('allComps', allComps);

			// after getting all the components in the editor instance, find the table component to add/replace headers to it
			// const tableComponent = allComps.find((ele) => ele.attributes.type === 'custom-table');

			// no need to find the table component, get the table reference from the modal properties
			const tableComponent = thisTableRef;
			console.log("tableComponent", tableComponent);

			// find the <tbody> and <thead> in the table component's to check if its empty or not and add a column accordingly
			const tbody = tableComponent.find("tbody")[0];
			const thead = tableComponent.find("thead")[0];
			const tfoot = tableComponent.find("tfoot")[0];
			console.log("thead, tbody, tfoot", thead, tbody, tfoot);

			// check if tbody is empty or not/has any rows plotted or not
			if (thead.components().length !== 0) {
				console.log("thead is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// iterate through each row in thead
				thead.components().forEach((row) => {
					// Move only if there is a column to the left in this row
					if (currentIndex > 0) {
						// const currentCell = row.components().at(currentIndex);
						// const prevCell = row.components().at(currentIndex - 1);

						// // Remove the current cell and insert it to the left
						// row.components().remove(currentIndex);
						// row.components().add(currentCell, { at: currentIndex - 1 });

						// Swap the positions of the current cell and the cell to the right
						const newCells = row.components().reduce((acc, cell, index) => {
							if (index === currentIndex) {
								const nextCell = row
									.components()
									.at(currentIndex - 1)
									.clone();
								acc.push(nextCell);
							} else if (index === currentIndex - 1) {
								const currentCell = row.components().at(currentIndex).clone();
								acc.push(currentCell);
							} else {
								const clonedCell = cell.clone();
								acc.push(clonedCell);
							}
							return acc;
						}, []);

						row.components().reset(newCells);

						// Update model properties in thead
						const tempValue = thisModelRef.get(`HeaderField_${currentIndex}`);
						thisModelRef.set(`HeaderField_${currentIndex}`, thisModelRef.get(`HeaderField_${currentIndex - 1}`));
						thisModelRef.set(`HeaderField_${currentIndex - 1}`, tempValue);
						// so that HeaderField_i are saved in export/downloaded code also
						const tempObjValue = thisModelRef.attributes.HeaderFields[`HeaderField_${currentIndex}`];
						thisModelRef.attributes.HeaderFields[`HeaderField_${currentIndex}`] = thisModelRef.attributes.HeaderFields[`HeaderField_${currentIndex - 1}`];
						thisModelRef.attributes.HeaderFields[`HeaderField_${currentIndex - 1}`] = tempObjValue;

						const tempAttrValue = thisModelRef.attributes.attributes[`data-headerfield_${currentIndex}`];
						thisModelRef.attributes.attributes[`data-headerfield_${currentIndex}`] = thisModelRef.attributes.attributes[`data-headerfield_${currentIndex - 1}`];
						thisModelRef.attributes.attributes[`data-headerfield_${currentIndex - 1}`] = tempAttrValue;
					}
				});
			} else {
				console.log("thead is empty");
			}

			// check if tbody is empty or not/has any rows plotted or not
			if (tbody.components().length !== 0) {
				console.log("tbody is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// iterate through each row in tbody
				tbody.components().forEach((row) => {
					// Move only if there is a column to the left in this row
					if (currentIndex > 0) {
						const newCells = row.components().reduce((acc, cell, index) => {
							if (index === currentIndex - 1) {
								const currentCell = row.components().at(currentIndex).clone();
								acc.push(currentCell);
							} else if (index === currentIndex) {
								const prevCell = row
									.components()
									.at(currentIndex - 1)
									.clone();
								acc.push(prevCell);
							} else {
								const clonedCell = cell.clone();
								acc.push(clonedCell);
							}
							return acc;
						}, []);

						row.components().reset(newCells);
					}
				});
			} else {
				console.log("tbody is empty");
			}

			// check if tfoot is empty or not/has any rows plotted or not
			if (tfoot.components().length !== 0) {
				console.log("tfoot is not empty");

				// get all the rows and loop over them to add a cell (<th>)
				// let rows = tbody.find('tr');
				// console.log(rows);

				// if dynamicTableFooterColumns is true, then iterate through each row in tfoot
				if (thisModelRef.attributes.dynamicTableFooterColumns) {
					console.log("footer columns are dynamic");
					tfoot.components().forEach((row) => {
						console.log("tfoot row", row);
						// apply only on the row elements, not the pagination elements or any others
						if (row.attributes.type === "custom-table-tfoot-row") {
							// Move only if there is a column to the left in this row
							if (currentIndex > 0) {
								// Swap the positions of the current cell and the cell to the right
								const newCells = row.components().reduce((acc, cell, index) => {
									if (index === currentIndex) {
										const nextCell = row
											.components()
											.at(currentIndex - 1)
											.clone();
										acc.push(nextCell);
									} else if (index === currentIndex - 1) {
										const currentCell = row.components().at(currentIndex).clone();
										acc.push(currentCell);
									} else {
										const clonedCell = cell.clone();
										acc.push(clonedCell);
									}
									return acc;
								}, []);

								row.components().reset(newCells);
							}
						}
					});
				}
			} else {
				console.log("tfoot is empty");
			}

			// After updating the header fields, trigger a re-render
			thisModelRef.view.render();
			console.log("model (2)", thisModelRef);

			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// when plotTable is run, call the script of the component so that it gets the updated model properties
			thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("moveColumnToLeft() model props ****", thisModelRef.props());
		}

		// APPLY ELEMENTS TO ALL CELLS OF A COLUMN - this applies thei inner elements (except the header name) to all the cells in that column
		function applyElementsToColumn(editorRef, thisModelRef, thisTableRef, currentIndex) {
			console.log("editorRef, thisModelRef, thisTableRef, currentIndex", editorRef, thisModelRef, thisTableRef, currentIndex);

			// storing to session storage, the index that was clicked
			sessionStorage.setItem(`table_${thisModelRef.attributes.attributes.id}_selected_column_${currentIndex}`, `${currentIndex}`);

			// get all the components in the editor instance
			// const allComps = getAllComponents(editorRef.DomComponents.getWrapper());
			// console.log('allComps', allComps);

			// after getting all the components in the editor instance, find the table component to add/replace headers to it
			// const tableComponent = allComps.find((ele) => ele.attributes.type === 'custom-table');

			// no need to find the table component, get the table reference from the modal properties
			const tableComponent = thisTableRef;
			console.log("tableComponent", tableComponent);

			// find stellar-button-container in thead's tr's th and then make sure its tagName stays DIV, not TH
			let thead = tableComponent.find("thead")[0];
			let theadTr = thead.find(".custom-table-thead-row")[0];
			theadTr.find("th").forEach((th) => {
				let stellarButtonContainers = th.find(".stellar-button-container");
				if (stellarButtonContainers) {
					// looping over all the stellar-button-containers present in that th and resetting their tagNames
					stellarButtonContainers.forEach((sbc) => {
						sbc.set("tagName", "div");
					});
				}

				// manually set the actionButton's tagName to button
				let actionButtons = th.find(".custom-table-action-button");
				if (actionButtons) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					actionButtons.forEach((ab) => {
						ab.set("tagName", "button");
					});
				}

				// manually set the input_file_container's tagName to button
				let inputFileButtons = th.find(".input_file_container");
				if (inputFileButtons) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					inputFileButtons.forEach((ifb) => {
						ifb.set("tagName", "div");
					});
				}

				// manually set the radio_regular_form's tagName to button
				let radioButtonGroup = th.find(".radio_regular_form");
				if (radioButtonGroup) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					radioButtonGroup.forEach((rbg) => {
						rbg.set("tagName", "div");
					});
				}

				// manually set the checkbox_regular_form's tagName to button
				let checkboxGroup = th.find(".checkbox_regular_form");
				if (checkboxGroup) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					checkboxGroup.forEach((cbg) => {
						cbg.set("tagName", "div");
					});
				}

				// manually set the checkbox_regular_form_new's tagName to button
				let checkboxGroupNew = th.find(".checkbox_regular_form_new");
				if (checkboxGroupNew) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					checkboxGroupNew.forEach((cbgn) => {
						cbgn.set("tagName", "div");
					});
				}

				// manually set the input_select_container's tagName to button
				let dropdownContainer = th.find(".input_select_container");
				if (dropdownContainer) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					dropdownContainer.forEach((dc) => {
						dc.set("tagName", "div");
					});
				}

				// manually set the form_text_field's tagName to button
				let inputTextContainer = th.find('[data-gjs-type="form_text_field"]');
				if (inputTextContainer) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					inputTextContainer.forEach((itc) => {
						itc.set("tagName", "div");
						itc.addAttributes({"data-gjs-type": "form_text_field"});
					});
				}

				// manually set the form_number_field's tagName to button
				let inputNumberContainer = th.find('[data-gjs-type="form_number_field"]');
				if (inputNumberContainer) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					inputNumberContainer.forEach((inc) => {
						inc.set("tagName", "div");
						inc.addAttributes({"data-gjs-type": "form_number_field"});
					});
				}

				// manually set the send-email-button-container's tagName to button
				let sendEmailContainer = th.find(".send-email-button-container");
				if (sendEmailContainer) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					sendEmailContainer.forEach((sec) => {
						sec.set("tagName", "div");
					});
				}

				// manually set the normal_text_area_regular_form's tagName to button
				let normalTextAreaContainer = th.find(".normal_text_area_regular_form");
				if (normalTextAreaContainer) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					normalTextAreaContainer.forEach((ntac) => {
						ntac.set("tagName", "div");
					});
				}

				// manually set the custom-table-row-selection-checkbox-container's tagName to button
				let tableRowSelectionCheckboxContainer = th.find(".custom-table-row-selection-checkbox-container");
				if (tableRowSelectionCheckboxContainer) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					tableRowSelectionCheckboxContainer.forEach((trscc) => {
						trscc.set("tagName", "div");
					});
				}

				// manually set the dateTimeInputNew's tagName to button
				let dateTimeInputContainer = th.find('[data-gjs-type="dateTimeInputNew"]');
				if (dateTimeInputContainer) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					dateTimeInputContainer.forEach((dti) => {
						dti.set("tagName", "div");
						dti.addAttributes({"data-gjs-type": "dateTimeInputNew"});
					});
				}

				// manually set the dateTimeInputNew's tagName to button
				let singleColumnComponent = th.find('[data-gjs-type="custom-table-thead-row"][class*="gjs-row"]');
				console.log('dfaddsageafs', singleColumnComponent);
				if (singleColumnComponent) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					singleColumnComponent.forEach((scc) => {
						scc.set("tagName", "div");
						scc.addAttributes({"data-gjs-type": "custom-table-thead-row"});
					});
				}

				// manually set the dateTimeInputNew's tagName to button
				let toggleContainer = th.find('.custom-table-toggle-container');
				if (toggleContainer) {
					// looping over all the custom-table-action-button present in that th and resetting their tagNames
					toggleContainer.forEach((tc) => {
						tc.set("tagName", "div");
					});
				}
			});

			// // find the <tbody> in the table component's to check if its empty or not and add a column accordingly
			// const tbody = tableComponent.find('tbody')[0];
			// console.log('tbody', tbody);

			// // check if tbody is empty or not/has any rows plotted or not
			// if (tbody.components().length !== 0) {
			// 	console.log('tbody is not empty');

			// 	// get all the rows and loop over them to add a cell (<th>)
			// 	// let rows = tbody.find('tr');
			// 	// console.log(rows);

			// 	// iterate through each row in tbody
			//     tbody.find('tr[data-gjs-type="custom-table-tbody-row"]').forEach(row => {
			//        	row.find('td').forEach(td => {
			//        		console.log('/td', td);
			//        		let innerText = td.getEl().textContent;
			//        		console.log('/td innerText', innerText);
			//        	});
			//     });
			// } else {
			// 	console.log('tbody is empty');
			// }

			// commenting this because it was updating model props resulting in loss of EditableFields prop - thisModelRef.view.render();
			// After updating the header fields, trigger a re-render
			// thisModelRef.view.render();
			console.log("model (2)", thisModelRef);

			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);

			// when plotTable is run, call the script of the component so that it gets the updated model properties
			thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("moveColumnToLeft() model props ****", thisModelRef.props());
		}

		// PLOT TABLE COLUMNS & ROWS - this fetches api data and plots the table
		async function plotTable(editorRef, thisModelRef, thisTableRef, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue) {
			console.log("editorRef, thisModelRef, thisTableRef, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue", editorRef, thisModelRef, thisTableRef, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue);

			// to save the api trait values as model properties so that next time the modal is open, the value is there that the user enetered previously
			// thisModelRef.attributes.customUrlValue = customUrlValue;
			// thisModelRef.attributes.baseUrlValue = baseUrlValue;
			// thisModelRef.attributes.apiPathValue = apiPathValue;
			// thisModelRef.attributes.apiMethodValue = apiMethodValue;
			// thisModelRef.attributes.apiUsernameValue = apiUsernameValue;
			// thisModelRef.attributes.apiPasswordValue = apiPasswordValue;
			// thisModelRef.attributes.apiBearerTokenValue = apiBearerTokenValue;
			// thisModelRef.attributes.apiMoreHeadersValue = apiMoreHeadersValue;
			// thisModelRef.attributes.apiBodyValue = apiBodyValue;
			// thisModelRef.attributes.nestedArrOfObjKeynameValue = nestedArrOfObjKeynameValue;

			thisModelRef.set({
				customUrlValue,
				baseUrlValue,
				apiPathValue,
				apiMethodValue,
				apiUsernameValue,
				apiBearerTokenValue,
				apiMoreHeadersValue,
				apiBodyValue,
				nestedArrOfObjKeynameValue,
			});

			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// when plotTable is run, call the script of the component so that it gets the updated model properties
			// thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("plotTable model props ****", thisModelRef.props());

			// // fetching API data
			// let apiData;

			// // if only custom url is provided and its a GET call
			// if (customUrlValue && !baseUrlValue && !apiPathValue && apiMethodValue === 'GET') {
			// 	// if nothing is provided, for authentication
			// 	if (!apiUsernameValue && !apiPasswordValue && !apiBearerTokenValue) {
			// 		// add extra headers, if needed
			//         let config = {
			//           	headers: {},
			//         };

			//         if (apiMoreHeadersValue) {
			//             // ['a:b', "c:d"]
			//             // extracting headers from More_Headers trait
			//             apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			//                 let key, val;
			//                 [key, val] = header.split(":");
			//                 console.log("[key, val]:\n", key, val);

			//                 config.headers[key] = val;
			//             });
			//         }

			//         console.log("config:", config);

			//         try {
			//             // GET call to get the data
			//             let response = await fetch(`${customUrlValue}`, config);
			//             let responseData = await response.json();
			//             apiData = responseData;
			//         } catch (e) {
			//             // statements
			//             console.log(e);
			//         }
			// 	}

			// 	// if bearer token is provided but nothing else, for authentication
			// 	else if (!apiUsernameValue && !apiPasswordValue && apiBearerTokenValue) {
			// 		// add extra headers, if needed
			//         let config = {
			//             headers: {
			//               	"Content-Type": "application/json",
			//             	Authorization: `Bearer ${apiBearerTokenValue}`,
			//             },
			//         };

			//         if (apiMoreHeadersValue) {
			//             // ['a:b', "c:d"]
			//             // extracting headers from More_Headers trait
			//             apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			//                 let key, val;
			//                 [key, val] = header.split(":");
			//                 console.log("[key, val]:\n", key, val);

			//                 config.headers[key] = val;
			//             });
			//         }

			//         console.log("config:", config);

			//         try {
			//             // GET call to get the data
			//             let response = await fetch(`${customUrlValue}`, config);
			//             let responseData = await response.json();
			//             apiData = responseData;
			//         } catch (e) {
			//             // statements
			//             console.log(e);
			//         }
			// 	}

			// 	// if username & password are provided but nothing else, for authentication
			// 	else if (apiUsernameValue && apiPasswordValue && !apiBearerTokenValue) {
			// 		// add extra headers, if needed
			//         let config = {
			//             headers: {
			//               	Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
			//             },
			//         };

			//         if (apiMoreHeadersValue) {
			//             // ['a:b', "c:d"]
			//             // extracting headers from More_Headers trait
			//             apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			//                 let key, val;
			//                 [key, val] = header.split(":");
			//                 console.log("[key, val]:\n", key, val);

			//                 config.headers[key] = val;
			//             });
			//         }

			//         console.log("config:", config);

			//         try {
			//             // GET call to get the data
			//             let response = await fetch(`${customUrlValue}`, config);
			//             let responseData = await response.json();
			//             apiData = responseData;
			//         } catch (e) {
			//             // statements
			//             console.log(e);
			//         }
			// 	}
			// }

			// // if only base url and its remaining path is provided and its a GET call
			// else if (!customUrlValue && baseUrlValue && apiPathValue && apiMethodValue === 'GET') {
			// 	let url, baseUrlBearerToken;
			//     if (baseUrlValue === 'baseUrl1') {
			//         url = options.baseUrl1 + apiPathValue;
			//         baseUrlBearerToken = options.baseUrl1BearerToken;
			//     } else if (baseUrlValue === 'baseUrl2') {
			//         url = options.baseUrl2 + apiPathValue;
			//         baseUrlBearerToken = options.baseUrl2BearerToken;
			//     } else if (baseUrlValue === 'baseUrl3') {
			//         url = options.baseUrl3 + apiPathValue;
			//         baseUrlBearerToken = options.baseUrl3BearerToken;
			//     } else if (baseUrlValue === 'baseUrl4') {
			//         url = options.baseUrl4 + apiPathValue;
			//         baseUrlBearerToken = options.baseUrl4BearerToken;
			//     } else if (baseUrlValue === 'baseUrl5') {
			//         url = options.baseUrl5 + apiPathValue;
			//         baseUrlBearerToken = options.baseUrl5BearerToken;
			//     }
			//     console.log('url, baseUrlBearerToken', url, baseUrlBearerToken);

			//     // if nothing is provided, for authentication
			// 	if (!apiUsernameValue && !apiPasswordValue && !baseUrlBearerToken) {
			// 		// add extra headers, if needed
			//         let config = {
			//           	headers: {},
			//         };

			//         if (apiMoreHeadersValue) {
			//             // ['a:b', "c:d"]
			//             // extracting headers from More_Headers trait
			//             apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			//                 let key, val;
			//                 [key, val] = header.split(":");
			//                 console.log("[key, val]:\n", key, val);

			//                 config.headers[key] = val;
			//             });
			//         }

			//         console.log("config:", config);

			//         try {
			//             // GET call to get the data
			//             let response = await fetch(`${url}`, config);
			//             let responseData = await response.json();
			//             apiData = responseData;
			//         } catch (e) {
			//             // statements
			//             console.log(e);
			//         }
			// 	}

			// 	// if bearer token is provided but nothing else, for authentication
			// 	else if (!apiUsernameValue && !apiPasswordValue && baseUrlBearerToken) {
			// 		// add extra headers, if needed
			//         let config = {
			//             headers: {
			//               	"Content-Type": "application/json",
			//             	Authorization: `Bearer ${baseUrlBearerToken}`,
			//             },
			//         };

			//         if (apiMoreHeadersValue) {
			//             // ['a:b', "c:d"]
			//             // extracting headers from More_Headers trait
			//             apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			//                 let key, val;
			//                 [key, val] = header.split(":");
			//                 console.log("[key, val]:\n", key, val);

			//                 config.headers[key] = val;
			//             });
			//         }

			//         console.log("config:", config);

			//         try {
			//             // GET call to get the data
			//             let response = await fetch(`${url}`, config);
			//             let responseData = await response.json();
			//             apiData = responseData;
			//         } catch (e) {
			//             // statements
			//             console.log(e);
			//         }
			// 	}

			// 	// if username & password are provided but nothing else, for authentication
			// 	else if (apiUsernameValue && apiPasswordValue && !baseUrlBearerToken) {
			// 		// add extra headers, if needed
			//         let config = {
			//             headers: {
			//               	Authorization: "Basic " + btoa(apiUsernameValue + ":" + apiPasswordValue),
			//             },
			//         };

			//         if (apiMoreHeadersValue) {
			//             // ['a:b', "c:d"]
			//             // extracting headers from More_Headers trait
			//             apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
			//                 let key, val;
			//                 [key, val] = header.split(":");
			//                 console.log("[key, val]:\n", key, val);

			//                 config.headers[key] = val;
			//             });
			//         }

			//         console.log("config:", config);

			//         try {
			//             // GET call to get the data
			//             let response = await fetch(`${url}`, config);
			//             let responseData = await response.json();
			//             apiData = responseData;
			//         } catch (e) {
			//             // statements
			//             console.log(e);
			//         }
			// 	}
			// }

			// console.log('apiData:', apiData);

			// plotting data into the table
			let modelProps = thisModelRef.props();
			console.log("thisModelRef.props()", modelProps);

			// get all the components in the editor instance
			// const allComps = getAllComponents(editorRef.DomComponents.getWrapper());
			// console.log('allComps', allComps);

			// after getting all the components in the editor instance, find the table component to add/replace headers to it
			// const tableComponent = allComps.find((ele) => ele.attributes.type === 'custom-table');

			// // no need to find the table component, get the table reference from the modal properties
			// const tableComponent = thisTableRef;
			// console.log('tableComponent', tableComponent);

			// // find the <tr> in the table component's <thead> and replace with user-provided header fields
			// const tbody = tableComponent.find('tbody')[0];
			// console.log('tbody', tbody);

			// // clear the inner components of <tbody>
			// console.log('clearing tbody');
			// tbody.components("");

			// // CHECKING & PLOTTING DATASTRUCTURES
			// // if the response is an array-of-objects
			// if (isArrayofObjects(apiData)) {
			// 	console.log('#');

			// 	// loop over apiData array as a table's row
			// 	apiData.forEach((obj) => {
			// 		let tr = {
			// 			tagName: "tr",
			// 			type: "custom-table-tbody-row",
			// 			attributes: {class: "custom-table-tbody-row"},

			// 			"style-default": {
			// 				width: "100%",
			// 			},

			// 			style: {
			// 				width: "100%",
			// 			},

			// 			components: [],
			// 		};

			// 		// get data and add it as table's column
			// 		for (let i = 0; i < modelProps.numColsInputValue; i++) {
			// 			let content;
			// 			if (`HeaderField_${i}` in modelProps) {
			// 				content = obj[`${modelProps[`HeaderField_${i}`]}`];
			// 			}
			// 			console.log('content', content);

			// 			let td = {
			// 				tagName: "td",
			// 				// type: "custom-table-tbody-row",
			// 				// attributes: {class: "custom-table-tbody-row"},

			// 				"style-default": {
			// 					padding: "8px",
			// 				},

			// 				style: {
			// 					padding: "8px",
			// 				},

			// 				content: content,

			// 				// components: [
			// 				// 	{
			// 			    //         tagName: "p",
			// 			    //         type: "text",
			// 			    //         editable: true,

			// 			    //         components: {
			// 			    //             type: "textnode",
			// 			    //             content: content,
			// 			    //         },
			// 			    //     },
			// 				// ],
			// 			};

			// 			tr.components.push(td);
			// 		}

			// 		tbody.append(tr);
			// 	});
			// }
		}

		// UPDATE TABLE COLUMNS & ROWS - this fetches api data and updates the table using toggle component
		function updateTable(editorRef, thisModelRef, thisTableRef, updatingCustomUrlValue, updatingBaseUrlValue, updatingApiPathValue, updatingApiQueryParamValue, updatingApiTableAttributeValue, updatingApiMethodValue, updatingApiUsernameValue, updatingApiPasswordValue, updatingApiBearerTokenValue, updatingApiMoreHeadersValue) {
			console.log("editorRef, thisModelRef, thisTableRef, updatingCustomUrlValue, updatingBaseUrlValue, updatingApiPathValue, updatingApiQueryParamValue, updatingApiTableAttributeValue, updatingApiMethodValue, updatingApiUsernameValue, updatingApiPasswordValue, updatingApiBearerTokenValue, updatingApiMoreHeadersValue", editorRef, thisModelRef, thisTableRef, updatingCustomUrlValue, updatingBaseUrlValue, updatingApiPathValue, updatingApiQueryParamValue, updatingApiTableAttributeValue, updatingApiMethodValue, updatingApiUsernameValue, updatingApiPasswordValue, updatingApiBearerTokenValue, updatingApiMoreHeadersValue);

			thisModelRef.set({
				updatingCustomUrlValue,
				updatingBaseUrlValue,
				updatingApiPathValue,
				updatingApiQueryParamValue,
				updatingApiTableAttributeValue,
				updatingApiMethodValue,
				updatingApiUsernameValue,
				updatingApiPasswordValue,
				updatingApiBearerTokenValue,
				updatingApiMoreHeadersValue,
			});
			
			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// when plotTable is run, call the script of the component so that it gets the updated model properties
			// thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("updateTable model props ****", thisModelRef.props());
		}

		// Add API fields/columns dynamically to the table's main edit modal so that user can select which field to make editable in the action-edit modal
		async function updateMainTableEditModal(editorRef, thisModelRef, thisTableRef, editableApiFieldsContainer, editableApiFieldsButton, searchEditableApiFieldsValue, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue) {
			console.log("editorRef, thisModelRef, thisTableRef, editableApiFieldsContainer, editableApiFieldsButton, searchEditableApiFieldsValue, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue", editorRef, thisModelRef, thisTableRef, editableApiFieldsContainer, editableApiFieldsButton, searchEditableApiFieldsValue, customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue);

			// make API call to get the fields
			let apiData,
				editableFields = {};

			// getting baseUrlBearerToken if apiBearerTokenValue is not provided
			let baseUrlBearerToken;
			if (baseUrlValue === "baseUrl1") {
				baseUrlBearerToken = options.baseUrl1BearerToken;
			} else if (baseUrlValue === "baseUrl2") {
				baseUrlBearerToken = options.baseUrl2BearerToken;
			} else if (baseUrlValue === "baseUrl3") {
				baseUrlBearerToken = options.baseUrl3BearerToken;
			} else if (baseUrlValue === "baseUrl4") {
				baseUrlBearerToken = options.baseUrl4BearerToken;
			} else if (baseUrlValue === "baseUrl5") {
				baseUrlBearerToken = options.baseUrl5BearerToken;
			}

			try {
				let response = await fetch(`${API_HOST}customroutes/getByTableName`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${apiBearerTokenValue ? apiBearerTokenValue : baseUrlBearerToken}`,
					},
					// body: JSON.stringify({projectId : "64df604132bcdbec0c024776"}),
					body: JSON.stringify({
						tableName: extractTableName(customUrlValue ? customUrlValue : options[baseUrlValue] + apiPathValue),
					}),
				});

				let responseData = await response.json();
				apiData = responseData;
			} catch (e) {
				// statements
				console.log(e);
			}

			if (apiData?.fields) {
				// if a search term is provided, then filter
				if (searchEditableApiFieldsValue) {
					// assign back the old EditableFields first in the search-case so that its not replaced by new ones and old ones get lost
					if (thisModelRef.attributes.hasOwnProperty("EditableFields")) {
						editableFields = thisModelRef.get("EditableFields");
					}

					// if a search term is provided, show only that in the modal
					const checkboxContainer = document.createElement("div");
					checkboxContainer.style.display = "flex";
					checkboxContainer.style.flexDirection = "column";

					if (searchEditableApiFieldsValue.includes("-")) {
						// Split the search term by hyphen ("-")
						const searchTermParts = searchEditableApiFieldsValue.split("-").map((part) => part.trim());

						// Check if the search term contains a hyphen and has two parts
						if (searchTermParts.length === 2) {
							const parentFieldName = searchTermParts[0];
							const nestedFieldName = searchTermParts[1];

							// Find the field with the parent field name
							const parentField = apiData.fields.find((field) => field.field_name.toLowerCase() === parentFieldName.toLowerCase());

							if (parentField && parentField.field_type.toLowerCase() === "reference") {
								try {
									// Make an API call to fetch options for the reference field
									let optionsResponse = await fetch(`${API_HOST}customroutes/getByTableName`, {
										method: "POST",
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${apiBearerTokenValue ? apiBearerTokenValue : baseUrlBearerToken}`,
										},
										body: JSON.stringify({
											tableName: extractTableName(customUrlValue ? customUrlValue : options[baseUrlValue] + apiPathValue).split("_")[0] + "_" + parentFieldName,
										}),
									});

									let optionsData = await optionsResponse.json();

									// Filter the options based on the nested field name
									const filteredOptions = optionsData.fields.filter((optionField) => optionField.field_name.toLowerCase().includes(nestedFieldName.toLowerCase()));

									// Loop over the filtered options array and create checkboxes for each option field
									filteredOptions.forEach((optionField) => {
										let optionCheckbox = document.createElement("input");
										optionCheckbox.type = "checkbox";
										optionCheckbox.name = parentField.field_name + "-" + optionField.field_name;
										optionCheckbox.id = parentField.field_name + "-" + optionField.field_name;
										optionCheckbox.value = optionField.field_name;

										optionCheckbox.style.marginRight = "0.5em";

										// Add event listener to option checkboxes
										optionCheckbox.addEventListener("change", () => {
											// key name for example - WebProject1_Address-Street
											let optionFieldNameKey = `${extractTableName(customUrlValue ? customUrlValue : options[baseUrlValue] + apiPathValue).split("_")[0] + "_" + parentField.field_name + "-" + optionField.field_name}`;
											if (optionCheckbox.checked) {
												// Merge the existing editableFields with the new key-value pair
												editableFields = {
													...editableFields,
													[optionFieldNameKey]: optionField.field_type,
												};
											} else {
												// Delete the key-value pair if unchecked
												delete editableFields[optionFieldNameKey];
											}
										});

										let optionLabel = document.createElement("label");
										optionLabel.textContent = parentFieldName + "-" + optionField.field_name;
										optionLabel.htmlFor = parentField.field_name + "-" + optionField.field_name;

										// Append option checkbox and label to checkboxContainer
										const optionCheckboxWrapper = document.createElement("div");
										optionCheckboxWrapper.appendChild(optionCheckbox);
										optionCheckboxWrapper.appendChild(optionLabel);
										checkboxContainer.appendChild(optionCheckboxWrapper);
									});
								} catch (error) {
									console.error("Error fetching options:", error);
								}
							}

							// Clear any existing content in editableApiFieldsContainer
							editableApiFieldsContainer.innerHTML = "";

							// Append checkboxContainer to editableApiFieldsContainer
							editableApiFieldsContainer.appendChild(checkboxContainer);
						} else {
							// Filter the fields based on the search term
							const filteredFields = apiData.fields.filter((field) => field.field_name.toLowerCase().includes(searchEditableApiFieldsValue.toLowerCase()));

							// Loop over the filtered fields array and create label and checkbox pairs
							filteredFields.forEach(async (field) => {
								const label = document.createElement("label");
								label.textContent = field.field_name;

								const checkbox = document.createElement("input");
								checkbox.type = "checkbox";
								checkbox.name = field.field_name;
								checkbox.id = field.field_name;
								checkbox.value = field.field_name;

								checkbox.style.marginRight = "0.5em";

								// Check if the field exists in EditableFields and set the checkbox as checked
								let fieldNameKey = field.field_name.includes(" ") ? `'${field.field_name}'` : field.field_name;
								if (thisModelRef.attributes.hasOwnProperty("EditableFields")) {
									if (thisModelRef.attributes.EditableFields.hasOwnProperty(fieldNameKey)) {
										checkbox.checked = true;
										// Add or update the key-value pair in the object
										editableFields[fieldNameKey] = field.field_type;
									}
								}

								// Add change event listener to each checkbox
								checkbox.addEventListener("change", () => {
									// Wrap the keynames in single-quotes if there are spaces in the field_names
									let fieldNameKey = field.field_name.includes(" ") ? `'${field.field_name}'` : field.field_name; // Wrap field names with spaces in single quotes

									if (checkbox.checked) {
										// Merge the existing editableFields with the new key-value pair
										editableFields = {
											...editableFields,
											[fieldNameKey]: field.field_type,
										};
									} else {
										// Delete the key-value pair if unchecked
										delete editableFields[fieldNameKey];
									}
								});

								// Append label and checkbox to editableApiFieldsContainer
								const checkboxWrapper = document.createElement("div");
								checkboxWrapper.appendChild(checkbox);
								checkboxWrapper.appendChild(label);
								checkboxContainer.appendChild(checkboxWrapper);

								// handling reference field_type
								if (field.field_type.toLowerCase() === "reference") {
									// Fetch options for the reference field
									try {
										let optionsResponse = await fetch(`${API_HOST}customroutes/getByTableName`, {
											method: "POST",
											headers: {
												"Content-Type": "application/json",
												Authorization: `Bearer ${apiBearerTokenValue ? apiBearerTokenValue : baseUrlBearerToken}`,
											},
											body: JSON.stringify({
												tableName: extractTableName(customUrlValue ? customUrlValue : options[baseUrlValue] + apiPathValue).split("_")[0] + "_" + field.field_name,
											}),
										});
										let optionsData = await optionsResponse.json();

										// Loop over the options and create checkboxes for each option field
										if (optionsData.fields) {
											optionsData.fields.forEach((optionField) => {
												let optionCheckbox = document.createElement("input");
												optionCheckbox.type = "checkbox";
												optionCheckbox.name = field.field_name + "-" + optionField.field_name;
												optionCheckbox.id = field.field_name + "-" + optionField.field_name;
												optionCheckbox.value = optionField.field_name;

												optionCheckbox.style.marginRight = "0.5em";

												// Add event listener to option checkboxes
												optionCheckbox.addEventListener("change", () => {
													// key name for example - WebProject1_Address-Street
													let optionFieldNameKey = `${extractTableName(customUrlValue ? customUrlValue : options[baseUrlValue] + apiPathValue).split("_")[0] + "_" + field.field_name + "-" + optionField.field_name}`;
													if (checkbox.checked) {
														// Merge the existing editableFields with the new key-value pair
														editableFields = {
															...editableFields,
															[optionFieldNameKey]: optionField.field_type,
														};
													} else {
														// Delete the key-value pair if unchecked
														delete editableFields[optionFieldNameKey];
													}
												});

												let optionLabel = document.createElement("label");
												optionLabel.textContent = optionField.field_name;
												optionLabel.htmlFor = field.field_name + "-" + optionField.field_name;

												// Append option checkbox and label to checkboxContainer
												const optionCheckboxWrapper = document.createElement("div");
												optionCheckboxWrapper.appendChild(optionCheckbox);
												optionCheckboxWrapper.appendChild(optionLabel);
												checkboxContainer.appendChild(optionCheckboxWrapper);
											});
										}
									} catch (error) {
										console.error("Error fetching options:", error);
									}
								}
							});

							// Clear any existing content in editableApiFieldsContainer
							editableApiFieldsContainer.innerHTML = "";

							// Append checkboxContainer to editableApiFieldsContainer
							editableApiFieldsContainer.appendChild(checkboxContainer);
						}
					}
					// if its not a reference term entered for search
					else {
						const checkboxContainer = document.createElement("div");
						checkboxContainer.style.display = "flex";
						checkboxContainer.style.flexDirection = "column";

						// Filter the fields based on the search term
						const filteredFields = apiData.fields.filter((field) => field.field_name.toLowerCase().includes(searchEditableApiFieldsValue.toLowerCase()));

						// Loop over the filtered fields array and create label and checkbox pairs
						filteredFields.forEach(async (field) => {
							const label = document.createElement("label");
							label.textContent = field.field_name;

							const checkbox = document.createElement("input");
							checkbox.type = "checkbox";
							checkbox.name = field.field_name;
							checkbox.id = field.field_name;
							checkbox.value = field.field_name;

							checkbox.style.marginRight = "0.5em";

							// Check if the field exists in EditableFields and set the checkbox as checked
							let fieldNameKey = field.field_name.includes(" ") ? `'${field.field_name}'` : field.field_name;
							if (thisModelRef.attributes.hasOwnProperty("EditableFields")) {
								if (thisModelRef.attributes.EditableFields.hasOwnProperty(fieldNameKey)) {
									checkbox.checked = true;
									// Add or update the key-value pair in the object
									editableFields[fieldNameKey] = field.field_type;
								}
							}

							// Add change event listener to each checkbox
							checkbox.addEventListener("change", () => {
								// Wrap the keynames in single-quotes if there are spaces in the field_names
								let fieldNameKey = field.field_name.includes(" ") ? `'${field.field_name}'` : field.field_name; // Wrap field names with spaces in single quotes

								if (checkbox.checked) {
									// Add or update the key-value pair in the object
									editableFields[fieldNameKey] = field.field_type;
								} else {
									// Delete the key-value pair if unchecked
									delete editableFields[fieldNameKey];
								}
							});

							// Append label and checkbox to editableApiFieldsContainer
							const checkboxWrapper = document.createElement("div");
							checkboxWrapper.appendChild(checkbox);
							checkboxWrapper.appendChild(label);
							checkboxContainer.appendChild(checkboxWrapper);
						});

						// Clear any existing content in editableApiFieldsContainer
						editableApiFieldsContainer.innerHTML = "";

						// Append checkboxContainer to editableApiFieldsContainer
						editableApiFieldsContainer.appendChild(checkboxContainer);
					}

					// // Clear any existing content in editableApiFieldsContainer
					// editableApiFieldsContainer.innerHTML = '';

					// // Append checkboxContainer to editableApiFieldsContainer
					// editableApiFieldsContainer.appendChild(checkboxContainer);
				}

				// no search term provided
				else {
					// Clear any existing content in editableApiFieldsContainer
					editableApiFieldsContainer.innerHTML = "";

					// console.log('apiData?.fields', apiData?.fields);
					const checkboxContainer = document.createElement("div");
					checkboxContainer.style.display = "flex";
					checkboxContainer.style.gap = "5%";
					checkboxContainer.style.justifyContent = "flex-start";
					checkboxContainer.style.flexWrap = "wrap";
					checkboxContainer.style.width = "50%";
					checkboxContainer.style.maxWidth = "50%";

					let inputElement = document.createElement("div");
					inputElement.style.display = "flex";
					inputElement.style.gap = "5%";
					inputElement.style.justifyContent = "flex-start";
					inputElement.style.flexWrap = "wrap";
					inputElement.style.width = "50%";
					inputElement.style.maxWidth = "50%";

					// Loop over the fields array and create label and checkbox pairs
					apiData.fields.forEach(async (field) => {
						const nonReferenceCheckboxContainer = document.createElement("div");
						nonReferenceCheckboxContainer.style.display = "flex";
						nonReferenceCheckboxContainer.style.alignItems = "baseline";
						nonReferenceCheckboxContainer.style.minWidth = "30%";
						nonReferenceCheckboxContainer.style.maxWidth = "30%";
						nonReferenceCheckboxContainer.style.wordWrap = "break-word";

						const label = document.createElement("label");
						label.style.wordWrap = "break-word";
						label.textContent = field.field_name;

						const checkbox = document.createElement("input");
						checkbox.type = "checkbox";
						checkbox.name = field.field_name;
						checkbox.id = field.field_name;
						checkbox.value = field.field_name;

						checkbox.style.marginRight = "0.5em";

						// ...WRITE THE LOGIC TO REASSIGN THEIR VALUES ALSO THAT THE USER SELECTED PREVIOUSLY...
						// Check if the field exists in EditableFields and set the checkbox as checked - so that user knows what they selected previously for making which fields editable
						let fieldNameKey = field.field_name.includes(" ") ? `'${field.field_name}'` : field.field_name;
						if (thisModelRef.attributes.hasOwnProperty("EditableFields")) {
							if (thisModelRef.attributes.EditableFields.hasOwnProperty(fieldNameKey)) {
								checkbox.checked = true;
								// Add or update the key-value pair in the object
								editableFields[fieldNameKey] = field.field_type;
							}
						}

						// Add change event listener to each checkbox
						checkbox.addEventListener("change", () => {
							// wrap the keynames in single-quotes if there are spaces in the field_names
							let fieldNameKey = field.field_name.includes(" ") ? `'${field.field_name}'` : field.field_name; // Wrap field names with spaces in single quotes

							if (checkbox.checked) {
								// Add or update the key-value pair in the object
								editableFields[fieldNameKey] = field.field_type;
							} else {
								// Delete the key-value pair if unchecked
								delete editableFields[fieldNameKey];
							}

							// // Update the EditableFields prop/traits of the model
							// // *NOTE - Always fill the header fields after providing the API now so that the EditableFields prop gets updated in session storage
							// thisModelRef.set('EditableFields', editableFields);
						});

						// Append label and checkbox to editableApiFieldsContainer
						nonReferenceCheckboxContainer.appendChild(checkbox);
						nonReferenceCheckboxContainer.appendChild(label);
						checkboxContainer.appendChild(nonReferenceCheckboxContainer);

						// handling reference field_type
						if (field.field_type.toLowerCase() === "reference") {
							// Fetch options for the checkboxes
							try {
								let optionsResponse = await fetch(`${API_HOST}customroutes/getByTableName`, {
									method: "POST",
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${apiBearerTokenValue ? apiBearerTokenValue : baseUrlBearerToken}`,
									},
									body: JSON.stringify({
										tableName: extractTableName(customUrlValue ? customUrlValue : options[baseUrlValue] + apiPathValue).split("_")[0] + "_" + field.field_name,
									}),
								});
								let optionsData = await optionsResponse.json();

								// Add checkboxes for each option field
								if (optionsData.fields) {
									optionsData.fields.forEach((optionField) => {
										// Create checkboxes for fields with type 'Reference'
										const referenceCheckboxContainer = document.createElement("div");
										referenceCheckboxContainer.style.display = "flex";
										referenceCheckboxContainer.style.gap = "5%";
										referenceCheckboxContainer.style.alignItems = "baseline";
										referenceCheckboxContainer.style.minWidth = "30%";
										referenceCheckboxContainer.style.maxWidth = "30%";
										referenceCheckboxContainer.style.wordWrap = "break-word";

										let optionCheckbox = document.createElement("input");
										optionCheckbox.type = "checkbox";
										optionCheckbox.name = field.field_name + "-" + optionField.field_name;
										optionCheckbox.id = field.field_name + "-" + optionField.field_name;
										optionCheckbox.value = optionField.field_name;

										optionCheckbox.style.marginRight = "0.5em";

										// Check if the field exists in EditableFields and set the checkbox as checked - so that user knows what they selected previously for making which fields editable
										let optionFieldNameKey = `${extractTableName(customUrlValue ? customUrlValue : options[baseUrlValue] + apiPathValue).split("_")[0] + "_" + field.field_name + "-" + optionField.field_name}`;
										if (thisModelRef.attributes.hasOwnProperty("EditableFields")) {
											if (thisModelRef.attributes.EditableFields.hasOwnProperty(optionFieldNameKey)) {
												optionCheckbox.checked = true;
												// Add checked field to EditableFields
												editableFields[optionFieldNameKey] = optionField.field_type;
											}
										}

										// Add event listener to option checkboxes
										optionCheckbox.addEventListener("change", () => {
											// key name for example - WebProject1_Address-Street
											let optionFieldNameKey = `${extractTableName(customUrlValue ? customUrlValue : options[baseUrlValue] + apiPathValue).split("_")[0] + "_" + field.field_name + "-" + optionField.field_name}`;
											if (optionCheckbox.checked) {
												// Add checked field to EditableFields
												editableFields[optionFieldNameKey] = optionField.field_type;
											} else {
												// Remove unchecked field from EditableFields
												delete editableFields[optionFieldNameKey];
											}
											// // Update the EditableFields prop/traits of the model
											// thisModelRef.set('EditableFields', editableFields);
										});

										let optionLabel = document.createElement("label");
										// optionLabel.textContent = optionField.field_name;
										// optionLabel.textContent = extractTableName(customUrlValue ? customUrlValue : options[baseUrlValue] + apiPathValue).split('_')[0] + '_' + field.field_name + '-' + optionField.field_name;
										optionLabel.style.wordWrap = "break-word";
										optionLabel.textContent = field.field_name + "-" + optionField.field_name;
										optionLabel.htmlFor = field.field_name + " - " + optionField.field_name;

										// Append option checkbox and label to inputElement
										referenceCheckboxContainer.appendChild(optionCheckbox);
										referenceCheckboxContainer.appendChild(optionLabel);
										inputElement.appendChild(referenceCheckboxContainer);
									});
								}
							} catch (error) {
								console.error("Error fetching options:", error);
							}
						}
					});
					editableApiFieldsContainer.appendChild(checkboxContainer);
					// Append inputElement to editableApiFieldsContainer
					editableApiFieldsContainer.appendChild(inputElement);
				}
			}

			// on Apply button click update the session storage
			editableApiFieldsButton.addEventListener("click", function (e) {
				// Update the EditableFields prop/traits of the model
				thisModelRef.set("EditableFields", editableFields);
				thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
				// close the modal on clicking the button, at the end
				editor.Modal.close();
				console.log("thisModelRef updated", thisModelRef);
			});

			// just in case, needed to get props from html attribtes -
			// handling model attributes and session storage for "download/preview/incog window"
			// update the model prop's EditableFields in model's html attributes by stringifying it
			// its not saving the hyphenated keynames as stringify removes them
			thisModelRef.addAttributes({
				"data-EditableFields": JSON.stringify(editableFields),
			});
			console.log("+thisModelRef+", thisModelRef);
		}

		// STORE VALUES OF TOTAL-ROWS-API as MODEL PROPS - this fetches total-rows-api values and store it so that it can be used in Paginate method to initialize pagination component
		async function storeTotalRowsApi(editorRef, thisModelRef, thisTableRef, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue) {
			console.log("editorRef, thisModelRef, thisTableRef, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue", editorRef, thisModelRef, thisTableRef, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue);

			// to save the api trait values as model properties so that next time the modal is open, the value is there that the user enetered previously
			// thisModelRef.attributes.totalRowsAPIValue = totalRowsAPIValue;
			// thisModelRef.attributes.totalRowsAPIKeynameValue = totalRowsAPIKeynameValue;
			// thisModelRef.attributes.totalRowsAPIUsernameValue = totalRowsAPIUsernameValue;
			// thisModelRef.attributes.totalRowsAPIPasswordValue = totalRowsAPIPasswordValue;
			// thisModelRef.attributes.totalRowsAPIBearerTokenValue = totalRowsAPIBearerTokenValue;
			// thisModelRef.attributes.totalRowsAPIMoreHeadersValue = totalRowsAPIMoreHeadersValue;

			thisModelRef.set("totalRowsAPIValue", `${totalRowsAPIValue}`);
			thisModelRef.set("totalRowsAPIBaseUrlValue", `${totalRowsAPIBaseUrlValue}`);
			thisModelRef.set("totalRowsAPIPathValue", `${totalRowsAPIPathValue}`);
			thisModelRef.set("totalRowsAPIKeynameValue", `${totalRowsAPIKeynameValue}`);
			thisModelRef.set("totalRowsAPIMethodValue", `${totalRowsAPIMethodValue}`);
			thisModelRef.set("totalRowsAPIUsernameValue", `${totalRowsAPIUsernameValue}`);
			thisModelRef.set("totalRowsAPIPasswordValue", `${totalRowsAPIPasswordValue}`);
			thisModelRef.set("totalRowsAPIBearerTokenValue", `${totalRowsAPIBearerTokenValue}`);
			thisModelRef.set("totalRowsAPIMoreHeadersValue", `${totalRowsAPIMoreHeadersValue}`);
			thisModelRef.set("totalRowsAPIBodyValue", `${totalRowsAPIBodyValue}`);
			thisModelRef.set("totalRowsAPINestedArrOfObjKeynameValue", `${totalRowsAPINestedArrOfObjKeynameValue}`);

			// // also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// // when plotTable is run, call the script of the component so that it gets the updated model properties
			// thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("storeTotalRowsApi model props ****", thisModelRef.props(), thisModelRef.getEl());
		}

		// ADD A ROW IN TFOOT - this fetches api data and plots the table
		async function addRowInTfoot(editorRef, thisModelRef, thisTableRef, numColsInputValue) {
			console.log("editorRef, thisModelRef, thisTableRef, numColsInputValue", editorRef, thisModelRef, thisTableRef, numColsInputValue);

			let tfootRow = {
				tagName: "tr",
				type: "custom-table-tfoot-row",
				attributes: {
					class: "custom-table-tfoot-row",
				},

				"style-default": {
					width: "100%",
					"background-color": thisModelRef.get("tableTfootRowEnableBackgroundColor") ? thisModelRef.get("tableTfootRowBackgroundColor") : "lightgray",
					"background-image": thisModelRef.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${thisModelRef.get("tableTfootRowLinearGradient1")}, ${thisModelRef.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
					"border-top": thisModelRef.get("tableTfootRowBorderTopWidth") || thisModelRef.get("tableTfootRowBorderTopType") || thisModelRef.get("tableTfootRowBorderTopColor") ? `${thisModelRef.get("tableTfootRowBorderTopWidth")}px ${thisModelRef.get("tableTfootRowBorderTopType")} ${thisModelRef.get("tableTfootRowBorderTopColor")}` : "",
					"border-right": thisModelRef.get("tableTfootRowBorderRightWidth") || thisModelRef.get("tableTfootRowBorderRightType") || thisModelRef.get("tableTfootRowBorderRightColor") ? `${thisModelRef.get("tableTfootRowBorderRightWidth")}px ${thisModelRef.get("tableTfootRowBorderRightType")} ${thisModelRef.get("tableTfootRowBorderRightColor")}` : "",
					"border-bottom": thisModelRef.get("tableTfootRowBorderBottomWidth") || thisModelRef.get("tableTfootRowBorderBottomType") || thisModelRef.get("tableTfootRowBorderBottomColor") ? `${thisModelRef.get("tableTfootRowBorderBottomWidth")}px ${thisModelRef.get("tableTfootRowBorderBottomType")} ${thisModelRef.get("tableTfootRowBorderBottomColor")}` : "",
					"border-left": thisModelRef.get("tableTfootRowBorderLeftWidth") || thisModelRef.get("tableTfootRowBorderLeftType") || thisModelRef.get("tableTfootRowBorderLeftColor") ? `${thisModelRef.get("tableTfootRowBorderLeftWidth")}px ${thisModelRef.get("tableTfootRowBorderLeftType")} ${thisModelRef.get("tableTfootRowBorderLeftColor")}` : "",
				},

				style: {
					width: "100%",
					"background-color": thisModelRef.get("tableTfootRowEnableBackgroundColor") ? thisModelRef.get("tableTfootRowBackgroundColor") : "lightgray",
					"background-image": thisModelRef.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${thisModelRef.get("tableTfootRowLinearGradient1")}, ${thisModelRef.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
					"border-top": thisModelRef.get("tableTfootRowBorderTopWidth") || thisModelRef.get("tableTfootRowBorderTopType") || thisModelRef.get("tableTfootRowBorderTopColor") ? `${thisModelRef.get("tableTfootRowBorderTopWidth")}px ${thisModelRef.get("tableTfootRowBorderTopType")} ${thisModelRef.get("tableTfootRowBorderTopColor")}` : "",
					"border-right": thisModelRef.get("tableTfootRowBorderRightWidth") || thisModelRef.get("tableTfootRowBorderRightType") || thisModelRef.get("tableTfootRowBorderRightColor") ? `${thisModelRef.get("tableTfootRowBorderRightWidth")}px ${thisModelRef.get("tableTfootRowBorderRightType")} ${thisModelRef.get("tableTfootRowBorderRightColor")}` : "",
					"border-bottom": thisModelRef.get("tableTfootRowBorderBottomWidth") || thisModelRef.get("tableTfootRowBorderBottomType") || thisModelRef.get("tableTfootRowBorderBottomColor") ? `${thisModelRef.get("tableTfootRowBorderBottomWidth")}px ${thisModelRef.get("tableTfootRowBorderBottomType")} ${thisModelRef.get("tableTfootRowBorderBottomColor")}` : "",
					"border-left": thisModelRef.get("tableTfootRowBorderLeftWidth") || thisModelRef.get("tableTfootRowBorderLeftType") || thisModelRef.get("tableTfootRowBorderLeftColor") ? `${thisModelRef.get("tableTfootRowBorderLeftWidth")}px ${thisModelRef.get("tableTfootRowBorderLeftType")} ${thisModelRef.get("tableTfootRowBorderLeftColor")}` : "",
				},

				components: [],
			};

			for (let i = 0; i < numColsInputValue; i++) {
				let td = {
					tagName: "td",
					editable: true,

					"style-default": {
						"font-size": thisModelRef.get("tableTfootFontSize") ? `${thisModelRef.get("tableTfootFontSize")}vw` : "",
						color: thisModelRef.get("tableTfootFontColor") ? thisModelRef.get("tableTfootFontColor") : "",
						"font-family": thisModelRef.get("tableTfootFontFamily") ? thisModelRef.get("tableTfootFontFamily") : "",
					},

					style: {
						"font-size": thisModelRef.get("tableTfootFontSize") ? `${thisModelRef.get("tableTfootFontSize")}vw` : "",
						color: thisModelRef.get("tableTfootFontColor") ? thisModelRef.get("tableTfootFontColor") : "",
						"font-family": thisModelRef.get("tableTfootFontFamily") ? thisModelRef.get("tableTfootFontFamily") : "",
					},

					components: [
						{
							tagName: "p",
							type: "text",
							editable: true,

							"style-default": {
								padding: "8px",
								// "border-left": "1px solid black",
								// "border-right": "1px solid black",
							},

							style: {
								padding: "8px",
								// "border-left": "1px solid black",
								// "border-right": "1px solid black",
							},

							components: {
								type: "textnode",
								content: `Footer cell ${i}`,
							},
						},
					],
				};

				tfootRow.components.push(td);
			}

			let paginationComponent = thisTableRef.find(".custom-table-tfoot")[0].find(".custom-table-tfoot-row-for-pagination")[0];
			// if there is pagination component, add the new row right on top of it. Else keep appending the row at the end
			if (paginationComponent) {
				let paginationComponentIdx = paginationComponent.index();
				console.log("index of paginationComponent", paginationComponentIdx);
				// if the position of pagination is first only, add the new row in its position
				if (paginationComponentIdx === 0) {
					thisTableRef.find(".custom-table-tfoot")[0].components().add(tfootRow, {
						at: 0,
					});
				} else {
					thisTableRef.find(".custom-table-tfoot")[0].components().add(tfootRow, {
						at: paginationComponentIdx,
					});
				}
			} else {
				let lastPosition = thisTableRef.find(".custom-table-tfoot")[0].components().length;
				thisTableRef
					.find(".custom-table-tfoot")[0]
					.components()
					.add(tfootRow, {
						at: lastPosition - 1,
					});
			}

			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// when plotTable is run, call the script of the component so that it gets the updated model properties
			thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("addRowInTfoot model props ****", thisModelRef.props());

			// plotting data into the table
			let modelProps = thisModelRef.props();
			console.log("thisModelRef.props()", modelProps);
		}

		// UPDATE ROWS IN TFOOT - this updates the rows in tfoot by the number of columns
		async function updateRowInTfoot(editorRef, thisModelRef, thisTableRef, numColsInputValue, addColAtIdxValue) {
			console.log("editorRef, thisModelRef, thisTableRef, numColsInputValue", editorRef, thisModelRef, thisTableRef, numColsInputValue);

			let allRows = thisTableRef.find(".custom-table-tfoot")[0].find("tr.custom-table-tfoot-row");
			console.log("allRows", allRows);
			// if one column is added from addColumnAtIndex() then add a cell in footer at the same index - addColAtIdxValue
			if (addColAtIdxValue) {
				// loop through all rows and add a cell at an index
				for (let row = 0; row < allRows.length; row++) {
					let td = {
						tagName: "td",
						editable: true,

						"style-default": {
							"font-size": thisModelRef.get("tableTfootFontSize") ? `${thisModelRef.get("tableTfootFontSize")}vw` : "",
							color: thisModelRef.get("tableTfootFontColor") ? thisModelRef.get("tableTfootFontColor") : "",
							"font-family": thisModelRef.get("tableTfootFontFamily") ? thisModelRef.get("tableTfootFontFamily") : "",
						},

						style: {
							"font-size": thisModelRef.get("tableTfootFontSize") ? `${thisModelRef.get("tableTfootFontSize")}vw` : "",
							color: thisModelRef.get("tableTfootFontColor") ? thisModelRef.get("tableTfootFontColor") : "",
							"font-family": thisModelRef.get("tableTfootFontFamily") ? thisModelRef.get("tableTfootFontFamily") : "",
						},

						components: [
							{
								tagName: "p",
								type: "text",
								editable: true,

								components: {
									type: "textnode",
									content: `Updated footer row ${row} cell`,
								},
							},
						],
					};

					// insert the new cell at the specified index if it doesn't have the showDBSizeInTableFooter
					if (!allRows[row].find(".showDBSizeInTableFooter")[0]) {
						allRows[row].components().add(td, {
							at: addColAtIdxValue,
						});
					} else {
						// update the colspan attribute of the total-row-result footer tr
						allRows[row].find("td")[0].addAttributes({
							colspan: `${numColsInputValue}`,
						});
						console.log("allRows[row] total", allRows[row]);
					}
				}
			}

			// else if addColAtIdxValue is not provided meaning - if number-of-headers is updated, then just ADD/REMOVE footer cells accordingly
			else {
				// loop through all the rows and check if they have updated amount of columns
				for (let row = 0; row < allRows.length; row++) {
					if (!allRows[row].find(".showDBSizeInTableFooter")[0]) {
						// number of td in the row are equal to numColsInputValue then skip, else update
						let allCellsInRow = allRows[row].find("td");

						// when COLUMNS ADDED: if number of cells in the row is less than current/updated number of columns
						if (allCellsInRow.length !== numColsInputValue && allCellsInRow.length <= numColsInputValue) {
							// see how many cells in that row are missing from the current amount of columns
							let difference = numColsInputValue - allCellsInRow.length;

							for (let diff = 0; diff < difference; diff++) {
								let td = {
									tagName: "td",
									editable: true,

									"style-default": {
										"font-size": thisModelRef.get("tableTfootFontSize") ? `${thisModelRef.get("tableTfootFontSize")}vw` : "",
										color: thisModelRef.get("tableTfootFontColor") ? thisModelRef.get("tableTfootFontColor") : "",
										"font-family": thisModelRef.get("tableTfootFontFamily") ? thisModelRef.get("tableTfootFontFamily") : "",
									},

									style: {
										"font-size": thisModelRef.get("tableTfootFontSize") ? `${thisModelRef.get("tableTfootFontSize")}vw` : "",
										color: thisModelRef.get("tableTfootFontColor") ? thisModelRef.get("tableTfootFontColor") : "",
										"font-family": thisModelRef.get("tableTfootFontFamily") ? thisModelRef.get("tableTfootFontFamily") : "",
									},

									components: [
										{
											tagName: "p",
											type: "text",
											editable: true,

											components: {
												type: "textnode",
												content: `Updated footer cell ${diff}`,
											},
										},
									],
								};

								allRows[row].append(td);
							}
						}

						// when COLUMNS DELETED: if number of cells in the row is less than current/updated number of columns
						else if (allCellsInRow.length !== numColsInputValue && allCellsInRow.length >= numColsInputValue) {
							// see how many cells in that row are missing from the current amount of columns
							let difference = allCellsInRow.length - numColsInputValue;

							for (let diff = 0; diff < difference; diff++) {
								allRows[row].getLastChild().remove();
							}
						}
					} else {
						// update the colspan attribute of the total-row-result footer tr
						allRows[row].find("td")[0].addAttributes({
							colspan: `${numColsInputValue}`,
						});
						console.log("allRows[row] total", allRows[row]);
					}
				}
			}

			// also call Paginate so that a new component is added
			// thisModelRef.Paginate.call(thisModelRef);
			// when plotTable is run, call the script of the component so that it gets the updated model properties
			thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
			console.log("addRowInTfoot model props ****", thisModelRef.props());

			// plotting data into the table
			let modelProps = thisModelRef.props();
			console.log("thisModelRef.props()", modelProps);
		}

		// APPLY DISPLAY DATE FORMATS on columns displaying dates in the table
		async function applyDisplayDateFormats(editor, thisModelRef, thisTableRef, colIdx, columnDatatype, columnDateFormat) {
			console.log("editor, thisModelRef, thisTableRef, colIdx, columnDatatype, columnDateFormat", editor, thisModelRef, thisTableRef, colIdx, columnDatatype, columnDateFormat);

			// update the model props
			let columnwiseDisplayDateFormats = thisModelRef.get("ColumnwiseDisplayDateFormats");
			// let columnwiseDisplayDateFormats = thisModelRef.attributes.ColumnwiseDisplayDateFormats;
			// columnwiseDisplayDateFormats[`col${colIdx}`] = {
			// 	columnDatatype,
			// 	columnDateFormat,
			// };
			columnwiseDisplayDateFormats = {
				...columnwiseDisplayDateFormats,
				[`col${colIdx}`]: columnDateFormat,
			};

			// while registering this column for date conversion in 'ColumnwiseDisplayDateFormats', also remove them from conversions 'ColumnwiseDisplayTimeZoneFormats' & 'ColumnwiseDisplayNumberFormats'
			// Check and remove col${colIdx} from ColumnwiseDisplayTimeZoneFormats and ColumnwiseDisplayNumberFormats
			let columnwiseDisplayTimeZoneFormats = thisModelRef.get("ColumnwiseDisplayTimeZoneFormats");
			let columnwiseDisplayNumberFormats = thisModelRef.get("ColumnwiseDisplayNumberFormats");

			// Remove the key if it exists in ColumnwiseDisplayTimeZoneFormats
			if (columnwiseDisplayTimeZoneFormats && columnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${colIdx}`)) {
				delete columnwiseDisplayTimeZoneFormats[`col${colIdx}`];
				thisModelRef.set("ColumnwiseDisplayTimeZoneFormats", columnwiseDisplayTimeZoneFormats);
			}

			// Remove the key if it exists in ColumnwiseDisplayNumberFormats
			if (columnwiseDisplayNumberFormats && columnwiseDisplayNumberFormats.hasOwnProperty(`col${colIdx}`)) {
				delete columnwiseDisplayNumberFormats[`col${colIdx}`];
				thisModelRef.set("ColumnwiseDisplayNumberFormats", columnwiseDisplayNumberFormats);
			}

			if (Object.keys(columnwiseDisplayDateFormats).length > 0) {
				thisModelRef.set("ColumnwiseDisplayDateFormats", columnwiseDisplayDateFormats);
				// thisModelRef.set('ColumnwiseDisplayDateFormats', `${columnwiseDisplayDateFormats}`);
				// thisModelRef.set({ColumnwiseDisplayDateFormats:  columnwiseDisplayDateFormats});
				// thisModelRef.attributes.ColumnwiseDisplayDateFormats = columnwiseDisplayDateFormats;
				thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
				// await editor.store()
			}
			console.log("+thisModelRef+", thisModelRef);
		}
		
		// APPLY DISPLAY DATE FORMATS on columns displaying dates in the table
		async function applyDisplayTimeZoneFormats(editor, thisModelRef, thisTableRef, colIdx, columnDatatype, columnTimezoneFormat) {
			console.log("editor, thisModelRef, thisTableRef, colIdx, columnDatatype, columnTimezoneFormat", editor, thisModelRef, thisTableRef, colIdx, columnDatatype, columnTimezoneFormat);

			// update the model props
			let columnwiseDisplayTimeZoneFormats = thisModelRef.get("ColumnwiseDisplayTimeZoneFormats");
			// let columnwiseDisplayTimeZoneFormats = thisModelRef.attributes.columnwiseDisplayTimeZoneFormats;
			// columnwiseDisplayTimeZoneFormats[`col${colIdx}`] = {
			// 	columnDatatype,
			// 	columnTimezoneFormat,
			// };
			columnwiseDisplayTimeZoneFormats = {
				...columnwiseDisplayTimeZoneFormats,
				[`col${colIdx}`]: columnTimezoneFormat,
			};

			// while registering this column for date conversion in 'ColumnwiseDisplayTimeZoneFormats', also remove them from conversions 'ColumnwiseDisplayDateFormats' & 'ColumnwiseDisplayNumberFormats'
			// Check and remove col${colIdx} from ColumnwiseDisplayDateFormats and ColumnwiseDisplayNumberFormats
			let columnwiseDisplayDateFormats = thisModelRef.get("ColumnwiseDisplayDateFormats");
			let columnwiseDisplayNumberFormats = thisModelRef.get("ColumnwiseDisplayNumberFormats");

			// Remove the key if it exists in ColumnwiseDisplayDateFormats
			if (columnwiseDisplayDateFormats && columnwiseDisplayDateFormats.hasOwnProperty(`col${colIdx}`)) {
				delete columnwiseDisplayDateFormats[`col${colIdx}`];
				thisModelRef.set("ColumnwiseDisplayDateFormats", columnwiseDisplayDateFormats);
			}

			// Remove the key if it exists in ColumnwiseDisplayNumberFormats
			if (columnwiseDisplayNumberFormats && columnwiseDisplayNumberFormats.hasOwnProperty(`col${colIdx}`)) {
				delete columnwiseDisplayNumberFormats[`col${colIdx}`];
				thisModelRef.set("ColumnwiseDisplayNumberFormats", columnwiseDisplayNumberFormats);
			}

			if (Object.keys(columnwiseDisplayTimeZoneFormats).length > 0) {
				thisModelRef.set("ColumnwiseDisplayTimeZoneFormats", columnwiseDisplayTimeZoneFormats);
				// thisModelRef.set('columnwiseDisplayTimeZoneFormats', `${columnwiseDisplayTimeZoneFormats}`);
				// thisModelRef.set({columnwiseDisplayTimeZoneFormats:  columnwiseDisplayTimeZoneFormats});
				// thisModelRef.attributes.columnwiseDisplayTimeZoneFormats = columnwiseDisplayTimeZoneFormats;
				thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
				// await editor.store()
			}
			console.log("+thisModelRef+", thisModelRef);
		}

		// APPLY DISPLAY NUMBER FORMATS on columns displaying dates in the table
		async function applyDisplayNumberFormats(editor, thisModelRef, thisTableRef, colIdx, columnDatatype, columnNumberFormat) {
			console.log("editor, thisModelRef, thisTableRef, colIdx, columnDatatype, columnNumberFormat", editor, thisModelRef, thisTableRef, colIdx, columnDatatype, columnNumberFormat);

			// update the model props
			let columnwiseDisplayNumberFormats = thisModelRef.get("ColumnwiseDisplayNumberFormats");
			// let columnwiseDisplayDateFormats = thisModelRef.attributes.ColumnwiseDisplayDateFormats;
			// columnwiseDisplayDateFormats[`col${colIdx}`] = {
			// 	columnDatatype,
			// 	columnDateFormat,
			// };
			columnwiseDisplayNumberFormats = {
				...columnwiseDisplayNumberFormats,
				[`col${colIdx}`]: columnNumberFormat,
			};

			// while registering this column for date conversion in 'ColumnwiseDisplayNumberFormats', also remove them from conversions 'ColumnwiseDisplayDateFormats' & 'ColumnwiseDisplayTimeZoneFormats'
			// Check and remove col${colIdx} from ColumnwiseDisplayDateFormats and ColumnwiseDisplayTimeZoneFormats
			let columnwiseDisplayDateFormats = thisModelRef.get("ColumnwiseDisplayDateFormats");
			let columnwiseDisplayTimeZoneFormats = thisModelRef.get("ColumnwiseDisplayTimeZoneFormats");

			// Remove the key if it exists in ColumnwiseDisplayDateFormats
			if (columnwiseDisplayDateFormats && columnwiseDisplayDateFormats.hasOwnProperty(`col${colIdx}`)) {
				delete columnwiseDisplayDateFormats[`col${colIdx}`];
				thisModelRef.set("ColumnwiseDisplayDateFormats", columnwiseDisplayDateFormats);
			}

			// Remove the key if it exists in ColumnwiseDisplayTimeZoneFormats
			if (columnwiseDisplayTimeZoneFormats && columnwiseDisplayTimeZoneFormats.hasOwnProperty(`col${colIdx}`)) {
				delete columnwiseDisplayTimeZoneFormats[`col${colIdx}`];
				thisModelRef.set("ColumnwiseDisplayTimeZoneFormats", columnwiseDisplayTimeZoneFormats);
			}

			if (Object.keys(columnwiseDisplayNumberFormats).length > 0) {
				thisModelRef.set("ColumnwiseDisplayNumberFormats", columnwiseDisplayNumberFormats);
				// thisModelRef.set('ColumnwiseDisplayDateFormats', `${columnwiseDisplayDateFormats}`);
				// thisModelRef.set({ColumnwiseDisplayDateFormats:  columnwiseDisplayDateFormats});
				// thisModelRef.attributes.ColumnwiseDisplayDateFormats = columnwiseDisplayDateFormats;
				thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
				// await editor.store()
			}
			console.log("+thisModelRef+", thisModelRef);
		}

		// APPLY functions to specific column in the table (from LocStr/SessStr)
		async function applyFunctionsFromStorageFunc(editor, thisModelRef, thisTableRef, colIdx, applyFunctionsFromStorage) {
			console.log("editor, thisModelRef, thisTableRef, colIdx, applyFunctionsFromStorage", editor, thisModelRef, thisTableRef, colIdx, applyFunctionsFromStorage);

			// update the model props
			let applyFunctionsFromStorageObj = thisModelRef.get("applyFunctionsFromStorage");

			applyFunctionsFromStorageObj = {
				...applyFunctionsFromStorageObj,
				[`col${colIdx}`]: applyFunctionsFromStorage,
			};

			if (Object.keys(applyFunctionsFromStorageObj).length > 0) {
				thisModelRef.set("applyFunctionsFromStorage", applyFunctionsFromStorageObj);
				thisModelRef.attributes.script.call(thisModelRef.getEl(), thisModelRef.props());
				// await editor.store()
			}
			console.log("+thisModelRef+", thisModelRef);
		}

		// helper function to return a list of all components in the editor instance
		const getAllComponents = (model, result = []) => {
			result.push(model);
			model.components().each((mod) => getAllComponents(mod, result));
			return result;
		};

		// helper function to check if a variable is an array-of-objects or not
		function isArrayofObjects(variable) {
			// Check if the variable is an array
			if (!Array.isArray(variable)) {
				return false;
			}

			// Check if all elements in the array are objects
			for (const element of variable) {
				if (typeof element !== "object" || Array.isArray(element) || element === null) {
					return false;
				}
			}
			return true;
		}

		// helper function to get value of a key in nested object
		function findKeyValue(obj, key) {
			if (obj.hasOwnProperty(key)) {
				// If the current object has the key, return its value
				return obj[key];
			}

			for (var prop in obj) {
				if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
					// If the current property is an object, recursively search within it
					var result = findKeyValue(obj[prop], key);
					if (result !== undefined) {
						return result;
					}
				}
			}
			// Key not found in the object
			return undefined;
		}

		// helper function to extract arguments from linear-gradient(30deg, purple, red) string
		function extractLinearGradientValues(gradient) {
			// Regular expression to match linear gradient syntax
			const regex = /linear-gradient\((.+?)\)/;
			const match = gradient.match(regex);

			if (match) {
				// Extracting the content inside the parentheses
				const gradientContent = match[1];

				// Splitting the content by commas
				const gradientValues = gradientContent.split(",");

				// Trim each value to remove extra spaces
				const trimmedValues = gradientValues.map((value) => value.trim());

				// Extracting angle, start color, and end color
				const angle = trimmedValues[0];
				const startColor = trimmedValues[1];
				const endColor = trimmedValues[2];

				return {
					angle,
					startColor,
					endColor,
				};
			}

			// Return null if the input doesn't match the expected format
			return null;
		}

		// helper function to extract arguments from '1px solid red'
		function parseBorder(border) {
			const regex = /(\d+px)?\s*([\w\s]+)?\s*(.*)/;

			const matches = border.match(regex);

			if (!matches) {
				// Invalid border format
				return null;
			}

			const [, width, style, color] = matches;

			return {
				width: width || "1px", // Default width to '1px' if not provided
				style: style ? style.trim() : "solid", // Default style to 'solid' if not provided
				color: color ? color.trim() : "black", // Default color to 'black' if not provided
			};
		}

		// helper function to extract table names from redSling datamodels
		// const extractTableName = (url) => {
		//     const regex = /\/(data_grid|table_data|contains_filter_grid|nested_data|strict_filter_nested)\/([^?\/]+)/;
		//     const match = url.match(regex);
		//     return match ? match[2] : null;
		// };

		const extractTableName = (url) => {
			// Match the table name after the last forward slash (/)
			const regex = /\/([^\/?]+)(\?|$)/;
			const match = url.match(regex);
			return match ? match[1] : null;
		};

		editor.on("component:add", (componentModel) => {
			if (componentModel.get("type") === "custom-table-container") {
				componentModel.set("HeaderFields", {});
				componentModel.set("richTextIndexes", []);
				componentModel.attributes.HeaderFields = {};
			}			
		});

		// TO UPDATE TOTAL-ROWS COUNT & PAGINATION, on refresh (using the same Paginate() functionality)
		// (add to paginationScript also so that these work in download as well)
		editor.on("component:mount", async (componentModel) => {
			// console.log('componentModel', componentModel);

			if (componentModel.get("type") === "custom-table-container") {
				// just to give scrollbar color to old tables also as well as newly added ones
				componentModel.setStyle({
					padding: "0.3em",
					"overflow-x": "auto",
					"scrollbar-color": "rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)",
				});

				const customTableContainer = componentModel;
				const customTable = customTableContainer.find(".custom-table")[0];
				const customTableThead = customTable.find(".custom-table-thead")[0];
				const customTableTbody = customTable.find(".custom-table-tbody")[0];
				const customTableTfoot = customTable.find(".custom-table-tfoot")[0];

				// adding custom-table-sort-container to columns that don't have one
				let allRows = customTableThead.find('.custom-table-thead-row');
				allRows.forEach((row) => {
					let allHeaderCells = row.find('th[data-gjs-type="custom-table-thead-row"');
					// console.log('@@ allHeaderCells', allHeaderCells);

					allHeaderCells.forEach((cell) => {
						let sortingBtnContainerIsThere =  cell.find('.custom-table-sort-container');
						// console.log('@@ sortingBtnContainerIsThere', sortingBtnContainerIsThere);
						// if there are no sorting buttons then add them
						if ((sortingBtnContainerIsThere.length === 0) && componentModel.get("SortingBtnsInAllCols")) {
							cell.components().push({type: "custom-table-sort-container"});
						}
					});
				});

				// making pagination dynamic, such that if new record is added or deleted or edited in backend DB then it should reflect on the already plotted tables on editor loading
				let paginationRequired = componentModel.get("paginationRequired");
				let TotalRows = componentModel.get("TotalRows");
				let showDBSizeInTableFooter = componentModel.get("showDBSizeInTableFooter");
				let useTablePlottingApiForPagination = componentModel.get("useTablePlottingApiForPagination");
				let numRowsPerPageValue = componentModel.get("numRowsPerPageValue");
				let numColsInputValue = componentModel.get("numColsInputValue");

				let totalRowsAPIValue = componentModel.get("totalRowsAPIValue");
				let totalRowsAPIBaseUrlValue = componentModel.get("totalRowsAPIBaseUrlValue");
				let totalRowsAPIPathValue = componentModel.get("totalRowsAPIPathValue");
				let totalRowsAPIKeynameValue = componentModel.get("totalRowsAPIKeynameValue");
				let totalRowsAPIMethodValue = componentModel.get("totalRowsAPIMethodValue");
				let totalRowsAPIUsernameValue = componentModel.get("totalRowsAPIUsernameValue");
				let totalRowsAPIPasswordValue = componentModel.get("totalRowsAPIPasswordValue");
				let totalRowsAPIBearerTokenValue = componentModel.get("totalRowsAPIBearerTokenValue");
				let totalRowsAPIMoreHeadersValue = componentModel.get("totalRowsAPIMoreHeadersValue");
				let totalRowsAPIBodyValue = componentModel.get("totalRowsAPIBodyValue");
				let totalRowsAPINestedArrOfObjKeynameValue = componentModel.get("totalRowsAPINestedArrOfObjKeynameValue");

				let customUrlValue = componentModel.get("customUrlValue");
				let baseUrlValue = componentModel.get("baseUrlValue");
				let apiPathValue = componentModel.get("apiPathValue");
				let apiMethodValue = componentModel.get("apiMethodValue");
				let apiUsernameValue = componentModel.get("apiUsernameValue");
				let apiPasswordValue = componentModel.get("apiPasswordValue");
				let apiBearerTokenValue = componentModel.get("apiBearerTokenValue");
				let apiMoreHeadersValue = componentModel.get("apiMoreHeadersValue");
				let apiBodyValue = componentModel.get("apiBodyValue");
				let nestedArrOfObjKeynameValue = componentModel.get("nestedArrOfObjKeynameValue");
				// wrapping these values in updatedProps named object because the if cases below are extracting value from updatedProps - so this was a shortcut
				let updatedProps = {
					customUrlValue,
					baseUrlValue,
					apiPathValue,
					apiMethodValue,
					apiUsernameValue,
					apiPasswordValue,
					apiBearerTokenValue,
					apiMoreHeadersValue,
					apiBodyValue,
					nestedArrOfObjKeynameValue,
				};

				console.log("++ paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue", paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue);
				console.log("++ customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue", customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue);

				// make an api call to get the TotalRows
				let totalRows;
				let totalRowsVal;
				let apiData;

				// use total-rows-api if the user has this api, else use the table-plotting api
				if (!useTablePlottingApiForPagination) {
					// if only totalRowsAPIValue is provided and its a GET call
					if (totalRowsAPIValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
						// if nothing is provided, for authentication
						if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${totalRowsAPIValue}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${totalRowsAPIValue}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${totalRowsAPIValue}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only totalRowsAPIBaseUrlValue is provided and its a GET call
					else if (!totalRowsAPIValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
						// concatenating base url and its remaining part
						let url, baseUrlBearerToken;
						if (totalRowsAPIBaseUrlValue === "baseUrl1") {
							url = options.baseUrl1 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl1BearerToken;
						} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
							url = options.baseUrl2 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl2BearerToken;
						} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
							url = options.baseUrl3 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl3BearerToken;
						} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
							url = options.baseUrl4 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl4BearerToken;
						} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
							url = options.baseUrl5 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl5BearerToken;
						}
						console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${baseUrlBearerToken}`,
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only totalRowsAPIValue is provided and its a POST call
					else if (totalRowsAPIValue && totalRowsAPIBodyValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
						// if nothing is provided, for authentication
						if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: totalRowsAPIBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${totalRowsAPIValue}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: totalRowsAPIBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${totalRowsAPIValue}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: totalRowsAPIBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${totalRowsAPIValue}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only totalRowsAPIBaseUrlValue is provided and its a POST call
					else if (!totalRowsAPIValue && totalRowsAPIBodyValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
						// concatenating base url and its remaining part
						let url, baseUrlBearerToken;
						if (totalRowsAPIBaseUrlValue === "baseUrl1") {
							url = options.baseUrl1 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl1BearerToken;
						} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
							url = options.baseUrl2 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl2BearerToken;
						} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
							url = options.baseUrl3 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl3BearerToken;
						} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
							url = options.baseUrl4 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl4BearerToken;
						} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
							url = options.baseUrl5 + totalRowsAPIPathValue;
							baseUrlBearerToken = options.baseUrl5BearerToken;
						}
						console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: totalRowsAPIBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: totalRowsAPIBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${baseUrlBearerToken}`,
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: totalRowsAPIBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
								},
							};

							if (totalRowsAPIMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								totalRows = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}
				} else {
					// if only custom url is provided and its a GET call
					if (updatedProps.customUrlValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a GET call
					else if (!updatedProps.customUrlValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
						// concatenating base url and its remaining part
						let url, baseUrlBearerToken;
						if (updatedProps.baseUrlValue === "baseUrl1") {
							url = options.baseUrl1 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl1BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl2") {
							url = options.baseUrl2 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl2BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl3") {
							url = options.baseUrl3 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl3BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl4") {
							url = options.baseUrl4 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl4BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl5") {
							url = options.baseUrl5 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl5BearerToken;
						}
						console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${baseUrlBearerToken}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								headers: {
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only custom url is provided and its a POST call
					else if (updatedProps.customUrlValue && updatedProps.apiBodyValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${updatedProps.customUrlValue}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}

					// if only base url is provided and its a POST call
					else if (!updatedProps.customUrlValue && updatedProps.apiBodyValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
						// concatenating base url and its remaining part
						let url, baseUrlBearerToken;
						if (updatedProps.baseUrlValue === "baseUrl1") {
							url = options.baseUrl1 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl1BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl2") {
							url = options.baseUrl2 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl2BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl3") {
							url = options.baseUrl3 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl3BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl4") {
							url = options.baseUrl4 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl4BearerToken;
						} else if (updatedProps.baseUrlValue === "baseUrl5") {
							url = options.baseUrl5 + updatedProps.apiPathValue;
							baseUrlBearerToken = options.baseUrl5BearerToken;
						}
						console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

						// if nothing is provided, for authentication
						if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if bearer token is provided but nothing else, for authentication
						else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: `Bearer ${baseUrlBearerToken}`,
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}

						// if username & password are provided but nothing else, for authentication
						else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
							// add extra headers, if needed
							let config = {
								method: "POST",
								body: updatedProps.apiBodyValue,
								headers: {
									"Content-Type": "application/json",
									Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
								},
							};

							if (updatedProps.apiMoreHeadersValue) {
								// ['a:b', "c:d"]
								// extracting headers from More_Headers trait
								updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
									let key, val;
									[key, val] = header.split(":");
									console.log("[key, val]:\n", key, val);

									config.headers[key] = val;
								});
							}

							console.log("config:", config);

							try {
								// GET call to get the data
								let response = await fetch(`${url}`, config);
								let responseData = await response.json();
								apiData = responseData;
							} catch (e) {
								// statements
								console.log(e);
							}
						}
					}
				}

				console.log("++ totalRows, apiData:", totalRows, apiData);

				try {
					if (typeof totalRows === "object" && !Array.isArray(totalRows) && !apiData) {
						totalRowsVal = findKeyValue(totalRows, totalRowsAPIKeynameValue);
					} else if (!totalRows && apiData) {
						// take the length of the response, if the plotting api is provided and not the total-rows api
						totalRowsVal = apiData.length;
					}
				} catch (e) {
					// statements
					console.log(e);
				}

				console.log("++ totalRowsVal:", totalRowsVal);

				// updating pagination
				if (paginationRequired) {
					// let customTablePagination = customTableTfoot.find(".custom-table-tfoot-row-for-pagination")[0].find("td")[0].find(".custom-table-pagination")[0];
					const customTableTfootRow = customTableTfoot.find(".custom-table-tfoot-row-for-pagination")[0];
				    console.log("customTableTfootRow:", customTableTfootRow);

				    const tfootTd = customTableTfootRow ? customTableTfootRow.find("td")[0] : null;
				    console.log("tfootTd:", tfootTd);

				    const customTablePagination = tfootTd ? tfootTd.find(".custom-table-pagination")[0] : null;
				    console.log("customTablePagination:", customTablePagination);

					let customTablePaginationContent;

					customTablePaginationContent = `
						<li class="uk-pagination-previous" style="list-style: none;"><a href="">Previous <</a></li>
						<ul class="uk-pagination uk-pagination-left" data-uk-pagination="{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}}"></ul>
						<li class="uk-pagination-next" style="list-style: none;"><a href="">> Next</a></li>
					`;

					if (customTablePagination) {
						customTablePagination.set("content", customTablePaginationContent);

						console.log("++pagination updated++");
					}
				}

				// updating total amount in the footer as - x results
				try {
					if (totalRowsVal && showDBSizeInTableFooter) {
						if (customTableTfoot.find(".showDBSizeInTableFooter")[0]) {
							console.log("++ replacewith");
							// customTableTfoot.find('.showDBSizeInTableFooter')[0].replaceWith(`<p class="showDBSizeInTableFooter">${totalRowsVal} results</p>`);
							// customTableTfoot.find('.custom-table-tfoot-row')[0].replaceWith({
							// 	tagName: "tr",
							// 	type: "custom-table-tfoot-row",
							// 	attributes: {class: "custom-table-tfoot-row"},

							// 	"style-default": {
							// 		width: "100%",
							// 		'background-color': options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : '',
							// 		'background-image': options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : 'linear-gradient(180deg, #714BEE, #005C8F)',
							// 		'border-top': options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : '',
							// 		'border-right': options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : '',
							// 		'border-bottom': options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : '',
							// 		'border-left': options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : '',
							// 	},

							// 	style: {
							// 		width: "100%",
							// 		'background-color': options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : '',
							// 		'background-image': options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : 'linear-gradient(180deg, #714BEE, #005C8F)',
							// 		'border-top': options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : '',
							// 		'border-right': options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : '',
							// 		'border-bottom': options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : '',
							// 		'border-left': options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : '',
							// 	},

							// 	components: [
							// 		{
							// 			tagName: "td",
							// 			editable: true,
							// 			attributes: {colspan: `${numColsInputValue}`},

							// 			"style-default": {
							// 				'font-size': options.table_constants ? options.table_constants.tableTfootFontSize : '',
							// 				'font-color': options.table_constants ? options.table_constants.tableTfootFontColor : '',
							// 				'font-family': options.table_constants ? options.table_constants.tableTfootFontFamily : '',
							// 			},

							// 			style: {
							// 				'font-size': options.table_constants ? options.table_constants.tableTfootFontSize : '',
							// 				'font-color': options.table_constants ? options.table_constants.tableTfootFontColor : '',
							// 				'font-family': options.table_constants ? options.table_constants.tableTfootFontFamily : '',
							// 			},

							// 			components: [
							// 				{
							// 		            tagName: "p",
							// 		            type: "text",
							// 		            editable: true,
							// 		            attributes: {class: "showDBSizeInTableFooter"},

							// 		            "style-default": {
							// 						padding: "8px",
							// 						'border-left': '1px solid black',
							// 						'border-right': '1px solid black',
							// 					},

							// 					style: {
							// 						padding: "8px",
							// 						'border-left': '1px solid black',
							// 						'border-right': '1px solid black',
							// 					},

							// 		            components: {
							// 		                type: "textnode",
							// 		                content: `${totalRowsVal} results`,
							// 		            },
							// 		        },
							// 			],
							// 		},
							// 	],
							// });

							customTableTfoot
								.find(".custom-table-tfoot-row")[0]
								.find("td")[0]
								.find(".showDBSizeInTableFooter")[0]
								.replaceWith({
									tagName: "p",
									type: "text",
									editable: true,
									attributes: { class: "showDBSizeInTableFooter" },

									"style-default": {
										// padding: "8px",
										"border-left": "1px solid black",
										"border-right": "1px solid black",
										"background-color": "inherit",
										background: "inherit",
										"text-align": "inherit",
											
										"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
										"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
										"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
										"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
									},

									style: {
										// padding: "8px",
										"border-left": "1px solid black",
										"border-right": "1px solid black",
										"background-color": "inherit",
										background: "inherit",
										"text-align": "inherit",
										
										"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
										"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
										"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
										"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
									},

									components: {
										type: "textnode",
										content: `${totalRowsVal} results`,
									},
								});
						} else {
							console.log("++ append");
							// customTableTfoot.append(`<tr><td colspan="${numColsInputValue}"><p class="showDBSizeInTableFooter">${totalRowsVal} results</p></td></tr>`);
							customTableTfoot.append({
								tagName: "tr",
								type: "custom-table-tfoot-row",
								attributes: { class: "custom-table-tfoot-row" },

								"style-default": {
									width: "100%",
									"background-color": componentModel.get("tableTfootRowEnableBackgroundColor") ? componentModel.get("tableTfootRowBackgroundColor") : "lightgray",
									"background-image": componentModel.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${componentModel.get("tableTfootRowLinearGradient1")}, ${componentModel.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
									"border-top": componentModel.get("tableTfootRowBorderTopWidth") || componentModel.get("tableTfootRowBorderTopType") || componentModel.get("tableTfootRowBorderTopColor") ? `${componentModel.get("tableTfootRowBorderTopWidth")}px ${componentModel.get("tableTfootRowBorderTopType")} ${componentModel.get("tableTfootRowBorderTopColor")}` : "",
									"border-right": componentModel.get("tableTfootRowBorderRightWidth") || componentModel.get("tableTfootRowBorderRightType") || componentModel.get("tableTfootRowBorderRightColor") ? `${componentModel.get("tableTfootRowBorderRightWidth")}px ${componentModel.get("tableTfootRowBorderRightType")} ${componentModel.get("tableTfootRowBorderRightColor")}` : "",
									"border-bottom": componentModel.get("tableTfootRowBorderBottomWidth") || componentModel.get("tableTfootRowBorderBottomType") || componentModel.get("tableTfootRowBorderBottomColor") ? `${componentModel.get("tableTfootRowBorderBottomWidth")}px ${componentModel.get("tableTfootRowBorderBottomType")} ${componentModel.get("tableTfootRowBorderBottomColor")}` : "",
									"border-left": componentModel.get("tableTfootRowBorderLeftWidth") || componentModel.get("tableTfootRowBorderLeftType") || componentModel.get("tableTfootRowBorderLeftColor") ? `${componentModel.get("tableTfootRowBorderLeftWidth")}px ${componentModel.get("tableTfootRowBorderLeftType")} ${componentModel.get("tableTfootRowBorderLeftColor")}` : "",
								},

								style: {
									width: "100%",
									"background-color": componentModel.get("tableTfootRowEnableBackgroundColor") ? componentModel.get("tableTfootRowBackgroundColor") : "lightgray",
									"background-image": componentModel.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${componentModel.get("tableTfootRowLinearGradient1")}, ${componentModel.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
									"border-top": componentModel.get("tableTfootRowBorderTopWidth") || componentModel.get("tableTfootRowBorderTopType") || componentModel.get("tableTfootRowBorderTopColor") ? `${componentModel.get("tableTfootRowBorderTopWidth")}px ${componentModel.get("tableTfootRowBorderTopType")} ${componentModel.get("tableTfootRowBorderTopColor")}` : "",
									"border-right": componentModel.get("tableTfootRowBorderRightWidth") || componentModel.get("tableTfootRowBorderRightType") || componentModel.get("tableTfootRowBorderRightColor") ? `${componentModel.get("tableTfootRowBorderRightWidth")}px ${componentModel.get("tableTfootRowBorderRightType")} ${componentModel.get("tableTfootRowBorderRightColor")}` : "",
									"border-bottom": componentModel.get("tableTfootRowBorderBottomWidth") || componentModel.get("tableTfootRowBorderBottomType") || componentModel.get("tableTfootRowBorderBottomColor") ? `${componentModel.get("tableTfootRowBorderBottomWidth")}px ${componentModel.get("tableTfootRowBorderBottomType")} ${componentModel.get("tableTfootRowBorderBottomColor")}` : "",
									"border-left": componentModel.get("tableTfootRowBorderLeftWidth") || componentModel.get("tableTfootRowBorderLeftType") || componentModel.get("tableTfootRowBorderLeftColor") ? `${componentModel.get("tableTfootRowBorderLeftWidth")}px ${componentModel.get("tableTfootRowBorderLeftType")} ${componentModel.get("tableTfootRowBorderLeftColor")}` : "",
								},

								components: [
									{
										tagName: "td",
										editable: true,
										attributes: { colspan: `${numColsInputValue}` },

										"style-default": {
											"font-size": componentModel.get("tableTfootFontSize") ? `${componentModel.get("tableTfootFontSize")}vw` : "",
											color: componentModel.get("tableTfootFontColor") ? componentModel.get("tableTfootFontColor") : "",
											"font-family": componentModel.get("tableTfootFontFamily") ? componentModel.get("tableTfootFontFamily") : "",
											
											"padding-top": componentModel.get("tableTfootCellPaddingTop") ? `${componentModel.get("tableTfootCellPaddingTop")}vh` : 0,
											"padding-right": componentModel.get("tableTfootCellPaddingRight") ? `${componentModel.get("tableTfootCellPaddingRight")}vw` : 0,
											"padding-bottom": componentModel.get("tableTfootCellPaddingBottom") ? `${componentModel.get("tableTfootCellPaddingBottom")}vh` : 0,
											"padding-left": componentModel.get("tableTfootCellPaddingLeft") ? `${componentModel.get("tableTfootCellPaddingLeft")}vw` : 0,
										},

										style: {
											"font-size": componentModel.get("tableTfootFontSize") ? `${componentModel.get("tableTfootFontSize")}vw` : "",
											color: componentModel.get("tableTfootFontColor") ? componentModel.get("tableTfootFontColor") : "",
											"font-family": componentModel.get("tableTfootFontFamily") ? componentModel.get("tableTfootFontFamily") : "",
											
											"padding-top": componentModel.get("tableTfootCellPaddingTop") ? `${componentModel.get("tableTfootCellPaddingTop")}vh` : 0,
											"padding-right": componentModel.get("tableTfootCellPaddingRight") ? `${componentModel.get("tableTfootCellPaddingRight")}vw` : 0,
											"padding-bottom": componentModel.get("tableTfootCellPaddingBottom") ? `${componentModel.get("tableTfootCellPaddingBottom")}vh` : 0,
											"padding-left": componentModel.get("tableTfootCellPaddingLeft") ? `${componentModel.get("tableTfootCellPaddingLeft")}vw` : 0,
										},

										components: [
											{
												tagName: "p",
												type: "text",
												editable: true,
												attributes: { class: "showDBSizeInTableFooter" },

												"style-default": {
													// padding: "8px",
													"border-left": "1px solid black",
													"border-right": "1px solid black",
											
													"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
													"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
													"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
													"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
												},

												style: {
													// padding: "8px",
													"border-left": "1px solid black",
													"border-right": "1px solid black",
											
													"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
													"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
													"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
													"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
												},

												components: {
													type: "textnode",
													content: `${totalRowsVal} results`,
												},
											},
										],
									},
								],
							});
						}
					} else {
						console.log("++ customTableTfoot ^", customTableTfoot, componentModel);
						if (customTableTfoot.find(".custom-table-tfoot-row")[0]) {
							customTableTfoot.find(".custom-table-tfoot-row")[0].remove();
						}
					}
				} catch (e) {
					// statements
					console.log("custom-table-tfoot-row error", e);
				}

				// to rerender the view for the updates to show, like pagination changes, result changes, etc
				componentModel.view.render();
			}
		});

		// TO MAKE THE PAGINATION RESPONSIVE WHEN SCREENS ARE MOBILE VIEW PORTS
		editor.on('change:device', (dev) => {
		 	const currentDevice = editor.getDevice(); // Get the current device type
    		console.log('customTableContainer current device:', currentDevice, dev);

    		// if mobile viewport is opened, update pagination with lesser pages
    		if (currentDevice === "Mobile" ||
    			currentDevice === "Android" ||
    			currentDevice === "iPhoneSE" ||
    			currentDevice === "iPhoneXR" ||
    			currentDevice === "iPhone13Mini" ||
    			currentDevice === "iPhone14Plus" ||
    			currentDevice === "iPhone14ProMax" ||
    			currentDevice === "Pixel7" ||
    			currentDevice === "SamsungGalaxyS8" ||
    			currentDevice === "SamsungGalaxyS20Ultra" ||
    			currentDevice === "GalaxyFold" ||
    			currentDevice === "SamsungGalaxyA5171") {
    			let allCustomTableContainers = editor.getWrapper().find('.custom-table-container');

    			if (allCustomTableContainers.length > 0) {
	    			// adjust responsiveness of pagination of all tables in this page
	    			allCustomTableContainers.forEach(async (componentModel) => {
	    				// making table columns responsive -
	    				let allHeaders = componentModel.find('.custom-table')[0].find('.custom-table-thead')[0].find('.custom-table-thead-row')[0].find('th');
	    				// if there are more than 3 columns then make the table scrollable in mobile view -
	    				if (allHeaders.length > 3) {
	    					allHeaders.forEach(th => {
	    						// console.log(dev, '-th-', th);

	    						// if headers are there and someone already hasn't given them min-width property from styles manager, then apply one
	    						if (th && !('min-width' in th.getStyle())) {
	    							let existingStyles = th.getStyle();
	    							existingStyles["min-width"] = '100px';

	    							th.setStyle(existingStyles);
	    						}
	    					});
	    				}

	    				let paginationRequired = componentModel.get("paginationRequired");
						let TotalRows = componentModel.get("TotalRows");
						let showDBSizeInTableFooter = componentModel.get("showDBSizeInTableFooter");
						let useTablePlottingApiForPagination = componentModel.get("useTablePlottingApiForPagination");
						let numRowsPerPageValue = componentModel.get("numRowsPerPageValue");
						let numColsInputValue = componentModel.get("numColsInputValue");

						let totalRowsAPIValue = componentModel.get("totalRowsAPIValue");
						let totalRowsAPIBaseUrlValue = componentModel.get("totalRowsAPIBaseUrlValue");
						let totalRowsAPIPathValue = componentModel.get("totalRowsAPIPathValue");
						let totalRowsAPIKeynameValue = componentModel.get("totalRowsAPIKeynameValue");
						let totalRowsAPIMethodValue = componentModel.get("totalRowsAPIMethodValue");
						let totalRowsAPIUsernameValue = componentModel.get("totalRowsAPIUsernameValue");
						let totalRowsAPIPasswordValue = componentModel.get("totalRowsAPIPasswordValue");
						let totalRowsAPIBearerTokenValue = componentModel.get("totalRowsAPIBearerTokenValue");
						let totalRowsAPIMoreHeadersValue = componentModel.get("totalRowsAPIMoreHeadersValue");
						let totalRowsAPIBodyValue = componentModel.get("totalRowsAPIBodyValue");
						let totalRowsAPINestedArrOfObjKeynameValue = componentModel.get("totalRowsAPINestedArrOfObjKeynameValue");

						let customUrlValue = componentModel.get("customUrlValue");
						let baseUrlValue = componentModel.get("baseUrlValue");
						let apiPathValue = componentModel.get("apiPathValue");
						let apiMethodValue = componentModel.get("apiMethodValue");
						let apiUsernameValue = componentModel.get("apiUsernameValue");
						let apiPasswordValue = componentModel.get("apiPasswordValue");
						let apiBearerTokenValue = componentModel.get("apiBearerTokenValue");
						let apiMoreHeadersValue = componentModel.get("apiMoreHeadersValue");
						let apiBodyValue = componentModel.get("apiBodyValue");
						let nestedArrOfObjKeynameValue = componentModel.get("nestedArrOfObjKeynameValue");
						// wrapping these values in updatedProps named object because the if cases below are extracting value from updatedProps - so this was a shortcut
						let updatedProps = {
							customUrlValue,
							baseUrlValue,
							apiPathValue,
							apiMethodValue,
							apiUsernameValue,
							apiPasswordValue,
							apiBearerTokenValue,
							apiMoreHeadersValue,
							apiBodyValue,
							nestedArrOfObjKeynameValue,
						};

						console.log("++ paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue", paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue);
						console.log("++ customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue", customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue);

						const customTableContainer = componentModel;
						const customTable = customTableContainer.find(".custom-table")[0];
						const customTableThead = customTable.find(".custom-table-thead")[0];
						const customTableTbody = customTable.find(".custom-table-tbody")[0];
						const customTableTfoot = customTable.find(".custom-table-tfoot")[0];

						// make an api call to get the TotalRows
						let totalRows;
						let totalRowsVal;
						let apiData;

						// use total-rows-api if the user has this api, else use the table-plotting api
						if (!useTablePlottingApiForPagination) {
							// if only totalRowsAPIValue is provided and its a GET call
							if (totalRowsAPIValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
								// if nothing is provided, for authentication
								if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										headers: {},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${totalRowsAPIValue}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if bearer token is provided but nothing else, for authentication
								else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${totalRowsAPIValue}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if username & password are provided but nothing else, for authentication
								else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										headers: {
											Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${totalRowsAPIValue}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}
							}

							// if only totalRowsAPIBaseUrlValue is provided and its a GET call
							else if (!totalRowsAPIValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
								// concatenating base url and its remaining part
								let url, baseUrlBearerToken;
								if (totalRowsAPIBaseUrlValue === "baseUrl1") {
									url = options.baseUrl1 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl1BearerToken;
								} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
									url = options.baseUrl2 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl2BearerToken;
								} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
									url = options.baseUrl3 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl3BearerToken;
								} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
									url = options.baseUrl4 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl4BearerToken;
								} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
									url = options.baseUrl5 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl5BearerToken;
								}
								console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

								// if nothing is provided, for authentication
								if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										headers: {},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if bearer token is provided but nothing else, for authentication
								else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${baseUrlBearerToken}`,
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if username & password are provided but nothing else, for authentication
								else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										headers: {
											Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}
							}

							// if only totalRowsAPIValue is provided and its a POST call
							else if (totalRowsAPIValue && totalRowsAPIBodyValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
								// if nothing is provided, for authentication
								if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: totalRowsAPIBodyValue,
										headers: {
											"Content-Type": "application/json",
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${totalRowsAPIValue}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if bearer token is provided but nothing else, for authentication
								else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: totalRowsAPIBodyValue,
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${totalRowsAPIValue}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if username & password are provided but nothing else, for authentication
								else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: totalRowsAPIBodyValue,
										headers: {
											"Content-Type": "application/json",
											Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${totalRowsAPIValue}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}
							}

							// if only totalRowsAPIBaseUrlValue is provided and its a POST call
							else if (!totalRowsAPIValue && totalRowsAPIBodyValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
								// concatenating base url and its remaining part
								let url, baseUrlBearerToken;
								if (totalRowsAPIBaseUrlValue === "baseUrl1") {
									url = options.baseUrl1 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl1BearerToken;
								} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
									url = options.baseUrl2 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl2BearerToken;
								} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
									url = options.baseUrl3 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl3BearerToken;
								} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
									url = options.baseUrl4 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl4BearerToken;
								} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
									url = options.baseUrl5 + totalRowsAPIPathValue;
									baseUrlBearerToken = options.baseUrl5BearerToken;
								}
								console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

								// if nothing is provided, for authentication
								if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: totalRowsAPIBodyValue,
										headers: {
											"Content-Type": "application/json",
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if bearer token is provided but nothing else, for authentication
								else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: totalRowsAPIBodyValue,
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${baseUrlBearerToken}`,
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if username & password are provided but nothing else, for authentication
								else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: totalRowsAPIBodyValue,
										headers: {
											"Content-Type": "application/json",
											Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
										},
									};

									if (totalRowsAPIMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										totalRows = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}
							}
						} else {
							// if only custom url is provided and its a GET call
							if (updatedProps.customUrlValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
								// if nothing is provided, for authentication
								if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										headers: {},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${updatedProps.customUrlValue}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if bearer token is provided but nothing else, for authentication
								else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${updatedProps.customUrlValue}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if username & password are provided but nothing else, for authentication
								else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										headers: {
											Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${updatedProps.customUrlValue}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}
							}

							// if only base url is provided and its a GET call
							else if (!updatedProps.customUrlValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
								// concatenating base url and its remaining part
								let url, baseUrlBearerToken;
								if (updatedProps.baseUrlValue === "baseUrl1") {
									url = options.baseUrl1 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl1BearerToken;
								} else if (updatedProps.baseUrlValue === "baseUrl2") {
									url = options.baseUrl2 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl2BearerToken;
								} else if (updatedProps.baseUrlValue === "baseUrl3") {
									url = options.baseUrl3 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl3BearerToken;
								} else if (updatedProps.baseUrlValue === "baseUrl4") {
									url = options.baseUrl4 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl4BearerToken;
								} else if (updatedProps.baseUrlValue === "baseUrl5") {
									url = options.baseUrl5 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl5BearerToken;
								}
								console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

								// if nothing is provided, for authentication
								if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										headers: {},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if bearer token is provided but nothing else, for authentication
								else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${baseUrlBearerToken}`,
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if username & password are provided but nothing else, for authentication
								else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										headers: {
											Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}
							}

							// if only custom url is provided and its a POST call
							else if (updatedProps.customUrlValue && updatedProps.apiBodyValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
								// if nothing is provided, for authentication
								if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: updatedProps.apiBodyValue,
										headers: {
											"Content-Type": "application/json",
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${updatedProps.customUrlValue}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if bearer token is provided but nothing else, for authentication
								else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: updatedProps.apiBodyValue,
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${updatedProps.customUrlValue}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if username & password are provided but nothing else, for authentication
								else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: updatedProps.apiBodyValue,
										headers: {
											"Content-Type": "application/json",
											Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${updatedProps.customUrlValue}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}
							}

							// if only base url is provided and its a POST call
							else if (!updatedProps.customUrlValue && updatedProps.apiBodyValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
								// concatenating base url and its remaining part
								let url, baseUrlBearerToken;
								if (updatedProps.baseUrlValue === "baseUrl1") {
									url = options.baseUrl1 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl1BearerToken;
								} else if (updatedProps.baseUrlValue === "baseUrl2") {
									url = options.baseUrl2 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl2BearerToken;
								} else if (updatedProps.baseUrlValue === "baseUrl3") {
									url = options.baseUrl3 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl3BearerToken;
								} else if (updatedProps.baseUrlValue === "baseUrl4") {
									url = options.baseUrl4 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl4BearerToken;
								} else if (updatedProps.baseUrlValue === "baseUrl5") {
									url = options.baseUrl5 + updatedProps.apiPathValue;
									baseUrlBearerToken = options.baseUrl5BearerToken;
								}
								console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

								// if nothing is provided, for authentication
								if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: updatedProps.apiBodyValue,
										headers: {
											"Content-Type": "application/json",
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if bearer token is provided but nothing else, for authentication
								else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: updatedProps.apiBodyValue,
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${baseUrlBearerToken}`,
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}

								// if username & password are provided but nothing else, for authentication
								else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
									// add extra headers, if needed
									let config = {
										method: "POST",
										body: updatedProps.apiBodyValue,
										headers: {
											"Content-Type": "application/json",
											Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
										},
									};

									if (updatedProps.apiMoreHeadersValue) {
										// ['a:b', "c:d"]
										// extracting headers from More_Headers trait
										updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
											let key, val;
											[key, val] = header.split(":");
											console.log("[key, val]:\n", key, val);

											config.headers[key] = val;
										});
									}

									console.log("config:", config);

									try {
										// GET call to get the data
										let response = await fetch(`${url}`, config);
										let responseData = await response.json();
										apiData = responseData;
									} catch (e) {
										// statements
										console.log(e);
									}
								}
							}
						}

						console.log("++ totalRows:", totalRows);

						try {
							if (typeof totalRows === "object" && !Array.isArray(totalRows) && !apiData) {
								totalRowsVal = findKeyValue(totalRows, totalRowsAPIKeynameValue);
							} else if (!totalRows && apiData) {
								// take the length of the response, if the plotting api is provided and not the total-rows api
								totalRowsVal = apiData.length;
							}
						} catch (e) {
							// statements
							console.log(e);
						}

						console.log("++ totalRowsVal:", totalRowsVal);

						// updating pagination
						if (paginationRequired) {
							let customTablePagination = customTableTfoot.find(".custom-table-tfoot-row-for-pagination")[0].find("td")[0].find(".custom-table-pagination")[0];
							let customTablePaginationContent;

							customTablePaginationContent = `
								<li class="uk-pagination-previous" style="list-style: none;"><a href="">Previous <</a></li>
								<ul class="uk-pagination uk-pagination-left" data-uk-pagination="{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}, displayedPages: 2, edges: 2}"></ul>
								<li class="uk-pagination-next" style="list-style: none;"><a href="">> Next</a></li>
							`;

							if (customTablePagination) {
								customTablePagination.set("content", customTablePaginationContent);
							}
							
							console.log("pagination updated");
						}

						// updating total amount in the footer as - x results
						try {
							if (totalRowsVal && showDBSizeInTableFooter) {
								if (customTableTfoot.find(".showDBSizeInTableFooter")[0]) {
									console.log("++ replacewith");
									// customTableTfoot.find('.showDBSizeInTableFooter')[0].replaceWith(`<p class="showDBSizeInTableFooter">${totalRowsVal} results</p>`);
									// customTableTfoot.find('.custom-table-tfoot-row')[0].replaceWith({
									// 	tagName: "tr",
									// 	type: "custom-table-tfoot-row",
									// 	attributes: {class: "custom-table-tfoot-row"},

									// 	"style-default": {
									// 		width: "100%",
									// 		'background-color': options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : '',
									// 		'background-image': options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : 'linear-gradient(180deg, #714BEE, #005C8F)',
									// 		'border-top': options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : '',
									// 		'border-right': options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : '',
									// 		'border-bottom': options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : '',
									// 		'border-left': options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : '',
									// 	},

									// 	style: {
									// 		width: "100%",
									// 		'background-color': options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : '',
									// 		'background-image': options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : 'linear-gradient(180deg, #714BEE, #005C8F)',
									// 		'border-top': options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : '',
									// 		'border-right': options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : '',
									// 		'border-bottom': options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : '',
									// 		'border-left': options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : '',
									// 	},

									// 	components: [
									// 		{
									// 			tagName: "td",
									// 			editable: true,
									// 			attributes: {colspan: `${numColsInputValue}`},

									// 			"style-default": {
									// 				'font-size': options.table_constants ? options.table_constants.tableTfootFontSize : '',
									// 				'font-color': options.table_constants ? options.table_constants.tableTfootFontColor : '',
									// 				'font-family': options.table_constants ? options.table_constants.tableTfootFontFamily : '',
									// 			},

									// 			style: {
									// 				'font-size': options.table_constants ? options.table_constants.tableTfootFontSize : '',
									// 				'font-color': options.table_constants ? options.table_constants.tableTfootFontColor : '',
									// 				'font-family': options.table_constants ? options.table_constants.tableTfootFontFamily : '',
									// 			},

									// 			components: [
									// 				{
									// 		            tagName: "p",
									// 		            type: "text",
									// 		            editable: true,
									// 		            attributes: {class: "showDBSizeInTableFooter"},

									// 		            "style-default": {
									// 						padding: "8px",
									// 						'border-left': '1px solid black',
									// 						'border-right': '1px solid black',
									// 					},

									// 					style: {
									// 						padding: "8px",
									// 						'border-left': '1px solid black',
									// 						'border-right': '1px solid black',
									// 					},

									// 		            components: {
									// 		                type: "textnode",
									// 		                content: `${totalRowsVal} results`,
									// 		            },
									// 		        },
									// 			],
									// 		},
									// 	],
									// });

									customTableTfoot
										.find(".custom-table-tfoot-row")[0]
										.find("td")[0]
										.find(".showDBSizeInTableFooter")[0]
										.replaceWith({
											tagName: "p",
											type: "text",
											editable: true,
											attributes: { class: "showDBSizeInTableFooter" },

											"style-default": {
												// padding: "8px",
												"border-left": "1px solid black",
												"border-right": "1px solid black",
												"background-color": "inherit",
												background: "inherit",
												"text-align": "inherit",
													
												"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
												"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
												"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
												"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
											},

											style: {
												// padding: "8px",
												"border-left": "1px solid black",
												"border-right": "1px solid black",
												"background-color": "inherit",
												background: "inherit",
												"text-align": "inherit",
													
												"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
												"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
												"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
												"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
											},

											components: {
												type: "textnode",
												content: `${totalRowsVal} results`,
											},
										});
								} else {
									console.log("++ append");
									// customTableTfoot.append(`<tr><td colspan="${numColsInputValue}"><p class="showDBSizeInTableFooter">${totalRowsVal} results</p></td></tr>`);
									customTableTfoot.append({
										tagName: "tr",
										type: "custom-table-tfoot-row",
										attributes: { class: "custom-table-tfoot-row" },

										"style-default": {
											width: "100%",
											"background-color": componentModel.get("tableTfootRowEnableBackgroundColor") ? componentModel.get("tableTfootRowBackgroundColor") : "lightgray",
											"background-image": componentModel.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${componentModel.get("tableTfootRowLinearGradient1")}, ${componentModel.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
											"border-top": componentModel.get("tableTfootRowBorderTopWidth") || componentModel.get("tableTfootRowBorderTopType") || componentModel.get("tableTfootRowBorderTopColor") ? `${componentModel.get("tableTfootRowBorderTopWidth")}px ${componentModel.get("tableTfootRowBorderTopType")} ${componentModel.get("tableTfootRowBorderTopColor")}` : "",
											"border-right": componentModel.get("tableTfootRowBorderRightWidth") || componentModel.get("tableTfootRowBorderRightType") || componentModel.get("tableTfootRowBorderRightColor") ? `${componentModel.get("tableTfootRowBorderRightWidth")}px ${componentModel.get("tableTfootRowBorderRightType")} ${componentModel.get("tableTfootRowBorderRightColor")}` : "",
											"border-bottom": componentModel.get("tableTfootRowBorderBottomWidth") || componentModel.get("tableTfootRowBorderBottomType") || componentModel.get("tableTfootRowBorderBottomColor") ? `${componentModel.get("tableTfootRowBorderBottomWidth")}px ${componentModel.get("tableTfootRowBorderBottomType")} ${componentModel.get("tableTfootRowBorderBottomColor")}` : "",
											"border-left": componentModel.get("tableTfootRowBorderLeftWidth") || componentModel.get("tableTfootRowBorderLeftType") || componentModel.get("tableTfootRowBorderLeftColor") ? `${componentModel.get("tableTfootRowBorderLeftWidth")}px ${componentModel.get("tableTfootRowBorderLeftType")} ${componentModel.get("tableTfootRowBorderLeftColor")}` : "",
										},

										style: {
											width: "100%",
											"background-color": componentModel.get("tableTfootRowEnableBackgroundColor") ? componentModel.get("tableTfootRowBackgroundColor") : "lightgray",
											"background-image": componentModel.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${componentModel.get("tableTfootRowLinearGradient1")}, ${componentModel.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
											"border-top": componentModel.get("tableTfootRowBorderTopWidth") || componentModel.get("tableTfootRowBorderTopType") || componentModel.get("tableTfootRowBorderTopColor") ? `${componentModel.get("tableTfootRowBorderTopWidth")}px ${componentModel.get("tableTfootRowBorderTopType")} ${componentModel.get("tableTfootRowBorderTopColor")}` : "",
											"border-right": componentModel.get("tableTfootRowBorderRightWidth") || componentModel.get("tableTfootRowBorderRightType") || componentModel.get("tableTfootRowBorderRightColor") ? `${componentModel.get("tableTfootRowBorderRightWidth")}px ${componentModel.get("tableTfootRowBorderRightType")} ${componentModel.get("tableTfootRowBorderRightColor")}` : "",
											"border-bottom": componentModel.get("tableTfootRowBorderBottomWidth") || componentModel.get("tableTfootRowBorderBottomType") || componentModel.get("tableTfootRowBorderBottomColor") ? `${componentModel.get("tableTfootRowBorderBottomWidth")}px ${componentModel.get("tableTfootRowBorderBottomType")} ${componentModel.get("tableTfootRowBorderBottomColor")}` : "",
											"border-left": componentModel.get("tableTfootRowBorderLeftWidth") || componentModel.get("tableTfootRowBorderLeftType") || componentModel.get("tableTfootRowBorderLeftColor") ? `${componentModel.get("tableTfootRowBorderLeftWidth")}px ${componentModel.get("tableTfootRowBorderLeftType")} ${componentModel.get("tableTfootRowBorderLeftColor")}` : "",
										},

										components: [
											{
												tagName: "td",
												editable: true,
												attributes: { colspan: `${numColsInputValue}` },

												"style-default": {
													"font-size": componentModel.get("tableTfootFontSize") ? `${componentModel.get("tableTfootFontSize")}vw` : "",
													color: componentModel.get("tableTfootFontColor") ? componentModel.get("tableTfootFontColor") : "",
													"font-family": componentModel.get("tableTfootFontFamily") ? componentModel.get("tableTfootFontFamily") : "",
													
													"padding-top": componentModel.get("tableTfootCellPaddingTop") ? `${componentModel.get("tableTfootCellPaddingTop")}vh` : 0,
													"padding-right": componentModel.get("tableTfootCellPaddingRight") ? `${componentModel.get("tableTfootCellPaddingRight")}vw` : 0,
													"padding-bottom": componentModel.get("tableTfootCellPaddingBottom") ? `${componentModel.get("tableTfootCellPaddingBottom")}vh` : 0,
													"padding-left": componentModel.get("tableTfootCellPaddingLeft") ? `${componentModel.get("tableTfootCellPaddingLeft")}vw` : 0,
												},

												style: {
													"font-size": componentModel.get("tableTfootFontSize") ? `${componentModel.get("tableTfootFontSize")}vw` : "",
													color: componentModel.get("tableTfootFontColor") ? componentModel.get("tableTfootFontColor") : "",
													"font-family": componentModel.get("tableTfootFontFamily") ? componentModel.get("tableTfootFontFamily") : "",
													
													"padding-top": componentModel.get("tableTfootCellPaddingTop") ? `${componentModel.get("tableTfootCellPaddingTop")}vh` : 0,
													"padding-right": componentModel.get("tableTfootCellPaddingRight") ? `${componentModel.get("tableTfootCellPaddingRight")}vw` : 0,
													"padding-bottom": componentModel.get("tableTfootCellPaddingBottom") ? `${componentModel.get("tableTfootCellPaddingBottom")}vh` : 0,
													"padding-left": componentModel.get("tableTfootCellPaddingLeft") ? `${componentModel.get("tableTfootCellPaddingLeft")}vw` : 0,
												},

												components: [
													{
														tagName: "p",
														type: "text",
														editable: true,
														attributes: { class: "showDBSizeInTableFooter" },

														"style-default": {
															// padding: "8px",
															"border-left": "1px solid black",
															"border-right": "1px solid black",
													
															"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
															"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
															"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
															"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
														},

														style: {
															// padding: "8px",
															"border-left": "1px solid black",
															"border-right": "1px solid black",
													
															"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
															"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
															"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
															"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
														},

														components: {
															type: "textnode",
															content: `${totalRowsVal} results`,
														},
													},
												],
											},
										],
									});
								}
							} else {
								console.log("++ customTableTfoot ^", customTableTfoot, componentModel);
								if (customTableTfoot.find(".custom-table-tfoot-row")[0]) {
									customTableTfoot.find(".custom-table-tfoot-row")[0].remove();
								}
							}
						} catch (e) {
							// statements
							console.log("custom-table-tfoot-row error", e);
						}

						// to rerender the view for the updates to show, like pagination changes, result changes, etc
						componentModel.view.render();
	    			});
    			}    			
    		}
    		// if laptop viewport is opened, change is back to normal
    		else {
    			let allCustomTableContainers = editor.getWrapper().find('.custom-table-container');

    			// adjust responsiveness of pagination of all tables in this page
    			allCustomTableContainers.forEach(async (componentModel) => {
    				let paginationRequired = componentModel.get("paginationRequired");
					let TotalRows = componentModel.get("TotalRows");
					let showDBSizeInTableFooter = componentModel.get("showDBSizeInTableFooter");
					let useTablePlottingApiForPagination = componentModel.get("useTablePlottingApiForPagination");
					let numRowsPerPageValue = componentModel.get("numRowsPerPageValue");
					let numColsInputValue = componentModel.get("numColsInputValue");

					let totalRowsAPIValue = componentModel.get("totalRowsAPIValue");
					let totalRowsAPIBaseUrlValue = componentModel.get("totalRowsAPIBaseUrlValue");
					let totalRowsAPIPathValue = componentModel.get("totalRowsAPIPathValue");
					let totalRowsAPIKeynameValue = componentModel.get("totalRowsAPIKeynameValue");
					let totalRowsAPIMethodValue = componentModel.get("totalRowsAPIMethodValue");
					let totalRowsAPIUsernameValue = componentModel.get("totalRowsAPIUsernameValue");
					let totalRowsAPIPasswordValue = componentModel.get("totalRowsAPIPasswordValue");
					let totalRowsAPIBearerTokenValue = componentModel.get("totalRowsAPIBearerTokenValue");
					let totalRowsAPIMoreHeadersValue = componentModel.get("totalRowsAPIMoreHeadersValue");
					let totalRowsAPIBodyValue = componentModel.get("totalRowsAPIBodyValue");
					let totalRowsAPINestedArrOfObjKeynameValue = componentModel.get("totalRowsAPINestedArrOfObjKeynameValue");

					let customUrlValue = componentModel.get("customUrlValue");
					let baseUrlValue = componentModel.get("baseUrlValue");
					let apiPathValue = componentModel.get("apiPathValue");
					let apiMethodValue = componentModel.get("apiMethodValue");
					let apiUsernameValue = componentModel.get("apiUsernameValue");
					let apiPasswordValue = componentModel.get("apiPasswordValue");
					let apiBearerTokenValue = componentModel.get("apiBearerTokenValue");
					let apiMoreHeadersValue = componentModel.get("apiMoreHeadersValue");
					let apiBodyValue = componentModel.get("apiBodyValue");
					let nestedArrOfObjKeynameValue = componentModel.get("nestedArrOfObjKeynameValue");
					// wrapping these values in updatedProps named object because the if cases below are extracting value from updatedProps - so this was a shortcut
					let updatedProps = {
						customUrlValue,
						baseUrlValue,
						apiPathValue,
						apiMethodValue,
						apiUsernameValue,
						apiPasswordValue,
						apiBearerTokenValue,
						apiMoreHeadersValue,
						apiBodyValue,
						nestedArrOfObjKeynameValue,
					};

					console.log("++ paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue", paginationRequired, numRowsPerPageValue, showDBSizeInTableFooter, useTablePlottingApiForPagination, TotalRows, totalRowsAPIValue, totalRowsAPIBaseUrlValue, totalRowsAPIPathValue, totalRowsAPIKeynameValue, totalRowsAPIMethodValue, totalRowsAPIUsernameValue, totalRowsAPIPasswordValue, totalRowsAPIBearerTokenValue, totalRowsAPIMoreHeadersValue, totalRowsAPIBodyValue, totalRowsAPINestedArrOfObjKeynameValue);
					console.log("++ customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue", customUrlValue, baseUrlValue, apiPathValue, apiMethodValue, apiUsernameValue, apiPasswordValue, apiBearerTokenValue, apiMoreHeadersValue, apiBodyValue, nestedArrOfObjKeynameValue);

					const customTableContainer = componentModel;
					const customTable = customTableContainer.find(".custom-table")[0];
					const customTableThead = customTable.find(".custom-table-thead")[0];
					const customTableTbody = customTable.find(".custom-table-tbody")[0];
					const customTableTfoot = customTable.find(".custom-table-tfoot")[0];

					// make an api call to get the TotalRows
					let totalRows;
					let totalRowsVal;
					let apiData;

					// use total-rows-api if the user has this api, else use the table-plotting api
					if (!useTablePlottingApiForPagination) {
						// if only totalRowsAPIValue is provided and its a GET call
						if (totalRowsAPIValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only totalRowsAPIBaseUrlValue is provided and its a GET call
						else if (!totalRowsAPIValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "GET") {
							// concatenating base url and its remaining part
							let url, baseUrlBearerToken;
							if (totalRowsAPIBaseUrlValue === "baseUrl1") {
								url = options.baseUrl1 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl1BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
								url = options.baseUrl2 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl2BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
								url = options.baseUrl3 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl3BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
								url = options.baseUrl4 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl4BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
								url = options.baseUrl5 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl5BearerToken;
							}
							console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${baseUrlBearerToken}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only totalRowsAPIValue is provided and its a POST call
						else if (totalRowsAPIValue && totalRowsAPIBodyValue && (!totalRowsAPIBaseUrlValue || totalRowsAPIBaseUrlValue === "null") && !totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${totalRowsAPIBearerTokenValue}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${totalRowsAPIValue}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only totalRowsAPIBaseUrlValue is provided and its a POST call
						else if (!totalRowsAPIValue && totalRowsAPIBodyValue && (totalRowsAPIBaseUrlValue || !totalRowsAPIBaseUrlValue === "null") && totalRowsAPIPathValue && totalRowsAPIMethodValue === "POST") {
							// concatenating base url and its remaining part
							let url, baseUrlBearerToken;
							if (totalRowsAPIBaseUrlValue === "baseUrl1") {
								url = options.baseUrl1 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl1BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl2") {
								url = options.baseUrl2 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl2BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl3") {
								url = options.baseUrl3 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl3BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl4") {
								url = options.baseUrl4 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl4BearerToken;
							} else if (totalRowsAPIBaseUrlValue === "baseUrl5") {
								url = options.baseUrl5 + totalRowsAPIPathValue;
								baseUrlBearerToken = options.baseUrl5BearerToken;
							}
							console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!totalRowsAPIUsernameValue && !totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${baseUrlBearerToken}`,
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (totalRowsAPIUsernameValue && totalRowsAPIPasswordValue && !totalRowsAPIBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: totalRowsAPIBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(totalRowsAPIUsernameValue + ":" + totalRowsAPIPasswordValue),
									},
								};

								if (totalRowsAPIMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									totalRowsAPIMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									totalRows = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}
					} else {
						// if only custom url is provided and its a GET call
						if (updatedProps.customUrlValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only base url is provided and its a GET call
						else if (!updatedProps.customUrlValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "GET") {
							// concatenating base url and its remaining part
							let url, baseUrlBearerToken;
							if (updatedProps.baseUrlValue === "baseUrl1") {
								url = options.baseUrl1 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl1BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl2") {
								url = options.baseUrl2 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl2BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl3") {
								url = options.baseUrl3 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl3BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl4") {
								url = options.baseUrl4 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl4BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl5") {
								url = options.baseUrl5 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl5BearerToken;
							}
							console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${baseUrlBearerToken}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									headers: {
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only custom url is provided and its a POST call
						else if (updatedProps.customUrlValue && updatedProps.apiBodyValue && (!updatedProps.baseUrlValue || updatedProps.baseUrlValue === "null") && !updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${updatedProps.apiBearerTokenValue}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${updatedProps.customUrlValue}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}

						// if only base url is provided and its a POST call
						else if (!updatedProps.customUrlValue && updatedProps.apiBodyValue && (updatedProps.baseUrlValue || !updatedProps.baseUrlValue === "null") && updatedProps.apiPathValue && updatedProps.apiMethodValue === "POST") {
							// concatenating base url and its remaining part
							let url, baseUrlBearerToken;
							if (updatedProps.baseUrlValue === "baseUrl1") {
								url = options.baseUrl1 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl1BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl2") {
								url = options.baseUrl2 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl2BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl3") {
								url = options.baseUrl3 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl3BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl4") {
								url = options.baseUrl4 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl4BearerToken;
							} else if (updatedProps.baseUrlValue === "baseUrl5") {
								url = options.baseUrl5 + updatedProps.apiPathValue;
								baseUrlBearerToken = options.baseUrl5BearerToken;
							}
							console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

							// if nothing is provided, for authentication
							if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if bearer token is provided but nothing else, for authentication
							else if (!updatedProps.apiUsernameValue && !updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: `Bearer ${baseUrlBearerToken}`,
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}

							// if username & password are provided but nothing else, for authentication
							else if (updatedProps.apiUsernameValue && updatedProps.apiPasswordValue && !updatedProps.apiBearerTokenValue && !baseUrlBearerToken) {
								// add extra headers, if needed
								let config = {
									method: "POST",
									body: updatedProps.apiBodyValue,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Basic " + btoa(updatedProps.apiUsernameValue + ":" + updatedProps.apiPasswordValue),
									},
								};

								if (updatedProps.apiMoreHeadersValue) {
									// ['a:b', "c:d"]
									// extracting headers from More_Headers trait
									updatedProps.apiMoreHeadersValue.split(/,\s*/).forEach((header) => {
										let key, val;
										[key, val] = header.split(":");
										console.log("[key, val]:\n", key, val);

										config.headers[key] = val;
									});
								}

								console.log("config:", config);

								try {
									// GET call to get the data
									let response = await fetch(`${url}`, config);
									let responseData = await response.json();
									apiData = responseData;
								} catch (e) {
									// statements
									console.log(e);
								}
							}
						}
					}

					console.log("++ totalRows:", totalRows);

					try {
						if (typeof totalRows === "object" && !Array.isArray(totalRows) && !apiData) {
							totalRowsVal = findKeyValue(totalRows, totalRowsAPIKeynameValue);
						} else if (!totalRows && apiData) {
							// take the length of the response, if the plotting api is provided and not the total-rows api
							totalRowsVal = apiData.length;
						}
					} catch (e) {
						// statements
						console.log(e);
					}

					console.log("++ totalRowsVal:", totalRowsVal);

					// updating pagination
					if (paginationRequired) {
						let customTablePagination = customTableTfoot.find(".custom-table-tfoot-row-for-pagination")[0].find("td")[0].find(".custom-table-pagination")[0];
						let customTablePaginationContent;

						customTablePaginationContent = `
							<li class="uk-pagination-previous" style="list-style: none;"><a href="">Previous <</a></li>
							<ul class="uk-pagination uk-pagination-left" data-uk-pagination="{items: ${totalRowsVal ? Number(totalRowsVal) : Number(TotalRows)}, itemsOnPage: ${Number(numRowsPerPageValue)}}"></ul>
							<li class="uk-pagination-next" style="list-style: none;"><a href="">> Next</a></li>
						`;

						if (customTablePagination) {
							customTablePagination.set("content", customTablePaginationContent);
						}
						
						console.log("pagination updated");
					}

					// updating total amount in the footer as - x results
					try {
						if (totalRowsVal && showDBSizeInTableFooter) {
							if (customTableTfoot.find(".showDBSizeInTableFooter")[0]) {
								console.log("++ replacewith");
								// customTableTfoot.find('.showDBSizeInTableFooter')[0].replaceWith(`<p class="showDBSizeInTableFooter">${totalRowsVal} results</p>`);
								// customTableTfoot.find('.custom-table-tfoot-row')[0].replaceWith({
								// 	tagName: "tr",
								// 	type: "custom-table-tfoot-row",
								// 	attributes: {class: "custom-table-tfoot-row"},

								// 	"style-default": {
								// 		width: "100%",
								// 		'background-color': options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : '',
								// 		'background-image': options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : 'linear-gradient(180deg, #714BEE, #005C8F)',
								// 		'border-top': options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : '',
								// 		'border-right': options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : '',
								// 		'border-bottom': options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : '',
								// 		'border-left': options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : '',
								// 	},

								// 	style: {
								// 		width: "100%",
								// 		'background-color': options.table_constants ? options.table_constants.tableTfootRowBackgroundColor : '',
								// 		'background-image': options.table_constants ? `linear-gradient(180deg, ${options.table_constants.tableTfootRowLinearGradient1}, ${options.table_constants.tableTfootRowLinearGradient2})` : 'linear-gradient(180deg, #714BEE, #005C8F)',
								// 		'border-top': options.table_constants ? `${options.table_constants.tableTfootRowBorderTopWidth} ${options.table_constants.tableTfootRowBorderTopType} ${options.table_constants.tableTfootRowBorderTopColor}` : '',
								// 		'border-right': options.table_constants ? `${options.table_constants.tableTfootRowBorderRightWidth} ${options.table_constants.tableTfootRowBorderRightType} ${options.table_constants.tableTfootRowBorderRightColor}` : '',
								// 		'border-bottom': options.table_constants ? `${options.table_constants.tableTfootRowBorderBottomWidth} ${options.table_constants.tableTfootRowBorderBottomType} ${options.table_constants.tableTfootRowBorderBottomColor}` : '',
								// 		'border-left': options.table_constants ? `${options.table_constants.tableTfootRowBorderLeftWidth} ${options.table_constants.tableTfootRowBorderLeftType} ${options.table_constants.tableTfootRowBorderLeftColor}` : '',
								// 	},

								// 	components: [
								// 		{
								// 			tagName: "td",
								// 			editable: true,
								// 			attributes: {colspan: `${numColsInputValue}`},

								// 			"style-default": {
								// 				'font-size': options.table_constants ? options.table_constants.tableTfootFontSize : '',
								// 				'font-color': options.table_constants ? options.table_constants.tableTfootFontColor : '',
								// 				'font-family': options.table_constants ? options.table_constants.tableTfootFontFamily : '',
								// 			},

								// 			style: {
								// 				'font-size': options.table_constants ? options.table_constants.tableTfootFontSize : '',
								// 				'font-color': options.table_constants ? options.table_constants.tableTfootFontColor : '',
								// 				'font-family': options.table_constants ? options.table_constants.tableTfootFontFamily : '',
								// 			},

								// 			components: [
								// 				{
								// 		            tagName: "p",
								// 		            type: "text",
								// 		            editable: true,
								// 		            attributes: {class: "showDBSizeInTableFooter"},

								// 		            "style-default": {
								// 						padding: "8px",
								// 						'border-left': '1px solid black',
								// 						'border-right': '1px solid black',
								// 					},

								// 					style: {
								// 						padding: "8px",
								// 						'border-left': '1px solid black',
								// 						'border-right': '1px solid black',
								// 					},

								// 		            components: {
								// 		                type: "textnode",
								// 		                content: `${totalRowsVal} results`,
								// 		            },
								// 		        },
								// 			],
								// 		},
								// 	],
								// });

								customTableTfoot
									.find(".custom-table-tfoot-row")[0]
									.find("td")[0]
									.find(".showDBSizeInTableFooter")[0]
									.replaceWith({
										tagName: "p",
										type: "text",
										editable: true,
										attributes: { class: "showDBSizeInTableFooter" },

										"style-default": {
											// padding: "8px",
											"border-left": "1px solid black",
											"border-right": "1px solid black",
											"background-color": "inherit",
											background: "inherit",
											"text-align": "inherit",
												
											"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
											"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
											"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
											"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
										},

										style: {
											// padding: "8px",
											"border-left": "1px solid black",
											"border-right": "1px solid black",
											"background-color": "inherit",
											background: "inherit",
											"text-align": "inherit",
												
											"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
											"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
											"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
											"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
										},

										components: {
											type: "textnode",
											content: `${totalRowsVal} results`,
										},
									});
							} else {
								console.log("++ append");
								// customTableTfoot.append(`<tr><td colspan="${numColsInputValue}"><p class="showDBSizeInTableFooter">${totalRowsVal} results</p></td></tr>`);
								customTableTfoot.append({
									tagName: "tr",
									type: "custom-table-tfoot-row",
									attributes: { class: "custom-table-tfoot-row" },

									"style-default": {
										width: "100%",
										"background-color": componentModel.get("tableTfootRowEnableBackgroundColor") ? componentModel.get("tableTfootRowBackgroundColor") : "lightgray",
										"background-image": componentModel.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${componentModel.get("tableTfootRowLinearGradient1")}, ${componentModel.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
										"border-top": componentModel.get("tableTfootRowBorderTopWidth") || componentModel.get("tableTfootRowBorderTopType") || componentModel.get("tableTfootRowBorderTopColor") ? `${componentModel.get("tableTfootRowBorderTopWidth")}px ${componentModel.get("tableTfootRowBorderTopType")} ${componentModel.get("tableTfootRowBorderTopColor")}` : "",
										"border-right": componentModel.get("tableTfootRowBorderRightWidth") || componentModel.get("tableTfootRowBorderRightType") || componentModel.get("tableTfootRowBorderRightColor") ? `${componentModel.get("tableTfootRowBorderRightWidth")}px ${componentModel.get("tableTfootRowBorderRightType")} ${componentModel.get("tableTfootRowBorderRightColor")}` : "",
										"border-bottom": componentModel.get("tableTfootRowBorderBottomWidth") || componentModel.get("tableTfootRowBorderBottomType") || componentModel.get("tableTfootRowBorderBottomColor") ? `${componentModel.get("tableTfootRowBorderBottomWidth")}px ${componentModel.get("tableTfootRowBorderBottomType")} ${componentModel.get("tableTfootRowBorderBottomColor")}` : "",
										"border-left": componentModel.get("tableTfootRowBorderLeftWidth") || componentModel.get("tableTfootRowBorderLeftType") || componentModel.get("tableTfootRowBorderLeftColor") ? `${componentModel.get("tableTfootRowBorderLeftWidth")}px ${componentModel.get("tableTfootRowBorderLeftType")} ${componentModel.get("tableTfootRowBorderLeftColor")}` : "",
									},

									style: {
										width: "100%",
										"background-color": componentModel.get("tableTfootRowEnableBackgroundColor") ? componentModel.get("tableTfootRowBackgroundColor") : "lightgray",
										"background-image": componentModel.get("tableTfootRowEnableLinearGradient1") ? `linear-gradient(180deg, ${componentModel.get("tableTfootRowLinearGradient1")}, ${componentModel.get("tableTfootRowLinearGradient2")})` : "linear-gradient(180deg, lightgray, lightgray)",
										"border-top": componentModel.get("tableTfootRowBorderTopWidth") || componentModel.get("tableTfootRowBorderTopType") || componentModel.get("tableTfootRowBorderTopColor") ? `${componentModel.get("tableTfootRowBorderTopWidth")}px ${componentModel.get("tableTfootRowBorderTopType")} ${componentModel.get("tableTfootRowBorderTopColor")}` : "",
										"border-right": componentModel.get("tableTfootRowBorderRightWidth") || componentModel.get("tableTfootRowBorderRightType") || componentModel.get("tableTfootRowBorderRightColor") ? `${componentModel.get("tableTfootRowBorderRightWidth")}px ${componentModel.get("tableTfootRowBorderRightType")} ${componentModel.get("tableTfootRowBorderRightColor")}` : "",
										"border-bottom": componentModel.get("tableTfootRowBorderBottomWidth") || componentModel.get("tableTfootRowBorderBottomType") || componentModel.get("tableTfootRowBorderBottomColor") ? `${componentModel.get("tableTfootRowBorderBottomWidth")}px ${componentModel.get("tableTfootRowBorderBottomType")} ${componentModel.get("tableTfootRowBorderBottomColor")}` : "",
										"border-left": componentModel.get("tableTfootRowBorderLeftWidth") || componentModel.get("tableTfootRowBorderLeftType") || componentModel.get("tableTfootRowBorderLeftColor") ? `${componentModel.get("tableTfootRowBorderLeftWidth")}px ${componentModel.get("tableTfootRowBorderLeftType")} ${componentModel.get("tableTfootRowBorderLeftColor")}` : "",
									},

									components: [
										{
											tagName: "td",
											editable: true,
											attributes: { colspan: `${numColsInputValue}` },

											"style-default": {
												"font-size": componentModel.get("tableTfootFontSize") ? `${componentModel.get("tableTfootFontSize")}vw` : "",
												color: componentModel.get("tableTfootFontColor") ? componentModel.get("tableTfootFontColor") : "",
												"font-family": componentModel.get("tableTfootFontFamily") ? componentModel.get("tableTfootFontFamily") : "",
												
												"padding-top": componentModel.get("tableTfootCellPaddingTop") ? `${componentModel.get("tableTfootCellPaddingTop")}vh` : 0,
												"padding-right": componentModel.get("tableTfootCellPaddingRight") ? `${componentModel.get("tableTfootCellPaddingRight")}vw` : 0,
												"padding-bottom": componentModel.get("tableTfootCellPaddingBottom") ? `${componentModel.get("tableTfootCellPaddingBottom")}vh` : 0,
												"padding-left": componentModel.get("tableTfootCellPaddingLeft") ? `${componentModel.get("tableTfootCellPaddingLeft")}vw` : 0,
											},

											style: {
												"font-size": componentModel.get("tableTfootFontSize") ? `${componentModel.get("tableTfootFontSize")}vw` : "",
												color: componentModel.get("tableTfootFontColor") ? componentModel.get("tableTfootFontColor") : "",
												"font-family": componentModel.get("tableTfootFontFamily") ? componentModel.get("tableTfootFontFamily") : "",
												
												"padding-top": componentModel.get("tableTfootCellPaddingTop") ? `${componentModel.get("tableTfootCellPaddingTop")}vh` : 0,
												"padding-right": componentModel.get("tableTfootCellPaddingRight") ? `${componentModel.get("tableTfootCellPaddingRight")}vw` : 0,
												"padding-bottom": componentModel.get("tableTfootCellPaddingBottom") ? `${componentModel.get("tableTfootCellPaddingBottom")}vh` : 0,
												"padding-left": componentModel.get("tableTfootCellPaddingLeft") ? `${componentModel.get("tableTfootCellPaddingLeft")}vw` : 0,
											},

											components: [
												{
													tagName: "p",
													type: "text",
													editable: true,
													attributes: { class: "showDBSizeInTableFooter" },

													"style-default": {
														// padding: "8px",
														"border-left": "1px solid black",
														"border-right": "1px solid black",
												
														"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
														"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
														"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
														"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
													},

													style: {
														// padding: "8px",
														"border-left": "1px solid black",
														"border-right": "1px solid black",
												
														"margin-top": componentModel.get("tableTfootTotalRowsMarginTop") ? `${componentModel.get("tableTfootTotalRowsMarginTop")}vh` : 0,
														"margin-right": componentModel.get("tableTfootTotalRowsMarginRight") ? `${componentModel.get("tableTfootTotalRowsMarginRight")}vw` : 0,
														"margin-bottom": componentModel.get("tableTfootTotalRowsMarginBottom") ? `${componentModel.get("tableTfootTotalRowsMarginBottom")}vh` : 0,
														"margin-left": componentModel.get("tableTfootTotalRowsMarginLeft") ? `${componentModel.get("tableTfootTotalRowsMarginLeft")}vw` : 0,
													},

													components: {
														type: "textnode",
														content: `${totalRowsVal} results`,
													},
												},
											],
										},
									],
								});
							}
						} else {
							console.log("++ customTableTfoot ^", customTableTfoot, componentModel);
							if (customTableTfoot.find(".custom-table-tfoot-row")[0]) {
								customTableTfoot.find(".custom-table-tfoot-row")[0].remove();
							}
						}
					} catch (e) {
						// statements
						console.log("custom-table-tfoot-row error", e);
					}

					// to rerender the view for the updates to show, like pagination changes, result changes, etc
					componentModel.view.render();
    			});
    		}
		});
	}
};

export default CustomTablePluginNew;


